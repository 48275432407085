import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'

import IconButton from '../../Buttons/IconButton'

class SingleButtonCheckFormInput extends PureComponent {
  render() {
    const icon = this.props.value ? faCheck : faXmark
    const color = this.props.value ? 'success' : 'light'
    return (
      <IconButton
        size={this.props.size}
        icon={icon}
        color={color}
        condensed={this.props.condensed}
        block={this.props.block}
        onClick={this.handleClick}
      >{this.props.text}
      </IconButton>
    )
  }

  compareValues = newValue => {
    return this.props.value.includes(newValue)
  }

  handleClick = () => {
    const { onChange, value } = this.props
    return onChange(!value, null)
  }

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,
    size: PropTypes.string,
    condensed: PropTypes.bool,
    block: PropTypes.bool
  }
}

export default SingleButtonCheckFormInput
