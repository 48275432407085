import PropTypes from 'prop-types'
import { NavLink as RouterNavLink } from 'react-router-dom'
import {
  Nav,
  NavItem,
  NavLink
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './sidebar.scss'
import Feature from '../../components/Authentication/Feature'
import {
  findFeaturePermissionsByKey,
  stageProperties,
  stages
} from '../../components/Authentication/featurePermissions'
import routes from '../Main/routes'

const getIcon = link => {
  if(link.IconComponent) {
    return <link.IconComponent size='lg' {...link.iconProps} />
  } else if(link.icon && link.badgeComponent) {
    return (
      <span className='fa-layers fa-fw'>
        <FontAwesomeIcon icon={link.icon} size='lg' {...link.iconProps} />
        {link.badgeComponent ? <link.badgeComponent /> : null}
      </span>
    )
  } else if(link.icon) {
    return <FontAwesomeIcon icon={link.icon} size='lg' {...link.iconProps} />
  } else {
    return null
  }
}

const Sidebar = ({ isActive, onNavClick }) => {
  const routeNavItems = routes.filter(link => link.sidebarEnabled).map(link => {
    const icon = getIcon(link)
    const featurePermissions = findFeaturePermissionsByKey(link.featureKey)
    const featureProperties = stageProperties[featurePermissions.stage] || stageProperties.default
    const navItemClassName = isActive ? featureProperties.colorClass : null
    const sidebarDescription = featurePermissions.stage > stages.BETA && featureProperties.sidebarText
    return (
      <Feature key={link.path} featureKey={link.featureKey}>
        <NavItem
          className={navItemClassName}
          onClick={onNavClick}
        >
          <NavLink
            disabled={!isActive}
            isActive={link.isActive}
            exact={link.exact}
            to={link.path}
            tag={RouterNavLink}
            activeClassName='active'
            className='mr-child-3 sidebar-row'
            title={link.text}
          >
            <span className='sidebar-item-icon'>
              {icon}
            </span>
            <span className='sidebar-item-text'>
              {link.text}{sidebarDescription && ` (${sidebarDescription})`}
            </span>
          </NavLink>
        </NavItem>
      </Feature>
    )
  })
  return (
    <Nav
      id='nav-sidebar'
      vertical
      className={isActive ? null : 'disabled'}
    >
      {routeNavItems}
    </Nav>
  )
}

Sidebar.propTypes = {
  isActive: PropTypes.bool,
  onNavClick: PropTypes.func.isRequired
}

export default Sidebar
