import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Input as RSInput } from 'reactstrap'
import { clsx } from 'clsx'
import get from 'lodash/get'
import isBoolean from 'lodash/isBoolean'

import FormInputGroupWrapper from './FormInputGroupWrapper'
import FormInputWrapper from './FormInputWrapper'

const FormPlainCheckboxInput = ({
  name,
  value,
  indeterminate,
  errors,
  label,
  inputProps,
  inputOnly,
  required,
  disabled,
  formGroupClassName,
  tooltipError,
  onChange,
  onValueChange,
  isButton,
  buttonCheckboxData = {
    trueLabel: null,
    trueClassName: 'btn-sm btn-outline-primary',
    falseLabel: null,
    falseClassName: 'btn-sm btn-danger'
  },
  isCustomAddon,
  labelTooltip,
  helpText,
  positionFixed,
  invalid,
  initialValue = false
}) => {
  const ref = useRef(null)

  useEffect(() => {
    if(isBoolean(indeterminate)) {
      ref.current.indeterminate = !value && indeterminate
    }
  }, [indeterminate, value])

  const booleanLabel = value ? buttonCheckboxData.trueLabel : buttonCheckboxData.falseLabel
  const inputLabel = isButton ? booleanLabel : label
  const buttonClassName = value ? buttonCheckboxData.trueClassName : buttonCheckboxData.falseClassName

  return (
    <FormInputWrapper
      name={name}
      label={inputLabel}
      inputOnly={inputOnly}
      required={required}
      check={!isButton}
      formGroupClassName={formGroupClassName}
      labelClassName={isButton ? clsx('btn', buttonClassName) : 'w-100'}
      tooltip={labelTooltip}
      helpText={helpText}
    >
      <FormInputGroupWrapper
        name={name}
        errors={errors}
        tooltipError={tooltipError}
        isCustomAddon={isCustomAddon}
        positionFixed={positionFixed}
      >
        <RSInput
          innerRef={ref}
          id={name}
          invalid={invalid || !!get(errors, name)}
          type='checkbox'
          className={clsx('mt-2', isButton && 'btn-check')} // provide margin due prop size='sm' on <Label /> breaks input
          checked={value == null ? initialValue : value}
          data-testid={name}
          {...inputProps}
          disabled={disabled}
          onChange={event => {
            // Two on changes for possibility to perform two different actions based on a single change
            onValueChange?.(event)
            onChange?.(event)
          }}
        />
      </FormInputGroupWrapper>
    </FormInputWrapper>
  )
}

FormPlainCheckboxInput.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string.isRequired,
  value: PropTypes.bool,
  errors: PropTypes.object,
  label: PropTypes.node,
  inputProps: PropTypes.object,
  inputOnly: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  formGroupClassName: PropTypes.string,
  tooltipError: PropTypes.bool,
  indeterminate: PropTypes.bool,
  reverseButtonColors: PropTypes.bool,
  isButton: PropTypes.bool,
  onChange: PropTypes.func,
  onValueChange: PropTypes.func,
  buttonCheckboxData: PropTypes.shape({
    trueLabel: PropTypes.node,
    trueClassName: PropTypes.string,
    falseLabel: PropTypes.node,
    falseClassName: PropTypes.string
  }),
  isCustomAddon: PropTypes.bool,
  labelTooltip: PropTypes.node,
  helpText: PropTypes.node,
  positionFixed: PropTypes.bool,
  invalid: PropTypes.bool,
  initialValue: PropTypes.bool
}

export default FormPlainCheckboxInput
