import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { hideActionPrompt } from '../../actions/uiActions'
import { uiTypes } from '../../constants'
import useActions from '../../hooks/useActions'
import ConfirmWorkChangesPrompt from './ConfirmWorkChangesPrompt'
import DeleteMachineRecordPrompt from './DeleteMachineRecordPrompt'
import DeleteWorkRecordPrompt from './DeleteWorkRecordPrompt'
import ShowWarehouseInventoryErrors from './ShowWarehouseInventoryErrors'
import SwitchSupplyOfferVatPrompt from './SwitchSupplyOfferVatPrompt'
import SwitchSystemCustomerPrompt from './SwitchSystemCustomerPrompt'
import UnassignWorkFromInboundInvoiceRowPrompt from './UnassignWorkFromInboundInvoiceRowPrompt'

const promptComponents = {
  [uiTypes.PROMPT_SWITCH_SYSTEM_CUSTOMER]: SwitchSystemCustomerPrompt,
  [uiTypes.PROMPT_WORK_CHANGES]: ConfirmWorkChangesPrompt,
  [uiTypes.PROMPT_INBOUND_INVOICE_ROW_UNASSIGN_WORK]: UnassignWorkFromInboundInvoiceRowPrompt,
  [uiTypes.PROMPT_DELETE_WORK_RECORD]: DeleteWorkRecordPrompt,
  [uiTypes.PROMPT_DELETE_MACHINE_RECORD]: DeleteMachineRecordPrompt,
  [uiTypes.PROMPT_SUPPLY_OFFER_SWITCH_VAT]: SwitchSupplyOfferVatPrompt,
  [uiTypes.SHOW_WAREHOUSE_INVENTORY_ERRORS]: ShowWarehouseInventoryErrors
}

const componentActions = {
  hideActionPrompt
}

const ActionPromptContainer = React.memo(({ promptType, visible, data }) => {
  const actions = useActions(componentActions)
  const PromptBody = promptComponents[promptType]
  return PromptBody
    ? (
      <PromptBody
        visible={visible}
        data={data}
        hideActionPrompt={actions.hideActionPrompt}
      />
      )
    : null
})

ActionPromptContainer.propTypes = {
  promptType: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  data: PropTypes.any
}

const mapStateToProps = ({ ui }) => {
  const { promptType, visible, data } = ui.actionPrompt
  return {
    promptType,
    visible,
    data
  }
}

export default connect(mapStateToProps)(ActionPromptContainer)
