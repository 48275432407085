import {
  getFindItemByIdSelector,
  getItemsFromSearchResultsSelector,
  getStats,
  getSubentitySelectors
} from '../helpers/selectorHelpers'

export const getContactsFromArgument = arg => arg.contacts ? arg.contacts.records : arg

export const findContactWithId = getFindItemByIdSelector(getContactsFromArgument)

export const {
  getSubItemsOfItem: getFilesOfContact,
  rejectSubItemsOfItem: rejectFilesOfContact
} = getSubentitySelectors('contacts', 'files', 'files', 'contactId', 'fileId')

export const getContactsFromSearchResult = getItemsFromSearchResultsSelector('contacts', getContactsFromArgument, findContactWithId)

export const {
  getSubItemsOfItem: getCustomersOfContact
} = getSubentitySelectors('contacts', 'customers', 'customers', 'contactId', 'customerId')

export const {
  getSubItemsOfItem: getTargetsOfContact
} = getSubentitySelectors('contacts', 'targets', 'targets', 'contactId', 'targetId')

export const getStatsOfContact = getStats(findContactWithId, 'contacts', 'contactId')
