import { useEffect } from 'react'

const ClickOutsideHandler = ({
  onClickAway,
  mouseEvent = 'click',
  touchEvent = 'touchend',
  innerRef,
  children
}) => {
  useEffect(() => {
    const handleEvents = event => {
      if(innerRef.current && innerRef.current.contains(event.target)) {
        return
      }

      onClickAway(event)
    }
    const handleOnBlurEvent = event => onClickAway(event)

    document.addEventListener(mouseEvent, handleEvents)
    document.addEventListener(touchEvent, handleEvents)
    document.addEventListener('blur', handleOnBlurEvent)

    return () => {
      document.removeEventListener(mouseEvent, handleEvents)
      document.removeEventListener(touchEvent, handleEvents)
      document.removeEventListener('blur', handleOnBlurEvent)
    }
  }, [innerRef, mouseEvent, onClickAway, touchEvent])

  return children
}

export default ClickOutsideHandler
