import { parseTemplate } from 'url-template'

import constants, { actionTypes } from '../constants'
import { actionCreatorsFor, request } from '../helpers/actionHelpers'
import { PATH_SYSTEM_MESSAGES } from '../routes'
import { navigateTo } from './navigateActions'
import tableActions from './tableActions'

const baseActionCreators = actionCreatorsFor(constants.actionKeys.SYSTEM_MESSAGES)
const systemMessageUrlTemplate = parseTemplate(`${PATH_SYSTEM_MESSAGES}/{id}{?workId}`)
export const getUrl = (id, params) => systemMessageUrlTemplate.expand({ id: id || 'new', ...params })

const additionalActionCreators = {
  navigateTo: (systemMessageId, replaceUrl, params) => navigateTo(getUrl(systemMessageId, params), replaceUrl),
  navigateToBase: replaceUrl => navigateTo(PATH_SYSTEM_MESSAGES, replaceUrl),
  tableActions: tableActions(constants.actionKeys.SYSTEM_MESSAGES),
  doPatchAction: request(actionTypes.SYSTEM_MESSAGE_PATCH_ACTION_REQUEST)
}

const systemMessageActions = { ...baseActionCreators, ...additionalActionCreators }

export default systemMessageActions
