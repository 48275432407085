import { call, put, select, takeEvery } from 'redux-saga/effects'

import workActions from '../actions/workActions'
import {
  createPreHandoverInspection,
  createPreHandoverInspectionPdf,
  createPreHandoverInspectionRow,
  deletePreHandoverInspection,
  deletePreHandoverInspectionRow,
  doPreHandoverInspectionRowsPutAction,
  fetchPreHandoverInspectionRows,
  fetchPreHandoverInspections,
  updatePreHandoverInspection,
  updatePreHandoverInspectionRow
} from '../api/workApi'
import { actionTypes } from '../constants'
import { defaultEmbeddedNormalizer } from '../helpers/apiHelpers'
import { memoApiHandler } from '../helpers/generalSagas'
import { addSuccessNotification } from '../helpers/notificationHelpers'
import {
  createFlow,
  deleteFlow,
  fetchFlow,
  genericSagaErrorHandler,
  getPromiseHandlersFromData,
  updateFlow
} from '../helpers/sagaHelpers'

function* preHandoverInspectionFetchFlow({ data = {} }) {
  const { workId } = data
  try {
    yield put(workActions.preHandoverInspections.fetchStart())
    const preHandoverInspections = yield call(fetchPreHandoverInspections, workId)
    yield put(workActions.preHandoverInspections.fetchSuccess(preHandoverInspections))
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe lomakkeen noutamisessa')
  }
}
const preHandoverInspectionUpdateFlow = updateFlow(updatePreHandoverInspection, workActions.preHandoverInspections, 'Lomake', 'Lomakkeen')
const preHandoverInspectionCreateFlow = createFlow(createPreHandoverInspection, workActions.preHandoverInspections, 'Lomake', 'Lomakkeen')

function* preHandoverInspectionDeleteFlow({ record, data = {} }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    const toDelete = yield select(state => state.work.preHandoverInspections.records.find(preHandoverInspection => preHandoverInspection.id === record.preHandoverInspectionId))
    const response = yield call(deletePreHandoverInspection, { workId: record.workId, preHandoverInspectionId: toDelete.id })
    yield put(workActions.preHandoverInspections.deleteSuccess(toDelete))
    addSuccessNotification('Lomake poistettu')
    yield call(resolve, response)
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe lomakkeen poistamisessa', reject)
  }
}

const preHandoverInspectionRowFetchFlow = fetchFlow({
  fetchApi: fetchPreHandoverInspectionRows,
  actions: workActions.preHandoverInspectionRows,
  base: 'work.preHandoverInspectionRows',
  errorMsg: 'Lomakerivin'
})
const preHandoverInspectionRowUpdateFlow = updateFlow(updatePreHandoverInspectionRow, workActions.preHandoverInspectionRows, 'Lomakerivi', 'Lomakerivin')
const preHandoverInspectionRowCreateFlow = createFlow(createPreHandoverInspectionRow, workActions.preHandoverInspectionRows, 'Lomakerivi', 'Lomakerivin')
const preHandoverInspectionRowDeleteFlow = deleteFlow({
  deleteApi: deletePreHandoverInspectionRow,
  actions: workActions.preHandoverInspectionRows,
  singular: 'Lomakerivi',
  errorMsg: 'Lomakerivin',
  base: 'work.preHandoverInspectionRows'
})

function* preHandoverInspectionRowsPutActionFlow({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(record)
  const notificationText = record.isApproved == null
    ? 'Rivin hyväksyntä poistettu'
    : record.isApproved
      ? 'Rivi hyväksytty'
      : 'Rivi hylätty'
  try {
    const requestBody = record
    const urlExpand = data
    const response = yield call(doPreHandoverInspectionRowsPutAction, requestBody, urlExpand)
    yield put(workActions.preHandoverInspectionRows.fetchSuccess(response))
    addSuccessNotification(notificationText)
    yield call(resolve, response)
  } catch(err) {
    yield put(workActions.preHandoverInspectionRows.fetchError(err))
    yield * genericSagaErrorHandler(err, notificationText, reject)
  }
}

function* preHandoverFetchMemosFlow({ data = {} }) {
  const { workId, preHandoverInspectionId } = data
  try {
    yield put(workActions.preHandoverInspectionMemosFetchStart())
    const preHandoverInspectionMemos = yield call(fetchPreHandoverInspections, workId, { preHandoverInspectionId, action: 'memos', actionParam: 'supervisor' })
    yield memoApiHandler(workActions.preHandoverInspectionMemosFetchSuccess)(defaultEmbeddedNormalizer(preHandoverInspectionMemos))
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe lomakkeen login noutamisessa')
  }
}

function* preHandoverInspectionPdfPostActionFlow({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(record)
  try {
    const response = yield call(createPreHandoverInspectionPdf, record, data)
    yield call(resolve, response)
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Pdf:n generointi epäonnistui', reject)
  }
}

export default function* preHandoverInspectionSaga() {
  yield takeEvery(workActions.preHandoverInspections.actionTypes.fetchRequest, preHandoverInspectionFetchFlow)
  yield takeEvery(workActions.preHandoverInspections.actionTypes.updateRequest, preHandoverInspectionUpdateFlow)
  yield takeEvery(workActions.preHandoverInspections.actionTypes.createRequest, preHandoverInspectionCreateFlow)
  yield takeEvery(workActions.preHandoverInspections.actionTypes.deleteRequest, preHandoverInspectionDeleteFlow)

  yield takeEvery(workActions.preHandoverInspectionRows.actionTypes.fetchRequest, preHandoverInspectionRowFetchFlow)
  yield takeEvery(workActions.preHandoverInspectionRows.actionTypes.updateRequest, preHandoverInspectionRowUpdateFlow)
  yield takeEvery(workActions.preHandoverInspectionRows.actionTypes.createRequest, preHandoverInspectionRowCreateFlow)
  yield takeEvery(workActions.preHandoverInspectionRows.actionTypes.deleteRequest, preHandoverInspectionRowDeleteFlow)

  yield takeEvery(actionTypes.PRE_HANDOVER_INSPECTION_ROWS_PUT_ACTION_REQUEST, preHandoverInspectionRowsPutActionFlow)
  yield takeEvery(workActions.actionTypes.preHandoverInspectionMemosFetchRequest, preHandoverFetchMemosFlow)
  yield takeEvery(actionTypes.PRE_HANDOVER_INSPECTION_PDF_POST_REQUEST, preHandoverInspectionPdfPostActionFlow)
}
