import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { findTinkaSuppliers, getSuppliersFromArgument } from '../../selectors/supplierSelectors'
import { TypeaheadFormInput } from '../FormGroups'

const selectSingleId = ([selected]) => selected?.id

const SupplierChooserInput = ({
  supplierId,
  labelKey,
  field,
  onChange,
  setNull,
  align,
  isTinkaSuppliers
}) => {
  const suppliers = useSelector(state => isTinkaSuppliers ? findTinkaSuppliers(state) : getSuppliersFromArgument(state))
  const getSupplier = useCallback(supplierId => suppliers.filter(({ id }) => id === supplierId), [suppliers]) // filter because typehead requires an array

  return (
    <TypeaheadFormInput
      value={supplierId}
      getSelected={getSupplier}
      options={suppliers}
      labelKey={labelKey}
      field={field}
      onChange={onChange}
      setNull={setNull}
      align={align}
      handleOnChange={selectSingleId}
      inputProps={{
        'data-testid': field
      }}
    />
  )
}

SupplierChooserInput.propTypes = {
  supplierId: PropTypes.number,
  labelKey: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string
  ]).isRequired,
  field: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  setNull: PropTypes.bool,
  align: PropTypes.oneOf(['justify', 'left', 'right']),
  isTinkaSuppliers: PropTypes.bool
}

export default SupplierChooserInput
