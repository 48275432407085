import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Alert } from 'reactstrap'
import { clsx } from 'clsx'
import isFunction from 'lodash/isFunction'
import memoize from 'micro-memoize'

import RequiredFieldIndicator from '../FormGroups/helpers/RequiredFieldIndicator'
import DelayedActionButton from '../misc/DelayedActionButton'

const SelectedBlockLink = ({ title, isRequired, navigateTo }) => {
  if(!title) {
    return null
  }
  const content = (
    <div>
      <strong>
        {title}
        <RequiredFieldIndicator isRequired={!!isRequired} />
      </strong>
      <br />
    </div>
  )
  if(navigateTo) {
    const linkProps = isFunction(navigateTo)
      ? { onClick: navigateTo, to: null }
      : { to: navigateTo }
    return (
      <Link
        color='link'
        className='d-block p-0 text-dark'
        title='Avaa valittu'
        {...linkProps}
      >{content}
      </Link>
    )
  }
  return content
}
SelectedBlockLink.propTypes = {
  title: PropTypes.node,
  isRequired: PropTypes.bool,
  navigateTo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
}

export const getUnset = memoize((updateField, fieldName) => () => updateField(fieldName, null))

const SelectedBlock = ({
  disabled,
  unset,
  title,
  isRequired = false,
  navigateTo,
  children,
  testId,
  className
}) => (
  <Alert color='light' className={clsx('d-flex', className)}>
    {disabled
      ? null
      : (
        <div className='position-absolute top-0 end-0 py-1 px-2'>
          <DelayedActionButton
            close
            confirmationMessage=''
            outline
            onClick={unset}
            testId={`clear_${testId}`}
          />
        </div>
        )}
    <div data-testid={testId} className='overflow-auto-x'>
      <SelectedBlockLink
        title={title}
        isRequired={isRequired}
        navigateTo={navigateTo}
      />
      {children}
    </div>
  </Alert>
)

SelectedBlock.propTypes = {
  disabled: PropTypes.bool,
  unset: PropTypes.func,
  children: PropTypes.node,
  title: PropTypes.node,
  isRequired: PropTypes.bool,
  className: PropTypes.string,
  navigateTo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  testId: PropTypes.string
}

export default SelectedBlock
