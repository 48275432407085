import { faXmark } from '@fortawesome/free-solid-svg-icons'

import IconButton from './IconButton'

const CancelButton = props => (
  <IconButton color='danger' testId='cancelButton' {...props} icon={props.icon || faXmark} />
)

CancelButton.propTypes = IconButton.propTypes

export default CancelButton
