import constants from '../constants'
import { getCombinedListReducerFor, getTableOptions } from '../helpers/reducerHelpers'
import tableReducerFor from './tableReducer'

export const initialWarehouseActionsTableOptions = {
  default: getTableOptions({ orderBy: ['updatedAt'], sortOrder: 'DESC' })
}

export const initialWarehouseProductStatsTableOptions = {
  default: getTableOptions()
}

export default getCombinedListReducerFor(constants.actionKeys.WAREHOUSES, {
  warehouseActions: getCombinedListReducerFor(constants.actionKeys.WAREHOUSE_ACTIONS, {
    tableOptions: tableReducerFor(constants.actionKeys.WAREHOUSE_ACTIONS, initialWarehouseActionsTableOptions)
  }),
  warehouseProductStats: getCombinedListReducerFor(constants.actionKeys.WAREHOUSE_PRODUCT_STATS, {
    tableOptions: tableReducerFor(constants.actionKeys.WAREHOUSE_PRODUCT_STATS, initialWarehouseProductStatsTableOptions)
  }, '_id')
})
