import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormProvider, useForm } from 'react-hook-form'
import { clsx } from 'clsx'

import { addErrorNotification } from '../../helpers/notificationHelpers'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import { handleApiSubmitErrors } from '../hookForm/useApiForm'

const EditableRowWrapper = ({
  row,
  defaultEditValues,
  toggleRowEditingApi,
  meta,
  className,
  children,
  formRowProps,
  ...rowProps
}) => {
  const isNew = !row.original.id
  const formMethods = useForm({
    defaultValues: defaultEditValues ?? row.original
  })
  const { isDirty, isSubmitting, isSubmitSuccessful } = formMethods.formState
  const onSubmit = async rowData => {
    if((formRowProps?.allowSubmitNonDirtyForm || isDirty) && !isSubmitting) {
      formMethods.clearErrors()
      if(meta?.onSubmit) {
        try {
          await meta.onSubmit(rowData, row.original)
        } catch(error) {
          if(error.validationErrors) {
            handleApiSubmitErrors(error, formMethods.setError)
          } else {
            addErrorNotification(error.message)
          }
          formMethods.setError('root.serverError', { type: error.status, message: error?.json?.message ?? error.message ?? 'Tuntematon virhe' })
        }
      }
    } else if(isNew) {
      meta?.onCancelEdit?.(null)
    }
  }
  useEffect(() => {
    if(isSubmitSuccessful) {
      toggleRowEditingApi.disable()
    }
  }, [isSubmitSuccessful, toggleRowEditingApi])

  const formRef = useOnClickOutside(formMethods.handleSubmit(onSubmit), formRowProps?.ignoreOutsideClick)
  return (
    <FormProvider {...formMethods}>
      <form
        ref={formRef}
        role='row'
        className={clsx('dt-tr', className)}
        onSubmit={formMethods.handleSubmit(onSubmit)}
        {...rowProps}
      >
        {children}
      </form>
    </FormProvider>
  )
}

EditableRowWrapper.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    original: PropTypes.object.isRequired,
    getVisibleCells: PropTypes.func.isRequired,
    getIsSelected: PropTypes.func
  }),
  defaultEditValues: PropTypes.object,
  toggleRowEditingApi: PropTypes.shape({
    toggle: PropTypes.func.isRequired,
    disable: PropTypes.func.isRequired,
    enable: PropTypes.func.isRequired
  }).isRequired,
  meta: PropTypes.shape({
    onSubmit: PropTypes.func,
    onCancelEdit: PropTypes.func
  }),
  className: PropTypes.string,
  children: PropTypes.node,
  formRowProps: PropTypes.shape({
    allowSubmitNonDirtyForm: PropTypes.bool,
    ignoreOutsideClick: PropTypes.bool
  })
}

export default EditableRowWrapper
