import { takeLatest } from 'redux-saga/effects'

import eAddressActions from '../actions/eAddressActions'
import { fetchEaddresses } from '../api/eAddressApi'
import { actionTypes } from '../constants'
import { fetchFlow, getGenericErrorMessage } from '../helpers/sagaHelpers'

const eAddressFetchFlow = fetchFlow({
  fetchApi: fetchEaddresses,
  actions: eAddressActions,
  errorMsg: (err, type) => getGenericErrorMessage(err, 'Verkkolaskuosotteiden', type),
  showValidationErrors: true
})

export default function* eAddressSaga() {
  yield takeLatest(actionTypes.EADDRESS_FETCH_REQUEST, eAddressFetchFlow)
}
