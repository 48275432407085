import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { hidePhoneNumber } from '@evelia/helpers/helpers'

import { handleSendSmsToken } from '../../api/mfaApi'
import { addErrorNotification } from '../../helpers/notificationHelpers'
import { findCurrentEmployee } from '../../selectors/employeeSelectors'
import { findSystemCustomerSettings } from '../../selectors/systemCustomerSelectors'
import EveliaButton from '../Buttons/EveliaButton'
import Tooltip from '../Tooltip'

const SendSmsButton = ({ mfaSecretType }) => {
  const companyData = useSelector(findSystemCustomerSettings)
  const employeeData = useSelector(findCurrentEmployee)

  const sendSmsToken = async() => {
    try {
      await handleSendSmsToken(mfaSecretType)
    } catch(error) {
      addErrorNotification(error?.message || 'Tapahtui virhe')
    }
  }

  return companyData.smsSettings.isSmsEnabled && companyData.settings.isMfaSmsEnabled && employeeData.phoneNumber
    ? (
      <>
        <div>
          <EveliaButton
            onClick={sendSmsToken}
            testId='sendMfaSms'
          >
            Lähetä vahvistuskoodi tekstiviestillä
          </EveliaButton>
          <Tooltip id='test' message='Tekstiviestin lähetyksestä peritään hinnaston mukainen maksu' />
        </div>
        <small>
          {`Viesti lähetetään numeroon: ${hidePhoneNumber(employeeData.phoneNumber)}`}
        </small>
      </>
      )
    : null
}

SendSmsButton.propTypes = {
  mfaSecretType: PropTypes.oneOf(['mfaSecret', 'currentQrCodeMfaSecret']).isRequired
}

export default SendSmsButton
