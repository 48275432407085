import { createRef, PureComponent, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Getter
} from '@devexpress/dx-react-core'
import {
  CustomPaging,
  EditingState,
  FilteringState,
  GroupingState,
  IntegratedFiltering,
  IntegratedGrouping,
  IntegratedSorting,
  IntegratedSummary,
  PagingState,
  RowDetailState,
  SearchState,
  SelectionState,
  SortingState,
  SummaryState
} from '@devexpress/dx-react-grid'
import { GridExporter } from '@devexpress/dx-react-grid-export'
import { appendEitherOrEmpty } from '@evelia/helpers/helpers'
import {
  ExportPanel,
  Grid,
  PagingPanel,
  SearchPanel,
  Table,
  TableBandHeader,
  TableEditColumn,
  TableEditRow,
  TableFixedColumns,
  TableGroupRow,
  TableHeaderRow,
  TableInlineCellEditing,
  TableRowDetail,
  TableSummaryRow,
  Toolbar,
  VirtualTable
} from 'dx-react-grid-bootstrap5'
import saveAs from 'file-saver'
import memoize from 'micro-memoize'

import { callFunctionOrContent } from '../../helpers/helpers'
import { childrenPropType, tableOptionPropType } from '../../propTypes'
import { ExpandIcon } from '../Buttons/ExpandButton'
import { mimeTypes } from '../File/fileHelpers'
import TextFormInput from '../FormGroups/inputs/TextFormInput'
import {
  Command,
  DisablableIntegratedSelection,
  DisablableTableSelection,
  EditingHeadCellComponent,
  ExpandButtonColumn,
  ExportButton,
  getCommandCell,
  HeadCellComponent,
  HeadComponent,
  renderTableCell,
  renderTableRow,
  RootComponent,
  SortLabel,
  TableComponent,
  TableLoadingSpinner,
  TableNoDataCell,
  TableNoDataCellLow,
  TableSummaryContent,
  ToolbarContent
} from './defaultGridComponents'
import {
  parseOptions,
  reorderEditCommandColumn
} from './helpers'
import {
  exportLocalizationMessages,
  searchLocalizationMessages,
  tableLocalizationMessages,
  totalLocalizationMessages
} from './localization'

const editCellPropTypes = {
  editingEnabled: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func
  ]),
  column: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  onValueChange: PropTypes.func.isRequired,
  row: PropTypes.shape({
    id: PropTypes.number
  }),
  tableRow: PropTypes.shape({
    rowId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  })
}

const Stub = ({ value, children }) => children || value || <span />

Stub.propTypes = {
  value: PropTypes.any,
  children: childrenPropType
}

export const getEditCell = (editingStates, getRowId, validationErrors) => {
  const EditCell = props => {
    const { column, onValueChange, row, tableRow } = props
    const { rowId } = tableRow
    const rowValidationErrors = validationErrors[row.id == null ? 'new' : rowId]
    const columnSpec = editingStates.find(editingState => editingState.columnName === column.name)
    const columnValidationError = rowValidationErrors?.[columnSpec?.columnName]
    const validationProps = {}
    if(columnValidationError) {
      validationProps.title = columnValidationError.msg
      validationProps.className = 'table-danger is-invalid border border-danger'
    }

    const editingEnabled = callFunctionOrContent(props.editingEnabled, row) && !row[columnSpec?.disableCellIfRowContains]

    const EditingComponent = editingEnabled
      ? columnSpec?.editingComponent || TextFormInput
      : columnSpec.customRenderer
        ? ({ value, row }) => columnSpec.customRenderer(value, row)
        : columnSpec.customComponent ?? Stub

    const componentProps = {
      ...props,
      ...columnSpec?.customComponentProps,
      ...columnSpec?.getEditingComponentProps?.(row, props)
    }
    const inputId = `${columnSpec?.columnName}-${getRowId?.(row) ?? row.id ?? ''}`

    if(!editingEnabled) {
      return (
        <Table.Cell column={column} row={row}>
          <EditingComponent {...componentProps} />
        </Table.Cell>
      )
    } else {
      return (
        <TableEditRow.Cell {...props} {...validationProps}>
          <EditingComponent
            readOnly={!editingEnabled}
            {...componentProps}
            inputId={inputId}
            field={columnSpec?.columnName}
            onChange={onValueChange}
            isInvalid={!!columnValidationError}
          />
        </TableEditRow.Cell>
      )
    }
  }
  EditCell.propTypes = editCellPropTypes
  return EditCell
}

const ensurePromise = value => value && value.then && value.catch ? value : Promise.resolve(value)

const findColumnEditingState = (columnExtensions, field) => columnExtensions.find(columnEditingState => columnEditingState.columnName === field)

export const ControlledEditingState = ({
  columnExtensions,
  onAdded,
  onChanged,
  onDeleted,
  rows,
  defaultModel,
  getRowId,
  setValidationErrors,
  findFromRows
}) => {
  const [editingRowIds, setEditingRowIds] = useState([])
  const [addedRows, setAddedRows] = useState([])
  const [rowChanges, setRowChanges] = useState({})

  const changeAddedRows = value => {
    const initialized = defaultModel
      ? value.map(row => (Object.keys(row).length ? row : { ...defaultModel }))
      : value.map(row => columnExtensions.reduce((acc, columnExtension) => {
        if(acc[columnExtension.columnName] == null) {
          acc[columnExtension.columnName] = columnExtension.defaultValue ?? null
        }
        return acc
      }, { ...row }))
    setAddedRows(initialized)
  }

  const nullifyFieldsIfNeeded = modified =>
    Object.entries(modified)
      .reduce((acc, [key, value]) => {
        const columnEditingState = findColumnEditingState(columnExtensions, key) || {}
        return {
          ...acc,
          [key]: (value === '' && columnEditingState.setNull) ? null : value
        }
      }, {})

  const commitChanges = ({ added, changed, deleted }) => {
    // Check instructions for editing
    // https://devexpress.github.io/devextreme-reactive/react/grid/docs/guides/editing/
    if(added && added.length) {
      setValidationErrors('new', null)
      // It would be possible to add multiple rows simultaneously but not used
      ensurePromise(onAdded(nullifyFieldsIfNeeded(added[0])))
        .catch(err => {
          setValidationErrors('new', err.validationErrors)
          setAddedRows([added[0]])
        })
    }
    if(changed) {
      const changedId = Object.keys(changed)[0]
      const changedItem = changed[changedId]
      const oldItem = findFromRows(rows, changedId, getRowId)
      if(changedItem && oldItem) {
        setValidationErrors(changedId, null)
        const updatedItem = { ...oldItem, ...nullifyFieldsIfNeeded(changedItem) }
        ensurePromise(onChanged(updatedItem))
          .catch(err => {
            setValidationErrors(changedId, err.validationErrors)
            setRowChanges({ ...rowChanges, [changedId]: changedItem })
            setEditingRowIds([...new Set([...editingRowIds, Number(changedId)])])
          })
      }
    }
    if(deleted && deleted.length) {
      const deletedItem = getRowId ? rows.find(row => getRowId(row) === Number(deleted[0])) : rows[deleted[0]]
      if(onDeleted) {
        ensurePromise(onDeleted(deletedItem))
          .catch(err => console.error(err)) // saga helpers handles errors
      }
    }
  }
  return (
    <EditingState
      editingRowIds={editingRowIds}
      onEditingRowIdsChange={setEditingRowIds}
      rowChanges={rowChanges}
      onRowChangesChange={setRowChanges}
      addedRows={addedRows}
      onAddedRowsChange={changeAddedRows}
      onCommitChanges={commitChanges}
      columnExtensions={columnExtensions}
    />
  )
}
ControlledEditingState.propTypes = {
  columnExtensions: PropTypes.array,
  onAdded: PropTypes.func,
  onChanged: PropTypes.func,
  onDeleted: PropTypes.func,
  rows: PropTypes.array,
  defaultModel: PropTypes.object,
  getRowId: PropTypes.func,
  setValidationErrors: PropTypes.func.isRequired,
  findFromRows: PropTypes.func
}

const getGroupCellContent = columnSpecs => props => {
  const { column } = props // eslint-disable-line react/prop-types
  const columnExtension = columnSpecs.find(columnSpec => columnSpec.columnName === column.name) // eslint-disable-line react/prop-types
  if(columnExtension && columnExtension.groupComponent) {
    return <columnExtension.groupComponent {...props} />
  }
  return <TableGroupRow.Content {...props} />
}

const getCustomFormatters = memoize(columnSpecs => columnSpecs.customRenderers.map(({ columnName, Formatter }) => <Formatter key={columnName} />), { maxSize: 10 })

class DataGrid extends PureComponent {
  state = {
    validationErrors: {}
  }

  setValidationErrors = (rowId, validationErrors) => {
    this.setState({
      validationErrors: {
        ...this.state.validationErrors,
        [rowId]: validationErrors
      }
    })
  }

  findFromRows = (rows, rowId, getRowId) => getRowId ? rows.find(row => getRowId(row) === Number(rowId)) : rows[rowId]

  exporterRef = createRef()
  render() {
    const {
      rows,
      columnSpecs,
      onRowClick,
      RowComponent,
      noRowsText,
      onAdded,
      onChanged,
      onDeleted,
      hideCommandColumn,
      tableOptions,
      renderToolbarContent,
      disableSearch,
      buttonFilters,
      useVirtualTable,
      disableToolbar,
      onSelectionChange,
      selection,
      rowSelectionEnabled,
      headClasses,
      tableClasses,
      getRowId,
      defaultModel,
      disableFiltering,
      disableSorting,
      gridHeight,
      enableInlineEdit,
      interceptRowChange,
      rowPropsByRow,
      defaultSorting,
      testId,
      exportFilename,
      customSummaryCalculator,
      customizeSummaryCell,
      customizeHeader,
      customizeCell,
      isLoading,
      noEditColumnReorder,
      RowDetail,
      isLowNoDataRow,
      rowHeight,
      showSelectAll = true,
      totalSumClassName,
      TableSummaryContentComponent = TableSummaryContent
    } = this.props
    const GridTable = useVirtualTable ? VirtualTable : Table
    const height = useVirtualTable ? gridHeight || 750 : gridHeight

    const searchDisabled = disableSearch || disableToolbar
    const TableRowComponent = RowComponent || renderTableRow(onRowClick, rowPropsByRow)
    const TableCellComponent = renderTableCell(columnSpecs.columnWidths, columnSpecs.editingStates)

    const {
      sortingProps,
      pagingProps,
      filteringProps
    } = parseOptions(tableOptions, this.props)

    const showEditable = !hideCommandColumn && (onAdded || onChanged || onDeleted)
    const groupBy = !!columnSpecs.groupingStates.length
    return (
      <div className='w-100 grid-wrapper'>
        <Grid rows={rows} columns={columnSpecs.columns} getRowId={getRowId} rootComponent={RootComponent(useVirtualTable ? undefined : height)}>
          <ControlledEditingState
            columnExtensions={columnSpecs.editingStates}
            onAdded={onAdded}
            onChanged={onChanged}
            onDeleted={onDeleted}
            defaultModel={defaultModel}
            rows={rows}
            getRowId={getRowId}
            setValidationErrors={this.setValidationErrors}
            findFromRows={this.findFromRows}
          />
          <SearchState />
          <SortingState defaultSorting={defaultSorting} columnExtensions={columnSpecs.sortingStates} {...sortingProps} />
          {!!onSelectionChange && (
            <SelectionState
              selection={selection}
              onSelectionChange={onSelectionChange}
            />
          )}
          {!!onSelectionChange && <DisablableIntegratedSelection rowSelectionEnabled={rowSelectionEnabled} rows={rows} />}
          {tableOptions && <PagingState {...pagingProps} />}
          {tableOptions && <CustomPaging {...pagingProps} />}
          {tableOptions && <FilteringState {...filteringProps} />}
          {!disableFiltering && !tableOptions && <IntegratedFiltering />}
          {!tableOptions && <IntegratedSorting />}
          {groupBy && <GroupingState grouping={columnSpecs.groupingStates} />}
          {groupBy && <IntegratedGrouping columnExtensions={columnSpecs.groupingStates} />}

          <GridTable
            headComponent={HeadComponent(headClasses)}
            tableComponent={TableComponent(tableClasses, testId)}
            bodyComponent={isLoading ? TableLoadingSpinner : Table.TableBody}
            rowComponent={TableRowComponent}
            messages={noRowsText ? { noData: noRowsText } : tableLocalizationMessages}
            columnExtensions={columnSpecs.columnWidths}
            height={height}
            estimatedRowHeight={rowHeight || 50} // TODO: better estimate!
            cellComponent={TableCellComponent}
            noDataCellComponent={isLowNoDataRow ? TableNoDataCellLow : TableNoDataCell}
          />

          {getCustomFormatters(columnSpecs)}
          {!disableToolbar && <Toolbar />}
          {!disableToolbar && <ToolbarContent renderToolbarContent={renderToolbarContent} />}
          {!disableToolbar && !!exportFilename && <ExportPanel startExport={this.startExport} toggleButtonComponent={ExportButton} messages={exportLocalizationMessages} />}
          <TableHeaderRow
            showSortingControls={!disableSorting}
            sortLabelComponent={SortLabel}
            cellComponent={HeadCellComponent}
          />
          {!!onSelectionChange && <DisablableTableSelection showSelectAll={showSelectAll} rowSelectionEnabled={rowSelectionEnabled} />}
          {groupBy && (
            <TableGroupRow
              contentComponent={getGroupCellContent(columnSpecs.groupingStates)}
              columnExtensions={columnSpecs.groupingStates}
              iconComponent={ExpandIcon}
            />
          )}

          {!!RowDetail && <RowDetailState />}
          {!!RowDetail && <TableRowDetail contentComponent={RowDetail} toggleCellComponent={ExpandButtonColumn} />}

          {!!columnSpecs.columnBands.length && (
            <TableBandHeader
              columnBands={columnSpecs.columnBands}
            />
          )}
          {(!searchDisabled && !tableOptions) ? <SearchPanel messages={searchLocalizationMessages} /> : null}

          {!!columnSpecs.totalItems.length && <SummaryState totalItems={columnSpecs.totalItems} />}
          {!!columnSpecs.totalItems.length && <IntegratedSummary calculator={customSummaryCalculator} />}
          {!!columnSpecs.totalItems.length && (
            <TableSummaryRow
              messages={totalLocalizationMessages}
              itemComponent={TableSummaryContentComponent}
              totalCellComponent={props => (
                <Table.Cell
                  {...props}
                  className={appendEitherOrEmpty(props.className, totalSumClassName)}
                />
              )}
            />
          )}

          {tableOptions && <PagingPanel pageSizes={tableOptions.defaultLimits} />}
          {showEditable && <TableEditRow cellComponent={getEditCell(columnSpecs.editingStates, getRowId, this.state.validationErrors)} />}
          {showEditable && (
            <TableEditColumn
              showAddCommand={!!onAdded}
              showEditCommand={!!onChanged}
              showDeleteCommand={!!onDeleted}
              commandComponent={Command}
              cellComponent={getCommandCell(buttonFilters)}
              headerCellComponent={EditingHeadCellComponent}
            />
          )}
          {enableInlineEdit && (
            <TableInlineCellEditing
              cellComponent={getEditCell(columnSpecs.editingStates, null, this.state.validationErrors)}
            />
          )}
          {(!!columnSpecs.pinLeft.length || !!columnSpecs.pinRight.length) && <TableFixedColumns leftColumns={columnSpecs.pinLeft} rightColumns={columnSpecs.pinRight} />}
          {(showEditable && !noEditColumnReorder) && (
            <Getter
              name='tableColumns'
              computed={reorderEditCommandColumn}
            />
          )}
          {interceptRowChange && (
            <Getter
              name='createRowChange'
              computed={this.interceptRowChange}
            />
          )}
        </Grid>
        {!!exportFilename && (
          <GridExporter
            ref={this.exporterRef}
            columns={columnSpecs.exportColumns}
            rows={rows}
            onSave={this.onSave}
            totalSummaryItems={columnSpecs.totalItems}
            customizeSummaryCell={customizeSummaryCell}
            customizeHeader={customizeHeader}
            customizeCell={customizeCell}
          />
        )}
      </div>
    )
  }

  interceptRowChange = () =>
    (row, value, columnName) =>
      this.props.columnSpecs?.editingStates?.[columnName]?.interceptRowChange(row, value, columnName) ?? this.props.interceptRowChange(row, value, columnName)

  startExport = options => {
    this.exporterRef.current.exportGrid(options)
  }

  onSave = workbook =>
    workbook.xlsx.writeBuffer()
      .then(buffer => {
        return saveAs(new Blob([buffer], { type: mimeTypes.excel }), `${this.props.exportFilename}.xlsx`)
      })
}

export const commonPropTypes = {
  columnSpecs: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  RowComponent: PropTypes.any,
  noRowsText: PropTypes.node,
  getRowId: PropTypes.func,
  tableClasses: PropTypes.string,
  headClasses: PropTypes.string,
  rowPropsByRow: PropTypes.func,
  tableOptions: tableOptionPropType,
  defaultSorting: PropTypes.arrayOf(PropTypes.shape({
    columnName: PropTypes.string,
    direction: PropTypes.oneOf(['asc', 'desc', 'ASC', 'DESC'])
  })),
  renderToolbarContent: PropTypes.func,
  testId: PropTypes.string,
  onAdded: PropTypes.func,
  onChanged: PropTypes.func,
  onDeleted: PropTypes.func,
  defaultModel: PropTypes.object,
  enableInlineEdit: PropTypes.bool,
  useVirtualTable: PropTypes.bool
}

DataGrid.propTypes = {
  ...commonPropTypes,
  hideCommandColumn: PropTypes.bool,
  clearOptions: PropTypes.func,
  onSortingChange: PropTypes.func,
  onCurrentPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onFiltersChange: PropTypes.func,
  disableSearch: PropTypes.bool,
  buttonFilters: PropTypes.func,
  disableToolbar: PropTypes.bool,
  onSelectionChange: PropTypes.func,
  selection: PropTypes.array,
  rowSelectionEnabled: PropTypes.func,
  disableFiltering: PropTypes.bool,
  disableSorting: PropTypes.bool,
  gridHeight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  interceptRowChange: PropTypes.func,
  exportFilename: PropTypes.string,
  customSummaryCalculator: PropTypes.func,
  customizeSummaryCell: PropTypes.func,
  customizeHeader: PropTypes.func,
  customizeCell: PropTypes.func,
  isLoading: PropTypes.bool,
  noEditColumnReorder: PropTypes.bool,
  RowDetail: PropTypes.func,
  isLowNoDataRow: PropTypes.bool,
  rowHeight: PropTypes.number,
  showSelectAll: PropTypes.bool
}

export default DataGrid
