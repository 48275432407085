import { createSelector } from 'reselect'

import { mapWorkTypes } from '../helpers/helpers'
import { getFindItemByIdSelector } from '../helpers/selectorHelpers'

export const getMachineTypesFromArgument = arg => arg.machines?.machineTypes?.records || arg

export const findMachineTypeOptions = createSelector(
  getMachineTypesFromArgument,
  machineTypes => mapWorkTypes(machineTypes, type => type.id)
)

export const findMachineTypeWithId = getFindItemByIdSelector(getMachineTypesFromArgument)

export const getMachineTypeSelectMap = createSelector(
  getMachineTypesFromArgument,
  machineTypes => machineTypes.reduce((acc, machineType) => ({
    ...acc,
    [machineType.id]: {
      value: machineType.id,
      text: machineType.name,
      color: machineType.color
    }
  }), {})
)
