import { all, put, takeEvery, takeLatest } from 'redux-saga/effects'

import ticketActions from '../actions/ticketActions'
import ticketRecurrenceRuleActions from '../actions/ticketRecurrenceRuleActions'
import { ticketRecurrenceRuleApi } from '../api/ticketRecurrenceRuleApi'
import {
  createFlow,
  createSocketWatcher,
  deleteFlow,
  fetchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

const watchOnTicketRecurrenceRuleSockets = createSocketWatcher('ticketRecurrenceRule', {
  created: ticketRecurrenceRuleActions.createSuccess,
  updated: ticketRecurrenceRuleActions.updateSuccess,
  deleted: ticketRecurrenceRuleActions.deleteSuccess
})

const handleTicketRecurrenceRuleApiResponse = mainAction =>
  function* ({ data, tickets }) {
    yield put(mainAction(data))
    if(tickets) {
      yield put(ticketActions.fetchSuccess(tickets))
    }
    return data
  }

const ticketRecurrenceRuleFetchFlow = fetchFlow({
  fetchApi: ticketRecurrenceRuleApi.fetch,
  actions: ticketRecurrenceRuleActions,
  base: 'ticketRecurrenceRules',
  errorMsg: 'Toistuvan tehtävän sääntöjen',
  getApiResponseHandler: () => handleTicketRecurrenceRuleApiResponse(ticketRecurrenceRuleActions.fetchSuccess)
})

const ticketRecurrenceRuleUpdateFlow = updateFlow(ticketRecurrenceRuleApi.update, ticketRecurrenceRuleActions, 'Toistuvan tehtävän sääntö', 'Toistuvan tehtävän sääntöjen', handleTicketRecurrenceRuleApiResponse(ticketRecurrenceRuleActions.updateSuccess))
const ticketRecurrenceRuleCreateFlow = createFlow(ticketRecurrenceRuleApi.create, ticketRecurrenceRuleActions, 'Toistuvan tehtävän sääntö', 'Toistuvan tehtävän sääntöjen', handleTicketRecurrenceRuleApiResponse(ticketRecurrenceRuleActions.createSuccess))
const ticketRecurrenceRuleDeleteFlow = deleteFlow({
  deleteApi: ticketRecurrenceRuleApi.remove,
  actions: ticketRecurrenceRuleActions,
  singular: 'Toistuvan tehtävän sääntö',
  errorMsg: 'Toistuvan tehtävän sääntöjen',
  base: 'ticketRecurrenceRules'
})

export default function* ticketRecurrenceRuleSaga() {
  yield takeLatest(ticketRecurrenceRuleActions.actionTypes.fetchRequest, ticketRecurrenceRuleFetchFlow)
  yield takeEvery(ticketRecurrenceRuleActions.actionTypes.updateRequest, ticketRecurrenceRuleUpdateFlow)
  yield takeEvery(ticketRecurrenceRuleActions.actionTypes.createRequest, ticketRecurrenceRuleCreateFlow)
  yield takeEvery(ticketRecurrenceRuleActions.actionTypes.deleteRequest, ticketRecurrenceRuleDeleteFlow)

  yield all([
    watchOnTicketRecurrenceRuleSockets()
  ])
}
