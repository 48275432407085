import { all, takeEvery, takeLatest } from 'redux-saga/effects'

import supplyOfferTypeActions from '../actions/supplyOfferTypeActions'
import {
  createSupplyOfferType,
  deleteSupplyOfferType,
  fetchSupplyOfferTypes,
  updateSupplyOfferType
} from '../api/supplyOfferTypeApi'
import {
  createFlow,
  createSocketWatcher,
  deleteFlow,
  fetchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

const watchOnSupplyOfferTypeSockets = createSocketWatcher('supplyOfferType', {
  created: supplyOfferTypeActions.createSuccess,
  updated: supplyOfferTypeActions.updateSuccess,
  deleted: supplyOfferTypeActions.deleteSuccess
})

const supplyOfferSupplyOfferFetchFlow = fetchFlow({
  fetchApi: fetchSupplyOfferTypes,
  actions: supplyOfferTypeActions,
  base: 'supplyOfferTypes',
  errorMsg: 'Tarjoustyyppien'
})

const supplyOfferTypeUpdateFlow = updateFlow(updateSupplyOfferType, supplyOfferTypeActions, 'Tarjoustyyppi', 'Tarjoustyypin')
const supplyOfferTypeCreateFlow = createFlow(createSupplyOfferType, supplyOfferTypeActions, 'Tarjoustyyppi', 'Tarjoustyypin')
const supplyOfferTypeDeleteFlow = deleteFlow({
  deleteApi: deleteSupplyOfferType,
  actions: supplyOfferTypeActions,
  singular: 'Tarjoustyyppi',
  errorMsg: 'Tarjoustyypin',
  base: 'supplyOfferTypes'
})

export default function* supplyOfferTypeSaga() {
  yield takeLatest(supplyOfferTypeActions.actionTypes.fetchRequest, supplyOfferSupplyOfferFetchFlow)
  yield takeEvery(supplyOfferTypeActions.actionTypes.updateRequest, supplyOfferTypeUpdateFlow)
  yield takeEvery(supplyOfferTypeActions.actionTypes.createRequest, supplyOfferTypeCreateFlow)
  yield takeEvery(supplyOfferTypeActions.actionTypes.deleteRequest, supplyOfferTypeDeleteFlow)

  yield all([
    watchOnSupplyOfferTypeSockets()
  ])
}
