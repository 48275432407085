import PropTypes from 'prop-types'
import { NavLink as RouterNavLink } from 'react-router-dom'
import {
  NavLink
} from 'reactstrap'

import logo from '../../images/evelia-logo.svg'
import { PATH_FRONTPAGE } from '../../routes'

const AppNavLogo = ({ isLinkToMarketingSite }) => {
  const linkProps = isLinkToMarketingSite
    ? {
        tag: 'a',
        href: 'https://evelia.fi'
      }
    : {
        tag: RouterNavLink,
        to: PATH_FRONTPAGE
      }
  return (
    <NavLink
      {...linkProps}
      className='py-1 ps-0'
    >
      <img
        src={logo}
        alt='Evelia logo'
        className='app-logo'
      />
    </NavLink>
  )
}

AppNavLogo.propTypes = {
  isLinkToMarketingSite: PropTypes.bool
}

export default AppNavLogo
