import { createApi, defaultNormalizer } from '../helpers/apiHelpers'

export const employeeSubcontractorsApi = createApi({
  base: 'employees',
  editGetParams: params => ({ ...params, subItem: 'subcontractors' }),
  editMutateParams: params => ({ ...params, subItem: 'subcontractors' }),
  normalizer: defaultNormalizer
})

export default employeeSubcontractorsApi
