import { parseTemplate } from 'url-template'

import constants from '../constants'
import { actionCreatorsFor } from '../helpers/actionHelpers'
import { PATH_PRODUCTS_QUICK_PRODUCTS } from '../routes'
import { navigateTo } from './navigateActions'

const quickUrlTemplate = parseTemplate(`${PATH_PRODUCTS_QUICK_PRODUCTS}{/quickProductId}`)
export const getUrl = (quickProductId, params) => quickUrlTemplate.expand({ quickProductId: quickProductId || 'new', ...params })

const baseActionCreators = actionCreatorsFor(constants.actionKeys.QUICK_PRODUCTS)

const additionalActionCreators = {
  navigateTo: (quickProductId, replaceUrl, params) => navigateTo(getUrl(quickProductId, params), replaceUrl),
  navigateToBase: replaceUrl => navigateTo(quickUrlTemplate.expand({}), replaceUrl)
}

const quickProductActions = { ...baseActionCreators, ...additionalActionCreators }
export default quickProductActions
