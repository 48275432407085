import { call, put, takeEvery } from 'redux-saga/effects'

import fillOutFormActions from '../actions/fillOutFormActions'
import {
  createFillOutForm,
  createFillOutFormPdf,
  createFillOutFormSimplePdf,
  deleteFillOutForm,
  fetchFillOutForm,
  updateFillOutForm
} from '../api/fillOutFormApi'
import { actionTypes } from '../constants'
import {
  createFlow,
  createSocketWatcher,
  deleteFlow,
  genericSagaErrorHandler,
  getPromiseHandlersFromData,
  updateFlow
} from '../helpers/sagaHelpers'

const watchOnFillOutFormSockets = createSocketWatcher('fillOutForm', {
  created: fillOutFormActions.createSuccess,
  updated: fillOutFormActions.updateSuccess
})

function* fillOutFormFetchFlow({ data = {} }) {
  const { workId } = data
  try {
    yield put(fillOutFormActions.fetchStart())
    const fillOutForms = yield call(fetchFillOutForm, { workId })
    yield put(fillOutFormActions.fetchSuccess(fillOutForms))
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe lomakkeen noutamisessa')
  }
}

const fillOutFormUpdateFlow = updateFlow(updateFillOutForm, fillOutFormActions, 'Lomake', 'Lomakkeen')
const fillOutFormCreateFlow = createFlow(createFillOutForm, fillOutFormActions, 'Lomake', 'Lomakkeen')
const fillOutFormDeleteFlow = deleteFlow({
  deleteApi: deleteFillOutForm,
  actions: fillOutFormActions,
  singular: 'Lomake',
  errorMsg: 'Lomakkeen',
  base: 'fillOutForm'
})

function* fillOutFormPdfPostActionFlow({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    const response = yield call(data.isOldFillOutForm ? createFillOutFormSimplePdf : createFillOutFormPdf, record, data)
    yield call(resolve, response)
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Pdf:n luonti epäonnistui', reject)
  }
}

export default function* fillOutFormSaga() {
  yield takeEvery(fillOutFormActions.actionTypes.fetchRequest, fillOutFormFetchFlow)
  yield takeEvery(fillOutFormActions.actionTypes.createRequest, fillOutFormCreateFlow)
  yield takeEvery(fillOutFormActions.actionTypes.updateRequest, fillOutFormUpdateFlow)
  yield takeEvery(fillOutFormActions.actionTypes.deleteRequest, fillOutFormDeleteFlow)

  yield takeEvery(actionTypes.FILL_OUT_FORM_PDF_POST_REQUEST, fillOutFormPdfPostActionFlow)

  yield watchOnFillOutFormSockets()
}
