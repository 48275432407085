import { useCallback } from 'react'
import PropTypes from 'prop-types'

import inboundInvoiceActions from '../../actions/inboundInvoiceActions'
import useActions from '../../hooks/useActions'
import CancelButton from '../Buttons/CancelButton'
import SaveButton from '../Buttons/SaveButton'
import ActionPromptModal from './ActionPromptModal'

const componentActions = {
  unassignWork: inboundInvoiceActions.assignInboundInvoiceRowsRequest
}

const body = 'Poistetaanko myös ostolaskurivin/rivien kohdistus tälle työlle?'

const UnassignWorkFromInboundInvoiceRowPrompt = ({ visible, data, hideActionPrompt }) => {
  const actions = useActions(componentActions)

  const unassignWork = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const { inboundInvoiceId, inboundInvoiceRowIds } = data
        actions.unassignWork({ inboundInvoiceRowIds, workId: null }, { inboundInvoiceId, resolve, reject })
      })
        .then(() => hideActionPrompt()),
    [data, actions, hideActionPrompt]
  )

  return (
    <ActionPromptModal
      visible={visible}
      onCancel={hideActionPrompt}
      renderBody={() => body}
      renderFooter={() => (
        <>
          <SaveButton condensed onClick={unassignWork}>OK</SaveButton>
          <CancelButton condensed className='ml-3' outline onClick={hideActionPrompt}>Ei</CancelButton>
        </>
      )}
    />
  )
}

UnassignWorkFromInboundInvoiceRowPrompt.propTypes = {
  visible: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    inboundInvoiceId: PropTypes.number.isRequired,
    inboundInvoiceRowIds: PropTypes.arrayOf(PropTypes.number.isRequired)
  }),
  hideActionPrompt: PropTypes.func.isRequired
}

export default UnassignWorkFromInboundInvoiceRowPrompt
