import { io } from 'socket.io-client'

const socket = io({
  autoConnect: false,
  transports: ['websocket']
})

export const getSocket = () => socket

export const connectSocket = () => {
  socket.open()
}

let currentLeimaaSiteId = null

export const connectLeimaaSocket = siteId => {
  socket.auth = { siteId }
  if(currentLeimaaSiteId !== siteId) {
    socket.disconnect()
  }
  currentLeimaaSiteId = siteId
  socket.open()
}
