import constants from '../constants'
import { getCombinedListReducerFor, getTableOptions } from '../helpers/reducerHelpers'
import tableReducerFor from './tableReducer'

export const initialWorkRecordTableOptions = {
  default: getTableOptions(),
  supervisor: getTableOptions({ fixedFilters: { supervisor: true } })
}

const initialSalaryEventTableOptions = {
  default: getTableOptions({ orderBy: 'salaryEventId', filters: { onlyUnapproved: true } })
}

export default getCombinedListReducerFor(constants.actionKeys.WORK_RECORDS, {
  salaryEvents: getCombinedListReducerFor(constants.actionKeys.WORK_RECORD_SALARY_EVENTS, {
    tableOptions: tableReducerFor(constants.actionKeys.WORK_RECORD_SALARY_EVENTS, initialSalaryEventTableOptions)
  }, '_id'),
  memos: getCombinedListReducerFor(constants.actionKeys.WORK_RECORD_MEMOS),
  tableOptions: tableReducerFor(constants.actionKeys.WORK_RECORDS, initialWorkRecordTableOptions)
})
