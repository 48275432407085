import { all, put, takeEvery, takeLatest } from 'redux-saga/effects'

import denominationActions from '../actions/denominationActions'
import {
  denominationApi,
  denominationGroupApi,
  denominationPacketApi,
  normalizeDenominations
} from '../api/denominationApi'
import { actionTypes } from '../constants'
import {
  createActionFlow,
  createFlow,
  createSocketWatcherWithApiHandlerAndNormalizer,
  defaultApiResponseHandler,
  fetchFlow,
  searchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

const reduxName = 'denominations'

const handleDenominationApiResponse = (mainAction, tableIdentifier) =>
  function* ({ data, denominationGroups, tableOptions }) {
    yield put(mainAction(data))
    yield put(denominationActions.denominationGroups.fetchSuccess(denominationGroups))

    if(tableIdentifier != null && tableOptions && tableOptions.orderBy != null) {
      yield put(denominationActions.tableActions.updateOptions(tableOptions, tableIdentifier))
    }
    return data
  }

const watchOnDenominationSockets = createSocketWatcherWithApiHandlerAndNormalizer('denomination', denominationActions, handleDenominationApiResponse, normalizeDenominations)

const denominationFetchFlow = fetchFlow({
  fetchApi: denominationApi.fetch,
  actions: denominationActions,
  base: reduxName,
  idField: 'id',
  errorMsg: 'litteroiden',
  getApiResponseHandler: data => handleDenominationApiResponse(denominationActions.fetchSuccess, data.tableIdentifier)
})

const denominationUpdateFlow = updateFlow(denominationApi.update, denominationActions, 'Littera', 'Litteran', handleDenominationApiResponse(denominationActions.updateSuccess))
const denominationCreateFlow = createFlow(denominationApi.create, denominationActions, 'Littera', 'Litteran', handleDenominationApiResponse(denominationActions.createSuccess))
const denominationSearchFlow = searchFlow(denominationApi.search, denominationActions, 'Litteroiden', function* (data, searchTerm) {
  const denominations = yield handleDenominationApiResponse(denominationActions.fetchSuccess)(data)
  yield put(denominationActions.searchSuccess(denominations, searchTerm))
  return denominations
})

const denominationGroupFetchFlow = fetchFlow({
  fetchApi: denominationGroupApi.fetch,
  actions: denominationActions.denominationGroups,
  base: `${reduxName}.denominationGroups`,
  idField: 'id',
  errorMsg: 'litteraryhmien',
  getApiResponseHandler: () => defaultApiResponseHandler(denominationActions.denominationGroups.fetchSuccess)
})

const denominationGroupUpdateFlow = updateFlow(denominationGroupApi.update, denominationActions.denominationGroups, 'Litteraryhmä', 'Litteraryhmän', defaultApiResponseHandler(denominationActions.denominationGroups.updateSuccess))
const denominationGroupCreateFlow = createFlow(denominationGroupApi.create, denominationActions.denominationGroups, 'Litteraryhmä', 'Litteraryhmän', defaultApiResponseHandler(denominationActions.denominationGroups.createSuccess))

const denominationPacketFetchFlow = fetchFlow({
  fetchApi: denominationPacketApi.fetch,
  actions: denominationActions.denominationPackets,
  base: `${reduxName}.denominationPackets`,
  idField: 'id',
  errorMsg: 'litteraryhmien',
  getApiResponseHandler: () => defaultApiResponseHandler(denominationActions.denominationPackets.fetchSuccess)
})

const denominationPacketUpdateFlow = updateFlow(denominationPacketApi.update, denominationActions.denominationPackets, 'Nimikkeistö', 'Nimikkeistön', defaultApiResponseHandler(denominationActions.denominationPackets.updateSuccess))
const denominationPacketCreateFlow = createFlow(denominationPacketApi.create, denominationActions.denominationPackets, 'Nimikkeistö', 'Nimikkeistön', defaultApiResponseHandler(denominationActions.denominationPackets.createSuccess))

const denominationPacketPostActionFlow = createActionFlow(denominationPacketApi.create, denominationActions.denominationPackets, defaultApiResponseHandler)

export default function* denominationSaga() {
  yield takeEvery(denominationActions.actionTypes.fetchRequest, denominationFetchFlow)
  yield takeEvery(denominationActions.actionTypes.updateRequest, denominationUpdateFlow)
  yield takeEvery(denominationActions.actionTypes.createRequest, denominationCreateFlow)
  yield takeLatest(denominationActions.actionTypes.searchRequest, denominationSearchFlow)

  yield takeLatest(denominationActions.denominationGroups.actionTypes.fetchRequest, denominationGroupFetchFlow)
  yield takeEvery(denominationActions.denominationGroups.actionTypes.updateRequest, denominationGroupUpdateFlow)
  yield takeEvery(denominationActions.denominationGroups.actionTypes.createRequest, denominationGroupCreateFlow)

  yield takeLatest(denominationActions.denominationPackets.actionTypes.fetchRequest, denominationPacketFetchFlow)
  yield takeEvery(denominationActions.denominationPackets.actionTypes.updateRequest, denominationPacketUpdateFlow)
  yield takeEvery(denominationActions.denominationPackets.actionTypes.createRequest, denominationPacketCreateFlow)

  yield takeEvery(actionTypes.DENOMINATION_PACKETS_POST_ACTION_REQUEST, denominationPacketPostActionFlow)

  yield all([
    watchOnDenominationSockets()
  ])
}
