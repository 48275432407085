import { useCallback, useEffect, useRef } from 'react'

const useCallParseParam = (func, param) => {
  const refFunc = useRef(func)

  // Prevents callWithParam function from being initialized on each render
  useEffect(() => {
    refFunc.current = func
  }, [func])

  const callWithParam = useCallback((object, ...args) => refFunc.current(object[param], ...args), [param])
  return callWithParam
}

export const useCallParseId = func => useCallParseParam(func, 'id')
