import { miniSerializeError } from '@reduxjs/toolkit'
import { push, replace } from 'redux-first-history'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import configActions from '../actions/configActions'
import loginActions from '../actions/loginActions'
import { showActionPrompt } from '../actions/uiActions'
import {
  doLogin,
  doLogout,
  doRegistration,
  linkSystemCustomer,
  resetPw,
  resetPwRequest,
  switchSystemCustomer
} from '../api/loginApi'
import { actionTypes, uiTypes } from '../constants'
import { addErrorNotification, addSuccessNotification } from '../helpers/notificationHelpers'
import {
  clearSagaError,
  createSocketWatcherWithGenerator,
  genericSagaErrorHandler,
  getPromiseHandlersFromData,
  handleSagaError
} from '../helpers/sagaHelpers'
import { handleWhoAmI, handleWhoAmIError } from './whoAmISaga'

const watchOnSystemCustomerSockets = createSocketWatcherWithGenerator('systemCustomer', {
  switch: data => systemCustomerSwitchSocketHandler(data) // showActionPrompt(uiTypes.PROMPT_SWITCH_SYSTEM_CUSTOMER, data)
})

function* systemCustomerSwitchSocketHandler(data) {
  try {
    const currentSystemCustomer = yield select(state => state.systemCustomer.settingsData.details)
    if(data.to === currentSystemCustomer.id) {
      return
    }
    yield put(showActionPrompt(uiTypes.PROMPT_SWITCH_SYSTEM_CUSTOMER, data))
  } catch(err) {
    addErrorNotification('Yrityksen vaihto epäonnistui')
    yield put(loginActions.switchSystemCustomerError(miniSerializeError(err)))
  }
}

function* loginFlow({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(loginActions.loginStart(record))
    const returnData = yield call(doLogin, record)
    yield put(loginActions.loginSuccess(returnData))
    addSuccessNotification('Sisäänkirjautuminen onnistui')
    yield call(resolve, returnData)
    if(data?.redirect) {
      yield put(push('/'))
    }
    yield * clearSagaError()
  } catch(err) {
    yield * handleSagaError(err, true)
    addErrorNotification(`Sisäänkirjautuminen epäonnistui: ${err?.json?.message ?? 'Käyttäjätunnus/salasana väärin'}`)
    yield put(loginActions.loginError(miniSerializeError(err)))
    yield call(reject, err)
  }
}

function* logoutFlow({ data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(loginActions.logoutStart())
    yield call(doLogout)
    yield put(loginActions.logoutSuccess())
    addSuccessNotification('Kirjauduttu ulos')

    const err = new Error('Unauthorized')
    err.json = { message: 'Not logged in', globalError: true, handled: true, status: 401 }
    yield * handleWhoAmIError(err)
    yield put(push('/'))
    yield call(resolve)
  } catch(err) {
    yield put(loginActions.logoutError(miniSerializeError(err)))
    yield * genericSagaErrorHandler(err, 'Uloskirjautuminen epäonnistui', reject)
  }
}

function* registrationFlow({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(loginActions.registerStart())
    const returnData = yield call(doRegistration, record)
    yield put(loginActions.registerSuccess(returnData))
    addSuccessNotification('Käyttäjätunnus luotu. Voit nyt kirjautua sisään.')
    yield put(push('/'))
    yield call(resolve, returnData)
  } catch(err) {
    yield * handleSagaError(err, true)
    addErrorNotification('Käyttäjätunnuksen luonti epäonnistui')
    yield put(loginActions.registerError(miniSerializeError(err)))
    yield call(reject, err)
  }
}

function* linkSystemCustomerFlow({ record, data }) {
  const currentLocation = yield select(state => state.router.location)
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(push('/'))
    yield put(loginActions.linkSystemCustomerStart())
    const returnData = yield call(linkSystemCustomer, record)
    yield put(loginActions.linkSystemCustomerSuccess(returnData))
    yield * handleWhoAmI(returnData)
    addSuccessNotification('Yritys vaihdettu')
    yield call(resolve, returnData)
  } catch(err) {
    yield put(replace(currentLocation))
    yield put(loginActions.linkSystemCustomerError(miniSerializeError(err)))
    yield * genericSagaErrorHandler(err, 'Yrityksen linkitys epäonnistui', reject)
  }
}

function* systemCustomerSwitchFlow({ record, data }) {
  const currentLocation = yield select(state => state.router.location)
  try {
    yield put(push('/'))
    yield put(loginActions.switchSystemCustomerStart())
    const returnData = yield call(switchSystemCustomer, record)
    yield put(loginActions.switchSystemCustomerSuccess(returnData))
    yield * handleWhoAmI(returnData)
    addSuccessNotification('Yritys vaihdettu')
    yield put(configActions.fetchRequest())
  } catch(err) {
    yield put(replace(currentLocation))
    yield put(loginActions.switchSystemCustomerError(miniSerializeError(err)))
    yield * genericSagaErrorHandler(err, 'Yrityksen vaihto epäonnistui')
  }
}

function* resetPwRequestFlow({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(loginActions.passwordResetRequestStart())
    yield call(resetPwRequest, record)
    yield put(loginActions.passwordResetRequestSuccess())
    addSuccessNotification('Salasanan nollausviesti lähetetty')
    yield put(push('/'))
    yield call(resolve)
  } catch(err) {
    yield put(loginActions.passwordResetRequestError(miniSerializeError(err)))
    yield * genericSagaErrorHandler(err, 'Salasanan nollausviestin lähetys epäonnistui', reject)
  }
}

function* resetPwFlow({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(loginActions.passwordResetStart())
    yield call(resetPw, record)
    yield put(loginActions.passwordResetSuccess())
    addSuccessNotification('Salasana nollattu. Voit kirjautua sisään uudella salasanalla.')
    yield put(push('/'))
    yield call(resolve)
  } catch(err) {
    yield put(loginActions.passwordResetError(miniSerializeError(err)))
    yield * genericSagaErrorHandler(err, 'Salasanan nollaus epäonnistui', reject)
  }
}

export default function* loginSaga() {
  yield takeLatest(actionTypes.LOGIN_REQUEST, loginFlow)
  yield takeLatest(actionTypes.LOGOUT_REQUEST, logoutFlow)
  yield takeLatest(actionTypes.REGISTER_REQUEST, registrationFlow)
  yield takeLatest(actionTypes.SWITCH_SYSTEM_CUSTOMER_REQUEST, systemCustomerSwitchFlow)
  yield takeLatest(actionTypes.LINK_SYSTEM_CUSTOMER_REQUEST, linkSystemCustomerFlow)
  yield takeLatest(actionTypes.PASSWORD_RESET_REQUEST_REQUEST, resetPwRequestFlow)
  yield takeLatest(actionTypes.PASSWORD_RESET_REQUEST, resetPwFlow)

  yield watchOnSystemCustomerSockets()
}
