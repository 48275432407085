import { createApi, defaultNormalizer } from '../helpers/apiHelpers'

export const contactExtraApi = createApi({
  base: 'contacts',
  editGetParams: params => ({ ...params, subItem: 'extras' }),
  editMutateParams: params => ({ ...params, subItem: 'extras' }),
  normalizer: defaultNormalizer
})

export default contactExtraApi
