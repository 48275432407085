import { createSelector } from 'reselect'

import { mapRecordNameOptions } from '../helpers/helpers'
import { getFindItemByIdSelector, getFindItemsByIdsSelector } from '../helpers/selectorHelpers'

export const getCustomerGroupsFromArgument = arg => arg.customerGroups ? arg.customerGroups.records : arg

export const findCustomerGroupWithId = getFindItemByIdSelector(getCustomerGroupsFromArgument)
export const findCustomerGroupWithIds = getFindItemsByIdsSelector(getCustomerGroupsFromArgument)

export const getCustomerGroupOptions = createSelector(
  getCustomerGroupsFromArgument,
  customerGroups => mapRecordNameOptions(customerGroups)
)
