import { all, put, takeEvery, takeLatest } from 'redux-saga/effects'

import contactActions from '../actions/contactActions'
import customerActions from '../actions/customerActions'
import fileActions from '../actions/fileActions'
import targetActions from '../actions/targetActions'
import {
  createTarget,
  createTargetContact,
  createTargetFile,
  deleteTarget,
  deleteTargetContact,
  deleteTargetFile,
  fetchTargetContacts,
  fetchTargetFiles,
  fetchTargets,
  fetchTargetStats,
  normalizeTargets,
  searchTargets,
  updateTarget
} from '../api/targetApi'
import { getMemoSagas } from '../helpers/generalSagas'
import {
  createFlow,
  createSocketWatcher,
  createSocketWatcherWithApiHandlerAndNormalizer,
  deleteFlow,
  fetchFlow,
  fetchStatsFlow,
  getSubEntitySagas,
  searchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

const handleTargetApiResponse = mainAction =>
  function* ({
    data,
    customers,
    contacts,
    parents,
    tableOptions
  }) {
    yield put(customerActions.fetchSuccess(customers))
    yield put(contactActions.fetchSuccess(contacts))
    if(parents && parents.length) {
      yield put(targetActions.fetchSuccess(parents))
    }
    yield put(mainAction(data))
    if(tableOptions && tableOptions.orderBy != null) {
      yield put(targetActions.tableActions.updateOptions(tableOptions))
    }
    return data
  }

const watchOnTargetSockets = createSocketWatcherWithApiHandlerAndNormalizer('target', targetActions, handleTargetApiResponse, normalizeTargets)

const watchOnTargetContactSockets = createSocketWatcher('targetContact', {
  created: targetActions.contacts.createSuccess,
  updated: targetActions.contacts.updateSuccess
})

export const targetFetchFlow = fetchFlow({
  fetchApi: fetchTargets,
  actions: targetActions,
  base: 'targets',
  idField: 'targetId',
  errorMsg: 'kohteiden',
  apiResponseHandler: handleTargetApiResponse(targetActions.fetchSuccess)
})

const targetUpdateFlow = updateFlow(updateTarget, targetActions, 'Kohde', 'Kohteen', handleTargetApiResponse(targetActions.updateSuccess))
const targetCreateFlow = createFlow(createTarget, targetActions, 'Kohde', 'Kohteen', handleTargetApiResponse(targetActions.createSuccess))
const targetSearchFlow = searchFlow(searchTargets, targetActions, 'Kohteiden', function* (data, searchTerm) {
  const targets = yield handleTargetApiResponse(targetActions.fetchSuccess)(data)
  yield put(targetActions.searchSuccess(targets, searchTerm))
  return targets
})
const targetDeleteFlow = deleteFlow({
  deleteApi: deleteTarget,
  actions: targetActions,
  singular: 'Kohde',
  errorMsg: 'Kohteen',
  base: 'targets'
})

export { targetUpdateFlow, targetCreateFlow, targetSearchFlow }

const contactFlows = getSubEntitySagas(targetActions, contactActions, fetchTargetContacts, createTargetContact, deleteTargetContact, 'targetId', 'contactId', 'targets', 'contacts', {
  singular: 'Kohteen yhteyshenkilö',
  accusative: 'Kohteen yhteyshenkilön',
  fetchError: 'Virhe kohteen yhteyshenkilöiden noutamisessa',
  deleteSuccess: 'Kohteen yhteyshenkilö poistettu',
  deleteError: 'Virhe kohteen yhteyshenkilön poistamisessa'
}, function* (response) {
  const { targetContacts, contacts } = response
  if(targetContacts || contacts) {
    yield put(targetActions.contacts.fetchSuccess(targetContacts))
    yield put(contactActions.fetchSuccess(contacts))
  } else {
    yield put(targetActions.contacts.fetchSuccess(response)) // POST response
  }
})

const fileFlows = getSubEntitySagas(targetActions, fileActions, fetchTargetFiles, createTargetFile, deleteTargetFile, 'targetId', 'fileId', 'targets', 'files', {
  singular: 'Kohteen tiedosto',
  accusative: 'Kohteen tiedoston',
  fetchError: 'Virhe kohteen tiedostojen noutamisessa',
  deleteSuccess: 'Kohteen tiedostolinkki poistettu',
  deleteError: 'Virhe kohteen tiedostolinkin poistamisessa'
})

const targetStatsFetchFlow = fetchStatsFlow(targetActions, fetchTargetStats, 'targetId')

const memoSagas = getMemoSagas({
  actions: targetActions,
  baseName: 'targets',
  socketName: 'target',
  titleGenetive: 'kohteen'
})

export default function* targetSaga() {
  yield takeLatest(targetActions.actionTypes.fetchRequest, targetFetchFlow)
  yield takeEvery(targetActions.actionTypes.updateRequest, targetUpdateFlow)
  yield takeEvery(targetActions.actionTypes.createRequest, targetCreateFlow)
  yield takeLatest(targetActions.actionTypes.searchRequest, targetSearchFlow)
  yield takeEvery(targetActions.actionTypes.deleteRequest, targetDeleteFlow)

  yield takeLatest(targetActions.contacts.actionTypes.fetchRequest, contactFlows.subFetchFlow)
  yield takeEvery(targetActions.contacts.actionTypes.createRequest, contactFlows.subCreateFlow)
  yield takeLatest(targetActions.contacts.actionTypes.deleteRequest, contactFlows.subDeleteFlow)

  yield takeLatest(targetActions.files.actionTypes.fetchRequest, fileFlows.subFetchFlow)
  yield takeEvery(targetActions.files.actionTypes.createRequest, fileFlows.subCreateFlow)
  yield takeLatest(targetActions.files.actionTypes.deleteRequest, fileFlows.subDeleteFlow)

  yield takeLatest(targetActions.actionTypes.fetchStatsRequest, targetStatsFetchFlow)

  yield all([
    memoSagas(),
    watchOnTargetSockets(),
    watchOnTargetContactSockets()
  ])
}
