import { actionTypes } from '../constants'
import { error, request, start, success } from '../helpers/actionHelpers'

const loginActions = {
  loginRequest: request(actionTypes.LOGIN_REQUEST),
  loginStart: start(actionTypes.LOGIN_START),
  loginSuccess: success(actionTypes.LOGIN_SUCCESS),
  loginError: error(actionTypes.LOGIN_ERROR),

  logoutRequest: request(actionTypes.LOGOUT_REQUEST),
  logoutStart: start(actionTypes.LOGOUT_START),
  logoutSuccess: success(actionTypes.LOGOUT_SUCCESS),
  logoutError: error(actionTypes.LOGOUT_ERROR),

  registerRequest: request(actionTypes.REGISTER_REQUEST),
  registerStart: start(actionTypes.REGISTER_START),
  registerSuccess: success(actionTypes.REGISTER_SUCCESS),
  registerError: error(actionTypes.REGISTER_ERROR),

  switchSystemCustomerRequest: request(actionTypes.SWITCH_SYSTEM_CUSTOMER_REQUEST),
  switchSystemCustomerStart: start(actionTypes.SWITCH_SYSTEM_CUSTOMER_START),
  switchSystemCustomerSuccess: success(actionTypes.SWITCH_SYSTEM_CUSTOMER_SUCCESS),
  switchSystemCustomerError: error(actionTypes.SWITCH_SYSTEM_CUSTOMER_ERROR),

  linkSystemCustomerRequest: request(actionTypes.LINK_SYSTEM_CUSTOMER_REQUEST),
  linkSystemCustomerStart: start(actionTypes.LINK_SYSTEM_CUSTOMER_START),
  linkSystemCustomerSuccess: success(actionTypes.LINK_SYSTEM_CUSTOMER_SUCCESS),
  linkSystemCustomerError: error(actionTypes.LINK_SYSTEM_CUSTOMER_ERROR),

  passwordResetRequestRequest: request(actionTypes.PASSWORD_RESET_REQUEST_REQUEST),
  passwordResetRequestStart: start(actionTypes.PASSWORD_RESET_REQUEST_START),
  passwordResetRequestSuccess: success(actionTypes.PASSWORD_RESET_REQUEST_SUCCESS),
  passwordResetRequestError: error(actionTypes.PASSWORD_RESET_REQUEST_ERROR),

  passwordResetRequest: request(actionTypes.PASSWORD_RESET_REQUEST),
  passwordResetStart: start(actionTypes.PASSWORD_RESET_START),
  passwordResetSuccess: success(actionTypes.PASSWORD_RESET_SUCCESS),
  passwordResetError: error(actionTypes.PASSWORD_RESET_ERROR)
}

export default loginActions
