import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Badge } from 'reactstrap'

import CommentEur from '../../components/Icons/CommentEur'
import TooltipBase from '../../components/Tooltip/TooltipBase'
import { accountingStatuses, inboundInvoiceCostTypes, inboundInvoiceStates, vatTypeTitles } from '../../constants'
import { formatEur } from '../../helpers/helpers'
import { inboundInvoicePropType } from '../../propTypes'

const partialStates = [inboundInvoiceStates.INBOUND_INVOICE_PAYMENT_STATE_WAITING, inboundInvoiceStates.INBOUND_INVOICE_PAYMENT_STATE_PARTIALLY_PAID]

const paymentStateMap = {
  [inboundInvoiceStates.INBOUND_INVOICE_PAYMENT_STATE_WAITING_PERMISSION]: { title: 'Odottaa maksulupaa', color: 'danger' },
  [inboundInvoiceStates.INBOUND_INVOICE_PAYMENT_STATE_WAITING]: { title: 'Odottaa maksatusta', color: 'info' },
  [inboundInvoiceStates.INBOUND_INVOICE_PAYMENT_STATE_PARTIALLY_PAID]: { title: 'Osittain maksettu', color: 'warning' },
  [inboundInvoiceStates.INBOUND_INVOICE_PAYMENT_STATE_PAID]: { title: 'Maksettu', color: 'success' },
  [inboundInvoiceStates.INBOUND_INVOICE_PAYMENT_STATE_PAID_MANUALLY]: { title: 'Maksettu käsin', color: 'success' },
  [inboundInvoiceStates.INBOUND_INVOICE_PAYMENT_STATE_OVERPAID]: { title: 'Ylisuoritettu', color: 'danger' },
  [inboundInvoiceStates.INBOUND_INVOICE_PAYMENT_STATE_FAILED]: { title: 'Epäonnistunut', color: 'danger' }
}

const PaymentStateBadge = ({ inboundInvoiceId, paymentState, unapprovedSum, banksonState }) => {
  const getPaymentStateBadge = useMemo(() => {
    const data = { ...(paymentStateMap[paymentState] ?? { title: paymentState, color: 'danger' }) }
    if(banksonState === accountingStatuses.ACCOUNTING_STATUS_WAITING_PAYMENT) {
      data.title = <i>{data.title}<TooltipBase id={inboundInvoiceId} TargetComponent={CommentEur}>Vähintään yksi maksuerä odottaa maksupäivää</TooltipBase></i>
    }
    return <Badge color={data.color}>{data.title}</Badge>
  }, [banksonState, inboundInvoiceId, paymentState])
  const getPartialPaymentStateBadge = useMemo(() => {
    if(partialStates.includes(paymentState) && unapprovedSum !== 0) {
      return <Badge color='danger' title={`${formatEur(unapprovedSum)} ilman maksulupaa`}>Osittainen maksulupa</Badge>
    }
    return null
  }, [paymentState, unapprovedSum])
  return (
    <>
      {getPaymentStateBadge}
      {getPartialPaymentStateBadge}
    </>
  )
}

PaymentStateBadge.propTypes = {
  paymentState: PropTypes.string.isRequired,
  unapprovedSum: PropTypes.number.isRequired,
  banksonState: PropTypes.string,
  inboundInvoiceId: PropTypes.number.isRequired
}

export const inboundInvoiceStatusBadgeTypes = {
  ARCHIVE: 'archive',
  RECURRING: 'recurring',
  STATE: 'state',
  PAYMENT_STATE: 'paymentState',
  ACCOUNTING_STATE: 'accountingState',
  COST_TYPE: 'costType',
  VAT_TYPE: 'vatType'
}

const InboundInvoiceStatusBadge = ({ inboundInvoice, type = 'state' }) => {
  if(!inboundInvoice) {
    return null
  }
  switch(type) {
    case inboundInvoiceStatusBadgeTypes.ARCHIVE:
      if(inboundInvoice.archivedAt) {
        return <Badge color='info'>Arkistoitu</Badge>
      } else {
        return null
      }
    case inboundInvoiceStatusBadgeTypes.RECURRING:
      if(inboundInvoice.templateInboundInvoiceId) {
        return <Badge color='secondary'>Toistuva</Badge>
      } else {
        return null
      }
    case inboundInvoiceStatusBadgeTypes.STATE:
      switch(inboundInvoice.state) {
        case inboundInvoiceStates.INBOUND_INVOICE_STATE_RECEIVED:
          return <Badge color='info'>Perustiedot noudettu</Badge>
        case inboundInvoiceStates.INBOUND_INVOICE_STATE_RECEIVED_FULL:
          return <Badge color={inboundInvoice.isValidPayable ? 'info' : 'danger'}>{inboundInvoice.isValidPayable ? 'Käsittelemätön' : 'Puutteellinen'}</Badge>
        case inboundInvoiceStates.INBOUND_INVOICE_STATE_WAITING_APPROVAL:
          return <Badge color='warning'>Odottaa hyväksyntää</Badge>
        case inboundInvoiceStates.INBOUND_INVOICE_STATE_REJECTED:
          return <Badge color='danger'>Hylätty</Badge>
        case inboundInvoiceStates.INBOUND_INVOICE_STATE_APPROVED:
          return <Badge color='success'>Hyväksytty</Badge>
        default:
          return <Badge color='danger'>{inboundInvoice.state}</Badge>
      }
    case inboundInvoiceStatusBadgeTypes.PAYMENT_STATE:
      return (
        <PaymentStateBadge
          inboundInvoiceId={inboundInvoice.id}
          paymentState={inboundInvoice.paymentState}
          unapprovedSum={inboundInvoice.unapprovedSum}
          banksonState={inboundInvoice.banksonState}
        />
      )
    case inboundInvoiceStatusBadgeTypes.ACCOUNTING_STATE:
      switch(inboundInvoice.accountingState) {
        case inboundInvoiceStates.INBOUND_INVOICE_ACCOUNTING_STATE_WAITING:
          return <Badge color='danger'>Tiliöimätön</Badge>
        case inboundInvoiceStates.INBOUND_INVOICE_ACCOUNTING_STATE_PARTIALLY:
          return <Badge color='warning'>Osittain tiliöity</Badge>
        case inboundInvoiceStates.INBOUND_INVOICE_ACCOUNTING_STATE_DONE:
          return <Badge color='success'>Tiliöity</Badge>
        default:
          return <Badge color='danger'>{inboundInvoice.accountingState}</Badge>
      }
    case inboundInvoiceStatusBadgeTypes.COST_TYPE:
      switch(inboundInvoice.costType) {
        case inboundInvoiceCostTypes.INBOUND_INVOICE_COST_TYPE_SUBCONTRACTING:
          return <Badge color='info'>Alihankinta</Badge>
        default:
          return null
      }
    case inboundInvoiceStatusBadgeTypes.VAT_TYPE:
      if(inboundInvoice.vatType) {
        return <Badge color='warning'>{vatTypeTitles[inboundInvoice.vatType]}</Badge>
      } else {
        return null
      }
    default:
      return null
  }
}
InboundInvoiceStatusBadge.propTypes = {
  inboundInvoice: inboundInvoicePropType,
  type: PropTypes.oneOf(Object.values(inboundInvoiceStatusBadgeTypes))
}

export default InboundInvoiceStatusBadge
