import { ColumnMeta, flexRender, Header } from '@tanstack/react-table'
import { clsx } from 'clsx'

interface DataTableFooterProps<TData, TValue> {
  footer: Header<TData, TValue>
}

const DataTableFooter = <TData, TValue = unknown>({
  footer
}: DataTableFooterProps<TData, TValue>) => {
  const {
    colProps: { className: colClassName, ...colProps } = {},
    footerProps: { className: footerClassName, getClassName: getFooterClassName, boldClassName, ...footerProps } = {}
  } = footer.column.columnDef.meta ?? {} as ColumnMeta<TData, TValue>

  return (
    <div
      key={footer.id}
      role='columnheader'
      tabIndex={-1}
      className={clsx('dt-th text-ellipsis px-2',
        colClassName,
        footerClassName,
        getFooterClassName?.(footer.getContext())
      )}
      onClick={footer.column.getToggleSortingHandler()}
      onKeyDown={footer.column.getToggleSortingHandler()}
      style={{
        width: footer.getSize()
      }}
      {...colProps}
      {...footerProps}
    >
      <b className={clsx('px-1', boldClassName)}>
        {footer.isPlaceholder
          ? null
          : flexRender(
            footer.column.columnDef.footer,
            footer.getContext()
          )}
      </b>
    </div>
  )
}

export default DataTableFooter
