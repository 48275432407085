import { createApi } from '../helpers/apiHelpers'
import { optionsQueryBase } from './urlTemplates'

const normalizeMachineTypes = response => {
  const data = response.record || response.records
  return {
    data
  }
}

export const machineTypeApi = createApi({
  base: 'machine_types',
  normalizer: normalizeMachineTypes,
  extraUrlTemplate: optionsQueryBase
})
