import constants from '../constants'
import { getCombinedListReducerFor, getTableOptions } from '../helpers/reducerHelpers'
import tableReducerFor from './tableReducer'

export const initialPacketMatrixTableOptions = {
  default: getTableOptions()
}

export default getCombinedListReducerFor(constants.actionKeys.PACKET_MATRICES, {
  tableOptions: tableReducerFor(constants.actionKeys.PACKET_MATRICES, initialPacketMatrixTableOptions)
})
