import { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { DropdownItem, DropdownMenu, Input } from 'reactstrap'

import { findEmployeeSystemCustomers } from '../../selectors/systemCustomerSelectors'

const serializeInput = value => value.toLowerCase().replaceAll(' ', '')

const SystemCustomerDropdownList = ({ systemCustomerId, onClick }) => {
  const [filter, setFilter] = useState('')
  const employeeSystemCustomers = useSelector(findEmployeeSystemCustomers)

  if(!employeeSystemCustomers.length) {
    return null
  }
  const handleFilterChange = event => {
    const value = event.target.value
    setFilter(value)
  }

  const renderDropDownItem = employeeSystemCustomer => (
    <DropdownItem
      key={employeeSystemCustomer.id}
      disabled={employeeSystemCustomer.id === systemCustomerId}
      onClick={() => onClick(employeeSystemCustomer.id)}
    >
      <small>
        {employeeSystemCustomer.name}
      </small>
    </DropdownItem>
  )

  return (
    <DropdownMenu className='company-menu'>
      <DropdownItem header className='px-1 pt-0 mt-0'>
        <Input placeholder='Vaihda yritystä' value={filter} onChange={handleFilterChange} />
      </DropdownItem>
      {employeeSystemCustomers
        .filter(employeeSystemCustomer =>
          employeeSystemCustomer.id !== systemCustomerId &&
          (!filter || serializeInput(employeeSystemCustomer.name).includes(serializeInput(filter)))
        )
        .map(renderDropDownItem)}
    </DropdownMenu>
  )
}

SystemCustomerDropdownList.propTypes = {
  systemCustomerId: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
}

export default SystemCustomerDropdownList
