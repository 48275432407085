import { faRedo } from '@fortawesome/free-solid-svg-icons'

import IconButton from '../Buttons/IconButton'
import ErrorPage from './ErrorPage'

const OutdatedErrorPage = () => (
  <ErrorPage
    statusCode='Uusi versio'
    message={(
      <>
        Olemme julkaisseet Eveliasta päivitetyn version. <IconButton icon={faRedo} condensed color='info' onClick={() => window.location.reload()}>Päivitä</IconButton> selaimen sivu, jotta pääset käyttämään uutta versiota!<br />
      </>
    )}
  />
)

export default OutdatedErrorPage
