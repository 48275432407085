import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Input as RSInput } from 'reactstrap'
import get from 'lodash/get'

import FormInputGroupWrapper from '../hookForm/FormInputGroupWrapper'
import FormInputWrapper from '../hookForm/FormInputWrapper'

const DebouncedInput = ({
  value: initialValue,
  onChange,
  name,
  errors,
  label,
  inputProps,
  inputOnly,
  required,
  append,
  prepend,
  placeholder,
  debounce = 500,
  disabled,
  tooltipError,
  formGroupClassName,
  isCustomAddon,
  helpText
}) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [debounce, onChange, value])

  return (
    <FormInputWrapper
      name={name}
      label={label}
      inputOnly={inputOnly}
      required={required}
      formGroupClassName={formGroupClassName}
      helpText={helpText}
    >
      <FormInputGroupWrapper
        append={append}
        prepend={prepend}
        name={name}
        errors={errors}
        tooltipError={tooltipError}
        isCustomAddon={isCustomAddon}
      >
        <RSInput
          id={name}
          bsSize='sm'
          invalid={!!get(errors, name)}
          placeholder={placeholder}
          onChange={e => setValue(e.target.value)}
          value={value ?? ''}
          data-testid={name}
          {...inputProps}
          disabled={disabled}
        />
      </FormInputGroupWrapper>
    </FormInputWrapper>
  )
}

DebouncedInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  label: PropTypes.node,
  inputProps: PropTypes.object,
  inputOnly: PropTypes.bool,
  required: PropTypes.bool,
  append: PropTypes.node,
  prepend: PropTypes.node,
  placeholder: PropTypes.node,
  debounce: PropTypes.number,
  disabled: PropTypes.bool,
  tooltipError: PropTypes.bool,
  formGroupClassName: PropTypes.string,
  isCustomAddon: PropTypes.bool,
  helpText: PropTypes.node
}

export default DebouncedInput
