import PropTypes from 'prop-types'
import { useController } from 'react-hook-form'

import FormInput from './FormInput'
import FormPlainNumberInput from './FormPlainNumberInput'

const FormNumberInput = ({ control, errors, ...props }) => {
  const { field, formState } = useController({ name: props.name, control })
  const { value, onChange, ref, ...fieldProps } = field
  const inputErrors = formState.errors
  return (
    <FormPlainNumberInput
      {...props}
      value={value}
      errors={errors || inputErrors}
      onChange={onChange}
      inputProps={{
        innerRef: ref,
        ...props.inputProps,
        ...fieldProps
      }}
    />
  )
}

FormNumberInput.propTypes = {
  ...FormInput.propTypes,
  onValueChange: PropTypes.func
}

export default FormNumberInput
