import { miniSerializeError } from '@reduxjs/toolkit'
import {
  all,
  call,
  put,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'

import { formTemplateActions } from '../actions/formActions'
import workActions from '../actions/workActions'
import { formApi } from '../api/formApi'
import { fetchWorkFormTemplates, normalizeWorkForm } from '../api/workApi'
import { addSuccessNotification } from '../helpers/notificationHelpers'
import {
  createFlow,
  createSocketWatcherWithApiHandlerAndNormalizer,
  deleteFlow,
  fetchFlow,
  genericSagaErrorHandler,
  getGenericErrorMessage,
  getPromiseHandlersFromData,
  updateFlow
} from '../helpers/sagaHelpers'

const handleWorkFormTemplatesApiResponse = mainAction =>
  function* ({ data, workForms }) {
    yield put(mainAction(data))
    yield put(workActions.workForms.fetchSuccess(workForms))
    return data
  }
const handleWorkFormsApiResponse = mainAction =>
  function* ({ data }) {
    yield put(mainAction(data))
    return data
  }

const watchOnWorkFormSockets = createSocketWatcherWithApiHandlerAndNormalizer('formTemplateForm', workActions.workForms, handleWorkFormsApiResponse, normalizeWorkForm)

const workFormTemplatesFetchFlow = fetchFlow({
  fetchApi: fetchWorkFormTemplates,
  actions: workActions.workFormTemplates,
  base: 'work.workFormTemplates',
  errorMsg: 'Työn lomakepohjien',
  apiResponseHandler: handleWorkFormTemplatesApiResponse(formTemplateActions.fetchSuccess)
})

const workFormsFetchFlow = fetchFlow({
  fetchApi: formApi.fetch,
  actions: workActions.workForms,
  base: 'work.workForms',
  errorMsg: 'Työn lomakkeen',
  apiResponseHandler: handleWorkFormsApiResponse(workActions.workForms.fetchSuccess)
})

const workFormPdfFlow = function* ({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  if(data.subItemId) {
    yield put(workActions.workForms.updateStart(record))
  }
  try {
    const apiResponse = yield call(formApi.update, record, data)
    if(data.subItemId) {
      yield put(workActions.workForms.updateSuccess(apiResponse.data))
      addSuccessNotification(`Työn lomake tallennettu`)
    }
    addSuccessNotification(`PDF-tiedosto luotu työn tiedostoihin`)
    yield call(resolve, apiResponse.data)
  } catch(err) {
    if(data.subItemId) {
      yield put(workActions.workForms.updateError(miniSerializeError(err), record))
      yield * genericSagaErrorHandler(err, getGenericErrorMessage(err, 'Työn lomakkeen', 'päivityksessä'), reject)
    }
    yield * genericSagaErrorHandler(err, 'PDF-tiedoston luonti epäonnistui')
  }
}

const workFormUpdateFlow = updateFlow(formApi.update, workActions.workForms, 'Työn lomake', 'Työn lomakkeen', handleWorkFormsApiResponse(workActions.workForms.updateSuccess))
const workFormCreateFlow = createFlow(formApi.create, workActions.workForms, 'Työn lomake', 'Työn lomakkeen', handleWorkFormsApiResponse(workActions.workForms.createSuccess))
const workFormDeleteFlow = deleteFlow({
  deleteApi: formApi.remove,
  actions: workActions.workForms,
  singular: 'Työn lomakepohja',
  errorMsg: 'Työn lomakepohjan',
  base: 'work.workForms'
})

export default function* workFormSaga() {
  yield takeLatest(workActions.workFormTemplates.actionTypes.fetchRequest, workFormTemplatesFetchFlow)
  yield takeLatest(workActions.workForms.actionTypes.fetchRequest, workFormsFetchFlow)
  yield takeEvery(workActions.workForms.actionTypes.updateRequest, workFormUpdateFlow)
  yield takeEvery(workActions.workForms.actionTypes.createRequest, workFormCreateFlow)
  yield takeEvery(workActions.workForms.actionTypes.deleteRequest, workFormDeleteFlow)
  yield takeLatest(workActions.workFormsPdf.actionTypes.createRequest, workFormPdfFlow)
  yield all([
    watchOnWorkFormSockets()
  ])
}
