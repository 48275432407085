import PropTypes from 'prop-types'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

import useToggle from '../../hooks/useToggle'
import TimeRecordIndicator from './TimeRecordIndicator'
import TimeRecordTypeButtons from './TimeRecordTypeButtons'

const TimeRecordChooser = ({ className }) => {
  const [dropdownOpen, { toggle, disable }] = useToggle()
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} data-testid='timeRecordChooser'>
      <DropdownToggle color='link' className={className}>
        <TimeRecordIndicator />
      </DropdownToggle>

      <DropdownMenu className='px-3 py-4 timerecord-menu shadow bg-light'>
        <TimeRecordTypeButtons onClick={disable} />
      </DropdownMenu>
    </Dropdown>
  )
}

TimeRecordChooser.propTypes = {
  className: PropTypes.string
}

export default TimeRecordChooser
