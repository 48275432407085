import snakeCase from 'lodash/snakeCase'
import { parseTemplate } from 'url-template'

import {
  createApi,
  del,
  get,
  patch,
  post,
  put
} from '../helpers/apiHelpers'
import { normalizeApproversResponse } from './receiverApi'
import { normalizeWhoAmI } from './whoAmIApi'

const systemCustomerUrl = '/api/system_customers'
const systemCustomerSettingsUrl = `${systemCustomerUrl}/settings`
const systemCustomerMaventaUrl = `${systemCustomerUrl}/maventa`
const systemCustomerFormTemplateUrl = `${systemCustomerUrl}/form_template`
const systemCustomerAccountingUrl = `${systemCustomerUrl}/accounting`
const systemCustomerCreditActionsUrl = `${systemCustomerUrl}/credit_actions`
const systemCustomerEmailSettingsUrl = `${systemCustomerUrl}/emails`
const systemCustomerTermsOfServiceActionUrl = `${systemCustomerUrl}/terms`
const systemCustomerSmsSettingsUrl = `${systemCustomerUrl}/sms`

const systemCustomerSubItemUrl = `${systemCustomerUrl}{/subItem,subItemId,subSubItem,subSubItemId}`
const systemCustomerSubItemUrlTemplate = parseTemplate(systemCustomerSubItemUrl)

const systemCustomerBankAccountsUrlTemplate = parseTemplate(`${systemCustomerUrl}/bank_accounts{/id,action}`)

export const fetchSystemCustomer = () =>
  get(systemCustomerUrl)

export const fetchSystemCustomerBankAccounts = () =>
  get(systemCustomerBankAccountsUrlTemplate.expand({}))

export const updateSystemCustomerDetails = data =>
  patch(systemCustomerUrl, {
    body: JSON.stringify(data)
  })

export const updateSystemCustomerSettings = data =>
  patch(systemCustomerSettingsUrl, {
    body: JSON.stringify(data)
  })

export const updateSystemCustomerFormTemplateSettings = data =>
  patch(systemCustomerFormTemplateUrl, {
    body: JSON.stringify(data)
  })

export const updateSystemCustomerMaventaSettings = data =>
  patch(systemCustomerMaventaUrl, {
    body: JSON.stringify(data)
  })

export const updateSystemCustomerAccountingSettings = data =>
  patch(systemCustomerAccountingUrl, {
    body: JSON.stringify(data)
  })

export const updateSystemCustomerBankAccount = ({ id, ...data } = {}) =>
  put(systemCustomerBankAccountsUrlTemplate.expand({ id }), {
    body: JSON.stringify(data)
  })

export const createSystemCustomerBankAccount = data =>
  post(systemCustomerBankAccountsUrlTemplate.expand({}), {
    body: JSON.stringify(data)
  })

export const deleteSystemCustomerBankAccount = ({ id, ...data }) =>
  del(systemCustomerBankAccountsUrlTemplate.expand({ id }), {
    body: JSON.stringify(data)
  })

export const syncSystemCustomerBankAccountBalance = ({ id }) =>
  post(systemCustomerBankAccountsUrlTemplate.expand({ id, action: 'balance' }), {})

export const updateSystemCustomerEmailSettings = data =>
  patch(systemCustomerEmailSettingsUrl, {
    body: JSON.stringify(data)
  })

export const fetchSystemCustomerCreditActions = data =>
  get(systemCustomerCreditActionsUrl)

export const approveTermsOfService = () =>
  post(systemCustomerTermsOfServiceActionUrl)

export const fetchPricingRules = () =>
  get(systemCustomerSubItemUrlTemplate.expand({ subItem: 'pricing_rules' }))

export const fetchPricingRuleSets = () =>
  get(systemCustomerSubItemUrlTemplate.expand({ subItem: 'pricing_rule_sets' }))

export const updatePricingRuleSet = pricingRuleSet =>
  put(systemCustomerSubItemUrlTemplate.expand({ subItem: 'pricing_rule_sets', subItemId: pricingRuleSet.id }), {
    body: JSON.stringify(pricingRuleSet)
  })

export const createPricingRuleSet = pricingRuleSet =>
  post(systemCustomerSubItemUrlTemplate.expand({ subItem: 'pricing_rule_sets' }), {
    body: JSON.stringify(pricingRuleSet)
  })

export const deletePricingRuleSet = pricingRuleSet =>
  del(systemCustomerSubItemUrlTemplate.expand({ subItem: 'pricing_rule_sets', subItemId: pricingRuleSet.id }), {
    body: JSON.stringify(pricingRuleSet)
  })

export const fetchPricingRuleFallbacks = () =>
  get(systemCustomerSubItemUrlTemplate.expand({ subItem: 'pricing_rule_fallbacks' }))

export const updatePricingRuleFallback = pricingRuleFallback =>
  put(systemCustomerSubItemUrlTemplate.expand({ subItem: 'pricing_rule_fallbacks' }), {
    body: JSON.stringify(pricingRuleFallback)
  })

export const fetchSubItem = ({ subItem, ...params }) =>
  get(systemCustomerSubItemUrlTemplate.expand({ subItem: snakeCase(subItem), ...params }))

export const salaryTypeApi = createApi({ base: 'system_customers/salary_types' })

export const salaryGroupApi = createApi({ base: 'system_customers/salary_groups' })

export const fetchPacketIndustries = (params = {}) => {
  return get(systemCustomerSubItemUrlTemplate.expand({ ...params, subItem: 'packet_industries' }))
    .then(parsePacketIndustryFetchResponse)
}

export const updatePacketIndustry = packetIndustry => {
  return put(systemCustomerSubItemUrlTemplate.expand({ subItem: 'packet_industries', subItemId: packetIndustry.id }), {
    body: JSON.stringify(packetIndustry)
  })
}

export const createPacketIndustry = packetIndustry => {
  return post(systemCustomerSubItemUrlTemplate.expand({ subItem: 'packet_industries' }), {
    body: JSON.stringify(packetIndustry)
  })
}

const parsePacketIndustryFetchResponse = response => response

export const fetchWageIncomeTypes = createApi({ base: 'system_customers/wage_income_types' }).fetch

const setSubItemParam = subItem => params => ({ ...params, subItem })

export const { create: startProduction } = createApi({ base: 'system_customers', editMutateParams: setSubItemParam('start_production'), normalizer: normalizeWhoAmI })
export const { create: requestHelp } = createApi({ base: 'system_customers', editMutateParams: setSubItemParam('request_help') })
export const systemCustomerAnnualInvoicingApi = createApi({ base: 'system_customers/annual_invoicings' })
export const systemCustomerApproversApi = createApi({ base: 'system_customers/approvers', normalizer: normalizeApproversResponse })

export const updateSystemCustomerSmsSettings = data =>
  patch(systemCustomerSmsSettingsUrl, {
    body: JSON.stringify(data)
  })
