import PropTypes from 'prop-types'

import { EMAIL_SUPPORT } from '../../constants'
import ErrorPage from './ErrorPage'

const defaultMessage = (
  <h5>Jokin meni nyt pahasti vikaan.<br />
    Päivitä sivu tai ota tarvittaessa yhteyttä Evelian <a href={`mailto:${EMAIL_SUPPORT}`}>asiakaspalveluun</a>
  </h5>
)

const InternalErrorPage = ({ message = defaultMessage, eventId }) => (
  <ErrorPage statusCode={500} message={message} eventId={eventId} />
)

InternalErrorPage.propTypes = {
  message: PropTypes.node,
  eventId: PropTypes.string
}
export default InternalErrorPage
