import PropTypes from 'prop-types'

import ActionPromptModal from './ActionPromptModal'

const ShowWarehouseInventoryErrors = ({ visible, data, hideActionPrompt }) => {
  const body = (
    <ul>
      {data.productErrors.map(error => <li key={error}>{error}</li>)}
    </ul>
  )

  return (
    <ActionPromptModal
      visible={visible}
      renderBody={body}
      title='Virheitä varastoinventaariossa!'
      onCancel={hideActionPrompt}
    />
  )
}

ShowWarehouseInventoryErrors.propTypes = {
  visible: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    productErrors: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  hideActionPrompt: PropTypes.func.isRequired
}

export default ShowWarehouseInventoryErrors
