import PropTypes from 'prop-types'
import { faRecycle, faXmark } from '@fortawesome/free-solid-svg-icons'

import workActions from '../../actions/workActions'
import { workRowApiActions } from '../../constants'
import { promisifyAction } from '../../helpers/dispatchHelpers'
import useActions from '../../hooks/useActions'
import IconButton from '../Buttons/IconButton'

const componentActions = {
  doWorkRowPatchAction: workActions.workRows.doPutAction
}

const RepricePromptBody = ({ hideActionPrompt, data, visible }) => {
  const workId = data.workId
  const actions = useActions(componentActions)
  const body = (
    <>
      <p>Toiminto päivittää käytössä olevan hinnoittelusäännöstön mukaisesti kaikkien tunnistettujen tuotteiden tuotetiedot sisältäen myynti- ja ostohinnat.</p><p>Huom! Toiminto korvaa käsin asetetut alennukset ja kateprosentit valitun hinnoittelusäännöstön mukaisesti.</p>
    </>
  )

  const repriceWorkRows = () => promisifyAction(
    { workId },
    actions.doWorkRowPatchAction,
    { action: workRowApiActions.REPRICE }
  )

  const confirm = async() => {
    await repriceWorkRows()
    hideActionPrompt()
  }

  return (
    <>
      {body}
      <div className='modal-body-buttons'>
        <IconButton
          icon={faRecycle}
          condensed
          color='success'
          onClick={confirm}
          testId='repricePromptButton'
        >
          Uudelleenhinnoittele työrivien tuotteet
        </IconButton>
        <IconButton
          icon={faXmark}
          condensed
          outline
          color='danger'
          onClick={hideActionPrompt}
          testId='cancelrepricePromptButton'
        >
          Älä uudelleenhinnoittele
        </IconButton>
      </div>
    </>
  )
}

RepricePromptBody.propTypes = {
  hideActionPrompt: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    workId: PropTypes.number.isRequired
  }).isRequired
}

export default RepricePromptBody
