import { dateFormats, now, withFormat } from '@evelia/helpers/dateHelpers'

import constants, { actionTypes, invoiceStates } from '../constants'
import { getCombinedListReducerFor, getTableOptions } from '../helpers/reducerHelpers'
import tableReducerFor from './tableReducer'

const openInvoiceStateFilter = [{ value: invoiceStates.INVOICE_STATE_COMPLETED, operator: 'notEqual' }, { value: invoiceStates.INVOICE_STATE_COMPLETED, operator: 'isNull' }]

export const initialInvoiceTableOptions = {
  default: getTableOptions({ filters: { includeArchived: false } }),
  open: getTableOptions({ filters: { includeArchived: false, state: openInvoiceStateFilter } }),
  completed: getTableOptions({ filters: { includeArchived: false, state: invoiceStates.INVOICE_STATE_COMPLETED } }),
  late: getTableOptions({ filters: { includeArchived: false, state: openInvoiceStateFilter, dateDue: { value: withFormat(now(), dateFormats.isoDate), operator: 'lessThan' }, invoiceNumber: { value: null, operator: 'isNotNull' } } }),
  nonRemindedInvoices: getTableOptions(),
  pastDueRemindersInvoices: getTableOptions()
}

export default getCombinedListReducerFor(constants.actionKeys.INVOICES, {
  files: getCombinedListReducerFor(constants.actionKeys.INVOICE_FILES),
  accountings: getCombinedListReducerFor(constants.actionKeys.INVOICE_ACCOUNTINGS),
  memos: getCombinedListReducerFor(constants.actionKeys.INVOICE_MEMOS),
  invoicing: (state = [], action) => {
    switch(action.type) {
      case actionTypes.CHARGE_INVOICE_START:
        return [...state, action.record.id]
      case actionTypes.CHARGE_INVOICE_SUCCESS:
        return state.filter(id => id !== action.record.id)
      case actionTypes.CHARGE_INVOICE_ERROR:
        return state.filter(id => id !== action.error.id)
      default:
        return state
    }
  },
  pdf: (state = {}, action) => {
    switch(action.type) {
      case actionTypes.DOWNLOAD_PDF_INVOICE_SUCCESS:
        return { ...state, base64: action.record.pdf }
      default:
        return state
    }
  },
  interestInvoicing: getCombinedListReducerFor(constants.actionKeys.INTEREST_INVOICING, {}, '_id'),
  tableOptions: tableReducerFor(constants.actionKeys.INVOICES, initialInvoiceTableOptions),
  formSettings: getCombinedListReducerFor(constants.actionKeys.INVOICE_FORM_SETTINGS)
})
