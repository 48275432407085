export const emptyArray = []
Object.freeze(emptyArray)

export const workRowTypes = {
  WORK_ROW_TYPE_PRODUCT: 'product',
  WORK_ROW_TYPE_COMMENT: 'comment',
  WORK_ROW_TYPE_WORK_RECORD: 'workRecord',
  WORK_ROW_TYPE_MACHINE_RECORD: 'machineRecord',
  WORK_ROW_TYPE_INBOUND_INVOICE: 'inboundInvoice',
  WORK_ROW_TYPE_TEMPLATE: 'template',
  WORK_ROW_TYPE_FIXED_PRICE: 'fixedPrice',
  WORK_ROW_TYPE_SUB_TOTAL: 'subTotal',
  WORK_ROW_TYPE_SUB_HEADER: 'subHeader',
  WORK_ROW_TYPE_INSTALMENT: 'instalment'
}

export const workSystemTypes = {
  WORK_TYPE_DEFAULT: 'work',
  WORK_TYPE_INSTALMENT: 'instalment',
  WORK_TYPE_PROJECT: 'project'
}

export const invoiceOverrideReceiverOptions = {
  TARGET: 'target',
  CUSTOMER: 'customer'
}

export const logoAlignments = {
  LEFT: 'left',
  CENTER: 'center'
}

export const invoicingTypes = {
  EMAIL: 'Sähköposti',
  MAVENTA: 'Verkkolasku',
  PDF: 'PDF',
  FACTORING: 'Laskurahoitus',
  CERTUM: 'Certum lasku',
  XML: 'XML'
}

export const invoiceTypes = {
  INVOICE_TYPE_INVOICE: 'invoice',
  INVOICE_TYPE_CREDIT: 'credit',
  INVOICE_TYPE_REMINDER: 'reminder',
  INVOICE_TYPE_INTEREST: 'interest',
  INVOICE_TYPE_CONSOLIDATED: 'consolidated'
}

export const vatTypeInfoTexts = {
  reversedVat: 'Käännetty verovelvollisuus, lasku ei sisällä arvonlisäveroa. Ostaja on verovelvollinen AVL 8 c §:n mukaan.',
  euSales: 'ALV 0 % yhteisömyynti.'
}
