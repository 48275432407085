import PropTypes from 'prop-types'
import { Badge } from 'reactstrap'
import clsx from 'clsx'
import isString from 'lodash/isString'

import { getNodeColorProps } from '../../helpers/helpers'

const unknownState = {
  text: 'Tuntematon',
  color: 'light'
}

const defaultContentRenderer = option => option.text

const ModelBadge = ({
  model,
  optionMap,
  lookupKey = 'state',
  renderContent = defaultContentRenderer,
  size,
  defaultColor,
  className
}) => {
  const state = optionMap[model[lookupKey]] || unknownState
  const colorProps = state.color ? getNodeColorProps(state.color) : { color: defaultColor }

  const content = renderContent(state)
  return (
    <Badge
      {...colorProps}
      size={size}
      className={clsx('text-break-spaces', className)}
      title={isString(content) ? content : null}
    >
      {content}
    </Badge>
  )
}

ModelBadge.propTypes = {
  optionMap: PropTypes.object,
  lookupKey: PropTypes.string,
  size: PropTypes.string,
  model: PropTypes.object,
  renderContent: PropTypes.func,
  defaultColor: PropTypes.string,
  className: PropTypes.string
}

export default ModelBadge
