import productActions from '../actions/productActions'
import constants from '../constants'
import { getCombinedListReducerFor, getExtraInfoReducer, getStatsReducer, getTableOptions } from '../helpers/reducerHelpers'
import getTableReducer from './tableReducer'

export const initialProductTableOptions = {
  default: getTableOptions(),
  workRowProductChooser: getTableOptions(),
  purchaseOrderRowProductChooser: getTableOptions()
}

const initialStats = {
  productId: null,
  workRowCount: 0,
  cashReceiptRowCount: 0,
  inboundInvoiceRowCount: 0,
  offerPostRowCount: 0
}

export default getCombinedListReducerFor(constants.actionKeys.PRODUCTS, {
  tableOptions: getTableReducer(constants.actionKeys.PRODUCT_TABLE_OPTIONS, initialProductTableOptions),
  files: getCombinedListReducerFor(constants.actionKeys.PRODUCT_FILES),
  productTasks: getCombinedListReducerFor(constants.actionKeys.PRODUCT_TASKS),
  productWarehouseStats: getCombinedListReducerFor(constants.actionKeys.PRODUCT_WAREHOUSE_STATS, undefined, '_id'),
  stats: getStatsReducer(productActions, initialStats),
  extraInfo: getExtraInfoReducer(productActions, 'productId')
})
