import PropTypes from 'prop-types'

import ErrorPage from './ErrorPage'

const NotFoundErrorPage = ({ message = 'Sivua ei löytynyt' }) => (
  <ErrorPage statusCode={404} message={message} />
)

NotFoundErrorPage.propTypes = {
  message: PropTypes.string
}

export default NotFoundErrorPage
