import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { formatDateTimeOrEmpty, now } from '@evelia/helpers/dateHelpers'
import { formatThousandsSeparatedBySpaces } from '@evelia/helpers/helpers'
import isFunction from 'lodash/isFunction'

import { projectExtraExpenseTypes, projectExtraExpenseTypesMap } from '../../constants'
import { projectBudgetFieldMap } from '../../containers/ProjectPage/ProjectBudgetInput'
import { formatDiff, formatWithUnitMemoized } from '../../helpers/helpers'
import { getProjectExtraBudgetsByProjectId, getProjectExtraBudgetsByType } from '../../selectors/projectSelectors'
import { DataGrid } from '../Table'
import { getAuthorCell } from '../Table/connectedCellFormatters'
import ConnectedTableCellFormatter from '../Table/ConnectedTableCellFormatter'
import { mapToDataGrid } from '../Table/helpers'

export const extraBudgetTableFields = [
  {
    header: 'Päivämäärä',
    field: 'createdAt',
    customRenderer: value => formatDateTimeOrEmpty(value),
    width: 150,
    default: now()
  },
  {
    header: 'Työntekijä',
    field: 'createdBy',
    customComponent: ConnectedTableCellFormatter,
    // DIRTY HACK: getAuthorCell IS a function but for some reason frontend tests (incomingRowsToWorkRowsModal.test.tsx) fail sayings it's not -> idiomatic workaround
    customComponentProps: isFunction(getAuthorCell) ? getAuthorCell() : getAuthorCell,
    width: 150
  },
  {
    header: 'Tyyppi',
    field: 'type',
    customRenderer: value => projectExtraExpenseTypesMap[value].text,
    width: 100
  },
  {
    header: 'Budjetin muutos',
    field: 'value',
    width: 200,
    customRenderer: (value, model) => formatDiff(formatWithUnitMemoized(projectBudgetFieldMap[model.type]?.unit, formatThousandsSeparatedBySpaces))(value)
  },
  {
    header: 'Selite',
    field: 'description',
    wordWrapEnabled: true
  }
]

const ProjectExtraBudgetsTable = ({ projectId, type }) => {
  const extraBudgetSelector = useMemo(() => type ? getProjectExtraBudgetsByType : getProjectExtraBudgetsByProjectId, [type])
  const projectExtraBudgets = useSelector(state => extraBudgetSelector(state, { projectId, projectExtraExpenseType: type }))
  const columnSpecs = mapToDataGrid(extraBudgetTableFields)

  return (
    <DataGrid
      columnSpecs={columnSpecs}
      rows={projectExtraBudgets}
      disableSearch
      disableToolbar
      disableFiltering
      tableClasses='table-striped'
      testId='extraBudgetsTable'
      isLowNoDataRow
    />
  )
}

ProjectExtraBudgetsTable.propTypes = {
  type: PropTypes.oneOf(Object.values(projectExtraExpenseTypes)),
  projectId: PropTypes.number.isRequired
}

export default ProjectExtraBudgetsTable
