import { parseTemplate } from 'url-template'

import {
  defaultEmbeddedNormalizer,
  del,
  get,
  getSubApi,
  getSubEntityApi,
  post,
  put
} from '../helpers/apiHelpers'

const baseUrl = '/api/receivers/{receiverId}{?q,newReceiverId}'

const receiverUrlTemplate = parseTemplate(baseUrl)
const receiverFileUrlTemplate = parseTemplate(baseUrl + '/files{/receiverFileId}')
export const receiverInboundInvoiceUrlTemplate = parseTemplate(baseUrl + '/inbound_invoices')
const receiverApproverUrlTemplate = parseTemplate(baseUrl + '/approvers{/subItem,subItemType,subItemId}')

export const normalizeReceiverResponse = response => {
  return defaultEmbeddedNormalizer(response)
}

export const fetchReceivers = (params = {}) =>
  get(receiverUrlTemplate.expand(params))
    .then(normalizeReceiverResponse)

export const updateReceiver = receiver =>
  put(receiverUrlTemplate.expand({ receiverId: receiver.id }), {
    body: JSON.stringify(receiver)
  }).then(normalizeReceiverResponse)

export const createReceiver = receiver =>
  post(receiverUrlTemplate.expand({}), {
    body: JSON.stringify(receiver)
  }).then(normalizeReceiverResponse)

export const searchReceiver = search =>
  get(receiverUrlTemplate.expand({ q: search }))
    .then(normalizeReceiverResponse)

export const deleteReceiver = (receiver, actionData = {}) =>
  del(receiverUrlTemplate.expand({ receiverId: receiver.id, ...actionData }))

export const {
  fetchApi: fetchReceiverFiles,
  createApi: createReceiverFile,
  deleteApi: deleteReceiverFile
} = getSubEntityApi(receiverFileUrlTemplate, 'receiverId', 'fileId', 'receiverFileId', 'file')

export const normalizeApproversResponse = response => {
  const { _embedded, records, record } = response
  const {
    employees,
    employee
  } = _embedded || {}
  const data = records || record
  return {
    data,
    employees: employees || employee
  }
}

export const {
  fetchApi: fetchReceiverApprovers,
  createApi: createReceiverApprover,
  updateApi: updateReceiverApprover,
  deleteApi: deleteReceiverApprover
} = getSubApi(receiverApproverUrlTemplate, 'subItemId', normalizeApproversResponse)
