import { get, post } from '../helpers/apiHelpers'

const baseUrl = '/api/mfa'

export const requestUrls = {
  mfaQrCode: `${baseUrl}/qr_code`,
  setupOtp: `${baseUrl}/setup_otp`,
  verifyOtp: `${baseUrl}/verify_otp`,
  sendSmsToken: `${baseUrl}/send_sms_token`,
  sentSmsCountByEmployeeId: `${baseUrl}/sent_sms_count_by_employee_id`,
  resetUserMfa: `${baseUrl}/reset_user_mfa`
}

export const fetchSentSmsCountByEmployeeId = () => {
  return get(requestUrls.sentSmsCountByEmployeeId)
}

const fetchMfaQrCode = () => {
  return get(requestUrls.mfaQrCode)
}

const verifyOtp = (data, isSetupOtp) => {
  return post(isSetupOtp ? requestUrls.setupOtp : requestUrls.verifyOtp, {
    body: JSON.stringify(data)
  })
}

export const resetUserMfa = async employeeId => {
  return post(`${requestUrls.resetUserMfa}/${employeeId}`)
}

export const handleFetchMfaQrCode = async() => {
  const {
    svgQrCode,
    currentQrCodeMfaSecret
  } = await fetchMfaQrCode()
  return ({
    svgQrCode: `data:image/svg+xml;utf8,${encodeURIComponent(svgQrCode)}`,
    currentQrCodeMfaSecret
  })
}

export const handleVerifyOtp = async(totpToken, isRegister) => {
  return verifyOtp({ totpToken }, isRegister)
}

export const handleSendSmsToken = async mfaSecretType => {
  return post(`${requestUrls.sendSmsToken}/${mfaSecretType}`)
}
