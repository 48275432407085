import moment from '@evelia/helpers/dateHelpers'

import { get } from '../helpers/apiHelpers'

export const fetchDashboard = () => {
  return get(`/api/dashboard?startDate=${moment().subtract(3, 'months').startOf('month').format('YYYY-MM-DD')}`)
    .then(response => {
      return {
        data: response.record,
        ...response._embedded
      }
    })
}
