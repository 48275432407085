import { put, takeLatest } from 'redux-saga/effects'

import packetActions from '../actions/packetActions'
import packetMatrixActions from '../actions/packetMatrixActions'
import { packetMatrixApi } from '../api/packetMatrixApi'
import { fetchFlow } from '../helpers/sagaHelpers'

const handlePacketMatrixApiResponse = mainAction =>
  function* ({
    data,
    packets,
    installations,
    packetInstallations,
    tableOptions
  }) {
    yield put(mainAction(data))
    yield put(packetActions.fetchSuccess(packets))
    yield put(packetActions.installations.fetchSuccess(installations))
    yield put(packetActions.packetInstallations.fetchSuccess(packetInstallations))
    if(tableOptions && tableOptions.orderBy != null) {
      yield put(packetMatrixActions.tableActions.updateOptions(tableOptions))
    }
    return data
  }

const packetMatrixFetchFlow = fetchFlow({
  fetchApi: packetMatrixApi.fetch,
  actions: packetMatrixActions,
  apiResponseHandler: handlePacketMatrixApiResponse(packetMatrixActions.fetchSuccess),
  base: 'packetMatrices',
  idField: 'id',
  errorMsg: 'Syöttösivujen'
})

export default function* packetMatrixSaga() {
  yield takeLatest(packetMatrixActions.actionTypes.fetchRequest, packetMatrixFetchFlow)
}
