import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'

import workRecordProductActions from '../actions/workRecordProductActions'
import {
  createWorkRecordProduct,
  createWorkRecordProductPricingRule,
  createWorkRecordProductSalaryType,
  deleteWorkRecordProduct,
  deleteWorkRecordProductPricingRule,
  deleteWorkRecordProductSalaryType,
  doWorkRecordProductPatchAction,
  fetchWorkRecordProductPricingRules,
  fetchWorkRecordProducts,
  fetchWorkRecordProductSalaryTypes,
  fetchWorkRecordProductStats,
  normalizeWorkRecordProductPricingRuleResponse,
  updateWorkRecordProduct,
  updateWorkRecordProductPricingRule,
  updateWorkRecordProductSalaryType
} from '../api/workRecordProductApi'
import { actionTypes } from '../constants'
import { addSuccessNotification } from '../helpers/notificationHelpers'
import {
  createFlow,
  createSocketWatcher,
  createSocketWatcherWithGenerator,
  deleteFlow,
  fetchFlow,
  fetchStatsFlow,
  genericSagaErrorHandler,
  getPromiseHandlersFromData,
  updateFlow
} from '../helpers/sagaHelpers'

const watchOnWorkRecordProductSockets = createSocketWatcher('workRecordProduct', {
  created: workRecordProductActions.createSuccess,
  updated: workRecordProductActions.updateSuccess
})

const workRecordProductFetchFlow = fetchFlow({
  fetchApi: fetchWorkRecordProducts,
  actions: workRecordProductActions,
  base: 'workRecordProducts',
  errorMsg: 'Työtehtävätuotteiden',
  updateLastFetched: true
})

const workRecordProductUpdateFlow = updateFlow(updateWorkRecordProduct, workRecordProductActions, 'Työtehtävätuote', 'Työtehtävätuotteen')
const workRecordProductCreateFlow = createFlow(createWorkRecordProduct, workRecordProductActions, 'Työtehtävätuote', 'Työtehtävätuotteen')
const workRecordProductDeleteFlow = deleteFlow({
  deleteApi: deleteWorkRecordProduct,
  actions: workRecordProductActions,
  singular: 'Työtehtävätuote',
  errorMsg: 'Työtehtävätuotteen',
  base: 'workRecordProducts'
})

const watchOnWorkRecordProductSalaryTypeSockets = createSocketWatcher('workRecordProductSalaryType', {
  created: workRecordProductActions.salaryTypes.createSuccess,
  updated: workRecordProductActions.salaryTypes.updateSuccess
})

function* workRecordProductSalaryTypeFetchFlow({ data = {} }) {
  try {
    if(data.force || (yield select(state => !state.workRecordProducts.workRecordProductSalaryTypes.records.length))) {
      yield put(workRecordProductActions.salaryTypes.fetchStart())
      const workRecordProductSalaryTypes = yield call(fetchWorkRecordProductSalaryTypes, data)
      yield put(workRecordProductActions.salaryTypes.fetchSuccess(workRecordProductSalaryTypes))
    }
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe palkkalajien noudossa')
  }
}

const workRecordProductSalaryTypeUpdateFlow = updateFlow(updateWorkRecordProductSalaryType, workRecordProductActions.salaryTypes, 'Palkkalaji', 'Palkkalajin')
const workRecordProductSalaryTypeCreateFlow = createFlow(createWorkRecordProductSalaryType, workRecordProductActions.salaryTypes, 'Palkkalaji', 'Palkkalajin')
const workRecordProductSalaryTypeDeleteFlow = deleteFlow({
  deleteApi: deleteWorkRecordProductSalaryType,
  actions: workRecordProductActions.salaryTypes,
  singular: 'Palkkalaji',
  errorMsg: 'Palkkalajin',
  base: 'workRecordProducts.workRecordProductSalaryTypes',
  key: '_id'
})

const handleWorkRecordProductPricingRuleResponse = mainAction =>
  function* ({ data }) {
    yield put(mainAction(data))
    return data
  }

const watchOnWorkRecordProductPricingRuleSockets = createSocketWatcherWithGenerator('workRecordProductPricingRule', {
  created: function* (data) {
    yield handleWorkRecordProductPricingRuleResponse(workRecordProductActions.pricingRules.createSuccess)(normalizeWorkRecordProductPricingRuleResponse(data))
  },
  updated: function* (data) {
    yield handleWorkRecordProductPricingRuleResponse(workRecordProductActions.pricingRules.updateSuccess)(normalizeWorkRecordProductPricingRuleResponse(data))
  },
  deleted: function* (data) {
    yield put(workRecordProductActions.pricingRules.deleteSuccess(data))
  }
})

function* workRecordProductPricingRuleFetchFlow({ data = {} }) {
  try {
    if(data.force || (yield select(state => !state.workRecordProducts.workRecordProductPricingRules.records.length))) {
      yield put(workRecordProductActions.pricingRules.fetchStart())
      const apiResponse = yield call(fetchWorkRecordProductPricingRules, data)
      yield handleWorkRecordProductPricingRuleResponse(workRecordProductActions.pricingRules.fetchSuccess)(apiResponse)
    }
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe hinnoittelusääntöjen noudossa')
  }
}

const workRecordProductPricingRuleUpdateFlow = updateFlow(updateWorkRecordProductPricingRule, workRecordProductActions.pricingRules, 'Hinnoittelu', 'Hinnoittelun', handleWorkRecordProductPricingRuleResponse(workRecordProductActions.pricingRules.updateSuccess))
const workRecordProductPricingRuleCreateFlow = createFlow(createWorkRecordProductPricingRule, workRecordProductActions.pricingRules, 'Hinnoittelu', 'Hinnoittelun', handleWorkRecordProductPricingRuleResponse(workRecordProductActions.pricingRules.createSuccess))
const workRecordProductPricingRuleDeleteFlow = deleteFlow({
  deleteApi: deleteWorkRecordProductPricingRule,
  actions: workRecordProductActions.pricingRules,
  singular: 'Hinnoittelu',
  errorMsg: 'Hinnoittelun',
  base: 'workRecordProducts.workRecordProductPricingRules'
})

const workRecordProductStatsFetchFlow = fetchStatsFlow(workRecordProductActions, fetchWorkRecordProductStats, 'workRecordProductId')

const createWorkRecordProductActionFlow = apiAction => function* ({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(record)
  try {
    const responseBody = record
    const urlExpand = data
    const response = yield call(apiAction, responseBody, urlExpand)
    const workRecordProduct = yield put(workRecordProductActions.fetchSuccess(response.data))
    addSuccessNotification('Toiminto onnistui')
    yield call(resolve, workRecordProduct)
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Toiminto epäonnistui', reject)
  }
}

const workRecordProductPatchActionFlow = createWorkRecordProductActionFlow(doWorkRecordProductPatchAction)

export default function* workRecordProductSaga() {
  yield takeLatest(workRecordProductActions.actionTypes.fetchRequest, workRecordProductFetchFlow)
  yield takeEvery(workRecordProductActions.actionTypes.updateRequest, workRecordProductUpdateFlow)
  yield takeEvery(workRecordProductActions.actionTypes.createRequest, workRecordProductCreateFlow)
  yield takeEvery(workRecordProductActions.actionTypes.deleteRequest, workRecordProductDeleteFlow)

  yield takeLatest(workRecordProductActions.salaryTypes.actionTypes.fetchRequest, workRecordProductSalaryTypeFetchFlow)
  yield takeEvery(workRecordProductActions.salaryTypes.actionTypes.updateRequest, workRecordProductSalaryTypeUpdateFlow)
  yield takeEvery(workRecordProductActions.salaryTypes.actionTypes.createRequest, workRecordProductSalaryTypeCreateFlow)
  yield takeEvery(workRecordProductActions.salaryTypes.actionTypes.deleteRequest, workRecordProductSalaryTypeDeleteFlow)

  yield takeLatest(workRecordProductActions.pricingRules.actionTypes.fetchRequest, workRecordProductPricingRuleFetchFlow)
  yield takeEvery(workRecordProductActions.pricingRules.actionTypes.updateRequest, workRecordProductPricingRuleUpdateFlow)
  yield takeEvery(workRecordProductActions.pricingRules.actionTypes.createRequest, workRecordProductPricingRuleCreateFlow)
  yield takeEvery(workRecordProductActions.pricingRules.actionTypes.deleteRequest, workRecordProductPricingRuleDeleteFlow)

  yield takeLatest(workRecordProductActions.actionTypes.fetchStatsRequest, workRecordProductStatsFetchFlow)

  yield takeEvery(actionTypes.WORK_RECORD_PRODUCT_PATCH_ACTION_REQUEST, workRecordProductPatchActionFlow)

  yield all([
    watchOnWorkRecordProductSockets(),
    watchOnWorkRecordProductSalaryTypeSockets(),
    watchOnWorkRecordProductPricingRuleSockets()
  ])
}
