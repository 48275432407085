import PropTypes from 'prop-types'

import { EMAIL_SUPPORT } from '../../constants'
import ErrorPage from './ErrorPage'

const defaultMessage = (
  <h5>Pääsy kielletty<br />
    Ota tarvittaessa yhteyttä esimieheenne <br />tai Evelian <a href={`mailto:${EMAIL_SUPPORT}`}>asiakaspalveluun</a>
  </h5>
)

const ForbiddenErrorPage = ({ message = defaultMessage }) => (
  <ErrorPage statusCode={403} message={message} />
)

ForbiddenErrorPage.propTypes = {
  message: PropTypes.node
}

export default ForbiddenErrorPage
