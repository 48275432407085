import { faDoorOpen } from '@fortawesome/free-solid-svg-icons/faDoorOpen'

import loginActions from '../../actions/loginActions'
import useActions from '../../hooks/useActions'
import IconButton from '../Buttons/IconButton'

const componentActions = {
  doLogout: loginActions.logoutRequest
}

const LogoutButton = () => {
  const actions = useActions(componentActions)
  return (
    <IconButton
      className='me-1'
      icon={faDoorOpen}
      condensed
      color='danger'
      onClick={actions.doLogout}
      testId='mfaLogout'
    >
      Kirjaudu ulos
    </IconButton>
  )
}

export default LogoutButton
