import { createApi, defaultEmbeddedNormalizer } from '../helpers/apiHelpers'
import { BaseIdModel } from './types/baseModelTypes'

export type OccupationClassificationModel = {
  code: string
  name: string
  description: string
} & BaseIdModel

export const occupationClassificationApi = createApi({
  base: 'occupation_classifications',
  normalizer: defaultEmbeddedNormalizer
})
