import CommonValueFormGroup from './CommonValueFormGroup'
import DateTimeFormInput from './inputs/DateTimeFormInput'
import { commonFormInputDefaultProps, commonFormInputPropTypes } from './propTypes'

const DateTimeFormGroup = props => (
  <CommonValueFormGroup
    {...commonFormInputDefaultProps}
    {...props}
    setNull
    forceFeedback
    Component={DateTimeFormInput}
  />
)
DateTimeFormGroup.propTypes = commonFormInputPropTypes

export default DateTimeFormGroup
