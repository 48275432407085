import { parseTemplate } from 'url-template'

import constants from '../constants'
import { actionCreatorsFor } from '../helpers/actionHelpers'
import { PATH_CASH_RECEIPTS } from '../routes'
import { navigateTo } from './navigateActions'

const cashReceiptUrlTemplate = parseTemplate(`${PATH_CASH_RECEIPTS}{/cashReceiptId}{?refund}`)
export const getUrl = (cashReceiptId, params) => cashReceiptUrlTemplate.expand({ cashReceiptId: cashReceiptId || 'new', ...params })

const baseActionCreators = actionCreatorsFor(constants.actionKeys.CASH_RECEIPTS)

const additionalActionCreators = {
  rows: actionCreatorsFor(constants.actionKeys.CASH_RECEIPTS_ROWS),
  files: actionCreatorsFor(constants.actionKeys.CASH_RECEIPTS_FILES),
  pdf: actionCreatorsFor(constants.actionKeys.CASH_RECEIPTS_PDF),
  navigateTo: (cashReceiptId, replaceUrl, params) => navigateTo(getUrl(cashReceiptId, params), replaceUrl),
  navigateToBase: replaceUrl => navigateTo(PATH_CASH_RECEIPTS, replaceUrl)
}

const cashReceiptActions = { ...baseActionCreators, ...additionalActionCreators }
export default cashReceiptActions
