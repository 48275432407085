import constants, { actionTypes } from '../constants'
import { actionCreatorsFor, request } from '../helpers/actionHelpers'

const baseActionCreators = actionCreatorsFor(constants.actionKeys.FILL_OUT_FORMS)

const additionalActionCreators = {
  fillOutFormPdfPostRequest: request(actionTypes.FILL_OUT_FORM_PDF_POST_REQUEST)
}

const fillOutFormActions = { ...baseActionCreators, ...additionalActionCreators }
export default fillOutFormActions
