import { emptyArray } from '@evelia/helpers/constants'
import { isSameOrBefore } from '@evelia/helpers/dateHelpers'
import { roundNumber } from '@evelia/helpers/helpers'

import { CombinedVatRateModel, useGetVatCodesQuery, type VatCodeModel } from '../../api/rtk/vatCodeApi'
import { useTypedSelector } from '../../reducerTypes'
import { findCombinedVatRates, getVatCodeById } from '../../selectors/vatSelectors'

export const useVatCodes = () => {
  return useGetVatCodesQuery(undefined, {
    selectFromResult: response => ({
      ...response,
      data: response?.data?.records || emptyArray
    })
  })
}
export const useVatCode = (id: number | null) => {
  return useGetVatCodesQuery(undefined, {
    skip: !id,
    selectFromResult: response => ({
      ...response,
      data: getVatCodeById(response, id) as VatCodeModel
    })
  })
}

export const useCombinedVatRates = () => {
  // Ensure that vatCodes are registered to redux
  useVatCodes()
  return useTypedSelector(state => findCombinedVatRates(state))
}

export const calculatePrices = (combinedVatRate: CombinedVatRateModel, isVatPrimary: number, oldVatPrice: number, oldPrice: number, count: number, discount: number) => {
  const vatRate = combinedVatRate.rate / 100
  const price = isVatPrimary ? roundNumber(oldVatPrice / (1 + vatRate), 8) : oldPrice
  const vatPrice = isVatPrimary ? oldVatPrice : roundNumber(oldPrice * (1 + vatRate), 8)
  const priceSum = count * price * (1 - discount / 100)
  const vatPriceSum = count * vatPrice * (1 - discount / 100)
  return {
    vatClassId: combinedVatRate.vatClassId,
    vatCodeId: combinedVatRate.id,
    price,
    vatPrice,
    priceSum,
    vatPriceSum
  }
}

const getVatRateByVatClassId = (combinedVatRatesList, vatClassId, deliveryDate) => combinedVatRatesList.find(combinedVatRate => combinedVatRate.vatClassId === vatClassId && isSameOrBefore(combinedVatRate.validAfter, deliveryDate))

export const useLatestDeliveryDateVatCodePrices = (rows, isVatPrimary) => {
  const vatRatesAndRowsByVatRateIdentifier = {}
  const combinedVatRates = useCombinedVatRates()

  for(const row of rows) {
    const vatRate = getVatRateByVatClassId(combinedVatRates, row.vatClassId, row.deliveryDate)
    const { vatClassId } = vatRate || {}
    if(Object.hasOwn(vatRatesAndRowsByVatRateIdentifier, vatClassId)) {
      vatRatesAndRowsByVatRateIdentifier[vatClassId].rowsToBeUpdated.add(row.id)
      vatRatesAndRowsByVatRateIdentifier[vatClassId].foundVatRates.add(vatRate.rate)
      if(row.deliveryDate > vatRatesAndRowsByVatRateIdentifier[vatClassId].newestDeliveryDate) {
        vatRatesAndRowsByVatRateIdentifier[vatClassId].newestDeliveryDate = row.deliveryDate
      }
    } else {
      if(vatRate) {
        vatRatesAndRowsByVatRateIdentifier[vatClassId] = {
          rowsToBeUpdated: new Set([row.id]),
          foundVatRates: new Set([vatRate.rate]),
          newestDeliveryDate: row.deliveryDate
        }
      }
    }
  }

  return rows.map(row => {
    const {
      id,
      vatClassId,
      vatCodeId,
      vatPrice,
      price,
      count,
      discount = 0

    } = row

    const { foundVatRates = new Set(), rowsToBeUpdated = new Set(), newestDeliveryDate = '' } = vatClassId ? vatRatesAndRowsByVatRateIdentifier[vatClassId] : {}
    if(foundVatRates.size > 1 && rowsToBeUpdated.has(id)) {
      const vatRate = getVatRateByVatClassId(combinedVatRates, vatClassId, newestDeliveryDate)
      if(vatRate.id !== vatCodeId) {
        const prices = calculatePrices(vatRate, isVatPrimary, vatPrice, price, count, discount)
        return {
          ...row,
          ...prices
        }
      }
    }
    return row
  })
}
