import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { faWindowRestore } from '@fortawesome/free-solid-svg-icons'

import useCallPromiseAndTapFunction from '../../hooks/useCallPromiseAndTapFunction'
import useToggle from '../../hooks/useToggle'
import IconButton from '../Buttons/IconButton'
import { ButtonFooter, CancelFooter, ConfirmationFooter } from './buttonFormModalFooterHelpers'
import ModalPrompt from './ModalPrompt'

/**
 * Wraps modal content inside a form
 *
 */
const ButtonFormModal = ({
  buttonText,
  headerTitle,
  onOpened,
  onClosed,
  model,
  getForm,
  FormComponent,
  modalSize,
  onSubmit,
  requireConfirmation,
  disabled,
  buttonColor = 'success',
  buttonSize = 'sm',
  buttonIcon = faWindowRestore,
  ButtonComponent = IconButton,
  buttonProps = {},
  formProps = {},
  testId,
  renderButtons,
  prependContent,
  appendContent,
  noButtonText,
  onlyCancel,
  saveLabel,
  cancelLabel
}) => {
  // Modal
  const [isOpen, { enable: openModal, disable: closeModal }] = useToggle()

  const handleOpenModal = useCallback(event => {
    event.stopPropagation()
    openModal()
  }, [openModal])
  // Form
  const getModalForm = useCallback(({ extraProps, ...formOptions }) =>
    FormComponent
      ? (
        <FormComponent
          {...extraProps}
          {...formOptions}
        />
        )
      : getForm({ extraProps, ...formOptions })
  , [FormComponent, getForm])

  const handleFormSubmit = useCallPromiseAndTapFunction(onSubmit, closeModal)

  const Footer = onlyCancel ? CancelFooter : requireConfirmation ? ConfirmationFooter : ButtonFooter

  // arguments comes from form
  const renderModalButtons = useCallback(({ handleSubmit, isSaving, model }) => (
    renderButtons
      ? renderButtons({ handleSubmit, isSaving, onCancel: closeModal, model })
      : (
        <Footer
          onSuccess={handleSubmit}
          onCancel={closeModal}
          isBusy={isSaving}
          cancelLabel={cancelLabel}
          saveLabel={saveLabel}
        />
        )
  ), [renderButtons, closeModal, Footer, cancelLabel, saveLabel])

  return (
    <>
      <ButtonComponent
        onClick={handleOpenModal}
        size={buttonSize}
        icon={buttonIcon}
        disabled={disabled}
        color={buttonColor}
        testId={testId}
        {...buttonProps}
      >
        {noButtonText ? null : buttonText || headerTitle}
      </ButtonComponent>
      {!disabled && (
        <ModalPrompt
          formProps={formProps}
          model={model}
          closeModal={closeModal}
          isOpen={isOpen}
          header={headerTitle || buttonText}
          save={handleFormSubmit}
          getForm={getModalForm}
          renderButtons={renderModalButtons}
          modalProps={{
            onOpened,
            onClosed,
            size: modalSize
          }}
          prependContent={prependContent}
          appendContent={appendContent}
        />
      )}
    </>
  )
}

ButtonFormModal.propTypes = {
  buttonText: PropTypes.node,
  headerTitle: PropTypes.node,
  onOpened: PropTypes.func,
  onClosed: PropTypes.func,
  model: PropTypes.object.isRequired,
  FormComponent: PropTypes.elementType,
  getForm: PropTypes.func,
  modalSize: PropTypes.string,
  onSubmit: PropTypes.func,
  requireConfirmation: PropTypes.bool,
  disabled: PropTypes.bool,
  buttonColor: PropTypes.string,
  buttonSize: PropTypes.string,
  ButtonComponent: PropTypes.elementType,
  buttonProps: PropTypes.object,
  buttonIcon: PropTypes.object,
  formProps: PropTypes.object,
  testId: PropTypes.string,
  renderButtons: PropTypes.func,
  prependContent: PropTypes.node,
  appendContent: PropTypes.node,
  noButtonText: PropTypes.bool,
  onlyCancel: PropTypes.bool,
  saveLabel: PropTypes.string,
  cancelLabel: PropTypes.string
}

export default ButtonFormModal
