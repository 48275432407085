import { takeEvery } from 'redux-saga/effects'

import { federatedCredentialApi } from '../api/federatedCredentialApi'
import { deleteFlow, fetchFlow } from '../helpers/sagaHelpers'
import { federatedCredentialActions } from '../slices/federatedCredentialSlice'

const reduxName = 'federatedCredentials'
const singular = 'Liitetty tunnus'
const errorMessage = 'Liitettyjen tunnusten'

const federatedCredentialFetchFlow = fetchFlow({
  fetchApi: federatedCredentialApi.fetch,
  actions: federatedCredentialActions,
  base: reduxName,
  errorMsg: errorMessage
})

const federatedCredentialDeleteFlow = deleteFlow({
  deleteApi: federatedCredentialApi.remove,
  actions: federatedCredentialActions,
  base: reduxName,
  errorMsg: errorMessage,
  singular
})

export default function* federatedCredentialSaga() {
  yield takeEvery(federatedCredentialActions.fetchRequest.type, federatedCredentialFetchFlow)
  yield takeEvery(federatedCredentialActions.deleteRequest.type, federatedCredentialDeleteFlow)
}
