import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { Col, Container, Row } from 'reactstrap'

import { handleFetchMfaQrCode, handleVerifyOtp } from '../../api/mfaApi'
import { MFA_RELOAD_PAGE_ROUTES } from '../../containers/Main/routes'
import { addErrorNotification } from '../../helpers/notificationHelpers'
import useToggle from '../../hooks/useToggle'
import { findCurrentEmployee } from '../../selectors/employeeSelectors'
import { findSystemCustomerSettings } from '../../selectors/systemCustomerSelectors'
import { AccordionContainer, AccordionElement } from '../Accordions'
import LoadingSpinner from '../Loading/LoadingSpinner'
import ModalBase from '../Modals/ModalBase'
import LogoutButton from './LogoutButton'
import MultiFactorAuthenticationInfo from './MultiFactorAuthenticationInfo'
import QrCodeAlert from './QrCodeAlert'
import SendSmsButton from './SendSmsButton'
import TotpInput from './TotpInput'
import TotpVerifyButton from './TotpVerifyButton'

const SetupAuthenticatorAppModal = ({ isOpen, handleClose }) => {
  const location = useLocation()
  const companyData = useSelector(findSystemCustomerSettings)
  const employeeData = useSelector(findCurrentEmployee)
  const [isError, { enable: enableError, disable: disableError }] = useToggle(false)
  const [state, setState] = useState(null)
  const [totpToken, setTotpToken] = useState('')

  useEffect(() => {
    async function fetchQrCode() {
      try {
        const stateData = await handleFetchMfaQrCode()
        setState(stateData)
      } catch(error) {
        addErrorNotification(error?.message || 'Tapahtui virhe')
      }
    }
    if(isOpen) {
      fetchQrCode()
    }
  }, [isOpen])

  useEffect(() => {
    if(!totpToken) {
      disableError()
    }
  }, [totpToken, disableError])

  const verifyOtp = async() => {
    disableError()
    try {
      await handleVerifyOtp(totpToken, true)
      handleClose(totpToken)
      if(MFA_RELOAD_PAGE_ROUTES.includes(location.pathname)) {
        window.location.reload()
      }
    } catch(error) {
      enableError()
      addErrorNotification(error?.message || 'Tapahtui virhe')
    }
  }

  return (
    <ModalBase
      modalProps={{
        'data-testid': 'mfaSetupModal'
      }}
      title='Lisävarmenteen käyttöönotto'
      isOpen={isOpen}
      renderBody={(
        <Container>
          <MultiFactorAuthenticationInfo isMfaSetup />
          <Row data-testid='mfaSetupRow'>
            <Col xs={12} sm={6} data-testid='mfaQrCodeColumn'>
              <h6 data-testid='mfaQrCodeTitle'>
                1. Lue QR-koodi autentikaattorilla
              </h6>
              {state?.svgQrCode
                ? (
                  <img
                    className='d-block m-auto'
                    style={{ maxWidth: 225 }}
                    src={state.svgQrCode}
                    alt='QR code for authenticator app'
                    data-testid='mfaQrCode'
                  />
                  )
                : <LoadingSpinner testId='mfaQrCodeLoader' />}
            </Col>
            <Col xs={12} sm={6} data-testid='mfaTotpInputColumn'>
              <h6 data-testid='mfaTotpInputTitle'>
                2. Syötä autentikaattorin antama vahvistuskoodi
              </h6>
              <TotpInput
                setTotpToken={setTotpToken}
                isError={isError}
                handleVerifyOtp={verifyOtp}
              />
            </Col>
          </Row>
          <QrCodeAlert />
          <AccordionContainer>
            <AccordionElement accordionId='mfaSecret' headerText='salausavain' testId='mfaSecretAccordionElement'>
              {state?.currentQrCodeMfaSecret}
            </AccordionElement>
            {companyData.settings.isMfaSmsEnabled && employeeData.phoneNumber
              ? (
                <AccordionElement accordionId='mfaSms' headerText='ohjeet tekstiviestillä vahvistamiseen' testId='mfaSmsAccordionElement'>
                  <div className='mb-3'>
                    Voit myös lähettää lisävarmenteen vahvistuskoodin tekstiviestillä ja antaa vahvistuskoodin kohdassa 2. Syötä autentikaattorin antama vahvistuskoodi
                  </div>
                  <SendSmsButton mfaSecretType='currentQrCodeMfaSecret' />
                </AccordionElement>
                )
              : null}
          </AccordionContainer>
        </Container>
      )}
      renderFooter={(
        <div className='modal-body-buttons'>
          <LogoutButton />
          <TotpVerifyButton handleVerifyOtp={verifyOtp} disabled={!totpToken} />
        </div>
      )}
    />
  )
}

SetupAuthenticatorAppModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default SetupAuthenticatorAppModal
