import { put, takeEvery, takeLatest } from 'redux-saga/effects'

import costCentreActions from '../actions/costCentreActions'
import denominationActions from '../actions/denominationActions'
import employeeActions from '../actions/employeeActions'
import fileActions from '../actions/fileActions'
import receiverActions from '../actions/receiverActions'
import supplierActions from '../actions/supplierActions'
import {
  createReceiver,
  createReceiverApprover,
  createReceiverFile,
  deleteReceiver,
  deleteReceiverApprover,
  deleteReceiverFile,
  fetchReceiverApprovers,
  fetchReceiverFiles,
  fetchReceivers,
  normalizeReceiverResponse,
  searchReceiver,
  updateReceiver,
  updateReceiverApprover
} from '../api/receiverApi'
import {
  createFlow,
  createSocketWatcherWithApiHandlerAndNormalizer,
  deleteFlow,
  fetchFlow,
  getSubEntitySagas,
  getSubSagas,
  searchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

const handleReceiverApiResponse = mainAction =>
  function* ({ data, denominations, costCentres, suppliers }) {
    yield put(mainAction(data))
    yield put(denominationActions.fetchSuccess(denominations))
    yield put(costCentreActions.fetchSuccess(costCentres))
    yield put(supplierActions.fetchSuccess(suppliers))
    return data
  }

const watchOnSockets = createSocketWatcherWithApiHandlerAndNormalizer('receiver', receiverActions, handleReceiverApiResponse, normalizeReceiverResponse)

export const approverApiHandler = mainAction => function* ({ data, employees }) {
  yield put(mainAction(data))
  yield put(employeeActions.fetchSuccess(employees))
  return data
}

const receiverFetchFlow = fetchFlow({
  fetchApi: fetchReceivers,
  actions: receiverActions,
  base: 'receivers',
  errorMsg: 'saajien',
  getApiResponseHandler: () => handleReceiverApiResponse(receiverActions.fetchSuccess)
})

const receiverUpdateFlow = updateFlow(updateReceiver, receiverActions, 'Saaja', 'Saajan', handleReceiverApiResponse(receiverActions.updateSuccess))
const receiverCreateFlow = createFlow(createReceiver, receiverActions, 'Saaja', 'Saajan', handleReceiverApiResponse(receiverActions.createSuccess))
const receiverSearchFlow = searchFlow(searchReceiver, receiverActions, 'Saajien', function* (data, searchTerm) {
  const receivers = yield handleReceiverApiResponse(receiverActions.fetchSuccess)(data)
  yield put(receiverActions.searchSuccess(receivers, searchTerm))
  return receivers
})
const receiverDeleteFlow = deleteFlow({
  deleteApi: deleteReceiver,
  actions: receiverActions,
  singular: 'Saaja',
  errorMsg: 'Saajan',
  base: 'receivers'
})

const fileFlows = getSubEntitySagas(receiverActions, fileActions, fetchReceiverFiles, createReceiverFile, deleteReceiverFile, 'receiverId', 'fileId', 'receivers', 'files', {
  singular: 'Saajan tiedosto',
  accusative: 'Saajan tiedoston',
  fetchError: 'Virhe saajan tiedostojen noutamisessa',
  deleteSuccess: 'Saajan tiedostolinkki poistettu',
  deleteError: 'Virhe saajan tiedostolinkin poistamisessa'
})

const {
  subFetchFlow: receiverApproversFetchFlow,
  subCreateFlow: receiverApproverCreateFlow,
  subUpdateFlow: receiverApproverUpdateFlow,
  subDeleteFlow: receiverApproverDeleteFlow
} = getSubSagas({
  mainActions: receiverActions,
  mainReduxName: 'receivers',
  subReduxName: 'approvers'
}, {
  fetchApi: fetchReceiverApprovers,
  createApi: createReceiverApprover,
  updateApi: updateReceiverApprover,
  deleteApi: deleteReceiverApprover
}, {
  singular: 'Saajan ostolaskuhyväksyjä',
  accusative: 'Saajan ostolaskuhyväksyjän',
  fetchError: 'Virhe saajan ostolaskuhyväksyjien noutamisessa',
  deleteSuccess: 'Saajan ostolaskuhyväksyjä poistettu',
  deleteError: 'Virhe saajan ostolaskuhyväksyjän poistamisessa'
}, approverApiHandler)

export default function* receiverSaga() {
  yield takeLatest(receiverActions.actionTypes.fetchRequest, receiverFetchFlow)
  yield takeEvery(receiverActions.actionTypes.updateRequest, receiverUpdateFlow)
  yield takeEvery(receiverActions.actionTypes.createRequest, receiverCreateFlow)
  yield takeLatest(receiverActions.actionTypes.searchRequest, receiverSearchFlow)
  yield takeEvery(receiverActions.actionTypes.deleteRequest, receiverDeleteFlow)

  yield takeLatest(receiverActions.files.actionTypes.fetchRequest, fileFlows.subFetchFlow)
  yield takeEvery(receiverActions.files.actionTypes.createRequest, fileFlows.subCreateFlow)
  yield takeLatest(receiverActions.files.actionTypes.deleteRequest, fileFlows.subDeleteFlow)

  yield takeEvery(receiverActions.approvers.actionTypes.fetchRequest, receiverApproversFetchFlow)
  yield takeEvery(receiverActions.approvers.actionTypes.createRequest, receiverApproverCreateFlow)
  yield takeEvery(receiverActions.approvers.actionTypes.updateRequest, receiverApproverUpdateFlow)
  yield takeEvery(receiverActions.approvers.actionTypes.deleteRequest, receiverApproverDeleteFlow)

  yield watchOnSockets()
}
