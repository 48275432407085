import CommonValueFormGroup from './CommonValueFormGroup'
import RadioFormInput from './inputs/RadioFormInput'
import { commonFormInputDefaultProps, commonFormInputPropTypes } from './propTypes'

const RadioFormGroup = props => (
  <CommonValueFormGroup {...commonFormInputDefaultProps} {...props} type='radio' Component={RadioFormInput} />
)
RadioFormGroup.propTypes = commonFormInputPropTypes

export default RadioFormGroup
