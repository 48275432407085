import constants from '../constants'
import { actionCreatorsFor } from '../helpers/actionHelpers'

const baseActionCreators = actionCreatorsFor(constants.actionKeys.WORK_TYPES)

const additionalActionCreators = {

}

const workTypeActions = { ...baseActionCreators, ...additionalActionCreators }
export default workTypeActions
