import LoginHeader from '../../containers/LoginPage/LoginHeader'
import ErrorPage from './ErrorPage'

const GatewayTimeoutErrorPage = () => (
  <>
    <LoginHeader />
    <div className='h-100 overflow-auto bg-light'>
      <ErrorPage
        statusCode={504}
        message={(
          <>
            Yhteysvirhe taustajärjestelmään.
            <br />
            Yritä hetken kuluttua uudelleen.
          </>
      )}
      />
    </div>
  </>
)

export default GatewayTimeoutErrorPage
