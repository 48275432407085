import { emptyArray } from '@evelia/helpers/constants'
import { sortByProperty } from '@evelia/helpers/helpers'
import { createCachedSelector } from 're-reselect'

import { calculateWorkRowPrice } from '../components/WorkRows/workRowHelpers'
import { getFindItemByIdSelector } from '../helpers/selectorHelpers'
import { findAuthorsOfCurrentEmployee } from './authorSelectors'
import { getIsSupervisor } from './employeeSelectors'
import { findSystemCustomerSettings } from './systemCustomerSelectors'
import { findCombinedVatRates } from './vatSelectors'
import { findWorkWithId } from './workSelectors'

export const WORK_ROW_LIMIT = 200
const sortByRank = sortByProperty('rank')

const getWorkRows = createCachedSelector(
  (state, __workId) => state.work.workRows.records,
  findWorkWithId,
  findCombinedVatRates,
  findSystemCustomerSettings,
  getIsSupervisor,
  findAuthorsOfCurrentEmployee,
  (workRows, work, combinedVatRates, settings, isSupervisor, authors) => {
    if(!work) {
      return emptyArray
    }
    const isOtherRowsHidden = settings.settings.isWorkRowsHiddenFromOtherUsers && !isSupervisor
    const filteredWorkRows = workRows
      .filter(workRow => workRow.workId === work?.id && (!isOtherRowsHidden || authors.some(author => author.id === workRow.createdBy)))
    if(!filteredWorkRows.length) {
      return emptyArray
    }
    return filteredWorkRows
      .sort(sortByRank)
      .map(calculateWorkRowPrice(work, combinedVatRates))
  }
)((__state, workId) => `${workId}`)

export const findWorkRowWithId = getFindItemByIdSelector((state, workRowId, workId) => getWorkRows(state, workId))

export const getInvoicedWorkRows = createCachedSelector(
  getWorkRows,
  workRows => workRows.filter(row => row.isInvoiced)
)((__state, workId) => `${workId}`)

export const getOpenWorkRows = createCachedSelector(
  getWorkRows,
  workRows => workRows.filter(row => !row.isInvoiced)
)((__state, workId) => `${workId}`)

export const getOpenInvoiceableWorkRows = createCachedSelector(
  getWorkRows,
  (state, workId, rowIds) => rowIds,
  (workRows, rowIds) => {
    const rows = workRows.filter(row => !row.isInvoiced && !row.preventInvoicing)
    return rowIds ? rows.filter(row => rowIds.includes(row.id)) : rows
  }
)((__state, workId) => `${workId}`)

export const getOpenWorkRowsTotalCount = createCachedSelector(
  getOpenWorkRows,
  workRows => workRows.length
)((__state, workId) => `${workId}`)

export const getLimitedOpenWorkRows = createCachedSelector(
  getWorkRows,
  (__state, __workId, useLimit) => !!useLimit,
  (__state, __workId, __useLimit, hidePreventInvoicingRows) => !!hidePreventInvoicingRows,
  (workRows, useLimit, hidePreventInvoicingRows) => {
    const workRowsForDisplay = workRows
      .filter(({ preventInvoicing, invoiceId }) =>
        !invoiceId && (!hidePreventInvoicingRows || !preventInvoicing)
      )
    return useLimit
      ? workRowsForDisplay.slice(-WORK_ROW_LIMIT)
      : workRowsForDisplay
  }
)((__state, workId, useLimit, hidePreventInvoicingRows) => `${workId}_${useLimit}_${hidePreventInvoicingRows}`)
