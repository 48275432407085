import { parseTemplate } from 'url-template'

import {
  del,
  get,
  getSubEntityApi,
  post,
  put
} from '../helpers/apiHelpers'

const baseUrl = '/api/contacts/{?q}{contactId}'

const contactUrlTemplate = parseTemplate(baseUrl)
const contactFileUrlTemplate = parseTemplate(baseUrl + '/files{/contactFileId}')
const contactCustomerUrlTemplate = parseTemplate(baseUrl + '/customers')
const contactTargetUrlTemplate = parseTemplate(baseUrl + '/targets')
export const contactWorkUrlTemplate = parseTemplate(baseUrl + '/work')
export const contactProjectUrlTemplate = parseTemplate(baseUrl + '/projects')
const contactSubItemUrlTemplate = parseTemplate(`${baseUrl}{/subItem}{/subItemId}`)

export const fetchContacts = (params = {}) =>
  get(contactUrlTemplate.expand(params))

export const updateContact = contact =>
  put(contactUrlTemplate.expand({ contactId: contact.id }), {
    body: JSON.stringify(contact)
  })

export const createContact = contact =>
  post(contactUrlTemplate.expand({}), {
    body: JSON.stringify(contact)
  })

export const searchContact = search =>
  get(contactUrlTemplate.expand({ q: search }))

export const {
  fetchApi: fetchContactFiles,
  createApi: createContactFile,
  deleteApi: deleteContactFile
} = getSubEntityApi(contactFileUrlTemplate, 'contactId', 'fileId', 'contactFileId', 'file')

const normalizeContactCustomers = response => {
  const { _embedded, records } = response
  const { customers } = _embedded || {}
  const contactCustomers = records
  return {
    contactCustomers,
    customers
  }
}

const normalizeContactTargets = response => {
  const { _embedded, records } = response
  const { targets } = _embedded || {}
  const contactTargets = records
  return {
    contactTargets,
    targets
  }
}

export const fetchContactCustomers = (contactId, params = {}) => {
  return get(contactCustomerUrlTemplate.expand({ ...params, contactId }))
    .then(normalizeContactCustomers)
}

export const fetchContactTargets = (contactId, params = {}) => {
  return get(contactTargetUrlTemplate.expand({ ...params, contactId }))
    .then(normalizeContactTargets)
}

export const deleteContact = contact => {
  return del(contactUrlTemplate.expand({ contactId: contact.id }))
}

export const fetchContactStats = ({ contactId }) =>
  get(contactSubItemUrlTemplate.expand({ contactId, subItem: 'stats' }))
