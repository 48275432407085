interface NoDataRowCellProps {
  children?: React.ReactNode
  isLoading?: boolean
}

const NoDataRowCell = ({
  children,
  isLoading
}: NoDataRowCellProps) => (
  <div
    role='row'
    className='d-flex px-2 py-1 bg-light'
    data-testid='no-data-cell'
  >
    <div
      className='dt-td form-control-sm'
      role='cell'
    >
      {isLoading
        ? 'Ladataan...'
        : <b className='text-muted'>{children}</b>}
    </div>
  </div>
)

export default NoDataRowCell
