import { call, put, select, takeEvery } from 'redux-saga/effects'

import miscellaneousActions from '../actions/miscellaneousActions'
import {
  fetchPostalCode
} from '../api/miscellaneousApi'
import {
  addErrorNotification,
  addInfoNotification,
  addSuccessNotification,
  addWarningNotification
} from '../helpers/notificationHelpers'
import {
  createSocketWatcherWithGenerator,
  genericSagaErrorHandler,
  getPromiseHandlersFromData
} from '../helpers/sagaHelpers'

function* postalCodeFetchFlow({ data = {} }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  const { postalCodeId } = data
  try {
    const cachedPostalCode = (yield select(state => state.miscellaneous.postalCodes)).records[postalCodeId]
    if(cachedPostalCode) {
      return resolve(cachedPostalCode)
    } else {
      yield put(miscellaneousActions.postalCodes.fetchStart())
      const postalCode = yield call(fetchPostalCode, data)
      yield put(miscellaneousActions.postalCodes.fetchSuccess(postalCode))
      resolve(postalCode)
    }
  } catch(err) {
    if(err.status !== 404) {
      yield * genericSagaErrorHandler(err, 'Virhe postinumeron noutamisessa')
    }
    reject(err)
  }
}

const watchOnNotificationSockets = createSocketWatcherWithGenerator('notification', {
  error: function* ({ message, ...opts }) { yield addErrorNotification(message, opts) },
  success: function* ({ message, ...opts }) { yield addSuccessNotification(message, opts) },
  warning: function* ({ message, ...opts }) { yield addWarningNotification(message, opts) },
  info: function* ({ message, ...opts }) { yield addInfoNotification(message, opts) }
})

export default function* miscellaneousSaga() {
  yield takeEvery(miscellaneousActions.postalCodes.actionTypes.fetchRequest, postalCodeFetchFlow)
  yield watchOnNotificationSockets()
}
