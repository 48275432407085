import PropTypes from 'prop-types'
import { appendValuesOrEmpty } from '@evelia/helpers/helpers'

import { childrenPropType } from '../../propTypes'

const FormButtonContainer = ({
  children,
  right,
  sticky,
  noBorder,
  noContainer
}) => (
  <div className={appendValuesOrEmpty([noContainer ? '' : 'form-buttons', right ? 'text-end' : '', sticky ? 'sticky-bottom bg-white' : '', noBorder ? 'border-start-0' : ''])}>
    {children}
  </div>
)

FormButtonContainer.propTypes = {
  children: childrenPropType.isRequired,
  right: PropTypes.bool,
  sticky: PropTypes.bool,
  noBorder: PropTypes.bool,
  noContainer: PropTypes.bool
}

export default FormButtonContainer
