import { emptyArray } from '@evelia/helpers/constants'
import { capitalize } from '@evelia/helpers/helpers'
import { Action, ThunkDispatch } from '@reduxjs/toolkit'
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition
} from '@reduxjs/toolkit/query'
import { createApi } from '@reduxjs/toolkit/query/react'
import { Draft, Patch } from 'immer'
import isObject from 'lodash/isObject'
import snakeCase from 'lodash/snakeCase'

import { addErrorNotification, addSuccessNotification } from '../../helpers/notificationHelpers'
import { getSocket } from '../../socket'
import { BaseIdModel } from '../types/baseModelTypes'
import { ApiRecordResponse, ApiResponse } from '.'
import { getBaseQuery } from './apiHelpers'

type ToSingularString<S extends string> = S extends `${infer P1}s` ? P1 : S

type NotificationMessages = {
  errorMessage?: string
  successMessage?: string
}

type ServerMessageError = {
  error?: { data?: { message?: string } }
}

type GetRecordsQueryDefinition<
  TDataModel extends BaseIdModel,
  TQueryArgs,
  TPath extends string
  > = Omit<
  QueryDefinition<TQueryArgs, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, NonNullable<unknown>, FetchBaseQueryMeta>, TPath, ApiResponse<TDataModel>, `${ToSingularString<TPath>}Api`>,
  'type'>

type UpdateRecipe<T> = (data: T) => void | T
type PatchCollection = { patches: Patch[], inversePatches: Patch[], undo: () => void }
export type UpdateCacheData<T> = (updateRecipe: UpdateRecipe<T | Draft<T>>) => PatchCollection

const hasServerErrorMessage = (error: unknown): error is ServerMessageError => {
  return isObject(error) && 'error' in error &&
        isObject(error.error) && 'data' in error.error &&
        isObject(error.error.data) && 'message' in error.error.data
}

export const createNotification = async(queryFulfilled: Promise<unknown>, { errorMessage, successMessage }: NotificationMessages) => {
  try {
    await queryFulfilled
    if(successMessage) {
      addSuccessNotification(successMessage)
    }
  } catch(error: unknown) {
    const message = hasServerErrorMessage(error) ? (error?.error?.data?.message ?? errorMessage) : errorMessage
    if(message) {
      addErrorNotification(message)
    }
  }
}

const isRecordInCache = <TDataModel extends BaseIdModel >({ data }: { data?: { records: TDataModel[] } }, id: number | undefined) => {
  return id && data?.records.some(record => record.id === id)
}

interface CreateCrudApiProps<TDataModel extends BaseIdModel, TQueryArgs, TPath extends string> {
  path: TPath
  queryDefinition: GetRecordsQueryDefinition<TDataModel, TQueryArgs, TPath>
  socketMatchers?: {
    created?: (record: TDataModel, queryArgs: TQueryArgs, updateCachedData: UpdateCacheData<ApiResponse<TDataModel>>) => boolean
    updated?: (record: TDataModel, queryArgs: TQueryArgs) => boolean
  }
  embeddedHandler?: (dispatch: ThunkDispatch<unknown, unknown, Action>, embedded: ApiResponse<TDataModel>['_embedded']) => void | Promise<void>
  invalidateOnCreate?: boolean
  titles: {
    singular: string
    plural?: string
    genetive: string
    pluralGenetive: string
  }
}

export const createCRUDApi = <TDataModel extends BaseIdModel, TQueryArgs, TPath extends string>({
  path,
  queryDefinition,
  socketMatchers,
  embeddedHandler,
  invalidateOnCreate,
  titles
}: CreateCrudApiProps<TDataModel, TQueryArgs, TPath>) => {
  const singularPath = path.endsWith('s') ? path.slice(0, -1) : path
  const baseQueryPath = snakeCase(path)

  const api = createApi({
    reducerPath: `${singularPath}Api`,
    baseQuery: getBaseQuery(baseQueryPath),
    tagTypes: [path],
    endpoints: builder => ({
      // @ts-expect-error "Type '((arg: TQueryArgs) => string | FetchArgs) | undefined' is not assignable to type '(arg: TQueryArgs) => string | FetchArgs'." - can't find where I could type it as non nullable
      getRecords: builder.query<ApiResponse<TDataModel>, TQueryArgs>({
        onQueryStarted: async(__args, { queryFulfilled }) => createNotification(queryFulfilled, {
          errorMessage: `Virhe ${titles.pluralGenetive} haussa`
        }),
        providesTags: [{ type: path }],
        onCacheEntryAdded: async(queryArgs, {
          updateCachedData,
          cacheDataLoaded,
          cacheEntryRemoved,
          getCacheEntry,
          dispatch
        }) => {
          const socket = getSocket()
          try {
            await cacheDataLoaded
            socket.on(`${singularPath}:created`, (_channel: string, data: ApiRecordResponse<TDataModel>) => {
              if(!socketMatchers?.created?.(data.record, queryArgs, updateCachedData)) {
                return
              }
              updateCachedData(draft => {
                (draft.records as TDataModel[]).push(data.record)
                if(draft._embedded?.options?.ids) {
                  (draft._embedded.options.ids as number[]).push(data.record.id)
                }
                if(draft._embedded?.options?.totalCount != null) {
                  draft._embedded.options.totalCount++
                }
              })
              embeddedHandler?.(dispatch, data._embedded)
            })
            socket.on(`${singularPath}:updated`, (_channel: string, data: ApiRecordResponse<TDataModel>) => {
              const isMatch = socketMatchers?.updated != null ? socketMatchers.updated(data.record, queryArgs) : isRecordInCache(getCacheEntry(), data?.record.id)
              if(!isMatch) {
                return
              }
              updateCachedData(draft => {
                const index = draft.records.findIndex(record => record.id === data.record.id)
                draft.records[index] = data.record
              })
              embeddedHandler?.(dispatch, data._embedded)
            })
            socket.on(`${singularPath}:deleted`, (_channel: string, { id }: BaseIdModel) => {
              if(!isRecordInCache(getCacheEntry(), id)) {
                return
              }
              updateCachedData(draft => {
                draft.records = draft.records.filter(record => record.id !== id) as TDataModel[]
                if(draft._embedded?.options?.ids) {
                  draft._embedded.options.ids = draft._embedded.options.ids.filter(draftId => draftId !== id) as number[]
                }
                if(draft._embedded?.options?.totalCount != null) {
                  draft._embedded.options.totalCount = Math.max(0, draft._embedded.options.totalCount - 1)
                }
              })
            })
          } catch{
          }
          await cacheEntryRemoved
          socket.off(`${singularPath}:created`)
          socket.off(`${singularPath}:updated`)
          socket.off(`${singularPath}:deleted`)
        },
        ...queryDefinition
      }),
      getRecord: builder.query<ApiRecordResponse<TDataModel>, number>({
        query: id => `/${id}`,
        providesTags: (__result, __error, id) => [{ type: path, id }],
        onQueryStarted: async(__args, { queryFulfilled }) => createNotification(queryFulfilled, {
          errorMessage: `Virhe ${titles.genetive} haussa`
        }),
        onCacheEntryAdded: async(queryId, {
          updateCachedData,
          cacheDataLoaded,
          cacheEntryRemoved,
          dispatch
        }) => {
          const socket = getSocket()
          try {
            await cacheDataLoaded
            socket.on(`${singularPath}:updated`, (_channel: string, data: ApiRecordResponse<TDataModel>) => {
              if(data?.record.id !== queryId) {
                return
              }
              updateCachedData(draft => {
                draft.record = data.record
              })
              embeddedHandler?.(dispatch, data._embedded)
            })
            socket.on(`${singularPath}:deleted`, (_channel: string, { id }: BaseIdModel) => {
              // Returned id is PurchaseOrderRow.id so we can't filter based on equality of purchaseOrderId = queryArgs.id
              if(id !== queryId) {
                return
              }
              updateCachedData(draft => {
                // Remove the result from cache - otherwise it's possible to navigate back to deleted record page
                // + this avoids unnecessary "Record not found" error message after deletion
                draft.record = undefined as unknown as TDataModel
              })
            })
          } catch{
          }
          await cacheEntryRemoved
          socket.off(`${singularPath}:updated`)
          socket.off(`${singularPath}:deleted`)
        }
      }),
      createRecord: builder.mutation<ApiRecordResponse<TDataModel>, Omit<TDataModel, 'id'>>({
        query: body => ({
          url: '',
          method: 'POST',
          body
        }),
        invalidatesTags: invalidateOnCreate ? [{ type: path }] : [],
        onQueryStarted: async(__args, { queryFulfilled }) => createNotification(queryFulfilled, {
          successMessage: `${capitalize(titles.singular)} luotu`,
          errorMessage: `Virhe ${titles.genetive} luonnissa`
        })
      }),
      updateRecord: builder.mutation<ApiRecordResponse<TDataModel>, TDataModel>({
        query: body => ({
          url: `/${body.id}`,
          method: 'PUT',
          body
        }),
        onQueryStarted: async(__args, { queryFulfilled }) => createNotification(queryFulfilled, {
          successMessage: `${capitalize(titles.singular)} päivitetty`,
          errorMessage: `Virhe ${titles.genetive} päivityksessä`
        })
      }),
      deleteRecord: builder.mutation<void, BaseIdModel>({
        query: body => ({
          url: `/${body.id}`,
          method: 'DELETE'
        }),
        onQueryStarted: async(__args, { queryFulfilled }) => createNotification(queryFulfilled, {
          successMessage: `${capitalize(titles.singular)} poistettu`,
          errorMessage: `Virhe ${titles.genetive} poistossa`
        })
      })
    })
  })

  const useMutationsHook = () => {
    const [create] = api.useCreateRecordMutation()
    const [update] = api.useUpdateRecordMutation()
    const [remove] = api.useDeleteRecordMutation()
    return { create, update, remove }
  }

  const useRecord = (id: number | undefined): Omit<ReturnType<typeof api.useGetRecordQuery>, 'data'> & { data: TDataModel | undefined } => {
    return api.useGetRecordQuery(id!, {
      skip: id == null,
      selectFromResult: response => ({
        ...response,
        data: response?.data?.record
      })
    })
  }

  const useRecords = (args: TQueryArgs): Omit<ReturnType<typeof api.useGetRecordsQuery>, 'data'> & { data: TDataModel[] } => {
    return api.useGetRecordsQuery(args, {
      selectFromResult: response => ({
        ...response,
        data: response?.data?.records ?? emptyArray
      })
    })
  }

  return { api, useRecord, useRecords, useMutationsHook }
}
