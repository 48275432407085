import { createApi } from '../helpers/apiHelpers'

export const accountApi = createApi({
  base: 'accounts'
})

export const createAccountsBulk = payload => accountApi.create(payload, { subItem: 'bulk' })
export const updateAccountsBulk = payload => accountApi.update(payload, { subItem: 'bulk' })

export const fetchDefaultAccounts = () => accountApi.fetch({ subItem: 'default_accounts' })
