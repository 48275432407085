import CommonValueFormGroup from './CommonValueFormGroup'
import DateFormInput from './inputs/DateFormInput'
import { commonFormInputDefaultProps, commonFormInputPropTypes } from './propTypes'

const DateFormGroup = props => (
  <CommonValueFormGroup
    {...commonFormInputDefaultProps}
    {...props}
    setNull
    forceFeedback
    Component={DateFormInput}
  />
)
DateFormGroup.propTypes = commonFormInputPropTypes

export default DateFormGroup
