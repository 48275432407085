import {
  all,
  put,
  takeEvery,
  takeLatest,
  takeLeading
} from 'redux-saga/effects'

import systemMessageActions from '../actions/systemMessageActions'
import { normalizeSystemMessages, systemMessageApi } from '../api/systemMessageApi'
import systemMessageTitles, { systemMessageTitlesCapitalized } from '../components/SystemMessage/systemMessageTitles'
import { actionTypes } from '../constants'
import {
  createActionFlow,
  createFlow,
  createSocketWatcherWithApiHandlerAndNormalizer,
  deleteFlow,
  fetchFlow
} from '../helpers/sagaHelpers'

const titles = systemMessageTitles
const titlesCapitalized = systemMessageTitlesCapitalized
const reduxName = 'systemMessages'

const handleSystemMessageApiResponse = (mainAction, tableIdentifier) =>
  function* ({ data, tableOptions }) {
    yield put(mainAction(data))

    if(tableIdentifier != null && tableOptions && tableOptions.orderBy != null) {
      yield put(systemMessageActions.tableActions.updateOptions(tableOptions, tableIdentifier))
    }
    return data
  }

const handleSystemMessageMutateApiResponse = mainAction => function* ({ data }) {
  if(Array.isArray(data)) {
    yield all(data.map(record => put(mainAction(record))))
  } else {
    yield put(mainAction(data))
  }
  return data
}

const systemMessagePatchActionFlow = createActionFlow(systemMessageApi.patch, systemMessageActions, handleSystemMessageMutateApiResponse)

const watchOnSystemMessageSockets = createSocketWatcherWithApiHandlerAndNormalizer('systemMessage', systemMessageActions, handleSystemMessageApiResponse, normalizeSystemMessages)

const systemMessageFetchFlow = fetchFlow({
  fetchApi: systemMessageApi.fetch,
  actions: systemMessageActions,
  base: reduxName,
  idField: 'id',
  errorMsg: titles.genetive,
  getApiResponseHandler: data => handleSystemMessageApiResponse(systemMessageActions.fetchSuccess, data.tableIdentifier)
})

const systemMessageCreateFlow = createFlow(
  systemMessageApi.create,
  systemMessageActions,
  titlesCapitalized.basic,
  titles.genetive,
  handleSystemMessageMutateApiResponse(systemMessageActions.createSuccess))

const systemMessageDeleteFlow = deleteFlow({
  deleteApi: systemMessageApi.remove,
  actions: systemMessageActions,
  singular: titlesCapitalized.basic,
  errorMsg: titlesCapitalized.genetive,
  base: reduxName
})

export default function* systemMessageSaga() {
  yield takeLatest(systemMessageActions.actionTypes.fetchRequest, systemMessageFetchFlow)
  yield takeLatest(systemMessageActions.actionTypes.createRequest, systemMessageCreateFlow)
  yield takeLeading(actionTypes.SYSTEM_MESSAGE_PATCH_ACTION_REQUEST, systemMessagePatchActionFlow)
  yield takeEvery(systemMessageActions.actionTypes.deleteRequest, systemMessageDeleteFlow)

  yield all([
    watchOnSystemMessageSockets()
  ])
}
