import CommonValueFormGroup from './CommonValueFormGroup'
import ButtonCheckFormInput from './inputs/ButtonCheckFormInput'
import { commonFormInputDefaultProps, commonFormInputPropTypes } from './propTypes'

const ButtonCheckFormGroup = props => (
  <CommonValueFormGroup {...commonFormInputDefaultProps} {...props} Component={ButtonCheckFormInput} />
)
ButtonCheckFormGroup.propTypes = commonFormInputPropTypes

export default ButtonCheckFormGroup
