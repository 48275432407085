import { createEntityAdapter } from '@reduxjs/toolkit'

import { MemoModel } from '../api/rtk/memoApi'
import { createGenericSlice } from '../helpers/sliceHelpers'
import { EveliaRootState } from '../reducerTypes'

const purchaseOrderMemoAdapter = createEntityAdapter<MemoModel, number>({ selectId: purchaseOrderMemo => purchaseOrderMemo.id })

const purchaseOrderMemoSlice = createGenericSlice({
  name: 'memos',
  entityAdapter: purchaseOrderMemoAdapter
})

export const purchaseOrderMemoEntitySelectors = purchaseOrderMemoAdapter.getSelectors<EveliaRootState>(state => state.purchaseOrders.memos)
export const purchaseOrderMemoActions = purchaseOrderMemoSlice.actions

export default purchaseOrderMemoSlice
