import { timeRecordExtraTimeTypes, timeRecordSystemTypes } from '@evelia/helpers/constants'
import { sortByProperty } from '@evelia/helpers/helpers'
import sortBy from 'lodash/sortBy'
import { createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'

import { getFindItemByIdSelector, getFindItemsByIdsSelector } from '../helpers/selectorHelpers'
import { findCurrentEmployee, findEmployeeWithId } from './employeeSelectors'

const getTimeRecordsFromArgument = arg => arg?.timeRecords?.records ?? arg
const getTimeRecordTypesFromArgument = arg => arg?.timeRecords?.timeRecordTypes?.records ?? arg
export const getTimeRecordSitesFromArgument = arg => arg?.timeRecords?.timeRecordSites?.records ?? arg
const getTimeRecordExtraTimesFromArgument = arg => arg?.timeRecords?.timeRecordExtraTimes?.records ?? arg
const getTimeRecordExtraTimeStatsFromArgument = arg => arg?.timeRecords?.timeRecordExtraTimes?.stats?.records ?? arg

const sortTimeRecords = timeRecords => sortBy(timeRecords, ['startedAt', 'id'])
const sortTimeRecordTypes = timeRecordTypes => sortBy(timeRecordTypes, ['systemType', 'id'])

const findCurrentTimeRecordOfEmployee = createCachedSelector(
  getTimeRecordsFromArgument,
  (state, { employeeId } = {}) => Number(employeeId),
  (timeRecords, employeeId) => timeRecords.find(timeRecord => timeRecord.employeeId === employeeId && timeRecord.endTime == null)
)((state, { employeeId } = {}) => `${employeeId}`)

const findCurrentTimeRecordOfCurrentEmployee = createSelector(
  getTimeRecordsFromArgument,
  findCurrentEmployee,
  (timeRecords, employee) => timeRecords.find(timeRecord => timeRecord.employeeId === employee.id && timeRecord.endTime == null)
)

export const getTimeRecords = createSelector(
  getTimeRecordsFromArgument,
  timeRecords => sortTimeRecords(timeRecords)
)

export const getTimeRecordTypes = createSelector(
  getTimeRecordTypesFromArgument,
  timeRecordTypes => sortTimeRecordTypes(timeRecordTypes.filter(timeRecordType => !timeRecordType.isSystem && !timeRecordType.isDeleted))
)

export const getSelectableTimeRecordTypes = createSelector(
  getTimeRecordTypes,
  timeRecordTypes => timeRecordTypes.filter(timeRecordType => timeRecordType.systemType !== timeRecordSystemTypes.TIME_RECORD_SYSTEM_TYPE_AWAY)
)

const findTimeRecordTypesWithIdList = getFindItemsByIdsSelector(getTimeRecordTypesFromArgument)

export const getSelectableTimeRecordTypesByIds = createCachedSelector(
  findTimeRecordTypesWithIdList,
  timeRecordTypes => getTimeRecordTypes(timeRecordTypes).filter(timeRecordType => timeRecordType.systemType !== timeRecordSystemTypes.TIME_RECORD_SYSTEM_TYPE_AWAY)
)((state, timeRecordTypeIds) => JSON.stringify(timeRecordTypeIds))

const filterTimeRecordsByEmployee = (timeRecordTypes, employee) => {
  const employeeRoleIdsOfEmployee = employee.employeeRoleIds
  return timeRecordTypes.filter(timeRecordType =>
    (employeeRoleIdsOfEmployee.some(value => timeRecordType.employeeRoleIds.includes(value)) || !timeRecordType.employeeRoleIds.length)
  )
}

export const getTimeRecordTypesOfCurrentEmployee = createSelector(
  getTimeRecordTypes,
  findCurrentEmployee,
  filterTimeRecordsByEmployee
)

export const getTimeRecordTypesOfEmployee = createCachedSelector(
  getTimeRecordTypes,
  findEmployeeWithId,
  filterTimeRecordsByEmployee
)((state, employeeId) => `${employeeId}`)

export const getSystemTimeRecordTypes = createSelector(
  getTimeRecordTypesFromArgument,
  timeRecordTypes => sortTimeRecordTypes(timeRecordTypes.filter(timeRecordType => timeRecordType.isSystem))
)

export const findTimeRecordTypeWithId = getFindItemByIdSelector(getTimeRecordTypesFromArgument)

export const findCurrentTimeRecordType = createSelector(
  findCurrentTimeRecordOfCurrentEmployee,
  getTimeRecordTypesFromArgument,
  (currentTimeRecord, timeRecordTypes) => currentTimeRecord ? findTimeRecordTypeWithId(timeRecordTypes, currentTimeRecord.timeRecordTypeId) : null
)

export const findCurrentTimeRecordTypeOfEmployee = createCachedSelector(
  findCurrentTimeRecordOfEmployee,
  getTimeRecordTypesFromArgument,
  (currentTimeRecord, timeRecordTypes) => currentTimeRecord ? findTimeRecordTypeWithId(timeRecordTypes, currentTimeRecord.timeRecordTypeId) : null
)((state, { employeeId }) => `${employeeId}`)

const sortByDate = sortByProperty('date', true)

export const getTimeRecordExtraTimes = createSelector(
  getTimeRecordExtraTimesFromArgument,
  timeRecordExtraTimes => timeRecordExtraTimes.sort(sortByDate)
)

export const getTimeRecordExtraTimeStats = createCachedSelector(
  getTimeRecordExtraTimeStatsFromArgument,
  (state, opts) => opts?.employeeId ?? findCurrentEmployee(state)?.id,
  (timeRecordExtraTimeStats, employeeId) => timeRecordExtraTimeStats[employeeId]
)((state, { employeeId }) => `${employeeId}`)

export const getTimeRecordLunchTypes = createSelector(
  getTimeRecordTypes,
  timeRecordTypes => timeRecordTypes.filter(timeRecordType => timeRecordType.systemType === timeRecordSystemTypes.TIME_RECORD_SYSTEM_TYPE_LUNCH)
)

const findExtraTimeByDateAndType = (timeRecordExtraTimes, date, type) => timeRecordExtraTimes.find(extraTime => extraTime.date === date && extraTime.type === type)?.value || 0

export const getTimeRecordExtraTimesByDate = createSelector(
  getTimeRecordExtraTimes,
  (state, date) => date,
  (timeRecordExtraTimes, date) => {
    return {
      date,
      flexitime: findExtraTimeByDateAndType(timeRecordExtraTimes, date, timeRecordExtraTimeTypes.TIME_RECORD_EXTRA_TIME_TYPE_FLEXITIME),
      overtime: findExtraTimeByDateAndType(timeRecordExtraTimes, date, timeRecordExtraTimeTypes.TIME_RECORD_EXTRA_TIME_TYPE_OVERTIME)
    }
  }
)
