import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { faExternalLink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const onClick = noPreventDefault => event => {
  // Link is used to give users possibility to open links to new window.
  // Tables have their own onRowClick events therefore stopPropagation to prevent duplicate redirect call
  if(event.ctrlKey || event.metaKey) {
    event.stopPropagation()
    return
  }
  if(!noPreventDefault) {
    // Allow table to handle click actions
    event.preventDefault()
  }
}

const TableLink = ({ to, children, disabled, noPreventDefault }) => disabled
  ? children
  : (
    <Link
      data-testid='tableLink'
      to={to}
      onClick={onClick(noPreventDefault)}
    >
      {children || <FontAwesomeIcon icon={faExternalLink} />}
    </Link>
    )

TableLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  disabled: PropTypes.bool,
  noPreventDefault: PropTypes.bool
}

export default TableLink
