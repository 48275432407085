import { createApi, getNormalizers } from '../helpers/apiHelpers'

const normalizers = getNormalizers('file', false)

export const normalizeFileLinkResponse = response =>
  Array.isArray(response)
    ? normalizers.normalizeListResponse(response)
    : normalizers.normalizeResponse(response)

export const createFileLinkApi = (base, baseIdFieldName) => {
  if(!baseIdFieldName) {
    throw new Error('baseIdFieldName is required on createFileLinkApi')
  }
  const editGetParams = (baseId, payload) =>
    ({ id: baseId, subItem: 'files', subItemId: payload?.id })

  const editMutateParams = (params, payload) =>
    ({ id: params[baseIdFieldName] || payload[baseIdFieldName], subItem: 'files', subItemId: params.id })

  return createApi({
    base,
    normalizer: normalizeFileLinkResponse,
    editGetParams,
    editMutateParams
  })
}
