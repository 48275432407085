import { parseTemplate } from 'url-template'

import { del, get, post, put } from '../helpers/apiHelpers'
import { normalizeWorkList } from './workApi'

const baseUrl = '/api/calendar_events{/type}{/calendarEventId}{?startDate,endDate}'
const calendarEventUrlTemplate = parseTemplate(baseUrl)

export const fetchCalendarEvents = (params = {}) => {
  return get(calendarEventUrlTemplate.expand(params))
    .then(parseCalendarEventFetchResponse)
}

export const updateCalendarEvent = (calendarEvent, params = {}) => {
  return put(calendarEventUrlTemplate.expand({ ...params, calendarEventId: calendarEvent.id }), {
    body: JSON.stringify(calendarEvent)
  }).then(parseCalendarEventFetchResponse)
}

export const createCalendarEvent = (calendarEvent, params = {}) => {
  return post(calendarEventUrlTemplate.expand(params), {
    body: JSON.stringify(calendarEvent)
  }).then(parseCalendarEventFetchResponse)
}

export const deleteCalendarEvent = (calendarEvent, params = {}) => {
  return del(calendarEventUrlTemplate.expand({ ...params, calendarEventId: calendarEvent.id }))
}

export const parseCalendarEventFetchResponse = response => [
  response.record || response.records,
  normalizeWorkList(response._embedded.work)
]
