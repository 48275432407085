import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { appendEitherOrEmpty } from '@evelia/helpers/helpers'
import { faCubes } from '@fortawesome/free-solid-svg-icons'
import isFunction from 'lodash/isFunction'

import ProjectSearchItem from '../../containers/ProjectPage/ProjectSearchItem'
import { targetPropType } from '../../propTypes'
import { findTargetWithId } from '../../selectors/targetSelectors'
import { contentRowWithIcon } from '../Details/ProjectDetails'
import PurchaseOrderDetails from '../Details/PurchaseOrderDetails'

export const renderPostalSearchItemInline = (item, numberField, { prependComponent, appendComponent } = {}) => (
  <span>
    {isFunction(prependComponent) && prependComponent(item)}
    {appendEitherOrEmpty(item[numberField], item.name)}&nbsp;
    {appendEitherOrEmpty(item.nameExtension, item.nameExtension2)}
    {(item.address || item.postalCode || item.city) &&
      <small> {appendEitherOrEmpty(item.address, item.postalCode, ' | ')} {item.city || ''}</small>}
    {isFunction(appendComponent) && appendComponent(item)}
  </span>
)

export const renderContactSearchItemInline = (item, numberField, { prependComponent, appendComponent } = {}) => (
  <span>
    {isFunction(prependComponent) && prependComponent(item)}
    {item[numberField] || ''}
    {appendEitherOrEmpty(item.firstName, item.lastName)}&nbsp;
    {appendEitherOrEmpty(item.nameExtension, item.nameExtension2)}
    {(item.email || item.phoneNumber) &&
      <small> {appendEitherOrEmpty(item.email, item.phoneNumber, ' | ')}</small>}
    {isFunction(appendComponent) && appendComponent(item)}
  </span>
)

export const PostalSearchItem = ({ item, extra }) => (
  <span>
    {item.name} {extra}
    {(item.nameExtension || item.nameExtension2) && (
      <>
        <br />
        <small>{appendEitherOrEmpty(appendEitherOrEmpty(item.nameExtension, item.nameExtension2), item.businessId, ' | ')}
        </small>
      </>
    )}
    {(item.address || item.postalCode || item.city) && (
      <>
        <br />
        <small>{appendEitherOrEmpty(item.address, item.postalCode, ' | ')} {item.city || ''}</small>
      </>
    )}
    {(item.phoneNumber || item.email) && (
      <>
        <br />
        <small>{appendEitherOrEmpty(item.phoneNumber, item.email, ' | ')}</small>
      </>
    )}
  </span>
)

PostalSearchItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    nameExtension: PropTypes.string,
    nameExtension2: PropTypes.string,
    businessId: PropTypes.string,
    address: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string
  }),
  extra: PropTypes.node
}

export const renderPostalSearchItem = item => <PostalSearchItem item={item} />

export const renderContactSearchItem = item => (
  <span>
    {appendEitherOrEmpty(item.firstName, item.lastName)}&nbsp;
    {appendEitherOrEmpty(item.nameExtension, item.nameExtension2)}
    {(item.email || item.phoneNumber) && (
      <>
        <br />
        <small>{appendEitherOrEmpty(item.email, item.phoneNumber, ' | ')}</small>
      </>
    )}
  </span>
)

export const renderProjectSearchItem = item => <ProjectSearchItem item={item} />

export const renderTargetSearchItem = item => <TargetSearchItem item={item} />

export const renderDeliveryTypeSearchItem = item => <DeliveryTypeSearchItem item={item} />

export const renderPurchaseOrderSearchItem = item => <PurchaseOrderDetails item={item} />

export const DeliveryTypeSearchItem = ({ item }) => (
  <span>
    <span className='fw-bold'>{item.code} ({item.type})</span> {item.name}
    {(item.address || item.postalCode || item.city) && (
      <>
        <br />
        <small>{appendEitherOrEmpty(item.address, item.postalCode, ' | ')} {item.city || ''}</small>
      </>
    )}
  </span>
)

DeliveryTypeSearchItem.propTypes = {
  item: PropTypes.shape({
    code: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string
  })
}

export const TargetSearchItem = ({ item: target }) => {
  const targetPath = target && target.targetPath.split('.').map(targetId => Number(targetId))
  const mainTargetOfTarget = useSelector(state => targetPath?.length > 1 ? findTargetWithId(state, targetPath[0]).name : null)

  return (
    <span>
      {appendEitherOrEmpty(target.targetNumber, target.name)}&nbsp;
      {appendEitherOrEmpty(target.nameExtension, target.nameExtension2)} <br />
      <small>{contentRowWithIcon(mainTargetOfTarget, faCubes)}</small>
      {(target.address || target.postalCode || target.city) &&
        <small> {appendEitherOrEmpty(target.address, target.postalCode, ' | ')} {target.city || ''}</small>}
    </span>
  )
}

TargetSearchItem.propTypes = {
  item: targetPropType
}
