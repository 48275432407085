import { ReactNode, useRef } from 'react'
import {
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  HeaderContext,
  Meta,
  Row,
  RowData,
  TableMeta,
  TableOptions,
  useReactTable
} from '@tanstack/react-table'
import { clsx } from 'clsx'

import './dataTable.scss'
import { dataTableSortingFunctions, defaultTextColumn } from './dataTableComponents'
import DataTableFooter from './DataTableFooter'
import DataTableHeader from './DataTableHeader'
import { globalStringFilterFn } from './dataTableHelpers'
import DataTablePagination from './DataTablePagination'
import DataTableRow, { CommonCellProps } from './DataTableRow'

interface HeaderProps<TData, TValue> {
  className?: string
  getClassName?: (context: HeaderContext<TData, TValue>) => string
  style?: React.CSSProperties
}

declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface CellContext<TData, TValue> {
    isEditable: boolean
    isDeletable: boolean
    toggleRowEditingApi: {
      toggle: () => void
      disable: () => void
      enable: () => void
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData, TValue> {
    colProps?: {
      className?: string
      style?: React.CSSProperties
      onlyPinOnEdit?: boolean
      isEditable?: boolean
    } & Record<string, unknown>
    headerProps?: HeaderProps<TData, TValue>
    footerProps?: HeaderProps<TData, TValue> & { boldClassName?: string }
    cellProps?: {
      className?: string
      getClassName?: (row: Row<TData>) => string
      style?: React.CSSProperties
    } & Record<string, unknown>
    isHidden?: boolean
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Meta<TData> extends TableMeta<TData> {
    onEdit?: (...args: unknown[]) => unknown
    onCancelEdit?: (id: number | string | null) => false | void
    getRowProps?: (row: Row<TData>) => { formRowProps?: object, isEditable?: boolean, isDeletable?: boolean, className?: string, useAggregateCells?: boolean, defaultEditValues?: Partial<TData> }
    customRowContent?: (row: Row<TData>, common: CommonCellProps<TData>, meta: Meta<TData>) => ReactNode
    isDeleteRowsEnabled?: boolean
    editingRows?: Set<string>
  }
}

export interface DataTableProps<T, TMeta> extends Omit<TableOptions<T>, 'getCoreRowModel' | 'defaultColumn' | 'meta'> {
  onRowClick?: (model: T) => void
  testId?: string
  enableEditing?: boolean
  containerClassName?: string
  noDataContent?: ReactNode
  showFooter?: boolean
  pageSizeOptions?: number[]
  defaultPageSize?: number
  defaultColumn?: Record<string | number | symbol, unknown>
  rowIdField?: string
  isNewRow?: (model: T) => boolean
  isVirtualTotalCount?: boolean
  meta?: Meta<T> & TMeta
}

const DataTable = <TData extends RowData, TMeta>({
  data,
  columns,
  state, // Pass state instead of individual state objects to allow uncontrolled table actions like col resizing
  onRowSelectionChange,
  onRowClick,
  onGlobalFilterChange,
  onColumnSizingChange,
  onSortingChange,
  onPaginationChange,
  globalFilterFn = globalStringFilterFn,
  onColumnVisibilityChange,
  meta,
  testId,
  defaultColumn = defaultTextColumn,
  enableEditing,
  enableGrouping,
  containerClassName = '',
  noDataContent,
  showFooter,
  rowIdField = 'id',
  isNewRow,
  isVirtualTotalCount = false,
  ...restTableOptions
}: DataTableProps<TData, TMeta>) => {
  const tableOptions: TableOptions<TData> = {
    data,
    columns,
    columnResizeMode: 'onChange',
    state,
    defaultColumn,
    onRowSelectionChange,
    onColumnVisibilityChange,
    onColumnSizingChange,
    onGlobalFilterChange,
    onSortingChange,
    onPaginationChange,
    globalFilterFn,
    meta,
    sortingFns: dataTableSortingFunctions,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: restTableOptions.manualSorting ? undefined : getSortedRowModel(),
    getFilteredRowModel: restTableOptions.manualFiltering ? undefined : getFilteredRowModel(),
    getGroupedRowModel: enableGrouping ? getGroupedRowModel() : undefined,
    enableGrouping,
    ...restTableOptions
  }
  const table = useReactTable(tableOptions)

  const { rows } = table.getRowModel()
  const tableContainerRef = useRef(null)

  return (
    <>
      {onPaginationChange
        ? <DataTablePagination table={table} isVirtualTotalCount={isVirtualTotalCount} />
        : null}
      <div ref={tableContainerRef} className={`data-table-container ${containerClassName}`}>
        <div
          className={clsx('data-table table table-sm', (enableEditing || onRowClick) && 'table-hover')}
          role='table'
          data-testid={testId}
        >
          <div
            className='dt-thead table-success'
            role='rowgroup'
          >
            {table.getHeaderGroups().map(headerGroup => (
              <div
                key={headerGroup.id}
                className='dt-th'
                role='row'
              >
                {headerGroup.headers.map(header => (
                  <DataTableHeader
                    key={header.id}
                    header={header}
                  />
                ))}
              </div>
            ))}
          </div>
          <div
            className='dt-tbody'
            role='rowgroup'
          >
            {rows.length
              ? rows.map(row => (
                <DataTableRow
                  key={row.id}
                  row={row}
                  enableEditing={!!enableEditing}
                  onRowClick={onRowClick}
                  meta={meta}
                  rowIdField={rowIdField}
                  isNewRow={isNewRow}
                />
              ))
              : noDataContent}
          </div>
          {showFooter && (
            <div
              className='dt-tfooter'
              role='rowgroup'
            >
              {table.getFooterGroups().map(footerGroup =>
                footerGroup.headers.some(footer => footer.column.columnDef.footer) && ( // render row only if there's footer spec on some column
                  <div
                    key={footerGroup.id}
                    className='dt-th'
                    role='row'
                  >
                    {footerGroup.headers.map(footer => (
                      <DataTableFooter
                        key={footer.id}
                        footer={footer}
                      />
                    ))}
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      {onPaginationChange
        ? <DataTablePagination table={table} isVirtualTotalCount={isVirtualTotalCount} />
        : null}
    </>
  )
}

export default DataTable
