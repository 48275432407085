import { useEffect, useRef } from 'react'

const mouseEvent = 'mousedown'
const touchEvent = 'touchend'

const useOnClickOutside = (onClickAway, isDisabled, ignoreClicksOn) => {
  const ref = useRef()

  useEffect(() => {
    if(isDisabled) {
      return
    }
    const handleEvents = event => {
      // role=dialog appears on date pickers
      if((ref.current && ref.current.contains(event.target)) || event.target.closest?.('[role=dialog]') || ignoreClicksOn?.(event)) {
        return
      }
      onClickAway(event)
    }
    const handleOnBlurEvent = event => handleEvents(event)

    document.addEventListener(mouseEvent, handleEvents)
    document.addEventListener(touchEvent, handleEvents)
    document.addEventListener('blur', handleOnBlurEvent)

    return () => {
      document.removeEventListener(mouseEvent, handleEvents)
      document.removeEventListener(touchEvent, handleEvents)
      document.removeEventListener('blur', handleOnBlurEvent)
    }
  }, [ignoreClicksOn, isDisabled, onClickAway])
  return ref
}

export default useOnClickOutside
