import constants from '../constants'
import { actionCreatorsFor } from '../helpers/actionHelpers'
import { PATH_FORM_TEMPLATES } from '../routes'
import { navigateTo } from './navigateActions'

const getUrl = formTemplateId => `${PATH_FORM_TEMPLATES}/${formTemplateId || 'new'}`

const baseActionCreators = actionCreatorsFor(constants.actionKeys.FORM_TEMPLATES)

const additionalActionCreators = {
  navigateTo: (formTemplateId, replaceUrl) => navigateTo(getUrl(formTemplateId), replaceUrl),
  navigateToBase: replaceUrl => navigateTo(PATH_FORM_TEMPLATES, replaceUrl)
}

export const formTemplateActions = { ...baseActionCreators, ...additionalActionCreators }
