import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'

import employeeActions from '../actions/employeeActions'
import { showActionPrompt } from '../actions/uiActions'
import workActions from '../actions/workActions'
import workRecordActions from '../actions/workRecordActions'
import {
  approveWorkRecordSalaryEvents,
  fetchWorkRecordSalaryEvents,
  normalizeWorkRecords,
  startWorkRecord,
  stopWorkRecord,
  workRecordApi
} from '../api/workRecordApi'
import constants, { actionTypes, uiTypes } from '../constants'
import { getMemoSagas } from '../helpers/generalSagas'
import { addSuccessNotification } from '../helpers/notificationHelpers'
import {
  createActionFlow,
  createFlow,
  createSocketWatcherWithGenerator,
  deleteFlow,
  fetchFlow,
  genericSagaErrorHandler,
  getPromiseHandlersFromData,
  updateFlow
} from '../helpers/sagaHelpers'
import { getHasServiceLevelAccess } from '../selectors/whoAmISelectors'
import { getSalaryEventsOfWorkRecord } from '../selectors/workRecordSelectors'
import { handleEmployeeApiResponse } from './employeeSaga'
import { handleWorkApiResponse } from './workSaga'

const { actionKeys } = constants

const watchOnWorkRecordSockets = createSocketWatcherWithGenerator('workRecord', {
  created: function* (data) {
    yield handleWorkRecordApiResponse(workRecordActions.createSuccess)(normalizeWorkRecords(data))
  },
  updated: function* (data) {
    yield handleWorkRecordApiResponse(workRecordActions.updateSuccess)(normalizeWorkRecords(data))
  },
  deleted: function* (data) {
    yield put(workRecordActions.deleteSuccess(data))
  },
  promptDelete: function* (data) {
    yield handleWorkRecordApiResponse(workRecordActions.fetchSuccess)(normalizeWorkRecords(data))
    yield put(showActionPrompt(uiTypes.PROMPT_DELETE_WORK_RECORD, data))
  }
})

const handleWorkRecordApiResponse = (mainAction, tableIdentifier) =>
  function* ({ data, work, employees, tableOptions }) {
    yield put(mainAction(data))
    if(work) {
      yield handleWorkApiResponse(workActions.fetchSuccess)(work)
    }
    if(employees) {
      yield handleEmployeeApiResponse(employeeActions.fetchSuccess)(employees)
    }
    if(tableIdentifier != null && tableOptions?.orderBy != null) {
      yield put(workRecordActions.tableActions.updateOptions(tableOptions, tableIdentifier))
    }
    return data
  }

const handleSalaryEventApiResponse = mainAction => function* ({ data, work, tableOptions }) {
  if(work) {
    yield handleWorkApiResponse(workActions.fetchSuccess)(work)
  }
  if(tableOptions) {
    yield put(workRecordActions.salaryEvents.tableActions.updateOptions(tableOptions))
  }
  yield put(mainAction(data))
}

const workRecordFetchFlow = fetchFlow({
  fetchApi: workRecordApi.fetch,
  actions: workRecordActions,
  base: 'workRecords',
  errorMsg: 'työkirjausten',
  getApiResponseHandler: data => handleWorkRecordApiResponse(workRecordActions.fetchSuccess, data.tableIdentifier),
  preShouldPerformRequest: function* () {
    return yield select(state => getHasServiceLevelAccess(state, actionKeys.WORK_RECORDS))
  }
})

const workRecordUpdateFlow = updateFlow(workRecordApi.update, workRecordActions, 'Työkirjaus', 'Työkirjauksen', handleWorkRecordApiResponse(workRecordActions.updateSuccess))
const workRecordCreateFlow = createFlow(workRecordApi.create, workRecordActions, 'Työkirjaus', 'Työkirjauksen', handleWorkRecordApiResponse(workRecordActions.createSuccess))
const workRecordDeleteFlow = deleteFlow({
  deleteApi: workRecordApi.remove,
  actions: workRecordActions,
  singular: 'Työkirjaus',
  errorMsg: 'Työkirjauksen',
  base: 'workRecords'
})

const workRecordStartFlow = function* ({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(workRecordActions.startRecordStart(record))
    const response = yield call(startWorkRecord, record)
    yield handleWorkRecordApiResponse(workRecordActions.createSuccess)(response)
    addSuccessNotification('Työkirjaus käynnistetty')
    yield call(resolve, response)
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe työkirjauksen käynnistämisessä', reject)
  }
}

const workRecordStopFlow = function* ({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(workRecordActions.stopRecordStart(record))
    yield put(workRecordActions.setBusy(true))
    const response = yield call(stopWorkRecord, record)
    yield handleWorkRecordApiResponse(workRecordActions.createSuccess)(response)
    yield put(workRecordActions.setBusy(false))
    addSuccessNotification('Työkirjaus pysäytetty')
    yield call(resolve, response)
  } catch(err) {
    yield put(workRecordActions.setBusy(false))
    yield * genericSagaErrorHandler(err, 'Virhe työkirjauksen pysäyttämisessä', reject)
  }
}

function* workRecordSalaryEventFetchFlow({ data = {} }) {
  const workRecordId = data.id
  try {
    yield put(workRecordActions.salaryEvents.fetchStart())
    const salaryEvents = yield call(fetchWorkRecordSalaryEvents, data)
    if(workRecordId) {
      const toDelete = yield select(state => getSalaryEventsOfWorkRecord(state, workRecordId))
      yield all(toDelete.map(recordToDelete => put(workRecordActions.salaryEvents.deleteSuccess(recordToDelete)))) // clean up...
    }
    yield handleSalaryEventApiResponse(workRecordActions.salaryEvents.fetchSuccess)(salaryEvents)
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe palkkatapahtumien noudossa')
  }
}

const workRecordSalaryEventApproveFlow = function* ({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(workRecordActions.approveSalaryEventsStart(record))
    yield put(workRecordActions.setBusy(true))
    const salaryEvents = yield call(approveWorkRecordSalaryEvents, record)
    yield put(workRecordActions.setBusy(false))
    const oldOptions = yield select(state => state.workRecords.salaryEvents.tableOptions.default)
    const newIds = [...(new Set([...oldOptions.ids, ...salaryEvents.tableOptions.ids]))]
    salaryEvents.tableOptions = {
      ...oldOptions,
      ids: newIds
    }
    yield handleSalaryEventApiResponse(workRecordActions.salaryEvents.fetchSuccess)(salaryEvents)
    addSuccessNotification('Palkkatapahtumat hyväksytty')
    yield call(resolve, salaryEvents)
  } catch(err) {
    yield put(workRecordActions.setBusy(false))
    yield * genericSagaErrorHandler(err, 'Virhe palkkatapahtumien hyväksynnässä', reject)
  }
}

const memoSagas = getMemoSagas({
  actions: workRecordActions,
  baseName: 'workRecords',
  socketName: 'work_record',
  titleGenetive: 'työkirjauksen',
  apiBaseUrl: 'work_records'
})

const workRecordPatchActionFlow = createActionFlow(workRecordApi.patch, workRecordActions, handleWorkRecordApiResponse)

export default function* workRecordSaga() {
  yield takeEvery(workRecordActions.actionTypes.fetchRequest, workRecordFetchFlow)
  yield takeEvery(workRecordActions.actionTypes.updateRequest, workRecordUpdateFlow)
  yield takeEvery(workRecordActions.actionTypes.createRequest, workRecordCreateFlow)
  yield takeEvery(workRecordActions.actionTypes.deleteRequest, workRecordDeleteFlow)

  yield takeEvery(workRecordActions.actionTypes.startRecordRequest, workRecordStartFlow)
  yield takeEvery(workRecordActions.actionTypes.stopRecordRequest, workRecordStopFlow)

  yield takeLatest(workRecordActions.actionTypes.approveSalaryEventsRequest, workRecordSalaryEventApproveFlow)

  yield takeEvery(workRecordActions.salaryEvents.actionTypes.fetchRequest, workRecordSalaryEventFetchFlow)

  yield takeEvery(actionTypes.WORK_RECORD_PATCH_ACTION_REQUEST, workRecordPatchActionFlow)

  yield all([
    memoSagas(),
    watchOnWorkRecordSockets()
  ])
}
