import { all, put, takeEvery } from 'redux-saga/effects'

import employeeActions from '../actions/employeeActions'
import systemCustomerActions from '../actions/systemCustomerActions'
import {
  employmentApi,
  employmentSalaryTypeApi,
  taxDeductionCardApi,
  wageEarnerApi
} from '../api/wageEarnerApi'
import {
  createFlow,
  createSocketWatcherWithApiHandlerAndNormalizer,
  defaultApiResponseHandler,
  deleteFlow,
  fetchFlow,
  updateFlow
} from '../helpers/sagaHelpers'
import { employmentSalaryTypeActions } from '../slices/employmentSalaryTypeSlice'
import { employmentActions } from '../slices/employmentSlice'
import { insuranceCompanyActions } from '../slices/insuranceCompanySlice'
import { insuranceActions } from '../slices/insuranceSlice'
import { occupationClassificationActions } from '../slices/occupationClassificationSlice'
import { taxDeductionCardActions } from '../slices/taxDeductionCardSlice'
import { wageEarnerActions } from '../slices/wageEarnerSlice'

export const handleWageEarnerApiResponse = mainAction => function* ({
  data,
  salaryTypes,
  employmentSalaryTypes,
  employees
}) {
  yield put(employeeActions.fetchSuccess(employees))
  yield put(systemCustomerActions.salaryTypes.fetchSuccess(salaryTypes))
  yield put(employmentSalaryTypeActions.fetchSuccess(employmentSalaryTypes))
  yield put(mainAction(data))
  return data
}

const watchOnWageEarnerSockets = createSocketWatcherWithApiHandlerAndNormalizer('wageEarner', wageEarnerActions, handleWageEarnerApiResponse)

export const handleEmploymentApiResponse = mainAction => function* ({
  data,
  insurances,
  insuranceCompanies,
  occupationClassifications,
  salaryTypes,
  employmentSalaryTypes
}) {
  yield put(insuranceActions.fetchSuccess(insurances))
  yield put(insuranceCompanyActions.fetchSuccess(insuranceCompanies))
  yield put(occupationClassificationActions.fetchSuccess(occupationClassifications))
  yield put(systemCustomerActions.salaryTypes.fetchSuccess(salaryTypes))
  yield put(employmentSalaryTypeActions.fetchSuccess(employmentSalaryTypes))
  yield put(mainAction(data))
  return data
}

const wageEarnerFetchFlow = fetchFlow({
  fetchApi: wageEarnerApi.fetch,
  actions: wageEarnerActions,
  base: 'wageEarners.wageEarners',
  errorMsg: 'palkansaajien',
  apiResponseHandler: handleWageEarnerApiResponse(wageEarnerActions.fetchSuccess)
})

const wageEarnerCreateFlow = createFlow(wageEarnerApi.create, wageEarnerActions, 'Palkansaaja', 'Palkansaajan', handleWageEarnerApiResponse(wageEarnerActions.createSuccess))
const wageEarnerUpdateFlow = updateFlow(wageEarnerApi.update, wageEarnerActions, 'Palkansaaja', 'Palkansaajan', handleWageEarnerApiResponse(wageEarnerActions.updateSuccess))

const taxDeductionCardFetchFlow = fetchFlow({
  fetchApi: taxDeductionCardApi.fetch,
  actions: taxDeductionCardActions,
  base: 'wageEarners.taxDeductionCards',
  errorMsg: 'verokorttien',
  apiResponseHandler: defaultApiResponseHandler(taxDeductionCardActions.fetchSuccess)
})

const taxDeductionCardCreateFlow = createFlow(taxDeductionCardApi.create, taxDeductionCardActions, 'Verokortti', 'Verokortin', defaultApiResponseHandler(taxDeductionCardActions.createSuccess))
const taxDeductionCardUpdateFlow = updateFlow(taxDeductionCardApi.update, taxDeductionCardActions, 'Verokortti', 'Verokortin', defaultApiResponseHandler(taxDeductionCardActions.updateSuccess))

const employmentFetchFlow = fetchFlow({
  fetchApi: employmentApi.fetch,
  actions: employmentActions,
  base: 'wageEarners.employments',
  errorMsg: 'työsuhteiden',
  apiResponseHandler: handleEmploymentApiResponse(employmentActions.fetchSuccess)
})

const employmentCreateFlow = createFlow(employmentApi.create, employmentActions, 'Työsuhde', 'Työsuhteen', handleEmploymentApiResponse(employmentActions.createSuccess))
const employmentUpdateFlow = updateFlow(employmentApi.update, employmentActions, 'Työsuhde', 'Työsuhteen', handleEmploymentApiResponse(employmentActions.updateSuccess))

const employmentSalaryTypeFetchFlow = fetchFlow({
  fetchApi: employmentSalaryTypeApi.fetch,
  actions: employmentSalaryTypeActions,
  base: 'wageEarners.employmentSalaryTypes',
  errorMsg: 'työsuhteen palkkalajien',
  apiResponseHandler: defaultApiResponseHandler(employmentSalaryTypeActions.fetchSuccess)
})

const employmentSalaryTypeUpdateFlow = updateFlow(employmentSalaryTypeApi.update, employmentSalaryTypeActions, 'Työsuhteen palkkalaji', 'Työsuhteen palkkalajin', defaultApiResponseHandler(employmentSalaryTypeActions.updateSuccess))
const employmentSalaryTypeDeleteFlow = deleteFlow({
  deleteApi: employmentSalaryTypeApi.remove,
  actions: employmentSalaryTypeActions,
  base: 'wageEarners.employmentSalaryTypes',
  singular: 'Työsuhteen palkkalaji',
  errorMsg: 'Työsuhteen palkkalajin',
  key: '_id'
})

export default function* wageEarnerSaga() {
  yield takeEvery(wageEarnerActions.fetchRequest.type, wageEarnerFetchFlow)
  yield takeEvery(wageEarnerActions.createRequest.type, wageEarnerCreateFlow)
  yield takeEvery(wageEarnerActions.updateRequest.type, wageEarnerUpdateFlow)

  yield takeEvery(taxDeductionCardActions.fetchRequest.type, taxDeductionCardFetchFlow)
  yield takeEvery(taxDeductionCardActions.createRequest.type, taxDeductionCardCreateFlow)
  yield takeEvery(taxDeductionCardActions.updateRequest.type, taxDeductionCardUpdateFlow)

  yield takeEvery(employmentActions.fetchRequest.type, employmentFetchFlow)
  yield takeEvery(employmentActions.createRequest.type, employmentCreateFlow)
  yield takeEvery(employmentActions.updateRequest.type, employmentUpdateFlow)

  yield takeEvery(employmentSalaryTypeActions.fetchRequest.type, employmentSalaryTypeFetchFlow)
  yield takeEvery(employmentSalaryTypeActions.updateRequest.type, employmentSalaryTypeUpdateFlow)
  yield takeEvery(employmentSalaryTypeActions.deleteRequest.type, employmentSalaryTypeDeleteFlow)

  yield all([
    watchOnWageEarnerSockets()
  ])
}
