import constants, { actionTypes } from '../constants'
import { actionCreatorsFor, request } from '../helpers/actionHelpers'

const baseActionCreators = actionCreatorsFor(constants.actionKeys.SYSTEM_CUSTOMER, {
  startProduction: true,
  requestHelp: true
})

const additionalActionCreators = {
  details: actionCreatorsFor(constants.actionKeys.SYSTEM_CUSTOMER_DETAILS),
  settings: actionCreatorsFor(constants.actionKeys.SYSTEM_CUSTOMER_SETTINGS),
  maventa: actionCreatorsFor(constants.actionKeys.SYSTEM_CUSTOMER_MAVENTA),
  accounting: actionCreatorsFor(constants.actionKeys.SYSTEM_CUSTOMER_ACCOUNTING),
  bankAccounts: actionCreatorsFor(constants.actionKeys.SYSTEM_CUSTOMER_BANK_ACCOUNTS),
  creditActions: actionCreatorsFor(constants.actionKeys.SYSTEM_CUSTOMER_CREDIT_ACTIONS),
  emailSettings: actionCreatorsFor(constants.actionKeys.SYSTEM_CUSTOMER_EMAIL_SETTINGS),
  smsSettings: actionCreatorsFor(constants.actionKeys.SYSTEM_CUSTOMER_SMS_SETTINGS),
  termsOfService: actionCreatorsFor(constants.actionKeys.SYSTEM_CUSTOMER_TERMS_OF_SERVICE),
  pricingRules: actionCreatorsFor(constants.actionKeys.PRICING_RULES),
  pricingRuleSets: actionCreatorsFor(constants.actionKeys.PRICING_RULE_SETS),
  pricingRuleFallbacks: actionCreatorsFor(constants.actionKeys.PRICING_RULE_FALLBACKS),
  salaryTypes: actionCreatorsFor(constants.actionKeys.SALARY_TYPES),
  packetIndustries: actionCreatorsFor(constants.actionKeys.PACKET_INDUSTRIES),
  wageIncomeTypes: actionCreatorsFor(constants.actionKeys.WAGE_INCOME_TYPES),
  salaryGroups: actionCreatorsFor(constants.actionKeys.SALARY_GROUP),
  systemCustomerAnnualInvoicings: actionCreatorsFor(constants.actionKeys.SYSTEM_CUSTOMER_ANNUAL_INVOICINGS),
  approvers: actionCreatorsFor(constants.actionKeys.SYSTEM_CUSTOMER_APPROVERS),
  syncBankAccountBalance: request(actionTypes.SYNC_BANK_ACCOUNT_BALANCE),
  startProductionRequest: request(baseActionCreators.actionTypes.startProductionRequest),
  requestHelpRequest: request(baseActionCreators.actionTypes.requestHelpRequest),
  formTemplate: actionCreatorsFor(constants.actionKeys.SYSTEM_CUSTOMER_FORM_TEMPLATE)
}

const systemCustomerActions = { ...baseActionCreators, ...additionalActionCreators }
export default systemCustomerActions
