import { Link } from 'react-router-dom'

import { PATH_SYSTEM_MESSAGES } from '../../routes'
import SystemMessageNotifications from './SystemMessageNotifications'

const SystemMessageNotificationDropdown = () => {
  return (
    <div
      className='position-absolute end-0 card rounded pt-3 px-2 pb-2 z-dropdown'
      data-testid='notificationsDropdown'
    >
      <div className='mhv-75 mwv-75 mt-1 overflow-auto' style={{ width: '60ch' }}>
        <SystemMessageNotifications />
      </div>
      <Link to={PATH_SYSTEM_MESSAGES} size='sm' className='m-1'>
        Näytä kaikki
      </Link>
    </div>
  )
}

export default SystemMessageNotificationDropdown
