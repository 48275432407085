import PropTypes from 'prop-types'

import { childrenPropType } from '../../propTypes'
import TooltipComponent from './TooltipComponent'

export const tooltipBasePropTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  message: PropTypes.node,
  wrapperClassName: PropTypes.string,
  tooltipClassName: PropTypes.string,
  placement: PropTypes.string,
  children: childrenPropType
}

const TooltipBase = props => {
  const {
    id,
    children,
    message,
    placement = 'right',
    wrapperClassName = 'tooltip-container ms-1',
    TargetComponent,
    targetComponentProps,
    tooltipClassName
  } = props
  const tooltipId = `tooltipcomp-${id}`
  return (
    <span className={wrapperClassName}>
      <TargetComponent {...targetComponentProps} id={tooltipId} />
      <TooltipComponent id={tooltipId} placement={placement} message={message} innerClassName={tooltipClassName}>
        {children}
      </TooltipComponent>
    </span>
  )
}

TooltipBase.propTypes = {
  ...tooltipBasePropTypes,
  TargetComponent: PropTypes.elementType.isRequired,
  targetComponentProps: PropTypes.object.isRequired
}

export default TooltipBase
