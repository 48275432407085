import CommonValueFormGroup from './CommonValueFormGroup'
import ButtonRadioFormInput from './inputs/ButtonRadioFormInput'
import { commonFormInputDefaultProps, commonFormInputPropTypes } from './propTypes'

const ButtonRadioFormGroup = props => (
  <CommonValueFormGroup {...commonFormInputDefaultProps} {...props} Component={ButtonRadioFormInput} />
)
ButtonRadioFormGroup.propTypes = commonFormInputPropTypes

export default ButtonRadioFormGroup
