import { ChangeEvent } from 'react'
import { get } from 'react-hook-form'
import { Input as RSInput } from 'reactstrap'

import FormInputGroupWrapper from './FormInputGroupWrapper'
import FormInputWrapper from './FormInputWrapper'

export interface FormPlainInputProps
  extends Pick<React.ComponentProps<typeof FormInputWrapper>,
  'name' |
  'label' |
  'inputOnly' |
  'required' |
  'formGroupClassName' |
  'labelClassName' |
  'tooltip' |
  'helpText'>,
  Pick<React.ComponentProps<typeof FormInputGroupWrapper>,
  'append' |
  'prepend' |
  'name' |
  'errors' |
  'tooltipError' |
  'isCustomAddon' |
  'positionFixed' > {
  value?: boolean | number | string
  onChange?: (ev: ChangeEvent<HTMLInputElement>) => void
  onValueChange?: (ev: ChangeEvent<HTMLInputElement>) => void
  inputProps?: Partial<React.ComponentProps<typeof RSInput>>
  errors?: object
  label?: React.ReactNode
  tooltip?: string
  inputOnly?: boolean
  required?: boolean
  tooltipError?: boolean
  append?: React.ReactNode
  prepend?: React.ReactNode
  formGroupClassName?: string
  inputGroupClassName?: string
  isCustomAddon?: boolean
  disabled?: boolean
  readOnly?: boolean
  testId?: string
  placeholder?: string
  invalid?: boolean
  inline?: boolean
}

const FormPlainInput = ({
  name,
  value,
  errors,
  onChange,
  onValueChange,
  label,
  tooltip,
  inputProps,
  inputOnly,
  required,
  tooltipError,
  append,
  prepend,
  formGroupClassName,
  inputGroupClassName,
  labelClassName,
  isCustomAddon,
  disabled,
  testId,
  placeholder,
  positionFixed,
  helpText,
  invalid,
  inline
}: FormPlainInputProps) => (
  <FormInputWrapper
    name={name}
    label={label}
    inputOnly={inputOnly}
    required={required}
    formGroupClassName={formGroupClassName}
    labelClassName={labelClassName}
    tooltip={tooltip}
    helpText={helpText}
    inline={inline}
  >
    <FormInputGroupWrapper
      append={append}
      prepend={prepend}
      name={name}
      errors={errors}
      tooltipError={tooltipError}
      isCustomAddon={isCustomAddon}
      positionFixed={positionFixed}
      className={inputGroupClassName}
    >
      <RSInput
        id={name}
        name={name}
        value={value?.toString() ?? ''}
        bsSize='sm'
        invalid={invalid || !!get(errors, name)}
        data-testid={testId ?? name}
        placeholder={placeholder}
        {...inputProps}
        disabled={disabled}
        onChange={event => {
          // Two on changes for possibility to perform different actions based on a "regular" form update onChange
          onChange?.(event)
          onValueChange?.(event)
        }}
      />
    </FormInputGroupWrapper>
  </FormInputWrapper>
)

export default FormPlainInput
