import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import memoize from 'micro-memoize'

import CommonButtonFormInput from './CommonButtonFormInput'

class ButtonRadioFormInput extends PureComponent {
  render() {
    return <CommonButtonFormInput {...this.props} handleClick={this.handleClick} />
  }

  handleClick = memoize(value => event => {
    const { onChange, setNull, value: prevValue } = this.props
    if(setNull && prevValue === value) {
      return onChange(null, event)
    }
    return onChange(value, event)
  })

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    setNull: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool
    ])
  }
}

export default ButtonRadioFormInput
