import { createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'

import {
  findFeaturePermissionsByKey,
  getSystemCustomerPermissions
} from '../components/Authentication/featurePermissions'
import { serviceLevelValues } from '../constants'
import { findCurrentEmployeeLevel } from './employeeSelectors'

/**
 * requireInternalPermission: boolean | null | [string]
 */
const hasAccessWithInternalPermission = ({ requireInternalPermission }, featureKey, whoAmIData) => {
  // If there's no requirement, grant access
  if(!requireInternalPermission) {
    return true
  }

  // If we don't know who user is or what is enabled, don't grant access
  if(!whoAmIData?.internallyEnabledFeatures) {
    return false
  }

  // If required permissions is an array, as long as all of them is enabled, grant access
  if(Array.isArray(requireInternalPermission)) {
    return requireInternalPermission.every(requiredFeatureKey => whoAmIData.internallyEnabledFeatures.includes(requiredFeatureKey))
  }

  // Grant access if feature is enabled
  return whoAmIData.internallyEnabledFeatures.includes(featureKey.toUpperCase())
}

const hasAccessWithAccessLevel = ({ minAccessLevel }, employeeAccessLevel, ignoreAccessLevel) => ignoreAccessLevel || (employeeAccessLevel >= minAccessLevel)

export const getHasServiceLevelAccess = createCachedSelector(
  state => state.whoAmI.data,
  (state, featureKey) => featureKey,
  (whoAmIData, featureKey) => {
    const { minServiceLevel } = findFeaturePermissionsByKey(featureKey)
    return serviceLevelValues[whoAmIData?.serviceLevel] >= minServiceLevel
  }
)((state, featureKey) => `${featureKey}`)

export const getHasAccessToFeature = createCachedSelector(
  state => state.whoAmI.data,
  state => state.systemCustomer.settingsData.settings,
  state => findCurrentEmployeeLevel(state).accessLevel,
  state => getSystemCustomerPermissions(state.systemCustomer),
  (state, featureKey) => featureKey,
  (state, featureKey, ignoreAccessLevel) => !!ignoreAccessLevel,
  (state, featureKey, ignoreAccessLevel, minAccessLevel) => minAccessLevel,
  (whoAmIData, settings, employeeAccessLevel, systemCustomerPermissions, featureKey, ignoreAccessLevel, minAccessLevel) => {
    /* If minAccessLevel is defined but featureKey is not,
     * only look at minAccessLevel.
     * Otherwise direct minAccessLevel is ignored
     * and uses accessLevel set in featurePermissions */
    if(!featureKey && minAccessLevel) {
      return employeeAccessLevel >= minAccessLevel
    }
    const permissions = findFeaturePermissionsByKey(featureKey)
    const { minServiceLevel, stage } = permissions
    const { systemCustomerStage } = systemCustomerPermissions
    return !!(
      serviceLevelValues[whoAmIData?.serviceLevel] >= minServiceLevel &&
      hasAccessWithInternalPermission(permissions, featureKey, whoAmIData) &&
      hasAccessWithAccessLevel(permissions, employeeAccessLevel, ignoreAccessLevel) &&
      systemCustomerStage >= stage &&
      (permissions.requireSetting ? permissions.requireSetting(settings) : true)
    )
  }
)((state, featureKey, ignoreAccessLevel) => `${featureKey}__${ignoreAccessLevel}`)

export const getHasAccessToFeatureCallback = createCachedSelector(
  state => state.whoAmI.data,
  state => state.systemCustomer.settingsData.settings,
  state => findCurrentEmployeeLevel(state).accessLevel,
  state => getSystemCustomerPermissions(state.systemCustomer),
  (__state, ignoreAccessLevel) => !!ignoreAccessLevel,
  (whoAmIData, settings, employeeAccessLevel, systemCustomerPermissions, ignoreAccessLevel) => {
    const { systemCustomerStage } = systemCustomerPermissions
    return featureKey => {
      const permissions = findFeaturePermissionsByKey(featureKey)
      const { minServiceLevel, stage } = permissions
      return !!(
        serviceLevelValues[whoAmIData?.serviceLevel] >= minServiceLevel &&
        hasAccessWithInternalPermission(permissions, featureKey, whoAmIData) &&
        hasAccessWithAccessLevel(permissions, employeeAccessLevel, ignoreAccessLevel) &&
        systemCustomerStage >= stage &&
        (permissions.requireSetting ? permissions.requireSetting(settings) : true)
      )
    }
  }
)((__state, ignoreAccessLevel) => `${ignoreAccessLevel}`)

export const getDenominationSystemType = createSelector(
  state => state.whoAmI.data,
  data => data.denominationSystemType
)
