import customerActions from '../actions/customerActions'
import constants from '../constants'
import { getCombinedListReducerFor, getPrhReducer, getStatsReducer, getTableOptions } from '../helpers/reducerHelpers'
import tableReducerFor from './tableReducer'

export const initialCustomerTableOptions = {
  default: getTableOptions()
}

const initialStats = {
  customerId: 0,
  contactCount: 0,
  fileCount: 0,
  invoiceCount: 0,
  memoCount: 0,
  targetCount: 0,
  workCount: 0,
  supplyOfferCount: 0,
  isBusy: false
}

const initialPrh = {}

export default getCombinedListReducerFor(constants.actionKeys.CUSTOMERS, {
  contacts: getCombinedListReducerFor(constants.actionKeys.CUSTOMER_CONTACTS),
  files: getCombinedListReducerFor(constants.actionKeys.CUSTOMER_FILES),
  memos: getCombinedListReducerFor(constants.actionKeys.CUSTOMER_MEMOS),
  tableOptions: tableReducerFor(constants.actionKeys.CUSTOMERS, initialCustomerTableOptions),
  stats: getStatsReducer(customerActions, initialStats),
  prh: getPrhReducer(customerActions, initialPrh)
})
