import {
  createEntityAdapter
} from '@reduxjs/toolkit'

import { InsuranceCompanyModel } from '../api/insuranceCompanyApi'
import { createGenericSlice } from '../helpers/sliceHelpers'
import { EveliaRootState } from '../reducerTypes'

const insuranceCompanyAdapter = createEntityAdapter<InsuranceCompanyModel>({})

const insuranceCompanySlice = createGenericSlice({
  name: 'insuranceCompanies',
  entityAdapter: insuranceCompanyAdapter
})

export const insuranceCompanyEntitySelectors = insuranceCompanyAdapter.getSelectors<EveliaRootState>(state => state.insuranceCompanies)
export const insuranceCompanyActions = insuranceCompanySlice.actions

export default insuranceCompanySlice
