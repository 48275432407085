import { size } from '@floating-ui/dom'
import { autoUpdate, useFloating } from '@floating-ui/react'

/**
 * Similar implementation that react-bootstrap-typeahead uses but with successor of popper.js
 */
const useFloatingLabel = positionFixed => {
  return useFloating({
    strategy: positionFixed ? 'fixed' : 'absolute',
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
    middleware: [
      size({
        apply({ elements, rects }) {
          Object.assign(elements.floating.style, {
            maxWidth: `${rects.reference.width}px`
          })
        }
      })
    ]
  })
}
export default useFloatingLabel
