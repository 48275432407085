import { createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'

import { isNewlyCreated } from '../helpers/helpers'
import { getFindItemByIdSelector } from '../helpers/selectorHelpers'

const getProductLinesFromArgument = arg => arg.productLines ? arg.productLines.records : arg

export const findProductLineWithId = getFindItemByIdSelector(getProductLinesFromArgument)

export const getActiveProductLines = createSelector(
  getProductLinesFromArgument,
  productLines => productLines.filter(productLine => productLine.isEnabled && !isNewlyCreated(productLine))
)

export const findProductSupplierInfosBySupplierId = createCachedSelector(
  getProductLinesFromArgument,
  state => state.productLines.supplierStats.records,
  (state, { supplierId }) => Number(supplierId),
  (productLines, supplierStats, supplierId) =>
    supplierStats.filter(supplierStat => supplierStat.supplierId === supplierId)
      .map(supplierStat => {
        const productLine = productLines.find(productLine => productLine.id === supplierStat.productLineId)
        return {
          ...supplierStat,
          productLineCode: productLine?.code,
          productLineName: productLine?.name
        }
      })
)((state, { supplierId }) => `${supplierId}`)
