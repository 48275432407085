import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Button, InputGroupText } from 'reactstrap'
import { faMagnifyingGlass, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DebouncedInput from '../misc/DebouncedInput'

const DataTableGlobalFilter = forwardRef(({ value, onChange }, ref) => {
  return (
    <div ref={ref} className='dt-thead d-flex justify-content-end'>
      <DebouncedInput
        value={value}
        onChange={onChange}
        name='filter'
        placeholder='Etsi'
        isCustomAddon
        prepend={(
          <InputGroupText>
            <FontAwesomeIcon icon={faMagnifyingGlass} className='px-1' />
          </InputGroupText>
        )}
        append={(
          <Button
            onClick={() => onChange('')}
            data-testid='clear-filter'
            disabled={!value}
            size='xs'
            outline
            color='secondary'
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>

        )}
      />
    </div>
  )
})

DataTableGlobalFilter.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

export default DataTableGlobalFilter
