import { all, takeEvery, takeLatest } from 'redux-saga/effects'

import costProvisionActions from '../actions/costProvisionActions'
import { costProvisionApi } from '../api/costProvisionApi'
import {
  createFlow,
  createSocketWatcher,
  deleteFlow,
  fetchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

const watchOnCostProvisionSockets = createSocketWatcher('costProvision', {
  created: costProvisionActions.createSuccess,
  updated: costProvisionActions.updateSuccess,
  deleted: costProvisionActions.deleteSuccess
})

const costProvisionFetchFlow = fetchFlow({
  fetchApi: costProvisionApi.fetch,
  actions: costProvisionActions,
  base: 'costProvisions',
  errorMsg: 'Kuluvarausten'
})

const costProvisionUpdateFlow = updateFlow(costProvisionApi.update, costProvisionActions, 'Kuluvaraus', 'Kuluvarauksen')
const costProvisionCreateFlow = createFlow(costProvisionApi.create, costProvisionActions, 'Kuluvaraus', 'Kuluvarauksen')
const costProvisionDeleteFlow = deleteFlow({
  deleteApi: costProvisionApi.remove,
  actions: costProvisionActions,
  singular: 'Kuluvaraus',
  errorMsg: 'Kuluvarauksen',
  base: 'costProvisions'
})

export default function* costProvisionSaga() {
  yield takeLatest(costProvisionActions.actionTypes.fetchRequest, costProvisionFetchFlow)
  yield takeEvery(costProvisionActions.actionTypes.updateRequest, costProvisionUpdateFlow)
  yield takeEvery(costProvisionActions.actionTypes.createRequest, costProvisionCreateFlow)
  yield takeEvery(costProvisionActions.actionTypes.deleteRequest, costProvisionDeleteFlow)

  yield all([
    watchOnCostProvisionSockets()
  ])
}
