import constants from '../constants'
import { actionCreatorsFor } from '../helpers/actionHelpers'

const baseActionCreators = actionCreatorsFor(constants.actionKeys.AUTHORS)

const additionalActionCreators = {
}

const authorActions = { ...baseActionCreators, ...additionalActionCreators }
export default authorActions
