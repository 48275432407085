import { getUrl } from '../../actions/productActions'
import TableLink from '../../components/Table/TableLink'
import ProductLineCodeAndProductNumber from './ProductLineCodeAndProductNumber'

const isWorkRow = model => !!model?.type && Object.prototype.hasOwnProperty.call(model, 'productNumber')
const isProductLike = model => model && (
  (isWorkRow(model) && model.productId) ||
  Object.prototype.hasOwnProperty.call(model, 'customerEan') // only defined on products
)

// Function is used on various places where each model is "product like"
// but not necessarily product. In such case hide link to product page
export const productLineCodeAndProductNumberLinkRenderer = (value, model) => (
  <TableLink to={getUrl(model?.productId || model?.id)} disabled={!isProductLike(model)}>
    <ProductLineCodeAndProductNumber productLineCode={model?.productLineCode} productNumber={value} />
  </TableLink>
)
