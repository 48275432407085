import PropTypes from 'prop-types'

import WorkStatusBadge from '../../../containers/WorkPage/WorkStatusBadge'
import { workPropType } from '../../../propTypes'
import WorkCustomerDetails from './WorkCustomerDetails'

const WorkSearchItem = ({ work, showCustomerDetails, getItemDetails, className }) => {
  return (
    <div className={className}>
      <WorkStatusBadge work={work} />
      {getItemDetails(work)}
      {showCustomerDetails && work.customerId
        ? (
          <WorkCustomerDetails customerId={work.customerId} />
          )
        : null}
    </div>
  )
}

WorkSearchItem.propTypes = {
  work: workPropType,
  showCustomerDetails: PropTypes.bool,
  getItemDetails: PropTypes.func,
  className: PropTypes.string
}

export default WorkSearchItem
