import { actionTypes } from '../constants'

export const showActionPrompt = (promptType, data) => ({
  type: actionTypes.ACTION_PROMPT_SHOW,
  promptType,
  data
})

export const hideActionPrompt = () => ({
  type: actionTypes.ACTION_PROMPT_HIDE
})
