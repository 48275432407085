import { actionTypes } from '../constants'
import { error, request, start, success } from '../helpers/actionHelpers'

const eAddressActions = {
  fetchRequest: request(actionTypes.EADDRESS_FETCH_REQUEST),
  fetchStart: start(actionTypes.EADDRESS_FETCH_START),
  fetchSuccess: success(actionTypes.EADDRESS_FETCH_SUCCESS),
  fetchError: error(actionTypes.EADDRESS_FETCH_ERROR)
}

export default eAddressActions
