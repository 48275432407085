import { actionKeys, actionTypes } from '../constants'
import { actionCreatorsFor, request } from '../helpers/actionHelpers'
import tableActionsFor from './tableActions'

const baseActionCreators = actionCreatorsFor(actionKeys.DENOMINATIONS)

const additionalActionCreators = {
  denominationPackets: {
    ...actionCreatorsFor(actionKeys.DENOMINATION_PACKETS),
    doPostAction: request(actionTypes.DENOMINATION_PACKETS_POST_ACTION_REQUEST)
  },
  denominationGroups: actionCreatorsFor(actionKeys.DENOMINATION_GROUPS),
  tableActions: tableActionsFor(actionKeys.DENOMINATION_TABLE_OPTIONS)
}

const denominationActions = { ...baseActionCreators, ...additionalActionCreators }
export default denominationActions
