import PropTypes from 'prop-types'
import { Badge } from 'reactstrap'
import { appendValuesOrEmpty } from '@evelia/helpers/helpers'

const ProductLineCodeBadge = ({
  productLineCode,
  color = 'success',
  size,
  className,
  darkText
}) => productLineCode
  ? <Badge color={color} size={size} className={appendValuesOrEmpty(['me-1', className, darkText ? 'text-dark' : null])}>{productLineCode}</Badge>
  : null

ProductLineCodeBadge.propTypes = {
  productLineCode: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  darkText: PropTypes.bool
}

export default ProductLineCodeBadge
