import { emptyArray } from '@evelia/helpers/constants'
import { sortByProperty } from '@evelia/helpers/helpers'
import { createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'

import { mapRecordNameOptions } from '../helpers/helpers'
import { getFindItemByIdSelector } from '../helpers/selectorHelpers'
import { getActiveProductLines } from './productLineSelectors'

export const mapPricingRulesWithProductLines = createCachedSelector(
  state => getActiveProductLines(state),
  state => state.systemCustomer.pricingRules.records,
  (state, pricingRuleSetId) => pricingRuleSetId === null ? null : Number(pricingRuleSetId),
  (productLines, pricingRules, pricingRuleSetId) =>
    productLines.map(productLine => pricingRules.find(pricingRule => pricingRule.productLineId === productLine.id && pricingRule.pricingRuleSetId === pricingRuleSetId) ||
      { id: 'temp_' + productLine.id + '_' + pricingRuleSetId, productLineId: productLine.id, pricingRuleType: 1, pricingRuleSetId })
)((state, pricingRuleSetId) => pricingRuleSetId === null ? 0 : pricingRuleSetId)

export const getPricingRuleSetOptions = createSelector(
  state => state && state.systemCustomer ? state.systemCustomer.pricingRuleSets.records : state,
  pricingRuleSets => [{ value: null, text: 'Oletus' }, ...pricingRuleSets.map(pricingRuleSet => ({ value: pricingRuleSet.id, text: pricingRuleSet.name }))]
)

export const findPricingRuleSetById = getFindItemByIdSelector(state => state.systemCustomer.pricingRuleSets.records)

export const findPricingRuleFallbackWithSetId = createCachedSelector(
  state => state.systemCustomer.pricingRuleFallbacks.records,
  (state, pricingRuleSetId) => pricingRuleSetId === null ? null : Number(pricingRuleSetId),
  (pricingRuleFallbacks, pricingRuleSetId) => pricingRuleFallbacks.find(pricingRuleFallback => pricingRuleFallback.pricingRuleSetId === pricingRuleSetId)
)((state, pricingRuleSetId) => pricingRuleSetId === null ? 0 : pricingRuleSetId)

export const getSalaryTypesFromArgument = arg => arg.systemCustomer?.salaryTypes?.records ?? arg

export const findSalaryTypeWithId = getFindItemByIdSelector(getSalaryTypesFromArgument)

export const getPacketIndustriesFromArgument = arg => arg?.systemCustomer?.packetIndustries?.records ?? arg

export const findPacketIndustryWithId = getFindItemByIdSelector(getPacketIndustriesFromArgument)

export const getPacketIndustrySelectOptions = createSelector(
  getPacketIndustriesFromArgument,
  packetIndustries => mapRecordNameOptions(packetIndustries)
)

export const getSalaryGroupsFromArgument = arg => arg.systemCustomer.salaryGroups ? arg.systemCustomer.salaryGroups.records : arg

export const findSalaryGroupWithId = createCachedSelector(
  getSalaryGroupsFromArgument,
  (state, salaryGroupId) => Number(salaryGroupId),
  (salaryGroups, salaryGroupId) => salaryGroups.find(salaryGroup => salaryGroup.id === salaryGroupId)
)((state, salaryGroupId) => salaryGroupId)

const sortByName = sortByProperty('name')
export const findEmployeeSystemCustomers = createSelector(
  state => state.whoAmI.employeeSystemCustomers || emptyArray,
  employeeSystemCustomers => [...employeeSystemCustomers].sort(sortByName)
)

export const findSystemCustomerSettings = createSelector(
  state => state.systemCustomer,
  systemCustomer => systemCustomer.settingsData
)

export const getSystemCustomerAnnualInvoicingsByType = createCachedSelector(
  state => state.systemCustomer.systemCustomerAnnualInvoicings.records,
  (__state, type) => type,
  (systemCustomerAnnualInvoicings, type) => systemCustomerAnnualInvoicings.filter(systemCustomerAnnualInvoicing => systemCustomerAnnualInvoicing.type === type)
)((__state, type) => type)

export const getWageIncomeTypesFromArgument = arg => arg?.systemCustomer?.wageIncomeTypes?.records ?? arg

export const findWageIncomeTypeWithId = getFindItemByIdSelector(getWageIncomeTypesFromArgument)
