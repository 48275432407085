import constants, { actionTypes } from '../constants'
import { actionCreatorsFor, request } from '../helpers/actionHelpers'

const baseActionCreators = actionCreatorsFor(constants.actionKeys.PRODUCT_LINES)

const additionalActionCreators = {
  supplierStats: actionCreatorsFor(constants.actionKeys.PRODUCT_LINES_SUPPLIER_STATS, {}, '_id'),
  deleteNetPricesRequest: request(actionTypes.PRODUCT_LINES_DELETE_NET_PRICES_REQUEST)
}

const productLineActions = { ...baseActionCreators, ...additionalActionCreators }
export default productLineActions
