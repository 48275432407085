import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { faCaretDown, faLandmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import loginActions from '../../actions/loginActions'
import { ADMINISTRATIVE_SYSTEM_CUSTOMER_ID } from '../../constants'
import SystemCustomerDropdownList from './SystemCustomerDropdownList'

const SystemCustomerChooser = ({ className }) => {
  const systemCustomer = useSelector(state => state.systemCustomer.settingsData)
  const dispatch = useDispatch()
  const switchSystemCustomer = useCallback(systemCustomerId => dispatch(loginActions.switchSystemCustomerRequest({ systemCustomerId })), [dispatch])

  if(!systemCustomer) {
    return null
  }
  const systemCustomerId = systemCustomer.details.id
  const companyName = systemCustomer.details.name

  return (
    <UncontrolledDropdown tag='span'>
      <DropdownToggle color='link' className={className} title={`${companyName} (${systemCustomerId})`}>
        <span className='brand-primary'>
          <FontAwesomeIcon icon={faLandmark} className='me-2' />
          <span className={systemCustomer.details.id === ADMINISTRATIVE_SYSTEM_CUSTOMER_ID ? 'text-danger' : 'text-light'}>{companyName}</span>
          <FontAwesomeIcon icon={faCaretDown} className='ms-2' />
        </span>
      </DropdownToggle>
      <SystemCustomerDropdownList
        systemCustomerId={systemCustomer.details.id}
        onClick={switchSystemCustomer}
      />
    </UncontrolledDropdown>
  )
}

SystemCustomerChooser.propTypes = {
  className: PropTypes.string
}

export default SystemCustomerChooser
