import PropTypes from 'prop-types'
import { Badge } from 'reactstrap'
import { appendEitherOrEmpty } from '@evelia/helpers/helpers'
import { faProjectDiagram, faUserTie } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { contentRow } from './PostalDetails'

export const contentRowWithIcon = (content, icon) => content ? <><FontAwesomeIcon icon={icon} /> {content}<br /></> : null

const ProjectDetails = ({
  name,
  mainProjectName,
  customerName,
  address,
  postalCode,
  city,
  projectNumber
}) => {
  return (
    <div>
      <span className='d-inline-flex align-items-center'>
        {projectNumber ? <Badge className='me-1' color='info'>{projectNumber}</Badge> : null}
        {name}
      </span>
      <br />
      {contentRowWithIcon(mainProjectName, faProjectDiagram)}
      {contentRowWithIcon(customerName, faUserTie)}
      <address className='m-0'>
        {contentRow(address)}
        {appendEitherOrEmpty(postalCode, city)}
      </address>
    </div>
  )
}

ProjectDetails.propTypes = {
  name: PropTypes.string,
  customerName: PropTypes.string,
  mainProjectName: PropTypes.string,
  address: PropTypes.string,
  postalCode: PropTypes.string,
  city: PropTypes.string,
  projectNumber: PropTypes.number
}

export default ProjectDetails
