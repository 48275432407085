import { Component, Fragment } from 'react'
import { FormFeedback, FormGroup, FormText } from 'reactstrap'
import get from 'lodash/get'
import noop from 'lodash/noop'
import setWith from 'lodash/setWith'
import memoize from 'micro-memoize'

import Label from './helpers/Label'
import { commonFormGroupPropTypes } from './propTypes'

export const handleOnChange = memoize(props => (value, event) => {
  const {
    field,
    onChange,
    setNull,
    setWithCustomizer,
    proxyOnChange
  } = props

  if(value === '' && setNull) {
    value = null
  }
  if(proxyOnChange) {
    return onChange(value, event)
  }
  return onChange(event, setWith({}, field, value, setWithCustomizer), false, { field, value })
})

class CommonValueFormGroup extends Component {
  state = {
    validationError: null
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(nextProps.model?._isEditable !== this.props.model?._isEditable) {
      return true
    }
    if(get(nextProps.model, this.props.field) !== get(this.props.model, this.props.field)) {
      return true
    }
    if(Object.entries(nextProps).filter(([key]) => key !== 'model').some(([key, value]) => this.props[key] !== value)) {
      return true
    }
    if(this.state.validationError !== nextState.validationError) {
      return true
    }
    return false
  }

  setInternalValidationError = message => this.setState({ validationError: message })
  clearInternalValidationError = () => this.setState({ validationError: null })
  render() {
    const {
      label,
      helpText,
      className,
      forceFeedback,
      Component,
      model,
      defaultValue,
      setWithCustomizer,
      onChange,
      isHidden,
      inputOnly,
      value,
      inputClassName,
      groupTestId,
      labelTooltip,
      proxyOnChange,
      ...inputProps
    } = this.props
    const {
      field,
      type,
      validationErrors,
      usePlaceholder,
      isRequired,
      setNull,
      idPrefix
    } = inputProps

    let currentValue = model ? get(model, field) : value
    if(currentValue === undefined && !proxyOnChange) {
      currentValue = defaultValue !== undefined ? defaultValue : (setNull ? null : '')
      onChange(null, setWith({}, field, currentValue, setWithCustomizer), true)
    }

    if(type === 'hidden') {
      return <Component {...inputProps} onChange={noop} />
    }
    const isCheck = type === 'checkbox' || type === 'radio'
    const inputId = `${idPrefix ? `${idPrefix}-` : ''}${field}${type === 'radio' ? `-${value}` : ''}`
    const isInvalid = !!validationErrors?.[field] || !!this.state.validationError
    const feedbackMessage = isInvalid ? this.state.validationError || validationErrors?.[field].msg : null

    const groupLabel = (
      <Label
        label={label}
        usePlaceholder={usePlaceholder}
        isCheck={isCheck}
        inputId={inputId}
        isRequired={isRequired}
        tooltip={labelTooltip}
        inputOnly={inputOnly}
      />
    )

    const GroupComponent = inputOnly ? Fragment : FormGroup
    const groupComponentProps = inputOnly ? {} : { check: isCheck, className: `${className || ''} ${isHidden ? 'd-none' : ''}`, 'data-testid': groupTestId }

    return (
      <GroupComponent {...groupComponentProps}>
        {!isCheck && groupLabel}
        <Component
          label={label}
          value={currentValue}
          inputValue={value}
          inputId={inputId}
          onChange={this.handleOnChange}
          isInvalid={isInvalid}
          {...inputProps}
          setValidationError={this.setInternalValidationError}
          clearValidationError={this.clearInternalValidationError}
          groupLabel={groupLabel}
          className={inputClassName}
        />
        {isCheck && groupLabel}
        {helpText ? <FormText color='muted' data-testid='helpText'>{helpText}</FormText> : null}
        <FormFeedback className={forceFeedback && isInvalid ? 'd-block' : null}>{feedbackMessage}</FormFeedback>
      </GroupComponent>
    )
  }

  handleOnChange = handleOnChange(this.props)

  static propTypes = commonFormGroupPropTypes
}

export default CommonValueFormGroup
