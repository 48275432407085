import PropTypes from 'prop-types'

import { timeRecordTypePropType } from '../../propTypes'
import IconButton from '../Buttons/IconButton'
import { getTimeRecordTypeIcon } from './timeRecordTypeHelpers'

const TimeRecordStatusButton = ({
  timeRecordType,
  disabled,
  onClick,
  size,
  className,
  testId
}) => (
  <IconButton
    size={size}
    color={timeRecordType.color}
    onClick={onClick}
    icon={getTimeRecordTypeIcon(timeRecordType)}
    className={className}
    disabled={disabled}
    outline={timeRecordType.isOutline}
    condensed
    testId={testId || `timeRecordType_${timeRecordType.name}`}
  >
    {timeRecordType.name}
  </IconButton>
)

TimeRecordStatusButton.propTypes = {
  timeRecordType: timeRecordTypePropType,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string,
  testId: PropTypes.string,
  className: PropTypes.string
}
export default TimeRecordStatusButton
