import { parseTemplate } from 'url-template'

import constants from '../constants'
import {
  actionCreatorsFor,
  error,
  request,
  start,
  success
} from '../helpers/actionHelpers'
import { PATH_MACHINE_RECORDS } from '../routes'
import { navigateTo } from './navigateActions'
import tableActions from './tableActions'

const machineRecordUrlTemplate = parseTemplate(`${PATH_MACHINE_RECORDS}/{machineRecordId}`)
export const getUrl = (machineRecordId, params) => machineRecordUrlTemplate.expand({ machineRecordId: machineRecordId || 'new', ...params })

const baseActionCreators = actionCreatorsFor(constants.actionKeys.MACHINE_RECORDS, { startRecord: true, stopRecord: true })

const additionalActionCreators = {
  navigateTo: (machineRecordId, replaceUrl, params) => navigateTo(getUrl(machineRecordId, params), replaceUrl),
  navigateToBase: replaceUrl => navigateTo(PATH_MACHINE_RECORDS, replaceUrl),
  memos: actionCreatorsFor(constants.actionKeys.MACHINE_RECORD_MEMOS),
  tableActions: tableActions(constants.actionKeys.MACHINE_RECORDS),

  startRecordRequest: request(baseActionCreators.actionTypes.startRecordRequest),
  startRecordStart: start(baseActionCreators.actionTypes.startRecordStart),
  startRecordSuccess: success(baseActionCreators.actionTypes.startRecordSuccess),
  startRecordError: error(baseActionCreators.actionTypes.startRecordError),
  stopRecordRequest: request(baseActionCreators.actionTypes.stopRecordRequest),
  stopRecordStart: start(baseActionCreators.actionTypes.stopRecordStart),
  stopRecordSuccess: success(baseActionCreators.actionTypes.stopRecordSuccess),
  stopRecordError: error(baseActionCreators.actionTypes.stopRecordError)
}

const machineRecordActions = { ...baseActionCreators, ...additionalActionCreators }
export default machineRecordActions
