import CommonValueFormGroup from './CommonValueFormGroup'
import TextFormInput from './inputs/TextFormInput'
import { commonFormInputDefaultProps, commonFormInputPropTypes } from './propTypes'

const TextFormGroup = props => (
  <CommonValueFormGroup {...commonFormInputDefaultProps} {...props} Component={TextFormInput} />
)
TextFormGroup.propTypes = commonFormInputPropTypes

export default TextFormGroup
