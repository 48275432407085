import { all, takeEvery, takeLeading } from 'redux-saga/effects'

import ticketTypeActions from '../actions/ticketTypeActions'
import { ticketTypeApi } from '../api/ticketTypeApi'
import ticketTypeTitles, { ticketTypeTitlesCapitalized } from '../components/Tickets/ticketTypeTitles'
import {
  createFlow,
  createSocketWatcher,
  deleteFlow,
  fetchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

const titles = ticketTypeTitles
const titlesCapitalized = ticketTypeTitlesCapitalized
const reduxName = 'ticketTypes'

const watchOnTicketTypeSockets = createSocketWatcher('ticketType', {
  created: ticketTypeActions.createSuccess,
  updated: ticketTypeActions.updateSuccess,
  deleted: ticketTypeActions.deleteSuccess
})

const ticketTypeFetchFlow = fetchFlow({
  fetchApi: ticketTypeApi.fetch,
  actions: ticketTypeActions,
  base: reduxName,
  idField: 'id',
  errorMsg: titles.genetive
})

const ticketTypeUpdateFlow = updateFlow(ticketTypeApi.update, ticketTypeActions, titlesCapitalized.basic, titlesCapitalized.genetive)
const ticketTypeCreateFlow = createFlow(ticketTypeApi.create, ticketTypeActions, titlesCapitalized.basic, titlesCapitalized.genetive)
const ticketTypeDeleteFlow = deleteFlow({
  deleteApi: ticketTypeApi.remove,
  actions: ticketTypeActions,
  singular: titlesCapitalized.basic,
  errorMsg: titlesCapitalized.genetive,
  base: reduxName
})

export default function* ticketTypeSaga() {
  yield takeLeading(ticketTypeActions.actionTypes.fetchRequest, ticketTypeFetchFlow)
  yield takeEvery(ticketTypeActions.actionTypes.updateRequest, ticketTypeUpdateFlow)
  yield takeEvery(ticketTypeActions.actionTypes.createRequest, ticketTypeCreateFlow)
  yield takeEvery(ticketTypeActions.actionTypes.deleteRequest, ticketTypeDeleteFlow)
  yield all([
    watchOnTicketTypeSockets()
  ])
}
