import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import historyActions from '../../actions/historyActions'
import { navigateTo } from '../../actions/navigateActions'
import { getLastChangedRoute } from '../../selectors/historySelectors'
import IconButton from './IconButton'

const ToLastPathButton = ({
  onClick,
  to,
  icon,
  ...rest
}) => {
  const dispatch = useDispatch()
  const lastChangedPath = useSelector(getLastChangedRoute)
  const lastPath = lastChangedPath ? `${lastChangedPath.pathname}${lastChangedPath.search}${lastChangedPath.hash}` : null
  const lastPathKey = lastChangedPath ? lastChangedPath.key : null

  const handleClick = async event => {
    if(onClick) {
      await onClick(event)
    }
    dispatch(historyActions.replaceHistory(lastPathKey))
    dispatch(navigateTo(lastPath ?? to))
  }

  return (
    <IconButton
      icon={icon || faCheck}
      color='success'
      data-testid='toLastPathButton'
      {...rest}
      onClick={handleClick}
    />
  )
}

ToLastPathButton.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.string.isRequired,
  icon: PropTypes.any
}

export default ToLastPathButton
