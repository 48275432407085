import moment from '@evelia/helpers/dateHelpers'
import { miniSerializeError } from '@reduxjs/toolkit'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import dashboardActions from '../actions/dashboardActions'
import inboundInvoiceActions from '../actions/inboundInvoiceActions'
import { fetchDashboard } from '../api/dashboardApi'
import { genericSagaErrorHandler } from '../helpers/sagaHelpers'

function* dashboardFetchFlow({ data = {} }) {
  try {
    const currentDashboard = yield select(dashboardSelector)
    if(!currentDashboard.lastFetched || moment().diff(currentDashboard.lastFetched, 'minutes') > 5 || data.force) {
      yield put(dashboardActions.fetchStart())
      const { data, inboundInvoiceStats } = yield call(fetchDashboard)
      yield put(dashboardActions.fetchSuccess(data))
      yield put(inboundInvoiceActions.fetchStatsSuccess(inboundInvoiceStats))
    }
  } catch(err) {
    yield put(dashboardActions.fetchError(miniSerializeError(err)))
    yield * genericSagaErrorHandler(err, 'Virhe tilastojen noutamisessa')
  }
}

const dashboardSelector = state => state.dashboard

export default function* dashboardSaga() {
  yield takeLatest(dashboardActions.actionTypes.fetchRequest, dashboardFetchFlow)
}
