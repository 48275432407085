import { parseTemplate } from 'url-template'

import {
  defaultEmbeddedNormalizer,
  del,
  download,
  get,
  post,
  put,
  upload
} from '../helpers/apiHelpers'

const fileUrlTemplate = parseTemplate('/api/files{/fileId,fileName}{/relationType,relationId}')

export const fileUrl = file => `/api/files/${file.id}/${file.fileName}`

export const createFile = file =>
  post(fileUrlTemplate.expand({}), {
    body: JSON.stringify(file)
  })

export const updateFile = file =>
  put(fileUrlTemplate.expand({ fileId: file.id }), {
    body: JSON.stringify(file)
  })

export const deleteFile = file =>
  del(fileUrlTemplate.expand({ fileId: file.id }))

export const fetchFiles = (params = {}) =>
  get(fileUrlTemplate.expand(params))

export const fetchCustomerRelationFiles = async(params = {}) => {
  const response = await get(fileUrlTemplate.expand({ relationType: 'customers', relationId: params.customerId }))
  return defaultEmbeddedNormalizer(response)
}

export const fetchProjectRelationFiles = async(params = {}) => {
  const response = await get(fileUrlTemplate.expand({ relationType: 'projects', relationId: params.projectId }))
  return defaultEmbeddedNormalizer(response)
}

export const uploadToS3 = (file, data) => {
  const formData = new FormData()
  Object.entries(data.fields).forEach(([key, value]) => {
    formData.append(key, value)
  })
  formData.append('file', file)
  return upload(data.url, {
    body: formData
  })
}

export const downloadFile = file => {
  return download(fileUrlTemplate.expand({ fileId: file.id, fileName: file.fileName }))
}
