import { parseTemplate } from 'url-template'

import constants from '../constants'
import { actionCreatorsFor } from '../helpers/actionHelpers'
import { navigateTo } from './navigateActions'

const workUrlTemplate = parseTemplate('{+baseUrl}{/workRecordId}')
const getUrl = (baseUrl, workRecordId, params) => workUrlTemplate.expand({ baseUrl, workRecordId: workRecordId || 'new', ...params })

const baseActionCreators = actionCreatorsFor(constants.actionKeys.WORK_CUSTOM_STATES)

const additionalActionCreators = {
  navigateTo: (baseUrl, workRecordId, replaceUrl, params) => navigateTo(getUrl(baseUrl, workRecordId, params), replaceUrl)
}

const workCustomStateActions = { ...baseActionCreators, ...additionalActionCreators }
export default workCustomStateActions
