/* eslint-disable handle-callback-err, react/prop-types */
import { Button } from 'reactstrap'
import { faCalendarAlt, faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import FormPlainInput from './FormPlainInput'

const FormMaterialUiInputAdapter = ({
  errors,
  inputRef,
  setOpen,
  isDate,
  value,
  name,
  label,
  inputOnly,
  disabled,
  tooltipError,
  error: __error,
  helperText: __helperText,
  onChange,
  hideInput,
  InputProps: __inputProps,
  ...props
}) => hideInput
  ? (
    <Button
      onClick={setOpen}
      disabled={disabled}
      color='primary'
      title='Valitse'
      size='sm'
      tabIndex={-1}
    >
      <FontAwesomeIcon icon={isDate ? faCalendarAlt : faClock} />
    </Button>
    )
  : (
    <FormPlainInput
      name={name}
      value={value}
      onChange={onChange}
      label={label}
      inputOnly={inputOnly}
      errors={errors}
      tooltipError={tooltipError}
      isCustomAddon
      append={(
        <Button
          onClick={setOpen}
          disabled={disabled}
          color='primary'
          title='Valitse'
          size='sm'
          tabIndex={-1}
        >
          <FontAwesomeIcon icon={isDate ? faCalendarAlt : faClock} />
        </Button>
        )}
      inputProps={props}
    />

    )

export default FormMaterialUiInputAdapter
