import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import memoize from 'micro-memoize'

import CommonValueFormGroup from './CommonValueFormGroup'
import TypeaheadFormInput from './inputs/TypeaheadFormInput'

export const getRelevantValidationMessage = props => {
  const { validationErrors, field } = props
  const relevantFields = Object.values(validationErrors).filter(value => value.param.startsWith(field + '['))
  if(relevantFields.length) {
    const relevantValidationMessage = relevantFields
      .reduce((acc, value) => (acc ? acc + ' | ' : '') + value.msg + ': ' + value.value, validationErrors[field] ? validationErrors[field].msg : '')
    return {
      ...validationErrors,
      [field]: {
        location: 'body',
        param: field,
        value: null,
        msg: relevantValidationMessage
      }
    }
  }
  return validationErrors
}

class TypeaheadFormGroup extends PureComponent {
  render() {
    const {
      formGroupClassName,
      field,
      testId
    } = this.props

    const validationErrors = getRelevantValidationMessage(this.props)

    const isInvalid = !!validationErrors[field]
    return (
      <CommonValueFormGroup
        forceFeedback
        className={formGroupClassName}
        {...this.props}
        validationErrors={validationErrors}
        inputProps={this.getInputProps(field, isInvalid)}
        Component={TypeaheadFormInput}
        groupTestId={testId || `${field}-group`}
      />
    )
  }

  getInputProps = memoize((field, isInvalid) => ({
    className: isInvalid ? 'is-invalid' : '',
    'data-testid': field
  }))
}

TypeaheadFormGroup.propTypes = {
  async: PropTypes.bool,
  autoFocus: PropTypes.bool,
  field: PropTypes.string.isRequired,
  selected: PropTypes.array,
  defaultSelected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  labelKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  label: PropTypes.node,
  multiple: PropTypes.bool,
  allowNew: PropTypes.bool,
  helpText: PropTypes.string,
  placeholder: PropTypes.string,
  setNull: PropTypes.bool,
  emptyLabel: PropTypes.string,
  promptText: PropTypes.string,
  searchText: PropTypes.string,
  newSelectionPrefix: PropTypes.string,
  options: PropTypes.array,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  setRef: PropTypes.object,
  handleInputChange: PropTypes.func,
  selectWithEnter: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSearch: PropTypes.func,
  validationErrors: PropTypes.object,
  filterBy: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.func
  ]),
  renderMenuItemChildren: PropTypes.func,
  onMenuToggle: PropTypes.func,
  flip: PropTypes.bool,
  disabled: PropTypes.bool,
  disableAll: PropTypes.bool,
  dropup: PropTypes.bool,
  isRequired: PropTypes.bool,
  formGroupClassName: PropTypes.string,
  model: PropTypes.object.isRequired,
  testId: PropTypes.string
}

TypeaheadFormGroup.defaultProps = {
  validationErrors: {}
}

export default TypeaheadFormGroup
