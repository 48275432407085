import { call, put, takeEvery, takeLeading } from 'redux-saga/effects'

import productLineActions from '../actions/productLineActions'
import {
  createProductLine,
  deleteNetPrices,
  deleteProductLine,
  fetchProductLines,
  fetchSupplierStats,
  updateProductLine
} from '../api/productLineApi'
import { actionTypes } from '../constants'
import { addSuccessNotification } from '../helpers/notificationHelpers'
import {
  createFlow,
  createSocketWatcher,
  deleteFlow,
  fetchFlow,
  genericSagaErrorHandler,
  getPromiseHandlersFromData,
  updateFlow
} from '../helpers/sagaHelpers'

const watchOnSockets = createSocketWatcher('productLine', {
  created: productLineActions.createSuccess,
  updated: productLineActions.updateSuccess,
  deleted: productLineActions.deleteSuccess
})

const productLineFetchFlow = fetchFlow({
  fetchApi: fetchProductLines,
  actions: productLineActions,
  base: 'productLines',
  errorMsg: 'tuotelinjojen'
})

const productLineUpdateFlow = updateFlow(updateProductLine, productLineActions, 'Tuotelinja', 'Tuotelinjan')
const productLineCreateFlow = createFlow(createProductLine, productLineActions, 'Tuotelinja', 'Tuotelinjan')
const productLineDeleteFlow = deleteFlow({
  deleteApi: deleteProductLine,
  actions: productLineActions,
  singular: 'Tuotelinja',
  errorMsg: 'Tuotelinjan',
  base: 'productLines'
})

const productLineSupplierStatsFetchFlow = fetchFlow({
  fetchApi: fetchSupplierStats,
  actions: productLineActions.supplierStats,
  base: 'productLines.supplierStats',
  errorMsg: 'toimittajatilastojen',
  idField: '_id'
})

const deleteNetPricesFlow = function* ({ record, data = {} }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    const [newSupplierStats] = yield call(deleteNetPrices, record)
    yield put(productLineActions.supplierStats.updateSuccess(newSupplierStats))

    addSuccessNotification(`Nettohinnasto poistettu`)
    yield call(resolve)
  } catch(err) {
    yield * genericSagaErrorHandler(err, `Virhe nettohinnaston poistossa`, reject)
  }
}

export default function* productLineSaga() {
  yield takeLeading(productLineActions.actionTypes.fetchRequest, productLineFetchFlow)
  yield takeEvery(productLineActions.actionTypes.updateRequest, productLineUpdateFlow)
  yield takeEvery(productLineActions.actionTypes.createRequest, productLineCreateFlow)
  yield takeEvery(productLineActions.actionTypes.deleteRequest, productLineDeleteFlow)

  yield takeLeading(productLineActions.supplierStats.actionTypes.fetchRequest, productLineSupplierStatsFetchFlow)
  yield takeEvery(actionTypes.PRODUCT_LINES_DELETE_NET_PRICES_REQUEST, deleteNetPricesFlow)

  yield watchOnSockets()
}
