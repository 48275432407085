import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'

import { selectOptionsPropType } from '../../../propTypes'
import { commonFormInputDefaultProps, commonFormInputPropTypes } from '../propTypes'

const getOption = option => {
  const value = option.value == null ? '' : option.value
  const className = option.className || ''
  return <option className={className} key={value} value={value}>{option.text}</option>
}

class SelectFormInput extends PureComponent {
  render() {
    const {
      field,
      value,
      options,
      isInvalid,
      disabled,
      disableAll,
      inputId,
      onBlur,
      onKeyDown,
      testId
    } = this.props
    return (
      <Input
        invalid={isInvalid}
        type='select'
        name={field}
        id={inputId}
        value={value == null ? '' : value}
        onChange={this.handleOnChange}
        disabled={disabled || disableAll}
        className='custom-select'
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        data-testid={testId || field}
      >
        {this.mapOptions(options)}
      </Input>
    )
  }

  mapOptions = options => {
    if(!options) {
      options = []
    }
    const { value, includeNull, nullText } = this.props
    const nullValue = options.find(option => option.value === null)
    const returnArray = []
    if((value == null || includeNull) && !nullValue) {
      returnArray.push(<option key='' value=''>{nullText}</option>)
    }
    return [...returnArray, ...options.map(option => {
      if(option.options) {
        return (
          <optgroup label={option.text} key={option.text}>
            {option.options.map(option => getOption(option))}
          </optgroup>
        )
      } else {
        return getOption(option)
      }
    })]
  }

  handleOnChange = event => {
    const { onChange, isNumeric } = this.props
    const value = event.target.value === '' ? null : (isNumeric ? Number(event.target.value) : event.target.value)
    return onChange(value, event)
  }
}
SelectFormInput.propTypes = {
  ...commonFormInputPropTypes,
  options: selectOptionsPropType,
  includeNull: PropTypes.bool.isRequired,
  isNumeric: PropTypes.bool.isRequired,
  nullText: PropTypes.string
}

SelectFormInput.defaultProps = {
  ...commonFormInputDefaultProps,
  includeNull: false,
  isNumeric: false
}

export default SelectFormInput
