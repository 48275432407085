import { Component } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import noop from 'lodash/noop'
import setWith from 'lodash/setWith'

import TextFormInput from './inputs/TextFormInput'
import { commonFormInputDefaultProps, commonFormInputPropTypes } from './propTypes'

class HiddenValueFormGroup extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    if(get(nextProps.model, this.props.field) !== get(this.props.model, this.props.field)) {
      return true
    }
    if(Object.entries(nextProps).filter(([key]) => key !== 'model').some(([key, value]) => this.props[key] !== value)) {
      return true
    }
    return false
  }

  render() {
    const {
      label,
      helpText,
      className,
      forceFeedback,
      Component,
      model,
      defaultValue,
      setWithCustomizer,
      onChange,
      isHidden,
      inputOnly,
      value,
      ...inputProps
    } = this.props
    const { field, setNull } = inputProps

    let currentValue = get(model, field)
    if(currentValue === undefined && onChange) {
      currentValue = defaultValue !== undefined ? defaultValue : (setNull ? null : '')
      onChange(null, setWith({}, field, currentValue, setWithCustomizer), true)
    }
    return <Component {...inputProps} type='hidden' onChange={noop} />
  }
}

HiddenValueFormGroup.propTypes = {
  ...commonFormInputPropTypes,
  onChange: PropTypes.func,
  Component: PropTypes.elementType
}

HiddenValueFormGroup.defaultProps = {
  ...commonFormInputDefaultProps,
  Component: TextFormInput
}

export default HiddenValueFormGroup
