import { Control, FieldValues, Path, useController } from 'react-hook-form'

import FormPlainDateInput from './FormPlainDateInput'

interface FormDateInputProps<T extends FieldValues> extends Omit<React.ComponentProps<typeof FormPlainDateInput>, 'onChange'> {
  control: Control<T>
  name: Path<T>
}

const FormDateInput = <T extends FieldValues>({
  control,
  errors,
  ...props
}: FormDateInputProps<T>) => {
  const { field, formState } = useController({ name: props.name, control })
  const {
    value,
    onChange,
    ref,
    ...fieldProps
  } = field
  const inputErrors = formState.errors

  return (
    <FormPlainDateInput
      {...props}
      value={value}
      errors={errors ?? inputErrors}
      onChange={onChange}
      inputProps={{
        innerRef: ref,
        ...props.inputProps,
        ...fieldProps
      }}
    />
  )
}

export default FormDateInput
