import constants, { machineSystemTypes } from '../constants'
import { getCombinedListReducerFor, getTableOptions } from '../helpers/reducerHelpers'
import tableReducerFor from './tableReducer'

const commonOptions = { orderBy: ['name'], sortOrder: ['ASC'], filters: { includeUnusable: false } }

export const initialMachineTableOptions = {
  default: getTableOptions({ ...commonOptions, fixedFilters: { systemType: machineSystemTypes.MACHINE } }),
  resource: getTableOptions({ ...commonOptions, fixedFilters: { systemType: machineSystemTypes.RESOURCE } }),
  internal: getTableOptions({ ...commonOptions, fixedFilters: { systemType: machineSystemTypes.INTERNAL } })
}

export default getCombinedListReducerFor(constants.actionKeys.MACHINES, {
  memos: getCombinedListReducerFor(constants.actionKeys.MACHINE_MEMOS),
  files: getCombinedListReducerFor(constants.actionKeys.MACHINE_FILES),
  machineLogs: getCombinedListReducerFor(constants.actionKeys.MACHINE_LOGS),
  machineLogTypes: getCombinedListReducerFor(constants.actionKeys.MACHINE_LOG_TYPES, {}, '_id'),
  machineTypes: getCombinedListReducerFor(constants.actionKeys.MACHINE_TYPES),
  machinePricingRules: getCombinedListReducerFor(constants.actionKeys.MACHINE_PRICING_RULES),
  tableOptions: tableReducerFor(constants.actionKeys.MACHINES, initialMachineTableOptions)
})
