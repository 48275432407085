import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Alert } from 'reactstrap'

import { findSystemCustomerSettings } from '../../selectors/systemCustomerSelectors'

const MultiFactorAuthenticationInfo = ({ isMfaSetup, message }) => {
  const companyData = useSelector(findSystemCustomerSettings)
  const defaultMessage = `Yritykselle ${companyData.details.name} on määritetty käyttöön kaksivaiheinen tunnistautuminen.${isMfaSetup ? ' Ota kaksivaiheinen tunnistautuminen käyttöön seuraamalla näitä ohjeita.' : ''}`
  return (
    <Alert data-testid='mfaInfo'>{message || defaultMessage}</Alert>
  )
}

MultiFactorAuthenticationInfo.propTypes = {
  isMfaSetup: PropTypes.bool,
  message: PropTypes.string
}

export default MultiFactorAuthenticationInfo
