import { createApi } from '../helpers/apiHelpers'
import { parseFilterParams, parseTableOptionsFromQuery } from '../helpers/reducerHelpers'
import { optionsQueryBase } from './urlTemplates'

const normalizePacketMatrices = response => {
  const { records, record, _embedded } = response
  const {
    options,
    packets,
    installations,
    packetInstallations
  } = _embedded || {}
  const data = records || record
  return {
    data,
    packets,
    installations,
    packetInstallations,
    tableOptions: parseTableOptionsFromQuery(options)
  }
}

export const packetMatrixApi = createApi({
  base: 'packet_matrices',
  normalizer: normalizePacketMatrices,
  extraUrlTemplate: `${optionsQueryBase}`,
  editGetParams: parseFilterParams
})
