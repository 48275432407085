import PropTypes from 'prop-types'

const Subtitle = ({ children, className = '' }) =>
  <p className={`text-dark fw-bold ${className}`}>{children}</p>

Subtitle.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default Subtitle
