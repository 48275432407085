import constants, { actionTypes } from '../constants'
import { actionCreatorsFor, request } from '../helpers/actionHelpers'

const baseActionCreators = actionCreatorsFor(constants.actionKeys.INVITATIONS)

const additionalActionCreators = {
  unsubscribe: request(actionTypes.INVITATIONS_UNSUBSCRIBE)
}

const invitationActions = { ...baseActionCreators, ...additionalActionCreators }
export default invitationActions
