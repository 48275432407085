import { parseTemplate } from 'url-template'

import constants, { actionTypes } from '../constants'
import {
  actionCreatorsFor,
  error,
  request,
  start,
  success
} from '../helpers/actionHelpers'
import { PATH_PRODUCTS_WORK_RECORD_PRODUCTS } from '../routes'
import { navigateTo } from './navigateActions'

const workUrlTemplate = parseTemplate(`${PATH_PRODUCTS_WORK_RECORD_PRODUCTS}/{workRecordId}`)
export const getUrl = (workRecordId, params) => workUrlTemplate.expand({ workRecordId: workRecordId || 'new', ...params })

const baseActionCreators = actionCreatorsFor(constants.actionKeys.WORK_RECORD_PRODUCTS, { fetchStats: true })

const additionalActionCreators = {
  navigateTo: (workRecordId, replaceUrl, params) => navigateTo(getUrl(workRecordId, params), replaceUrl),
  navigateToBase: replaceUrl => navigateTo(PATH_PRODUCTS_WORK_RECORD_PRODUCTS, replaceUrl),
  salaryTypes: actionCreatorsFor(constants.actionKeys.WORK_RECORD_PRODUCT_SALARY_TYPES, {}, '_id'),
  pricingRules: actionCreatorsFor(constants.actionKeys.WORK_RECORD_PRODUCT_PRICING_RULES, {}),
  fetchStatsRequest: request(baseActionCreators.actionTypes.fetchStatsRequest),
  fetchStatsStart: start(baseActionCreators.actionTypes.fetchStatsStart),
  fetchStatsSuccess: success(baseActionCreators.actionTypes.fetchStatsSuccess),
  fetchStatsError: error(baseActionCreators.actionTypes.fetchStatsError),
  doPatchAction: request(actionTypes.WORK_RECORD_PRODUCT_PATCH_ACTION_REQUEST)
}

const workRecordProductActions = { ...baseActionCreators, ...additionalActionCreators }
export default workRecordProductActions
