import { capitalizeObjectValues } from '../../helpers/helpers'

const ticketTitles = {
  basic: 'tehtävä',
  genetive: 'tehtävän',
  partitive: 'tehtävää',
  allative: 'tehtävälle',
  pluralBasic: 'tehtävät',
  pluralGenetive: 'tehtävien',
  pluralPartitive: 'tehtäviä'
}

export const ticketTemplateListTitles = {
  basic: 'tehtävälista',
  genetive: 'tehtävälistan',
  partitive: 'tehtävälistaa',
  allative: 'tehtävälistalle',
  pluralBasic: 'tehtävälistat',
  pluralGenetive: 'tehtävälistojen',
  pluralPartitive: 'tehtävälistoja'
}

export const ticketTitlesCapitalized = capitalizeObjectValues(ticketTitles)

export default ticketTitles
