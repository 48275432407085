import { actionTypes } from '../constants'

const initialEaddress = {}

const eAddressReducer = (state = initialEaddress, action) => {
  switch(action.type) {
    case actionTypes.EADDRESS_FETCH_START:
      return {
        ...state,
        busy: true
      }
    case actionTypes.EADDRESS_FETCH_SUCCESS:
      return {
        ...action.record,
        busy: false
      }
    case actionTypes.EADDRESS_FETCH_ERROR:
      return {
        busy: false
      }
    default:
      return state
  }
}

export default eAddressReducer
