import { getSelf } from '@evelia/helpers/helpers'
import { miniSerializeError } from '@reduxjs/toolkit'
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  takeLeading
} from 'redux-saga/effects'

import machineActions from '../actions/machineActions'
import { machineApi, normalizeMachines } from '../api/machineApi'
import { machineLogApi } from '../api/machineLogApi'
import { machinePricingRuleApi } from '../api/machinePricingRuleApi'
import { machineTypeApi } from '../api/machineTypeApi'
import machineTitles, { machineTitlesCapitalized } from '../components/Machines/machineTitles'
import { getFileLinkSagas, getMemoSagas } from '../helpers/generalSagas'
import {
  createFlow,
  createSocketWatcher,
  createSocketWatcherWithApiHandlerAndNormalizer,
  deleteFlow,
  fetchFlow,
  genericSagaErrorHandler,
  getSubSagas,
  searchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

const titles = machineTitles
const titlesCapitalized = machineTitlesCapitalized
const reduxName = 'machines'

export const handleMachineApiResponse = (mainAction, tableIdentifier) =>
  function* ({ data, tableOptions, machinePricingRules }) {
    yield put(mainAction(data))

    if(tableIdentifier != null && tableOptions && tableOptions.orderBy != null) {
      yield put(machineActions.tableActions.updateOptions(tableOptions, tableIdentifier))
    }
    yield put(machineActions.machinePricingRules.fetchSuccess(machinePricingRules.data))
    return data
  }

const watchOnMachineSockets = createSocketWatcherWithApiHandlerAndNormalizer('machine', machineActions, handleMachineApiResponse, normalizeMachines)

const machineFetchFlow = fetchFlow({
  fetchApi: machineApi.fetch,
  actions: machineActions,
  base: reduxName,
  idField: 'id',
  errorMsg: titles.genetive,
  getApiResponseHandler: data => handleMachineApiResponse(machineActions.fetchSuccess, data.tableIdentifier)
})

const machineUpdateFlow = updateFlow(machineApi.update, machineActions, titlesCapitalized.basic, titlesCapitalized.genetive, handleMachineApiResponse(machineActions.updateSuccess))
const machineCreateFlow = createFlow(machineApi.create, machineActions, titlesCapitalized.basic, titlesCapitalized.genetive, handleMachineApiResponse(machineActions.createSuccess))
const machineDeleteFlow = deleteFlow({
  deleteApi: machineApi.remove,
  actions: machineActions,
  singular: titlesCapitalized.basic,
  errorMsg: titlesCapitalized.genetive,
  base: reduxName
})
const machineSearchFlow = searchFlow(search => machineApi.fetch({ q: search }), machineActions, titlesCapitalized.pluralGenetive, function* (data, searchTerm) {
  const machines = yield handleMachineApiResponse(machineActions.fetchSuccess)(data)
  yield put(machineActions.searchSuccess(machines, searchTerm))
  return machines
})

const memoSagas = getMemoSagas({
  actions: machineActions,
  baseName: 'machines',
  socketName: 'machine',
  titleGenetive: titles.genetive
})

const fileLinkSagas = getFileLinkSagas({
  actions: machineActions,
  baseName: 'machines',
  baseIdFieldName: 'machineId',
  socketName: 'machine',
  titleGenetive: titles.genetive
})

const machineLogTypeFetchFlow = function* ({ data }) {
  try {
    yield put(machineActions.machineLogTypeFetchStart())
    const apiResponse = yield call(() => machineApi.fetch({ subItem: 'machine_log_types' }, { normalizer: getSelf }))
    // NOTE: replace data so optimistically updated results gets cleared
    yield put(machineActions.machineLogTypeFetchSuccess(apiResponse, { updateLastFetched: true, replace: true }))
  } catch(err) {
    yield put(machineActions.machineLogTypeFetchError(miniSerializeError(err)))
    yield * genericSagaErrorHandler(err, 'Virhe konetyyppien', 'noudossa')
  }
}

const getMachineLogSagas = () => {
  const handleMachineLogApiResponse = mainAction =>
    function* ({ data }) {
      yield put(mainAction(data))
      if(data.type) {
        const isNewType = yield select(state => !state.machines.machineLogTypes.records.every(logType => logType !== data.type))
        if(isNewType) {
          yield put(machineActions.machineLogTypeFetchSuccess({ _id: data.type, type: data.type }))
        }
      }
      return data
    }

  const machineLogSagas = getSubSagas({
    mainActions: machineActions,
    mainReduxName: 'machines',
    subReduxName: 'machineLogs'
  }, {
    fetchApi: machineLogApi.fetch,
    createApi: machineLogApi.create,
    updateApi: machineLogApi.update,
    deleteApi: machineLogApi.remove
  }, {
    singular: `${titlesCapitalized.genetive} tapahtuma`,
    accusative: `${titlesCapitalized.genetive} tapahtuman`,
    fetchError: `Virhe ${titles.genetive} tapahtumien noutamisessa`,
    deleteSuccess: `${titlesCapitalized.genetive} tapahtuma poistettu`,
    deleteError: `Virhe ${titles.genetive} tapahtuman poistamisessa`
  }, handleMachineLogApiResponse)

  const watchOnMachineLogSockets = createSocketWatcher(`machineLog`, {
    created: machineActions.machineLogs.createSuccess,
    updated: machineActions.machineLogs.updateSuccess,
    deleted: machineActions.machineLogs.deleteSuccess
  }, ({ record }) => record)

  return function* machineLogSagaRunner() {
    yield takeEvery(machineActions.machineLogs.actionTypes.fetchRequest, machineLogSagas.subFetchFlow)
    yield takeEvery(machineActions.machineLogs.actionTypes.updateRequest, machineLogSagas.subUpdateFlow)
    yield takeEvery(machineActions.machineLogs.actionTypes.createRequest, machineLogSagas.subCreateFlow)
    yield takeLatest(machineActions.machineLogs.actionTypes.deleteRequest, machineLogSagas.subDeleteFlow)
    yield takeLatest(machineActions.machineLogs.actionTypes.deleteRequest, machineLogTypeFetchFlow)

    yield all([
      watchOnMachineLogSockets()
    ])
  }
}

const machineLogSagas = getMachineLogSagas()

const getMachinePricingRuleSagas = () => {
  const handleMachinePricingRuleApiResponse = mainAction =>
    function* handleMachinePricingRuleApiResponse({ data }) {
      yield put(mainAction(data))
    }
  const machinePricingRuleSagas = getSubSagas({
    mainActions: machineActions,
    mainReduxName: 'machines',
    subReduxName: 'machinePricingRules'
  }, {
    fetchApi: machinePricingRuleApi.fetch,
    createApi: machinePricingRuleApi.create,
    updateApi: machinePricingRuleApi.update,
    deleteApi: machinePricingRuleApi.remove
  }, {
    singular: `${titlesCapitalized.genetive} hinnoittelutapa`,
    accusative: `${titlesCapitalized.genetive} maksutavan`,
    fetchError: `Virhe ${titles.genetive} maksutapojen noutamisessa`,
    deleteSuccess: `${titlesCapitalized.genetive} hinnoittelutapa poistettu`,
    deleteError: `Virhe ${titles.genetive} maksutavan poistamisessa`
  }, handleMachinePricingRuleApiResponse)

  const watchOnMachinePricingRuleSockets = createSocketWatcher(`machinePricingRule`, {
    created: machineActions.machinePricingRules.createSuccess,
    updated: machineActions.machinePricingRules.updateSuccess,
    deleted: machineActions.machinePricingRules.deleteSuccess
  }, response => response.record || response)

  return function* machinePricingRuleSagaRunner() {
    yield takeEvery(machineActions.machinePricingRules.actionTypes.fetchRequest, machinePricingRuleSagas.subFetchFlow)
    yield takeEvery(machineActions.machinePricingRules.actionTypes.updateRequest, machinePricingRuleSagas.subUpdateFlow)
    yield takeEvery(machineActions.machinePricingRules.actionTypes.createRequest, machinePricingRuleSagas.subCreateFlow)
    yield takeLatest(machineActions.machinePricingRules.actionTypes.deleteRequest, machinePricingRuleSagas.subDeleteFlow)

    yield all([
      watchOnMachinePricingRuleSockets()
    ])
  }
}
const machinePricingRuleSagas = getMachinePricingRuleSagas()

const getMachineTypeSagas = () => {
  const handleMachineTypeApiResponse = (mainAction, extra) =>
    function* handleMachineTypeApiResponse({ data }) {
      yield put(mainAction(data, extra))
    }
  const machineTypeSagas = getSubSagas({
    mainActions: machineActions,
    mainReduxName: 'machines',
    subReduxName: 'machineTypes'
  }, {
    fetchApi: machineTypeApi.fetch,
    createApi: machineTypeApi.create,
    updateApi: machineTypeApi.update,
    deleteApi: machineTypeApi.remove
  }, {
    singular: `${titlesCapitalized.genetive} tyyppi`,
    accusative: `${titlesCapitalized.genetive} tyypin`,
    fetchError: `Virhe ${titles.genetive} tyyppien noutamisessa`,
    deleteSuccess: `${titlesCapitalized.genetive} tyyppi poistettu`,
    deleteError: `Virhe ${titles.genetive} tyypin poistamisessa`
  }, handleMachineTypeApiResponse)

  const watchOnMachineTypeSockets = createSocketWatcher(`machineType`, {
    created: machineActions.machineTypes.createSuccess,
    updated: machineActions.machineTypes.updateSuccess,
    deleted: machineActions.machineTypes.deleteSuccess
  }, ({ record }) => record)

  // Use custom flow due getSubSagas doesn't support updateLastFetched
  const subFetchFlow = fetchFlow({
    fetchApi: machineTypeApi.fetch,
    actions: machineActions.machineTypes,
    base: `machines.machineTypes`,
    idField: 'id',
    errorMsg: 'tyypin',
    updateLastFetched: true,
    apiResponseHandler: handleMachineTypeApiResponse?.(machineActions.machineTypes.fetchSuccess, { updateLastFetched: true })
  })

  return function* machineTypeSagaRunner() {
    yield takeLeading(machineActions.machineTypes.actionTypes.fetchRequest, subFetchFlow)
    yield takeEvery(machineActions.machineTypes.actionTypes.updateRequest, machineTypeSagas.subUpdateFlow)
    yield takeEvery(machineActions.machineTypes.actionTypes.createRequest, machineTypeSagas.subCreateFlow)
    yield takeLatest(machineActions.machineTypes.actionTypes.deleteRequest, machineTypeSagas.subDeleteFlow)

    yield all([
      watchOnMachineTypeSockets()
    ])
  }
}
const machineTypeSagas = getMachineTypeSagas()

export default function* machineSaga() {
  yield takeEvery(machineActions.actionTypes.fetchRequest, machineFetchFlow)
  yield takeEvery(machineActions.actionTypes.updateRequest, machineUpdateFlow)
  yield takeEvery(machineActions.actionTypes.createRequest, machineCreateFlow)
  yield takeEvery(machineActions.actionTypes.deleteRequest, machineDeleteFlow)
  yield takeEvery(machineActions.actionTypes.searchRequest, machineSearchFlow)

  yield takeLeading(machineActions.actionTypes.machineLogTypeFetchRequest, machineLogTypeFetchFlow)

  yield all([
    memoSagas(),
    fileLinkSagas(),
    watchOnMachineSockets(),
    machineLogSagas(),
    machinePricingRuleSagas(),
    machineTypeSagas()
  ])
}
