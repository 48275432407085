import { createRef, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Progress } from 'reactstrap'

import EveliaButton from '../Buttons/EveliaButton'
import IconButton from '../Buttons/IconButton'

export class DelayedActionButton extends PureComponent {
  state = {
    displayTimer: false,
    seconds: null,
    progress: null,
    isLoading: false
  }

  interval = createRef()

  startTimer = event => {
    event.preventDefault()
    event.stopPropagation()
    const seconds = Math.floor(this.props.timeout / 1000)
    const refreshRate = 10
    const progress = 100

    this.setState({ displayTimer: true, seconds, progress })

    this.interval.current = setInterval(() => {
      let progress = this.state.progress - Math.round((100 / ((seconds) * refreshRate)))
      if(this.state.progress <= 0 || progress <= 0) {
        progress = 0
        this.clear()
      }
      this.setState({ progress })
    }, 1000 / refreshRate)
  }

  handleActionClick = event => {
    this.setState({ isLoading: true })
    const maybePromise = this.props.onClick(event)
    return (maybePromise?.then ? maybePromise : Promise.resolve())
      .finally(this.clear)
  }

  componentWillUnmount = () => {
    clearInterval(this.interval.current)
    this.interval.current = null
  }

  clear = () => {
    if(!this.interval.current) {
      return
    }
    clearInterval(this.interval.current)
    this.setState({ displayTimer: false, isLoading: false })
  }

  render() {
    const {
      color,
      onClick,
      confirmationMessage,
      timeout,
      children,
      outline,
      disabled,
      isBusy,
      size = 'sm',
      ...rest
    } = this.props
    const isDisabled = disabled || isBusy || this.state.isLoading
    const ButtonComponent = rest.icon ? IconButton : EveliaButton
    if(rest.icon) {
      rest.condensed = true
    }
    return (
      this.state.displayTimer
        ? (
          <ButtonComponent
            color={color}
            onClick={this.handleActionClick}
            disabled={isDisabled}
            size={size}
            {...rest}
          >
            {rest.close ? null : (confirmationMessage || children)}
            <Progress className='delay-progress-bar' value={Math.round(this.state.progress)} />
          </ButtonComponent>
          )
        : (
          <ButtonComponent
            color={color}
            onClick={this.startTimer}
            outline={outline}
            disabled={isDisabled}
            size={size}
            {...rest}
          >
            {children}
          </ButtonComponent>
          )
    )
  }

  static propTypes = {
    timeout: PropTypes.number,
    color: PropTypes.string,
    confirmationMessage: PropTypes.string,
    children: PropTypes.node,
    onClick: PropTypes.func.isRequired,
    outline: PropTypes.bool,
    hint: PropTypes.string,
    disabled: PropTypes.bool,
    isBusy: PropTypes.bool,
    testId: PropTypes.string,
    size: PropTypes.string
  }

  static defaultProps = {
    timeout: 4000,
    color: 'danger',
    confirmationMessage: 'Varmista painamalla toisen kerran'
  }
}

export default DelayedActionButton
