export const rowTypes = {
  ROW_TYPE_DENOMINATION_GROUP: 'denominationGroup',
  ROW_TYPE_DENOMINATION: 'denomination',
  ROW_TYPE_INBOUND_INVOICE: 'inboundInvoice',
  ROW_TYPE_WORK: 'work',
  ROW_TYPE_BUDGET: 'projectDenominationBudget'
}

export const columnTypes = {
  COLUMN_TYPE_INITIAL_BUDGET: 'initialBudget',
  COLUMN_TYPE_ESTIMATED: 'estimatedBudget',
  COLUMN_TYPE_TOTAL_SUM: 'totalSum',
  COLUMN_TYPE_PERCENT: 'percent'
}

export const columnTypeMap = {
  [columnTypes.COLUMN_TYPE_INITIAL_BUDGET]: {
    name: 'Budjetoitu',
    defaultVisibility: false
  },
  [columnTypes.COLUMN_TYPE_ESTIMATED]: {
    name: 'Ennuste',
    defaultVisibility: true
  },
  [columnTypes.COLUMN_TYPE_TOTAL_SUM]: {
    name: 'Toteutunut',
    defaultVisibility: true
  },
  [columnTypes.COLUMN_TYPE_PERCENT]: {
    name: '%',
    defaultVisibility: false
  }
}
