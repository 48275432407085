import { parseTemplate } from 'url-template'

import {
  del,
  get,
  getSubEntityApi,
  post,
  put
} from '../helpers/apiHelpers'
import { parseFilterParams, parseTableOptionsFromQuery } from '../helpers/reducerHelpers'
import { optionsQueryBase } from './urlTemplates'

const baseUrl = '/api/customers{/customerId}{?newCustomerId}{/action}'

const customerUrlTemplate = parseTemplate(`${baseUrl}${optionsQueryBase}`)
const customerContactUrlTemplate = parseTemplate(baseUrl + '/contacts{/customerContactId}')
const customerFileUrlTemplate = parseTemplate(baseUrl + '/files{/customerFileId}')
export const customerSubItemUrlTemplate = parseTemplate(baseUrl + '{/subItem,subItemType,subItemId}{?name,businessId,targetId}')
const customerContactFetchUrlTemplate = parseTemplate(baseUrl + '/contacts')

export const normalizeCustomers = response => {
  const { _embedded, records, record } = response
  const {
    contacts,
    options
  } = _embedded || {}
  const data = records || record
  return {
    data,
    contacts,
    tableOptions: parseTableOptionsFromQuery(options)
  }
}

export const fetchCustomers = (params = {}) => {
  const filterParams = parseFilterParams(params)
  return get(customerUrlTemplate.expand(filterParams))
    .then(normalizeCustomers)
}

export const updateCustomer = (customer, actionData = {}) => {
  return put(customerUrlTemplate.expand({ customerId: customer.id, ...actionData }), {
    body: JSON.stringify(customer)
  }).then(normalizeCustomers)
}

export const createCustomer = (customer, actionData = {}) => {
  return post(customerUrlTemplate.expand(actionData), {
    body: JSON.stringify(customer)
  }).then(normalizeCustomers)
}

export const searchCustomers = search => {
  return get(customerUrlTemplate.expand({ q: search }))
    .then(normalizeCustomers)
}

export const {
  createApi: createCustomerContact,
  deleteApi: deleteCustomerContact
} = getSubEntityApi(customerContactUrlTemplate, 'customerId', 'contactId', 'customerContactId', 'contact')

export const {
  fetchApi: fetchCustomerFiles,
  createApi: createCustomerFile,
  deleteApi: deleteCustomerFile
} = getSubEntityApi(customerFileUrlTemplate, 'customerId', 'fileId', 'customerFileId', 'file')

const normalizeCustomerContacts = response => {
  const { _embedded, records } = response
  const { contacts } = _embedded || {}
  const customerContacts = records
  return {
    customerContacts,
    contacts
  }
}

export const fetchCustomerContacts = (customerId, params = {}) => {
  return get(customerContactFetchUrlTemplate.expand({ ...params, customerId }))
    .then(normalizeCustomerContacts)
}

export const deleteCustomer = (customer, actionData = {}) =>
  del(customerUrlTemplate.expand({ customerId: customer.id, ...actionData }))

export const fetchCustomerStats = ({ customerId }) =>
  get(customerSubItemUrlTemplate.expand({ customerId, subItem: 'stats' }))

export const fetchCustomerPrh = ({ name, businessId }) =>
  get(customerSubItemUrlTemplate.expand({ name, businessId, subItem: 'prh' }))
    .then(parseCustomerPrhFetchResponse)

const parseCustomerPrhFetchResponse = response => response
