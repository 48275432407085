import { dateFormats, endOfThis, formatDateOrNull, startOfThis } from '@evelia/helpers/dateHelpers'
import orderBy from 'lodash/orderBy'
import { createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'

import {
  getFilterItemsByFieldSelector,
  getFindItemByIdSelector,
  getMemoSelector,
  getTableIdsSelector
} from '../helpers/selectorHelpers'
import { findCurrentEmployee, getIsSupervisor } from './employeeSelectors'
import { findWorkRecordProductWithId } from './workRecordProductSelectors'

const getWorkRecordsFromArgument = arg => arg?.workRecords?.records ?? arg
export const getSalaryEventsFromArgument = arg => arg?.workRecords?.salaryEvents.records ?? arg
const sortWorkRecords = workRecords => orderBy(workRecords, ['isRunning', 'workRowId', 'startDateTime'], ['desc', 'desc', 'desc'])

export const getWorkRecords = createSelector(
  getWorkRecordsFromArgument,
  findCurrentEmployee,
  getIsSupervisor,
  (workRecords, employee, isSupervisor) => {
    return isSupervisor
      ? sortWorkRecords(workRecords)
      : sortWorkRecords(workRecords.filter(workRecord => workRecord.employeeId === employee.id))
  }
)

const getOwnWorkRecords = createSelector(
  getWorkRecordsFromArgument,
  findCurrentEmployee,
  (workRecords, employee) =>
    sortWorkRecords(workRecords.filter(workRecord => workRecord.employeeId === employee.id))
)

export const findWorkRecordWithId = getFindItemByIdSelector(getWorkRecords)

export const getUnapprovedWorkRecords = createSelector(
  getWorkRecords,
  workRecords => sortWorkRecords(workRecords.filter(workRecord => !workRecord.isSalaryEventsApproved))
)

export const getRunningWorkRecords = createSelector(
  getOwnWorkRecords,
  workRecords => workRecords.filter(workRecord => workRecord.isRunning)
)

export const getWorkRecordsByWorkId = createCachedSelector(
  getWorkRecords,
  (state, workId) => Number(workId),
  (state, workId, onlyUnInvoiced) => !!onlyUnInvoiced,
  (workRecords, workId, onlyUnInvoiced) => workRecords.filter(workRecord => workRecord.workId === workId && (!onlyUnInvoiced || !workRecord.workRowId))
)((state, workId, onlyUnInvoiced) => `${workId}_${onlyUnInvoiced}`)

export const getInvoicableWorkRecords = createSelector(
  (state, workRecords) => workRecords || getWorkRecords(state),
  state => state.workRecordProducts.records,
  (workRecords, workRecordProducts) => workRecords.filter(workRecord => {
    const workRecordProduct = findWorkRecordProductWithId(workRecordProducts, workRecord.workRecordProductId)
    return !workRecord.isRunning && !workRecord.workRowId && !workRecord.preventInvoicing && workRecordProduct && workRecordProduct.isBillable
  })
)

export const getRunningWorkRecordsByWorkRecordProductId = getFilterItemsByFieldSelector(getRunningWorkRecords, 'workRecordProductId', Number)
export const getSalaryEventsOfWorkRecord = getFilterItemsByFieldSelector(getSalaryEventsFromArgument, 'workRecordId', Number)
export const getSalaryEventsOfEmployee = getFilterItemsByFieldSelector(getSalaryEventsFromArgument, 'employeeId', Number)

export const findMemosByWorkRecordId = getMemoSelector('workRecords', 'workRecordId')

export const getSalaryEventsByTableIds = getTableIdsSelector('workRecords.salaryEvents', '_id')

export const getDefaultSalaryEventFilters = createCachedSelector(
  (state, tableIdentifier) => state.workRecords.salaryEvents.tableOptions[tableIdentifier],
  state => state.whoAmI.data.employeeId,
  () => formatDateOrNull(startOfThis('month'), dateFormats.isoDate),
  () => formatDateOrNull(endOfThis('month'), dateFormats.isoDate),
  (tableOptions, ownEmployeeId, startDate, endDate) => {
    const { employeeId, ...query } = tableOptions.filters
    return {
      employeeId: employeeId ?? ownEmployeeId,
      query: {
        ...query,
        startDate,
        endDate
      }
    }
  }
)((state, tableIdentifier) => `${tableIdentifier}`)
