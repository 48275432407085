import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DataTablePageNumberItem from '../DataTable/DataTablePageNumberItem'
import PaginatorInput from './PaginatorInput'

/**
 * page starts from zero
 */
type Props = {
  page: number
  limit: number
  totalCount: number
  pageCount: number
  pageSizeOptions?: number[]
  setPage: (page: number) => void
  setLimit: (limit: number) => void
  isVirtualTotalCount: boolean
}

const defaultPageSizeOptions = [10, 25, 50, 100]

const Paginator = ({
  page,
  limit,
  totalCount,
  pageCount,
  pageSizeOptions = defaultPageSizeOptions,
  setPage,
  setLimit,
  isVirtualTotalCount
}: Props) => {
  const goToPrevPage = () => setPage(Math.max(page - 1, 0))
  const goToNextPage = () => setPage(page + 1)

  const firstResult = page * limit + 1
  const lastResult = Math.min(page * limit + limit, totalCount)
  const paginationSummary = totalCount === 0 ? '' : `Näytetään ${firstResult}-${lastResult}${isVirtualTotalCount ? '' : `/ ${totalCount}`}`

  return (
    <div className='d-flex align-items-center justify-content-between mt-3 mb-3'>
      <div className='d-flex align-items-center'>
        <nav>
          <ul className='pagination m-0'>
            {pageSizeOptions.map(pageSize => (
              <li
                key={pageSize}
                className={`page-item cursor-pointer ${limit === pageSize ? 'active' : ''}`}
                title={`Näytä sivulla ${pageSize} riviä`}
              >
                <button
                  className='page-link'
                  onClick={() => setLimit(pageSize)}
                >
                  {pageSize}
                </button>
              </li>
            ))}
          </ul>
        </nav>
        <div className='text-muted ms-4 small text-nowrap'>
          {paginationSummary}
        </div>
      </div>
      <div className='d-flex align-items-center gap-4'>
        <div className='d-flex align-items-center'>
          <div className='ms-5 me-2 small text-muted text-nowrap'>
            Siirry sivulle
          </div>
          <div style={{ width: '3rem' }}>
            <PaginatorInput page={page} pageCount={pageCount} setPage={setPage} />
          </div>
        </div>
        <nav>
          <ul className='pagination mb-0'>
            <li className={`page-item ${page === 0 ? 'disabled' : 'cursor-pointer'}`}>
              <button className='page-link' onClick={goToPrevPage}>
                <FontAwesomeIcon icon={faAngleLeft} className='small' />
              </button>
            </li>
            {[...Array(pageCount)].map((_, i) => {
              return (
                <DataTablePageNumberItem
                  key={i}
                  index={i}
                  page={page}
                  pageCount={pageCount}
                  setPage={setPage}
                />
              )
            })}
            <li className={`page-item ${(!totalCount || page === pageCount - 1) ? 'disabled' : 'cursor-pointer'}`}>
              <button className='page-link' onClick={goToNextPage}>
                <FontAwesomeIcon icon={faAngleRight} className='small' />
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default Paginator
