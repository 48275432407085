import { parseTemplate } from 'url-template'

import { del, get, post, put } from '../helpers/apiHelpers'

const baseUrl = '/api/employee_licence_types/{employeeLicenceTypeId}'
const employeeLicenceTypeUrlTemplate = parseTemplate(baseUrl)

export const fetchEmployeeLicenceTypes = (params = {}) => {
  return get(employeeLicenceTypeUrlTemplate.expand(params))
    .then(parseEmployeeLicenceTypeFetchResponse)
}

export const updateEmployeeLicenceType = employeeLicenceType => {
  return put(employeeLicenceTypeUrlTemplate.expand({ employeeLicenceTypeId: employeeLicenceType.id }), {
    body: JSON.stringify(employeeLicenceType)
  })
}

export const createEmployeeLicenceType = employeeLicenceType => {
  return post(employeeLicenceTypeUrlTemplate.expand({}), {
    body: JSON.stringify(employeeLicenceType)
  })
}

export const deleteEmployeeLicenceType = employeeLicenceType => {
  return del(employeeLicenceTypeUrlTemplate.expand({ employeeLicenceTypeId: employeeLicenceType.id }))
}

const parseEmployeeLicenceTypeFetchResponse = response => response
