import { createRef, PureComponent } from 'react'
import { Input } from 'reactstrap'

import { keyCodes } from '../../../constants'
import { checkIfEnter } from '../../../helpers/helpers'
import { commonFormInputDefaultProps, commonFormInputPropTypes } from '../propTypes'

const setSelectionRangeCapableTypes = ['text', 'textarea', 'search', 'password', 'tel', 'url']

class TextFormInput extends PureComponent {
  state = {
    lastSelectionStart: null,
    lastSelectionEnd: null
  }

  inputRef = createRef()
  componentDidMount = () => {
    if(this.props.autoFocus && this.inputRef.current) {
      this.inputRef.current.focus()
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    // Retain cursor position when model updates
    if(prevProps.value !== this.props.value) {
      const { lastSelectionStart, lastSelectionEnd } = this.state
      setSelectionRangeCapableTypes.includes(this.inputRef.current.type) && this.inputRef.current.setSelectionRange(lastSelectionStart, lastSelectionEnd || lastSelectionStart)
    }
  }

  render() {
    const {
      field,
      value,
      type = 'text',
      usePlaceholder,
      placeholder,
      label,
      step,
      rows,
      disabled,
      disableAll,
      readOnly,
      isInvalid,
      inputId,
      onKeyDown,
      testId,
      className,
      size
    } = this.props

    return (
      <Input
        className={className}
        invalid={isInvalid}
        type={type}
        placeholder={usePlaceholder ? label : (placeholder || null)}
        step={step}
        rows={rows}
        name={field}
        id={inputId}
        data-testid={testId || field}
        value={value || ''}
        onChange={this.handleOnChange}
        disabled={disabled || disableAll || readOnly}
        readOnly={readOnly}
        innerRef={this.inputRef}
        onKeyUp={this.handleKeyUp}
        onKeyDown={onKeyDown}
        onBlur={this.onBlur}
        bsSize={size}
      />
    )
  }

  handleOnChange = event => {
    const { onChange } = this.props
    const value = event.target.value

    this.setState({ lastSelectionStart: this.inputRef.current.selectionStart, lastSelectionEnd: this.inputRef.current.selectionEnd })
    return onChange(value, event)
  }

  handleKeyUp = event => {
    if(event.keyCode === keyCodes.ESC) {
      return this.props.onEscPressed(event)
    } else if(checkIfEnter(event)) {
      return this.props.onEnterPressed(event)
    }
    return event
  }

  onBlur = event => {
    this.props.onBlur && this.props.onBlur(event)
  }

  static propTypes = commonFormInputPropTypes
  static defaultProps = commonFormInputDefaultProps
}

export default TextFormInput
