import { actionKeys } from '../constants'
import { actionCreatorsFor } from '../helpers/actionHelpers'
import tableActionsFor from './tableActions'

const baseActionCreators = actionCreatorsFor(actionKeys.COST_CENTRES)

const additionalActionCreators = {
  costCentreGroups: actionCreatorsFor(actionKeys.COST_CENTRE_GROUPS),
  tableActions: tableActionsFor(actionKeys.COST_CENTRE_TABLE_OPTIONS)
}

const costCentreActions = { ...baseActionCreators, ...additionalActionCreators }
export default costCentreActions
