import { connect } from 'react-redux'

const TableCell = ({ renderer, result, value, row }) =>
  renderer ? renderer(result, value, row) : ''

const mapStateToProps = (state, ownProps) => {
  const value = ownProps.getValue ? ownProps.getValue(ownProps.row) : ownProps.value
  return {
    result: ownProps.selector && value ? ownProps.selector(state, value) : null
  }
}

export default connect(mapStateToProps)(TableCell)
