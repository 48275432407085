import { sortById } from '@evelia/helpers/helpers'
import sortedUniqBy from 'lodash/sortedUniqBy'
import memoize from 'micro-memoize'
import { createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'

import { vatTypes } from '../constants'
import { getCastedArray, getFindItemByIdSelector } from '../helpers/selectorHelpers'

export const getAccountsFromArguments = arg => arg.accounts ? arg.accounts.records : arg
const getDefaultAccountsFromArguments = arg => arg.accounts?.defaultAccounts ? arg.accounts?.defaultAccounts.records : arg

export const findAccountWithId = getFindItemByIdSelector(getAccountsFromArguments)

export const getSalesPaymentAccounts = createSelector(
  getAccountsFromArguments,
  (state, { rejectedAccounts }) => rejectedAccounts,
  (accounts, rejectedAccounts) => accounts.filter(account => !rejectedAccounts.includes(account.accountNumber) && account.isSalesPaymentAccount)
)

const getAccountTypes = memoize(accountTypes => Array.isArray(accountTypes) ? getCastedArray(accountTypes, Number) : [Number(accountTypes)])

export const filterAccountsByType = createCachedSelector(
  getAccountsFromArguments,
  (state, accountTypes) => getAccountTypes(accountTypes),
  (accounts, accountTypes) => accounts.filter(account => !account.disabled && accountTypes.includes(account.accountType))
)((state, accountTypes) => Array.isArray(accountTypes) ? accountTypes.join(';') : accountTypes)

export const getCombinedAccounts = createSelector(
  getAccountsFromArguments,
  getDefaultAccountsFromArguments,
  (accounts, defaultAccounts) => {
    // Values are sorted so that defaultAccount values always comes second
    // sortedUniqBy removes second instances aka defaultAccounts
    const combinedArray = [...accounts, ...defaultAccounts].sort(sortById)
    return sortedUniqBy(combinedArray, 'id')
  }
)

export const getAccountNumberByVatType = createCachedSelector(
  getDefaultAccountsFromArguments,
  (__state, vatType) => vatType,
  (defaultAccounts, vatType) => {
    switch(vatType) {
      case vatTypes.euSales:
        return defaultAccounts.defaultEuSalesAccount
      case vatTypes.reversedVat:
      default:
        return null
    }
  }
)((__state, vatType) => `${vatType}`)
