import { all, takeEvery, takeLeading } from 'redux-saga/effects'

import ticketStateActions from '../actions/ticketStateActions'
import { ticketStateApi } from '../api/ticketStateApi'
import ticketStateTitles, { ticketStateTitlesCapitalized } from '../components/Tickets/ticketStateTitles'
import {
  createFlow,
  createSocketWatcher,
  deleteFlow,
  fetchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

const titles = ticketStateTitles
const titlesCapitalized = ticketStateTitlesCapitalized
const reduxName = 'ticketStates'

const watchOnTicketStateSockets = createSocketWatcher('ticketState', {
  created: ticketStateActions.createSuccess,
  updated: ticketStateActions.updateSuccess,
  deleted: ticketStateActions.deleteSuccess
})

const ticketStateFetchFlow = fetchFlow({
  fetchApi: ticketStateApi.fetch,
  actions: ticketStateActions,
  base: reduxName,
  idField: 'id',
  errorMsg: titles.genetive
})

const ticketStateUpdateFlow = updateFlow(ticketStateApi.update, ticketStateActions, titlesCapitalized.basic, titlesCapitalized.genetive)
const ticketStateCreateFlow = createFlow(ticketStateApi.create, ticketStateActions, titlesCapitalized.basic, titlesCapitalized.genetive)
const ticketStateDeleteFlow = deleteFlow({
  deleteApi: ticketStateApi.remove,
  actions: ticketStateActions,
  singular: titlesCapitalized.basic,
  errorMsg: titlesCapitalized.genetive,
  base: reduxName
})

export default function* ticketStateSaga() {
  yield takeLeading(ticketStateActions.actionTypes.fetchRequest, ticketStateFetchFlow)
  yield takeEvery(ticketStateActions.actionTypes.updateRequest, ticketStateUpdateFlow)
  yield takeEvery(ticketStateActions.actionTypes.createRequest, ticketStateCreateFlow)
  yield takeEvery(ticketStateActions.actionTypes.deleteRequest, ticketStateDeleteFlow)
  yield all([
    watchOnTicketStateSockets()
  ])
}
