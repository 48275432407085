import { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import { flushSync } from 'react-dom'
import { Prompt } from 'react-router-dom'
import { Form as ReactStrapForm } from 'reactstrap'
import merge from 'lodash/merge'
import noop from 'lodash/noop'
import setWith from 'lodash/setWith'
import memoize from 'micro-memoize'

import { handleSubmit } from '../../helpers/formHelpers'
import { preventEnter } from '../../helpers/helpers'
import ClickOutsideHandler from '../ClickOutsideHandler'
import FormButtonContainer from './FormButtonContainer'

class Form extends Component {
  constructor(props) {
    super(props)
    this.state = {
      model: { ...props.initialModel, ...props.model },
      validationErrors: {},
      error: null,
      isDirty: false,
      isSaving: false
    }
    this.changePromise = new Promise(resolve => {
      this.changePromiseResolve = resolve // to resolve only after initial mount
    })
    this.isFormMounted = createRef()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if(
      (!prevState?.model?.id && nextProps?.model?.id) ||
      (prevState?.model?.id !== nextProps?.model?.id) ||
      (prevState?.model?._isEditable !== nextProps?.model?._isEditable)
    ) {
      return { model: nextProps.model }
    }
    return null
  }

  handleChange = (event, data, skipDirty, { field, value } = {}) => {
    if(this.props.onHandleChange) {
      data = this.props.onHandleChange(structuredClone(data), this.state.model)
    }
    this.changePromise = this.changePromise.then(() => {
      return new Promise((resolve, reject) => {
        let model = structuredClone(this.state.model)
        const dataKeys = Object.keys(data)
        if(dataKeys.length === 1 && field && value) {
          model = setWith(model, field, value, this.props.arraysOnFieldNames ? () => undefined : Object)
        } else {
          model = merge(model, data)
        }
        const newState = { model }
        if(!skipDirty) {
          newState.isDirty = true
        }
        if(this.isFormMounted.current) {
          return flushSync(() => this.setState(newState, () => resolve(newState.model)))
        }
        resolve(newState.model)
      }).then(model => {
        if(this.props.notifyChange) {
          this.props.notifyChange(data, model)
        }
        return model
      })
    })
    return this.changePromise
  }

  handleSubmit = (event, additionalSaveData) => {
    const { saveFunction } = this.props
    return saveFunction ? handleSubmit.call(this, event, saveFunction, additionalSaveData) : Promise.resolve()
  }

  handleKeyDown = event => {
    const { preventSubmitOnEnter } = this.props
    if(preventSubmitOnEnter && event.target.localName.toLowerCase() !== 'textarea') {
      preventEnter(event)
    }
  }

  changeField = (fieldName, value, skipIsDirty = false) => {
    return this.handleChange(null, setWith({}, fieldName, value, this.props.arraysOnFieldNames ? () => undefined : Object), skipIsDirty, { field: fieldName, value })
  }

  onReset = () => new Promise(resolve =>
    this.setState({ model: this.props.initialModel || {} }, () => resolve(this.state.model))
  )

  DirtyPrompt = () => (
    <Prompt
      when={this.state.isDirty && !this.props.disablePrompt && this.isFormMounted.current}
      message='Sinulla on tallentamattomia muutoksia. Oletko varma että haluat poistua sivulta?'
    />
  )

  getDefaultPropsObject = memoize((validationErrors, error, model, arraysOnFieldNames, disabled, isDirty, idPrefix) => {
    return {
      validationErrors,
      error,
      model,
      onChange: this.handleChange,
      updateField: this.changeField,
      setWithCustomizer: arraysOnFieldNames ? () => undefined : Object,
      disableAll: disabled,
      isDirty,
      onReset: this.onReset,
      idPrefix
    }
  })

  render() {
    const {
      disabled,
      getForm,
      children,
      idPrefix,
      isRow,
      extraProps,
      manualButtons,
      className,
      arraysOnFieldNames,
      testId
    } = this.props
    const { validationErrors, error, model, isDirty } = this.state
    const defaultProps = this.getDefaultPropsObject(
      validationErrors,
      error,
      model,
      arraysOnFieldNames,
      disabled,
      isDirty,
      idPrefix
    )

    const formFunc = getForm || children
    const formFuncOptions = {
      defaultProps,
      updateField: this.changeField,
      model,
      extraProps,
      renderButtons: this.renderButtons
    }
    if(isRow) {
      const {
        onClick,
        onClickAway,
        innerRef,
        stickyTop,
        stickyBottom,
        bgColor
      } = this.props
      const formClassName = ['d-flex', stickyTop ? 'sticky-top' : null, stickyBottom ? 'sticky-bottom' : null, bgColor ? `bg-${bgColor}` : null, className].filter(Boolean).join(' ')
      return (
        <ClickOutsideHandler innerRef={innerRef} onClickAway={onClickAway || this.onBlur} mouseEvent='mouseup'>
          <tr
            className={formClassName}
            style={{ top: stickyTop || undefined, bottom: stickyBottom || undefined }}
            onClick={onClick}
            ref={innerRef}
            onBlur={this.onBlur}
          >
            <this.DirtyPrompt />
            {children.call(this, formFuncOptions)}
          </tr>
        </ClickOutsideHandler>
      )
    }
    return (
      <ReactStrapForm
        className={className || null}
        onSubmit={this.handleSubmit}
        onBlur={this.handleOnBlur}
        onKeyDown={this.handleKeyDown}
        data-testid={testId}
      >
        <this.DirtyPrompt />
        {formFunc.call(this, formFuncOptions)}
        {!manualButtons && this.renderButtons()}
      </ReactStrapForm>
    )
  }

  onBlur = event => this.props.onBlur(event, this.state.model)

  renderButtons = () => {
    const { renderButtons, disabled, noButtonBorder, noButtonContainer } = this.props
    if(renderButtons) {
      const { isDirty, isSaving, model } = this.state
      return (
        <FormButtonContainer noBorder={noButtonBorder} noContainer={noButtonContainer}>
          {renderButtons({ handleSubmit: this.handleSubmit, isSaving, isDirty, model, disabled, onReset: this.onReset })}
        </FormButtonContainer>
      )
    }
  }

  handleOnBlur = event => {
    if(this.props.clearOnBlur) {
      this.setState({ isDirty: false })
    }
    this.props.onBlur(event)
  }

  getModel = () => ({ ...this.state.model })

  componentDidMount = () => {
    this.isFormMounted.current = true
    this.changePromiseResolve()
  }

  componentWillUnmount = () => {
    this.isFormMounted.current = false
  }

  static propTypes = {
    model: PropTypes.object.isRequired,
    initialModel: PropTypes.object,
    saveFunction: PropTypes.func,
    arraysOnFieldNames: PropTypes.bool,
    disabled: PropTypes.bool,
    children: PropTypes.func,
    getForm: PropTypes.func,
    onBlur: PropTypes.func,
    clearOnBlur: PropTypes.bool,
    disablePrompt: PropTypes.bool,
    notifyChange: PropTypes.func,
    onHandleChange: PropTypes.func,
    idPrefix: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    renderButtons: PropTypes.func,
    isRow: PropTypes.bool,
    onClick: PropTypes.func,
    onClickAway: PropTypes.func,
    innerRef: PropTypes.object,
    stickyTop: PropTypes.string,
    stickyBottom: PropTypes.string,
    bgColor: PropTypes.string,
    extraProps: PropTypes.any,
    preventSubmitOnEnter: PropTypes.bool,
    manualButtons: PropTypes.bool,
    className: PropTypes.string,
    noButtonBorder: PropTypes.bool,
    noButtonContainer: PropTypes.bool,
    testId: PropTypes.string
  }

  static defaultProps = {
    initialModel: {},
    arraysOnFieldNames: false,
    disabled: false,
    onBlur: noop,
    disablePrompt: false,
    notifyChange: noop,
    manualButtons: false
  }
}

export const formPropTypes = {
  defaultProps: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
  extraProps: PropTypes.object,
  renderButtons: PropTypes.func
}

export default Form
