import { useSelector } from 'react-redux'

import { getSystemMessages } from '../../selectors/systemMessageSelectors'
import Loading from '../Loading/Loading'
import SystemMessageNotificationRow from './SystemMessageNotificationRow'

const renderSystemMessage = systemMessage => (
  <SystemMessageNotificationRow key={systemMessage.id} systemMessage={systemMessage} />
)
const SystemMessageNotifications = () => {
  const systemMessages = useSelector(getSystemMessages)
  const isBusy = useSelector(state => state.systemMessages.metadata.busy)

  return (
    <>
      <h2 className='h5'>Tiedotteet ja viestit</h2>
      <hr className='m-0' />
      <Loading loading={isBusy}>
        {systemMessages.length
          ? (
            <div className='overflow-auto-y'>
              <div className='text-muted text-end small px-2'>Merkitse luetuksi</div>
              <ul className='list-unstyled text-start wrap mw-100 m-1'>
                {systemMessages.map(renderSystemMessage)}
              </ul>
            </div>
            )
          : (
            <>
              <div className='text-muted my-3 mx-1'>
                Ei uusia tiedotteita
              </div>
              <hr className='m-0' />
            </>
            )}
      </Loading>
    </>
  )
}

export default SystemMessageNotifications
