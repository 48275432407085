import PropTypes from 'prop-types'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { callFunctionOrContent } from '../../helpers/helpers'

const ModalBase = ({
  title,
  isOpen,
  onCancel,
  renderBody,
  renderFooter,
  modalProps
}) => {
  return (
    <Modal isOpen={isOpen} toggle={onCancel} onClick={ev => ev.stopPropagation() /* Stop propagation, so form submit with enter don't propagate to parent */} {...modalProps}>
      <ModalHeader className='modal-header' cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={onCancel}><span className='modal-title'>{title}</span></ModalHeader>
      <ModalBody>
        {callFunctionOrContent(renderBody)}
      </ModalBody>
      {renderFooter && (
        <ModalFooter>
          {callFunctionOrContent(renderFooter)}
        </ModalFooter>
      )}
    </Modal>
  )
}

ModalBase.propTypes = {
  title: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  renderBody: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  renderFooter: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  modalProps: PropTypes.shape({
    unmountOnClose: PropTypes.bool,
    backdrop: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),
    wrapClassName: PropTypes.string,
    modalClassName: PropTypes.string,
    contentClassName: PropTypes.string,
    size: PropTypes.string,
    'data-testid': PropTypes.string
  })
}

export default ModalBase
