import {
  all,
  put,
  takeEvery,
  takeLatest,
  takeLeading
} from 'redux-saga/effects'

import accountActions from '../actions/accountActions'
import {
  accountApi,
  createAccountsBulk,
  fetchDefaultAccounts,
  updateAccountsBulk
} from '../api/accountApi'
import {
  actionFlow,
  createFlow,
  createSocketWatcher,
  fetchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

const watchOnAccountSockets = createSocketWatcher('account', {
  created: accountActions.createSuccess,
  updated: accountActions.updateSuccess,
  createdBulk: accountActions.fetchSuccess
})

const accountFetchFlow = fetchFlow({
  fetchApi: accountApi.fetch,
  actions: accountActions,
  errorMsg: 'tilikartan',
  base: 'accounts',
  updateLastFetched: true
})

const defaultAccountFetchFlow = fetchFlow({
  fetchApi: fetchDefaultAccounts,
  actions: accountActions.defaultAccounts,
  errorMsg: 'oletustilien',
  base: 'accounts.defaultAccounts',
  updateLastFetched: true
})

const accountUpdateFlow = updateFlow(accountApi.update, accountActions, 'Tili', 'Tilin')
const accountCreateFlow = createFlow(accountApi.create, accountActions, 'Tili', 'Tilin')

function* handleBulkSave(data) {
  yield put(accountActions.fetchSuccess(data))
}

const accountCreateBulkFlow = actionFlow({
  apiFunction: createAccountsBulk,
  apiResponseHandler: handleBulkSave,
  successMsg: 'Tilit lisätty tilikarttaan',
  errorMsg: 'Virhe tilien luonnissa'
})
const accountUpdateBulkFlow = actionFlow({
  apiFunction: updateAccountsBulk,
  apiResponseHandler: handleBulkSave,
  successMsg: 'Tilit päivitetty tilikarttaan',
  errorMsg: 'Virhe tilien päivittämisessä'
})

export default function* accountSaga() {
  yield takeLatest(accountActions.actionTypes.fetchRequest, accountFetchFlow)
  yield takeEvery(accountActions.actionTypes.updateRequest, accountUpdateFlow)
  yield takeEvery(accountActions.actionTypes.createRequest, accountCreateFlow)
  yield takeLeading(accountActions.actionTypes.createBulkRequest, accountCreateBulkFlow)
  yield takeLeading(accountActions.actionTypes.updateBulkRequest, accountUpdateBulkFlow)

  yield takeLatest(accountActions.defaultAccounts.actionTypes.fetchRequest, defaultAccountFetchFlow)

  yield all([
    watchOnAccountSockets()
  ])
}
