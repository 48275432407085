import { actionTypes } from '../constants'

const initialState = {
  busy: true
}

const configReducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.CONFIGS_FETCH_START:
      return {
        ...state,
        busy: true
      }
    case actionTypes.CONFIGS_FETCH_SUCCESS:
      return {
        ...action.record,
        busy: false
      }
    case actionTypes.CONFIGS_FETCH_ERROR:
      return {
        busy: false
      }
    default:
      return state
  }
}

export default configReducer
