import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'

class PlainTextFormInput extends PureComponent {
  render() {
    const { value, plainTextValueFormatter, testId, id } = this.props
    let currentValue = value || ''
    if(plainTextValueFormatter) {
      currentValue = plainTextValueFormatter(currentValue)
    }

    return (
      <Input
        plaintext
        readOnly
        value={currentValue}
        data-testid={testId}
        id={id}
      />
    )
  }

  static propTypes = {
    value: PropTypes.any,
    plainTextValueFormatter: PropTypes.func,
    testId: PropTypes.string,
    id: PropTypes.string
  }
}

export default PlainTextFormInput
