import { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { formatThousandsSeparatedBySpaces } from '@evelia/helpers/helpers'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import projectActions from '../../actions/projectActions'
import { FormInputGroup, NumberFormInput, PlainTextFormInput } from '../../components/FormGroups'
import ButtonFormModal from '../../components/Modals/ButtonFormModal'
import ProjectExtraBudgetsTable from '../../components/Projects/ProjectExtraBudgetsTable'
import { Subtitle } from '../../components/Typography'
import { projectExtraExpenseTypes } from '../../constants'
import { promisifyAction } from '../../helpers/dispatchHelpers'
import { formatDiff, formatWithUnitMemoized } from '../../helpers/helpers'
import useActions from '../../hooks/useActions'
import { formDefaultPropsPropType, projectPropType, shapeOrEmpty } from '../../propTypes'
import { getProjectExtraBudgetSumByType } from '../../selectors/projectSelectors'
import ProjectExtraBudgetForm from './ProjectExtraBudgetForm'

export const projectBudgetFieldMap = {
  [projectExtraExpenseTypes.INCOME]: {
    label: 'Myyntiarvio',
    field: 'estimatedSales',
    totalSumField: 'totalInvoicePriceSum',
    isVatless: true,
    unit: '€'
  },
  [projectExtraExpenseTypes.EXPENSE]: {
    label: 'Materiaalikustannusarvio',
    field: 'estimatedCost',
    totalSumField: 'totalExpensesSum',
    isVatless: true,
    unit: '€'
  },
  [projectExtraExpenseTypes.HOUR]: {
    label: 'Tuntiarvio',
    field: 'estimatedHours',
    totalSumField: 'totalBasicHours',
    isVatless: false,
    unit: 'h'
  },
  [projectExtraExpenseTypes.SALARY]: {
    label: 'Palkkakustannusarvio',
    field: 'estimatedSalaryPrices',
    totalSumField: 'totalWorkRecordExpensesSum',
    isVatless: false,
    unit: '€'
  },
  [projectExtraExpenseTypes.SUBCONTRACTING]: {
    label: 'Alihankinta-arvio',
    field: 'estimatedSubcontracting',
    totalSumField: 'totalSubcontractingSum',
    isVatless: true,
    unit: '€'
  }
}

export const getDenominationFieldData = type => {
  const fieldData = projectBudgetFieldMap[type]
  const unit = fieldData.unit
  const valueFormatter = formatWithUnitMemoized(unit, formatThousandsSeparatedBySpaces)
  const diffValueFormatter = formatDiff(valueFormatter)
  return {
    fieldData,
    unit,
    valueFormatter,
    diffValueFormatter
  }
}

export const useDenominationFieldData = type => {
  return useMemo(() => getDenominationFieldData(type), [type])
}

const componentActions = {
  createExtraBudget: projectActions.projectExtraBudgets.createRequest
}

const ProjectBudgetInput = ({ model, type, defaultProps }) => {
  const {
    fieldData,
    unit,
    valueFormatter,
    diffValueFormatter
  } = useDenominationFieldData(type)

  const extraSum = useSelector(state => getProjectExtraBudgetSumByType(state, { projectId: model.id, projectExtraExpenseType: type }))
  const originalValue = model[fieldData.field]
  const currentValue = originalValue + extraSum

  const actions = useActions(componentActions)
  const formProps = useMemo(() => ({ initialModel: { type }, extraProps: { unit, currentValue, extraSum, valueFormatter, diffValueFormatter } }), [currentValue, diffValueFormatter, extraSum, type, unit, valueFormatter])

  const createExtraBudget = useCallback(data =>
    promisifyAction(data, actions.createExtraBudget, {
      projectId: model.id
    })
  , [actions.createExtraBudget, model.id])

  const PlainInput = useCallback(() => <PlainTextFormInput value={formatThousandsSeparatedBySpaces(currentValue)} testId={fieldData.field} />, [fieldData.field, currentValue])

  const getButtonFormModal = useCallback(() => (
    <ButtonFormModal
      model={model}
      headerTitle={`Muokkaa ${fieldData.label.toLowerCase()}ta`}
      noButtonText
      FormComponent={ProjectExtraBudgetForm}
      onSubmit={createExtraBudget}
      buttonIcon={faEdit}
      buttonSize='md'
      buttonColor='link'
      formProps={formProps}
      modalSize='lg'
      testId={`edit_${fieldData.field}`}
      appendContent={(
        <>
          <Subtitle>Aiemmat lisäbudjetit</Subtitle>
          <ProjectExtraBudgetsTable projectId={model.id} type={type} />
        </>
      )}
    />
  ), [createExtraBudget, fieldData.field, fieldData.label, formProps, model, type])

  const isNew = !model.id
  if(isNew) {
    return (
      <FormInputGroup
        field={fieldData.field}
        label={fieldData.label}
        labelTooltip={fieldData.isVatless ? 'Veroton' : null}
        appendComponent={unit}
        inputComponent={NumberFormInput}
        setNull
        isRequired
        {...defaultProps}
      />
    )
  } else {
    return (
      <FormInputGroup
        field={fieldData.field}
        label={fieldData.label}
        value={currentValue}
        labelTooltip={fieldData.isVatless ? 'Veroton' : null}
        prependComponent={getButtonFormModal}
        appendComponent={unit}
        inputComponent={PlainInput}
        helpText={<i>Alkuperäinen: {valueFormatter(originalValue)} ({diffValueFormatter(extraSum)})</i>}
        {...defaultProps}
      />
    )
  }
}

ProjectBudgetInput.propTypes = {
  model: shapeOrEmpty(projectPropType).isRequired,
  type: PropTypes.string.isRequired,
  defaultProps: formDefaultPropsPropType.isRequired
}

export default ProjectBudgetInput
