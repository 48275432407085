import { parseTemplate } from 'url-template'

import { del, get, post, put } from '../helpers/apiHelpers'

const baseUrl = '/api/product_groups/{productGroupId}'
const productGroupUrlTemplate = parseTemplate(baseUrl)

export const fetchProductGroups = (params = {}) => {
  return get(productGroupUrlTemplate.expand(params))
    .then(parseproductGroupFetchResponse)
}

export const updateProductGroup = productGroup => {
  return put(productGroupUrlTemplate.expand({ productGroupId: productGroup.id }), {
    body: JSON.stringify(productGroup)
  })
}

export const createProductGroup = productGroup => {
  return post(productGroupUrlTemplate.expand({}), {
    body: JSON.stringify(productGroup)
  })
}

export const deleteProductGroup = productGroup => {
  return del(productGroupUrlTemplate.expand({ productGroupId: productGroup.id }))
}

const parseproductGroupFetchResponse = response => response
