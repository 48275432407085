import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons'
import memoize from 'micro-memoize'

import CancelButton from '../Buttons/CancelButton'
import SaveButton from '../Buttons/SaveButton'
import ToLastPathButton from '../Buttons/ToLastPathButton'

// eslint-disable-next-line react/prop-types
export const renderSingleSaveButton = memoize((content, busyContent, props) => ({ isSaving, disabled, condensed }) => (
  <SaveButton
    {...props}
    isBusy={isSaving}
    busyContent={busyContent}
    disabled={disabled}
    condensed={condensed}
  >{content}
  </SaveButton>
))

export const renderSaveButton = renderSingleSaveButton('Tallenna', 'Tallennetaan...')
export const renderSaveButtonSm = renderSingleSaveButton('Tallenna', 'Tallennetaan...', { size: 'sm' })

export const renderFilterButton = renderSingleSaveButton('Suodata', 'Suodatetaan...')

export const renderSendButton = renderSingleSaveButton('Lähetä', 'Lähetetään...')

export const renderSearchButton = renderSingleSaveButton('Hae', 'Haetaan...')

// eslint-disable-next-line react/prop-types
export const renderSaveCancelButtons = (onCancel, { content = 'Tallenna', busyContent = 'Tallennetaan' } = {}) => ({ isSaving, disabled, condensed = false }) => (
  <>
    <SaveButton isBusy={isSaving} busyContent={busyContent} disabled={disabled} condensed={condensed}>{content}</SaveButton>
    <CancelButton disabled={isSaving} onClick={onCancel}>Peruuta</CancelButton>
  </>
)

// eslint-disable-next-line react/prop-types
export const renderSaveAndReturnButtons = memoize((to, showReturnButton) => ({ handleSubmit, isSaving, disabled, condensed }) => (
  <div className='d-flex gap-2 mt-4'>
    <SaveButton isBusy={isSaving} disabled={disabled} condensed={condensed}>Tallenna</SaveButton>
    {showReturnButton && (
      <ToLastPathButton
        onClick={handleSubmit}
        to={to}
        outline
        icon={faAngleDoubleLeft}
        disabled={disabled}
        condensed
        isBusy={isSaving}
      >
        Tallenna ja palaa
      </ToLastPathButton>
    )}
  </div>
))
