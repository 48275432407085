import {
  createEntityAdapter
} from '@reduxjs/toolkit'

import { TaxDeductionCardModel } from '../api/wageEarnerApi'
import { createGenericSlice } from '../helpers/sliceHelpers'
import { EveliaRootState } from '../reducerTypes'

const taxDeductionCardAdapter = createEntityAdapter<TaxDeductionCardModel>({})

const taxDeductionCardSlice = createGenericSlice({
  name: 'taxDeductionCards',
  entityAdapter: taxDeductionCardAdapter
})

export const taxDeductionCardEntitySelectors = taxDeductionCardAdapter.getSelectors<EveliaRootState>(state => state.wageEarners.taxDeductionCards)
export const taxDeductionCardActions = taxDeductionCardSlice.actions

export default taxDeductionCardSlice
