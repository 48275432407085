import { useEffect, useState } from 'react'

// From https://typeofnan.dev/using-session-storage-in-react-with-hooks/

export const getSessionValueOrDefault = (key, defaultValue) => {
  const stored = sessionStorage.getItem(key)
  if(!stored) {
    return defaultValue
  }
  return JSON.parse(stored)
}

export const useKeepInSessionStorage = (key, value) => {
  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value))
  }, [key, value])
}

export const useSessionStorage = (key, defaultValue) => {
  const [value, setValue] = useState(
    getSessionValueOrDefault(key, defaultValue)
  )
  useKeepInSessionStorage(key, value)
  return [value, setValue]
}
