import constants, { actionTypes } from '../constants'
import { actionCreatorsFor, request } from '../helpers/actionHelpers'
import { PATH_PRODUCTS_PACKETS } from '../routes'
import { navigateTo } from './navigateActions'
import tableActions from './tableActions'

export const getUrl = packetId => `${PATH_PRODUCTS_PACKETS}/${packetId || 'new'}`

const baseActionCreators = actionCreatorsFor(constants.actionKeys.PACKETS)

const additionalActionCreators = {
  installations: actionCreatorsFor(constants.actionKeys.INSTALLATIONS),
  tableActions: tableActions(constants.actionKeys.PACKETS),
  packetInstallations: actionCreatorsFor(constants.actionKeys.PACKET_INSTALLATIONS, { fetchContent: true }),
  packetInstallationPackets: actionCreatorsFor(constants.actionKeys.PACKET_INSTALLATION_PACKETS),
  packetInstallationTasks: actionCreatorsFor(constants.actionKeys.PACKET_INSTALLATION_TASKS),
  packetInstallationProducts: actionCreatorsFor(constants.actionKeys.PACKET_INSTALLATION_PRODUCTS),
  authenticateStulRequest: request(actionTypes.AUTHENTICATE_STUL_REQUEST),
  navigateTo: (packetId, replaceUrl) => navigateTo(getUrl(packetId), replaceUrl),
  navigateToBase: replaceUrl => navigateTo(PATH_PRODUCTS_PACKETS, replaceUrl),
  packetGroups: actionCreatorsFor(constants.actionKeys.PACKET_GROUPS)
}

const packetActions = { ...baseActionCreators, ...additionalActionCreators }
export default packetActions
