import { actionTypes } from '../constants'
import { error, request, start, success } from '../helpers/actionHelpers'

const paymentActions = {
  createRequest: request(actionTypes.PAYMENT_CREATE_REQUEST),
  createStart: start(actionTypes.PAYMENT_CREATE_START),
  createSuccess: success(actionTypes.PAYMENT_CREATE_SUCCESS),
  createError: error(actionTypes.PAYMENT_CREATE_ERROR)
}

export default paymentActions
