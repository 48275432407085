import PropTypes from 'prop-types'
import { useController } from 'react-hook-form'

import FormPlainCheckboxInput from './FormPlainCheckboxInput'

const FormCheckboxInput = ({ control, errors, ...props }) => {
  const { field, formState } = useController({ name: props.name, control })
  const { value, onChange, ...fieldProps } = field
  const inputErrors = formState.errors
  return (
    <FormPlainCheckboxInput
      {...props}
      value={value}
      errors={errors || inputErrors}
      onChange={onChange}
      inputProps={{
        ...props.inputProps,
        ...fieldProps
      }}
    />
  )
}

FormCheckboxInput.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  label: PropTypes.node,
  inputProps: PropTypes.object,
  inputOnly: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  formGroupClassName: PropTypes.string,
  tooltipError: PropTypes.bool,
  onValueChange: PropTypes.func,
  positionFixed: PropTypes.bool,
  invalid: PropTypes.bool,
  initialValue: PropTypes.bool
}

export default FormCheckboxInput
