import { Link } from 'react-router-dom'
import { Badge } from 'reactstrap'

import { getUrl as getInvoiceUrl } from '../../actions/invoiceActions'
import { invoicePropType, shapeOrEmpty } from '../../propTypes'
import { getInternalStatus, getReminderState } from '../../selectors/invoiceSelectors'

const interestInvoiceState = {
  color: 'info',
  title: 'Korkolaskutettu'
}

const InterestInvoiceBadge = ({ invoice }) => {
  if(!invoice.interestInvoiceId) {
    return null
  }
  return (
    <Link to={getInvoiceUrl(invoice.interestInvoiceId)}>
      <br />
      <Badge color={interestInvoiceState.color}>{interestInvoiceState.title}</Badge>
    </Link>
  )
}
InterestInvoiceBadge.propTypes = {
  invoice: shapeOrEmpty(invoicePropType)
}

const InvoiceStatusBadge = ({ invoice = {} }) => {
  const { title, color } = getInternalStatus(invoice)
  const reminderState = getReminderState(invoice)
  return (
    <span className='flex-wrap mr-child-1'>
      {!!invoice.archivedAt && <Badge color='info'>Arkistoitu</Badge>}
      <Badge color={color}>{title}</Badge>
      {reminderState && (
        <>
          <br />
          <Badge color={reminderState.color}>{reminderState.title}</Badge>
        </>
      )}
      <InterestInvoiceBadge invoice={invoice} />
    </span>
  )
}

InvoiceStatusBadge.propTypes = {
  invoice: invoicePropType
}

const getStatusText = invoice => getInternalStatus(invoice).title

export default InvoiceStatusBadge
export {
  getStatusText
}
