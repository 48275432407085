import { Component } from 'react'
import PropTypes from 'prop-types'
import { castToArray } from '@evelia/helpers/helpers'

import productActions from '../../actions/productActions'
import { renderProductSearchResult } from '../Work/productRenderHelpers'
import SearchFormGroup from './SearchFormGroup'

class ProductSearchFormGroup extends Component {
  render() {
    const { defaultProps, field, ...searchFormGroupProps } = this.props
    return (
      <SearchFormGroup
        {...searchFormGroupProps}
        field={field}
        labelKey={field}
        handleOnChange={this.handleProductSearchOnChange}
        handleInputChange={this.handleProductSearchInputChange}
        searchAction={this.productSearchAction}
        getSelected={this.getSelected}
        filterBy={this.filterBy}
        renderMenuItemChildren={renderProductSearchResult}
        {...defaultProps}
      />
    )
  }

  getSelected = () => {
    const { model, field } = this.props
    return castToArray({ ...model, _isNotProduct: true, [field]: model[field] ?? '' })
  }

  filterBy = option => !option._isNotProduct

  getSearchPayload = searchTerm => {
    const { getSearchPayload, searchField } = this.props
    return getSearchPayload?.(searchTerm) ?? {
      [searchField ?? '*']: searchTerm
    }
  }

  productSearchAction = (searchTerm, data) =>
    productActions.searchRequest(this.getSearchPayload(searchTerm), {
      ...data,
      ...(this.props.searchProps || {})
    })

  handleProductSearchInputChange = selection => this.props.updateField(this.props.field, selection || '')
  handleProductSearchOnChange = selection => {
    const {
      parseProductFields,
      model,
      defaultProps,
      field,
      combinedVatRates
    } = this.props
    if(selection._isNotProduct) {
      return
    }
    return defaultProps.onChange(null, parseProductFields(selection, model, combinedVatRates))
      .then(newModel => newModel[field])
  }

  static propTypes = {
    field: PropTypes.string.isRequired,
    model: PropTypes.object.isRequired,
    defaultProps: PropTypes.shape({
      onChange: PropTypes.func.isRequired
    }),
    updateField: PropTypes.func.isRequired,
    parseProductFields: PropTypes.func.isRequired,
    inputOnly: PropTypes.bool,
    searchProps: PropTypes.object,
    onEnterPressed: PropTypes.func,
    onEscPressed: PropTypes.func,
    searchField: PropTypes.string,
    getSearchPayload: PropTypes.func,
    autoFocus: PropTypes.bool,
    combinedVatRates: PropTypes.array.isRequired
  }
}
export default ProductSearchFormGroup
