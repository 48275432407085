import { all, takeEvery, takeLatest } from 'redux-saga/effects'

import workTypeActions from '../actions/workTypeActions'
import {
  createWorkType,
  deleteWorkType,
  fetchWorkTypes,
  updateWorkType
} from '../api/workTypeApi'
import {
  createFlow,
  createSocketWatcher,
  deleteFlow,
  fetchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

const watchOnWorkTypeSockets = createSocketWatcher('workType', {
  created: workTypeActions.createSuccess,
  updated: workTypeActions.updateSuccess,
  deleted: workTypeActions.deleteSuccess
})

const workTypeFetchFlow = fetchFlow({
  fetchApi: fetchWorkTypes,
  actions: workTypeActions,
  base: 'workTypes',
  errorMsg: 'Työtyyppien'
})

const workTypeUpdateFlow = updateFlow(updateWorkType, workTypeActions, 'Työtyyppi', 'Työtyypin')
const workTypeCreateFlow = createFlow(createWorkType, workTypeActions, 'Työtyyppi', 'Työtyypin')
const workTypeDeleteFlow = deleteFlow({
  deleteApi: deleteWorkType,
  actions: workTypeActions,
  singular: 'Työtyyppi',
  errorMsg: 'Työtyypin',
  base: 'workTypes'
})

export default function* workTypeSaga() {
  yield takeLatest(workTypeActions.actionTypes.fetchRequest, workTypeFetchFlow)
  yield takeEvery(workTypeActions.actionTypes.updateRequest, workTypeUpdateFlow)
  yield takeEvery(workTypeActions.actionTypes.createRequest, workTypeCreateFlow)
  yield takeEvery(workTypeActions.actionTypes.deleteRequest, workTypeDeleteFlow)

  yield all([
    watchOnWorkTypeSockets()
  ])
}
