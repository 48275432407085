import { useCallback } from 'react'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import clsx from 'clsx'
import noop from 'lodash/noop'

import useToggle from '../../../hooks/useToggle'
import IconButton from '../../Buttons/IconButton'
import ColorBadge from '../../misc/ColorBadge'
import { commonFormInputPropTypes } from '../propTypes'

const themeColors = [
  'primary',
  'secondary',
  'success',
  'warning',
  'info',
  'danger'
]

const ThemeColorFormInput = ({ value = 'success', disabled, onChange, setNull }) => {
  const [displayColorPicker, { toggle, disable: closePicker }] = useToggle(false)

  const handleChange = useCallback(color => () => {
    onChange(color, null)
    closePicker()
  }, [closePicker, onChange])

  const resetValue = useCallback(() =>
    onChange(null, null)
  , [onChange])

  return (
    <div className='d-flex align-items-center mt-n2'>
      <Dropdown isOpen={displayColorPicker} toggle={disabled ? noop : toggle} data-testid='themeColorPicker'>
        <DropdownToggle
          tag='span'
          data-toggle='dropdown'
          aria-expanded={displayColorPicker}
          className={clsx(!disabled && 'cursor-pointer')}
        >
          <ColorBadge color={value} />
        </DropdownToggle>
        <DropdownMenu>
          {themeColors.map(color => (
            <ColorBadge
              key={color}
              color={color}
              onClick={handleChange(color)}
              className='m-1'
              data-testid={`themeColorPicker_${color}`}
            />
          ))}
        </DropdownMenu>
      </Dropdown>
      {setNull && (
        <IconButton
          onClick={resetValue}
          icon={faXmark}
          size='xs'
          color='link'
          className='text-muted'
        />
      )}
    </div>
  )
}
ThemeColorFormInput.propTypes = commonFormInputPropTypes

export default ThemeColorFormInput
