import constants from '../constants'
import { actionCreatorsFor } from '../helpers/actionHelpers'

const baseActionCreators = actionCreatorsFor(constants.actionKeys.PROJECT_TYPES)

const additionalActionCreators = {

}

const projectTypeActions = { ...baseActionCreators, ...additionalActionCreators }
export default projectTypeActions
