import PropTypes from 'prop-types'

import machineRecordActions from '../../actions/machineRecordActions'
import { machineRecordPropType } from '../../propTypes'
import DeleteRecordPrompt from './DeleteRecordPrompt'

const DeleteMachineRecordPrompt = ({ visible, data }) => {
  return (
    <DeleteRecordPrompt
      visible={visible}
      data={data}
      deleteAction={machineRecordActions.deleteRequest}
      typeLabel='konetunnit'
    />
  )
}

DeleteMachineRecordPrompt.propTypes = {
  visible: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    records: PropTypes.arrayOf(machineRecordPropType)
  })
}

export default DeleteMachineRecordPrompt
