import { parseTemplate } from 'url-template'

import { del, get, post, put } from '../helpers/apiHelpers'

const baseUrl = '/api/work_types/{workTypeId}'
const workTypeUrlTemplate = parseTemplate(baseUrl)

export const fetchWorkTypes = (params = {}) =>
  get(workTypeUrlTemplate.expand(params))

export const updateWorkType = workType =>
  put(workTypeUrlTemplate.expand({ workTypeId: workType.id }), {
    body: JSON.stringify(workType)
  })

export const createWorkType = workType =>
  post(workTypeUrlTemplate.expand({}), {
    body: JSON.stringify(workType)
  })

export const deleteWorkType = workType =>
  del(workTypeUrlTemplate.expand({ workTypeId: workType.id }))
