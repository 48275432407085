import { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

import Form from '../Form/Form'
import { renderSaveCancelButtons } from '../Form/renderButtons'
import { TextFormGroup } from '../FormGroups'
import ModalBase from './ModalBase'

const ModalPrompt = ({
  isOpen,
  closeModal,
  model,
  save,
  header,
  field = 'message',
  label = 'Viesti',
  getForm,
  FormComponent,
  renderButtons,
  modalProps,
  manualButtons = false,
  disablePrompt = false,
  formProps,
  prependContent,
  appendContent
}) => {
  const formRef = useRef()
  const getPromptForm = useCallback(formOptions => {
    if(getForm) {
      return getForm(formOptions)
    } else if(FormComponent) {
      return (
        <FormComponent
          closeModal={closeModal}
          {...formOptions}
        />
      )
    }
    return (
      <TextFormGroup field={field} label={label} {...formOptions.defaultProps} />
    )
  }, [FormComponent, closeModal, field, getForm, label])

  const renderDefaultButtons = useCallback(() => renderSaveCancelButtons(closeModal), [closeModal])

  const renderBody = useCallback(() => {
    return (
      <>
        {prependContent}
        <Form
          {...formProps}
          ref={formRef}
          model={model}
          saveFunction={save}
          getForm={getPromptForm}
          renderButtons={renderButtons || renderDefaultButtons}
          manualButtons={manualButtons}
          disablePrompt={disablePrompt}
        />
        {appendContent}
      </>
    )
  }, [appendContent, disablePrompt, formProps, getPromptForm, manualButtons, model, prependContent, renderButtons, renderDefaultButtons, save])

  return (
    <ModalBase
      title={header}
      isOpen={isOpen}
      onCancel={closeModal}
      renderBody={renderBody}
      modalProps={modalProps}
    />
  )
}

ModalPrompt.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
  save: PropTypes.func,
  header: PropTypes.node.isRequired,
  field: PropTypes.string,
  label: PropTypes.string,
  getForm: PropTypes.func,
  FormComponent: PropTypes.elementType,
  renderButtons: PropTypes.func,
  modalProps: PropTypes.object,
  manualButtons: PropTypes.bool,
  disablePrompt: PropTypes.bool,
  formProps: PropTypes.object,
  prependContent: PropTypes.node,
  appendContent: PropTypes.node
}

export default ModalPrompt
