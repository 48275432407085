import { parseTemplate } from 'url-template'

import constants from '../constants'
import { actionCreatorsFor } from '../helpers/actionHelpers'
import { PATH_SETTINGS } from '../routes'
import { navigateTo } from './navigateActions'

const workFormTypeTemplate = parseTemplate(`${PATH_SETTINGS}/work_form_types{/formTypeId}`)
const workFormTypeBase = parseTemplate(`${PATH_SETTINGS}/work_form_types`)
const invoiceFormTypeTemplate = parseTemplate(`${PATH_SETTINGS}/invoice_form_types{/formTypeId}`)
const invoiceFormTypeBase = parseTemplate(`${PATH_SETTINGS}/invoice_form_types`)

const baseActionCreators = actionCreatorsFor(constants.actionKeys.FORM_TYPES)

const additionalActionCreators = {
  navigateToWorkFormType: (formTypeId, replaceUrl) => navigateTo(workFormTypeTemplate.expand({ formTypeId: formTypeId || 'new' }), replaceUrl),
  navigateToWorkFormTypeBase: replaceUrl => navigateTo(workFormTypeBase.expand({ }), replaceUrl),
  navigateToInvoiceFormType: (formTypeId, replaceUrl) => navigateTo(invoiceFormTypeTemplate.expand({ formTypeId: formTypeId || 'new' }), replaceUrl),
  navigateToInvoiceFormTypeBase: replaceUrl => navigateTo(invoiceFormTypeBase.expand({ }), replaceUrl)
}

const formTypeActions = { ...baseActionCreators, ...additionalActionCreators }
export default formTypeActions
