import PropTypes from 'prop-types'
import { Helmet as ReactHelmet } from 'react-helmet-async'
import { connect } from 'react-redux'

export const docTitlePropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  )
])

const Helmet = ({ title, companyName, separator = ' - ' }) => {
  const titleArray = [].concat(title)
  const documentTitle = [...titleArray, 'Evelia', companyName].filter(i => i).join(separator)
  return (
    <ReactHelmet>
      <title>{documentTitle}</title>
    </ReactHelmet>
  )
}
Helmet.propTypes = {
  title: docTitlePropType,
  companyName: PropTypes.string,
  separator: PropTypes.string
}

const mapStateToProps = ({ systemCustomer }) => ({
  companyName: systemCustomer.settingsData.details.name
})

export default connect(mapStateToProps)(Helmet)
