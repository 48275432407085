import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import Bluebird from 'bluebird'

import { hideActionPrompt } from '../../actions/uiActions'
import useActions from '../../hooks/useActions'
import { workRecordPropType } from '../../propTypes'
import CancelButton from '../Buttons/CancelButton'
import SaveButton from '../Buttons/SaveButton'
import ActionPromptModal from './ActionPromptModal'

const promptComponentActions = {
  hideActionPrompt
}

const DeleteRecordPrompt = ({ visible, data, deleteAction, typeLabel }) => {
  const promptActions = useActions(promptComponentActions)
  const dispatch = useDispatch()

  const { records } = data
  const body = `Poistetaanko myös alkuperäiset ${typeLabel} (${records.length} kpl)?`

  const deleteRecord = useCallback(
    () =>
      Bluebird.mapSeries(records, workRecord =>
        new Promise((resolve, reject) =>
          dispatch(deleteAction({ id: workRecord.id }, { resolve, reject }))
        )
      )
        .then(() => promptActions.hideActionPrompt()),
    [records, dispatch, deleteAction, promptActions]
  )

  return (
    <ActionPromptModal
      visible={visible}
      onCancel={hideActionPrompt}
      renderBody={() => body}
      renderFooter={() => (
        <>
          <SaveButton condensed onClick={deleteRecord}>Ok</SaveButton>
          <CancelButton condensed className='ms-3' outline onClick={promptActions.hideActionPrompt}>Ei</CancelButton>
        </>
      )}
    />
  )
}

DeleteRecordPrompt.propTypes = {
  visible: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    records: PropTypes.arrayOf(workRecordPropType)
  }),
  deleteAction: PropTypes.func.isRequired,
  typeLabel: PropTypes.string.isRequired
}

export default DeleteRecordPrompt
