import { createApi } from '../helpers/apiHelpers'
import { parseFilterParams, parseTableOptionsFromQuery } from '../helpers/reducerHelpers'
import { optionsQueryBase } from './urlTemplates'

export const normalizeTickets = response => {
  const data = response.record || response.records
  const {
    work,
    targets,
    customers,
    contacts,
    projects,
    options
  } = response._embedded || {}

  return {
    data,
    work,
    targets,
    customers,
    contacts,
    projects,
    tableOptions: parseTableOptionsFromQuery(options)
  }
}

export const ticketApi = createApi({
  base: 'tickets',
  normalizer: normalizeTickets,
  extraUrlTemplate: `${optionsQueryBase}{?onlyOpen,isOwn,sendNotification}`,
  editGetParams: params =>
    params.subItem === 'occurrencies'
      ? { subItem: 'occurrencies', id: params.ticketId }
      : parseFilterParams(params)
})

const getFetchParams = params => {
  const {
    workId,
    customerId,
    contactId,
    projectId,
    ...rest
  } = params
  let subParams = null
  if(workId) {
    subParams = { base: 'work', id: workId }
  } else if(customerId) {
    subParams = { base: 'customers', id: customerId }
  } else if(contactId) {
    subParams = { base: 'contacts', id: contactId }
  } else if(projectId) {
    subParams = { base: 'projects', id: projectId }
  } else {
    throw new Error('Tuntematon tehtäväparametri')
  }
  return { ...subParams, subItem: 'tickets', ...rest }
}

export const fetchRelationTickets = params =>
  ticketApi.fetch(getFetchParams(params))
