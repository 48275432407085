import { useSelector } from 'react-redux'

import { getUnreadInboundMessages } from '../../selectors/inboundMessageSelectors'

const InboundMessageNavBadge = () => {
  const count = useSelector(state => getUnreadInboundMessages(state)).length
  return count > 0
    ? (
      <span data-testid='runningWorkRecordCountIndicator' className='fa-layers-counter fa-2x'>{count}</span>
      )
    : null
}

export default InboundMessageNavBadge
