import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'

import workActions from '../actions/workActions'
import {
  createCalendarEvent,
  deleteCalendarEvent,
  fetchCalendarEvents,
  parseCalendarEventFetchResponse,
  updateCalendarEvent
} from '../api/calendarEventApi'
import {
  createFlow,
  createSocketWatcherWithGenerator,
  deleteFlow,
  genericSagaErrorHandler,
  getPromiseHandlersFromData,
  updateFlow
} from '../helpers/sagaHelpers'
import { findWorkScheduleEventsBetween } from '../selectors/calendarEventSelectors'
import { handleWorkApiResponse } from './workSaga'

const watchOnWorkScheduleSockets = createSocketWatcherWithGenerator('workSchedule', {
  created: function* (data) {
    yield handleWorkScheduleApiResponse(workActions.workScheduleEvents.createSuccess)(parseCalendarEventFetchResponse(data))
  },
  updated: function* (data) {
    yield handleWorkScheduleApiResponse(workActions.workScheduleEvents.updateSuccess)(parseCalendarEventFetchResponse(data))
  },
  deleted: function* (data) {
    yield put(workActions.workScheduleEvents.deleteSuccess(data))
  }
})

const handleWorkScheduleApiResponse = mainAction => function* ([workScheduleResponse, work]) {
  yield handleWorkApiResponse(workActions.fetchSuccess)(work)
  yield put(mainAction(workScheduleResponse))
}

const checkDifferentParams = (stateParams, fetchParams) => {
  return stateParams.startDate !== fetchParams.startDate || stateParams.endDate !== fetchParams.endDate
}

function* workScheduleFetchFlow({ data = {} }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    const fetchParams = { ...data, type: 'work' }
    if(data.force || checkDifferentParams((yield select(state => state.work.workScheduleEvents.metadata.fetchParams)), fetchParams)) {
      yield put(workActions.workScheduleEvents.fetchStart())
      const apiResponse = yield call(fetchCalendarEvents, fetchParams)
      yield handleWorkScheduleApiResponse(workActions.workScheduleEvents.fetchSuccess)(apiResponse)
      yield put(workActions.workScheduleEvents.setFetchParams(fetchParams))
      return resolve(apiResponse[0])
    }
    return resolve(yield select(state => findWorkScheduleEventsBetween(state, fetchParams.startDate, fetchParams.endDate)))
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe kalenterimerkintöjen noudossa', reject)
  }
}

const workScheduleUpdateFlow = function* ({ data = {}, ...opts }) {
  yield * updateFlow(updateCalendarEvent, workActions.workScheduleEvents, 'Kalenterimerkintä', 'Kalenterimerkinnän', handleWorkScheduleApiResponse(workActions.workScheduleEvents.updateSuccess))({ ...opts, data: { ...data, type: 'work' } })
}
const workScheduleCreateFlow = function* ({ data = {}, ...opts }) {
  yield * createFlow(createCalendarEvent, workActions.workScheduleEvents, 'Kalenterimerkintä', 'Kalenterimerkinnän', handleWorkScheduleApiResponse(workActions.workScheduleEvents.createSuccess))({ ...opts, data: { ...data, type: 'work' } })
}
const workScheduleDeleteFlow = function* ({ data = {}, ...opts }) {
  yield * deleteFlow({
    deleteApi: deleteCalendarEvent,
    actions: workActions.workScheduleEvents,
    singular: 'Kalenterimerkintä',
    errorMsg: 'Kalenterimerkinnän',
    base: 'work.workScheduleEvents'
  })({ ...opts, data: { ...data, type: 'work' } })
}

export default function* calendarEventSaga() {
  yield takeLatest(workActions.workScheduleEvents.actionTypes.fetchRequest, workScheduleFetchFlow)
  yield takeEvery(workActions.workScheduleEvents.actionTypes.updateRequest, workScheduleUpdateFlow)
  yield takeEvery(workActions.workScheduleEvents.actionTypes.createRequest, workScheduleCreateFlow)
  yield takeEvery(workActions.workScheduleEvents.actionTypes.deleteRequest, workScheduleDeleteFlow)

  yield all([
    watchOnWorkScheduleSockets()
  ])
}
