import { all, put, takeEvery, takeLatest } from 'redux-saga/effects'

import productActions from '../actions/productActions'
import quickProductActions from '../actions/quickProductActions'
import { normalizeProducts } from '../api/productApi'
import {
  createQuickProduct,
  deleteQuickProduct,
  fetchQuickProducts,
  normalizeQuickProductResponse
} from '../api/quickProductApi'
import {
  createFlow,
  createSocketWatcherWithApiHandlerAndNormalizer,
  deleteFlow,
  fetchFlow
} from '../helpers/sagaHelpers'
import { handleProductApiResponse } from './productSaga'

const handleQuickProductApiResponse = mainAction =>
  function* ({ data, products }) {
    yield handleProductApiResponse(productActions.fetchSuccess, false)(normalizeProducts(products))
    yield put(mainAction(data))
    return data
  }

const watchOnQuickProductSockets = createSocketWatcherWithApiHandlerAndNormalizer('quickProduct', quickProductActions, handleQuickProductApiResponse, normalizeQuickProductResponse)

const quickProductFetchFlow = fetchFlow({
  fetchApi: fetchQuickProducts,
  actions: quickProductActions,
  base: 'quickProducts',
  errorMsg: 'Pikatuotteiden',
  apiResponseHandler: handleQuickProductApiResponse(quickProductActions.fetchSuccess)
})

const quickProductCreateFlow = createFlow(createQuickProduct, quickProductActions, 'Pikatuote', 'Pikatuotteen', handleQuickProductApiResponse(quickProductActions.createSuccess))
const quickProductDeleteFlow = deleteFlow({
  deleteApi: deleteQuickProduct,
  actions: quickProductActions,
  singular: 'Pikatuote',
  errorMsg: 'Pikatuotteen',
  base: 'quickProducts'
})

export default function* quickProductSaga() {
  yield takeLatest(quickProductActions.actionTypes.fetchRequest, quickProductFetchFlow)
  yield takeEvery(quickProductActions.actionTypes.createRequest, quickProductCreateFlow)
  yield takeEvery(quickProductActions.actionTypes.deleteRequest, quickProductDeleteFlow)

  yield all([
    watchOnQuickProductSockets()
  ])
}
