import { capitalizeObjectValues } from '../../helpers/helpers'

const receiptTitles = {
  basic: 'kuitti',
  genetive: 'kuitin',
  partitive: 'kuittia',
  allative: 'kuitille',
  pluralBasic: 'kuitit',
  pluralGenetive: 'kuittien',
  pluralPartitive: 'kuitteja'
}

export const receiptTitlesCapitalized = capitalizeObjectValues(receiptTitles)

export default receiptTitles
