import merge from 'redux-crud-mb/dist/reducers/list/store/merge'

import fileActions from '../actions/fileActions'
import constants from '../constants'
import { getCombinedListReducerFor } from '../helpers/reducerHelpers'

export default getCombinedListReducerFor(constants.actionKeys.FILES, {
  base64: (state = { records: [] }, action) => {
    switch(action.type) {
      case fileActions.actionTypes.downloadFileStart:
        return { ...state, records: merge(state.records, [{ ...action.record, _downloading: true, _errored: false }], 'id') }
      case fileActions.actionTypes.downloadFileSuccess:
        return { ...state, records: merge(state.records, [{ ...action.record, _downloading: false, _errored: false }], 'id') }
      case fileActions.actionTypes.downloadFileError:
        return { ...state, records: merge(state.records, [{ ...action.record, _downloading: false, _errored: true, base64: null }], 'id') }
      default:
        return state
    }
  },
  customerFiles: getCombinedListReducerFor(constants.actionKeys.CUSTOMER_RELATION_FILES),
  projectFiles: getCombinedListReducerFor(constants.actionKeys.PROJECT_RELATION_FILES)
})
