import constants from '../constants'
import { actionCreatorsFor, request } from '../helpers/actionHelpers'

const baseActionCreators = actionCreatorsFor(constants.actionKeys.ACCOUNTS, { createBulk: true, updateBulk: true })

const additionalActionCreators = {
  createBulkRequest: request(baseActionCreators.actionTypes.createBulkRequest),
  updateBulkRequest: request(baseActionCreators.actionTypes.updateBulkRequest),
  defaultAccounts: actionCreatorsFor(constants.actionKeys.DEFAULT_ACCOUNTS)
}

const accountActions = { ...baseActionCreators, ...additionalActionCreators }
export default accountActions
