import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import TooltipBase, { tooltipBasePropTypes } from './TooltipBase'

const Tooltip = props => {
  const { icon = faQuestionCircle, className } = props
  const targetComponentProps = useMemo(() => ({
    icon,
    className
  }), [className, icon])
  return (
    <TooltipBase
      {...props}
      TargetComponent={FontAwesomeIcon}
      targetComponentProps={targetComponentProps}
    />
  )
}
Tooltip.propTypes = {
  ...tooltipBasePropTypes,
  icon: PropTypes.object,
  className: PropTypes.string
}

export default Tooltip
