import { Badge } from 'reactstrap'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import maxBy from 'lodash/maxBy'
import minBy from 'lodash/minBy'

import { formatEur, formatPercent } from '../../helpers/helpers'
import { productPropType } from '../../propTypes'
import { UserPermission } from '../Authentication/permissionWrappers'
import Tooltip from '../Tooltip'

export const ProductPriceBadge = ({ product }) => {
  if(!product) {
    return null
  }
  const price = product.salesPrice
  const discount = product.salesDiscount

  return (
    <UserPermission>
      <Badge color='info' className='ms-1'>
        {formatEur(price)}
        {discount ? <small> (-{formatPercent(discount)})</small> : ''}
      </Badge>
      {renderPriceDifference(product)}
    </UserPermission>
  )
}
ProductPriceBadge.propTypes = {
  product: productPropType
}

const ProductSearchResult = ({ searchResult }) => (
  <div className='border-bottom'>
    <div className='py-1'>
      <Badge className='align-top mt-1' color='success'>{searchResult.productLineCode} {searchResult.productNumber}</Badge>
      <span className='d-inline-block ms-2'>
        {searchResult.name}<br />
        <span className='fw-light'>{searchResult.nameExtension}</span>
        <ProductPriceBadge product={searchResult} />
      </span>
    </div>
  </div>
)
ProductSearchResult.propTypes = {
  searchResult: productPropType
}

const renderPriceDifference = product => {
  const supplierInfosWithListPrice = product.supplierInfos.filter(supplierInfo => supplierInfo.listPrice != null)
  const minSupplier = minBy(supplierInfosWithListPrice, 'listPrice')
  const maxSupplier = maxBy(supplierInfosWithListPrice, 'listPrice')
  if(minSupplier && maxSupplier) {
    const listPriceDifference = maxSupplier.listPrice / minSupplier.listPrice
    return listPriceDifference >= 1.3 ? <Tooltip className='text-danger' icon={faExclamationTriangle} message='Halvimman ja kalleimman toimittajan listahintojen ero on yli 30%. Tarkista tuotteen toimittajatietojen paikkansapitävyys.' /> : null
  }
  return null
}

export const renderProductSearchResult = searchResult => <ProductSearchResult searchResult={searchResult} />
