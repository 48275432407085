import isError from 'lodash/isError'

import { isLocalDevelopmentEnv } from './envHelpers'

export class ArgumentOutOfRangeError extends Error {}

export class NotFoundError extends Error {
  constructor(message) {
    super(message)
    this.status = 404
  }
}

export const throwIfNotInProduction = error => {
  if(isLocalDevelopmentEnv) {
    throw error
  }

  if(isError(error)) {
    console.error(error.message)
  } else {
    console.error(error)
  }

  return false
}
