/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup'

yup.addMethod(yup.object, 'min', function min(min, message = '${path} pitää sisältää vähintään ${min} avainta') {
  return this.test({
    message,
    name: 'min',
    exclusive: true,
    params: { min },
    test(value) {
      return value == null || Object.keys(value).length >= this.resolve(min)
    }
  })
})

yup.addMethod(yup.object, 'max', function max(max, message = '${path} pitää sisältää enintään ${max} avainta') {
  return this.test({
    message,
    name: 'max',
    exclusive: true,
    params: { max },
    test(value) {
      return value == null || Object.keys(value).length <= this.resolve(max)
    }
  })
})
