import { capitalizeObjectValues } from '../../helpers/helpers'

const systemMessageTitles = {
  basic: 'tiedote',
  genetive: 'tiedotteen',
  partitive: 'tiedotetta',
  allative: 'tiedotteelle',
  pluralBasic: 'tiedotteet',
  pluralGenetive: 'tiedotteiden',
  pluralPartitive: 'tiedotteita'
}

export const systemMessageTitlesCapitalized = capitalizeObjectValues(systemMessageTitles)

export default systemMessageTitles
