import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Badge } from 'reactstrap'

import { workStates, workSystemTypes } from '../../constants'
import { workPropType } from '../../propTypes'
import { getWorkCustomStateSelectMap } from '../../selectors/workCustomStateSelectors'
import ModelBadge from '../Badge/ModelBadge'

const PreventInvoicingBadge = () => <Badge color='danger'>Ei laskuteta</Badge>

const RecurringWorkBadge = () => <Badge color='secondary'>Toistuva</Badge>

const RecurringWorkTemplateBadge = () => <Badge color='secondary'>Toistuva pohja</Badge>

const ProjectWorkBadge = ({ work, workCustomStatesMap }) => (
  <>
    <Badge color='info'>Projekti</Badge>
    {work.state === workStates.WORK_STATE_CLOSED && (
      <ModelBadge model={work} optionMap={workCustomStatesMap} lookupKey='workCustomStateId' />
    )}
  </>
)
ProjectWorkBadge.propTypes = {
  work: workPropType.isRequired,
  workCustomStatesMap: PropTypes.object
}

const WorkStatusBadge = ({ work = {} }) => {
  const workCustomStatesMap = useSelector(getWorkCustomStateSelectMap)
  return (
    <span className='mr-child-1'>
      {work.type === workSystemTypes.WORK_TYPE_PROJECT
        ? <ProjectWorkBadge work={work} workCustomStatesMap={workCustomStatesMap} />
        : (
          <>
            <ModelBadge model={work} optionMap={workCustomStatesMap} lookupKey='workCustomStateId' />
            {work.notInvoisable && <PreventInvoicingBadge />}
            {work?.templateWorkId && <RecurringWorkBadge />}
            {work?.isRecurring && <RecurringWorkTemplateBadge />}
          </>
          )}
    </span>
  )
}

WorkStatusBadge.propTypes = {
  work: workPropType
}
export default WorkStatusBadge
