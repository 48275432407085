import { useCallback } from 'react'
import { castToArray } from '@evelia/helpers/helpers'

import systemMessageActions from '../../actions/systemMessageActions'
import { promisifyAction } from '../../helpers/dispatchHelpers'
import useActions from '../../hooks/useActions'

const componentActions = {
  confirmNotification: systemMessageActions.doPatchAction
}

const useConfirmSystemMessage = (ids, onConfirmed) => {
  const actions = useActions(componentActions)

  const confirmNotification = useCallback(async() => {
    const systemMessageIds = castToArray(ids)
    if(!systemMessageIds.length) {
      return
    }
    try {
      await promisifyAction({ systemMessageIds }, actions.confirmNotification, { subItem: 'confirm' })
      onConfirmed?.()
    } catch(error) {
      console.warn('Tuntematon virhe tiedotteen lukemisessa', error)
    }
  }, [actions.confirmNotification, ids, onConfirmed])

  return confirmNotification
}

export default useConfirmSystemMessage
