import { createApi } from '@reduxjs/toolkit/query/react'

import { mimeTypes } from '../../components/File/fileHelpers'
import { getBaseQuery } from './apiHelpers'

export type CreateExcelExportPayload = {
  headers: {
    header: string
    key: string
    format?: 'number' | 'date' | 'percent'
  }[]
  rows: Record<string, unknown>[]
  name: string
}

export type CreateExcelExportResponse = {
  name: string
  result: string
}

export const exportsApi = createApi({
  reducerPath: 'exports',
  baseQuery: getBaseQuery('exports'),
  endpoints: builder => ({
    createExcelExport: builder.query<CreateExcelExportResponse, CreateExcelExportPayload>({
      query: body => ({
        url: '/excel',
        method: 'POST',
        body
      }),
      transformResponse: ({ result, name }: CreateExcelExportResponse) => {
        return {
          result: `data:${mimeTypes.excel};base64,${result}`,
          name: `${name}.xlsx`
        }
      }
    })
  })
})

export const { useCreateExcelExportQuery, useLazyCreateExcelExportQuery } = exportsApi
