import { all, takeEvery, takeLatest } from 'redux-saga/effects'

import projectTypeActions from '../actions/projectTypeActions'
import { projectTypeApi } from '../api/projectTypeApi'
import {
  createFlow,
  createSocketWatcher,
  deleteFlow,
  fetchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

const watchOnProjectTypeSockets = createSocketWatcher('projectType', {
  created: projectTypeActions.createSuccess,
  updated: projectTypeActions.updateSuccess,
  deleted: projectTypeActions.deleteSuccess
})

const projectTypeFetchFlow = fetchFlow({
  fetchApi: projectTypeApi.fetch,
  actions: projectTypeActions,
  base: 'projectTypes',
  errorMsg: 'Projektityyppien'
})

const projectTypeUpdateFlow = updateFlow(projectTypeApi.update, projectTypeActions, 'Projektityyppi', 'Projektityypin')
const projectTypeCreateFlow = createFlow(projectTypeApi.create, projectTypeActions, 'Projektityyppi', 'Projektityypin')
const projectTypeDeleteFlow = deleteFlow({
  deleteApi: projectTypeApi.remove,
  actions: projectTypeActions,
  singular: 'Projektityyppi',
  errorMsg: 'Projektityypin',
  base: 'projectTypes'
})

export default function* projectTypeSaga() {
  yield takeLatest(projectTypeActions.actionTypes.fetchRequest, projectTypeFetchFlow)
  yield takeEvery(projectTypeActions.actionTypes.updateRequest, projectTypeUpdateFlow)
  yield takeEvery(projectTypeActions.actionTypes.createRequest, projectTypeCreateFlow)
  yield takeEvery(projectTypeActions.actionTypes.deleteRequest, projectTypeDeleteFlow)

  yield all([
    watchOnProjectTypeSockets()
  ])
}
