import { all, takeEvery, takeLatest } from 'redux-saga/effects'

import customerGroupActions from '../actions/customerGroupActions'
import {
  createCustomerGroup,
  deleteCustomerGroup,
  fetchCustomerGroups,
  updateCustomerGroup
} from '../api/customerGroupApi'
import {
  createFlow,
  createSocketWatcher,
  deleteFlow,
  fetchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

const watchOnCustomerGroupSockets = createSocketWatcher('customerGroup', {
  created: customerGroupActions.createSuccess,
  updated: customerGroupActions.updateSuccess,
  deleted: customerGroupActions.deleteSuccess
})

const customerGroupFetchFlow = fetchFlow({
  fetchApi: fetchCustomerGroups,
  actions: customerGroupActions,
  base: 'customerGroups',
  errorMsg: 'Asiakasryhmien'
})

const customerGroupUpdateFlow = updateFlow(updateCustomerGroup, customerGroupActions, 'Asiakasryhmä', 'Asiakasryhmän')
const customerGroupCreateFlow = createFlow(createCustomerGroup, customerGroupActions, 'Asiakasryhmä', 'Asiakasryhmän')
const customerGroupDeleteFlow = deleteFlow({
  deleteApi: deleteCustomerGroup,
  actions: customerGroupActions,
  singular: 'Asiakasryhmä',
  errorMsg: 'Asiakasryhmän',
  base: 'customerGroups'
})

export default function* customerGroupSaga() {
  yield takeLatest(customerGroupActions.actionTypes.fetchRequest, customerGroupFetchFlow)
  yield takeEvery(customerGroupActions.actionTypes.updateRequest, customerGroupUpdateFlow)
  yield takeEvery(customerGroupActions.actionTypes.createRequest, customerGroupCreateFlow)
  yield takeEvery(customerGroupActions.actionTypes.deleteRequest, customerGroupDeleteFlow)

  yield all([
    watchOnCustomerGroupSockets()
  ])
}
