import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'

import './_iconButton.scss'

interface ExpandIconProps extends Omit<FontAwesomeIconProps, 'icon'> {
  expanded?: boolean
}

export const ExpandIcon = ({
  expanded,
  ...restProps
}: ExpandIconProps) =>
  <FontAwesomeIcon icon={expanded ? faCaretDown : faCaretRight} {...restProps} />

interface ExpandButtonProps {
  visible?: boolean
  expanded?: boolean
  onToggle?: () => void
  className?: string
  children?: React.ReactNode
}

const ExpandButton = ({
  visible = true, // Set default instead of inverting due DataTree uses this prop
  expanded,
  onToggle,
  className,
  children,
  ...restProps
}: ExpandButtonProps) => {
  const onClick = onToggle
    ? e => {
      e.stopPropagation()
      onToggle()
    }
    : undefined
  return (
    <span
      role='button'
      tabIndex={0}
      className={
      `${visible ? 'visible' : 'invisible'} icon-button cursor-pointer btn p-0 px-2 align-baseline icon-container ${className ?? ''}`
    }
      data-testid='expandButton'
      onClick={onClick}
      onKeyDown={onClick}
      {...restProps}
    >
      <ExpandIcon expanded={expanded} />
      {children || null}
    </span>
  )
}

export default ExpandButton
