import constants, { actionTypes } from '../constants'
import { actionCreatorsFor, request, success } from '../helpers/actionHelpers'
import { PATH_OFFERS } from '../routes'
import { navigateTo } from './navigateActions'
import tableActions from './tableActions'

export const getUrl = offerId => `${PATH_OFFERS}/${offerId || 'new'}`

const baseActionCreators = actionCreatorsFor(constants.actionKeys.OFFERS, { fetchExtraInfo: true })
const baseRowActionCreators = actionCreatorsFor(constants.actionKeys.OFFER_POST_ROWS, { updateRank: true })

const additionalActionCreators = {
  files: actionCreatorsFor(constants.actionKeys.OFFER_FILES),
  memos: actionCreatorsFor(constants.actionKeys.OFFER_MEMOS),
  posts: {
    ...actionCreatorsFor(constants.actionKeys.OFFER_POSTS),
    doPatchAction: request(actionTypes.OFFER_POST_PATCH_ACTION_REQUEST)
  },
  postRows: {
    ...baseRowActionCreators,
    updateRankRequest: request(baseRowActionCreators.actionTypes.updateRankRequest),
    doPutAction: request(actionTypes.OFFER_POST_ROW_PUT_ACTION_REQUEST)
  },
  doPatchAction: request(actionTypes.OFFER_PATCH_ACTION_REQUEST),
  offerDefaults: actionCreatorsFor(constants.actionKeys.OFFER_DEFAULTS),
  navigateTo: (offerId, replaceUrl) => navigateTo(getUrl(offerId), replaceUrl),
  navigateToPost: (offerId, offerPostId) => navigateTo(getUrl(offerId) + `#posts/${offerPostId}`),
  navigateToBase: replaceUrl => navigateTo(PATH_OFFERS, replaceUrl),
  tableActions: tableActions(constants.actionKeys.OFFERS),
  doOfferAction: request(actionTypes.OFFER_ACTION_REQUEST),
  doOfferPostRowPostAction: request(actionTypes.OFFER_POST_ROWS_ACTION_REQUEST),
  generateReport: request(actionTypes.OFFER_GENERATE_REPORT_REQUEST),
  fetchExtraInfoSuccess: success(baseActionCreators.actionTypes.fetchExtraInfoSuccess),
  importPostRowsFromFile: (offerId, files, subItemType) => ({
    type: actionTypes.OFFER_POST_IMPORT_ROWS_REQUEST,
    offerId,
    files,
    subItemType
  })
}

const offerActions = { ...baseActionCreators, ...additionalActionCreators }
export default offerActions
