import { parseTemplate } from 'url-template'

import { del, get, post, put } from '../helpers/apiHelpers'

const baseUrl = '/api/employee_roles/{employeeRoleId}'
const employeeRoleUrlTemplate = parseTemplate(baseUrl)

export const fetchEmployeeRoles = (params = {}) => {
  return get(employeeRoleUrlTemplate.expand(params))
    .then(parseEmployeeRoleFetchResponse)
}

export const updateEmployeeRole = employeeRole => {
  return put(employeeRoleUrlTemplate.expand({ employeeRoleId: employeeRole.id }), {
    body: JSON.stringify(employeeRole)
  })
}

export const createEmployeeRole = employeeRole => {
  return post(employeeRoleUrlTemplate.expand({}), {
    body: JSON.stringify(employeeRole)
  })
}

export const deleteEmployeeRole = employeeRole => {
  return del(employeeRoleUrlTemplate.expand({ employeeRoleId: employeeRole.id }))
}

const parseEmployeeRoleFetchResponse = response => response
