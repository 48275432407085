import { EVELIA_VERSION_SOCKET_KEY } from '@evelia/helpers/constants'
import { faRedo } from '@fortawesome/free-solid-svg-icons'
import { eventChannel } from 'redux-saga'

import { addInfoNotification } from '../helpers/notificationHelpers'
import { getSocketWatcherFunction } from '../helpers/sagaHelpers'
import IconButton from './Buttons/IconButton'

const NewVersion = () => (
  <>
    <h2>
      Uusi versio!
    </h2>
    Eveliasta on julkaistu uusi versio. <IconButton icon={faRedo} condensed color='info' onClick={() => window.location.reload()}>Päivitä</IconButton> sivu päästäksesi nauttimaan uudesta versiosta.
  </>
)

export const emitNewVersionNotification = (emit, rev) => {
  if(import.meta.env.VITE_GIT_REV && rev !== import.meta.env.VITE_GIT_REV) {
    addInfoNotification(<NewVersion />, { autoClose: false, toastId: 'versionNotification', onClose: () => window.location.reload(), closeButton: false })
  }
}

export const watchOnVersionSockets = (fn = emitNewVersionNotification) => getSocketWatcherFunction(socket => {
  return eventChannel(emit => {
    socket.on(EVELIA_VERSION_SOCKET_KEY, rev => {
      fn(emit, rev)
    })
    return () => {
      socket.off(EVELIA_VERSION_SOCKET_KEY)
    }
  })
})

export default NewVersion
