import constants from '../constants'
import { actionCreatorsFor } from '../helpers/actionHelpers'

const baseActionCreators = actionCreatorsFor(constants.actionKeys.OFFER_POST_STATES)

const additionalActionCreators = {

}

const offerPostStateActions = { ...baseActionCreators, ...additionalActionCreators }
export default offerPostStateActions
