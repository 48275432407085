import { Component } from 'react'
import { connect } from 'react-redux'

import contactActions from '../../actions/contactActions'
import { renderContactSearchItemInline } from '../../components/Helpers/searchResultRenderers'
import { getContactsFromSearchResult } from '../../selectors/contactSelectors'
import SearchResults, { searchResultsPropType } from './SearchResults'

class ContactResults extends Component {
  renderContact = renderContactSearchItemInline
  render() {
    return (
      <SearchResults
        title='Yhteyshenkilöt'
        renderResult={this.renderContact}
        {...this.props}
      />
    )
  }

  static propTypes = searchResultsPropType
}

const mapStateToProps = state => {
  return {
    results: getContactsFromSearchResult(state),
    isBusy: state.contacts.search.isBusy
  }
}

const mapDispatchToProps = dispatch => {
  return {
    navigateTo: contactId => dispatch(contactActions.navigateTo(contactId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactResults)
