import { all, takeEvery, takeLatest } from 'redux-saga/effects'

import inboundMessageActions from '../actions/inboundMessageActions'
import { inboundMessageApi } from '../api/inboundMessageApi'
import {
  createFlow,
  createSocketWatcher,
  deleteFlow,
  fetchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

const watchOnInboundMessageSockets = createSocketWatcher('inboundMessage', {
  created: inboundMessageActions.createSuccess,
  updated: inboundMessageActions.updateSuccess,
  deleted: inboundMessageActions.deleteSuccess
})

const inboundMessageFetchFlow = fetchFlow({
  fetchApi: inboundMessageApi.fetch,
  actions: inboundMessageActions,
  base: 'inboundMessages',
  errorMsg: 'Ulkoisten yhteydenottojen'
})

const inboundMessageUpdateFlow = updateFlow(inboundMessageApi.update, inboundMessageActions, 'Ulkoinen yhteydenotto', 'Ulkoisten yhteydenottojen')
const inboundMessageCreateFlow = createFlow(inboundMessageApi.create, inboundMessageActions, 'Ulkoinen yhteydenotto', 'Ulkoisten yhteydenottojen')
const inboundMessageDeleteFlow = deleteFlow({
  deleteApi: inboundMessageApi.remove,
  actions: inboundMessageActions,
  singular: 'Ulkoinen yhteydenotto',
  errorMsg: 'Ulkoisten yhteydenottojen',
  base: 'inboundMessages'
})

export default function* inboundMessageSaga() {
  yield takeLatest(inboundMessageActions.actionTypes.fetchRequest, inboundMessageFetchFlow)
  yield takeEvery(inboundMessageActions.actionTypes.updateRequest, inboundMessageUpdateFlow)
  yield takeEvery(inboundMessageActions.actionTypes.createRequest, inboundMessageCreateFlow)
  yield takeEvery(inboundMessageActions.actionTypes.deleteRequest, inboundMessageDeleteFlow)

  yield all([
    watchOnInboundMessageSockets()
  ])
}
