import { capitalizeObjectValues } from '../../helpers/helpers'

const ticketStateTitles = {
  basic: 'tila',
  genetive: 'tilan',
  partitive: 'tiloja',
  allative: 'tiloille',
  pluralBasic: 'tilat',
  pluralGenetive: 'tilojen',
  pluralPartitive: 'tiloja'
}

export const ticketStateTitlesCapitalized = capitalizeObjectValues(ticketStateTitles)

export default ticketStateTitles
