import { createSelector } from 'reselect'

import { workSystemTypes } from '../constants'
import { mapRecordNameOptions } from '../helpers/helpers'
import { getFindItemByIdSelector, getFindItemsByIdsSelector } from '../helpers/selectorHelpers'

export const getWorkTypesFromArgument = arg => arg.workTypes ? arg.workTypes.records : arg

export const findWorkTypeWithId = getFindItemByIdSelector(getWorkTypesFromArgument)
export const findWorkTypeWithIds = getFindItemsByIdsSelector(getWorkTypesFromArgument)

export const getWorkTypeSelectOptions = createSelector(
  getWorkTypesFromArgument,
  workTypes => mapRecordNameOptions(workTypes)
)

export const workSystemTypeOptions = [
  { value: null, text: 'Kaikki' },
  { value: workSystemTypes.WORK_TYPE_DEFAULT, text: 'Työt' },
  { value: workSystemTypes.WORK_TYPE_PROJECT, text: 'Projektilähetteet' },
  { value: workSystemTypes.WORK_TYPE_INSTALMENT, text: 'Maksuerät' }
]
