import {
  ActionCreatorWithPayload,
  createEntityAdapter,
  PayloadAction
} from '@reduxjs/toolkit'

import { SalaryCalculationModel } from '../api/salaryPeriodApi'
import { createGenericSlice } from '../helpers/sliceHelpers'
import { EveliaRootState } from '../reducerTypes'

const salaryCalculationAdapter = createEntityAdapter<SalaryCalculationModel>({})

const salaryCalculationSlice = createGenericSlice({
  name: 'salaryCalculations',
  entityAdapter: salaryCalculationAdapter,
  reducers: {
    doPatchAction: (state, __action: PayloadAction<{ record: Partial<SalaryCalculationModel> }>) => state
  }
})

export const salaryCalculationEntitySelectors = salaryCalculationAdapter.getSelectors<EveliaRootState>(state => state.salaryPeriods.salaryCalculations)
export const salaryCalculationActions = salaryCalculationSlice.actions as typeof salaryCalculationSlice.actions & {
  doPatchAction: ActionCreatorWithPayload<{ record: SalaryCalculationModel }>
}

export default salaryCalculationSlice
