import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import fileActions from '../actions/fileActions'
import reportActions from '../actions/reportActions'
import { fetchReport, generateReport } from '../api/reportApi'
import { actionTypes } from '../constants'
import { genericSagaErrorHandler, getPromiseHandlersFromData } from '../helpers/sagaHelpers'

function* reportFetchFlow({ data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(reportActions.fetchStart(data))
    const report = yield call(fetchReport, data)
    yield put(reportActions.fetchSuccess(report, data))
    resolve(report)
  } catch(err) {
    yield put(reportActions.generateError(err, data))
    yield * genericSagaErrorHandler(err, 'Virhe raportin noutamisessa', reject)
  }
}

function* reportExportFileDataFlow({ data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    const report = yield call(fetchReport, data)
    resolve(report)
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe tiedoston generoimisessa', reject)
  }
}

function* reportGenerateFlow({ data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(reportActions.generateStart(data))
    const fileData = yield call(generateReport, data)
    yield put(reportActions.generateSuccess(data))
    if(!data.ignoreFetchSuccess) {
      yield put(fileActions.fetchSuccess([fileData], data))
    }
    resolve(fileData)
  } catch(err) {
    yield put(reportActions.generateError(err, data))
    yield * genericSagaErrorHandler(err, 'Virhe raportin luomisessa', reject)
  }
}

export default function* reportSaga() {
  yield takeEvery(actionTypes.REPORTS_FETCH_REQUEST, reportFetchFlow)
  yield takeEvery(actionTypes.REPORTS_GENERATE_REQUEST, reportGenerateFlow)
  yield takeLatest(actionTypes.REPORTS_GENERATE_REPORT_EXPORT_REQUEST, reportExportFileDataFlow)
}
