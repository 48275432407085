import constants from '../constants'
import { getCombinedListReducerFor, getTableOptions } from '../helpers/reducerHelpers'
import tableReducerFor from './tableReducer'

const initialCostCentreTableOptions = {
  default: getTableOptions()
}

export default getCombinedListReducerFor(constants.actionKeys.COST_CENTRES, {
  costCentreGroups: getCombinedListReducerFor(constants.actionKeys.COST_CENTRE_GROUPS),
  tableOptions: tableReducerFor(constants.actionKeys.COST_CENTRES, initialCostCentreTableOptions)
})
