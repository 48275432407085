import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import {
  faBullhorn,
  faCog,
  faCogs,
  faFile,
  faLaptopMedical,
  faLink,
  faSignOutAlt,
  faUserEdit,
  faWallet
} from '@fortawesome/free-solid-svg-icons'
import { faRectangleList } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import loginActions from '../../actions/loginActions'
import Feature from '../../components/Authentication/Feature'
import { actionKeys, routePaths } from '../../constants'
import useActions from '../../hooks/useActions'

const dropdownItems = [
  {
    to: routePaths.PATH_SETTINGS,
    tag: Link,
    icon: faCogs,
    title: 'Asetukset',
    featureKey: actionKeys.SETTINGS
  },
  {
    to: routePaths.PATH_USER_ACCOUNT,
    tag: Link,
    icon: faUserEdit,
    title: 'Omat asetukset'
  },
  {
    to: routePaths.PATH_FILES,
    tag: Link,
    icon: faFile,
    title: 'Tiedostot'
  },
  {
    to: routePaths.PATH_CREDIT_ACTIONS,
    tag: Link,
    icon: faWallet,
    title: 'Maksutiedot',
    featureKey: actionKeys.FINANCE
  },
  {
    to: routePaths.PATH_FORM_TEMPLATES,
    tag: Link,
    icon: faRectangleList,
    title: 'Lomakepohjat',
    featureKey: actionKeys.FORM_TEMPLATES
  },
  {
    key: 'divider-misc',
    divider: true
  },
  {
    to: routePaths.PATH_REGISTER,
    tag: Link,
    icon: faLink,
    title: 'Kytke yritykseen'
  },
  {
    to: routePaths.PATH_INVITATIONS,
    tag: Link,
    icon: faBullhorn,
    title: 'Suosittele Eveliaa'
  },
  {
    key: 'anydesk',
    href: 'https://anydesk.com/en/downloads',
    target: '_blank',
    rel: 'noopener noreferrer',
    tag: 'a',
    icon: faLaptopMedical,
    title: 'Etätuki|Anydesk'
  },
  {
    key: 'divider-logout',
    divider: true
  }
]

const renderDropdownItem = ({
  key,
  to,
  icon,
  title,
  featureKey,
  ...itemProps
}) => (
  <Feature key={key || to} featureKey={featureKey}>
    <DropdownItem to={to} {...itemProps}>
      {icon &&
        <FontAwesomeIcon fixedWidth icon={icon} className='brand-primary me-1' />}
      {title}
    </DropdownItem>
  </Feature>
)

const componentActions = {
  doLogout: loginActions.logoutRequest
}

const HeaderDropdown = ({ isActive }) => {
  const actions = useActions(componentActions)
  return (
    <UncontrolledDropdown tag='span' className='d-inline-block' data-testid='settings-menu' nav>
      <DropdownToggle outline color='primary'>
        <FontAwesomeIcon className='settings-icon' icon={faCog} />
      </DropdownToggle>
      <DropdownMenu className='mt-1'>
        {isActive
          ? dropdownItems.map(renderDropdownItem)
          : null}
        <DropdownItem
          key='logout'
          onClick={actions.doLogout}
        >
          <span className='brand-primary'>
            <FontAwesomeIcon
              icon={faSignOutAlt}
              fixedWidth
            />
            {' '}
          </span> Kirjaudu ulos
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}
HeaderDropdown.propTypes = {
  isActive: PropTypes.bool
}

export default HeaderDropdown
