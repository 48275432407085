import FormInputGroup from './FormInputGroup'
import NumberFormInput from './inputs/NumberFormInput'
import { commonFormInputDefaultProps, commonFormInputPropTypes } from './propTypes'

const CurrencyFormGroup = props => (
  <FormInputGroup
    {...commonFormInputDefaultProps}
    {...props}
    decimals={2}
    appendComponent='€'
    inputComponent={NumberFormInput}
  />
)
CurrencyFormGroup.propTypes = commonFormInputPropTypes

export default CurrencyFormGroup
