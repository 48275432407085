export const emailQueryTerms = ['receivers*', 'cc*', 'bcc*', 'subject', 'message', 'processType']
export const optionsQueryTerms = ['q', 'limit', 'page', 'orderBy', 'sortOrder', 'filters*', 'search', 'recordIds*', 'startDate', 'endDate', 'employeeId', 'noLimit']

export const queryTermsToTemplate = queryTerms => `{?${queryTerms.join(',')}}`

export const optionsQueryBase = queryTermsToTemplate(optionsQueryTerms)
export const queryWildcard = '{?query*}'

const filterEmptySearch = search => {
  if(!search || Object.keys(search).length === 0) {
    return undefined
  }
  const filteredSearch = Object.fromEntries(Object.entries(search).filter(([__key, value]) => value != null))
  return Object.keys(filteredSearch).length ? JSON.stringify(filteredSearch) : undefined
}

export const expandWithSearch = (urlTemplate, params) =>
  urlTemplate.expand({
    ...params,
    search: filterEmptySearch(params.search || null)
  })
