import { emptyArray, purchaseOrderRowTypes } from '@evelia/helpers/constants'
import { Action, ThunkDispatch } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'
import { ValueOf } from 'type-fest'

import offerActions from '../../actions/offerActions'
import productActions from '../../actions/productActions'
import supplierActions from '../../actions/supplierActions'
import supplyOfferActions from '../../actions/supplyOfferActions'
import workActions from '../../actions/workActions'
import { defaultEmbeddedNormalizer } from '../../helpers/apiHelpers'
import { getSocket } from '../../socket'
import { BaseIdModel } from '../types/baseModelTypes'
import { getBaseQuery } from './apiHelpers'
import { createNotification } from './createCRUDApi'
import { ApiRecordResponse, ApiResponse, EmbeddedModel } from './types/api'

export type PurchaseOrderDeliveryModel = {
  date: string
  count: number
  inboundInvoiceId: number
  inboundInvoiceRowId: number
}

export type PurchaseOrderRowModel = {
  id: number
  supplierId: number | null
  productId: number | null
  workId: number | null
  workRowId: number | null
  offerId: number | null
  offerPostId: number | null
  offerPostRowId: number | null
  supplyOfferId: number | null
  supplyOfferRowId: number | null
  name: string | null
  purchaseOrderId: number
  createdBy: number | null
  count: number | null
  price: number | null
  discount: number | null
  comment: string | null
  unit: string | null
  nameExtension: string | null
  productNumber: string | null
  confirmedAt: string | null
  confirmedPrice: number | null
  partialDelivery: boolean
  productLineCode: string | null
  isListPriceCheaper: boolean
  deliveries: PurchaseOrderDeliveryModel[]
  type: ValueOf<typeof purchaseOrderRowTypes>
} & BaseIdModel

type CreateManyPurchaseOrderRowModel = Partial<PurchaseOrderRowModel> & Pick<PurchaseOrderRowModel, 'type'>

const handleDispatchEmbeddedData = (dispatch: ThunkDispatch<unknown, unknown, Action>, embedded: EmbeddedModel) => {
  if(embedded.products?.length) dispatch(productActions.fetchSuccess(embedded.products))
  if(embedded.suppliers?.length) dispatch(supplierActions.fetchSuccess(embedded.suppliers))
  if(embedded.work?.records?.length) dispatch(workActions.fetchSuccess(embedded.work.records))
  if(embedded.workRows?.length) dispatch(workActions.workRows.fetchSuccess(embedded.workRows))
  if(embedded.offers?.length) dispatch(offerActions.fetchSuccess(embedded.offers))
  if(embedded.offerPosts?.length) dispatch(offerActions.posts.fetchSuccess(embedded.offerPosts))
  if(embedded.offerPostRows?.length) dispatch(offerActions.postRows.fetchSuccess(embedded.offerPostRows))
  if(embedded.supplyOffers?.length) dispatch(supplyOfferActions.fetchSuccess(embedded.supplyOffers))
  if(embedded.supplyOfferRows?.length) dispatch(supplyOfferActions.supplyOfferRows.fetchSuccess(embedded.supplyOfferRows))
}

export const purchaseOrderRowApi = createApi({
  reducerPath: 'purchaseOrderRowApi',
  baseQuery: getBaseQuery('purchase_orders'),
  endpoints: builder => ({
    getPurchaseOrderRows: builder.query<ApiResponse<PurchaseOrderRowModel>, BaseIdModel>({
      query: ({ id: purchaseOrderId }) => `/${purchaseOrderId}/purchase_order_rows`,
      onQueryStarted: async(__args, { dispatch, queryFulfilled }) => {
        const result = await queryFulfilled
        const { _data, ...embedded } = defaultEmbeddedNormalizer(result ?? {})
        if(embedded) {
          handleDispatchEmbeddedData(dispatch, embedded)
        }
      },
      onCacheEntryAdded: async(queryArgs, {
        updateCachedData,
        cacheDataLoaded,
        cacheEntryRemoved,
        getCacheEntry,
        dispatch
      }) => {
        const socket = getSocket()
        try {
          await cacheDataLoaded
          socket.on('purchaseOrderRow:created', (_channel: string, data: ApiRecordResponse<PurchaseOrderRowModel>) => {
            if(data.record.purchaseOrderId !== queryArgs.id) {
              return
            }
            updateCachedData(draft => {
              draft.records.push(data.record)
            })
            handleDispatchEmbeddedData(dispatch, data._embedded)
          })
          socket.on('purchaseOrderRow:updated', (_channel: string, data: ApiRecordResponse<PurchaseOrderRowModel>) => {
            if(data.record.purchaseOrderId !== queryArgs.id) {
              return
            }
            updateCachedData(draft => {
              const index = draft.records.findIndex(record => record.id === data.record.id)
              draft.records[index] = data.record
            })
            handleDispatchEmbeddedData(dispatch, data._embedded)
          })
          socket.on('purchaseOrderRow:deleted', (_channel: string, { id }: BaseIdModel) => {
            // Returned id is PurchaseOrderRow.id so we can't filter based on equality of purchaseOrderId = queryArgs.id
            const { data } = getCacheEntry()
            if(!data?.records?.some(row => row.id === id)) {
              return
            }
            updateCachedData(draft => {
              draft.records = draft.records.filter(record => record.id !== id)
            })
          })
        } catch{}
        await cacheEntryRemoved
        socket.off('purchaseOrderRow:updated')
        socket.off('purchaseOrderRow:created')
        socket.off('purchaseOrderRow:deleted')
      }
    }),
    createPurchaseOrderRow: builder.mutation<ApiRecordResponse<PurchaseOrderRowModel>, Omit<PurchaseOrderRowModel, 'id'>>({
      query: body => ({
        url: `/${body.purchaseOrderId}/purchase_order_rows`,
        method: 'POST',
        body
      }),
      onQueryStarted: async(__args, { queryFulfilled }) => createNotification(queryFulfilled, {
        successMessage: 'Ostotilausrivi luotu',
        errorMessage: 'Virhe ostotilausrivin luonnissa'
      })
    }),
    createManyPurchaseOrderRows: builder.mutation<ApiResponse<PurchaseOrderRowModel>, { purchaseOrderId: number, purchaseOrderRows: CreateManyPurchaseOrderRowModel[] }>({
      query: body => ({
        url: `/${body.purchaseOrderId}/purchase_order_rows/bulk`,
        method: 'POST',
        body: body.purchaseOrderRows
      }),
      onQueryStarted: async(__args, { queryFulfilled }) => createNotification(queryFulfilled, {
        successMessage: 'Ostotilausrivit luotu',
        errorMessage: 'Virhe ostotilausrivien luonnissa'
      })
    }),
    updatePurchaseOrderRow: builder.mutation<ApiRecordResponse<PurchaseOrderRowModel>, PurchaseOrderRowModel>({
      query: body => ({
        url: `/${body.purchaseOrderId}/purchase_order_rows/${body.id}`,
        method: 'PUT',
        body
      }),
      onQueryStarted: async(__args, { queryFulfilled }) => createNotification(queryFulfilled, {
        successMessage: 'Ostotilausrivi päivitetty',
        errorMessage: 'Virhe ostotilausrivin päivityksessä'
      })
    }),
    deletePurchaseOrderRow: builder.mutation<BaseIdModel, PurchaseOrderRowModel>({
      query: body => ({
        url: `/${body.purchaseOrderId}/purchase_order_rows/${body.id}`,
        method: 'DELETE'
      }),
      onQueryStarted: async(__args, { queryFulfilled }) => createNotification(queryFulfilled, {
        successMessage: 'Ostotilausrivi poistettu',
        errorMessage: 'Virhe ostotilausrivin poistossa'
      })
    }),
    updatePurchaseOrderRowPrices: builder.mutation<ApiResponse<PurchaseOrderRowModel>, { purchaseOrderId: number, supplierId: number }>({
      query: ({ purchaseOrderId, supplierId }) => ({
        url: `/${purchaseOrderId}/purchase_order_rows/update_prices`,
        method: 'PATCH',
        body: { supplierId }
      }),
      onQueryStarted: async(__args, { queryFulfilled }) => createNotification(queryFulfilled, {
        successMessage: 'Ostotilausrivien hinnat päivitetty',
        errorMessage: 'Virhe ostotilausrivien hintojen päivityksessä'
      })
    })
  })
})

export const {
  useGetPurchaseOrderRowsQuery,
  useCreateManyPurchaseOrderRowsMutation,
  useUpdatePurchaseOrderRowPricesMutation
} = purchaseOrderRowApi

export const usePurchaseOrderRowMutations = () => {
  const [save] = purchaseOrderRowApi.useCreatePurchaseOrderRowMutation()
  const [update] = purchaseOrderRowApi.useUpdatePurchaseOrderRowMutation()
  const [remove] = purchaseOrderRowApi.useDeletePurchaseOrderRowMutation()
  return { save, update, remove }
}

export const usePurchaseOrderRows = (id: number | null): Omit<ReturnType<typeof useGetPurchaseOrderRowsQuery>, 'data'> & { data: PurchaseOrderRowModel[] } => {
  // 'id: id as number' because even thought technically correct that id can be null, TS is unable to understand that we skip those cases
  return useGetPurchaseOrderRowsQuery({ id: id as number }, {
    skip: !id,
    selectFromResult: response => {
      return {
        ...response,
        data: response?.data?.records || emptyArray
      }
    }
  })
}
