import { parseTemplate } from 'url-template'

import {
  del,
  get,
  patch,
  post,
  put
} from '../helpers/apiHelpers'

const baseUrl = '/api/system_customers/work_record_products{/workRecordProductId}{/action}{?onlyEnabled,pricingRuleSetId}'
const workRecordProductUrlTemplate = parseTemplate(baseUrl)
const workRecordProductSubItemUrlTemplate = parseTemplate(`${baseUrl}{/subItem}{/subItemId}`)

export const fetchWorkRecordProducts = (params = {}) => {
  return get(workRecordProductUrlTemplate.expand(params))
    .then(parseWorkRecordProductFetchResponse)
}

export const updateWorkRecordProduct = workRecordProduct => {
  return put(workRecordProductUrlTemplate.expand({ workRecordProductId: workRecordProduct.id }), {
    body: JSON.stringify(workRecordProduct)
  })
}

export const createWorkRecordProduct = workRecordProduct => {
  return post(workRecordProductUrlTemplate.expand({}), {
    body: JSON.stringify(workRecordProduct)
  })
}

export const deleteWorkRecordProduct = workRecordProduct => {
  return del(workRecordProductUrlTemplate.expand({ workRecordProductId: workRecordProduct.id }))
}

const parseWorkRecordProductFetchResponse = response => response

export const fetchWorkRecordProductSalaryTypes = (params = {}) => {
  return get(workRecordProductSubItemUrlTemplate.expand({ ...params, subItem: 'salary_types' }))
    .then(parseWorkRecordProductSalaryTypeFetchResponse)
}

export const updateWorkRecordProductSalaryType = (salaryType, params) => {
  return put(workRecordProductSubItemUrlTemplate.expand({ ...params, subItem: 'salary_types', subItemId: salaryType.salaryTypeId }), {
    body: JSON.stringify(salaryType)
  })
}

export const createWorkRecordProductSalaryType = (salaryType, params) => {
  return post(workRecordProductSubItemUrlTemplate.expand({ ...params, subItem: 'salary_types' }), {
    body: JSON.stringify(salaryType)
  })
}

export const deleteWorkRecordProductSalaryType = (salaryType, params) => {
  return del(workRecordProductSubItemUrlTemplate.expand({ ...params, subItem: 'salary_types', subItemId: salaryType.salaryTypeId }))
}

const parseWorkRecordProductSalaryTypeFetchResponse = response => response

export const fetchWorkRecordProductPricingRules = (params = {}) => {
  return get(workRecordProductSubItemUrlTemplate.expand({ ...params, subItem: 'pricing_rule_sets' }))
    .then(normalizeWorkRecordProductPricingRuleResponse)
}

export const updateWorkRecordProductPricingRule = (pricingRule, params) => {
  return put(workRecordProductSubItemUrlTemplate.expand({ ...params, subItem: 'pricing_rule_sets', subItemId: pricingRule.pricingRuleSetId }), {
    body: JSON.stringify(pricingRule)
  }).then(normalizeWorkRecordProductPricingRuleResponse)
}

export const createWorkRecordProductPricingRule = (pricingRule, params) => {
  return post(workRecordProductSubItemUrlTemplate.expand({ ...params, subItem: 'pricing_rule_sets' }), {
    body: JSON.stringify(pricingRule)
  }).then(normalizeWorkRecordProductPricingRuleResponse)
}

export const deleteWorkRecordProductPricingRule = (pricingRule, params) => {
  return del(workRecordProductSubItemUrlTemplate.expand({ ...params, subItem: 'pricing_rule_sets', subItemId: pricingRule.pricingRuleSetId }))
}

export const normalizeWorkRecordProductPricingRuleResponse = response => ({ data: response.record || response.records })

export const fetchWorkRecordProductStats = ({ workRecordProductId }) =>
  get(workRecordProductSubItemUrlTemplate.expand({ workRecordProductId, subItem: 'stats' }))

export const doWorkRecordProductPatchAction = (data, { workRecordProductId, action } = {}) =>
  patch(workRecordProductUrlTemplate.expand({ workRecordProductId, action }), {
    body: JSON.stringify(data || {})
  }).then(normalizeWorkRecordProductList)

const normalizeWorkRecordProductList = response => {
  return {
    data: response
  }
}
