import { get } from '../helpers/apiHelpers'

export const normalizeWhoAmI = response => {
  const { _embedded, ...whoAmI } = response
  return {
    ..._embedded,
    whoAmI
  }
}

export const requestUrls = {
  fetchWhoAmI: '/api/'
}

export const fetchWhoAmI = () => {
  return get(requestUrls.fetchWhoAmI)
    .then(normalizeWhoAmI)
}
