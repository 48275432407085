import PropTypes from 'prop-types'

import PlainTextFormInput from './inputs/PlainTextFormInput'
import PlainFormGroup from './PlainFormGroup'
import { commonFormGroupPropTypes } from './propTypes'

const PlainTextFormGroup = ({
  label,
  helpText,
  className,
  field,
  validationErrors,
  value,
  model,
  testId
}) => {
  return (
    <PlainFormGroup
      renderInput={value => <PlainTextFormInput value={value} testId={testId || field} />}
      label={label}
      helpText={helpText}
      className={className}
      field={field}
      validationErrors={validationErrors}
      value={value}
      model={model}
    />
  )
}

PlainTextFormGroup.propTypes = {
  label: commonFormGroupPropTypes.label,
  helpText: commonFormGroupPropTypes.helpText,
  className: commonFormGroupPropTypes.className,
  field: commonFormGroupPropTypes.field,
  validationErrors: commonFormGroupPropTypes.validationErrors,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  model: PropTypes.object,
  testId: PropTypes.string
}

export default PlainTextFormGroup
