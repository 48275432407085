import { parseTemplate } from 'url-template'

import { del, post, put } from '../helpers/apiHelpers'
import { normalizeWhoAmI } from './whoAmIApi'

const linkUrlTemplate = parseTemplate('/api/link{/cuid}')
const pwResetUrlTemplate = parseTemplate('/api/pwreset{/cuid}')

export function doLogin(loginInfo) {
  const requestURL = '/api/login'
  return post(requestURL, {
    body: JSON.stringify(loginInfo)
  }).then(normalizeWhoAmI)
}

export function doLogout() {
  const requestURL = '/api/login'
  return del(requestURL)
}

export const doRegistration = registrationInfo => {
  const requestURL = '/api/register'
  return post(requestURL, {
    body: JSON.stringify(registrationInfo)
  })
}

export const switchSystemCustomer = data => {
  const requestURL = '/api/switch'
  return post(requestURL, {
    body: JSON.stringify(data)
  }).then(normalizeWhoAmI)
}

export const linkSystemCustomer = data => {
  const requestURL = linkUrlTemplate.expand(data)
  return post(requestURL, {
    body: JSON.stringify({})
  }).then(normalizeWhoAmI)
}

export const resetPwRequest = data => {
  const requestURL = pwResetUrlTemplate.expand({})
  return post(requestURL, {
    body: JSON.stringify(data)
  })
}

export const resetPw = data => {
  const requestURL = pwResetUrlTemplate.expand(data)
  return put(requestURL, {
    body: JSON.stringify(data)
  })
}
