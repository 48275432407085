import { projectStates } from '@evelia/helpers/constants'

import projectActions from '../actions/projectActions'
import constants from '../constants'
import { getCombinedListReducerFor, getExtraInfoReducer, getTableOptions } from '../helpers/reducerHelpers'
import tableReducerFor from './tableReducer'

export const initialProjectTableOptions = {
  default: getTableOptions({ filters: { rootOnly: true, state: [projectStates.PROJECT_STATE_CREATED, projectStates.PROJECT_STATE_STARTED] } }),
  list: getTableOptions({ filters: { rootOnly: true, state: [projectStates.PROJECT_STATE_CREATED, projectStates.PROJECT_STATE_STARTED] } }),
  extraInfo: getTableOptions({ filters: { rootOnly: true, state: [projectStates.PROJECT_STATE_CREATED, projectStates.PROJECT_STATE_STARTED] }, showExtraInfo: true })
}

export default getCombinedListReducerFor(constants.actionKeys.PROJECTS, {
  additionalPersons: getCombinedListReducerFor(constants.actionKeys.PROJECT_ADDITIONAL_PERSONS),
  employees: getCombinedListReducerFor(constants.actionKeys.PROJECT_EMPLOYEES),
  contacts: getCombinedListReducerFor(constants.actionKeys.PROJECT_CONTACTS),
  files: getCombinedListReducerFor(constants.actionKeys.PROJECT_FILES),
  memos: getCombinedListReducerFor(constants.actionKeys.PROJECT_MEMOS),
  invoices: getCombinedListReducerFor(constants.actionKeys.PROJECT_INVOICES),
  inboundInvoices: getCombinedListReducerFor(constants.actionKeys.PROJECT_INBOUND_INVOICES),
  projectExtraExpenses: getCombinedListReducerFor(constants.actionKeys.PROJECT_EXTRA_EXPENSES),
  projectExtraBudgets: getCombinedListReducerFor(constants.actionKeys.PROJECT_EXTRA_BUDGETS),
  projectDenominationBudgets: getCombinedListReducerFor(constants.actionKeys.PROJECT_DENOMINATION_BUDGETS),
  budgets: getCombinedListReducerFor(constants.actionKeys.PROJECTS_BUDGET, {}, '_id'),
  budgetsByDenomination: getCombinedListReducerFor(constants.actionKeys.PROJECTS_BUDGET_BY_DENOMINATION, {}, '_id'),
  budgetsRecursive: getCombinedListReducerFor(constants.actionKeys.PROJECTS_BUDGET_RECURSIVE, {}, '_id'),
  tableOptions: tableReducerFor(constants.actionKeys.PROJECTS, initialProjectTableOptions),
  extraInfo: getExtraInfoReducer(projectActions, 'projectId')
})
