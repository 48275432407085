import targetActions from '../actions/targetActions'
import constants from '../constants'
import { getCombinedListReducerFor, getStatsReducer, getTableOptions } from '../helpers/reducerHelpers'
import tableReducerFor from './tableReducer'

export const initialTargetTableOptions = {
  default: getTableOptions({ filters: { rootOnly: true } })
}

const initialStats = {
  targetId: 0,
  contactCount: 0,
  fileCount: 0,
  invoiceCount: 0,
  memoCount: 0,
  subTargetCount: 0,
  workCount: 0,
  supplyOfferCount: 0,
  isBusy: false
}

export default getCombinedListReducerFor(constants.actionKeys.TARGETS, {
  contacts: getCombinedListReducerFor(constants.actionKeys.TARGET_CONTACTS),
  files: getCombinedListReducerFor(constants.actionKeys.TARGET_FILES),
  memos: getCombinedListReducerFor(constants.actionKeys.TARGET_MEMOS),
  tableOptions: tableReducerFor(constants.actionKeys.TARGETS, initialTargetTableOptions),
  stats: getStatsReducer(targetActions, initialStats)
})
