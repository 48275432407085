import constants, { ticketTypes } from '../constants'
import { getCombinedListReducerFor, getTableOptions } from '../helpers/reducerHelpers'
import tableReducerFor from './tableReducer'

export const initialTicketTableOptions = {
  default: getTableOptions({ fixedFilters: { type: ticketTypes.TICKET } }),
  own: getTableOptions({ fixedFilters: { isOwn: true, type: ticketTypes.TICKET } }),
  recurringTickets: getTableOptions({ fixedFilters: { type: ticketTypes.TICKET_TEMPLATE, isRecurring: true } })
}

export default getCombinedListReducerFor(constants.actionKeys.TICKETS, {
  memos: getCombinedListReducerFor(constants.actionKeys.TICKET_MEMOS),
  employees: getCombinedListReducerFor(constants.actionKeys.TICKET_EMPLOYEES),
  files: getCombinedListReducerFor(constants.actionKeys.TICKET_FILES),
  tableOptions: tableReducerFor(constants.actionKeys.TICKETS, initialTicketTableOptions)
})
