import {
  faEuroSign,
  faFile
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const FileInvoiceEur = props => (
  <FontAwesomeIcon {...props} icon={faEuroSign} mask={faFile} transform='shrink-8 down-2' />
)

export default FileInvoiceEur
