import constants from '../constants'
import { getCombinedListReducerFor, getTableOptions } from '../helpers/reducerHelpers'
import tableReducerFor from './tableReducer'

const initialDenominationTableOptions = {
  default: getTableOptions()
}

export default getCombinedListReducerFor(constants.actionKeys.DENOMINATIONS, {
  denominationPackets: getCombinedListReducerFor(constants.actionKeys.DENOMINATION_PACKETS),
  denominationGroups: getCombinedListReducerFor(constants.actionKeys.DENOMINATION_GROUPS),
  tableOptions: tableReducerFor(constants.actionKeys.DENOMINATIONS, initialDenominationTableOptions)
})
