import { createApi, getNormalizers } from '../helpers/apiHelpers'

const normalizers = getNormalizers('employees', true)
export const normalizeTicketEmployeeResponse = normalizers.normalizeResponse

const editGetParams = (ticketId, payload) =>
  ({ id: ticketId, subItem: 'employees', subItemId: payload?.id })

const editMutateParams = ({ id, ticketId }, payload) =>
  ({ id: ticketId || payload.ticketId, subItem: 'employees', subItemId: id })

export const ticketEmployeeApi = createApi({
  base: 'tickets',
  normalizer: normalizers.normalizeResponse,
  editGetParams,
  editMutateParams
})
