export const tableLocalizationMessages = {
  noData: 'Ei rivejä'
}

export const searchLocalizationMessages = {
  searchPlaceholder: 'Etsi...'
}

export const totalLocalizationMessages = {
  sum: 'Yht',
  customPercent: '%'
}

export const exportLocalizationMessages = {
  showExportMenu: 'Lataa tiedostona',
  exportAll: 'Lataa tiedostona',
  exportSelected: 'Lataa valitut'
}
