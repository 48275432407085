import { all, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { formTemplateActions } from '../actions/formActions'
import { formTemplateApi } from '../api/formApi'
import { normalizeReceipts } from '../api/receiptApi'
import {
  createFlow,
  createSocketWatcherWithApiHandlerAndNormalizer,
  deleteFlow,
  fetchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

const handleFormTemplateApiResponse = mainAction =>
  function* ({ data }) {
    yield put(mainAction(data))
    return data
  }

const watchOnFormTemplateSockets = createSocketWatcherWithApiHandlerAndNormalizer('formTemplate', formTemplateActions, handleFormTemplateApiResponse, normalizeReceipts)

const formTemplateFetchFlow = fetchFlow({
  fetchApi: formTemplateApi.fetch,
  actions: formTemplateActions,
  base: 'formTemplates',
  errorMsg: 'Lomakepohjien',
  apiResponseHandler: handleFormTemplateApiResponse(formTemplateActions.fetchSuccess)
})

const formTemplateUpdateFlow = updateFlow(formTemplateApi.update, formTemplateActions, 'Lomakepohja', 'Lomakepohjan', handleFormTemplateApiResponse(formTemplateActions.updateSuccess))
const formTemplateCreateFlow = createFlow(formTemplateApi.create, formTemplateActions, 'Lomakepohja', 'Lomakepohjan', handleFormTemplateApiResponse(formTemplateActions.createSuccess))
const formTemplateDeleteFlow = deleteFlow({
  deleteApi: formTemplateApi.remove,
  actions: formTemplateActions,
  singular: 'Lomakepohja',
  errorMsg: 'Lomakepohjan',
  base: 'formTemplates'
})

export default function* formSaga() {
  yield takeLatest(formTemplateActions.actionTypes.fetchRequest, formTemplateFetchFlow)
  yield takeEvery(formTemplateActions.actionTypes.updateRequest, formTemplateUpdateFlow)
  yield takeEvery(formTemplateActions.actionTypes.createRequest, formTemplateCreateFlow)
  yield takeEvery(formTemplateActions.actionTypes.deleteRequest, formTemplateDeleteFlow)
  yield all([
    watchOnFormTemplateSockets()
  ])
}
