import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Feature from '../../components/Authentication/Feature'
import constants from '../../constants'
import ContactResults from './ContactResults'
import CustomerResults from './CustomerResults'
import InboundInvoiceResults from './InboundInvoiceResults'
import InvoiceResults from './InvoiceResults'
import ProductResults from './ProductResults'
import TargetResults from './TargetResults'
import WorkResults from './WorkResults'

const { actionKeys } = constants

const resultRenderers = [
  { Results: CustomerResults, featureKey: actionKeys.CUSTOMERS },
  { Results: TargetResults, featureKey: actionKeys.TARGETS },
  { Results: ProductResults, featureKey: actionKeys.PRODUCTS },
  { Results: WorkResults, featureKey: actionKeys.WORK },
  { Results: InvoiceResults, featureKey: actionKeys.INVOICES },
  { Results: InboundInvoiceResults, featureKey: actionKeys.INBOUND_INVOICES },
  { Results: ContactResults, featureKey: actionKeys.CONTACTS }
]

const SuperSearchResults = ({ isOpen, close }) => {
  if(!isOpen) {
    return null
  }
  const renderResults = ({ Results, featureKey }) => (
    <Feature key={featureKey} featureKey={featureKey}>
      <Results closeResults={close} />
    </Feature>
  )

  return (
    <div className='supersearch-results rounded shadow'>
      <div className='d-flex justify-content-between'>
        <span>
          <FontAwesomeIcon icon={faSearch} />
          <span className='h5' style={{ verticalAlign: 'top' }}> Hakutulokset</span>
        </span>
        <Button close onClick={close} />
      </div>
      {resultRenderers.map(renderResults)}
    </div>
  )
}

SuperSearchResults.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func.isRequired
}

export default SuperSearchResults
