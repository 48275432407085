import { Component } from 'react'
import { connect } from 'react-redux'

import invoiceActions from '../../actions/invoiceActions'
import { renderPostalSearchItemInline } from '../../components/Helpers/searchResultRenderers'
import { getInvoicesFromSearchResult } from '../../selectors/invoiceSelectors'
import InvoiceStatusBadge from '../InvoicePage/InvoiceStatusBadge'
import SearchResults, { searchResultsPropType } from './SearchResults'

const appendComponent = invoice => <span> <InvoiceStatusBadge invoice={invoice} /></span>

class InvoiceResults extends Component {
  renderInvoice = invoice =>
    renderPostalSearchItemInline(
      {
        ...invoice,
        name: invoice.work?.name,
        address: invoice.target?.address || invoice.customer?.address,
        postalCode: invoice.target?.postalCode || invoice.customer?.postalCode,
        city: invoice.target?.city || invoice.customer?.city
      },
      'invoiceNumber',
      { appendComponent }
    )

  render() {
    return (
      <SearchResults
        title='Laskut'
        renderResult={this.renderInvoice}
        {...this.props}
      />
    )
  }

  static propTypes = searchResultsPropType
}

const mapStateToProps = state => {
  return {
    results: getInvoicesFromSearchResult(state),
    isBusy: state.invoices.search.isBusy
  }
}

const mapDispatchToProps = dispatch => {
  return {
    navigateTo: invoiceId => dispatch(invoiceActions.navigateTo(invoiceId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceResults)
