import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { deepEqual } from 'fast-equals'
import memoize from 'micro-memoize'

import TypeaheadFormGroup from './TypeaheadFormGroup'

class IdTagFormGroup extends PureComponent {
  getModel = memoize((value, options) => {
    const {
      field,
      idField
    } = this.props
    return {
      [field]: (value || []).map(id => options.find(option => option[idField] === id)).filter(Boolean)
    }
  }, { isEqual: deepEqual })

  render() {
    const {
      model,
      field,
      options
    } = this.props
    return (
      <TypeaheadFormGroup
        multiple
        {...this.props}
        selectWithEnter
        options={options}
        allowNew={false}
        model={this.getModel(model[field], options)}
        handleOnChange={this.handleSelect}
      />
    )
  }

  handleSelect = selected => {
    if(this.props.handleOnChange) {
      return this.props.handleOnChange(selected)
    }
    return selected.map(s => s[this.props.idField])
  }

  static propTypes = {
    field: PropTypes.string.isRequired,
    model: PropTypes.object.isRequired,
    options: PropTypes.array,
    idField: PropTypes.string,
    handleOnChange: PropTypes.func
  }

  static defaultProps = {
    idField: 'id',
    options: []
  }
}

export default IdTagFormGroup
