import { put, takeEvery } from 'redux-saga/effects'

import {
  insuranceApi
} from '../api/insuranceApi'
import { createFlow, fetchFlow, updateFlow } from '../helpers/sagaHelpers'
import { insuranceCompanyActions } from '../slices/insuranceCompanySlice'
import { insuranceActions } from '../slices/insuranceSlice'

export const handleInsuranceApiResponse = mainAction => function* ({ data, insuranceCompanies }) {
  yield put(mainAction(data))
  yield put(insuranceCompanyActions.fetchSuccess(insuranceCompanies))
  return data
}

const insuranceFetchFlow = fetchFlow({
  fetchApi: insuranceApi.fetch,
  actions: insuranceActions,
  base: 'insurances',
  errorMsg: 'vakuutusten',
  apiResponseHandler: handleInsuranceApiResponse(insuranceActions.fetchSuccess)
})

const insuranceCreateFlow = createFlow(insuranceApi.create, insuranceActions, 'Vakuutus', 'Vakuutuksen', handleInsuranceApiResponse(insuranceActions.createSuccess))
const insuranceUpdateFlow = updateFlow(insuranceApi.update, insuranceActions, 'Vakuutus', 'Vakuutuksen', handleInsuranceApiResponse(insuranceActions.updateSuccess))

export default function* insuranceSaga() {
  yield takeEvery(insuranceActions.fetchRequest.type, insuranceFetchFlow)
  yield takeEvery(insuranceActions.createRequest.type, insuranceCreateFlow)
  yield takeEvery(insuranceActions.updateRequest.type, insuranceUpdateFlow)
}
