import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { InputGroup, InputGroupText } from 'reactstrap'
import isFunction from 'lodash/isFunction'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'

import CommonValueFormGroup from './CommonValueFormGroup'

const handleAddon = addon => (isString(addon) || isNumber(addon))
  ? <InputGroupText>{addon}</InputGroupText>
  : (
      isFunction(addon) ? addon() : addon
    )

export class InputGroupInput extends PureComponent {
  render() {
    const { prependComponent, appendComponent, inputComponent: InputComponent, getInputComponents } = this.props
    const { size, inputProps, ...restProps } = this.props
    return (
      <InputGroup size={size}>
        {prependComponent
          ? handleAddon(prependComponent)
          : null}
        {getInputComponents?.() ?? <InputComponent {...restProps} {...inputProps} />}
        {appendComponent
          ? handleAddon(appendComponent)
          : null}
      </InputGroup>
    )
  }

  static propTypes = {
    prependComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    appendComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    inputComponent: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.func
    ]),
    getInputComponents: PropTypes.func,
    size: PropTypes.string,
    inputProps: PropTypes.object
  }
}

const FormInputGroup = props => (<CommonValueFormGroup {...props} forceFeedback Component={InputGroupInput} />)

FormInputGroup.propTypes = {
  field: PropTypes.string.isRequired,
  label: PropTypes.node,
  prependComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  appendComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  inputComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  validationErrors: PropTypes.object
}

export default FormInputGroup
