import { actionTypes } from '../constants'

const reportActions = {
  fetchRequest: function(data) {
    return {
      data,
      type: actionTypes.REPORTS_FETCH_REQUEST
    }
  },
  fetchStart: function(data) {
    return {
      data,
      type: actionTypes.REPORTS_FETCH_START
    }
  },
  fetchSuccess: function(reportData, data) {
    return {
      data,
      reportData,
      type: actionTypes.REPORTS_FETCH_SUCCESS
    }
  },
  fetchError: function(error, data) {
    return {
      data,
      error,
      type: actionTypes.REPORTS_FETCH_ERROR
    }
  },

  generateRequest: function(data) {
    return {
      data,
      type: actionTypes.REPORTS_GENERATE_REQUEST
    }
  },
  generateStart: function(data) {
    return {
      data,
      type: actionTypes.REPORTS_GENERATE_START
    }
  },
  generateSuccess: function(data) {
    return {
      data,
      type: actionTypes.REPORTS_GENERATE_SUCCESS
    }
  },
  generateError: function(error, data) {
    return {
      data,
      error,
      type: actionTypes.REPORTS_GENERATE_ERROR
    }
  },

  generateExportRequest: function(data) {
    return {
      data,
      type: actionTypes.REPORTS_GENERATE_REPORT_EXPORT_REQUEST
    }
  }
}

export default reportActions
