import { parseTemplate } from 'url-template'

import { del, get, getSubEntityApi, post } from '../helpers/apiHelpers'
import { normalizeEmployeeResponse } from './employeeApi'

const baseUrl = '/api/cash_receipts/{cashReceiptId}{?removeWork}'
const cashReceiptUrlTemplate = parseTemplate(baseUrl)
const cashReceiptPdfUrlTemplate = parseTemplate(`${baseUrl}/pdf`)
const cashReceiptRowsUrlTemplate = parseTemplate(`${baseUrl}/cash_receipt_rows`)
const cashReceiptFileUrlTemplate = parseTemplate(`${baseUrl}/files{/cashReceiptFileId}`)

export const normalizeCashReceiptResponse = response => {
  const { _embedded, records, record } = response
  const { cashReceiptRows, customers, employees } = _embedded || {}

  const data = records || record
  return {
    data,
    cashReceiptRows: cashReceiptRows || [],
    customers: customers || [],
    employees: normalizeEmployeeResponse(employees) || []
  }
}

const normalizeCashReceiptRowResponse = response => {
  const { records } = response
  const data = records
  return {
    data
  }
}

export const fetchCashReceipts = (params = {}) =>
  get(cashReceiptUrlTemplate.expand(params))
    .then(normalizeCashReceiptResponse)

export const createCashReceipt = (cashReceipt, { typeParam }) => {
  return post(cashReceiptUrlTemplate.expand({ typeParam }), {
    body: JSON.stringify(cashReceipt)
  }).then(normalizeCashReceiptResponse)
}

export const fetchCashReceiptPdf = cashReceiptId => {
  return get(cashReceiptPdfUrlTemplate.expand({ cashReceiptId }))
}

export const fetchCashReceiptRows = cashReceiptId => {
  return get(cashReceiptRowsUrlTemplate.expand({ cashReceiptId }))
    .then(normalizeCashReceiptRowResponse)
}

export const {
  fetchApi: fetchCashReceiptFiles,
  createApi: createCashReceiptFile,
  deleteApi: deleteCashReceiptFile
} = getSubEntityApi(cashReceiptFileUrlTemplate, 'cashReceiptId', 'fileId', 'cashReceiptFileId', 'file')

export const deleteCashReceipt = (cashReceipt, actionData = {}) =>
  del(cashReceiptUrlTemplate.expand({ cashReceiptId: cashReceipt.id, ...actionData }))
