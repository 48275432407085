import { isSameOrBefore } from '@evelia/helpers/dateHelpers'
import { getDiscountCoefficient, parseSafeNumber, round8decimals } from '@evelia/helpers/helpers'

import { getCombinedVatRateWithId } from '../../selectors/vatSelectors'

export const calculatePriceWithoutVat = (combinedVatRate, vatPrice) => {
  const value = parseSafeNumber(vatPrice) / (1 + (combinedVatRate.rate / 100))
  return parseSafeNumber(value)
}

export const calculatePriceWithVat = (combinedVatRate, price) => {
  const value = parseSafeNumber(price) * (1 + (combinedVatRate.rate / 100))
  return parseSafeNumber(value)
}

export const calculateSum = (price, count, discount) => {
  const value = parseSafeNumber(price) * parseSafeNumber(count) * (1 - parseSafeNumber(discount) / 100)
  return parseSafeNumber(value)
}

export const calculateDiscount = (price, count, priceSum) => {
  const originalPriceSum = parseSafeNumber(price) * parseSafeNumber(count)
  // `originalPriceSum` being zero returns negative infinity
  if(originalPriceSum === 0) {
    return 0
  }
  const value = (1 - parseSafeNumber(priceSum) / originalPriceSum) * 100
  return parseSafeNumber(value)
}

export const calculateGrossProfitPercentage = (grossProfit, purchasePrice, count) => {
  const divider = purchasePrice * count + grossProfit
  if(divider === 0) {
    return 0
  }
  const grossProfitPercentage = (grossProfit / divider) * 100
  if(grossProfitPercentage > 100 || grossProfitPercentage < -100) {
    return null
  }
  return grossProfitPercentage
}
export const calculateGrossProfitFromPrice = (price, purchasePrice, count, discount) => round8decimals((price * count * getDiscountCoefficient(discount)) - (purchasePrice * count))
const calculateGrossProfitFromPercentage = (grossProfitPercentage, purchasePrice, count) => {
  const priceSum = purchasePrice * count
  const priceSumWithGrossProfit = purchasePrice * count / (1 - grossProfitPercentage / 100)
  return round8decimals(priceSumWithGrossProfit - priceSum)
}
const calculatePriceSumFromGrossProfit = (grossProfit, purchasePrice, count) => {
  const sumWithoutDiscount = purchasePrice * count + grossProfit
  return sumWithoutDiscount
}

export const getOnChangePrice = countKey => (setValue, getValues, { combinedVatRates }) => (event, prefix = '') => {
  const { value } = event.target
  const getFieldName = name => `${prefix}${name}`
  const [count, vatCodeId, discount] = getValues([getFieldName(countKey), getFieldName('vatCodeId'), getFieldName('discount')])
  const combinedVatRate = getCombinedVatRateWithId(combinedVatRates, vatCodeId)

  const price = value != null ? parseSafeNumber(value) : null
  const priceSum = calculateSum(price, count, discount)
  const vatPrice = calculatePriceWithVat(combinedVatRate, price)
  const vatPriceSum = calculateSum(vatPrice, count, discount)

  setValue(getFieldName('price'), value, { shouldDirty: true })
  setValue(getFieldName('priceSum'), priceSum)
  setValue(getFieldName('vatPrice'), vatPrice)
  setValue(getFieldName('vatPriceSum'), vatPriceSum)
  return value
}

export const onChangePrice = getOnChangePrice('count')

export const getOnChangeVatPrice = countKey => (setValue, getValues, { combinedVatRates }) => event => {
  const { value } = event.target
  const [count, vatCodeId, discount] = getValues([countKey, 'vatCodeId', 'discount'])
  const combinedVatRate = getCombinedVatRateWithId(combinedVatRates, vatCodeId)

  const vatPrice = value != null ? parseSafeNumber(value) : null
  const vatPriceSum = calculateSum(vatPrice, count, discount)
  const price = calculatePriceWithoutVat(combinedVatRate, vatPrice)
  const priceSum = calculateSum(price, count, discount)

  setValue('vatPrice', value, { shouldDirty: true })
  setValue('vatPriceSum', vatPriceSum)
  setValue('price', price)
  setValue('priceSum', priceSum)
  return value
}

export const onChangeVatPrice = getOnChangeVatPrice('count')

export const onChangePriceSum = (setValue, getValues, { combinedVatRates }) => event => {
  const { value } = event.target
  const [count, vatCodeId, price] = getValues(['count', 'vatCodeId', 'price'])
  const combinedVatRate = getCombinedVatRateWithId(combinedVatRates, vatCodeId)

  const priceSum = value != null ? parseSafeNumber(value) : null
  const discount = calculateDiscount(price, count, priceSum)
  const vatPriceSum = calculatePriceWithVat(combinedVatRate, priceSum)

  setValue('priceSum', value, { shouldDirty: true })
  setValue('discount', discount)
  setValue('vatPriceSum', vatPriceSum)
  return value
}

export const onChangeVatPriceSum = (setValue, getValues, { combinedVatRates }) => event => {
  const { value } = event.target
  const [count, vatCodeId, vatPrice] = getValues(['count', 'vatCodeId', 'vatPrice'])
  const combinedVatRate = getCombinedVatRateWithId(combinedVatRates, vatCodeId)

  const vatPriceSum = value != null ? parseSafeNumber(value) : null
  const discount = calculateDiscount(vatPrice, count, vatPriceSum)
  const priceSum = calculatePriceWithoutVat(combinedVatRate, vatPriceSum)

  setValue('vatPriceSum', value, { shouldDirty: true })
  setValue('discount', discount)
  setValue('priceSum', priceSum)
  return value
}

export const getOnChangeCount = countKey => (setValue, getValues) => event => {
  const { value } = event.target
  const [price, vatPrice, discount] = getValues(['price', 'vatPrice', 'discount'])

  const count = value != null ? parseSafeNumber(value) : null
  const priceSum = calculateSum(price, count, discount)
  const vatPriceSum = calculateSum(vatPrice, count, discount)

  setValue(countKey, value, { shouldDirty: true })
  setValue('priceSum', priceSum)
  setValue('vatPriceSum', vatPriceSum)
  return value
}

export const onChangeCount = getOnChangeCount('count')

export const onChangeDiscount = (setValue, getValues) => (event, prefix = '') => {
  const { value } = event.target
  const getFieldName = name => `${prefix}${name}`
  const [price, vatPrice, count, purchasePrice] = getValues([getFieldName('price'), getFieldName('vatPrice'), getFieldName('count'), getFieldName('purchasePrice')])

  const discount = value != null ? parseSafeNumber(value) : null
  const priceSum = calculateSum(price, count, discount)
  const vatPriceSum = calculateSum(vatPrice, count, discount)
  const grossProfit = calculateGrossProfitFromPrice(price, purchasePrice, count, discount)
  const grossProfitPercentage = round8decimals(grossProfit / priceSum * 100) || 0

  setValue(getFieldName('discount'), value, { shouldDirty: true })
  setValue(getFieldName('priceSum'), priceSum)
  setValue(getFieldName('vatPriceSum'), vatPriceSum)
  setValue(getFieldName('grossProfit'), grossProfit)
  setValue(
    getFieldName('grossProfitPercentage'),
    grossProfitPercentage > 100 || grossProfitPercentage < -100
      ? null
      : grossProfitPercentage
  )
  return value
}

export const getOnChangeVatClass = (countKey, prefix = '') => (setValue, getValues, { combinedVatRates }) => vatClassId => {
  const getFieldName = name => `${prefix}${name}`
  const [price, count, discount, deliveryDate] = getValues([getFieldName('price'), getFieldName(countKey), getFieldName('discount'), getFieldName('deliveryDate')])
  const combinedVatRate = combinedVatRates.find(combinedVatRate =>
    combinedVatRate.vatClassId === vatClassId &&
    isSameOrBefore(combinedVatRate.validAfter, deliveryDate || undefined))

  if(!combinedVatRate) {
    return vatClassId
  }

  const vatPrice = calculatePriceWithVat(combinedVatRate, price)
  const vatPriceSum = calculateSum(vatPrice, count, discount)

  setValue(getFieldName('vatClassId'), combinedVatRate.vatClassId, { shouldDirty: true })
  setValue(getFieldName('vatCodeId'), combinedVatRate.id, { shouldDirty: true })
  setValue(getFieldName('vatPrice'), vatPrice, { shouldDirty: true })
  setValue(getFieldName('vatPriceSum'), vatPriceSum, { shouldDirty: true })
  return vatClassId
}

export const onChangeVatClass = getOnChangeVatClass('count')

export const onChangeVatCode = (setValue, getValues, { combinedVatRates }) => vatCodeId => {
  const [price, count, discount] = getValues(['price', 'count', 'discount'])
  const combinedVatRate = combinedVatRates.find(combinedVatRate =>
    combinedVatRate.id === vatCodeId)

  if(!combinedVatRate) {
    return vatCodeId
  }

  const vatPrice = calculatePriceWithVat(combinedVatRate, price)
  const vatPriceSum = calculateSum(vatPrice, count, discount)

  setValue('vatClassId', combinedVatRate.vatClassId, { shouldDirty: true })
  setValue('vatCodeId', vatCodeId, { shouldDirty: true })
  setValue('vatRate', combinedVatRate.rate)
  setValue('vatPrice', vatPrice)
  setValue('vatPriceSum', vatPriceSum)
  return vatCodeId
}

export const getOnChangeDeliveryDate = countKey => (setValue, getValues, { combinedVatRates }) => deliveryDate => {
  const [price, count, discount, vatClassId] = getValues(['price', countKey, 'discount', 'vatClassId'])
  const combinedVatRate = combinedVatRates.find(combinedVatRate =>
    combinedVatRate.vatClassId === vatClassId &&
    isSameOrBefore(combinedVatRate.validAfter, deliveryDate || undefined))

  if(!combinedVatRate) {
    return vatClassId
  }

  const vatPrice = calculatePriceWithVat(combinedVatRate, price)
  const vatPriceSum = calculateSum(vatPrice, count, discount)

  setValue('deliveryDate', deliveryDate, { shouldDirty: true })
  setValue('vatCodeId', combinedVatRate.id)
  setValue('vatPrice', vatPrice)
  setValue('vatPriceSum', vatPriceSum)
  return vatClassId
}

export const onChangeDeliveryDate = getOnChangeDeliveryDate('count')

export const onChangeGrossProfit = (setValue, getValues, tableMeta) => event => {
  const { value } = event.target
  const [purchasePrice, count, discount] = getValues(['purchasePrice', 'count', 'discount'])

  const grossProfit = parseSafeNumber(value)
  const priceSum = calculatePriceSumFromGrossProfit(grossProfit, purchasePrice, count) || 0 // Handles NaN
  const grossProfitPercentage = round8decimals(grossProfit / priceSum * 100) || 0
  const price = (purchasePrice + grossProfit / count) * (1 / getDiscountCoefficient(discount))

  setValue('grossProfit', value)
  setValue(
    'grossProfitPercentage',
    grossProfitPercentage > 100 || grossProfitPercentage < -100
      ? null
      : grossProfitPercentage
  )
  onChangePrice(setValue, getValues, tableMeta)({ target: { value: price } })
  return value
}

export const onChangeGrossProfitPercentage = (setValue, getValues, tableMeta) => (event, prefix = '') => {
  const { value } = event.target
  const getFieldName = name => `${prefix}${name}`
  const [purchasePrice, count, discount] = getValues([getFieldName('purchasePrice'), getFieldName('count'), getFieldName('discount')])
  const grossProfitPercentage = parseSafeNumber(value)
  const grossProfit = calculateGrossProfitFromPercentage(grossProfitPercentage, purchasePrice, count) || 0
  const price = (purchasePrice + grossProfit / count) * (1 / getDiscountCoefficient(discount))

  setValue(getFieldName('grossProfitPercentage'), value)
  setValue(getFieldName('grossProfit'), grossProfit)

  onChangePrice(setValue, getValues, tableMeta)({ target: { value: price } }, prefix)
  return value
}
