import { createApi } from '../helpers/apiHelpers'
import { parseFilterParams, parseTableOptionsFromQuery } from '../helpers/reducerHelpers'
import { optionsQueryBase } from './urlTemplates'

export const normalizeReceipts = response => {
  const { _embedded, records, record } = response
  const {
    options
  } = _embedded || {}
  const data = records || record

  return {
    data,
    tableOptions: parseTableOptionsFromQuery(options)
  }
}

export const receiptApi = createApi({
  base: 'receipts',
  normalizer: normalizeReceipts,
  extraUrlTemplate: optionsQueryBase,
  editGetParams: parseFilterParams
})
