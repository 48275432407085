import constants from '../constants'
import { getCombinedListReducerFor, getTableOptions } from '../helpers/reducerHelpers'
import tableReducerFor from './tableReducer'

export const initialMachineRecordTableOptions = {
  default: getTableOptions()
}

export default getCombinedListReducerFor(constants.actionKeys.MACHINE_RECORDS, {
  memos: getCombinedListReducerFor(constants.actionKeys.MACHINE_RECORD_MEMOS),
  tableOptions: tableReducerFor(constants.actionKeys.MACHINE_RECORDS, initialMachineRecordTableOptions)
})
