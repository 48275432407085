import constants from '../constants'
import { actionCreatorsFor, request } from '../helpers/actionHelpers'
import { PATH_RECEIPTS } from '../routes'
import { navigateTo } from './navigateActions'
import tableActions from './tableActions'

export const getUrl = receiptId => `${PATH_RECEIPTS}/${receiptId || 'new'}`

const baseActionCreators = actionCreatorsFor(constants.actionKeys.RECEIPT, { patchAction: true })

const additionalActionCreators = {
  files: actionCreatorsFor(constants.actionKeys.RECEIPT_FILES),
  memos: actionCreatorsFor(constants.actionKeys.RECEIPT_MEMOS),
  accountings: actionCreatorsFor(constants.actionKeys.RECEIPT_ACCOUNTINGS),
  navigateTo: (receiptId, replaceUrl) => navigateTo(getUrl(receiptId), replaceUrl),
  navigateToBase: replaceUrl => navigateTo(PATH_RECEIPTS, replaceUrl),
  tableActions: tableActions(constants.actionKeys.RECEIPT),
  receiptPatchAction: request(baseActionCreators.actionTypes.patchActionRequest)
}

const receiptActions = { ...baseActionCreators, ...additionalActionCreators }
export default receiptActions
