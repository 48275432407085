import PropTypes from 'prop-types'

const PersonDetails = ({
  title,
  name,
  firstName,
  lastName,
  email,
  address,
  postalCode,
  city,
  phoneNumber,
  renderExtra,
  hideAddress
}) => (
  <div>
    {title && <><strong>{title}</strong><br /></>}
    {name || `${firstName || ''} ${lastName || ''}`}<br />
    {email && <>{email}<br /></>}
    {phoneNumber && <>{phoneNumber}<br /></>}
    {address && !hideAddress
      ? (
        <address>
          {address || ''}<br />
          {(postalCode || '') + ' ' + (city || '')}
        </address>
        )
      : null}
    {renderExtra && renderExtra()}
  </div>
)

PersonDetails.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  address: PropTypes.string,
  postalCode: PropTypes.string,
  city: PropTypes.string,
  phoneNumber: PropTypes.string,
  renderExtra: PropTypes.func,
  hideAddress: PropTypes.bool
}

export default PersonDetails
