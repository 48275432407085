import { createApi, defaultEmbeddedNormalizer, defaultNormalizer } from '../helpers/apiHelpers'
import { parseFilterParams } from '../helpers/reducerHelpers'
import { optionsQueryBase } from './urlTemplates'

export const timeRecordApi = createApi({
  base: 'time_records',
  normalizer: defaultEmbeddedNormalizer,
  extraUrlTemplate: optionsQueryBase,
  editGetParams: parseFilterParams
})

export const timeRecordTypeApi = createApi({
  base: 'time_record_types',
  normalizer: defaultNormalizer
})

export const timeRecordSiteApi = createApi({
  base: 'time_record_sites',
  normalizer: defaultEmbeddedNormalizer
})

export const timeRecordExtraTimeApi = createApi({
  base: 'employees',
  normalizer: defaultEmbeddedNormalizer,
  extraUrlTemplate: optionsQueryBase,
  editGetParams: params => parseFilterParams({
    ...params,
    id: params.employeeId,
    subItem: 'time_record_extra_times'
  }),
  editMutateParams: (params, data) => ({
    ...params,
    subItem: 'time_record_extra_times'
  })
})
