import { parseTemplate } from 'url-template'

import constants, { actionTypes } from '../constants'
import {
  actionCreatorsFor,
  error,
  request,
  start,
  success
} from '../helpers/actionHelpers'
import { PATH_WORK_RECORDS } from '../routes'
import { navigateTo } from './navigateActions'
import tableActions from './tableActions'

const workUrlTemplate = parseTemplate(`${PATH_WORK_RECORDS}{/workRecordId}{#hash}`)
export const getUrl = (workRecordId, params) => workUrlTemplate.expand({ workRecordId: workRecordId || 'new', ...params })

const baseActionCreators = actionCreatorsFor(constants.actionKeys.WORK_RECORDS, { startRecord: true, stopRecord: true, approveSalaryEvents: true })

const additionalActionCreators = {
  navigateTo: (workRecordId, replaceUrl, params) => navigateTo(getUrl(workRecordId, params), replaceUrl),
  navigateToBase: replaceUrl => navigateTo(PATH_WORK_RECORDS, replaceUrl),
  memos: actionCreatorsFor(constants.actionKeys.WORK_RECORD_MEMOS),
  startRecordRequest: request(baseActionCreators.actionTypes.startRecordRequest),
  startRecordStart: start(baseActionCreators.actionTypes.startRecordStart),
  startRecordSuccess: success(baseActionCreators.actionTypes.startRecordSuccess),
  startRecordError: error(baseActionCreators.actionTypes.startRecordError),
  stopRecordRequest: request(baseActionCreators.actionTypes.stopRecordRequest),
  stopRecordStart: start(baseActionCreators.actionTypes.stopRecordStart),
  stopRecordSuccess: success(baseActionCreators.actionTypes.stopRecordSuccess),
  stopRecordError: error(baseActionCreators.actionTypes.stopRecordError),
  approveSalaryEventsRequest: request(baseActionCreators.actionTypes.approveSalaryEventsRequest),
  approveSalaryEventsStart: start(baseActionCreators.actionTypes.approveSalaryEventsStart),
  approveSalaryEventsSuccess: success(baseActionCreators.actionTypes.approveSalaryEventsSuccess),
  approveSalaryEventsError: error(baseActionCreators.actionTypes.approveSalaryEventsError),
  salaryEvents: {
    ...actionCreatorsFor(constants.actionKeys.WORK_RECORD_SALARY_EVENTS, {}, '_id'),
    tableActions: tableActions(constants.actionKeys.WORK_RECORD_SALARY_EVENTS)
  },
  tableActions: tableActions(constants.actionKeys.WORK_RECORDS),
  doPatchAction: request(actionTypes.WORK_RECORD_PATCH_ACTION_REQUEST)
}

const workRecordActions = { ...baseActionCreators, ...additionalActionCreators }
export default workRecordActions
