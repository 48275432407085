import { createApi } from '@reduxjs/toolkit/query/react'
import queryString from 'query-string'

import { getBaseQuery } from './apiHelpers'

export type Account = {
  bic: string
  iban: string
}

export type InboundInvoiceModel = {
  id: number
  externalId: null | string
  source: null | string
  receiverId: number
  templateInboundInvoiceId: null
  createdAt: string
  updatedAt: string
  archivedAt: null
  completedAt: null
  dateDue: string
  inboundInvoiceDate: string
  type: string
  state: string
  accountingState: string
  paymentState: string
  banksonState: null
  inboundInvoiceNumber: number | null
  fetchedAt: null | string
  accounts: Account[]
  vatType: null | string
  currency: string
  invoiceNumber: number | null | string
  priceSum: number | null
  vatPriceSum: number
  openBalance: number
  deliveryDate: null | string
  deliveryType: null
  referenceNumber: string | null
  message: null | string
  paymentDates: unknown[]
  unapprovedSum: number
  yourReference: null
  ourReference: null
  notes: null
  recipientCustomerNumber: null | string
  recipientName: null | string
  recipientEmail: null
  recipientBusinessId: null | string
  recipientContactPersonName: null
  recipientAddress: null
  recipientAddress2: null
  recipientPostalCode: null
  recipientCity: null
  recipientState: null
  recipientCountry: null | string
  recipientOvt: null
  senderName: string
  senderBusinessId: null | string
  senderAddress: null | string
  senderAddress2: null
  senderPostalCode: null | string
  senderPostOffice: null | string
  senderCity: null | string
  senderState: null
  senderCountry: null | string
  senderPhone: null
  senderFax: null
  senderEmail: null
  senderInterestFee: number | null
  senderContactPerson: null
  senderContactEmail: null | string
  paymentTerms: null | string
  workOrderNumber: null
  orderNumber: null
  isAccountingsFromRows: boolean
  costType: string
  cashDiscounts: unknown[]
  isValidPayable: boolean
}

type ApprovedInboundInvoiceData = {
  paymentDate: string
  isoDate: string
  approvedSumPayments: number
  approvedSumSalaries: number
}

export const inboundInvoiceApi = createApi({
  reducerPath: 'inboundInvoiceApi',
  baseQuery: getBaseQuery('inbound_invoices'),
  endpoints: builder => ({
    getApprovedInboundInvoices: builder.query<{ record: Record<string, ApprovedInboundInvoiceData> }, { paymentRange: [string, string] }>({
      query: query => {
        const q = queryString.stringify(query)
        return `/approved_payments?${q}`
      }
    })
  })
})

export const { useGetApprovedInboundInvoicesQuery } = inboundInvoiceApi
