import { DataTypeProvider } from '@devexpress/dx-react-grid'
import moment, { formatDateOrNull } from '@evelia/helpers/dateHelpers'
import { getSelf } from '@evelia/helpers/helpers'
import {
  TableEditColumn
} from 'dx-react-grid-bootstrap5'
import isFunction from 'lodash/isFunction'
import memoize from 'micro-memoize'

import { formatDateFromSeparateFields } from '../../helpers/dateHelpers'

export const mapToDataGrid = memoize(columnSpecs =>
  columnSpecs.filter(columnSpec => columnSpec.isVisible !== false).reduce((acc, columnSpec) => {
    acc.columns.push({
      name: columnSpec.field,
      title: columnSpec.header,
      getCellValue: columnSpec.getCellValue
    })
    acc.exportColumns.push({
      name: columnSpec.field,
      title: columnSpec.textHeader || columnSpec.header,
      getCellValue: columnSpec.getCellValue
    })
    acc.columnWidths.push({
      columnName: columnSpec.field,
      width: columnSpec.width,
      wordWrapEnabled: !!columnSpec.wordWrapEnabled,
      getCellProps: columnSpec.getCellProps
    })
    acc.editingStates.push({
      columnName: columnSpec.field,
      editingEnabled: isFunction(columnSpec.editingEnabled) ? columnSpec.editingEnabled : !!columnSpec.editingEnabled,
      editingComponent: columnSpec.editingComponent,
      getEditingComponentProps: columnSpec.getEditingComponentProps,
      setNull: columnSpec.setNull,
      createRowChange: columnSpec.createRowChange,
      disableCellIfRowContains: columnSpec.disableCellIfRowContains,
      defaultValue: columnSpec.defaultValue,
      customRenderer: columnSpec.customRenderer,
      customComponent: columnSpec.customComponent,
      customComponentProps: columnSpec.customComponentProps,
      getCustomComponentProps: columnSpec.getCustomComponentProps
    })
    if(columnSpec.columnBandTitle != null) {
      const index = acc.columnBands.findIndex(column => {
        return column.title === columnSpec.columnBandTitle
      })
      if(index === -1) {
        acc.columnBands.push({
          title: columnSpec.columnBandTitle,
          children: [
            { columnName: columnSpec.field }
          ]
        })
      } else {
        acc.columnBands[index].children.push({ columnName: columnSpec.field })
      }
    }
    if(columnSpec.filteringEnabled != null) {
      acc.filteringStates.push({
        columnName: columnSpec.field,
        filteringEnabled: columnSpec.filteringEnabled,
        filteringComponent: columnSpec.filteringComponent
      })
    }
    if(columnSpec.groupBy != null) {
      acc.groupingStates.push({
        columnName: columnSpec.field,
        criteria: columnSpec.groupByCriteria,
        groupComponent: columnSpec.groupComponent
      })
    }
    if(columnSpec.sumType != null) {
      acc.totalItems.push({
        columnName: columnSpec.field,
        type: columnSpec.sumType
      })
    }
    if(columnSpec.sortingEnabled != null) {
      acc.sortingStates.push({
        columnName: columnSpec.field,
        sortingEnabled: columnSpec.sortingEnabled
      })
    }
    if(columnSpec.customComponent) {
      const Component = columnSpec.customComponent
      acc.customRenderers.push({
        columnName: columnSpec.field,
        Formatter: () => (
          <DataTypeProvider
            formatterComponent={props => <Component {...props} {...columnSpec.customComponentProps} {...columnSpec?.getCustomComponentProps?.(props)} />}
            for={[columnSpec.field]}
          />
        )
      })
    } else if(columnSpec.customRenderer) {
      acc.customRenderers.push({
        columnName: columnSpec.field,
        Formatter: () => (
          <DataTypeProvider
            formatterComponent={({ value, row }) => columnSpec.customRenderer(value, row)}
            for={[columnSpec.field]}
          />
        )
      })
    }
    if(columnSpec.pinLeft) {
      acc.pinLeft.push(columnSpec.field)
    } else if(columnSpec.pinRight) {
      acc.pinRight.push(columnSpec.field)
    }
    return acc
  }, { columns: [], exportColumns: [], columnWidths: [], customRenderers: [], editingStates: [], columnBands: [], filteringStates: [], sortingStates: [], groupingStates: [], totalItems: [], pinLeft: [], pinRight: [] }), { maxSize: 10 })

// Result found https://github.com/DevExpress/devextreme-reactive/issues/287#issuecomment-429224230
export const reorderEditCommandColumn = ({ tableColumns }) => [
  ...tableColumns.filter(c => c.type !== TableEditColumn.COLUMN_TYPE),
  { key: 'editCommand', type: TableEditColumn.COLUMN_TYPE, width: 100 }
]

const parseSorting = ({ orderBy, sortOrder }) =>
  orderBy.map((orderItem, index) => ({
    columnName: orderItem, direction: sortOrder[index].toLowerCase()
  }))

const parsePaging = ({ totalCount, limit, page, q }, customTotalCount) => {
  if(customTotalCount) {
    if(totalCount) {
      if((limit * (page) + totalCount) >= 1000) { // if more than 1000 results -> offsets out of bounds error
        totalCount = limit * (page) + totalCount
      } else if(totalCount === limit) {
        totalCount = limit * (page + 1) + 1
      } else {
        totalCount = limit * (page) + totalCount
      }
    } else {
      totalCount = 0
    }
  }

  return ({
    currentPage: page,
    pageSize: limit,
    totalCount
  })
}

const parseFiltering = ({ filters }) => ({
  filters: Array.isArray(filters)
    ? filters.map(pack => ({ columnName: pack[0], value: pack[1] }))
    : Object.entries(filters).map(([columnName, value]) => ({ columnName, value }))
})

export const parseOptions = (tableOptions, optionProps) => {
  if(!tableOptions) {
    return {}
  }
  const {
    onSortingChange,
    onCurrentPageChange,
    onPageSizeChange,
    onFiltersChange,
    clearOptions,
    customTotalCount
  } = optionProps
  const sortingProps = { sorting: parseSorting(tableOptions), onSortingChange }
  const pagingProps = { ...parsePaging(tableOptions, customTotalCount), onCurrentPageChange, onPageSizeChange }
  const filteringProps = { ...parseFiltering(tableOptions), onFiltersChange }
  return {
    sortingProps,
    pagingProps,
    filteringProps,
    clearOptions
  }
}

export const getSimpleRowId = row => row.id

export const getDoubleField = (field1Options, field2Options) => {
  const field1Formatter = field1Options.formatter || getSelf
  const field2Formatter = field2Options.formatter || getSelf
  return {
    header: <>{field1Options.header}<br /><small>{field2Options.header}</small></>,
    field: field1Options.field,
    customRenderer: (value, model) => <>{field1Formatter(value, model) || value}<br /><small>{field2Formatter(model[field2Options.field], model)}</small></>
  }
}

export const getField = (rowFields, field) => {
  return { ...rowFields.find(rowField => rowField.field === field) }
}

export const getDateField = (fieldName, header) => ({
  header,
  field: fieldName,
  getCellValue: model => model[fieldName] ? moment(model[fieldName]).unix() : null,
  customRenderer: (__field, model) => formatDateOrNull(model[fieldName]),
  width: 95
})

export const getCombinedDateTimeField = isEnd => {
  const dateField = isEnd ? 'endDate' : 'startDate'
  const timeField = isEnd ? 'endTime' : 'startTime'
  const header = isEnd ? 'Loppuaika' : 'Alkuaika'
  return {
    header,
    field: dateField,
    customRenderer: (__value, model) => formatDateFromSeparateFields(model[dateField], model[timeField]),
    width: 140
  }
}

export const renderExtraInfoCount = field => extraInfo => <b>{extraInfo?.[field] ? extraInfo[field] : '-'}</b>
