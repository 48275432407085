import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createColumnHelper } from '@tanstack/react-table'
import constant from 'lodash/constant'
import groupBy from 'lodash/groupBy'

import { getUrl } from '../../actions/warehouseActions'
import { WarehouseModel } from '../../api/types/warehouseTypes'
import DataTable from '../../components/DataTable/DataTable'
import DataTableExpandCell from '../../components/DataTable/DataTableExpandCell'
import DataTableGlobalFilter from '../../components/DataTable/DataTableGlobalFilter'
import { globalStringFilterFn } from '../../components/DataTable/dataTableHelpers'
import NoDataRowCell from '../../components/DataTable/NoDataRowCell'
import PlainRowCell from '../../components/DataTable/PlainRowCell'
import useDataTableStateStorage from '../../components/DataTable/useDataTableStateStorage'
import useExpand from '../../components/DataTable/useExpand'
import useNestedRowClick from '../../components/DataTable/useNestedRowClick'
import { checkedOrNullIcon } from '../../helpers/helpers'
import { WithSubRows } from '../../helpers/typeHelpers'
import { useCallParseId } from '../../hooks/useCallParseParam'
import { warehousePropType } from '../../propTypes'

type DecoratedWarehouseModel = WithSubRows<WarehouseModel>
const getSubRows = (row: DecoratedWarehouseModel) => row.subRows
const enableGlobalFilter = constant(true)
const columnHelper = createColumnHelper<DecoratedWarehouseModel>()
const columns = [
  columnHelper.accessor('name', {
    header: 'Nimi',
    size: 400,
    cell: ({ row, getValue, column }) => {
      return (
        <DataTableExpandCell
          row={row}
          testId={column.id}
          subRows={getSubRows(row.original)}
          value={getValue()}
          linkTo={getUrl(row.original.id)}
          hasRelation={!!row.original.id}
          hideExpandWithNoChild
          noBadge
        />
      )
    }
  }),
  columnHelper.accessor('isWasteWarehouse', {
    header: 'Hävikkivarasto',
    size: 150,
    cell: ({ getValue }) => <PlainRowCell name='isWasteWarehouse' value={checkedOrNullIcon(getValue())} />
  })
]

export const groupWarehouses = <T extends WarehouseModel>(warehouses: T[]): WithSubRows<T>[] => {
  if(!warehouses.length) {
    return []
  }
  // Group warehouses to their warehousePath length
  const decoratedWarehouses: WithSubRows<T>[] = warehouses.map(wh => ({ ...wh, subRows: [] }))
  const warehousesByLength = groupBy(decoratedWarehouses, 'warehousePath.length')
  const pathLengths = Object.keys(warehousesByLength).map(Number)
    .sort((a, b) => b - a)
  const shortestPath = pathLengths[pathLengths.length - 1]
  pathLengths.forEach(pathLength => {
    if(pathLength === shortestPath) {
      return
    }
    const parentWarehouses = warehousesByLength[pathLength - 1]
    warehousesByLength[pathLength].forEach(subWarehouse => {
      const parentWarehouse = parentWarehouses.find(parent => parent.id === subWarehouse.parentWarehouseId)
      parentWarehouse?.subRows.push(subWarehouse)
    })
  })
  return warehousesByLength[shortestPath]
}

interface WarehouseTableProps {
  warehouses: WarehouseModel[]
  onRowClick: (id: number) => void
}

const WarehouseTable = ({ warehouses, onRowClick }: WarehouseTableProps) => {
  const groupedWarehouses = useMemo(() => groupWarehouses(warehouses), [warehouses])
  const callWithId = useCallParseId(onRowClick)
  const {
    tableState,
    filteringOptions,
    sizingOptions,
    sortingOptions
  } = useDataTableStateStorage<DecoratedWarehouseModel>('warehouse', null)
  const { expanded, setExpanded } = useExpand(warehouses, tableState.globalFilter, getSubRows, {})
  const state = useMemo(() => ({
    ...tableState,
    expanded
  }), [expanded, tableState])
  const handleNestedRowClick = useNestedRowClick(callWithId, getSubRows)

  return (
    <>
      {/* @ts-expect-error forward ref not typed */}
      <DataTableGlobalFilter value={tableState.globalFilter} onChange={filteringOptions.onGlobalFilterChange} />
      <DataTable
        data={groupedWarehouses}
        state={state}
        columns={columns}
        onRowClick={handleNestedRowClick}
        getSubRows={getSubRows}
        onExpandedChange={setExpanded}
        globalFilterFn={globalStringFilterFn}
        noDataContent={<NoDataRowCell>Ei varastoja</NoDataRowCell>}
        testId='warehouseTable'
        getColumnCanGlobalFilter={enableGlobalFilter}
        filterFromLeafRows
        {...filteringOptions}
        {...sizingOptions}
        {...sortingOptions}
      />
    </>
  )
}

WarehouseTable.propTypes = {
  warehouses: PropTypes.arrayOf(warehousePropType),
  onRowClick: PropTypes.func.isRequired
}

export default WarehouseTable
