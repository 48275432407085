import { parseTemplate } from 'url-template'

import constants from '../constants'
import {
  actionCreatorsFor,
  errorRecords,
  requestRecords,
  startRecords,
  successRecords
} from '../helpers/actionHelpers'
import { PATH_PRODUCTS_MACHINES } from '../routes'
import { navigateTo } from './navigateActions'
import tableActions from './tableActions'

const machineUrlTemplate = parseTemplate(`${PATH_PRODUCTS_MACHINES}/{machineId}{?source}`)
export const getUrl = (machineId, params) => machineUrlTemplate.expand({ machineId: machineId || 'new', ...params })

const baseActionCreators = actionCreatorsFor(constants.actionKeys.MACHINES, { machineLogTypeFetch: true })

const additionalActionCreators = {
  navigateTo: (machineId, replaceUrl, params) => navigateTo(getUrl(machineId, params), replaceUrl),
  navigateToBase: replaceUrl => navigateTo(PATH_PRODUCTS_MACHINES, replaceUrl),
  memos: actionCreatorsFor(constants.actionKeys.MACHINE_MEMOS),
  files: actionCreatorsFor(constants.actionKeys.MACHINE_FILES),
  machineLogs: actionCreatorsFor(constants.actionKeys.MACHINE_LOGS),
  machineTypes: actionCreatorsFor(constants.actionKeys.MACHINE_TYPES),
  machinePricingRules: actionCreatorsFor(constants.actionKeys.MACHINE_PRICING_RULES),
  tableActions: tableActions(constants.actionKeys.MACHINES),
  machineLogTypeFetchRequest: requestRecords(baseActionCreators.actionTypes.machineLogTypeFetchRequest),
  machineLogTypeFetchSuccess: successRecords(baseActionCreators.actionTypes.machineLogTypeFetchSuccess),
  machineLogTypeFetchError: errorRecords(baseActionCreators.actionTypes.machineLogTypeFetchError),
  machineLogTypeFetchStart: startRecords(baseActionCreators.actionTypes.machineLogTypeFetchStart)
}

const machineActions = { ...baseActionCreators, ...additionalActionCreators }
export default machineActions
