import React, { ChangeEventHandler } from 'react'
import { FieldValues, useFormContext, UseFormGetValues, UseFormSetValue } from 'react-hook-form'
import { ColumnMeta } from '@tanstack/react-table'

import { PolymorphicPropsWithOwn } from '../../helpers/polymorphicTypes'
import DefaultFormInput from '../hookForm/FormInput'
import { FormPlainInputProps } from '../hookForm/FormPlainInput'

export interface FormMeta<TData extends FieldValues, TValue> {
  onValueChange?: (setValue: UseFormSetValue<TData>, getValues: UseFormGetValues<TData>, tableMeta: object, meta: ColumnMeta<TData, TValue>) => (ChangeEventHandler<HTMLInputElement> | ((value: TValue) => void))
}

interface ContextFormWrapperOwnProps<TData extends FieldValues, TValue> extends FormPlainInputProps {
  meta?: ColumnMeta<TData, TValue> & FormMeta<TData, TValue>
  tableMeta?: object
}

type ContextFormWrapperProps<TData extends FieldValues, TValue> =
// eslint-disable-next-line @typescript-eslint/no-explicit-any
  PolymorphicPropsWithOwn<ContextFormWrapperOwnProps<TData, TValue>, 'FormInput', React.FC<any>>

const ContextFormWrapper = <TData extends FieldValues, TValue>({
  FormInput = DefaultFormInput,
  meta,
  tableMeta,
  ...formInputProps
}: ContextFormWrapperProps<TData, TValue>) => {
  const { control, setValue, getValues } = useFormContext<TData>()
  const onValueChange = meta?.onValueChange?.(setValue, getValues, tableMeta, meta)
  return (
    <FormInput
      control={control}
      onValueChange={onValueChange}
      {...formInputProps}
    />
  )
}

export default React.memo(ContextFormWrapper) as unknown as <TData extends FieldValues, TValue>(
  props: ContextFormWrapperProps<TData, TValue>
) => ReturnType<typeof ContextFormWrapper>
