import { createApi } from '../helpers/apiHelpers'

export const costProvisionApi = createApi({
  base: 'cost_provisions',
  editGetParams: params => params.workId
    ? {
        ...params,
        base: 'work',
        id: params.workId,
        subItem: 'cost_provisions'
      }
    : params
})
