import constants from '../constants'
import { actionCreatorsFor } from '../helpers/actionHelpers'

const baseActionCreators = {}

const additionalActionCreators = {
  postalCodes: actionCreatorsFor(constants.actionKeys.POSTAL_CODES)
}

const miscellaneousActions = { ...baseActionCreators, ...additionalActionCreators }
export default miscellaneousActions
