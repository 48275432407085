import { createApi, defaultEmbeddedNormalizer } from '../helpers/apiHelpers'
import { parseFilterParams } from '../helpers/reducerHelpers'
import { optionsQueryBase } from './urlTemplates'

export const normalizeDenominations = response => defaultEmbeddedNormalizer(response)

export const denominationApi = createApi({
  base: 'denominations',
  normalizer: normalizeDenominations,
  extraUrlTemplate: optionsQueryBase,
  editGetParams: params =>
    params.denominationGroupId
      ? {
          ...params,
          base: 'denomination_groups',
          id: params.denominationGroupId,
          subItem: 'denominations'
        }
      : parseFilterParams(params),
  editSearchParams: parseFilterParams
})

export const denominationPacketApi = createApi({
  base: 'denomination_packets',
  normalizer: defaultEmbeddedNormalizer
})

export const denominationGroupApi = createApi({
  base: 'denomination_groups',
  normalizer: defaultEmbeddedNormalizer
})
