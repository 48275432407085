import React, { HtmlHTMLAttributes, ReactNode } from 'react'
import clsx from 'clsx'

interface PlainRowCellProps extends HtmlHTMLAttributes<HTMLDivElement> {
  value?: React.ReactNode
  children?: ReactNode
  name?: string
  getValue?: () => ReactNode
  className?: string
}

const PlainRowCell = React.memo(({
  children,
  value,
  getValue,
  name,
  className,
  ...rest
}: PlainRowCellProps) => {
  return (
    <div
      data-testid={name}
      className={clsx('form-control-sm text-ellipsis', className)} // matches bootstrap input sizing
      role='cell'
      {...rest}
    >
      {value ?? children ?? getValue?.()}
    </div>
  )
})

export default PlainRowCell
