import { miniSerializeError } from '@reduxjs/toolkit'
import { all, call, put, takeEvery } from 'redux-saga/effects'

import authorActions from '../actions/authorActions'
import timeRecordActions from '../actions/timeRecordActions'
import {
  timeRecordApi,
  timeRecordExtraTimeApi,
  timeRecordSiteApi,
  timeRecordTypeApi
} from '../api/timeRecordApi'
import { actionTypes } from '../constants'
import { defaultEmbeddedNormalizer } from '../helpers/apiHelpers'
import {
  createActionFlow,
  createFlow,
  createSocketWatcherWithGenerator,
  defaultApiResponseHandler,
  deleteFlow,
  fetchFlow,
  genericSagaErrorHandler,
  updateFlow
} from '../helpers/sagaHelpers'

const watchOnTimeRecordSockets = createSocketWatcherWithGenerator('timeRecord', {
  created: function* (data) {
    yield handleTimeRecordApiResponse(timeRecordActions.createSuccess)(defaultEmbeddedNormalizer(data))
  },
  updated: function* (data) {
    yield handleTimeRecordApiResponse(timeRecordActions.updateSuccess)(defaultEmbeddedNormalizer(data))
  },
  deleted: function* (data) {
    yield put(timeRecordActions.deleteSuccess(data))
  }
})

export const handleTimeRecordApiResponse = (mainAction, tableIdentifier) =>
  function* ({ data, timeRecordTypes, authors, tableOptions }) {
    yield put(timeRecordActions.timeRecordTypes.fetchSuccess(timeRecordTypes))
    yield put(authorActions.fetchSuccess(authors))
    yield put(mainAction(data))
    if(tableIdentifier != null && tableOptions?.orderBy != null) {
      yield put(timeRecordActions.tableActions.updateOptions(tableOptions, tableIdentifier))
    }
    return data
  }

const timeRecordFetchFlow = fetchFlow({
  fetchApi: timeRecordApi.fetch,
  actions: timeRecordActions,
  base: 'timeRecords',
  errorMsg: 'työaikakirjausten',
  getApiResponseHandler: data => handleTimeRecordApiResponse(timeRecordActions.fetchSuccess, data.tableIdentifier)
})

const timeRecordUpdateFlow = updateFlow(timeRecordApi.update, timeRecordActions, 'Työaikakirjaus', 'Työaikakirjauksen', handleTimeRecordApiResponse(timeRecordActions.updateSuccess))
const timeRecordCreateFlow = createFlow(timeRecordApi.create, timeRecordActions, 'Työaikakirjaus', 'Työaikakirjauksen', handleTimeRecordApiResponse(timeRecordActions.createSuccess))
const timeRecordDeleteFlow = deleteFlow({
  deleteApi: timeRecordApi.remove,
  actions: timeRecordActions,
  singular: 'Työaikakirjaus',
  errorMsg: 'Työaikakirjauksen',
  base: 'timeRecords'
})

const timeRecordTypeFetchFlow = fetchFlow({
  fetchApi: timeRecordTypeApi.fetch,
  actions: timeRecordActions.timeRecordTypes,
  base: 'timeRecords.timeRecordTypes',
  errorMsg: 'työaikatyyppien',
  getApiResponseHandler: () => defaultApiResponseHandler(timeRecordActions.timeRecordTypes.fetchSuccess)
})

const timeRecordTypeUpdateFlow = updateFlow(timeRecordTypeApi.update, timeRecordActions.timeRecordTypes, 'Työaikatyyppi', 'Työaikakirjaustyypin', defaultApiResponseHandler(timeRecordActions.timeRecordTypes.updateSuccess))
const timeRecordTypeCreateFlow = createFlow(timeRecordTypeApi.create, timeRecordActions.timeRecordTypes, 'Työaikatyyppi', 'Työaikakirjaustyypin', defaultApiResponseHandler(timeRecordActions.timeRecordTypes.createSuccess))
const timeRecordTypeDeleteFlow = deleteFlow({
  deleteApi: timeRecordTypeApi.remove,
  actions: timeRecordActions.timeRecordTypes,
  singular: 'Työaikatyyppi',
  errorMsg: 'Työaikakirjaustyypin',
  base: 'timeRecords.timeRecordTypes'
})

const timeRecordSiteFetchFlow = fetchFlow({
  fetchApi: timeRecordSiteApi.fetch,
  actions: timeRecordActions.timeRecordSites,
  base: 'timeRecords.timeRecordSites',
  errorMsg: 'työmaiden',
  getApiResponseHandler: () => defaultApiResponseHandler(timeRecordActions.timeRecordSites.fetchSuccess)
})

const timeRecordSiteUpdateFlow = updateFlow(timeRecordSiteApi.update, timeRecordActions.timeRecordSites, 'Työmaa', 'Työmaan', defaultApiResponseHandler(timeRecordActions.timeRecordSites.updateSuccess))
const timeRecordSiteCreateFlow = createFlow(timeRecordSiteApi.create, timeRecordActions.timeRecordSites, 'Työmaa', 'Työmaan', defaultApiResponseHandler(timeRecordActions.timeRecordSites.createSuccess))
const timeRecordSiteDeleteFlow = deleteFlow({
  deleteApi: timeRecordSiteApi.remove,
  actions: timeRecordActions.timeRecordSites,
  singular: 'Työmaa',
  errorMsg: 'Työmaan',
  base: 'timeRecords.timeRecordSites'
})

const timeRecordTypePostActionFlow = createActionFlow(timeRecordTypeApi.create, timeRecordActions.timeRecordTypes, defaultApiResponseHandler)

const timeRecordExtraTimeFetchFlow = fetchFlow({
  fetchApi: timeRecordExtraTimeApi.fetch,
  actions: timeRecordActions.timeRecordExtraTimes,
  base: 'timeRecords.timeRecordExtraTimes',
  errorMsg: 'liukumakirjausten',
  getApiResponseHandler: data => defaultApiResponseHandler(timeRecordActions.timeRecordExtraTimes.fetchSuccess, data.tableIdentifier)
})

const timeRecordExtraTimeUpdateFlow = createActionFlow(timeRecordExtraTimeApi.update, timeRecordActions.timeRecordExtraTimes, defaultApiResponseHandler)
const timeRecordExtraTimeCreateFlow = createFlow(timeRecordExtraTimeApi.create, timeRecordActions.timeRecordExtraTimes, 'Liukumakirjaus', 'Liukumakirjauksen', defaultApiResponseHandler(timeRecordActions.timeRecordExtraTimes.createSuccess))
const timeRecordExtraTimeDeleteFlow = deleteFlow({
  deleteApi: timeRecordExtraTimeApi.remove,
  actions: timeRecordActions.timeRecordExtraTimes,
  singular: 'Liukumakirjaus',
  errorMsg: 'Liukumakirjauksen',
  base: 'timeRecords.timeRecordsExtraTimes'
})

function* timeRecordExtraTimeStatsFlow({ record = {} }) {
  try {
    yield put(timeRecordActions.timeRecordExtraTimes.fetchStatsStart())
    const result = yield call(timeRecordExtraTimeApi.fetch, { ...record, subItemType: 'stats' })
    yield put(timeRecordActions.timeRecordExtraTimes.fetchStatsSuccess(result.data))
  } catch(err) {
    yield put(timeRecordActions.timeRecordExtraTimes.fetchStatsError(miniSerializeError(err)))
    yield * genericSagaErrorHandler(err)
  }
}

export default function* timeRecordSaga() {
  yield takeEvery(timeRecordActions.actionTypes.fetchRequest, timeRecordFetchFlow)
  yield takeEvery(timeRecordActions.actionTypes.updateRequest, timeRecordUpdateFlow)
  yield takeEvery(timeRecordActions.actionTypes.createRequest, timeRecordCreateFlow)
  yield takeEvery(timeRecordActions.actionTypes.deleteRequest, timeRecordDeleteFlow)

  yield takeEvery(timeRecordActions.timeRecordTypes.actionTypes.fetchRequest, timeRecordTypeFetchFlow)
  yield takeEvery(timeRecordActions.timeRecordTypes.actionTypes.updateRequest, timeRecordTypeUpdateFlow)
  yield takeEvery(timeRecordActions.timeRecordTypes.actionTypes.createRequest, timeRecordTypeCreateFlow)
  yield takeEvery(timeRecordActions.timeRecordTypes.actionTypes.deleteRequest, timeRecordTypeDeleteFlow)

  yield takeEvery(timeRecordActions.timeRecordSites.actionTypes.fetchRequest, timeRecordSiteFetchFlow)
  yield takeEvery(timeRecordActions.timeRecordSites.actionTypes.updateRequest, timeRecordSiteUpdateFlow)
  yield takeEvery(timeRecordActions.timeRecordSites.actionTypes.createRequest, timeRecordSiteCreateFlow)
  yield takeEvery(timeRecordActions.timeRecordSites.actionTypes.deleteRequest, timeRecordSiteDeleteFlow)

  yield takeEvery(timeRecordActions.timeRecordExtraTimes.actionTypes.fetchRequest, timeRecordExtraTimeFetchFlow)
  yield takeEvery(actionTypes.TIME_RECORD_EXTRA_TIME_UPDATE_ACTION_REQUEST, timeRecordExtraTimeUpdateFlow)
  yield takeEvery(timeRecordActions.timeRecordExtraTimes.actionTypes.createRequest, timeRecordExtraTimeCreateFlow)
  yield takeEvery(timeRecordActions.timeRecordExtraTimes.actionTypes.deleteRequest, timeRecordExtraTimeDeleteFlow)

  yield takeEvery(actionTypes.TIME_RECORD_TYPE_POST_ACTION_REQUEST, timeRecordTypePostActionFlow)
  yield takeEvery(timeRecordActions.timeRecordExtraTimes.actionTypes.fetchStatsRequest, timeRecordExtraTimeStatsFlow)

  yield all([
    watchOnTimeRecordSockets()
  ])
}
