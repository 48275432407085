import { useCallback, useMemo } from 'react'

import { callPromiseAndTapFunction } from '../helpers/helpers'

const useCallPromiseAndTapFunction = (promise, thenFunc) => {
  const handler = useMemo(() => promise ? callPromiseAndTapFunction(promise, thenFunc) : thenFunc, [promise, thenFunc])
  return useCallback(data => handler(data), [handler])
}

export default useCallPromiseAndTapFunction
