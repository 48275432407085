import constants from '../constants'
import { actionCreatorsFor } from '../helpers/actionHelpers'

const baseActionCreators = actionCreatorsFor(constants.actionKeys.OFFER_CUSTOM_STATES)

const additionalActionCreators = {

}

const offerCustomStateActions = { ...baseActionCreators, ...additionalActionCreators }
export default offerCustomStateActions
