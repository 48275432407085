import constants from '../constants'
import { actionCreatorsFor } from '../helpers/actionHelpers'

const baseActionCreators = actionCreatorsFor(constants.actionKeys.WORK_RECURRENCE_RULES)

const additionalActionCreators = {}

const workRecurrenceRuleActions = { ...baseActionCreators, ...additionalActionCreators }

export default workRecurrenceRuleActions
