import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { vatTypePropType } from '../../propTypes'
import ActionPromptModal from './ActionPromptModal'
import RepricePromptBody from './RepricePromptBody'
import SwitchWorkVatPrompt from './SwitchWorkVatPrompt'

const ConfirmWorkChangesPrompt = ({ hideActionPrompt, data, visible }) => {
  /* If we pass this null value for any confirm, it means there's no confirmation needed */
  const [isVatConfirmed, setIsVatConfirmed] = useState(data.vatSwitch === null)
  const [isRepriceConfirmed, setIsRepriceConfirmed] = useState(data.reprice === null)

  /* When everything is confirmed or cancelled, hide the modal */
  useEffect(() => {
    if(isVatConfirmed && isRepriceConfirmed) {
      hideActionPrompt()
    }
  }, [isVatConfirmed, isRepriceConfirmed, hideActionPrompt])

  return (
    <ActionPromptModal
      visible={visible}
      onCancel={hideActionPrompt}
      renderBody={(
        <>{!isVatConfirmed
          ? (
            <div className='modal-subprompt'>
              <SwitchWorkVatPrompt
                data={data.vatSwitch}
                hideActionPrompt={() => setIsVatConfirmed(true)}
              />
            </div>
            )
          : null}
          {!isRepriceConfirmed
            ? (
              <div className='modal-subprompt'>
                <RepricePromptBody
                  data={data.reprice}
                  hideActionPrompt={() => setIsRepriceConfirmed(true)}
                />
              </div>
              )
            : null}
        </>
    )}
    />
  )
}

ConfirmWorkChangesPrompt.propTypes = {
  hideActionPrompt: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    vatSwitch: PropTypes.shape({
      workId: PropTypes.number.isRequired,
      customerVatType: vatTypePropType,
      vatType: vatTypePropType
    }),
    reprice: PropTypes.shape({
      workId: PropTypes.number.isRequired
    })
  }).isRequired
}

export default ConfirmWorkChangesPrompt
