import PropTypes from 'prop-types'
import { appendEitherOrEmpty, getRightAddress } from '@evelia/helpers/helpers'
import { clsx } from 'clsx'

import { contentRowWithIcon } from './ProjectDetails'

export const contentRow = content => content ? <div>{content}</div> : null

const PostalDetails = ({
  title,
  name,
  nameExtension,
  nameExtension2,
  businessId,
  address,
  postalCode,
  city,
  phoneNumber,
  email,
  showOvt,
  ovt,
  renderExtra,
  mainName,
  icon,
  nameLink,
  showHorizontalDetails
}) => {
  const nameRow = nameLink
    ? (
      <div className='d-flex gap-2'>
        {nameLink}
        {businessId ? <span>{` | ${businessId}`}</span> : null}
      </div>
      )
    : contentRow(appendEitherOrEmpty(name, businessId, ' | '))

  const nameExtensionRow = showHorizontalDetails
    ? contentRow(appendEitherOrEmpty(nameExtension, nameExtension2, ' | '))
    : (
      <>
        {contentRow(nameExtension)}
        {contentRow(nameExtension2)}
      </>
      )

  const addressRow = showHorizontalDetails
    ? contentRow(getRightAddress({ address, postalCode, city }))
    : (
      <>
        {contentRow(address)}
        {contentRow(appendEitherOrEmpty(postalCode, city))}
      </>
      )

  return (
    <div className={clsx('d-flex flex-column', showHorizontalDetails && 'small')}>
      {title && <div><strong>{title}</strong></div>}
      {nameRow}
      {nameExtensionRow}
      {mainName ? contentRowWithIcon(mainName, icon) : null}
      <address className='m-0'>
        {addressRow}
      </address>
      {contentRow(appendEitherOrEmpty(phoneNumber, email, ' | '))}
      {showOvt ? contentRow(ovt) : null}
      {renderExtra?.()}
    </div>
  )
}

PostalDetails.propTypes = {
  title: PropTypes.node,
  name: PropTypes.string,
  nameExtension: PropTypes.string,
  nameExtension2: PropTypes.string,
  address: PropTypes.string,
  postalCode: PropTypes.string,
  city: PropTypes.string,
  businessId: PropTypes.string,
  phoneNumber: PropTypes.string,
  email: PropTypes.string,
  renderExtra: PropTypes.func,
  ovt: PropTypes.string,
  showOvt: PropTypes.bool,
  mainName: PropTypes.string,
  icon: PropTypes.any,
  nameLink: PropTypes.node,
  showHorizontalDetails: PropTypes.bool
}

export default PostalDetails
