import constants from '../constants'
import {
  actionCreatorsFor,
  error,
  request,
  start,
  success
} from '../helpers/actionHelpers'
import { PATH_CUSTOMERS } from '../routes'
import { navigateTo } from './navigateActions'
import tableActions from './tableActions'

export const getUrl = customerId => `${PATH_CUSTOMERS}/${customerId || 'new'}`

const baseActionCreators = actionCreatorsFor(constants.actionKeys.CUSTOMERS, { fetchStats: true, fetchPrh: true })

const additionalActionCreators = {
  contacts: actionCreatorsFor(constants.actionKeys.CUSTOMER_CONTACTS),
  files: actionCreatorsFor(constants.actionKeys.CUSTOMER_FILES),
  memos: actionCreatorsFor(constants.actionKeys.CUSTOMER_MEMOS),
  navigateTo: (customerId, replaceUrl) => navigateTo(getUrl(customerId), replaceUrl),
  navigateToBase: replaceUrl => navigateTo(PATH_CUSTOMERS, replaceUrl),
  tableActions: tableActions(constants.actionKeys.CUSTOMERS),
  fetchStatsRequest: request(baseActionCreators.actionTypes.fetchStatsRequest),
  fetchStatsStart: start(baseActionCreators.actionTypes.fetchStatsStart),
  fetchStatsSuccess: success(baseActionCreators.actionTypes.fetchStatsSuccess),
  fetchStatsError: error(baseActionCreators.actionTypes.fetchPrhError),
  fetchPrhRequest: request(baseActionCreators.actionTypes.fetchPrhRequest),
  fetchPrhStart: start(baseActionCreators.actionTypes.fetchPrhStart),
  fetchPrhSuccess: success(baseActionCreators.actionTypes.fetchPrhSuccess),
  fetchPrhError: error(baseActionCreators.actionTypes.fetchPrhError)
}

const customerActions = { ...baseActionCreators, ...additionalActionCreators }
export default customerActions
