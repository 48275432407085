import { LOCATION_CHANGE } from 'redux-first-history'

import { actionTypes } from '../constants'

const initialStateWithHistory = {
  history: []
}

const historyReducer = routerReducer => {
  const initialState = {
    ...routerReducer(),
    ...initialStateWithHistory
  }

  const attachedReducer = (state = initialState, action) => {
    const innerReducerState = routerReducer(state, action)
    let history = innerReducerState.history
    const newHistory = []
    switch(action.type) {
      case actionTypes.REPLACE_HISTORY:
        history.some(historyItem => {
          return historyItem.key === action.data.key || (newHistory.push(historyItem) && false)
        })
        history = newHistory
        break
      case LOCATION_CHANGE:
        if(action.payload.isFirstRendering) {
          history = [
            { ...action.payload.location }
          ]
        } else {
          switch(action.payload.action) {
            case 'POP':
              history = [...history.slice(0, history.length - 1)]
              break
            case 'PUSH':
              history = [...history, { ...action.payload.location }]
              break
            case 'REPLACE':
              history = [...history.slice(0, history.length - 1), { ...action.payload.location }]
              break
            default:
              return state
          }
        }
        break
      default:
        return state
    }

    return {
      ...innerReducerState,
      history
    }
  }

  return attachedReducer
}

export default historyReducer
