const tableActions = actionType => {
  const actionTypeUpper = actionType.toUpperCase()
  const actionTypes = {
    fetchStart: actionTypeUpper + '_OPTIONS_FETCH_START',
    updateOptions: actionTypeUpper + '_OPTIONS_UPDATE',
    updateOptionsRequest: actionTypeUpper + '_OPTIONS_UPDATE_REQUEST',
    clearOptions: actionTypeUpper + '_OPTIONS_CLEAR',
    createOption: actionTypeUpper + '_OPTIONS_CREATE'
  }
  return {
    actionTypes,
    fetchStart: identifier => {
      return {
        type: actionTypes.fetchStart,
        identifier
      }
    },
    updateOptions: (data, identifier = 'default') => {
      return {
        type: actionTypes.updateOptions,
        data,
        identifier
      }
    },
    clearOptions: (data, identifier = 'default') => {
      return {
        type: actionTypes.clearOptions,
        data,
        identifier
      }
    },
    updateOptionsRequest: data => {
      return {
        type: actionTypes.updateOptionsRequest,
        data
      }
    },
    createOption: identifier => {
      return {
        type: actionTypes.createOption,
        identifier
      }
    }
  }
}

export default tableActions
