import { useEffect } from 'react'
import { useLocation } from 'react-router'

import usePrevious from './usePrevious'

const useTapLocationChange = (tap, isTapEnabled = true) => {
  const location = useLocation()
  const previousPathname = usePrevious(location.pathname)
  const performTap = isTapEnabled && previousPathname !== location.pathname
  useEffect(() => {
    if(performTap) {
      tap()
    }
  }, [performTap, tap])
}

export default useTapLocationChange
