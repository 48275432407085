import Linkify from 'react-linkify'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { dateFormats, withFormat } from '@evelia/helpers/dateHelpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { getUrl } from '../../actions/systemMessageActions'
import { statusStateMap } from '../../constants'
import { systemMessagePropType } from '../../propTypes'
import { findEmployeeNameWithId } from '../../selectors/employeeSelectors'
import SystemMessageConfirmButton from './SystemMessageConfirmButton'
import { systemMessageSendTypeMap } from './systemMessageHelpers'
import SystemMessageSystemDetail from './SystemMessageSystemDetail'

const SystemMessageNotificationRow = ({ systemMessage }) => {
  const createdByName = useSelector(state => findEmployeeNameWithId(state, systemMessage.createdBy))
  const { text, icon } = systemMessageSendTypeMap[systemMessage.sendType]
  const status = statusStateMap[systemMessage.status]
  const statusMessage = systemMessage.statusMessage?.message || systemMessage.statusMessage || ''
  const date = systemMessage.sendedAt || systemMessage.sendAt

  return (
    <li
      className='px-1 py-3 border-bottom'
      key={systemMessage.id}
    >
      <div className='d-flex justify-content-between'>
        <Link to={getUrl(systemMessage.id)} data-testid='systemMessageTitle'>
          <div>
            <small className='text-muted'>
              <FontAwesomeIcon icon={icon} title={text} />
            </small>
            {' '}
            <b className='text-body'>{systemMessage.title || text}</b>
          </div>
        </Link>
        <SystemMessageConfirmButton size='xs' ids={systemMessage.id} color='secondary' />
      </div>

      <div>
        <small>
          {date
            ? withFormat(date, dateFormats.finnishDateTimeFormatShort)
            : 'Ei lähetysaikaa'}
        </small>
        {' '}
        <small className={`text-${status.color}`} title={statusMessage}>
          {status.text}
        </small>
      </div>

      <Linkify>
        <span className='text-pre-wrap'>
          {systemMessage.message}
        </span>
      </Linkify>
      <div>
        <small className='text-muted'>
          {systemMessage.isSystem
            ? <SystemMessageSystemDetail />
            : createdByName}
        </small>
      </div>
    </li>
  )
}

SystemMessageNotificationRow.propTypes = {
  systemMessage: systemMessagePropType.isRequired
}

export default SystemMessageNotificationRow
