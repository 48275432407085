import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import timeRecordActions from '../../actions/timeRecordActions'
import useFetch from '../../hooks/useFetch'
import { findCurrentTimeRecordType } from '../../selectors/timeRecordSelectors'
import TimeRecordTypeBadge from './TimeRecordTypeBadge'

const TimeRecordIndicator = () => {
  const fetchOptions = useMemo(() => ({ data: { subItem: 'big_brother' } }), [])
  useFetch(timeRecordActions.fetchRequest, fetchOptions)
  const currentTimeRecordType = useSelector(findCurrentTimeRecordType)

  return <TimeRecordTypeBadge timeRecordType={currentTimeRecordType} />
}

export default TimeRecordIndicator
