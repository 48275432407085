import React, { Component, Suspense } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as Sentry from '@sentry/react'

import { sagaErrorPropType } from '../../propTypes'
import { InternalErrorPage } from '../Errors'
import GatewayTimeoutErrorPage from '../Errors/GatewayTimeoutErrorPage'
import OutdatedErrorPage from '../Errors/OutdatedErrorPage'
import LoadingSpinner from '../Loading/LoadingSpinner'

const LoginPage = React.lazy(() => import('../../containers/LoginPage'))

const isChunkLoadError = error =>
  error?.name === 'ChunkLoadError' ||
    error?.message?.toLowerCase().includes('failed to fetch dynamically imported module') ||
    error?.message?.toLowerCase().includes('error loading dynamically imported module') ||
    error?.message?.includes('\'text/html\' is not a valid JavaScript MIME type') ||
    error?.message?.includes('Unable to preload CSS for')

export class MainErrorBoundary extends Component {
  state = {}

  componentDidCatch(error, errorInfo) {
    const errorBoundaryError = new Error(error.message)
    errorBoundaryError.name = `React ErrorBoundary ${errorBoundaryError.name}`
    errorBoundaryError.stack = errorInfo.componentStack

    // Using the `LinkedErrors` integration to link the errors together.
    error.cause = errorBoundaryError
    this.setState({ hasError: true, error })
    if(import.meta.env.MODE !== 'development' && !isChunkLoadError(error)) {
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo)
        const eventId = Sentry.captureException(error)
        this.setState({ eventId })
      })
    }
  }

  getErrorPage = () => {
    const { hasError, error } = this.state
    const { errors } = this.props
    if(hasError) {
      return isChunkLoadError(error) ? OutdatedErrorPage : InternalErrorPage
    } else {
      switch(errors.status) {
        case 401:
          return LoginPage
        case 504:
          return GatewayTimeoutErrorPage
        default:
          return null
      }
    }
  }

  render() {
    const { renderMainContent } = this.props
    const { eventId } = this.state
    const ErrorPage = this.getErrorPage()
    return ErrorPage
      ? (
        <Suspense fallback={<LoadingSpinner testId='loader' />}>
          <ErrorPage eventId={eventId} />
        </Suspense>
        )
      : renderMainContent()
  }

  static propTypes = {
    renderMainContent: PropTypes.func.isRequired,
    errors: sagaErrorPropType
  }
}

const mapStateToProps = ({ errors }) => ({
  errors
})

export default connect(mapStateToProps)(MainErrorBoundary)
