import { all, put, takeEvery } from 'redux-saga/effects'

import employeeActions from '../actions/employeeActions'
import machineActions from '../actions/machineActions'
import machineRecordActions from '../actions/machineRecordActions'
import { showActionPrompt } from '../actions/uiActions'
import workActions from '../actions/workActions'
import {
  machineRecordApi,
  normalizeMachineRecords,
  startMachineRecord,
  stopMachineRecord
} from '../api/machineRecordApi'
import machineRecordTitles, { machineRecordTitlesCapitalized } from '../components/MachineRecords/machineRecordTitles'
import { machineTitlesCapitalized } from '../components/Machines/machineTitles'
import { uiTypes } from '../constants'
import { getMemoSagas } from '../helpers/generalSagas'
import {
  createFlow,
  createSocketWatcherWithApiHandlerAndNormalizer,
  createSocketWatcherWithGenerator,
  deleteFlow,
  fetchFlow,
  updateFlow
} from '../helpers/sagaHelpers'
import { handleEmployeeApiResponse } from './employeeSaga'
import { handleMachineApiResponse } from './machineSaga'
import { handleWorkApiResponse } from './workSaga'

const titles = machineRecordTitles
const titlesCapitalized = machineRecordTitlesCapitalized
const reduxName = 'machineRecords'

const handleMachineRecordApiResponse = (mainAction, tableIdentifier) =>
  function* ({
    data,
    work,
    machines,
    employees,
    tableOptions
  }) {
    yield put(mainAction(data))

    if(work) {
      yield handleWorkApiResponse(workActions.fetchSuccess)(work)
    }
    if(machines) {
      yield handleMachineApiResponse(machineActions.fetchSuccess)(machines)
    }
    if(employees) {
      yield handleEmployeeApiResponse(employeeActions.fetchSuccess)(employees)
    }

    if(tableIdentifier != null && tableOptions && tableOptions.orderBy != null) {
      yield put(machineRecordActions.tableActions.updateOptions(tableOptions, tableIdentifier))
    }
    return data
  }

const watchOnMachineRecordSockets = createSocketWatcherWithApiHandlerAndNormalizer('machineRecord', machineRecordActions, handleMachineRecordApiResponse, normalizeMachineRecords)

const watchOnMachineRecordExtraSockets = createSocketWatcherWithGenerator('machineRecord', {
  promptDelete: function* (data) {
    yield put(showActionPrompt(uiTypes.PROMPT_DELETE_MACHINE_RECORD, data))
  }
})

const machineRecordFetchFlow = fetchFlow({
  fetchApi: machineRecordApi.fetch,
  actions: machineRecordActions,
  base: reduxName,
  idField: 'id',
  errorMsg: titles.genetive,
  getApiResponseHandler: data => handleMachineRecordApiResponse(machineRecordActions.fetchSuccess, data.tableIdentifier)
})

const machineRecordUpdateFlow = updateFlow(machineRecordApi.update, machineRecordActions, titlesCapitalized.basic, titlesCapitalized.genetive, handleMachineRecordApiResponse(machineRecordActions.updateSuccess))
const machineRecordCreateFlow = createFlow(machineRecordApi.create, machineRecordActions, titlesCapitalized.basic, titlesCapitalized.genetive, handleMachineRecordApiResponse(machineRecordActions.createSuccess))
const machineRecordDeleteFlow = deleteFlow({
  deleteApi: machineRecordApi.remove,
  actions: machineRecordActions,
  singular: titlesCapitalized.basic,
  errorMsg: titlesCapitalized.genetive,
  base: reduxName
})

const memoSagas = getMemoSagas({
  actions: machineRecordActions,
  baseName: 'machineRecords',
  apiBaseUrl: 'machine_records',
  socketName: 'machineRecord',
  titleGenetive: titles.genetive
})

const createStartStopMessages = (isStart, isSuccess) => () => {
  if(isSuccess) {
    return `${machineTitlesCapitalized.basic} ${isStart ? 'otettu käyttöön' : 'päätetty'}`
  }
  return `${machineTitlesCapitalized.genetive} ${isStart ? 'käyttöönotto' : 'päättäminen'} epäonnistui`
}

const machineRecordStartFlow = createFlow(
  startMachineRecord,
  machineRecordActions,
  createStartStopMessages(true, true),
  createStartStopMessages(true, false),
  handleMachineRecordApiResponse(machineRecordActions.createSuccess)
)
const machineRecordStopFlow = updateFlow(
  stopMachineRecord,
  machineRecordActions,
  createStartStopMessages(false, true),
  createStartStopMessages(false, false),
  handleMachineRecordApiResponse(machineRecordActions.updateSuccess))

export default function* machineRecordSaga() {
  yield takeEvery(machineRecordActions.actionTypes.fetchRequest, machineRecordFetchFlow)
  yield takeEvery(machineRecordActions.actionTypes.updateRequest, machineRecordUpdateFlow)
  yield takeEvery(machineRecordActions.actionTypes.createRequest, machineRecordCreateFlow)
  yield takeEvery(machineRecordActions.actionTypes.deleteRequest, machineRecordDeleteFlow)

  yield takeEvery(machineRecordActions.actionTypes.startRecordRequest, machineRecordStartFlow)
  yield takeEvery(machineRecordActions.actionTypes.stopRecordRequest, machineRecordStopFlow)

  yield all([
    memoSagas(),
    watchOnMachineRecordSockets(),
    watchOnMachineRecordExtraSockets()
  ])
}
