import workRecordProductActions from '../actions/workRecordProductActions'
import constants from '../constants'
import { getCombinedListReducerFor, getStatsReducer } from '../helpers/reducerHelpers'

const initialStats = {
  workRecordProductId: null,
  workRecordCount: 0,
  cashReceiptRowCount: 0
}

export default getCombinedListReducerFor(constants.actionKeys.WORK_RECORD_PRODUCTS, {
  workRecordProductSalaryTypes: getCombinedListReducerFor(constants.actionKeys.WORK_RECORD_PRODUCT_SALARY_TYPES, {}, '_id'),
  workRecordProductPricingRules: getCombinedListReducerFor(constants.actionKeys.WORK_RECORD_PRODUCT_PRICING_RULES),
  stats: getStatsReducer(workRecordProductActions, initialStats)
})
