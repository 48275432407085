import {
  createSlice,
  EntityAdapter,
  EntityId,
  EntityState,
  PayloadAction,
  SliceCaseReducers,
  ValidateSliceCaseReducers
} from '@reduxjs/toolkit'
import noop from 'lodash/noop'

export interface GenericState<T, Id extends EntityId = number> extends EntityState<T, Id> {
  isLoading: boolean
}

interface CreateGenericSliceProps<T, Id extends EntityId, Reducers extends SliceCaseReducers<GenericState<T, Id>>, TBase = T> {
  name: string
  reducers?: ValidateSliceCaseReducers<GenericState<T, Id>, Reducers>
  entityAdapter: EntityAdapter<T, Id>
  defaultModel?: TBase
  extraState?: Record<string, unknown>
}

// Just a typing wrapper helper to use createGenericSlice with generic typings, like: ReturnType<CreateGenericSliceWrapper<TDataModel>['createSlice']>
// https://stackoverflow.com/questions/50321419/typescript-returntype-of-generic-function
class CreateGenericSliceWrapper<T, Id extends EntityId = EntityId, Reducers extends SliceCaseReducers<GenericState<T, Id>> = SliceCaseReducers<GenericState<T, Id>>, TBase = T> {
  createSlice(props: CreateGenericSliceProps<T, Id, Reducers, TBase>) {
    return createGenericSlice<T, Id, Reducers, TBase>(props)
  }
}
export type CreateGenericSliceType<TDataModel> = ReturnType<CreateGenericSliceWrapper<TDataModel>['createSlice']>

export const createGenericSlice = <
  T,
  Id extends EntityId,
  Reducers extends SliceCaseReducers<GenericState<T, Id>>,
  TBase = T
>({
    name = '',
    reducers,
    entityAdapter,
    defaultModel: __defaultModel,
    extraState
  }: CreateGenericSliceProps<T, Id, Reducers, TBase>
  ) => {
  return createSlice({
    name,
    initialState: {
      ...entityAdapter.getInitialState(),
      isLoading: false,
      ...extraState
    },
    reducers: {
      fetchRequest: (state, __action: PayloadAction<object>) => state, // for type detection
      fetchStart: state => {
        state.isLoading = true
      },
      fetchSuccess: (state, action) => {
        state.isLoading = false
        Array.isArray(action.payload) ? entityAdapter.upsertMany(state, action) : entityAdapter.upsertOne(state, action)
      },
      fetchError: state => {
        state.isLoading = false
      },
      createRequest: (state, __action: PayloadAction<Record<string, unknown> & { record: TBase }>) => state,
      createStart: noop,
      createSuccess: (state, action) => {
        state.isLoading = false
        Array.isArray(action.payload) ? entityAdapter.addMany(state, action.payload) : entityAdapter.addOne(state, action)
      },
      createError: noop,
      updateRequest: (state, __action: PayloadAction<Record<string, unknown> & { record: T }>) => state,
      updateStart: noop,
      updateSuccess: (state, action) => {
        state.isLoading = false
        entityAdapter.upsertOne(state, action)
      },
      updateError: noop,
      deleteRequest: (state, __action: PayloadAction<Record<string, unknown> & { record: T }>) => state,
      deleteStart: noop,
      deleteSuccess: (state, action) => {
        const id = entityAdapter.selectId(action.payload)
        state.isLoading = false
        entityAdapter.removeOne(state, id)
      },
      deleteError: noop,
      ...reducers
    }

  })
}
