import { parseTemplate } from 'url-template'

import { del, get, post } from '../helpers/apiHelpers'
import { optionsQueryTerms } from './urlTemplates'

const baseUrlQueryTerms = ['pricingRuleSetId', ...optionsQueryTerms]

const baseUrl = `/api/quick_products{/quickProductId}{?${baseUrlQueryTerms.join(',')}}`
const quickProductUrlTemplate = parseTemplate(baseUrl)

export const normalizeQuickProductResponse = response => {
  const { _embedded, records, record } = response
  const { products } = _embedded || {}

  const data = records || record
  return {
    data,
    products: products || []
  }
}

export const fetchQuickProducts = (params = {}) => {
  return get(quickProductUrlTemplate.expand(params))
    .then(normalizeQuickProductResponse)
}

export const createQuickProduct = quickProduct => {
  return post(quickProductUrlTemplate.expand({}), {
    body: JSON.stringify(quickProduct)
  }).then(normalizeQuickProductResponse)
}

export const deleteQuickProduct = quickProduct => {
  return del(quickProductUrlTemplate.expand({ quickProductId: quickProduct.id }))
}
