import PropTypes from 'prop-types'

const RequiredFieldIndicator = ({ isRequired = true }) =>
  isRequired && <small className='text-danger'>*</small>

RequiredFieldIndicator.propTypes = {
  isRequired: PropTypes.bool
}

export default RequiredFieldIndicator
