import PropTypes from 'prop-types'
import { Tooltip } from 'reactstrap'

import useToggle from '../../hooks/useToggle'

const TooltipComponent = ({
  id,
  children,
  message,
  placement = 'right',
  innerClassName,
  ...rest
}) => {
  const [tooltipOpen, { toggle }] = useToggle(false)

  return (
    <Tooltip
      placement={placement}
      isOpen={tooltipOpen}
      target={id}
      toggle={toggle}
      innerClassName={`text-start ${innerClassName}`}
      {...rest}
    >
      {children || message}
    </Tooltip>
  )
}

TooltipComponent.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.node,
  placement: PropTypes.string,
  children: PropTypes.node,
  innerClassName: PropTypes.string
}

export default TooltipComponent
