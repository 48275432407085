import { all, put, takeEvery, takeLatest } from 'redux-saga/effects'

import customerActions from '../actions/customerActions'
import targetActions from '../actions/targetActions'
import workActions from '../actions/workActions'
import workRecurrenceRuleActions from '../actions/workRecurrenceRuleActions'
import { workRecurrenceRuleApi } from '../api/workRecurrenceRuleApi'
import {
  createFlow,
  createSocketWatcher,
  deleteFlow,
  fetchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

const watchOnWorkRecurrenceRuleSockets = createSocketWatcher('workRecurrenceRule', {
  created: workRecurrenceRuleActions.createSuccess,
  updated: workRecurrenceRuleActions.updateSuccess,
  deleted: workRecurrenceRuleActions.deleteSuccess
})

const handleWorkRecurrenceRuleApiResponse = mainAction =>
  function* ({ data, work, customers, targets }) {
    yield put(mainAction(data))
    if(work) {
      yield put(workActions.fetchSuccess(work))
    }
    if(customers) {
      yield put(customerActions.fetchSuccess(customers))
    }
    if(targets) {
      yield put(targetActions.fetchSuccess(targets))
    }
    return data
  }

const workRecurrenceRuleFetchFlow = fetchFlow({
  fetchApi: workRecurrenceRuleApi.fetch,
  actions: workRecurrenceRuleActions,
  base: 'workRecurrenceRules',
  errorMsg: 'Toistuvan työn sääntöjen',
  getApiResponseHandler: () => handleWorkRecurrenceRuleApiResponse(workRecurrenceRuleActions.fetchSuccess)
})

const workRecurrenceRuleUpdateFlow = updateFlow(workRecurrenceRuleApi.update, workRecurrenceRuleActions, 'Toistuvan työn sääntö', 'Toistuvan työn sääntöjen', handleWorkRecurrenceRuleApiResponse(workRecurrenceRuleActions.updateSuccess))
const workRecurrenceRuleCreateFlow = createFlow(workRecurrenceRuleApi.create, workRecurrenceRuleActions, 'Toistuvan työn sääntö', 'Toistuvan työn sääntöjen', handleWorkRecurrenceRuleApiResponse(workRecurrenceRuleActions.createSuccess))
const workRecurrenceRuleDeleteFlow = deleteFlow({
  deleteApi: workRecurrenceRuleApi.remove,
  actions: workRecurrenceRuleActions,
  singular: 'Toistuvan työn sääntö',
  errorMsg: 'Toistuvan työn sääntöjen',
  base: 'workRecurrenceRules'
})

export default function* workRecurrenceRuleSaga() {
  yield takeLatest(workRecurrenceRuleActions.actionTypes.fetchRequest, workRecurrenceRuleFetchFlow)
  yield takeEvery(workRecurrenceRuleActions.actionTypes.updateRequest, workRecurrenceRuleUpdateFlow)
  yield takeEvery(workRecurrenceRuleActions.actionTypes.createRequest, workRecurrenceRuleCreateFlow)
  yield takeEvery(workRecurrenceRuleActions.actionTypes.deleteRequest, workRecurrenceRuleDeleteFlow)

  yield all([
    watchOnWorkRecurrenceRuleSockets()
  ])
}
