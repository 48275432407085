import { Component } from 'react'
import PropTypes from 'prop-types'

import NotFoundErrorPage from '../../components/Errors/NotFoundErrorPage'
import { NotFoundError } from '../../helpers/errors'

export class RoutesErrorBoundary extends Component {
  state = {}

  static getDerivedStateFromError(error) {
    if(error instanceof NotFoundError) {
      return { hasError: true, error }
    } else {
      return { hasError: false, error: null }
    }
  }

  getErrorPage = () => {
    const { hasError, error } = this.state

    if(hasError && error instanceof NotFoundError) {
      return NotFoundErrorPage
    }
    return null
  }

  render() {
    const { renderContent } = this.props
    const ErrorPage = this.getErrorPage()
    return ErrorPage
      ? <ErrorPage />
      : renderContent()
  }

  static propTypes = {
    renderContent: PropTypes.func.isRequired
  }
}

export default RoutesErrorBoundary
