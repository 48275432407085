import { TypeaheadMenu } from 'react-bootstrap-typeahead'

const renderTypeaheadMenu = (results, menuProps, typeaheadProps) =>
  (!menuProps.emptyLabel && !results.length)
    ? null
    : (
      <TypeaheadMenu
        {...menuProps}
        className={typeaheadProps.menuClassName}
        options={results}
        text={typeaheadProps.text}
        labelKey={typeaheadProps.labelKey}
      />
      )

export default renderTypeaheadMenu
