import PropTypes from 'prop-types'

import ProductLineCodeBadge from './ProductLineCodeBadge'

const ProductLineCodeAndProductNumber = ({ productLineCode, productNumber, badgeColor, darkText }) => (
  <>
    <ProductLineCodeBadge productLineCode={productLineCode} color={badgeColor} darkText={darkText} />
    {productNumber}
  </>
)

ProductLineCodeAndProductNumber.propTypes = {
  productLineCode: PropTypes.string,
  productNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  badgeColor: PropTypes.string,
  darkText: PropTypes.bool
}

export default ProductLineCodeAndProductNumber
