import { createApi, defaultEmbeddedNormalizer } from '../helpers/apiHelpers'
import { parseFilterParams } from '../helpers/reducerHelpers'
import { optionsQueryBase } from './urlTemplates'

export const normalizeCostCentres = response => defaultEmbeddedNormalizer(response)

export const costCentreApi = createApi({
  base: 'cost_centres',
  normalizer: normalizeCostCentres,
  extraUrlTemplate: optionsQueryBase,
  editGetParams: params =>
    params.costCentreGroupId
      ? {
          ...params,
          base: 'cost_centre_groups',
          id: params.costCentreGroupId,
          subItem: 'cost_centres'
        }
      : parseFilterParams(params),
  editSearchParams: parseFilterParams
})

export const costCentreGroupApi = createApi({
  base: 'cost_centre_groups',
  normalizer: defaultEmbeddedNormalizer
})
