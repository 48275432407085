import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { faAngleRight, faExchangeAlt } from '@fortawesome/free-solid-svg-icons'

import loginActions from '../../actions/loginActions'
import { hideActionPrompt } from '../../actions/uiActions'
import IconButton from '../Buttons/IconButton'
import ActionPromptModal from './ActionPromptModal'

const SwitchSystemCustomerPrompt = ({ visible, data, switchToSystemCustomer }) => {
  const handleClick = systemCustomerId => () => switchToSystemCustomer(systemCustomerId)
  return (
    <ActionPromptModal
      visible={visible}
      onCancel={handleClick(data.from)}
      renderBody={() => (
        <>
          Yritys on vaihdettu toisessa välilehdessä!<br />
          Haluatko siirtyä uuden yrityksen sivulle vai jatkaa nykyisellä?
        </>
      )}
      renderFooter={() => (
        <>
          <IconButton icon={faExchangeAlt} condensed color='success' onClick={handleClick(data.to)}>Siirry uuteen</IconButton>
          <IconButton
            icon={faAngleRight}
            condensed
            outline
            color='danger'
            onClick={handleClick(data.from)}
          >Käytä nykyistä
          </IconButton>
        </>
      )}
    />
  )
}

SwitchSystemCustomerPrompt.propTypes = {
  visible: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    from: PropTypes.number.isRequired,
    to: PropTypes.number.isRequired
  }),
  switchToSystemCustomer: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
  switchToSystemCustomer: systemCustomerId => {
    dispatch(hideActionPrompt())
    dispatch(loginActions.switchSystemCustomerRequest({ systemCustomerId }))
  }
})

export default connect(null, mapDispatchToProps)(SwitchSystemCustomerPrompt)
