import { createApi, defaultEmbeddedNormalizer } from '../helpers/apiHelpers'
import { BaseIdModel } from './types/baseModelTypes'

export type InsuranceModel = {
  insuranceCompanyId: number
  startDate: string
  endDate?: string
} & BaseIdModel

export const insuranceApi = createApi({
  base: 'insurances',
  normalizer: defaultEmbeddedNormalizer
})
