import { parseTemplate } from 'url-template'

import {
  createApi,
  defaultEmbeddedNormalizer,
  defaultNormalizer,
  get,
  getSubApi,
  post
} from '../helpers/apiHelpers'
import { parseFilterParams, parseTableOptionsFromQuery } from '../helpers/reducerHelpers'
import { optionsQueryBase } from './urlTemplates'
import { normalizeWhoAmI } from './whoAmIApi'

const baseUrl = '/api/packets{/packetId}'

const packetSubItemUrlTemplate = parseTemplate(baseUrl + '{/subItem,subItemId,subItemType}')
const packetInstallationsUrlTemplate = parseTemplate(baseUrl + '/installations{/packetInstallationId}')

export const normalizePackets = response => {
  const { records, record, _embedded } = response
  const {
    installations,
    packetInstallations,
    options
  } = _embedded || {}
  const data = records || record
  return {
    data,
    installations,
    packetInstallations,
    tableOptions: parseTableOptionsFromQuery(options)
  }
}

export const packetApi = createApi({
  base: 'packets',
  normalizer: normalizePackets,
  extraUrlTemplate: optionsQueryBase,
  editGetParams: parseFilterParams
})

export const installationApi = createApi({
  base: 'packets/installations',
  normalizer: defaultNormalizer
})

export const fetchInstallationContents = params =>
  get(packetApi.baseUrlTemplate.expand(params))
    .then(defaultEmbeddedNormalizer)

export const packetInstallationContentApi = createApi({
  base: 'packets',
  normalizer: defaultNormalizer,
  extraUrlTemplate: '{/subItemActionId}'
})

const normalizePacketInstallations = response => {
  const { _embedded, records, record } = response
  const {
    installations
  } = _embedded || {}
  const data = records || record
  return {
    data,
    installations
  }
}

export const {
  fetchApi: fetchPacketInstallations,
  createApi: createPacketInstallation,
  updateApi: updatePacketInstallation,
  deleteApi: deletePacketInstallation
} = getSubApi(packetInstallationsUrlTemplate, 'packetInstallationId', normalizePacketInstallations)

export const authenticateStulApi = (data, params) => post(packetSubItemUrlTemplate.expand({ subItemType: 'authenticate_stul' }), {
  body: JSON.stringify(data)
}).then(normalizeWhoAmI)

export const packetGroupApi = createApi({
  base: 'packets/packet_groups',
  normalizer: defaultNormalizer
})
