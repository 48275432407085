import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'

interface LoadingSpinnerProps extends Omit<FontAwesomeIconProps, 'icon' | 'spin'> {
  className?: string
  testId?: string
}

const LoadingSpinner = ({ className, testId, ...props }: LoadingSpinnerProps) => (
  <FontAwesomeIcon
    {...props}
    data-testid={testId}
    icon={faSpinner}
    className={clsx('mx-1 text-success', className)}
    spin
  />
)

export default LoadingSpinner
