import { all, takeEvery, takeLatest } from 'redux-saga/effects'

import productGroupActions from '../actions/productGroupActions'
import {
  createProductGroup,
  deleteProductGroup,
  fetchProductGroups,
  updateProductGroup
} from '../api/productGroupApi'
import {
  createFlow,
  createSocketWatcher,
  deleteFlow,
  fetchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

const watchOnProductGroupSockets = createSocketWatcher('productGroup', {
  created: productGroupActions.createSuccess,
  updated: productGroupActions.updateSuccess,
  deleted: productGroupActions.deleteSuccess
})

const productGroupFetchFlow = fetchFlow({
  fetchApi: fetchProductGroups,
  actions: productGroupActions,
  base: 'productGroups',
  errorMsg: 'Tuoteryhmien'
})

const productGroupUpdateFlow = updateFlow(updateProductGroup, productGroupActions, 'Tuoteryhmä', 'Tuoteryhmän')
const productGroupCreateFlow = createFlow(createProductGroup, productGroupActions, 'Tuoteryhmä', 'Tuoteryhmän')
const productGroupDeleteFlow = deleteFlow({
  deleteApi: deleteProductGroup,
  actions: productGroupActions,
  singular: 'Tuoteryhmä',
  errorMsg: 'Tuoteryhmän',
  base: 'productGroups'
})

export default function* productGroupSaga() {
  yield takeLatest(productGroupActions.actionTypes.fetchRequest, productGroupFetchFlow)
  yield takeEvery(productGroupActions.actionTypes.updateRequest, productGroupUpdateFlow)
  yield takeEvery(productGroupActions.actionTypes.createRequest, productGroupCreateFlow)
  yield takeEvery(productGroupActions.actionTypes.deleteRequest, productGroupDeleteFlow)

  yield all([
    watchOnProductGroupSockets()
  ])
}
