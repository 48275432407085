import PropTypes from 'prop-types'

const ErrorPage = ({ statusCode, message, eventId }) => (
  <div className='text-center px-1 py-5' data-testid='errorPage'>
    <h1 className='display-1'>{statusCode}</h1>
    {message}
    {eventId && <small>Sentry event id: {eventId}</small>}
  </div>
)

ErrorPage.propTypes = {
  statusCode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  message: PropTypes.node.isRequired,
  eventId: PropTypes.string
}

export default ErrorPage
