import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getRunningWorkRecords } from '../../selectors/workRecordSelectors'

class WorkRecordNavBadge extends Component {
  static propTypes = {
    count: PropTypes.number.isRequired
  }

  render() {
    if(!this.props.count) {
      return null
    }
    return (
      <span data-testid='runningWorkRecordCountIndicator' className='fa-layers-counter fa-2x'>{this.props.count}</span>
    )
  }
}

const mapStateToProps = state => ({
  count: getRunningWorkRecords(state).length
})

export default connect(mapStateToProps)(WorkRecordNavBadge)
