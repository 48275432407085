import { parseTemplate } from 'url-template'

import constants from '../constants'
import { actionCreatorsFor } from '../helpers/actionHelpers'
import { PATH_INBOUND_MESSAGES } from '../routes'
import { navigateTo } from './navigateActions'

const inboundMessageTemplate = parseTemplate(`${PATH_INBOUND_MESSAGES}{/inboundMessageId}`)

const baseActionCreators = actionCreatorsFor(constants.actionKeys.INBOUND_MESSAGES)

const additionalActionCreators = {
  navigateToInboundMessage: (inboundMessageId, replaceUrl) => navigateTo(inboundMessageTemplate.expand({ inboundMessageId: inboundMessageId || 'new' }), replaceUrl),
  navigateToInboundMessageBase: replaceUrl => navigateTo(inboundMessageTemplate.expand({}), replaceUrl)
}

const inboundMessageActions = { ...baseActionCreators, ...additionalActionCreators }
export default inboundMessageActions
