type Props = {
  index: number
  page: number
  pageCount: number
  setPage: (page: number) => void
}

const DataTablePageNumberItem = ({ index, page, pageCount, setPage }: Props) => {
  const isSelectedPage = index === page
  const isFirstPageSelected = page === 0
  const isFirstPage = index === 0
  const isLastPage = index === pageCount - 1
  const isNextToSelectedPage = Math.abs(page - index) === 1
  const isTwoPagesFromSelectedPage = Math.abs(page - index) === 2
  const isThreePagesFromSelectedPage = Math.abs(page - index) === 3

  if(isFirstPage || isLastPage || isSelectedPage || isNextToSelectedPage || (isFirstPageSelected && isTwoPagesFromSelectedPage)) {
    return (
      <li
        key={index}
        className={`page-item cursor-pointer ${isSelectedPage ? 'active disabled' : ''}`}
      >
        <button className='page-link' onClick={() => setPage(index)}>
          {index + 1}
        </button>
      </li>
    )
  } else if((isFirstPageSelected && isThreePagesFromSelectedPage) || (!isNextToSelectedPage && isTwoPagesFromSelectedPage)) {
    return (
      <li
        key={index}
        className='page-item cursor-pointer'
      >
        <button className='page-link'>
          ...
        </button>
      </li>
    )
  }

  return null
}

export default DataTablePageNumberItem
