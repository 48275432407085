import React from 'react'
import PropTypes from 'prop-types'
import { AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap'

import { childrenPropType } from '../../propTypes'

const AccordionElement = ({
  accordionId,
  isOpen,
  headerText,
  children,
  testId
}) => {
  return (
    <AccordionItem data-testid={testId}>
      <AccordionHeader
        className='position-static'
        targetId={accordionId}
        data-testid={`${testId}Header`}
      >
        {isOpen ? 'Piilota' : 'Näytä'} {headerText}
      </AccordionHeader>
      <AccordionBody className='text-break' accordionId={accordionId} data-testid={`${testId}Body`}>
        {children}
      </AccordionBody>
    </AccordionItem>
  )
}

AccordionElement.propTypes = {
  accordionId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  headerText: PropTypes.string.isRequired,
  children: childrenPropType,
  testId: PropTypes.string
}

export default React.memo(AccordionElement)
