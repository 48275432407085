import contactActions from '../actions/contactActions'
import constants from '../constants'
import { getCombinedListReducerFor, getStatsReducer } from '../helpers/reducerHelpers'

const initialStats = {
  contactId: null,
  workCount: 0,
  projectCount: 0,
  offerCount: 0,
  contactTargetCount: 0,
  contactCustomerCount: 0,
  fileCount: 0
}

export default getCombinedListReducerFor(constants.actionKeys.CONTACTS, {
  files: getCombinedListReducerFor(constants.actionKeys.CONTACT_FILES),
  customers: getCombinedListReducerFor(constants.actionKeys.CONTACT_CUSTOMERS),
  targets: getCombinedListReducerFor(constants.actionKeys.CONTACT_TARGETS),
  stats: getStatsReducer(contactActions, initialStats),
  contactExtras: getCombinedListReducerFor(constants.actionKeys.CONTACT_EXTRAS)
})
