import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { NavLink } from 'reactstrap'
import { appendEitherOrEmpty } from '@evelia/helpers/helpers'
import { faClock, faPlusCircle, faUserTie } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { actionKeys } from '../../constants'
import { PATH_CREDIT_ACTIONS, PATH_USER_ACCOUNT } from '../../routes'
import { findCurrentEmployee } from '../../selectors/employeeSelectors'
import Feature from '../Authentication/Feature'
import { FinancePermission } from '../Authentication/permissionWrappers'
import IconButton from '../Buttons/IconButton'
import TimeRecordChooser from '../TimeRecords/TimeRecordChooser'
import SystemCustomerChooser from './SystemCustomerChooser'

const DAYS_TO_SHOW_CREDIT_WARNING = 30

const CreditIndicator = ({ remainingDays }) => {
  if(remainingDays == null || remainingDays > DAYS_TO_SHOW_CREDIT_WARNING) {
    return null
  }
  const iconClass = remainingDays <= 0 ? 'text-danger' : 'text-primary'
  const buttonColor = remainingDays <= 0 ? 'danger' : 'primary'
  const infoText = remainingDays <= 0
    ? 'Käyttöaika ylitetty!'
    : `Käyttöaikaa jäljellä ${remainingDays} päivää.`
  return (

    <FinancePermission>
      <NavLink tag='span' className='d-flex align-items-center text-light'>
        <FontAwesomeIcon icon={faClock} className={iconClass} />
        <span className='mx-2'>
          {infoText}
        </span>
        <Link to={PATH_CREDIT_ACTIONS}>
          <IconButton size='xs' condensed color={buttonColor} icon={faPlusCircle}>
            Uusi nyt
          </IconButton>
        </Link>
      </NavLink>
    </FinancePermission>
  )
}
CreditIndicator.propTypes = {
  remainingDays: PropTypes.number
}

const ProfileHeader = () => {
  const employee = useSelector(findCurrentEmployee)
  const credits = useSelector(state => state.configs.credits)
  const { isNotInTimeRecords, firstName, lastName } = employee || {}

  return (
    <div className='d-flex align-items-center flex-wrap text-light' data-testid='profileHeader'>
      <NavLink>
        <SystemCustomerChooser className='p-0 text-start' />
      </NavLink>
      <NavLink tag={Link} to={PATH_USER_ACCOUNT}>
        <FontAwesomeIcon icon={faUserTie} className='user-icon me-2' />
        <span className='text-light'>
          {appendEitherOrEmpty(firstName, lastName)}
        </span>
      </NavLink>
      <Feature featureKey={actionKeys.TIME_RECORDS}>
        <NavLink className='p-0' hidden={isNotInTimeRecords}>
          <TimeRecordChooser className='p-0' />
        </NavLink>
      </Feature>
      <CreditIndicator remainingDays={credits?.remainingDays} />
    </div>
  )
}

export default ProfileHeader
