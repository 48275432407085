import { appendEitherOrEmpty } from '@evelia/helpers/helpers'
import sortBy from 'lodash/sortBy'
import { createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'

import { EmployeeLevelModel } from '../api/rtk/employeeLevelsApi'
import { EmployeeModel } from '../api/rtk/employeesApi'
import { accessLevels } from '../constants'
import { getFindItemByIdSelector, getMemoSelector, getSubentitySelectors } from '../helpers/selectorHelpers'
import { EveliaRootState } from '../reducerTypes'
import { findSystemCustomerSettings, getSalaryGroupsFromArgument } from './systemCustomerSelectors'

// Employees selectors

export const getEmployeesFromArgument = createSelector(
  (arg: EveliaRootState) => (arg.employees ? arg.employees.records : arg) as EmployeeModel[],
  employees => sortBy(employees, ['lastName', 'firstName'])
)

type EmployeeFilters = {
  isActive?: boolean | null
  isNotResource?: boolean
  isNonBillable?: boolean
  isNotInSelectors?: boolean
  isNotInTimeRecords?: boolean
}
const keyFilter = (key: keyof EmployeeModel, value: boolean | null | undefined) => (employee: EmployeeModel) => value == null || employee[key] === value

export const findEmployees = createCachedSelector(
  getEmployeesFromArgument,
  (__state: EveliaRootState, filters: EmployeeFilters) => filters?.isActive !== undefined ? filters.isActive : true,
  (__state: EveliaRootState, filters: EmployeeFilters) => filters?.isNotResource,
  (__state: EveliaRootState, filters: EmployeeFilters) => filters?.isNonBillable,
  (__state: EveliaRootState, filters: EmployeeFilters) => filters?.isNotInSelectors,
  (__state: EveliaRootState, filters: EmployeeFilters) => filters?.isNotInTimeRecords,
  (
    employees,
    isActive,
    isNotResource,
    isNonBillable,
    isNotInSelectors,
    isNotInTimeRecords
  ) => {
    const filters = [
      (employee: EmployeeModel) => employee.firstName != null && employee.lastName != null,
      keyFilter('isActive', isActive),
      keyFilter('isNotResource', isNotResource),
      keyFilter('isNonBillable', isNonBillable),
      keyFilter('isNotInSelectors', isNotInSelectors),
      keyFilter('isNotInTimeRecords', isNotInTimeRecords)
    ]
    return employees.filter(employee => filters.every(filter => filter(employee)))
  }
)((__state: EveliaRootState, filters: EmployeeFilters = {}) => JSON.stringify(filters))

export const mapEmployeeOptions = createSelector(
  (employees: EmployeeModel[]) => employees,
  employees => employees
    .map(employee => ({ value: employee.id, text: appendEitherOrEmpty(employee.firstName, employee.lastName) }))
)

export const {
  getSubItemsOfItem: getFilesOfEmployee,
  rejectSubItemsOfItem: rejectFilesOfEmployee
} = getSubentitySelectors('employees', 'files', 'files', 'employeeId', 'fileId')

export const {
  getSubItemsOfItem: getFilesOfEmployeeByEmployeeLicence
} = getSubentitySelectors('employees', 'files', 'files', 'employeeLicenceId', 'fileId')

export const getEffectiveWorkTime = createSelector(
  findSystemCustomerSettings,
  getSalaryGroupsFromArgument,
  (state, { employeeId } = {}) => employeeId ? findEmployeeWithId(state, employeeId) : findCurrentEmployee(state),
  (systemCustomerSettings, salaryGroups, employee) => {
    const employeeSalaryGroup = employee.salaryGroupId ? salaryGroups.find(salaryGroup => salaryGroup.id === employee.salaryGroupId) : null
    return ({
      workTimeStart: employee.workTimeStart || employeeSalaryGroup?.workTimeStart || systemCustomerSettings.settings.workTimeStart,
      workTimeEnd: employee.workTimeEnd || employeeSalaryGroup?.workTimeEnd || systemCustomerSettings.settings.workTimeEnd
    })
  }
)

// Employee selectors

export const findEmployeeWithId = getFindItemByIdSelector(getEmployeesFromArgument) as (state: EveliaRootState, id: number) => EmployeeModel

export const findCurrentEmployee = createSelector(
  (state: EveliaRootState) => findEmployeeWithId(state, state.whoAmI.data.employeeId),
  employee => employee
)

export const findEmployeeNameWithId = createSelector(
  (state: EveliaRootState, employeeId: number) => findEmployeeWithId(state, employeeId),
  employee => employee ? `${employee.firstName} ${employee.lastName}` : ''
)

export const findCurrentEmployeeName = createSelector(
  findCurrentEmployee,
  employee => employee ? `${employee.firstName} ${employee.lastName}` : ''
)

export const getEmployeeLicencesByEmployeeId = createCachedSelector(
  (state: EveliaRootState) => (state.employees as unknown as { employeeLicences: { records: { employeeId: number }[] } }).employeeLicences.records,
  (__state: EveliaRootState, employeeId) => Number(employeeId),
  (employeeLicences, employeeId) => employeeLicences.filter(employeeLicence => employeeLicence.employeeId === employeeId)
)((__state: EveliaRootState, employeeId) => employeeId)

export const getIsOwn = createCachedSelector(
  findCurrentEmployee,
  (__state, employeeId) => employeeId,
  (currentEmployee, employeeId) => currentEmployee.id === employeeId
)((__state, employeeId) => `${employeeId}`)

// Level selectors

const fallbackEmployeeLevel = { id: -1, name: 'Fallback', accessLevel: accessLevels.ALL }
export const getEmployeeLevelsFromArgument = arg => arg.employees ? arg.employees.levels.records : arg

export const findEmployeeLevelWithId = getFindItemByIdSelector(getEmployeeLevelsFromArgument, fallbackEmployeeLevel) as (employeeLevels: EmployeeLevelModel[], id: number) => EmployeeLevelModel

export const getEmployeeLevelsMap = createSelector(
  getEmployeeLevelsFromArgument,
  employeeLevels => employeeLevels.reduce((acc, current) => {
    return {
      ...acc,
      [current.id]: current
    }
  }, {})
)

export const getEmployeeLevelsSorted = createSelector(
  getEmployeeLevelsFromArgument,
  levels => sortBy(levels, 'accessLevel')
)

export const findCurrentEmployeeLevel = createSelector(
  findCurrentEmployee,
  (state: EveliaRootState) => (state.employees as unknown as { levels: { records: EmployeeLevelModel[] } }).levels.records,
  (employee, employeeLevels) => employee ? findEmployeeLevelWithId(employeeLevels, employee.employeeLevelId) : fallbackEmployeeLevel
)

export const getUserSelectableEmployeeLevels = createSelector(
  getEmployeeLevelsSorted,
  findCurrentEmployeeLevel,
  (employeeLevels, currentEmployeeLevel) => {
    return employeeLevels.filter(({ accessLevel }) => currentEmployeeLevel.accessLevel >= accessLevel)
  }
)

export const getHasAccessLevel = createCachedSelector(
  findCurrentEmployeeLevel,
  (__state, minAccessLevel) => minAccessLevel,
  (employeeAccessLevel, minAccessLevel) => employeeAccessLevel.accessLevel >= minAccessLevel
)((__state, minAccessLevel) => minAccessLevel)

export const getIsSupervisor = createSelector(
  state => getHasAccessLevel(state, accessLevels.SUPERVISOR),
  isSupervisor => isSupervisor
)

// Role selectors

export const getEmployeeRolesOfEmployee = createCachedSelector(
  findEmployeeWithId,
  state => state.employeeRoles.records,
  (employee, employeeRoles) => employeeRoles.filter(employeeRole => employee.employeeRoleIds.includes(employeeRole.id))
)((__state, employeeId) => `${employeeId}`)

export const findEmployeesWithRoles = createCachedSelector(
  (state, __employeeRoles, employees) => employees || getEmployeesFromArgument(state),
  (__state, employeeRoles, __employees) => employeeRoles.map(employeeRole => Number.isInteger(employeeRole) ? employeeRole : employeeRole.id),
  (employees, employeeRoleIds) => {
    return employees.filter(employee => employeeRoleIds.some(employeeRoleId => employee.employeeRoleIds.includes(employeeRoleId)))
  }
)((__state, employeeRoles, __employees) => employeeRoles.map(employeeRole => Number.isInteger(employeeRole) ? employeeRole : employeeRole.id).join(','))

// Memo selectors

export const findMemosByEmployeeId = getMemoSelector('employees', 'employeeId')

export const findPrivateEmployee = createCachedSelector(
  findEmployeeWithId,
  // @ts-expect-error This is fixed when root state is correctly typed
  state => state.employees.extras.records,
  (employee, extras) => {
    const extraData = extras.find(({ id }) => id === employee?.id)
    return extraData
      ? {
          ...extraData,
          ...employee
        }
      : null
  }
)((__state, employeeId) => `${employeeId}`)
