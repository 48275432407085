import { actionTypes } from '../constants'

const sagaErrorHandlerActions = {
  handleSagaError: function(data) {
    return {
      data,
      type: actionTypes.SAGA_ERROR_HANDLE
    }
  },
  clearSagaError: function() {
    return {
      type: actionTypes.SAGA_ERROR_CLEAR
    }
  }
}

export default sagaErrorHandlerActions
