import { parseTemplate } from 'url-template'

import { del, get, post, put } from '../helpers/apiHelpers'

const baseUrl = '/api/product_lines{/productLineId,subItem,subItemId}'

const productLineUrlTemplate = parseTemplate(baseUrl)

export const fetchProductLines = (params = {}) => {
  return get(productLineUrlTemplate.expand(params))
}

export const updateProductLine = productLine => {
  return put(productLineUrlTemplate.expand({ productLineId: productLine.id }), {
    body: JSON.stringify(productLine)
  })
}

export const createProductLine = productLine => {
  return post(productLineUrlTemplate.expand({}), {
    body: JSON.stringify(productLine)
  })
}

export const deleteProductLine = productLine => {
  return del(productLineUrlTemplate.expand({ productLineId: productLine.id }))
}

export const updatePricingRule = (pricingRule, { productLineId } = {}) =>
  put(productLineUrlTemplate.expand({ subItem: 'pricing_rules', productLineId }), {
    body: JSON.stringify(pricingRule)
  })

export const fetchSupplierStats = ({ productLineId } = {}) =>
  get(productLineUrlTemplate.expand({ subItem: 'supplier_stats', productLineId }))

export const deleteNetPrices = ({ productLineId, supplierId }) =>
  del(productLineUrlTemplate.expand({ productLineId, subItem: 'net_prices', subItemId: supplierId }))
