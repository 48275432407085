import constants from '../constants'
import {
  actionCreatorsFor,
  error,
  request,
  start,
  success
} from '../helpers/actionHelpers'
import { PATH_TARGETS } from '../routes'
import { navigateTo } from './navigateActions'
import tableActions from './tableActions'

export const getUrl = (targetId, parentTargetId) => `${PATH_TARGETS}/${parentTargetId ? parentTargetId + '/subtarget/' : ''}${targetId || 'new'}`

const baseActionCreators = actionCreatorsFor(constants.actionKeys.TARGETS, { fetchStats: true })

const additionalActionCreators = {
  contacts: actionCreatorsFor(constants.actionKeys.TARGET_CONTACTS),
  files: actionCreatorsFor(constants.actionKeys.TARGET_FILES),
  memos: actionCreatorsFor(constants.actionKeys.TARGET_MEMOS),
  navigateTo: (targetId, replaceUrl, parentTargetId) => navigateTo(getUrl(targetId, parentTargetId), replaceUrl),
  navigateToBase: replaceUrl => navigateTo(PATH_TARGETS, replaceUrl),
  tableActions: tableActions(constants.actionKeys.TARGETS),
  fetchStatsRequest: request(baseActionCreators.actionTypes.fetchStatsRequest),
  fetchStatsStart: start(baseActionCreators.actionTypes.fetchStatsStart),
  fetchStatsSuccess: success(baseActionCreators.actionTypes.fetchStatsSuccess),
  fetchStatsError: error(baseActionCreators.actionTypes.fetchStatsError)
}

const targetActions = { ...baseActionCreators, ...additionalActionCreators }
export default targetActions
