import React from 'react'
import PropTypes from 'prop-types'

import BadgeRowCell from '../../containers/FilePage/BadgeRowCell'
import ExpandButton from '../Buttons/ExpandButton'
import TableLink from '../Table/TableLink'
import PlainRowCell from './PlainRowCell'

const isRowVisible = row => {
  // If columnFilters has __global__ - it tell's whether the row has passed the filter
  if(Object.hasOwn(row.columnFilters, '__global__')) {
    return row.columnFilters.__global__
  }
  // In case __global__ is not defined, there's no filter --> it is visible
  return true
}

export const DepthPaddedCell = ({ row, testId, children }) => {
  const leftPadding = `${row.depth * 2}rem`
  return (
    <div
      className='d-flex'
      style={{ paddingLeft: leftPadding }}
      data-testid={testId}
    >
      {children}
    </div>
  )
}

DepthPaddedCell.propTypes = {
  row: PropTypes.object.isRequired, // TansTack's CoreRow interface
  testId: PropTypes.string.isRequired,
  children: PropTypes.node
}

const DataTableExpandCell = ({
  row,
  testId,
  value,
  subRows,
  linkTo,
  hasRelation,
  hideExpandWithNoChild = false,
  noBadge = false,
  children
}) => {
  if(!subRows) {
    return (
      <DepthPaddedCell row={row} testId={testId}>
        {children ?? <PlainRowCell name='description' value={value} />}
      </DepthPaddedCell>
    )
  }
  const numChildVisible = row.parentId
    ? row.subRows.reduce((acc, subRow) => acc + (isRowVisible(subRow) ? 1 : 0), 0)
    : row.subRows.reduce((acc, subRow) => {
    // There seems to be a Bug in tanstack table - the addMeta(filterValue) isn't reflected here, only __global__
      const isSomeChildVisible = isRowVisible(subRow) || subRow.subRows.some(isRowVisible)
      return acc + (isSomeChildVisible ? 1 : 0)
    }
    , 0)

  const Badge = noBadge ? React.Fragment : BadgeRowCell
  const badgeProps = noBadge ? {} : { numChildren: numChildVisible }
  return (
    <DepthPaddedCell row={row} testId={testId}>
      <ExpandButton expanded={row.getIsExpanded()} visible={!(hideExpandWithNoChild && numChildVisible === 0)} />
      {hasRelation
        ? (
          <Badge {...badgeProps}>
            <PlainRowCell>
              <TableLink to={linkTo} disabled={linkTo == null} noPreventDefault>
                {children ?? `${row.original.relationNumber ?? ''} ${value}`}
              </TableLink>
            </PlainRowCell>
          </Badge>
          )
        : (
          <Badge {...badgeProps}>
            {children ?? <PlainRowCell value={value} />}
          </Badge>
          )}
    </DepthPaddedCell>
  )
}

DataTableExpandCell.propTypes = {
  row: PropTypes.object.isRequired, // TansTack's CoreRow interface
  testId: PropTypes.string.isRequired,
  value: PropTypes.any,
  subRows: PropTypes.array,
  linkTo: PropTypes.string,
  hasRelation: PropTypes.bool,
  hideExpandWithNoChild: PropTypes.bool,
  noBadge: PropTypes.bool,
  children: PropTypes.node
}

export default DataTableExpandCell
