import workActions from '../actions/workActions'
import constants, { workStates, workSystemTypes } from '../constants'
import { getCombinedListReducerFor, getExtraInfoReducer, getTableOptions } from '../helpers/reducerHelpers'
import tableReducerFor from './tableReducer'

const workStateFilters = { isTemplate: false, type: workSystemTypes.WORK_TYPE_DEFAULT }
const workPersonalStateFilters = { isTemplate: false, ownOnly: true }
const projectWorkStateFilters = { isTemplate: false, type: workSystemTypes.WORK_TYPE_PROJECT }
const workTemplateStateFilters = { isTemplate: true, isRecurring: false, type: workSystemTypes.WORK_TYPE_DEFAULT }
const searchStateFilters = {
  isTemplate: false,
  state: [
    workStates.WORK_STATE_CREATED,
    workStates.WORK_STATE_STARTED,
    workStates.WORK_STATE_INTERRUPTED,
    workStates.WORK_STATE_CONFIRMATION_REQUESTED
  ]
}

export const initialWorkTableOptions = {
  default: getTableOptions({ fixedFilters: workStateFilters }),
  own: getTableOptions({ fixedFilters: workPersonalStateFilters }),
  projectWork: getTableOptions({ fixedFilters: projectWorkStateFilters }),
  template: getTableOptions({ fixedFilters: workTemplateStateFilters }),
  search: getTableOptions({ fixedFilters: searchStateFilters }),
  workExtraInfo: { ...getTableOptions({ fixedFilters: workStateFilters }), showExtraInfo: true },
  projectWorkExtraInfo: { ...getTableOptions({ fixedFilters: projectWorkStateFilters }), showExtraInfo: true },
  recurringWork: getTableOptions({ fixedFilters: { ...workTemplateStateFilters, isRecurring: true, isAutomaticInvoiceable: false } }),
  recurringInvoice: getTableOptions({ fixedFilters: { ...workTemplateStateFilters, isRecurring: true, isAutomaticInvoiceable: true } })
}

export default getCombinedListReducerFor(constants.actionKeys.WORK, {
  workRows: getCombinedListReducerFor(constants.actionKeys.WORK_ROWS),
  files: getCombinedListReducerFor(constants.actionKeys.WORK_FILES),
  memos: getCombinedListReducerFor(constants.actionKeys.WORK_MEMOS),
  employees: getCombinedListReducerFor(constants.actionKeys.WORK_EMPLOYEES),
  workScheduleEvents: getCombinedListReducerFor(constants.actionKeys.WORK_SCHEDULE_EVENTS),
  workMachines: getCombinedListReducerFor(constants.actionKeys.WORK_MACHINES),
  tableOptions: tableReducerFor(constants.actionKeys.WORK, initialWorkTableOptions),
  summaryStats: getCombinedListReducerFor(constants.actionKeys.WORK_SUMMARY_STATS, {}, '_id'),
  preHandoverInspections: getCombinedListReducerFor(constants.actionKeys.PRE_HANDOVER_INSPECTIONS),
  preHandoverInspectionRows: getCombinedListReducerFor(constants.actionKeys.PRE_HANDOVER_INSPECTION_ROWS),
  preHandoverInspectionMemos: getCombinedListReducerFor(constants.actionKeys.PRE_HANDOVER_INSPECTION_MEMOS),
  systemMessages: getCombinedListReducerFor(constants.actionKeys.WORK_SYSTEM_MESSAGES),
  extraInfo: getExtraInfoReducer(workActions, 'workId'),
  workInvoicingRules: getCombinedListReducerFor(constants.actionKeys.WORK_INVOICING_RULES),
  workFormTemplates: getCombinedListReducerFor(constants.actionKeys.WORK_FORM_TEMPLATES),
  workForms: getCombinedListReducerFor(constants.actionKeys.WORK_FORMS)
})
