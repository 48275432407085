import { all, put, takeEvery } from 'redux-saga/effects'

import employeeActions from '../actions/employeeActions'
import systemCustomerActions from '../actions/systemCustomerActions'
import {
  salaryCalculationApi,
  salaryCalculationRowApi,
  salaryPeriodApi
} from '../api/salaryPeriodApi'
import {
  createActionFlow,
  createFlow,
  createSocketWatcherWithApiHandlerAndNormalizer,
  defaultApiResponseHandler,
  fetchFlow,
  updateFlow
} from '../helpers/sagaHelpers'
import { employmentSalaryTypeActions } from '../slices/employmentSalaryTypeSlice'
import { employmentActions } from '../slices/employmentSlice'
import { insuranceCompanyActions } from '../slices/insuranceCompanySlice'
import { insuranceActions } from '../slices/insuranceSlice'
import { occupationClassificationActions } from '../slices/occupationClassificationSlice'
import { salaryCalculationRowActions } from '../slices/salaryCalculationRowSlice'
import { salaryCalculationActions } from '../slices/salaryCalculationSlice'
import { salaryPeriodActions } from '../slices/salaryPeriodSlice'
import { wageEarnerActions } from '../slices/wageEarnerSlice'

export const handleSalaryPeriodApiResponse = mainAction => function* ({
  data,
  salaryGroups
}) {
  yield put(systemCustomerActions.salaryGroups.fetchSuccess(salaryGroups))
  yield put(mainAction(data))
  return data
}

const watchOnSalaryPeriodSockets = createSocketWatcherWithApiHandlerAndNormalizer('salaryPeriod', salaryPeriodActions, handleSalaryPeriodApiResponse)

const salaryPeriodFetchFlow = fetchFlow({
  fetchApi: salaryPeriodApi.fetch,
  actions: salaryPeriodActions,
  base: 'salaryPeriods.salaryPeriods',
  errorMsg: 'palkkajaksojen',
  apiResponseHandler: handleSalaryPeriodApiResponse(salaryPeriodActions.fetchSuccess)
})

const salaryPeriodCreateFlow = createFlow(salaryPeriodApi.create, salaryPeriodActions, 'Palkkajakso', 'Palkkajakson', handleSalaryPeriodApiResponse(salaryPeriodActions.createSuccess))
const salaryPeriodUpdateFlow = updateFlow(salaryPeriodApi.update, salaryPeriodActions, 'Palkkajakso', 'Palkkajakson', handleSalaryPeriodApiResponse(salaryPeriodActions.updateSuccess))

export const handleSalaryCalculationApiResponse = mainAction => function* ({
  data,
  wageEarners,
  employees,
  employments,
  insurances,
  insuranceCompanies,
  occupationClassifications,
  salaryTypes,
  employmentSalaryTypes
}) {
  yield put(employeeActions.fetchSuccess(employees))
  yield put(wageEarnerActions.fetchSuccess(wageEarners))
  yield put(employmentActions.fetchSuccess(employments))
  yield put(insuranceActions.fetchSuccess(insurances))
  yield put(insuranceCompanyActions.fetchSuccess(insuranceCompanies))
  yield put(occupationClassificationActions.fetchSuccess(occupationClassifications))
  yield put(systemCustomerActions.salaryTypes.fetchSuccess(salaryTypes))
  yield put(employmentSalaryTypeActions.fetchSuccess(employmentSalaryTypes))
  yield put(mainAction(data))
  return data
}

const watchOnSalaryCalculationSockets = createSocketWatcherWithApiHandlerAndNormalizer('salaryCalculation', salaryCalculationActions, handleSalaryCalculationApiResponse)

const salaryCalculationFetchFlow = fetchFlow({
  fetchApi: salaryCalculationApi.fetch,
  actions: salaryCalculationActions,
  base: 'salaryPeriods.salaryCalculations',
  errorMsg: 'palkkalaskelman',
  apiResponseHandler: handleSalaryCalculationApiResponse(salaryCalculationActions.fetchSuccess)
})

const salaryCalculationCreateFlow = createFlow(salaryCalculationApi.create, salaryCalculationActions, 'Palkkalaskelma', 'Palkkalaskelman', handleSalaryCalculationApiResponse(salaryCalculationActions.createSuccess))
const salaryCalculationUpdateFlow = updateFlow(salaryCalculationApi.update, salaryCalculationActions, 'Palkkalaskelma', 'Palkkalaskelman', handleSalaryCalculationApiResponse(salaryCalculationActions.updateSuccess))

const salaryCalculationPatchActionFlow = createActionFlow(salaryCalculationApi.patch, salaryCalculationActions, handleSalaryCalculationApiResponse)

const handleSalaryCalculationRowApiResponse = defaultApiResponseHandler

const watchOnSalaryCalculationRowSockets = createSocketWatcherWithApiHandlerAndNormalizer('salaryCalculationRow', salaryCalculationRowActions, handleSalaryCalculationRowApiResponse)

const salaryCalculationRowFetchFlow = fetchFlow({
  fetchApi: salaryCalculationRowApi.fetch,
  actions: salaryCalculationRowActions,
  base: 'salaryPeriods.salaryCalculationRows',
  errorMsg: 'palkkalaskelman rivien',
  apiResponseHandler: handleSalaryCalculationRowApiResponse(salaryCalculationRowActions.fetchSuccess)
})

const salaryCalculationRowCreateFlow = createFlow(salaryCalculationRowApi.create, salaryCalculationRowActions, 'Palkkalaskelman rivi', 'Palkkalaskelman rivin', handleSalaryCalculationRowApiResponse(salaryCalculationRowActions.createSuccess))
const salaryCalculationRowUpdateFlow = updateFlow(salaryCalculationRowApi.update, salaryCalculationRowActions, 'Palkkalaskelman rivi', 'Palkkalaskelman rivin', handleSalaryCalculationRowApiResponse(salaryCalculationRowActions.updateSuccess))

export default function* salaryPeriodSaga() {
  yield takeEvery(salaryPeriodActions.fetchRequest.type, salaryPeriodFetchFlow)
  yield takeEvery(salaryPeriodActions.createRequest.type, salaryPeriodCreateFlow)
  yield takeEvery(salaryPeriodActions.updateRequest.type, salaryPeriodUpdateFlow)

  yield takeEvery(salaryCalculationActions.fetchRequest.type, salaryCalculationFetchFlow)
  yield takeEvery(salaryCalculationActions.createRequest.type, salaryCalculationCreateFlow)
  yield takeEvery(salaryCalculationActions.updateRequest.type, salaryCalculationUpdateFlow)

  yield takeEvery(salaryCalculationRowActions.fetchRequest.type, salaryCalculationRowFetchFlow)
  yield takeEvery(salaryCalculationRowActions.createRequest.type, salaryCalculationRowCreateFlow)
  yield takeEvery(salaryCalculationRowActions.updateRequest.type, salaryCalculationRowUpdateFlow)

  yield takeEvery(salaryCalculationActions.doPatchAction.type, salaryCalculationPatchActionFlow)

  yield all([
    watchOnSalaryPeriodSockets(),
    watchOnSalaryCalculationSockets(),
    watchOnSalaryCalculationRowSockets()
  ])
}
