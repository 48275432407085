import constants from '../constants'
import { actionCreatorsFor } from '../helpers/actionHelpers'

const baseActionCreators = actionCreatorsFor(constants.actionKeys.DASHBOARD)

const actionCreators = {
}

const dashboardActions = { ...baseActionCreators, ...actionCreators }
export default dashboardActions
