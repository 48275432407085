import { createApi } from '../helpers/apiHelpers'

const normalizeWorkRecurrenceRule = response => {
  const { records, record, _embedded } = response
  const { work, customers, targets } = _embedded || {}
  const data = records || record

  return {
    data,
    work,
    customers,
    targets
  }
}

export const workRecurrenceRuleApi = createApi({
  base: 'work_recurrence_rules',
  editGetParams: params => params.workId
    ? {
        ...params,
        base: 'work',
        id: params.workId,
        subItem: 'work_recurrence_rules'
      }
    : params,
  normalizer: normalizeWorkRecurrenceRule,
  extraUrlTemplate: '{?startDate}'
})
