import constants from '../constants'
import { actionCreatorsFor } from '../helpers/actionHelpers'
import { PATH_RECEIVERS } from '../routes'
import { navigateTo } from './navigateActions'

export const getUrl = receiverId => `${PATH_RECEIVERS}/${receiverId || 'new'}`

const baseActionCreators = actionCreatorsFor(constants.actionKeys.RECEIVERS)

const additionalActionCreators = {
  files: actionCreatorsFor(constants.actionKeys.RECEIVER_FILES),
  approvers: actionCreatorsFor(constants.actionKeys.RECEIVER_APPROVERS),
  navigateTo: (receiverId, replaceUrl) => navigateTo(getUrl(receiverId), replaceUrl),
  navigateToBase: replaceUrl => navigateTo(PATH_RECEIVERS, replaceUrl)
}

const receiverActions = { ...baseActionCreators, ...additionalActionCreators }
export default receiverActions
