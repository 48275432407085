import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import memoize from 'micro-memoize'

import CommonButtonFormInput from './CommonButtonFormInput'

class ButtonCheckFormInput extends PureComponent {
  render() {
    return <CommonButtonFormInput {...this.props} handleClick={this.handleClick} compareValues={this.compareValues} />
  }

  compareValues = newValue => {
    return this.props.value.includes(newValue)
  }

  handleClick = memoize(newValue => () => {
    const { onChange, value: values } = this.props
    const newArray = this.compareValues(newValue)
      ? values.filter(value => value !== newValue)
      : [...values, newValue]
    return onChange(newArray, null)
  })

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.array.isRequired
  }
}

export default ButtonCheckFormInput
