import { miniSerializeError } from '@reduxjs/toolkit'
import {
  all,
  call,
  put,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'

import contactActions from '../actions/contactActions'
import customerActions from '../actions/customerActions'
import fileActions from '../actions/fileActions'
import {
  createCustomer,
  createCustomerContact,
  createCustomerFile,
  deleteCustomer,
  deleteCustomerContact,
  deleteCustomerFile,
  fetchCustomerContacts,
  fetchCustomerFiles,
  fetchCustomerPrh,
  fetchCustomers,
  fetchCustomerStats,
  normalizeCustomers,
  searchCustomers,
  updateCustomer
} from '../api/customerApi'
import { getMemoSagas } from '../helpers/generalSagas'
import {
  createFlow,
  createSocketWatcher,
  createSocketWatcherWithApiHandlerAndNormalizer,
  deleteFlow,
  fetchFlow,
  fetchStatsFlow,
  genericSagaErrorHandler,
  getPromiseHandlersFromData,
  getSubEntitySagas,
  searchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

export const handleCustomerApiResponse = mainAction =>
  function* ({ data, contacts, tableOptions }) {
    yield put(contactActions.fetchSuccess(contacts))
    yield put(mainAction(data))
    if(tableOptions && tableOptions.orderBy != null) {
      yield put(customerActions.tableActions.updateOptions(tableOptions))
    }
    return data
  }

const watchOnCustomerSockets = createSocketWatcherWithApiHandlerAndNormalizer('customer', customerActions, handleCustomerApiResponse, normalizeCustomers)

const watchOnCustomerContactSockets = createSocketWatcher('customerContact', {
  created: customerActions.contacts.createSuccess,
  updated: customerActions.contacts.updateSuccess
})

const watchOnCustomerFileSockets = createSocketWatcher('customerFile', {
  created: customerActions.files.createSuccess,
  updated: customerActions.files.updateSuccess
})

const customerFetchFlow = fetchFlow({
  fetchApi: fetchCustomers,
  actions: customerActions,
  base: 'customers',
  idField: 'customerId',
  errorMsg: 'asiakkaiden',
  apiResponseHandler: handleCustomerApiResponse(customerActions.fetchSuccess)
})

const customerUpdateFlow = updateFlow(updateCustomer, customerActions, 'Asiakas', 'Asiakkaan', handleCustomerApiResponse(customerActions.updateSuccess))
const customerCreateFlow = createFlow(createCustomer, customerActions, 'Asiakas', 'Asiakkaan', handleCustomerApiResponse(customerActions.createSuccess))
const customerSearchFlow = searchFlow(searchCustomers, customerActions, 'Asiakkaiden', function* (data, searchTerm) {
  const customers = yield handleCustomerApiResponse(customerActions.fetchSuccess)(data)
  yield put(customerActions.searchSuccess(customers, searchTerm))
  return customers
})
const customerDeleteFlow = deleteFlow({
  deleteApi: deleteCustomer,
  actions: customerActions,
  singular: 'Asiakas',
  errorMsg: 'Asiakkaan',
  base: 'customers'
})

const contactFlows = getSubEntitySagas(customerActions, contactActions, fetchCustomerContacts, createCustomerContact, deleteCustomerContact, 'customerId', 'contactId', 'customers', 'contacts', {
  singular: 'Asiakkaan yhteyshenkilö',
  accusative: 'Asiakkaan yhteyshenkilön',
  fetchError: 'Virhe asiakkaan yhteyshenkilöiden noutamisessa',
  deleteSuccess: 'Asiakkaan yhteyshenkilö poistettu',
  deleteError: 'Virhe asiakkaan yhteyshenkilön poistamisessa'
}, function* (response) {
  const { customerContacts, contacts } = response
  if(customerContacts || contacts) {
    yield put(customerActions.contacts.fetchSuccess(customerContacts))
    yield put(contactActions.fetchSuccess(contacts))
  } else {
    yield put(customerActions.contacts.fetchSuccess(response)) // POST response
  }
})

const fileFlows = getSubEntitySagas(customerActions, fileActions, fetchCustomerFiles, createCustomerFile, deleteCustomerFile, 'customerId', 'fileId', 'customers', 'files', {
  singular: 'Asiakkaan tiedosto',
  accusative: 'Asiakkaan tiedoston',
  fetchError: 'Virhe asiakkaan tiedostojen noutamisessa',
  deleteSuccess: 'Asiakkaan tiedostolinkki poistettu',
  deleteError: 'Virhe asiakkaan tiedostolinkin poistamisessa'
})

const customerStatsFetchFlow = fetchStatsFlow(customerActions, fetchCustomerStats, 'customerId')

const memoSagas = getMemoSagas({
  actions: customerActions,
  baseName: 'customers',
  socketName: 'customer',
  titleGenetive: 'asiakkaan'
})

function* customerPrhFetchFlow({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(customerActions.fetchPrhStart())
    const prhSearch = yield call(fetchCustomerPrh, record.prhSearchTerms)
    yield put(customerActions.fetchPrhSuccess(prhSearch))
    yield call(resolve, prhSearch)
  } catch(err) {
    yield put(customerActions.fetchPrhError(miniSerializeError(err)))
    yield * genericSagaErrorHandler(err, 'haun tulosten', reject)
  }
}

export default function* customerSaga() {
  yield takeLatest(customerActions.actionTypes.fetchRequest, customerFetchFlow)
  yield takeEvery(customerActions.actionTypes.updateRequest, customerUpdateFlow)
  yield takeEvery(customerActions.actionTypes.createRequest, customerCreateFlow)
  yield takeLatest(customerActions.actionTypes.searchRequest, customerSearchFlow)
  yield takeEvery(customerActions.actionTypes.deleteRequest, customerDeleteFlow)

  yield takeLatest(customerActions.contacts.actionTypes.fetchRequest, contactFlows.subFetchFlow)
  yield takeEvery(customerActions.contacts.actionTypes.createRequest, contactFlows.subCreateFlow)
  yield takeLatest(customerActions.contacts.actionTypes.deleteRequest, contactFlows.subDeleteFlow)

  yield takeLatest(customerActions.files.actionTypes.fetchRequest, fileFlows.subFetchFlow)
  yield takeEvery(customerActions.files.actionTypes.createRequest, fileFlows.subCreateFlow)
  yield takeLatest(customerActions.files.actionTypes.deleteRequest, fileFlows.subDeleteFlow)

  yield takeLatest(customerActions.actionTypes.fetchStatsRequest, customerStatsFetchFlow)
  yield takeLatest(customerActions.actionTypes.fetchPrhRequest, customerPrhFetchFlow)

  yield all([
    memoSagas(),
    watchOnCustomerSockets(),
    watchOnCustomerContactSockets(),
    watchOnCustomerFileSockets()
  ])
}
