import { createApi } from '../helpers/apiHelpers'
import { parseFilterParams, parseTableOptionsFromQuery } from '../helpers/reducerHelpers'
import { optionsQueryBase } from './urlTemplates'

export const normalizeSystemMessages = response => {
  const { record, records, _embedded } = response
  const data = records || record
  const {
    options
  } = _embedded || {}
  return {
    data,
    tableOptions: parseTableOptionsFromQuery(options)
  }
}

export const systemMessageApi = createApi({
  base: 'system_messages',
  normalizer: normalizeSystemMessages,
  extraUrlTemplate: `${optionsQueryBase}{?includeConfirmed}`,
  editGetParams: parseFilterParams,
  editMutateParams: params => ({ ...params, subItemType: params.sendType })
})

const normalizeSystemMessageLinks = response => {
  const { record, records, _embedded } = response
  const data = records || record
  const { systemMessages } = _embedded
  return {
    data,
    systemMessages
  }
}

export const getSystemMessageLinkApi = base => {
  const editGetParams = ({ id }) => ({ id, subItem: 'system_messages' })
  const editMutateParams = params => ({ id: params.id, subItem: 'system_messages', subItemId: params.systemMessageLinkId, subItemType: params.sendType })

  return createApi({ base, editGetParams, editMutateParams, normalizer: normalizeSystemMessageLinks })
}
