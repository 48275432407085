import constants from '../constants'
import { actionCreatorsFor } from '../helpers/actionHelpers'
import tableActions from './tableActions'

const baseActionCreators = actionCreatorsFor(constants.actionKeys.PACKET_MATRICES)

const additionalActionCreators = {
  tableActions: tableActions(constants.actionKeys.PACKET_MATRICES)
}

const packetMatrixActions = { ...baseActionCreators, ...additionalActionCreators }
export default packetMatrixActions
