import { post } from '../helpers/apiHelpers'

const requestUrls = {
  createPayment: '/api/paytrail/pay'
}

export const createPayment = payment => {
  return post(requestUrls.createPayment, {
    body: JSON.stringify(payment)
  })
}
