import { useCallback } from 'react'
import { Route, Switch } from 'react-router'

import permissionRouter from '../../components/Authentication/permissionRouter'
import { NotFoundErrorPage } from '../../components/Errors'
import routes from './routes'
import RoutesErrorBoundary from './RoutesErrorBoundary'

const mainRoutes =
  routes.map(route => {
    const RouteComponent = permissionRouter(route.featureKey)
    return <RouteComponent key={route.path} exact={route.exact} path={route.path} render={route.renderComponent} />
  })

const MainRoutes = () => {
  const renderContent = useCallback(() => (
    <Switch>
      {mainRoutes}
      <Route component={NotFoundErrorPage} />
    </Switch>
  ), [])
  return <RoutesErrorBoundary renderContent={renderContent} />
}

export default MainRoutes
