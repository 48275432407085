import { createApi } from '../helpers/apiHelpers'

const normalizeTicketRecurrenceRule = response => {
  const { records, record, _embedded } = response
  const { tickets } = _embedded || {}
  const data = records || record

  return {
    data,
    tickets
  }
}

export const ticketRecurrenceRuleApi = createApi({
  base: 'ticket_recurrence_rules',
  editGetParams: params => params.ticketId
    ? {
        ...params,
        base: 'tickets',
        id: params.ticketId,
        subItem: 'ticket_recurrence_rules'
      }
    : params,
  normalizer: normalizeTicketRecurrenceRule,
  extraUrlTemplate: '{?startDate}'
})
