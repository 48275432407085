import { useCallback } from 'react'

const useNestedRowClick = (onRowClick, getSubRows) => {
  const handleRowClick = useCallback((row, rowModel) => {
    const subRows = getSubRows(row)
    // If row has subRows - expand it
    if(subRows) {
      rowModel.toggleExpanded()
      return
    }
    onRowClick(row)
  }, [onRowClick, getSubRows])

  return handleRowClick
}

export default useNestedRowClick
