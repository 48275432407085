import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import inboundInvoiceActions from '../../actions/inboundInvoiceActions'
import { renderPostalSearchItemInline } from '../../components/Helpers/searchResultRenderers'
import { getInboundInvoicesFromSearchResult } from '../../selectors/inboundInvoiceSelectors'
import { findReceiverWithId } from '../../selectors/receiverSelectors'
import InboundInvoiceStatusBadge, {
  inboundInvoiceStatusBadgeTypes
} from '../InboundInvoicePage/InboundInvoiceStatusBadge'
import SearchResults, { searchResultsPropType } from './SearchResults'

const appendComponent = inboundInvoice => (
  <span className='m-child-1 ms-1'>
    <InboundInvoiceStatusBadge inboundInvoice={inboundInvoice} type={inboundInvoiceStatusBadgeTypes.ARCHIVE} />
    <InboundInvoiceStatusBadge inboundInvoice={inboundInvoice} type={inboundInvoiceStatusBadgeTypes.STATE} />
    <InboundInvoiceStatusBadge inboundInvoice={inboundInvoice} type={inboundInvoiceStatusBadgeTypes.ACCOUNTING_STATE} />
    <InboundInvoiceStatusBadge inboundInvoice={inboundInvoice} type={inboundInvoiceStatusBadgeTypes.PAYMENT_STATE} />
  </span>
)

class InboundInvoiceResults extends Component {
  renderInboundInvoice = inboundInvoice =>
    renderPostalSearchItemInline(
      {
        ...inboundInvoice,
        ...this.props.findReceiverInfo(inboundInvoice)
      },
      'inboundInvoiceNumber',
      { appendComponent }
    )

  render() {
    return (
      <SearchResults
        title='Ostolaskut'
        renderResult={this.renderInboundInvoice}
        {...this.props}
      />
    )
  }

  static propTypes = {
    ...searchResultsPropType,
    findReceiverInfo: PropTypes.func.isRequired
  }
}

const mapStateToProps = state => {
  return {
    results: getInboundInvoicesFromSearchResult(state),
    isBusy: state.inboundInvoices.search.isBusy,
    findReceiverInfo: inboundInvoice => {
      const receiver = inboundInvoice.receiverId ? findReceiverWithId(state, inboundInvoice.receiverId) : null
      return {
        name: receiver
          ? receiver.name
          : inboundInvoice.senderName
            ? `Lähettäjä: ${inboundInvoice.senderName}`
            : null
      }
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    navigateTo: inboundInvoiceId => dispatch(inboundInvoiceActions.navigateTo(inboundInvoiceId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InboundInvoiceResults)
