import { Component } from 'react'
import { connect } from 'react-redux'

import productActions from '../../actions/productActions'
import { ProductPriceBadge } from '../../components/Work/productRenderHelpers'
import { getProductsFromSearchResult } from '../../selectors/productSelectors'
import { productLineCodeAndProductNumberLinkRenderer } from '../ProductPage/productHelpers'
import SearchResults, { searchResultsPropType } from './SearchResults'

class ProductResults extends Component {
  render() {
    return (
      <SearchResults
        title='Tuotteet'
        renderResult={this.renderProduct}
        {...this.props}
      />
    )
  }

  renderProduct = product => (
    <>
      {productLineCodeAndProductNumberLinkRenderer(product.productNumber, product)}
      {' '}
      {product.name}
      {product.nameExtension ? ` - ${product.nameExtension}` : null}
      <ProductPriceBadge product={product} />
    </>
  )

  static propTypes = searchResultsPropType
}

const mapStateToProps = state => {
  return {
    results: getProductsFromSearchResult(state),
    isBusy: state.products.search.isBusy
  }
}

const mapDispatchToProps = dispatch => {
  return {
    navigateTo: productId => dispatch(productActions.navigateTo(productId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductResults)
