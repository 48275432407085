import { takeEvery } from 'redux-saga/effects'

import {
  occupationClassificationApi
} from '../api/occupationClassificationApi'
import { defaultApiResponseHandler, fetchFlow } from '../helpers/sagaHelpers'
import { occupationClassificationActions } from '../slices/occupationClassificationSlice'

const occupationClassificationFetchFlow = fetchFlow({
  fetchApi: occupationClassificationApi.fetch,
  actions: occupationClassificationActions,
  base: 'occupationClassifications',
  errorMsg: 'ammattiluokitusten',
  apiResponseHandler: defaultApiResponseHandler(occupationClassificationActions.fetchSuccess)
})

export default function* occupationClassificationSaga() {
  yield takeEvery(occupationClassificationActions.fetchRequest.type, occupationClassificationFetchFlow)
}
