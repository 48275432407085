import { getCoefficient, round8decimals, roundUpToNext, sortByProperty } from '@evelia/helpers/helpers'
import { createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'

import { machinePricingRuleTimeTypes, machinePricingRuleTimeTypeUnitsMap } from '../constants'
import { calculateDuration } from '../helpers/dateHelpers'
import { mapRecordNameOptions } from '../helpers/helpers'
import {
  getFilterItemsByFieldSelector,
  getFindItemByIdSelector,
  getMemoSelector,
  getSubentitySelectors,
  getTableIdsSelector
} from '../helpers/selectorHelpers'
import { getCombinedVatRateWithClassId, getCombinedVatRateWithId } from './vatSelectors'

export const getMachinesFromArgument = arg => arg.machines ? arg.machines.records : arg

export const findMachineWithId = getFindItemByIdSelector(getMachinesFromArgument)
export const findMachinePricingRuleWithId = getFindItemByIdSelector(state => state.machines.machinePricingRules.records)

export const getMachinesByTableIds = getTableIdsSelector('machines')

export const findMemosByMachineId = getMemoSelector('machines', 'machineId')

export const findMachineLogsByMachineId = getFilterItemsByFieldSelector(state => state.machines.machineLogs.records, ['machineId'])

export const {
  getSubItemsOfItem: getFilesOfMachine,
  rejectSubItemsOfItem: rejectFilesOfMachine
} = getSubentitySelectors('machines', 'files', 'files', 'machineId', 'fileId')

export const getAssignableMachines = createSelector(
  getMachinesFromArgument,
  machines => machines.filter(machine => !machine.isUnusable && !machine.isUnassignable)
)

export const findMachineLogTypeOptions = createSelector(
  state => state.machines.machineLogTypes.records,
  machineLogTypes => machineLogTypes.sort(sortByProperty('type')).map(({ type }) => ({ value: type, text: type }))
)

export const getMachinePricingRulesByMachine = getFilterItemsByFieldSelector(state => state.machines.machinePricingRules.records, 'machineId', Number)
export const getMachinePricingRuleOptions = createCachedSelector(
  getMachinePricingRulesByMachine,
  mapRecordNameOptions
)((__state, machineId) => `${machineId}`)

export const calculateMachineRecordPrices = (machineRecord, machine, machinePricingRule, account, combinedVatRates) => {
  if(!machineRecord || !machine || !machinePricingRule || !combinedVatRates) {
    return null
  }

  let count = machineRecord.amount
  if(machineRecord.isExplicitBillingAmount && machineRecord.billingAmount != null) {
    count = machineRecord.billingAmount
  } else if(machinePricingRule.isFixedPrice) {
    count = 1
  } else {
    const isTimeType = machinePricingRuleTimeTypes.includes(machinePricingRule.type)
    const unitOfTime = isTimeType ? machinePricingRuleTimeTypeUnitsMap[machinePricingRule.unit] : null
    const duration = calculateDuration(machineRecord, unitOfTime)
    if(duration != null) {
      count = duration
    }
  }

  const vatRateObject = getCombinedVatRateWithId(combinedVatRates, account?.vatCodeId) || getCombinedVatRateWithClassId(combinedVatRates, machine.defaultVatClassId)

  const safeCount = Number.isFinite(count) ? roundUpToNext(count, machinePricingRule.rounding) : 0
  const price = round8decimals(safeCount * machinePricingRule.unitPrice)
  const vatPrice = round8decimals(price * getCoefficient(vatRateObject.rate))
  const netPriceSum = Number.isFinite(machinePricingRule.netPrice) ? round8decimals(safeCount * machinePricingRule.netPrice) : null

  return {
    count: safeCount,
    unitPrice: price,
    netPrice: machinePricingRule.netPrice,
    netPriceSum,
    vatPrice,
    price,
    discount: 0,
    vatRateId: vatRateObject.id,
    vatRate: vatRateObject.rate,
    accountNumber: account?.id,
    isFixedPrice: machinePricingRule.isFixedPrice
  }
}
