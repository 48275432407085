import { parseTemplate } from 'url-template'

import constants from '../constants'
import { actionCreatorsFor } from '../helpers/actionHelpers'
import { PATH_WORK_QUICK_FILTERS } from '../routes'
import { navigateTo } from './navigateActions'

const baseActionCreators = actionCreatorsFor(constants.actionKeys.WORK_QUICK_FILTERS)
const workQuickFilterUrlTemplate = parseTemplate(`${PATH_WORK_QUICK_FILTERS}/{workQuickFilterId}`)
export const getUrl = (workQuickFilterId, params) => workQuickFilterUrlTemplate.expand({ workQuickFilterId: workQuickFilterId || 'new', ...params })

const additionalActionCreators = {
  navigateTo: (workQuickFilterId, replaceUrl, params) => navigateTo(getUrl(workQuickFilterId, params), replaceUrl),
  navigateToBase: replaceUrl => navigateTo(PATH_WORK_QUICK_FILTERS, replaceUrl)
}

const workQuickFilterActions = { ...baseActionCreators, ...additionalActionCreators }
export default workQuickFilterActions
