import { Component } from 'react'
import { connect } from 'react-redux'

import workActions from '../../actions/workActions'
import { renderPostalSearchItemInline } from '../../components/Helpers/searchResultRenderers'
import { findCustomerWithId } from '../../selectors/customerSelectors'
import { findTargetWithId } from '../../selectors/targetSelectors'
import { getWorkFromSearchResult } from '../../selectors/workSelectors'
import WorkStatusBadge from '../WorkPage/WorkStatusBadge'
import SearchResults, { searchResultsPropType } from './SearchResults'

class WorkResults extends Component {
  renderWork = work => {
    const workWithPostal = {
      ...this.props.findWorkPostalDetails(work),
      ...work
    }
    return renderPostalSearchItemInline(workWithPostal, 'workNumber', { appendComponent: item => <span> <WorkStatusBadge work={item} /></span> })
  }

  render() {
    return (
      <SearchResults
        title='Työt'
        renderResult={this.renderWork}
        {...this.props}
      />
    )
  }

  static propTypes = searchResultsPropType
}

const mapStateToProps = state => {
  return {
    results: getWorkFromSearchResult(state),
    isBusy: state.work.search.isBusy,
    findWorkPostalDetails: work => {
      if(!work) {
        return {}
      }
      const postalData = work.targetId
        ? findTargetWithId(state, work.targetId)
        : work.customerId
          ? findCustomerWithId(state, work.customerId)
          : {}
      return postalData || {}
    }
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    navigateTo: workId => dispatch(workActions.navigateTo(workId)),
    showMoreResults: () => {
      dispatch(workActions.showMoreSearchResults())
      ownProps.closeResults()
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkResults)
