import { invoiceTypes } from '../constants'

export const getFooterField = (footerField, settings, defaultInvoiceFormSettings) => {
  if(settings?.isDefault || settings?.useDefaultFooter) {
    return defaultInvoiceFormSettings?.[footerField]
  } else {
    return settings?.[footerField] || null
  }
}

export const getInvoiceFormSettingsByType = (invoiceType, invoiceFormSettings, parentInvoiceFormSettings, defaultInvoiceFormSettings, isParentInvoiceOfReminderInvoice) => {
  let settings
  if(isParentInvoiceOfReminderInvoice) {
    settings = parentInvoiceFormSettings || defaultInvoiceFormSettings
  } else if(invoiceType === invoiceTypes.INVOICE_TYPE_CREDIT) {
    const formSettings = parentInvoiceFormSettings || defaultInvoiceFormSettings
    settings = {
      ...formSettings,
      withoutWorkDescription: false /* because work description has credit invoice info text  */
    }
  } else if(invoiceType === invoiceTypes.INVOICE_TYPE_INTEREST || invoiceType === invoiceTypes.INVOICE_TYPE_REMINDER) {
    const formSettings = parentInvoiceFormSettings || defaultInvoiceFormSettings
    settings = {
      ...formSettings,
      withoutRowSum: false,
      withoutProductNumbers: false,
      withoutNameExtension: false,
      withoutAmountAndUnitPrices: false,
      withoutUnitPrices: false,
      withoutVatRate: false,
      withoutDiscount: false,
      withoutProductRows: false,
      withoutPrices: false,
      withoutTotalSum: false,
      withoutVat: false,
      withVat: false,
      onlySubTotals: false,
      withoutTarget: false,
      textBeforeRows: null,
      textAfterRows: null
    }
  } else {
    settings = invoiceFormSettings || defaultInvoiceFormSettings
  }

  return {
    ...settings,
    footerLeft: getFooterField('footerLeft', settings, defaultInvoiceFormSettings),
    footerCenter: getFooterField('footerCenter', settings, defaultInvoiceFormSettings),
    footerRight: getFooterField('footerRight', settings, defaultInvoiceFormSettings)
  }
}
