import { createApi, defaultEmbeddedNormalizer } from '../helpers/apiHelpers'

// Work rows are managed from work api.
const parseParams = ({ id, workId }) => ({
  subItem: 'workrows',
  subItemId: id,
  id: workId
})

const parseMutateParams = (params, requestData) => ({
  subItem: 'workrows',
  subItemId: params.id,
  id: params.workId || requestData.workId,
  action: params.action
})

const normalizeWorkRowResponse = defaultEmbeddedNormalizer

export const workRowApi = createApi({
  base: 'work',
  editGetParams: parseParams,
  editMutateParams: parseMutateParams,
  normalizer: normalizeWorkRowResponse
})

export const createWorkRowsFromInboundInvoiceRows = (data, params) => workRowApi.create(data, { ...params, action: 'from_inbound_invoice_rows' })
export const createWorkRowsFromWorkRecords = (data, params) => workRowApi.create(data, { ...params, action: 'from_work_records' })
export const createWorkRowsFromMachineRecords = (data, params) => workRowApi.create(data, { ...params, action: 'from_machine_records' })
