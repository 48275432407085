import { createSelector } from 'reselect'

import { formTypeTypes } from '../constants'
import { getFindItemByIdSelector } from '../helpers/selectorHelpers'
import { findCustomerGroupWithId, getCustomerGroupsFromArgument } from './customerGroupSelectors'
import { findCustomerWithId } from './customerSelectors'
import { findWorkTypeWithId } from './workTypeSelectors'

const getFormTypesFromArgument = arg => arg.formTypes ? arg.formTypes.records : arg

export const findFormTypeWithId = getFindItemByIdSelector(getFormTypesFromArgument)

export const getWorkFormTypes = createSelector(
  getFormTypesFromArgument,
  formTypes => formTypes.filter(formType => formType.type === formTypeTypes.WORK)
)

export const getInvoiceFormTypes = createSelector(
  getFormTypesFromArgument,
  formTypes => formTypes.filter(formType => formType.type === formTypeTypes.INVOICE)
)

export const mapFormTypeOptions = formTypes => formTypes.map(formType => ({ value: Number(formType.id), text: formType.name }))
export const mapFormDeliveryTypeOptions = formTypes => formTypes.map(formType => ({ value: Number(formType.id), text: `${formType.code} ${formType.name}`, ...formType }))
export const mapFormDeliveryTermOptions = deliveryTerms => deliveryTerms.map(deliveryTerm => ({ value: Number(deliveryTerm.id), text: deliveryTerm.code ? `(${deliveryTerm.code}) ${deliveryTerm.name}` : deliveryTerm.name }))

export const getWorkFormTypeOptions = createSelector(
  getWorkFormTypes,
  formTypes => mapFormTypeOptions(formTypes)
)

export const getInvoiceFormTypeOptions = createSelector(
  getInvoiceFormTypes,
  formTypes => mapFormTypeOptions(formTypes)
)

export const getDefaultWorkFormTypeOfCompany = createSelector(
  getWorkFormTypes,
  formTypes => formTypes.find(formType => formType.isDefault === true)
)

export const getDefaultInvoiceFormTypeOfCompany = createSelector(
  getInvoiceFormTypes,
  formTypes => formTypes.find(formType => formType.isDefault === true)
)

export const getDefaultWorkFormType = createSelector(
  getWorkFormTypes,
  (state, work, formTypeIdOfForm) => Number(formTypeIdOfForm),
  (state, work) => findWorkTypeWithId(state, work?.workTypeId),
  (state, work) => findCustomerWithId(state, work?.customerId),
  getCustomerGroupsFromArgument,
  getDefaultWorkFormTypeOfCompany,
  (formTypes, formTypeIdOfForm, workType, customer, customerGroups, defaultFormTypeOfCompany) => {
    const customerGroup = findCustomerGroupWithId(customerGroups, customer?.customerMainGroupId)
    let formTypeId
    if(formTypeIdOfForm) {
      formTypeId = formTypeIdOfForm
    } else if(workType?.workFormTypeId) {
      formTypeId = workType?.workFormTypeId
    } else if(customer?.workFormTypeId) {
      formTypeId = customer?.workFormTypeId
    } else if(customerGroup?.workFormTypeId) {
      formTypeId = customerGroup?.workFormTypeId
    } else {
      formTypeId = null
    }
    return formTypeId
      ? formTypes.find(formType => formType.id === formTypeId)
      : defaultFormTypeOfCompany
  }
)

export const getDefaultInvoiceFormType = createSelector(
  getInvoiceFormTypes,
  (state, work, formTypeIdOfForm) => Number(formTypeIdOfForm),
  (state, work) => findCustomerWithId(state, work?.customerId),
  getCustomerGroupsFromArgument,
  getDefaultInvoiceFormTypeOfCompany,
  (formTypes, formTypeIdOfForm, customer, customerGroups, defaultFormTypeOfCompany) => {
    const customerGroup = findCustomerGroupWithId(customerGroups, customer?.customerMainGroupId)
    let formTypeId
    if(formTypeIdOfForm) {
      formTypeId = formTypeIdOfForm
    } else if(customer?.invoiceFormTypeId) {
      formTypeId = customer?.invoiceFormTypeId
    } else if(customerGroup?.invoiceFormTypeId) {
      formTypeId = customerGroup?.invoiceFormTypeId
    } else {
      formTypeId = null
    }
    return formTypeId
      ? formTypes.find(formType => formType.id === formTypeId)
      : defaultFormTypeOfCompany
  }
)
