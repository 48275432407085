import PropTypes from 'prop-types'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import IconButton from '../Buttons/IconButton'
import useConfirmSystemMessage from './useConfirmSystemMessage'

const SystemMessageConfirmButton = ({ ids, onConfirmed, ...buttonProps }) => {
  const confirmNotification = useConfirmSystemMessage(ids, onConfirmed)

  const disabled = ids == null || (Array.isArray(ids) && !ids.length)
  return (
    <IconButton
      outline
      icon={faCheckCircle}
      onClick={confirmNotification}
      disabled={disabled}
      {...buttonProps}
    />
  )
}
SystemMessageConfirmButton.propTypes = {
  ids: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number)]
  ).isRequired,
  onConfirmed: PropTypes.func
}

export default SystemMessageConfirmButton
