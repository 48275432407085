import { all, put, takeEvery } from 'redux-saga/effects'

import workActions from '../actions/workActions'
import { normalizeWorkMachines, workMachineApi } from '../api/workMachineApi'
import machineTitles, { machineTitlesCapitalized } from '../components/Machines/machineTitles'
import {
  createFlow,
  createSocketWatcherWithApiHandlerAndNormalizer,
  deleteFlow,
  fetchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

const titles = machineTitles
const titlesCapitalized = machineTitlesCapitalized
const reduxName = 'work.workMachines'

const handleWorkMachineApiResponse = mainAction =>
  function* ({ data }) {
    yield put(mainAction(data))
    return data
  }

const watchOnWorkMachineSockets = createSocketWatcherWithApiHandlerAndNormalizer('workMachine', workActions.workMachines, handleWorkMachineApiResponse, normalizeWorkMachines)

const workMachineFetchFlow = fetchFlow({
  fetchApi: workMachineApi.fetch,
  actions: workActions.workMachines,
  base: reduxName,
  idField: 'id',
  errorMsg: titles.genetive,
  apiResponseHandler: handleWorkMachineApiResponse(workActions.workMachines.fetchSuccess)
})

const workMachineUpdateFlow = updateFlow(workMachineApi.update, workActions.workMachines, titlesCapitalized.basic, titlesCapitalized.genetive, handleWorkMachineApiResponse(workActions.workMachines.updateSuccess))
const workMachineCreateFlow = createFlow(workMachineApi.create, workActions.workMachines, titlesCapitalized.basic, titlesCapitalized.genetive, handleWorkMachineApiResponse(workActions.workMachines.createSuccess))
const workMachineDeleteFlow = deleteFlow({
  deleteApi: workMachineApi.remove,
  actions: workActions.workMachines,
  singular: titlesCapitalized.basic,
  errorMsg: titlesCapitalized.genetive,
  base: reduxName
})

export default function* workMachineSaga() {
  yield takeEvery(workActions.workMachines.actionTypes.fetchRequest, workMachineFetchFlow)
  yield takeEvery(workActions.workMachines.actionTypes.updateRequest, workMachineUpdateFlow)
  yield takeEvery(workActions.workMachines.actionTypes.createRequest, workMachineCreateFlow)
  yield takeEvery(workActions.workMachines.actionTypes.deleteRequest, workMachineDeleteFlow)

  yield all([
    watchOnWorkMachineSockets()
  ])
}
