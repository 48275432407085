import { actionKeys, actionTypes } from '../constants'
import { actionCreatorsFor, request } from '../helpers/actionHelpers'
import { PATH_PRODUCTS_WAREHOUSES } from '../routes'
import { navigateTo } from './navigateActions'
import tableActions from './tableActions'

export const getUrl = (warehouseId, parentWarehouseId) => `${PATH_PRODUCTS_WAREHOUSES}/${parentWarehouseId ? parentWarehouseId + '/subwarehouse/' : ''}${warehouseId || 'new'}`

const baseActionCreators = actionCreatorsFor(actionKeys.WAREHOUSES, { fetchStats: true })

const additionalActionCreators = {
  navigateTo: (warehouseId, replaceUrl, parentWarehouseId) => navigateTo(getUrl(warehouseId, parentWarehouseId), replaceUrl),
  navigateToBase: replaceUrl => navigateTo(PATH_PRODUCTS_WAREHOUSES, replaceUrl),
  warehouseActions: {
    ...actionCreatorsFor(actionKeys.WAREHOUSE_ACTIONS),
    tableActions: tableActions(actionKeys.WAREHOUSE_ACTIONS)
  },
  warehouseProductStats: {
    ...actionCreatorsFor(actionKeys.WAREHOUSE_PRODUCT_STATS, {}, '_id'),
    tableActions: tableActions(actionKeys.WAREHOUSE_PRODUCT_STATS)
  },
  generateReport: request(actionTypes.WAREHOUSE_GENERATE_REPORT_REQUEST),
  importReportFromFile: request(actionTypes.WAREHOUSE_REPORT_IMPORT_REQUEST)
}

const warehouseActions = { ...baseActionCreators, ...additionalActionCreators }
export default warehouseActions
