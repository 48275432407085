import { parseTemplate } from 'url-template'

import { del, get, post, put } from '../helpers/apiHelpers'

const baseUrl = '/api/supply_offer_types/{supplyOfferTypeId}'
const supplyOfferTypeUrlTemplate = parseTemplate(baseUrl)

export const fetchSupplyOfferTypes = (params = {}) =>
  get(supplyOfferTypeUrlTemplate.expand(params))

export const updateSupplyOfferType = supplyOfferType =>
  put(supplyOfferTypeUrlTemplate.expand({ supplyOfferTypeId: supplyOfferType.id }), {
    body: JSON.stringify(supplyOfferType)
  })

export const createSupplyOfferType = supplyOfferType =>
  post(supplyOfferTypeUrlTemplate.expand({}), {
    body: JSON.stringify(supplyOfferType)
  })

export const deleteSupplyOfferType = supplyOfferType =>
  del(supplyOfferTypeUrlTemplate.expand({ supplyOfferTypeId: supplyOfferType.id }))
