import constants from '../constants'
import { getCombinedListReducerFor, getTableOptions } from '../helpers/reducerHelpers'
import tableReducerFor from './tableReducer'

export const initialReceiptTableOptions = {
  default: getTableOptions({
    filters: { includeArchived: false },
    orderBy: ['receiptNumber'],
    sortOrder: ['DESC']
  })
}

export default getCombinedListReducerFor(constants.actionKeys.RECEIPT, {
  files: getCombinedListReducerFor(constants.actionKeys.RECEIPT_FILES),
  memos: getCombinedListReducerFor(constants.actionKeys.RECEIPT_MEMOS),
  accountings: getCombinedListReducerFor(constants.actionKeys.RECEIPT_ACCOUNTINGS),
  tableOptions: tableReducerFor(constants.actionKeys.RECEIPT, initialReceiptTableOptions)
})
