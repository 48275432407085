import { all, put, takeEvery, takeLatest } from 'redux-saga/effects'

import receiptActions from '../actions/receiptActions'
import {
  normalizeReceipts,
  receiptApi
} from '../api/receiptApi'
import { RECEIPT_REDUX_NAME } from '../components/Receipts/constants'
import receiptTitles, { receiptTitlesCapitalized } from '../components/Receipts/receiptTitles'
import { getFileLinkSagas, getMemoSagas } from '../helpers/generalSagas'
import {
  createActionFlow,
  createFlow,
  createSocketWatcher,
  createSocketWatcherWithApiHandlerAndNormalizer,
  deleteFlow,
  fetchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

const handleReceiptApiResponse = mainAction =>
  function* ({ data, tableOptions }) {
    yield put(mainAction(data))
    if(tableOptions && tableOptions.orderBy != null) {
      yield put(receiptActions.tableActions.updateOptions(tableOptions))
    }
    return data
  }

const watchOnReceiptSockets = createSocketWatcherWithApiHandlerAndNormalizer('receipt', receiptActions, handleReceiptApiResponse, normalizeReceipts)

const watchOnReceiptFileSockets = createSocketWatcher('receiptFile', {
  created: receiptActions.files.createSuccess,
  updated: receiptActions.files.updateSuccess
})

const receiptsPatchActionFlow = createActionFlow(receiptApi.patch, receiptActions, handleReceiptApiResponse)

const receiptFetchFlow = fetchFlow({
  fetchApi: receiptApi.fetch,
  actions: receiptActions,
  base: RECEIPT_REDUX_NAME,
  idField: 'receiptId',
  errorMsg: receiptTitlesCapitalized.pluralGenetive,
  apiResponseHandler: handleReceiptApiResponse(receiptActions.fetchSuccess)
})

const receiptUpdateFlow = updateFlow(receiptApi.update, receiptActions, receiptTitlesCapitalized.basic, receiptTitlesCapitalized.genetive, handleReceiptApiResponse(receiptActions.updateSuccess))
const receiptCreateFlow = createFlow(receiptApi.create, receiptActions, receiptTitlesCapitalized.basic, receiptTitlesCapitalized.genetive, handleReceiptApiResponse(receiptActions.createSuccess))
const receiptDeleteFlow = deleteFlow({
  deleteApi: receiptApi.remove,
  actions: receiptActions,
  singular: receiptTitlesCapitalized.basic,
  errorMsg: receiptTitlesCapitalized.genetive,
  base: RECEIPT_REDUX_NAME
})

const receiptMemoSagas = getMemoSagas({
  actions: receiptActions,
  baseName: RECEIPT_REDUX_NAME,
  socketName: 'receipt',
  titleGenetive: receiptTitles.genetive
})

const fileLinkSagas = getFileLinkSagas({
  actions: receiptActions,
  baseName: RECEIPT_REDUX_NAME,
  baseIdFieldName: 'receiptId',
  socketName: 'receipt',
  titleGenetive: receiptTitles.genetive
})

export default function* receiptSaga() {
  yield takeLatest(receiptActions.actionTypes.fetchRequest, receiptFetchFlow)
  yield takeEvery(receiptActions.actionTypes.updateRequest, receiptUpdateFlow)
  yield takeEvery(receiptActions.actionTypes.createRequest, receiptCreateFlow)
  yield takeEvery(receiptActions.actionTypes.deleteRequest, receiptDeleteFlow)
  yield takeLatest(receiptActions.actionTypes.patchActionRequest, receiptsPatchActionFlow)

  yield all([
    receiptMemoSagas(),
    fileLinkSagas(),
    watchOnReceiptSockets(),
    watchOnReceiptFileSockets()
  ])
}
