import { useMemo } from 'react'
import {
  ColumnSort,
  getPaginationRowModel,
  PaginationState,
  RowData,
  TableOptions,
  TableState
} from '@tanstack/react-table'
import { useDeepCompareMemoize } from 'use-deep-compare-effect'

import { RequiredFields } from '../../helpers/typeHelpers'
import { useLocalStorage } from '../../hooks/useLocalStorage'

export const dataTableStateStorageKey = (key: string, settingType: string) => `data_table_${settingType}_${key}`

const defaultPagination = { pageIndex: 0, pageSize: 50 }

/**
 * Create basic settings for given table with default values.
 * DataTable enables settings when given api function is provided so only extract what component needs
 *
 * "extraState" passed to tableState must be memoized!
 */
const useDataTableStateStorage = <TData extends RowData>(
  key: string,
  extraState?: object | null,
  defaults: { sizing?: Record<string, number>, sorting?: ColumnSort[], pagination?: PaginationState, filter?: string } = {}
) => {
  const [columnSizing, onColumnSizingChange] = useLocalStorage(dataTableStateStorageKey(key, 'sizing'), defaults.sizing ?? {})
  const [columnSorting, onSortingChange] = useLocalStorage(dataTableStateStorageKey(key, 'sorting'), defaults.sorting ?? [])
  const [globalFilter, onGlobalFilterChange] = useLocalStorage(dataTableStateStorageKey(key, 'filter'), defaults.filter ?? '')
  const [pagination, onPaginationChange] = useLocalStorage(dataTableStateStorageKey(key, 'pagination'), defaults.pagination ?? defaultPagination)

  const memoizedExtras = useDeepCompareMemoize(extraState)
  const tableState = useMemo(() => ({
    columnSizing,
    sorting: columnSorting,
    pagination,
    globalFilter,
    ...memoizedExtras
  } as TableState), [columnSizing, columnSorting, memoizedExtras, globalFilter, pagination])

  const sizingOptions: RequiredFields<Partial<TableOptions<TData>>, 'onColumnSizingChange'> = {
    onColumnSizingChange
  }
  const filteringOptions: RequiredFields<Partial<TableOptions<TData>>, 'onGlobalFilterChange'> = {
    onGlobalFilterChange,
    manualFiltering: false
  }
  const sortingOptions: RequiredFields<Partial<TableOptions<TData>>, 'onSortingChange'> = {
    onSortingChange,
    manualSorting: false
  }
  const paginationOptions: RequiredFields<Partial<TableOptions<TData>>, 'onPaginationChange'> & { isVirtualTotalCount: boolean } = {
    onPaginationChange,
    manualPagination: false,
    getPaginationRowModel: getPaginationRowModel(), // Define here so by default DataTable doesn't get paginated
    isVirtualTotalCount: false
  }
  return { tableState, sizingOptions, filteringOptions, sortingOptions, paginationOptions }
}

export default useDataTableStateStorage
