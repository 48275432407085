import PropTypes from 'prop-types'

import workRecordActions from '../../actions/workRecordActions'
import { workRecordPropType } from '../../propTypes'
import DeleteRecordPrompt from './DeleteRecordPrompt'

const DeleteWorkRecordPrompt = ({ visible, data }) => {
  return (
    <DeleteRecordPrompt
      visible={visible}
      data={data}
      deleteAction={workRecordActions.deleteRequest}
      typeLabel='työkirjaukset'
    />
  )
}

DeleteWorkRecordPrompt.propTypes = {
  visible: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    records: PropTypes.arrayOf(workRecordPropType)
  })
}

export default DeleteWorkRecordPrompt
