import { PropTypes } from 'prop-types'
import { Badge } from 'reactstrap'

import { childrenPropType } from '../../propTypes'

const BadgeRowCell = ({ children, numChildren }) => (
  <>
    {children}
    <div className='d-flex align-items-center'>
      <Badge color='info' pill data-testid='badge-cell'>{numChildren}</Badge>
    </div>
  </>
)
BadgeRowCell.propTypes = {
  children: childrenPropType,
  numChildren: PropTypes.number.isRequired
}

export default BadgeRowCell
