import { createApi } from '../helpers/apiHelpers'
import { parseFilterParams, parseTableOptionsFromQuery } from '../helpers/reducerHelpers'
import { normalizeMachinePricingRules } from './machinePricingRuleApi'
import { optionsQueryBase } from './urlTemplates'

export const normalizeMachines = response => {
  const data = response.record || response.records
  const {
    options,
    machinePricingRules
  } = response._embedded || {}

  return {
    data,
    tableOptions: parseTableOptionsFromQuery(options),
    machinePricingRules: normalizeMachinePricingRules(machinePricingRules)
  }
}

export const machineApi = createApi({
  base: 'machines',
  normalizer: normalizeMachines,
  extraUrlTemplate: optionsQueryBase,
  editGetParams: parseFilterParams
})
