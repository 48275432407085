import { parseTemplate } from 'url-template'

import constants, { actionTypes } from '../constants'
import {
  actionCreatorsFor,
  error,
  errorRecords,
  request,
  requestRecords,
  start,
  startRecords,
  success,
  successRecords
} from '../helpers/actionHelpers'
import {
  PATH_RECURRING_WORK,
  PATH_SETTINGS,
  PATH_WORK,
  PATH_WORK_TEMPLATES
} from '../routes'
import { navigateTo } from './navigateActions'
import tableActions from './tableActions'

const workUrlTemplate = parseTemplate(`${PATH_WORK}{/workId}{?customerId,targetId,source}`)
const instalmentTemplate = parseTemplate(`${PATH_SETTINGS}/instalment_templates{/instalmentId}`)
const fillOutFormTemplate = parseTemplate(`${PATH_WORK}{/workId}#form{/fillOutFormType}{/fillOutFormId}`)
export const getUrl = (workId, params) => workUrlTemplate.expand({ workId: workId || 'new', ...params })
const getFormTemplateUrl = (workId, formTemplateId, formTemplateWorkFormId, params) => `${PATH_WORK}/${workId}/formTemplate/${formTemplateId}/${formTemplateWorkFormId || 'new'}${params || ''}`

const baseActionCreators = actionCreatorsFor(constants.actionKeys.WORK, { createWorkRowsFromWorkRecords: true, createWorkRowsFromMachineRecords: true, summaryStatsFetch: true, preHandoverInspectionMemosFetch: true, fetchExtraInfo: true, createWorkTicket: true })
const baseWorkRowActionCreators = actionCreatorsFor(constants.actionKeys.WORK_ROWS, { updateRank: true })

const additionalActionCreators = {
  workRows: {
    ...baseWorkRowActionCreators,
    doPostAction: request(actionTypes.WORK_ROW_POST_ACTION_REQUEST),
    doPutAction: request(actionTypes.WORK_ROW_PUT_ACTION_REQUEST),
    updateRankRequest: request(baseWorkRowActionCreators.actionTypes.updateRankRequest)
  },
  files: actionCreatorsFor(constants.actionKeys.WORK_FILES),
  memos: actionCreatorsFor(constants.actionKeys.WORK_MEMOS),
  employees: actionCreatorsFor(constants.actionKeys.WORK_EMPLOYEES),
  workScheduleEvents: actionCreatorsFor(constants.actionKeys.WORK_SCHEDULE_EVENTS),
  workMachines: actionCreatorsFor(constants.actionKeys.WORK_MACHINES),
  navigateTo: (workId, replaceUrl, params) => navigateTo(getUrl(workId, params), replaceUrl),
  navigateToBase: replaceUrl => navigateTo(PATH_WORK, replaceUrl),
  navigateToWorkTemplateBase: replaceUrl => navigateTo(PATH_WORK_TEMPLATES, replaceUrl),
  navigateToRecurringWorkBase: replaceUrl => navigateTo(PATH_RECURRING_WORK, replaceUrl),
  navigateToInstalmentTemplateBase: replaceUrl => navigateTo(instalmentTemplate.expand({}), replaceUrl),
  navigateToInstalmentTemplate: (instalmentId, replaceUrl) =>
    navigateTo(instalmentTemplate.expand({ instalmentId: instalmentId || 'new' }), replaceUrl),
  navigateToFilloutFormBase: ({ workId, fillOutFormType, fillOutFormId }, replaceUrl) => navigateTo(fillOutFormTemplate.expand({ workId, fillOutFormType, fillOutFormId }), replaceUrl),
  navigateToWorkFormTemplateForm: (workId, formTemplateId, formTemplateWorkFormId, params, replaceUrl) => navigateTo(getFormTemplateUrl(workId, formTemplateId, formTemplateWorkFormId, params), replaceUrl),
  importInstalmentRowsFromFile: (workId, files) => ({
    type: actionTypes.WORK_INSTALMENT_IMPORT_ROWS_REQUEST,
    workId,
    files
  }),
  showMoreSearchResults: request(actionTypes.WORK_SHOW_MORE_SEARCH_RESULTS_REQUEST),
  createWorkRowsFromInboundInvoiceRowsRequest: request(actionTypes.WORK_ROWS_CREATE_FROM_INBOUND_INVOICE_ROWS_REQUEST),
  createWorkRowsFromInboundInvoiceRowsStart: start(actionTypes.WORK_ROWS_CREATE_FROM_INBOUND_INVOICE_ROWS_START),
  createWorkRowsFromInboundInvoiceRowsSuccess: success(actionTypes.WORK_ROWS_CREATE_FROM_INBOUND_INVOICE_ROWS_SUCCESS),
  createWorkRowsFromInboundInvoiceRowsError: error(actionTypes.WORK_ROWS_CREATE_FROM_INBOUND_INVOICE_ROWS_ERROR),
  doPatchAction: request(actionTypes.WORK_PATCH_ACTION_REQUEST),
  doPostAction: request(actionTypes.WORK_POST_ACTION_REQUEST),
  tableActions: tableActions(constants.actionKeys.WORK),
  createWorkRowsFromWorkRecordsRequest: request(baseActionCreators.actionTypes.createWorkRowsFromWorkRecordsRequest),
  createWorkRowsFromWorkRecordsStart: start(baseActionCreators.actionTypes.createWorkRowsFromWorkRecordsStart),
  createWorkRowsFromWorkRecordsSuccess: success(baseActionCreators.actionTypes.createWorkRowsFromWorkRecordsSuccess),
  createWorkRowsFromWorkRecordsError: error(baseActionCreators.actionTypes.createWorkRowsFromWorkRecordsError),
  createWorkRowsFromMachineRecordsRequest: request(baseActionCreators.actionTypes.createWorkRowsFromMachineRecordsRequest),
  createWorkRowsFromMachineRecordsStart: start(baseActionCreators.actionTypes.createWorkRowsFromMachineRecordsStart),
  createWorkRowsFromMachineRecordsSuccess: success(baseActionCreators.actionTypes.createWorkRowsFromMachineRecordsSuccess),
  createWorkRowsFromMachineRecordsError: error(baseActionCreators.actionTypes.createWorkRowsFromMachineRecordsError),
  summaryStatsFetchRequest: requestRecords(baseActionCreators.actionTypes.summaryStatsFetchRequest),
  summaryStatsFetchStart: startRecords(baseActionCreators.actionTypes.summaryStatsFetchStart),
  summaryStatsFetchSuccess: successRecords(baseActionCreators.actionTypes.summaryStatsFetchSuccess),
  summaryStatsFetchError: errorRecords(baseActionCreators.actionTypes.summaryStatsFetchError),
  createWorkTicketRequest: request(baseActionCreators.actionTypes.createWorkTicketRequest),
  createWorkTicketStart: startRecords(baseActionCreators.actionTypes.createWorkTicketStart),
  createWorkTicketSuccess: successRecords(baseActionCreators.actionTypes.createWorkTicketSuccess),
  createWorkTicketError: errorRecords(baseActionCreators.actionTypes.createWorkTicketError),

  preHandoverInspectionRowsApproveAction: request(actionTypes.PRE_HANDOVER_INSPECTION_ROWS_PUT_ACTION_REQUEST),
  preHandoverInspections: actionCreatorsFor(constants.actionKeys.PRE_HANDOVER_INSPECTIONS),
  preHandoverInspectionRows: actionCreatorsFor(constants.actionKeys.PRE_HANDOVER_INSPECTION_ROWS),
  preHandoverInspectionMemosFetchRequest: requestRecords(baseActionCreators.actionTypes.preHandoverInspectionMemosFetchRequest),
  preHandoverInspectionMemosFetchStart: startRecords(baseActionCreators.actionTypes.preHandoverInspectionMemosFetchStart),
  preHandoverInspectionMemosFetchSuccess: successRecords(baseActionCreators.actionTypes.preHandoverInspectionMemosFetchSuccess),
  preHandoverInspectionMemosFetchError: errorRecords(baseActionCreators.actionTypes.preHandoverInspectionMemosFetchError),
  preHandoverInspectionPdfPostRequest: request(actionTypes.PRE_HANDOVER_INSPECTION_PDF_POST_REQUEST),
  systemMessages: actionCreatorsFor(constants.actionKeys.WORK_SYSTEM_MESSAGES),
  fetchExtraInfoSuccess: success(baseActionCreators.actionTypes.fetchExtraInfoSuccess),
  workInvoicingRules: actionCreatorsFor(constants.actionKeys.WORK_INVOICING_RULES),
  doWorkAction: request(actionTypes.WORK_ACTION_REQUEST),
  workFormTemplates: actionCreatorsFor(constants.actionKeys.WORK_FORM_TEMPLATES),
  workForms: actionCreatorsFor(constants.actionKeys.WORK_FORMS),
  workFormsPdf: actionCreatorsFor(constants.actionKeys.WORK_FORMS_PDF),
  workTicketTemplateLists: actionCreatorsFor(constants.actionKeys.WORK_TICKET_TEMPLATE_LISTS)
}

const workActions = { ...baseActionCreators, ...additionalActionCreators }
export default workActions
