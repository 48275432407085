import { parseTemplate } from 'url-template'

import {
  del,
  get,
  getSubApi,
  getSubEntityApi,
  patch,
  post,
  put,
  upload
} from '../helpers/apiHelpers'
import { parseFilterParams, parseTableOptionsFromQuery } from '../helpers/reducerHelpers'
import { customerSubItemUrlTemplate, normalizeCustomers } from './customerApi'
import { targetSubItemUrlTemplate } from './targetApi'
import { emailQueryTerms, optionsQueryBase, queryTermsToTemplate } from './urlTemplates'
import { workSubItemUrlTemplate } from './workApi'

const baseUrl = '/api/invoices{/invoiceId}'
const invoiceQueryTerms = ['charge', 'force', 'pastDue', 'sendToCustomer', 'completedAt', 'typeParam']
const invoiceActionBase = `${baseUrl}{/action}${queryTermsToTemplate(invoiceQueryTerms)}`
const invoiceEmailTemplate = `${baseUrl}{/action}${queryTermsToTemplate(invoiceQueryTerms.concat(emailQueryTerms))}`

const invoiceUrlTemplate = parseTemplate(`${baseUrl}${optionsQueryBase}`)
const invoiceActionUrlTemplate = parseTemplate(invoiceActionBase)
const invoiceFileUrlTemplate = parseTemplate(baseUrl + '/files{/invoiceFileId}')
const invoiceAccountingUrlTemplate = parseTemplate(baseUrl + '/accountings{/accountingId}')
const invoicePdfUrlTemplate = parseTemplate(baseUrl + '/pdf')
const invoiceSubItemUrlTemplate = parseTemplate(baseUrl + '{/subItem,subItemType,subItemId}')
const invoiceFormSettingsUrlTemplate = parseTemplate(baseUrl + '/invoice_form_settings{/formSettingId}')

const getFetchUrl = params => {
  if(params.customerId) {
    return customerSubItemUrlTemplate.expand({ ...params, subItem: 'invoices' })
  } else if(params.targetId) {
    return targetSubItemUrlTemplate.expand({ ...params, subItem: 'invoices' })
  } else if(params.workId) {
    return workSubItemUrlTemplate.expand({ ...params, subItem: 'invoices' })
  } else if(params.templateWorkId) {
    return workSubItemUrlTemplate.expand({ workId: params.templateWorkId, subItem: 'invoice_occurrencies' })
  } else if(params.action) {
    return invoiceActionUrlTemplate.expand(params)
  } else {
    const filterParams = parseFilterParams(params)
    return invoiceUrlTemplate.expand(filterParams)
  }
}

export const normalizeInvoices = response => {
  const { _embedded, records, record } = response
  const {
    options,
    subInvoices
  } = _embedded || {}
  const data = records || record
  return {
    data,
    subInvoices,
    tableOptions: parseTableOptionsFromQuery(options)
  }
}

export const fetchInvoices = (params = {}) =>
  get(getFetchUrl(params))
    .then(normalizeInvoices)

export const updateInvoice = invoice =>
  put(invoiceUrlTemplate.expand({ invoiceId: invoice.id }), {
    body: JSON.stringify(invoice)
  }).then(normalizeInvoices)

export const createInvoice = (invoice, params) =>
  post(invoiceUrlTemplate.expand({ ...params }), {
    body: JSON.stringify(invoice)
  }).then(normalizeInvoices)

export const deleteInvoice = (invoice, params) =>
  del(invoiceUrlTemplate.expand({ invoiceId: invoice.id, ...params }))

export const chargeInvoice = (invoice, { typeParam, emailOptions, attachments = [], processType }) => {
  const withEmailQueryUrl = parseTemplate(invoiceEmailTemplate)
  const url = withEmailQueryUrl.expand({ invoiceId: invoice.id, action: 'invoice', typeParam, processType, ...emailOptions })

  return post(url, {
    body: JSON.stringify({ attachments })
  }).then(normalizeInvoices)
}

const normalizeInvoiceAccountingList = data => data

export const {
  fetchApi: fetchInvoiceAccountings,
  createApi: createInvoiceAccounting,
  updateApi: updateInvoiceAccounting,
  deleteApi: deleteInvoiceAccounting
} = getSubApi(invoiceAccountingUrlTemplate, 'accountingId', normalizeInvoiceAccountingList)

export const importInvoicePaymentsFromFile = file => {
  const formData = new FormData()
  formData.append('payments', file)
  return upload(invoiceActionUrlTemplate.expand({ action: 'payments' }), {
    body: formData
  })
}

export const createCreditInvoice = (data = {}, { invoiceId, sendToCustomer } = {}) =>
  post(invoiceActionUrlTemplate.expand({ action: 'credit_invoice', invoiceId, sendToCustomer }), {
    body: JSON.stringify(data)
  }).then(normalizeInvoices)

export const {
  fetchApi: fetchInvoiceFiles,
  createApi: createInvoiceFile,
  deleteApi: deleteInvoiceFile
} = getSubEntityApi(invoiceFileUrlTemplate, 'invoiceId', 'fileId', 'invoiceFileId', 'file')

export const sendInvoicePdf = invoiceId =>
  post(invoicePdfUrlTemplate.expand({ invoiceId }))

export const createReminderInvoices = ({ invoiceIds, charge, force }) =>
  post(invoiceActionUrlTemplate.expand({ action: 'reminders', charge, force }), {
    body: JSON.stringify({ ids: invoiceIds })
  }).then(normalizeInvoices)

export const doInvoicePatchAction = (data, { invoiceId, action }) =>
  patch(invoiceActionUrlTemplate.expand({ invoiceId, action }), {
    body: JSON.stringify(data)
  }).then(normalizeInvoices)

export const fetchInterestInvoicing = ({ completedAt }) =>
  get(invoiceActionUrlTemplate.expand({ action: 'interest_invoicing', completedAt }), {
  }).then(response => {
    const { records, _embedded } = response
    return {
      data: records,
      invoices: normalizeInvoices(_embedded.invoices),
      customers: normalizeCustomers(_embedded.customers)
    }
  })
export const createInterestInvoices = ({ customerIds, completedAt, typeParam }) =>
  post(invoiceActionUrlTemplate.expand({ action: 'interest_invoicing' }), {
    body: JSON.stringify({ customerIds, completedAt, typeParam })
  }).then(normalizeInvoices)

export const fetchLastInterestInvoicingRun = () =>
  get(invoiceSubItemUrlTemplate.expand({ subItem: 'interest_invoicing', subItemType: 'last_run' }))

export const createConsolidateInvoice = invoice => {
  return post(invoiceActionUrlTemplate.expand({ action: 'consolidated_invoice' }), {
    body: JSON.stringify(invoice)
  }).then(normalizeInvoices)
}

export const {
  fetchApi: fetchInvoiceFormSettings,
  updateApi: updateInvoiceFormSetting
} = getSubApi(invoiceFormSettingsUrlTemplate, 'formSettingId')
