import { takeEvery } from 'redux-saga/effects'

import {
  insuranceCompanyApi
} from '../api/insuranceCompanyApi'
import { defaultApiResponseHandler, fetchFlow } from '../helpers/sagaHelpers'
import { insuranceCompanyActions } from '../slices/insuranceCompanySlice'

const insuranceCompanyFetchFlow = fetchFlow({
  fetchApi: insuranceCompanyApi.fetch,
  actions: insuranceCompanyActions,
  base: 'insuranceCompanies',
  errorMsg: 'vakuutusyhtiöiden',
  apiResponseHandler: defaultApiResponseHandler(insuranceCompanyActions.fetchSuccess)
})

export default function* insuranceCompanySaga() {
  yield takeEvery(insuranceCompanyActions.fetchRequest.type, insuranceCompanyFetchFlow)
}
