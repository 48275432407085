import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { findCustomerWithId } from '../../../selectors/customerSelectors'
import { PostalSearchItem } from '../../Helpers/searchResultRenderers'

const WorkCustomerDetails = ({ customerId }) => {
  const customer = useSelector(state => findCustomerWithId(state, customerId))
  if(!customer) {
    return null
  }
  return (
    <>
      <br />
      <PostalSearchItem item={customer} />
    </>
  )
}

WorkCustomerDetails.propTypes = {
  customerId: PropTypes.number
}

export default WorkCustomerDetails
