import { parseTemplate } from 'url-template'

import {
  del,
  get,
  getSearchParams,
  getSubEntityApi,
  patch,
  post,
  put,
  upload
} from '../helpers/apiHelpers'
import { parseFilterParams, parseTableOptionsFromQuery } from '../helpers/reducerHelpers'
import { expandWithSearch, optionsQueryTerms } from './urlTemplates'
import { normalizeWarehouseResponseWithProducts } from './warehouseApi'

const baseUrlQueryTerms = ['pricingRuleSetId', 'purchasePricingType', 'onlyEnabled', 'onlyQuickProducts', 'onlyInWarehouse', 'showExtraInfo', ...optionsQueryTerms]

const baseUrl = `/api/products/{productId}{/action}{?${baseUrlQueryTerms.join(',')}}`

const productTaskBaseUrl = '/api/productTasks{/productTaskId}'
const productTaskBaseUrlTemplate = parseTemplate(productTaskBaseUrl)

const productUrlTemplate = parseTemplate(baseUrl)
const productFileUrlTemplate = parseTemplate(baseUrl + '/files{/productFileId}')
const productTaskUrlTemplate = parseTemplate(`${baseUrl}/productTasks{/productTaskId}`)
const productSubItemUrlTemplate = parseTemplate(`${baseUrl}{/subItem,subItemType,subItemId}`)

export const fetchProducts = (params = {}) => {
  const filterParams = parseFilterParams(params)
  return get(expandWithSearch(productUrlTemplate, filterParams))
    .then(normalizeProducts)
}

export const updateProduct = product => {
  return put(productUrlTemplate.expand({ productId: product.id }), {
    body: JSON.stringify(product)
  }).then(normalizeProducts)
}

export const createProduct = product => {
  return post(productUrlTemplate.expand({}), {
    body: JSON.stringify(product)
  }).then(normalizeProducts)
}

export const searchProduct = (search, data = {}) => {
  const filterParams = parseFilterParams(data)
  return get(productUrlTemplate.expand({ ...filterParams, ...getSearchParams(search) }))
    .then(normalizeProducts)
}

export const importProductsFromFile = file => {
  const formData = new FormData();
  (Array.isArray(file) ? file : [file]).forEach(file => formData.append('products', file))
  return upload('/api/products/import', {
    body: formData
  })
}

export const normalizeProducts = response => {
  const { _embedded, records, record } = response
  const {
    productTasks,
    extraInfo,
    options
  } = _embedded || {}
  const data = records || record
  return {
    data,
    extraInfo,
    productTasks,
    tableOptions: parseTableOptionsFromQuery(options)
  }
}

export const {
  fetchApi: fetchProductFiles,
  createApi: createProductFile,
  deleteApi: deleteProductFile
} = getSubEntityApi(productFileUrlTemplate, 'productId', 'fileId', 'productFileId', 'file')

export const fetchProductTasks = ({ productId }) =>
  productId
    ? get(productTaskUrlTemplate.expand({ productId }))
    : get(productTaskBaseUrlTemplate.expand({}))

export const updateProductTask = productTask =>
  put(productTaskBaseUrlTemplate.expand({ productTaskId: productTask.id }), {
    body: JSON.stringify(productTask)
  })

export const createProductTask = productTask =>
  post(productTaskBaseUrlTemplate.expand({}), {
    body: JSON.stringify(productTask)
  })

export const deleteProductTask = productTask =>
  del(productTaskBaseUrlTemplate.expand({ productTaskId: productTask.id }))

export const deleteProduct = product => {
  return del(productUrlTemplate.expand({ productId: product.id }))
}

export const fetchProductStats = ({ productId }) =>
  get(productSubItemUrlTemplate.expand({ productId, subItem: 'stats' }))

export const fetchProductWarehouseStats = ({ productId }) =>
  get(productSubItemUrlTemplate.expand({ productId, subItem: 'warehouses', subItemType: 'stats' }))
    .then(normalizeWarehouseResponseWithProducts)

export const doProductPostAction = (data, opts = {}) =>
  post(productSubItemUrlTemplate.expand(opts), {
    body: JSON.stringify(data || {})
  })

export const doProductPatchAction = (data, { productId, action } = {}) =>
  patch(productUrlTemplate.expand({ productId, action }), {
    body: JSON.stringify(data || {})
  })
