import constants, { actionTypes } from '../constants'
import { actionCreatorsFor, request } from '../helpers/actionHelpers'
import { PATH_SETTINGS_EMPLOYEES } from '../routes'
import { navigateTo } from './navigateActions'

export const getUrl = employeeId => `${PATH_SETTINGS_EMPLOYEES}/${employeeId || 'new'}`

const baseActionCreators = actionCreatorsFor(constants.actionKeys.EMPLOYEES)

const additionalActionCreators = {
  employeeLevels: actionCreatorsFor(constants.actionKeys.EMPLOYEE_LEVELS),
  employeeSubcontractors: actionCreatorsFor(constants.actionKeys.EMPLOYEE_SUBCONTRACTORS),
  employeeInvitations: actionCreatorsFor(constants.actionKeys.EMPLOYEE_INVITATIONS),
  navigateTo: (employeeId, replaceUrl) => navigateTo(getUrl(employeeId), replaceUrl),
  navigateToBase: replaceUrl => navigateTo(PATH_SETTINGS_EMPLOYEES, replaceUrl),
  fetchEmployeeWorkRequest: request(actionTypes.EMPLOYEE_WORK_FETCH_REQUEST),
  employeeLicences: actionCreatorsFor(constants.actionKeys.EMPLOYEE_LICENCES),
  files: actionCreatorsFor(constants.actionKeys.EMPLOYEE_FILES),
  systemMessages: actionCreatorsFor(constants.actionKeys.EMPLOYEE_SYSTEM_MESSAGES),
  memos: actionCreatorsFor(constants.actionKeys.EMPLOYEE_MEMOS),
  extras: actionCreatorsFor(constants.actionKeys.EMPLOYEE_EXTRAS)
}

const employeeActions = { ...baseActionCreators, ...additionalActionCreators }
export default employeeActions
