import { capitalizeObjectValues } from '../../helpers/helpers'

const machineTitles = {
  basic: 'resurssi',
  genetive: 'resurssin',
  partitive: 'resurssia',
  allative: 'resurssille',
  pluralBasic: 'resurssit',
  pluralGenetive: 'resurssien',
  pluralPartitive: 'resursseja'
}

export const machineTitlesCapitalized = capitalizeObjectValues(machineTitles)

export default machineTitles
