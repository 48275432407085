import { createApi } from '../helpers/apiHelpers'
import { parseFilterParams, parseTableOptionsFromQuery } from '../helpers/reducerHelpers'
import { optionsQueryBase } from './urlTemplates'

export const normalizeFormTemplates = response => {
  const { _embedded, records, record } = response
  const {
    options
  } = _embedded || {}
  const data = records || record

  return {
    data,
    tableOptions: parseTableOptionsFromQuery(options)
  }
}

export const formTemplateApi = createApi({
  base: 'forms/templates',
  normalizer: normalizeFormTemplates,
  extraUrlTemplate: optionsQueryBase,
  editGetParams: parseFilterParams
})

export const formApi = createApi({
  base: 'forms',
  normalizer: normalizeFormTemplates,
  extraUrlTemplate: optionsQueryBase,
  editGetParams: parseFilterParams
})
