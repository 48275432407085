import { toIdMap } from '@evelia/helpers/helpers'
import { createSelector } from 'reselect'

import {
  getFindItemByIdSelector,
  getItemsFromSearchResultsSelector,
  getMemoSelector,
  getStats,
  getSubentitySelectors
} from '../helpers/selectorHelpers'

export const getCustomersFromArgument = arg => arg.customers ? arg.customers.records : arg

export const findCustomerWithId = getFindItemByIdSelector(getCustomersFromArgument)

export const {
  getSubItemsOfItem: getContactsOfCustomer,
  rejectSubItemsOfItem: rejectContactsOfCustomer
} = getSubentitySelectors('customers', 'contacts', 'contacts', 'customerId', 'contactId')

export const {
  getSubItemsOfItem: getFilesOfCustomer,
  rejectSubItemsOfItem: rejectFilesOfCustomer
} = getSubentitySelectors('customers', 'files', 'files', 'customerId', 'fileId')

export const findMemosByCustomerId = getMemoSelector('customers', 'customerId')

export const getCustomersFromSearchResult = getItemsFromSearchResultsSelector('customers', getCustomersFromArgument, findCustomerWithId)

export const getStatsOfCustomer = getStats(findCustomerWithId, 'customers', 'customerId')

export const getCustomersMap = createSelector(
  getCustomersFromArgument,
  customers => toIdMap(customers))
