import { useController } from 'react-hook-form'

import { formInputPropTypes } from '../../propTypes'
import FormPlainInput from './FormPlainInput'

const FormInput = ({ control, ...props }) => {
  const { field, formState } = useController({ name: props.name, control })
  const { value, onChange, ref, ...fieldProps } = field
  const inputErrors = formState.errors
  return (
    <FormPlainInput
      {...props}
      value={value}
      errors={props.errors || inputErrors}
      onChange={onChange}
      inputProps={{
        innerRef: ref,
        ...props.inputProps,
        ...fieldProps
      }}
    />
  )
}

FormInput.propTypes = formInputPropTypes

export default FormInput
