import { Component } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'

import { commonFormInputDefaultProps, commonFormInputPropTypes } from '../propTypes'

export class RadioFormInput extends Component {
  render() {
    const {
      field,
      isInvalid,
      disabled,
      disableAll,
      value,
      inputValue,
      inputId,
      groupLabel,
      label,
      onKeyDown,
      testId
    } = this.props
    return (
      <Input
        invalid={isInvalid}
        type='radio'
        name={field}
        id={inputId}
        value={inputValue}
        checked={`${inputValue}` === `${value}`}
        disabled={disabled || disableAll}
        onChange={this.handleOnChange}
        label={groupLabel || label}
        onKeyDown={onKeyDown}
        data-testid={testId || field}
      />
    )
  }

  handleOnChange = event => {
    const { isNumeric, onChange } = this.props
    const value = event.target.value === '' ? null : (isNumeric ? Number(event.target.value) : event.target.value)
    return onChange(value, event)
  }

  static propTypes = {
    ...commonFormInputPropTypes,
    isNumeric: PropTypes.bool
  }

  static defaultProps = commonFormInputDefaultProps
}

export default RadioFormInput
