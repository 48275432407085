import { Component } from 'react'
import PropTypes from 'prop-types'

import { SearchFormGroup, TypeaheadFormGroup } from '../FormGroups'
import { renderPostalSearchItem } from '../Helpers/searchResultRenderers'
import SelectModal from '../Modals/SelectModal'

class BaseChooser extends Component {
  state = {
    isModalOpen: false
  }

  componentDidUpdate(prevProps) {
    const { item, itemId, additionalUpdater } = this.props
    if(itemId && itemId !== prevProps.itemId) {
      if(additionalUpdater) {
        additionalUpdater(item)
      }
    }
  }

  componentDidMount() {
    this.props.fetchAll?.()
  }

  render() {
    const {
      item,
      ItemTemplate,
      newItemHeader,
      NewItemComponent,
      getNewItemProps,
      labelKey,
      defaultProps,
      setNull,
      isRequired,
      fieldName,
      label,
      navigateTo,
      disableNew,
      disabled,
      searchAction,
      options,
      renderSearchResult,
      size
    } = this.props
    const { isModalOpen } = this.state
    const isDisabled = disabled ?? !!defaultProps.disableAll
    if(item) {
      return <ItemTemplate {...this.props} navigateTo={navigateTo} disabled={isDisabled} />
    }

    const FormGroupComponent = searchAction ? SearchFormGroup : TypeaheadFormGroup

    return (
      <>
        <FormGroupComponent
          {...defaultProps}
          disabled={isDisabled}
          setNull={setNull}
          label={label}
          field={fieldName}
          labelKey={labelKey}
          renderMenuItemChildren={renderSearchResult ?? renderPostalSearchItem}
          handleOnChange={this.handleOnChange}
          searchAction={searchAction}
          options={options}
          allowNew={!disableNew}
          handleNew={this.handleNew}
          placeholder='Aloita haku kirjoittamalla'
          isRequired={isRequired}
        />
        {NewItemComponent && (
          <SelectModal
            headerTitle={newItemHeader}
            isOpen={isModalOpen}
            closeModal={this.closeModal}
            bodyComponent={<NewItemComponent {...getNewItemProps?.(this.state.name, this.handleSaved)} />}
            size={size}
          />
        )}
      </>
    )
  }

  handleOnChange = (changedItem, skipIsDirty = false) => {
    const { updateField, fieldName, handleOnChange } = this.props
    const item = Array.isArray(changedItem) ? changedItem[0] : changedItem
    return Promise.resolve(updateField(fieldName, item ? item.id : null, skipIsDirty))
      .then(model => handleOnChange?.(item, model))
  }

  handleSaved = savedItem =>
    this.handleOnChange(savedItem)
      .then(() => {
        this.closeModal()
        return [savedItem, true]
      })

  handleNew = name => this.setState({ isModalOpen: true, name })
  closeModal = () => this.setState({ isModalOpen: false, name: null })

  static propTypes = {
    item: PropTypes.object,
    itemId: PropTypes.number,
    ItemTemplate: PropTypes.elementType.isRequired,
    newItemHeader: PropTypes.string,
    NewItemComponent: PropTypes.elementType,
    getNewItemProps: PropTypes.func,
    labelKey: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func
    ]).isRequired,
    searchAction: PropTypes.func,
    options: PropTypes.array,

    defaultProps: PropTypes.object,
    fieldName: PropTypes.string,
    label: PropTypes.string.isRequired,
    updateField: PropTypes.func,
    additionalUpdater: PropTypes.func,
    handleOnChange: PropTypes.func,
    disabled: PropTypes.bool,
    setNull: PropTypes.bool,
    isRequired: PropTypes.bool,
    fetchAll: PropTypes.func,
    navigateTo: PropTypes.func,
    disableNew: PropTypes.bool,
    renderSearchResult: PropTypes.func.isRequired,
    size: PropTypes.string
  }

  static defaultProps = {
    isRequired: false
  }
}

export default BaseChooser
