import { actionTypes } from '../constants'

const initialState = {
  status: null,
  message: null,
  url: null
}

const sagaErrorHandlerReducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.SAGA_ERROR_HANDLE: {
      const error = action.data
      return {
        ...initialState,
        ...error
      }
    }
    case actionTypes.SAGA_ERROR_CLEAR:
      return initialState
    default:
      return state
  }
}

export default sagaErrorHandlerReducer
