import PropTypes from 'prop-types'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { oneOfPrimitivesPropType } from '../../propTypes'

/**
 * Remember to create handle for dragging using useSortable `setActivatorNodeRef`
 */
const Draggable = ({ id, ...rest }) => {
  const {
    setNodeRef,
    transform,
    transition
  } = useSortable({ id })

  const style = {
    ...rest.style,
    transform: CSS.Transform.toString(transform),
    transition
  }
  return (
    <div
      ref={setNodeRef}
      {...rest}
      style={style}
    />
  )
}

Draggable.propTypes = {
  id: oneOfPrimitivesPropType.isRequired,
  style: PropTypes.object
}

export default Draggable
