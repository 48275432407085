import { capitalizeObjectValues } from '../../helpers/helpers'

const machineRecordTitles = {
  basic: 'konetunti',
  genetive: 'konetunnin',
  partitive: 'konetuntia',
  allative: 'konetunnille',
  pluralBasic: 'konetunnit',
  pluralGenetive: 'konetuntien',
  pluralPartitive: 'konetunteja'
}

export const machineRecordTitlesCapitalized = capitalizeObjectValues(machineRecordTitles)

export default machineRecordTitles
