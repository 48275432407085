import { ComponentType, ReactNode } from 'react'

import { useTypedSelector } from '../../reducerTypes'
import { findCurrentEmployeeLevel } from '../../selectors/employeeSelectors'
import { defaultStage, findFeaturePermissionsByKey, getSystemCustomerPermissions } from './featurePermissions'

/**
 * Render props component displaying content based on whoAmI employee level
 *
 * @param {number} featureKeyOrAccessLevel Either numeric employee level ADMIN, USER or feature key (check featurePermissions.js)
 * @param {function} renderHasAccess
 * @param {function} renderFallback
 */

interface PermissionWrapperProps {
  fallbackComponent?: ComponentType<{ children?: ReactNode | (() => ReactNode) }>
  hide?: boolean
  children?: ReactNode | (() => ReactNode)
}

const withPermissionCheck = (featureKeyOrAccessLevel: string | number, HasAccessComponent: ComponentType<{ children?: ReactNode | (() => ReactNode) }>, FallbackComponent?: ComponentType) => {
  const { minAccessLevel, stage } = Number.isInteger(Number(featureKeyOrAccessLevel))
    ? { minAccessLevel: Number(featureKeyOrAccessLevel), stage: defaultStage }
    : findFeaturePermissionsByKey(featureKeyOrAccessLevel)

  const PermissionWrapper = ({
    fallbackComponent,
    hide,
    ...rest
  }: PermissionWrapperProps) => {
    const employeeAccessLevel = useTypedSelector(state => findCurrentEmployeeLevel(state).accessLevel)
    const { systemCustomerStage } = useTypedSelector(state => getSystemCustomerPermissions(state.systemCustomer))
    const Fallback = fallbackComponent || FallbackComponent
    const hasAccess = employeeAccessLevel >= minAccessLevel && systemCustomerStage >= stage
    if(hasAccess) {
      return <HasAccessComponent {...rest} />
    }
    return Fallback
      ? <Fallback {...rest} />
      : null
  }

  return PermissionWrapper
}

export default withPermissionCheck
