import { createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'

import {
  getFilterItemsByFieldSelector,
  getFindItemByIdSelector,
  getSubentitySelectors
} from '../helpers/selectorHelpers'
import { findInboundInvoiceWithId } from './inboundInvoiceSelectors'

export const getReceiversFromArgument = arg => arg.receivers ? arg.receivers.records : arg
const getReceiverApproversFromArgument = arg => arg?.receivers?.approvers?.records ?? arg

export const findReceiverWithId = getFindItemByIdSelector(getReceiversFromArgument)

export const {
  getSubItemsOfItem: getFilesOfReceiver
} = getSubentitySelectors('receivers', 'files', 'files', 'receiverId', 'fileId')

export const getPaymentProhibitedReceivers = createSelector(
  getReceiversFromArgument,
  receivers => receivers.filter(receiver => receiver.isPaymentProhibited)
)

export const getReceiverApproversByReceiver = getFilterItemsByFieldSelector(getReceiverApproversFromArgument, 'receiverId')

export const findReceiverByInboundInvoiceId = createCachedSelector(
  getReceiversFromArgument,
  (state, { inboundInvoiceId }) => findInboundInvoiceWithId(state, inboundInvoiceId),
  (receivers, inboundInvoice) => inboundInvoice?.receiverId == null ? null : receivers.find(receiver => receiver.id === inboundInvoice.receiverId)
)((state, { inboundInvoiceId }) => `${inboundInvoiceId}`)

export const getReceiversWithSupplierId = createSelector(
  getReceiversFromArgument,
  receivers => receivers.filter(({ supplierId }) => supplierId != null)
)
