import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CountBadgeIcon = ({ icon, count, hideZero, ...iconProps }) => (
  <span className='fa-layers fa-fw'>
    <FontAwesomeIcon icon={icon} fixedWidth {...iconProps} />
    {hideZero && !count
      ? null
      : (
        <span
          data-testid='countBadgeIcon'
          className='fa-layers-counter fa-2x badge-icon-count'
        >
          {count}
        </span>
        )}
  </span>
)

CountBadgeIcon.propTypes = {
  icon: PropTypes.object,
  count: PropTypes.number,
  hideZero: PropTypes.bool
}
export default CountBadgeIcon
