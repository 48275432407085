import { createApi } from '../helpers/apiHelpers'

export const normalizeMachinePricingRules = response => {
  const data = response.record || response.records
  return {
    data
  }
}

const editGetParams = params => ({ ...params, id: params.machineId, subItem: 'machine_pricing_rules' })
const editMutateParams = (params, data) => ({ id: params.machineId ?? data.machineId, subItemId: params.id, subItem: 'machine_pricing_rules' })

export const machinePricingRuleApi = createApi({
  base: 'machines',
  normalizer: normalizeMachinePricingRules,
  editGetParams,
  editMutateParams
})
