import { parseTemplate } from 'url-template'

import { del, get, post, put } from '../helpers/apiHelpers'

const baseUrl = '/api/customer_groups/{customerGroupId}'
const customerGroupUrlTemplate = parseTemplate(baseUrl)

export const fetchCustomerGroups = (params = {}) => {
  return get(customerGroupUrlTemplate.expand(params))
    .then(parseCustomerGroupFetchResponse)
}

export const updateCustomerGroup = customerGroup => {
  return put(customerGroupUrlTemplate.expand({ customerGroupId: customerGroup.id }), {
    body: JSON.stringify(customerGroup)
  })
}

export const createCustomerGroup = customerGroup => {
  return post(customerGroupUrlTemplate.expand({}), {
    body: JSON.stringify(customerGroup)
  })
}

export const deleteCustomerGroup = customerGroup => {
  return del(customerGroupUrlTemplate.expand({ customerGroupId: customerGroup.id }))
}

const parseCustomerGroupFetchResponse = response => response
