import { createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'

import { accountRoles } from '../constants'

export const findInitialAccounting = createSelector(
  accountings => accountings,
  accountings => accountings.find(accounting => accounting.isInitial)
)

export const findPayments = createSelector(
  accountings => accountings,
  accountings => accountings.filter(accounting => !accounting.isInitial)
)

export const findAccountByRole = createCachedSelector(
  accountings => accountings,
  (state, accountingRole) => accountingRole,
  (accountings, accountingRole) => accountings.find(accounting => accounting.role === accountingRole)
)((accountings, accountingRole) => accountingRole)

const fieldNamesByRole = {
  [accountRoles.ACCOUNTING_ROLE_PAYABLE]: 'payableData',
  [accountRoles.ACCOUNTING_ROLE_RECEIVABLE]: 'receivableData',
  [accountRoles.ACCOUNTING_ROLE_PAYMENT]: 'paymentData',
  [accountRoles.ACCOUNTING_ROLE_SETTLEMENT]: 'settlementData',
  [accountRoles.ACCOUNTING_ROLE_DEFAULT]: 'defaultData'
}

const getFieldName = role => fieldNamesByRole[role] ?? fieldNamesByRole[accountRoles.ACCOUNTING_ROLE_DEFAULT]

export const mapAccountingsByRole = createSelector(
  accountings => accountings,
  (accountings, invert) => !!invert,
  (accountings, invert) => {
    return accountings.reduce((acc, accounting) => {
      const fieldName = getFieldName(accounting.role)
      const accountingToSave = {
        ...accounting,
        value: invert ? -accounting.value : accounting.value,
        valueWithoutVat: invert ? -accounting.valueWithoutVat : accounting.valueWithoutVat
      }
      acc[fieldName] = acc[fieldName] || []
      acc[fieldName].push(accountingToSave)
      return acc
    }, { defaultData: [] })
  }
)

export const reduceAccountsByAccountNumberAndVatCode = data => {
  const dataKeys = Object.keys(data).filter(key => Object.values(fieldNamesByRole).includes(key))
  return dataKeys.reduce((acc, dataKey) => {
    acc[dataKey] = Object.values(data[dataKey].filter(Boolean).reduce((acc2, accounting) => {
      const accountVatKey = `${accounting.accountNumber}_${accounting.vatCodeId}`

      acc2[accountVatKey] = acc2[accountVatKey] || { ...accounting, value: 0 }
      acc2[accountVatKey].value += accounting.value
      return acc2
    }, {}))
    return acc
  }, {})
}
