import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import fileActions from '../actions/fileActions'
import {
  createFile,
  deleteFile,
  downloadFile,
  fetchCustomerRelationFiles,
  fetchFiles,
  fetchProjectRelationFiles,
  updateFile,
  uploadToS3
} from '../api/fileApi'
import { promiseFileReader } from '../helpers/helpers'
import { addSuccessNotification } from '../helpers/notificationHelpers'
import {
  createSocketWatcher,
  deleteFlow,
  genericSagaErrorHandler,
  getPromiseHandlersFromData,
  updateFlow
} from '../helpers/sagaHelpers'

const watchOnSockets = createSocketWatcher('file', {
  created: fileActions.createSuccess
})

function* fileFetchFlow({ data = {} }) {
  try {
    yield put(fileActions.fetchStart())
    const files = yield call(fetchFiles, data)
    yield put(fileActions.fetchSuccess(files))
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe tiedostojen noutamisessa')
  }
}

function* customerFilesFetchFlow({ data = {} }) {
  try {
    yield put(fileActions.customerRelationFiles.fetchStart())
    const response = yield call(fetchCustomerRelationFiles, data)
    yield put(fileActions.customerRelationFiles.fetchSuccess(response.data))
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe tiedostojen noutamisessa')
  }
}

function* projectFilesFetchFlow({ data = {} }) {
  try {
    yield put(fileActions.projectRelationFiles.fetchStart())
    const response = yield call(fetchProjectRelationFiles, data)
    yield put(fileActions.projectRelationFiles.fetchSuccess(response.data))
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe tiedostojen noutamisessa')
  }
}

const fileUpdateFlow = updateFlow(updateFile, fileActions, 'Tiedosto', 'Tiedoston')
const fileDeleteFlow = deleteFlow({
  deleteApi: deleteFile,
  actions: fileActions,
  singular: 'Tiedosto',
  errorMsg: 'Tiedoston',
  base: 'files'
})

function* fileCreateFlow({ record = {}, data }) {
  const { file } = record
  const { resolve, reject } = getPromiseHandlersFromData(data)
  const cid = +(new Date())
  const fileRecord = {
    description: null,
    ...(data.extendedFileInfo || { systemTag: null, employeeLevelId: null }),
    id: cid,
    fileName: file.name,
    fileType: file.type || 'application/octet-stream', // defaulting to default, unknown file types return nothing
    size: file.size
  }
  try {
    yield put(fileActions.createStart(fileRecord))
    const { _s3Data, ...fileData } = yield call(createFile, fileRecord)
    yield call(uploadToS3, file, _s3Data)
    yield put(fileActions.createSuccess(fileData, cid))
    addSuccessNotification('Tiedosto tallennettu')
    yield call(resolve, fileData)
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe tiedoston tallennuksessa', reject, { showValidationErrors: true })
  }
}

function* fileDownloadFlow({ record = {}, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(fileActions.downloadFileStart(record))
    const response = yield call(downloadFile, record)
    const base64 = yield call(promiseFileReader, response)
    const newRecord = {
      id: record.id,
      fileName: record.fileName,
      fileType: response.type,
      base64: base64.substr(base64.indexOf(';base64,') + ';base64,'.length)
    }
    yield put(fileActions.downloadFileSuccess(newRecord))
    yield call(resolve, newRecord)
  } catch(err) {
    yield put(fileActions.downloadFileError(err, record))
    yield * genericSagaErrorHandler(err, 'Virhe tiedoston lataamisessa', reject)
  }
}

export default function* fileSaga() {
  yield takeLatest(fileActions.actionTypes.fetchRequest, fileFetchFlow)
  yield takeLatest(fileActions.customerRelationFiles.actionTypes.fetchRequest, customerFilesFetchFlow)
  yield takeLatest(fileActions.projectRelationFiles.actionTypes.fetchRequest, projectFilesFetchFlow)
  yield takeEvery(fileActions.actionTypes.createRequest, fileCreateFlow)
  yield takeEvery(fileActions.actionTypes.updateRequest, fileUpdateFlow)
  yield takeEvery(fileActions.actionTypes.deleteRequest, fileDeleteFlow)

  yield takeEvery(fileActions.actionTypes.downloadFileRequest, fileDownloadFlow)

  yield watchOnSockets()
}
