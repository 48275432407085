import React from 'react'
import { ColumnMeta, Getter } from '@tanstack/react-table'
import { clsx } from 'clsx'
import type { Primitive } from 'type-fest'

import { AllowedPolymorphicElements, PolymorphicPropsWithOwn } from '../../helpers/polymorphicTypes'
import FormPlainCheckboxInput from '../hookForm/FormPlainCheckboxInput'
import FormPlainInput from '../hookForm/FormPlainInput'

export const defaultDataTableRowInputProps = {
  role: 'cell',
  style: {
    backgroundColor: 'transparent'
  }
}

const defaultDataTableRowInputPlainCheckboxProps = {
  role: defaultDataTableRowInputProps.role
}

type PlainRowInputOwnProps<T> = {
  value?: T
  getValue?: Getter<T>
  name: string
  inputProps?: {
    className?: string
    getClassName?: (value: T | undefined, row: unknown) => string
  }
  meta?: ColumnMeta<T, unknown> & {
    inputProps?: {
      getClassName?: (value: T | undefined, row: unknown) => string
    }
  }
  row?: object
}

type PlainRowInputProps<T, TE extends AllowedPolymorphicElements> = PolymorphicPropsWithOwn<PlainRowInputOwnProps<T>, 'Input', TE>

const PlainRowInput = <T extends Primitive, TE extends AllowedPolymorphicElements>({
  getValue,
  value,
  name,
  inputProps,
  Input = FormPlainInput,
  meta,
  row,
  ...rest
}: PlainRowInputProps<T, TE>) => {
  const innerValue = value ?? getValue?.(name)
  const inputClassName = clsx(inputProps?.className, meta?.inputProps?.getClassName?.(innerValue, row))
  return (
    <Input
      name={name}
      value={innerValue ?? ''}
      inputProps={{
        ...(Input !== FormPlainCheckboxInput
          ? defaultDataTableRowInputProps
          : defaultDataTableRowInputPlainCheckboxProps
        ),
        ...inputProps,
        className: inputClassName,
        'aria-disabled': true,
        readOnly: true
      }}
      {...rest}
    />
  )
}

export default React.memo(PlainRowInput) as unknown as <T extends Primitive, TE extends AllowedPolymorphicElements = AllowedPolymorphicElements>(
  props: PlainRowInputProps<T, TE> & Partial<Pick<PlainRowInputProps<T, TE>, 'Input'>>,
) => ReturnType<typeof PlainRowInput>
