import { all, takeEvery, takeLatest } from 'redux-saga/effects'

import offerCustomStateActions from '../actions/offerCustomStateActions'
import { offerCustomStateApi } from '../api/offerCustomStateApi'
import {
  createFlow,
  createSocketWatcher,
  deleteFlow,
  fetchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

const watchOnOfferCustomStateSockets = createSocketWatcher('offerCustomState', {
  created: offerCustomStateActions.createSuccess,
  updated: offerCustomStateActions.updateSuccess,
  deleted: offerCustomStateActions.deleteSuccess
})

const offerCustomStateFetchFlow = fetchFlow({
  fetchApi: offerCustomStateApi.fetch,
  actions: offerCustomStateActions,
  base: 'offerCustomStates',
  idField: 'id',
  errorMsg: 'tarjouksen tilojen'
})

const offerCustomStateUpdateFlow = updateFlow(offerCustomStateApi.update, offerCustomStateActions, 'Tarjouksen tila', 'Tarjouksen tilan')
const offerCustomStateCreateFlow = createFlow(offerCustomStateApi.create, offerCustomStateActions, 'Tarjouksen tila', 'Tarjouksen tilan')
const offerCustomStateDeleteFlow = deleteFlow({
  deleteApi: offerCustomStateApi.remove,
  actions: offerCustomStateActions,
  singular: 'Tarjouksen tila',
  errorMsg: 'Tarjouksen tilan',
  base: 'offerCustomStates'
})

export default function* offerCustomStateSaga() {
  yield takeLatest(offerCustomStateActions.actionTypes.fetchRequest, offerCustomStateFetchFlow)
  yield takeEvery(offerCustomStateActions.actionTypes.updateRequest, offerCustomStateUpdateFlow)
  yield takeEvery(offerCustomStateActions.actionTypes.createRequest, offerCustomStateCreateFlow)
  yield takeEvery(offerCustomStateActions.actionTypes.deleteRequest, offerCustomStateDeleteFlow)
  yield all([
    watchOnOfferCustomStateSockets()
  ])
}
