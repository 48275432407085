import { capitalize, castToArray } from '@evelia/helpers/helpers'
import flatMap from 'lodash/flatMap'
import {
  all,
  call,
  put,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'

import fileActions from '../actions/fileActions'
import productActions from '../actions/productActions'
import {
  createProduct,
  createProductFile,
  createProductTask,
  deleteProduct,
  deleteProductFile,
  deleteProductTask,
  doProductPatchAction,
  doProductPostAction,
  fetchProductFiles,
  fetchProducts,
  fetchProductStats,
  fetchProductTasks,
  fetchProductWarehouseStats,
  importProductsFromFile,
  normalizeProducts,
  searchProduct,
  updateProduct,
  updateProductTask
} from '../api/productApi'
import { actionTypes } from '../constants'
import {
  addErrorNotification,
  addInfoNotification,
  addSuccessNotification,
  removeNotification,
  updateNotification
} from '../helpers/notificationHelpers'
import {
  createFlow,
  createSocketWatcher,
  deleteFlow,
  fetchFlow,
  fetchStatsFlow,
  genericSagaErrorHandler,
  getDefaultGeneratorSocketHandlers,
  getPromiseHandlersFromData,
  getSubEntitySagas,
  searchFlow,
  updateFlow
} from '../helpers/sagaHelpers'
import { handleWarehouseStatsApiResponse } from './warehouseSaga'

const importCallback = (types, typesGenetive) => data => {
  if(data.ok) {
    if(data.percent === 1) {
      removeNotification(types)
      return addSuccessNotification(`${types} tuotu ${Number((data.percent * 100).toFixed(2))}%`)
    }
    if(data.ready) {
      return addSuccessNotification(`${capitalize(typesGenetive)} tuonti valmis`, { autoClose: false })
    } else {
      const message = `${types} tuotu ${Number((data.percent * 100).toFixed(2))}%`
      updateNotification(types, { render: message })
      return addInfoNotification(`${types} tuotu ${Number((data.percent * 100).toFixed(2))}%`, { toastId: types, autoClose: false })
    }
  } else {
    console.error(data.error)
    return addErrorNotification(`Virhe ${typesGenetive} tuonnissa`)
  }
}

export const handleProductApiResponse = (mainAction, tableIdentifier) =>
  function* ({ data, extraInfo, productTasks, tableOptions }) {
    const combinedProductTasks = productTasks || flatMap(castToArray(data), '_embedded.productTasks')
    yield put(productActions.productTasks.fetchSuccess(combinedProductTasks))
    if(extraInfo) {
      yield put(productActions.fetchExtraInfoSuccess(extraInfo))
    }
    yield put(mainAction(data))
    if(tableOptions && tableIdentifier != null) {
      yield put(productActions.tableActions.updateOptions({ ...tableOptions }, tableIdentifier))
    }
    return data
  }

const watchOnSockets = createSocketWatcher('product', {
  ...getDefaultGeneratorSocketHandlers(productActions, handleProductApiResponse, normalizeProducts),
  'import:products': function* (data) { yield importCallback('Tuotteita', 'tuotteiden')(data) },
  'import:prices': function* (data) { yield importCallback('Hintoja', 'hintojen')(data) }
})

const productFetchFlow = fetchFlow({
  fetchApi: fetchProducts,
  actions: productActions,
  base: 'products',
  errorMsg: 'Tuotteiden',
  getApiResponseHandler: data => handleProductApiResponse(productActions.fetchSuccess, data.tableIdentifier || 'default')
})

const productUpdateFlow = updateFlow(updateProduct, productActions, 'Tuote', 'Tuotteen', handleProductApiResponse(productActions.updateSuccess))
const productCreateFlow = createFlow(createProduct, productActions, 'Tuote', 'Tuotteen', handleProductApiResponse(productActions.createSuccess))
const productSearchFlow = searchFlow(searchProduct, productActions, 'Tuotteiden', function* (result, searchTerm) {
  const products = yield handleProductApiResponse(productActions.fetchSuccess)(result)
  yield put(productActions.searchSuccess(products, searchTerm))
  return products
})
const productDeleteFlow = deleteFlow({
  deleteApi: deleteProduct,
  actions: productActions,
  singular: 'Tuote',
  errorMsg: 'Tuotteen',
  base: 'products'
})

function* productImportFlow({ files }) {
  try {
    addSuccessNotification('Tiedosto(je)n käsittely aloitettu')
    yield call(importProductsFromFile, files)
    addSuccessNotification('Tuotteiden tuonti aloitettu')
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe tuotteiden tuonnissa')
  }
}

const fileFlows = getSubEntitySagas(productActions, fileActions, fetchProductFiles, createProductFile, deleteProductFile, 'productId', 'fileId', 'products', 'files', {
  singular: 'Tuotteen tiedosto',
  accusative: 'Tuotteen tiedoston',
  fetchError: 'Virhe tuotteen tiedostojen noutamisessa',
  deleteSuccess: 'Tuotteen tiedostolinkki poistettu',
  deleteError: 'Virhe tuotteen tiedostolinkin poistamisessa'
})

const watchOnProductTaskSockets = createSocketWatcher('productTask', {
  created: productActions.productTasks.createSuccess,
  updated: productActions.productTasks.updateSuccess,
  deleted: productActions.productTasks.deleteSuccess
})

const productTasksFetchFlow = fetchFlow({
  fetchApi: fetchProductTasks,
  actions: productActions.productTasks,
  errorMsg: 'Työkulun'
})

const productTaskUpdateFlow = updateFlow(updateProductTask, productActions.productTasks, 'Työkulu', 'Työkulun')
const productTaskCreateFlow = createFlow(createProductTask, productActions.productTasks, 'Työkulu', 'Työkulun')
const productTaskDeleteFlow = deleteFlow({
  deleteApi: deleteProductTask,
  actions: productActions.productTasks,
  singular: 'Työkulu',
  errorMsg: 'Työkulun',
  base: 'products.productTasks'
})

const productStatsFetchFlow = fetchStatsFlow(productActions, fetchProductStats, 'productId')

const productWarehouseStatsFetchFlow = fetchFlow({
  fetchApi: fetchProductWarehouseStats,
  actions: productActions.productWarehouseStats,
  errorMsg: 'Varastosaldojen',
  apiResponseHandler: handleWarehouseStatsApiResponse(productActions.productWarehouseStats.fetchSuccess)
})

const createProductActionFlow = (apiAction, responseHandler) => function* ({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    const responseBody = record
    const urlExpand = data
    const response = yield call(apiAction, responseBody, urlExpand)
    let retVal
    if(responseHandler) {
      retVal = yield * responseHandler(response, data)
    } else {
      retVal = yield put(productActions.fetchSuccess(response.record))
    }
    addSuccessNotification('Toiminto onnistui')
    yield call(resolve, retVal)
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Toiminto epäonnistui', reject)
  }
}

const productPatchActionFlow = createProductActionFlow(doProductPatchAction)
const productPostActionFlow = createProductActionFlow(doProductPostAction, function* (response, data) {
  if(data.subItem === 'warehouses') {
    return
  }
  return yield put(productActions.fetchSuccess(response.record))
})

export default function* productSaga() {
  yield takeLatest(productActions.actionTypes.fetchRequest, productFetchFlow)
  yield takeEvery(productActions.actionTypes.updateRequest, productUpdateFlow)
  yield takeEvery(productActions.actionTypes.createRequest, productCreateFlow)
  yield takeLatest(productActions.actionTypes.searchRequest, productSearchFlow)
  yield takeEvery(productActions.actionTypes.deleteRequest, productDeleteFlow)

  yield takeLatest(actionTypes.PRODUCTS_IMPORT_REQUEST, productImportFlow)

  yield takeLatest(productActions.files.actionTypes.fetchRequest, fileFlows.subFetchFlow)
  yield takeEvery(productActions.files.actionTypes.createRequest, fileFlows.subCreateFlow)
  yield takeLatest(productActions.files.actionTypes.deleteRequest, fileFlows.subDeleteFlow)

  yield takeLatest(productActions.productTasks.actionTypes.fetchRequest, productTasksFetchFlow)
  yield takeEvery(productActions.productTasks.actionTypes.updateRequest, productTaskUpdateFlow)
  yield takeEvery(productActions.productTasks.actionTypes.createRequest, productTaskCreateFlow)
  yield takeLatest(productActions.productTasks.actionTypes.deleteRequest, productTaskDeleteFlow)

  yield takeLatest(productActions.actionTypes.fetchStatsRequest, productStatsFetchFlow)
  yield takeEvery(actionTypes.PRODUCT_PATCH_ACTION_REQUEST, productPatchActionFlow)
  yield takeEvery(actionTypes.PRODUCT_POST_ACTION_REQUEST, productPostActionFlow)

  yield takeEvery(productActions.productWarehouseStats.actionTypes.fetchRequest, productWarehouseStatsFetchFlow)

  yield all([
    watchOnSockets(),
    watchOnProductTaskSockets()
  ])
}
