import constants from '../constants'
import { getCombinedListReducerFor } from '../helpers/reducerHelpers'

export default getCombinedListReducerFor(constants.actionKeys.EMPLOYEES, {
  levels: getCombinedListReducerFor(constants.actionKeys.EMPLOYEE_LEVELS),
  invitations: getCombinedListReducerFor(constants.actionKeys.EMPLOYEE_INVITATIONS),
  employeeLicences: getCombinedListReducerFor(constants.actionKeys.EMPLOYEE_LICENCES),
  employeeSubcontractors: getCombinedListReducerFor(constants.actionKeys.EMPLOYEE_SUBCONTRACTORS),
  files: getCombinedListReducerFor(constants.actionKeys.EMPLOYEE_FILES),
  systemMessages: getCombinedListReducerFor(constants.actionKeys.EMPLOYEE_SYSTEM_MESSAGES),
  memos: getCombinedListReducerFor(constants.actionKeys.EMPLOYEE_MEMOS),
  extras: getCombinedListReducerFor(constants.actionKeys.EMPLOYEE_EXTRAS)
})
