import { accessLevels } from '../constants'

export const getEmployeeFullText = (employeeLevels, currentEmployee) => employee => {
  const baseKey = employee.firstName + ' ' + employee.lastName
  if(!employeeLevels || !currentEmployee) {
    return baseKey
  }

  const employeeLevelObject = employeeLevels[employee.employeeLevelId]
  const currentEmployeeAccessLevel = employeeLevels[currentEmployee.employeeLevelId]?.accessLevel
  // Show accessleves only for supervisors and higher
  if(employeeLevelObject?.name && currentEmployeeAccessLevel >= accessLevels.SUPERVISOR) {
    return `${baseKey} ${employeeLevelObject.name}`
  }
  return baseKey
}

export const renderEmployeeFullText = (employeeLevels, currentEmployee) => {
  const currentEmployeeAccessLevel = employeeLevels[currentEmployee?.employeeLevelId]?.accessLevel
  if(currentEmployeeAccessLevel < accessLevels.SUPERVISOR) {
    return undefined
  }

  return (employee, _, index) => {
    const employeeLevelObject = employeeLevels[employee.employeeLevelId]
    const baseKey = employee.firstName + ' ' + employee.lastName
    return (
      <div>
        <span data-testid={`responsibleEmployee-name-${index}`}>{baseKey}</span>
        <span className='ms-1'>
          <small>
            {(employeeLevelObject?.name || '')}
          </small>
        </span>
      </div>
    )
  }
}
