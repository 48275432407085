import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { CompatRouter } from 'react-router-dom-v5-compat'
import { Flip, ToastContainer } from 'react-toastify'

import MainErrorBoundary from '../components/Authentication/MainErrorBoundary'
import Helmet from '../components/misc/Helmet'
import ActionPromptContainer from '../components/Modals/ActionPromptContainer'
import Main from './Main/Main'

const App = ({ store, history }) => {
  const renderMainContent = useCallback(() => (
    <>
      <Helmet />
      <Main />
    </>
  ), [])
  return (
    <Provider store={store}>
      <Router history={history}>
        <CompatRouter>
          <HelmetProvider>
            <MainErrorBoundary renderMainContent={renderMainContent} />
            <ActionPromptContainer />
            <ToastContainer theme='colored' transition={Flip} closeOnClick />
          </HelmetProvider>
        </CompatRouter>
      </Router>
    </Provider>
  )
}

App.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default App
