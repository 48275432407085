import { Component } from 'react'
import { connect } from 'react-redux'

import customerActions from '../../actions/customerActions'
import { renderPostalSearchItemInline } from '../../components/Helpers/searchResultRenderers'
import { getCustomersFromSearchResult } from '../../selectors/customerSelectors'
import SearchResults, { searchResultsPropType } from './SearchResults'

class CustomerResults extends Component {
  renderCustomer = item => renderPostalSearchItemInline(item, 'customerNumber')
  render() {
    return (
      <SearchResults
        title='Asiakkaat'
        renderResult={this.renderCustomer}
        {...this.props}
      />
    )
  }

  static propTypes = searchResultsPropType
}

const mapStateToProps = state => {
  return {
    results: getCustomersFromSearchResult(state),
    isBusy: state.customers.search.isBusy
  }
}

const mapDispatchToProps = dispatch => {
  return {
    navigateTo: customerId => dispatch(customerActions.navigateTo(customerId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerResults)
