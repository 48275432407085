import PropTypes from 'prop-types'
import noop from 'lodash/noop'

export const commonFormInputPropTypes = {
  field: PropTypes.string,
  type: PropTypes.string,
  usePlaceholder: PropTypes.bool,
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  step: PropTypes.string,
  rows: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  model: PropTypes.object,
  validationErrors: PropTypes.object,
  setNull: PropTypes.bool,
  disabled: PropTypes.bool,
  disableAll: PropTypes.bool,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  setWithCustomizer: PropTypes.func,
  onEnterPressed: PropTypes.func,
  onEscPressed: PropTypes.func,
  onKeyDown: PropTypes.func,
  autoFocus: PropTypes.bool,
  isRequired: PropTypes.bool,
  onBlur: PropTypes.func,
  testId: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg']),
  label: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node
  ])
}

export const commonFormInputDefaultProps = {
  model: {},
  validationErrors: {},
  type: 'text',
  usePlaceholder: false,
  disabled: false,
  disableAll: false,
  setNull: false,
  setWithCustomizer: Object,
  onEnterPressed: noop,
  onEscPressed: noop
}

export const commonFormGroupPropTypes = {
  ...commonFormInputPropTypes,
  helpText: PropTypes.node,
  className: PropTypes.string,
  isHidden: PropTypes.bool,
  proxyOnChange: PropTypes.bool,
  Component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func
  ]).isRequired,
  labelTooltip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.node
  ])
}
