import { createSelector } from 'reselect'

import { inboundMessageStates } from '../constants'
import { getFindItemByIdSelector } from '../helpers/selectorHelpers'

export const getInboundMessagesFromArgument = arg => arg.inboundMessages ? arg.inboundMessages.records : arg

export const findInboundMessageWithId = getFindItemByIdSelector(getInboundMessagesFromArgument)

export const getInboundMessageOptions = createSelector(
  getInboundMessagesFromArgument,
  inboundMessages => inboundMessages
)

export const getUnreadInboundMessages = createSelector(
  getInboundMessagesFromArgument,
  messages => messages.filter(message => message.inboundMessageState === inboundMessageStates.NEW)
)
