import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Alert } from 'reactstrap'
import { appendEitherOrEmpty } from '@evelia/helpers/helpers'

import WorkStatusBadge from '../../containers/WorkPage/WorkStatusBadge'
import { workPropType } from '../../propTypes'
import { getWorkViewStatuses } from '../../selectors/workExtraSelectors'
import WorkCustomerDetails from '../Choosers/WorkChooser/WorkCustomerDetails'
import SimpleDetails from './SimpleDetails'

const getWorkDetails = (work, customerIsSalesProhibited, showCustomerSalesProhibition, showCustomerDetails) => {
  return (
    <>
      <WorkStatusBadge work={work} />
      {appendEitherOrEmpty(work.workNumber, work.name, ': ')}
      {customerIsSalesProhibited && showCustomerSalesProhibition ? <Alert color='danger'> Työn asiakas on myyntikiellossa</Alert> : null}
      {showCustomerDetails
        ? (
          <WorkCustomerDetails customerId={work.customerId} />
          )
        : null}
    </>
  )
}
const WorkDetails = React.memo(({ title, work = {}, showCustomerSalesProhibition, showCustomerDetails }) => {
  const { customerIsSalesProhibited } = useSelector(state => getWorkViewStatuses(state, work.id))
  return (
    <SimpleDetails
      title={title}
      text={getWorkDetails(work, customerIsSalesProhibited, showCustomerSalesProhibition, showCustomerDetails)}
    />
  )
})

WorkDetails.propTypes = {
  title: PropTypes.string,
  work: workPropType,
  showCustomerSalesProhibition: PropTypes.bool,
  showCustomerDetails: PropTypes.bool
}

export default WorkDetails
