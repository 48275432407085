import { useCallback, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead'
import { castToArray } from '@evelia/helpers/helpers'
import isFunction from 'lodash/isFunction'

import { keyCodes } from '../../../constants'
import renderTypeaheadMenu from './renderTypeaheadMenu'

export const selectionHandler = (selected, { labelKey, handleNew, handleOnChange }, noReturn) => {
  const singleSelected = Array.isArray(selected) ? selected[0] : selected
  if(singleSelected && singleSelected.customOption && handleNew) {
    const key = isFunction(labelKey) ? 'label' : labelKey
    return handleNew(singleSelected[key])
  }
  if(selected) {
    const data = handleOnChange(selected)
    if(!noReturn) {
      return data
    }
  }
}

const defaultOnChange = value => value
const defaultGetSelected = value => castToArray(value)

const defaultProps = {
  emptyLabel: 'Ei tuloksia',
  promptText: 'Aloita haku kirjoittamalla',
  searchText: 'Etsitään...',
  newSelectionPrefix: 'Lisää: ',
  renderMenu: renderTypeaheadMenu,
  delay: 500,
  positionFixed: true
}

const TypeaheadFormInput = ({
  defaultValue,
  setNull,
  handleOnChange = defaultOnChange,
  onChange,
  getSelected = defaultGetSelected,
  value,
  async,
  field,
  setRef,
  handleInputChange,
  disabled,
  disableAll,
  selectWithEnter,
  inputProps,
  options,
  useCache = false,
  paginate = true,
  multiple,
  labelKey,
  handleNew,
  ...restProps
}) => {
  const typeaheadProps = useMemo(() => ({
    ...defaultProps,
    ...restProps,
    labelKey
  }), [labelKey, restProps])

  const typeaheadRef = useRef()
  const clickAway = useCallback(() => {
    typeaheadRef?.current?.hideMenu()
  }, [])

  useEffect(() => {
    if(setRef) {
      setRef.current = typeaheadRef.current
    }
  }, [setRef])

  const shouldSelectHint = useCallback((shouldSelect, e) =>
    shouldSelect || (selectWithEnter && e.keyCode === keyCodes.ENTER), [selectWithEnter])

  const onChangeHandler = useCallback(selection => {
    const handledSelection = selectionHandler(selection, { labelKey, handleNew, handleOnChange })
    return handledSelection?.then ? handledSelection.then(data => onChange(data)) : onChange(handledSelection)
  }, [onChange, labelKey, handleNew, handleOnChange])

  const TypeaheadComponent = async ? AsyncTypeahead : Typeahead
  return (
    <TypeaheadComponent
      id={field}
      onBlur={clickAway}
      {...typeaheadProps}
      labelKey={labelKey}
      clearButton={setNull} // NOTE: broken somewhere between v5.1.2 - v5.2.0 https://github.com/ericgio/react-bootstrap-typeahead/pull/666
      onChange={onChangeHandler}
      onInputChange={handleInputChange}
      defaultSelected={defaultValue}
      ref={typeaheadRef}
      positionFixed
      disabled={disabled || !!disableAll}
      useCache={useCache}
      paginate={paginate}
      paginationText='Lisää tuloksia'
      selected={getSelected(value)}
      inputProps={inputProps}
      selectHint={shouldSelectHint}
      options={options}
      multiple={multiple}
    />
  )
}

TypeaheadFormInput.propTypes = {
  getSelected: PropTypes.func,
  handleOnChange: PropTypes.func,
  field: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array
  ]),

  // See all possible props: https://github.com/ericgio/react-bootstrap-typeahead/blob/master/docs/Props.md
  labelKey: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string
  ]).isRequired,
  options: PropTypes.array.isRequired,
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  align: PropTypes.oneOf(['justify', 'left', 'right']),
  handleInputChange: PropTypes.func,
  handleNew: PropTypes.func,
  onSearch: PropTypes.func,
  defaultValue: PropTypes.array,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  disableAll: PropTypes.bool,
  setNull: PropTypes.bool,
  setRef: PropTypes.shape({ current: PropTypes.any }),
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  menuClassName: PropTypes.string,
  async: PropTypes.bool,
  selectWithEnter: PropTypes.bool,
  inputProps: PropTypes.object,
  useCache: PropTypes.bool,
  paginate: PropTypes.bool,
  multiple: PropTypes.bool
}

export default TypeaheadFormInput
