export const PATH_FRONTPAGE = '/'
export const PATH_WORK = '/work'
export const PATH_TARGETS = '/targets'
export const PATH_CUSTOMERS = '/customers'
export const PATH_PRODUCTS = '/products'
export const PATH_PROJECTS = '/projects'
export const PATH_PROJECTS_REPORT = PATH_PROJECTS + '/report'
export const PATH_CONTACTS = '/contacts'
export const PATH_WORK_RECORDS = '/work_records'
export const PATH_INVOICES = '/invoices'
export const PATH_INBOUND_INVOICES = '/inbound_invoices'
export const PATH_RECEIVERS = '/receivers'
export const PATH_RECEIPTS = '/receipts'
export const PATH_CASH_RECEIPTS = '/cash_receipts'
export const PATH_CALENDAR = '/calendar'
export const PATH_OFFERS = '/offers'
export const PATH_OFFERS_REPORT = PATH_OFFERS + '/report'
export const PATH_SUPPLY_OFFERS = '/supply_offers'
export const PATH_FILES = '/files'
export const PATH_REPORTS = '/reports'
export const PATH_FINANCE = '/finance'
export const PATH_SETTINGS = '/settings'
export const PATH_SETTINGSWIZARD = '/settingswizard'
export const PATH_SYSTEM_MESSAGES = '/system_messages'
export const PATH_SALARIES = '/salaries'
export const PATH_CREDITS = '/credits'
export const PATH_REGISTER = '/register'
export const PATH_INVITATIONS = '/invitations'
export const PATH_USER_ACCOUNT = '/user_account'
export const PATH_TICKETS = '/tickets'
export const PATH_MACHINE_RECORDS = PATH_WORK_RECORDS + '/machine_records'
export const PATH_TIME_RECORDS = '/time_records'
export const PATH_INBOUND_MESSAGES = '/inbound_messages'
export const PATH_CREDIT_ACTIONS = '/credit_actions'
export const PATH_FORM_TEMPLATES = '/form_templates'
export const PATH_PURCHASE_ORDERS = '/purchase_orders'

export const PATH_SETTINGS_EMPLOYEES = PATH_SETTINGS + '/employees'
export const PATH_SETTINGS_SYSTEM_CUSTOMER = PATH_SETTINGS + '/system_customer'
export const PATH_SETTINGS_TIME_RECORDS = PATH_SETTINGS + '/time_records'
export const PATH_SETTINGS_WAGE_EARNERS = PATH_SETTINGS + '/wage_earners'

export const PATH_PRODUCTS_QUICK_PRODUCTS = PATH_PRODUCTS + '/quickproducts'
export const PATH_PRODUCTS_WORK_RECORD_PRODUCTS = PATH_PRODUCTS + '/workrecordproducts'
export const PATH_PRODUCTS_WAREHOUSES = PATH_PRODUCTS + '/warehouses'
export const PATH_PRODUCTS_PACKETS = PATH_PRODUCTS + '/packets'
export const PATH_PRODUCTS_MACHINES = PATH_PRODUCTS + '/machines'

export const PATH_WORK_RECORDS_TIME_CARD = PATH_WORK_RECORDS + '/time_card'
export const PATH_WORK_RECORDS_EMPLOYEE_RECORDS = PATH_WORK_RECORDS + '/employee_records'

export const PATH_WORK_COST_PROVISIONS = PATH_WORK + '/cost_provision'

export const WORK_TAB_HASH_KEYS = {
  work: 'work',
  personal: 'personal',
  project: 'project',
  templates: 'templates',
  search: 'search',
  workExtraInfo: 'workExtraInfo',
  projectWorkExtraInfo: 'projectWorkExtraInfo'
}

export const PATH_WORK_SEARCH_RESULTS = PATH_WORK + '#' + WORK_TAB_HASH_KEYS.search
export const PATH_WORK_TEMPLATES = PATH_WORK + '#' + WORK_TAB_HASH_KEYS.templates

export const PATH_WORK_QUICK_FILTERS = PATH_WORK + '/work_quick_filters'

export const PATH_RECURRING_WORK = PATH_WORK + '/recurring_work'
export const PATH_WORK_KANBAN = PATH_WORK + '/kanban'
export const PATH_SUPPLY_OFFERS_KANBAN = PATH_SUPPLY_OFFERS + '/kanban'
export const PATH_TICKETS_KANBAN = PATH_TICKETS + '/kanban'

export const PATH_RECURRING_TICKETS = PATH_TICKETS + '/recurring_tickets'
export const PATH_TICKET_TEMPLATE_LISTS = PATH_TICKETS + '/ticket_template_list'

export const PATH_RECURRING_INVOICES = PATH_INVOICES + '/recurring_invoices'
