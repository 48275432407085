import CommonValueFormGroup from './CommonValueFormGroup'
import TimeFormInput from './inputs/TimeFormInput'
import { commonFormInputDefaultProps, commonFormInputPropTypes } from './propTypes'

const TimeFormGroup = props => (
  <CommonValueFormGroup
    {...commonFormInputDefaultProps}
    {...props}
    setNull
    forceFeedback
    Component={TimeFormInput}
  />
)
TimeFormGroup.propTypes = commonFormInputPropTypes

export default TimeFormGroup
