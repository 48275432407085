import { clsx } from 'clsx'

interface ColorBadgeProps extends React.HTMLAttributes<HTMLSpanElement> {
  color: string
  className?: string
}

const ColorBadge = ({ color, className, ...rest }: ColorBadgeProps) => {
  return (
    <span className={clsx('p-1 d-inline-block border', rest.onClick && 'cursor-pointer', className)} {...rest}>
      {color?.startsWith('#')
        ? (
          <div
            className={clsx('color-box-badge', !color && 'no-color')}
            style={{ background: color }}
            data-testid='color-badge'
          />
          )
        : (
          <div
            className={clsx('color-box-badge', color ? `bg-${color}` : 'no-color')}
            data-testid='color-badge'
          />
          )}

    </span>
  )
}

export default ColorBadge
