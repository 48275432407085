import offerActions from '../actions/offerActions'
import constants from '../constants'
import {
  getCombinedListReducerFor,
  getCombinedSingleRecordReducerFor,
  getExtraInfoReducer,
  getTableOptions
} from '../helpers/reducerHelpers'
import tableReducerFor from './tableReducer'

export const initialOfferTableOptions = {
  default: getTableOptions(),
  extraInfo: getTableOptions()
}

export default getCombinedListReducerFor(constants.actionKeys.OFFERS, {
  files: getCombinedListReducerFor(constants.actionKeys.OFFER_FILES),
  memos: getCombinedListReducerFor(constants.actionKeys.OFFER_MEMOS),
  posts: getCombinedListReducerFor(constants.actionKeys.OFFER_POSTS),
  postRows: getCombinedListReducerFor(constants.actionKeys.OFFER_POST_ROWS),
  offerDefaults: getCombinedSingleRecordReducerFor(constants.actionKeys.OFFER_DEFAULTS),
  tableOptions: tableReducerFor(constants.actionKeys.OFFERS, initialOfferTableOptions),
  extraInfo: getExtraInfoReducer(offerActions, 'offerId')
})
