import { useEffect, useState } from 'react'

import DebouncedInput from '../misc/DebouncedInput'

type Props = {
  page: number
  pageCount: number
  setPage: (page: number) => void
}

const PaginatorInput = ({ page, pageCount, setPage }: Props) => {
  const [value, setValue] = useState('')

  useEffect(() => {
    const newPage = Number.parseInt(value)
    if(Number.isFinite(newPage) && newPage <= pageCount && newPage >= 1) {
      setPage(newPage - 1)
    } else {
      setValue('')
    }
  }, [pageCount, setPage, value])

  useEffect(() => {
    setValue('')
  }, [page])

  return (
    <DebouncedInput
      name='value'
      value={value}
      onChange={setValue}
    />
  )
}

export default PaginatorInput
