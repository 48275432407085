import { sortById } from '@evelia/helpers/helpers'
import { createSelector } from 'reselect'

import { workStateMap, workStates } from '../constants'
import { mapRecordNameOptions } from '../helpers/helpers'
import { getFindItemByIdSelector, getFindItemsByIdsSelector } from '../helpers/selectorHelpers'
import { findSystemCustomerSettings } from './systemCustomerSelectors'

export const getWorkCustomStatesFromArgument = arg => arg.workCustomStates ? arg.workCustomStates.records : arg

export const findWorkCustomStateWithId = getFindItemByIdSelector(getWorkCustomStatesFromArgument)
export const findWorkCustomStateWithIds = getFindItemsByIdsSelector(getWorkCustomStatesFromArgument)

export const getWorkCustomStates = createSelector(
  getWorkCustomStatesFromArgument,
  workCustomStates => workCustomStates.sort(sortById)
)

export const getUserSelectableWorkCustomStates = createSelector(
  getWorkCustomStates,
  findSystemCustomerSettings,
  (workCustomStates, { settings }) => {
    return workCustomStates
      .filter(workCustomState =>
      // allow users to hide default states except initial started state
        !(settings.disableWorkCustomSystemStates && workCustomState.isSystem && workCustomState.state === workStates.WORK_STATE_STARTED) &&
      workCustomState.state <= workStates.WORK_STATE_STARTED
      )
  }
)

export const getWorkCustomStateSelectOptions = createSelector(
  getWorkCustomStates,
  workCustomStates => mapRecordNameOptions(workCustomStates)
)

export const getProjectWorkWorkCustomStateSelectOptions = createSelector(
  getWorkCustomStates,
  workCustomStates => mapRecordNameOptions(workCustomStates.filter(option => option.id === 2 || option.id === 8))
)

export const getWorkCustomStateSelectMap = createSelector(
  getWorkCustomStates,
  workCustomStates => workCustomStates.reduce((acc, workCustomState) => ({
    ...acc,
    [workCustomState.id]: {
      value: workCustomState.id,
      text: workCustomState.name,
      color: workCustomState.color || workStateMap[workCustomState.state].color
    }
  }), {})
)

export const getDefaultWorkCustomStateIds = createSelector(
  getWorkCustomStates,
  findSystemCustomerSettings,
  (workCustomStates, { settings }) => {
    return workCustomStates.filter(workCustomState => workCustomState.state !== workStates.WORK_STATE_CLOSED &&
      !(settings.disableWorkCustomSystemStates && workCustomState.isSystem && workCustomState.state === workStates.WORK_STATE_STARTED))
      .map(({ id }) => id)
  })
