import PropTypes from 'prop-types'
import { faAngleRight, faExchangeAlt } from '@fortawesome/free-solid-svg-icons'

import { vatTypePropType } from '../../propTypes'
import { getVatTypeTitle } from '../../selectors/vatSelectors'
import IconButton from '../Buttons/IconButton'

const SwitchVatPromptBody = ({
  hideActionPrompt,
  switchVatType,
  visible,
  customerVatType,
  vatType,
  type
}) => {
  const text = type === 'work' ? 'työlle' : 'tarjoukselle'
  const text2 = type === 'work' ? 'Työllä' : 'Tarjouksella'

  const customerVatTypeTitle = getVatTypeTitle(customerVatType)
  const vatTypeTitle = getVatTypeTitle(vatType)
  const body = (
    <>
      Asiakkaalle on oletuksena käytössä <b>{customerVatTypeTitle}</b><br />
      {text2} on käytössä <b>{vatTypeTitle}</b>
    </>
  )

  const buttonOk = customerVatType
    ? `Vaihda ${text} ${customerVatTypeTitle}`
    : `Vaihda ${text} normaali verokanta`
  const buttonCancel = `Pidä ${text2.toLowerCase()} ${vatTypeTitle}`

  return (
    <>
      {body}
      <div className='modal-body-buttons'>
        <IconButton
          icon={faExchangeAlt}
          condensed
          color='success'
          onClick={() => switchVatType(customerVatType)}
          testId='switchVatTypeButton'
        >
          {buttonOk}
        </IconButton>
        <IconButton
          icon={faAngleRight}
          condensed
          outline
          color='danger'
          onClick={hideActionPrompt}
          testId='cancelSwitchVatTypeButton'
        >
          {buttonCancel}
        </IconButton>
      </div>
    </>
  )
}

SwitchVatPromptBody.propTypes = {
  customerVatType: vatTypePropType,
  vatType: vatTypePropType,
  hideActionPrompt: PropTypes.func.isRequired,
  switchVatType: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  type: PropTypes.string
}

export default SwitchVatPromptBody
