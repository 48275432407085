import PropTypes from 'prop-types'
import { Alert, Col, Row } from 'reactstrap'

const Loading = ({ loading, children, render, testId }) => {
  if(loading) {
    return (
      <Row data-testid={testId}>
        <Col sm='12'>
          <Alert color='primary'>Ladataan</Alert>
        </Col>
      </Row>
    )
  } else if(render) {
    return render()
  } else {
    return children
  }
}

Loading.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node,
  render: PropTypes.func,
  testId: PropTypes.string
}

export default Loading
