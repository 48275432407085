import CommonValueFormGroup from './CommonValueFormGroup'
import SelectFormInput from './inputs/SelectFormInput'
import { commonFormInputDefaultProps, commonFormInputPropTypes } from './propTypes'

const SelectFormGroup = props => (
  <CommonValueFormGroup {...commonFormInputDefaultProps} {...props} setNull Component={SelectFormInput} />
)
SelectFormGroup.propTypes = commonFormInputPropTypes

export default SelectFormGroup
