import PropTypes from 'prop-types'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import CancelButton from '../Buttons/CancelButton'

const SelectModal = ({
  headerTitle,
  isOpen,
  onOpened,
  closeModal,
  bodyComponent,
  footerComponent,
  size,
  height,
  testId
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onOpened={onOpened}
      toggle={closeModal}
      size={size}
      data-testid={testId}
    >
      <ModalHeader className='modal-header' toggle={closeModal}> <span className='modal-title'>{headerTitle}</span></ModalHeader>
      <ModalBody style={{ height }}>
        {bodyComponent}
      </ModalBody>
      <ModalFooter>
        {!footerComponent ? <CancelButton onClick={closeModal}>Peruuta</CancelButton> : footerComponent}
      </ModalFooter>
    </Modal>
  )
}

SelectModal.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  bodyComponent: PropTypes.element.isRequired,
  footerComponent: PropTypes.node,
  size: PropTypes.string,
  onOpened: PropTypes.func,
  height: PropTypes.string,
  testId: PropTypes.string
}

export default SelectModal
