import { createRef, PureComponent } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import isObject from 'lodash/isObject'

import { keyCodes } from '../../constants'
import CommonValueFormGroup, { handleOnChange } from './CommonValueFormGroup'
import Typeahead from './inputs/TypeaheadFormInput'
import { getRelevantValidationMessage } from './TypeaheadFormGroup'

class TagFormGroup extends PureComponent {
  typeahead = createRef()
  // KeyListenerTagInput = withKeyListener(Typeahead, , [keyCodes.ENTER, keyCodes.ESC])

  render() {
    const validationErrors = getRelevantValidationMessage(this.props)

    const inputProps = {
      'data-testid': this.props.field
    }
    return (
      <CommonValueFormGroup
        {...this.props}
        validationErrors={validationErrors}
        forceFeedback
        setRef={this.typeahead}
        multiple
        selectWithEnter
        handleOnChange={this.handleSelect}
        handleInputChange={this.handleCommaOnInput}
        onBlur={this.onBlur}
        onKeyDown={this.onKeyDown}
        defaultValue={[]}
        Component={Typeahead}
        inputProps={inputProps}
      />
    )
  }

  onKeyDown = event => {
    if(![keyCodes.ENTER, keyCodes.ESC].some(key => event.keyCode === key)) {
      return
    }

    let value = this.typeahead.current.getInput().value
    if(!value) {
      return
    } else if(event.keyCode === keyCodes.ESC) {
      value = null // clear input
    }
    this.addNewEntry(value)
  }

  onBlur = () => {
    const value = this.typeahead.current.getInput().value
    if(value) {
      this.addNewEntry(value)
    }
  }

  handleSelect = selected => {
    const { handleSelect } = this.props
    return handleSelect ? handleSelect(selected) : selected.map(item => isObject(item) ? item.tag : item)
  }

  handleOnChange = handleOnChange(this.props)

  handleCommaOnInput = value => {
    if(value.length && value.includes(',')) {
      const cleanedValue = value.replace(/,/g, '')
      this.addNewEntry(cleanedValue)
    }
  }

  addNewEntry = value => {
    const cleanedValue = value && value.trim()
    const { model, field } = this.props
    const currentValue = get(model, field) || []
    const result = new Set(cleanedValue ? [...currentValue, cleanedValue] : currentValue)
    this.typeahead.current.clear()
    setTimeout(() => this.handleOnChange(this.handleSelect([...result])), 0)
  }
}

TagFormGroup.propTypes = {
  field: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  model: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  handleSelect: PropTypes.func,
  options: PropTypes.array,
  newSelectionPrefix: PropTypes.string.isRequired,
  labelKey: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.func.isRequired
  ]),
  setWithCustomizer: PropTypes.func.isRequired,
  validationErrors: PropTypes.object,
  emptyLabel: PropTypes.string,
  allowNew: PropTypes.bool,
  isRequired: PropTypes.bool,
  renderMenuItemChildren: PropTypes.func,
  disableAll: PropTypes.bool
}

TagFormGroup.defaultProps = {
  options: [],
  setWithCustomizer: Object,
  newSelectionPrefix: 'Lisää tunniste: ',
  labelKey: 'tag',
  allowNew: true
}

export default TagFormGroup
