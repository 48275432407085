import constants from '../constants'
import { actionCreatorsFor } from '../helpers/actionHelpers'

const baseActionCreators = actionCreatorsFor(constants.actionKeys.TICKET_RECURRENCE_RULES)

const additionalActionCreators = {}

const ticketRecurrenceRuleActions = { ...baseActionCreators, ...additionalActionCreators }

export default ticketRecurrenceRuleActions
