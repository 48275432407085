import PropTypes from 'prop-types'

import supplyOfferActions from '../../actions/supplyOfferActions'
import { supplyOfferPatchActions, vatTypes } from '../../constants'
import { usePromisifyActionCallback } from '../../hooks/useActions'
import { vatTypePropType } from '../../propTypes'
import ActionPromptModal from './ActionPromptModal'
import SwitchVatPromptBody from './SwitchVatPromptBody'

const SwitchSupplyOfferVatPrompt = ({ visible, data, hideActionPrompt }) => {
  const switchVatTypeAction = usePromisifyActionCallback(supplyOfferActions.doPatchAction)

  const switchVatType = async vatType => {
    // Posting original vatType resets it
    const newVatType = vatType || data.vatType
    const action = newVatType === vatTypes.reversedVat
      ? supplyOfferPatchActions.MARK_REVERSED_VAT
      : newVatType === vatTypes.euSales
        ? supplyOfferPatchActions.MARK_EU_SALES
        : null
    if(action) {
      await switchVatTypeAction(null, { supplyOfferId: data.supplyOfferId, action })
    }
    hideActionPrompt()
  }

  return (
    <ActionPromptModal
      visible={visible}
      renderBody={(
        <SwitchVatPromptBody
          hideActionPrompt={hideActionPrompt}
          switchVatType={switchVatType}
          customerVatType={data.customerVatType}
          vatType={data.vatType}
          type='supplyOffer'
        />
      )}
    />
  )
}

SwitchSupplyOfferVatPrompt.propTypes = {
  visible: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    supplyOfferId: PropTypes.number.isRequired,
    customerVatType: vatTypePropType,
    vatType: vatTypePropType
  }),
  hideActionPrompt: PropTypes.func.isRequired
}

export default SwitchSupplyOfferVatPrompt
