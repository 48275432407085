import PropTypes from 'prop-types'
import { Badge } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { timeRecordTypePropType } from '../../propTypes'
import { getTimeRecordTypeIcon } from './timeRecordTypeHelpers'

const TimeRecordTypeBadge = ({ timeRecordType, showName }) => {
  if(!timeRecordType) {
    return null
  }
  return <Badge pill color={timeRecordType.color} title={timeRecordType.name}><FontAwesomeIcon icon={getTimeRecordTypeIcon(timeRecordType)} /> {showName ? timeRecordType.name : null}</Badge>
}

TimeRecordTypeBadge.propTypes = {
  timeRecordType: timeRecordTypePropType,
  showName: PropTypes.bool
}

export default TimeRecordTypeBadge
