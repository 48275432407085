import PropTypes from 'prop-types'

import PostalDetails from './PostalDetails'

const CustomerDetails = ({ renderExtra, nameLink, showHorizontalDetails, ...customer }) => {
  return (
    <PostalDetails
      {...customer}
      phoneNumber={customer.phone}
      nameLink={nameLink}
      renderExtra={renderExtra}
      showHorizontalDetails={showHorizontalDetails}
    />
  )
}

CustomerDetails.propTypes = {
  renderExtra: PropTypes.func,
  nameLink: PropTypes.node,
  showHorizontalDetails: PropTypes.bool
}

export default CustomerDetails
