import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, InputGroup } from 'reactstrap'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import noop from 'lodash/noop'

import IconButton from '../Buttons/IconButton'

class SearchInput extends PureComponent {
  state = {
    input: ''
  }

  handleOnChange = event => {
    const { onChange } = this.props
    const value = event.target.value
    this.setState({
      input: value
    })
    onChange(event, value)
  }

  handleSubmit = event => {
    const { input } = this.state
    event.preventDefault()
    this.props.onSubmit(input)
  }

  onFocus = () => {
    if(this.state.input.length) {
      this.props.onFocus()
    }
  }

  onBlur = () => {
    setTimeout(() =>
      this.props.onBlur()
    , 300)
  }

  render() {
    const { placeholder } = this.props
    const { input } = this.state
    return (
      <Form onSubmit={this.handleSubmit}>
        <InputGroup>
          <Input
            placeholder={placeholder}
            type='search'
            name='search'
            id='search'
            value={input}
            onChange={this.handleOnChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            data-testid='searchInput'
          />
          <IconButton
            disabled={!input.length}
            type='submit'
            color='primary'
            className='post-search-btn'
            icon={faSearch}
            data-testid='postSearchButton'
            size='md'
          />
        </InputGroup>
      </Form>
    )
  }
}

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
}

SearchInput.defaultProps = {
  placeholder: 'Haku...',
  onChange: noop,
  onSubmit: noop,
  onFocus: noop,
  onBlur: noop
}

export default SearchInput
