import { call, put, takeLatest } from 'redux-saga/effects'

import paymentActions from '../actions/paymentActions'
import { createPayment } from '../api/paymentApi'
import { actionTypes } from '../constants'
import { genericSagaErrorHandler, getPromiseHandlersFromData } from '../helpers/sagaHelpers'

function* paymentFlow({ record, data = {} }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(paymentActions.createStart())
    const response = yield call(createPayment, record)
    if(response && response.redirectUrl) {
      window.location.replace(response.redirectUrl)
    }
    yield call(resolve, record)
  } catch(err) {
    yield put(paymentActions.createError())
    yield * genericSagaErrorHandler(err, 'Maksu epäonnistui', reject)
  }
}

export default function* paymentSaga() {
  yield takeLatest(actionTypes.PAYMENT_CREATE_REQUEST, paymentFlow)
}
