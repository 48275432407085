import { createApi, defaultEmbeddedNormalizer } from '../helpers/apiHelpers'
import { BaseIdModel } from './types/baseModelTypes'

export type InsuranceCompanyModel = {
  type: 0 | 1
  name: string
  businessId: string
  companyIdentifier: string
} & BaseIdModel

export const insuranceCompanyApi = createApi({
  base: 'insurance_companies',
  normalizer: defaultEmbeddedNormalizer
})
