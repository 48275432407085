import constants from '../constants'
import { actionCreatorsFor } from '../helpers/actionHelpers'

const baseActionCreators = actionCreatorsFor(constants.actionKeys.WHO_AM_I)

const actionCreators = {
}

const whoAmIActions = { ...baseActionCreators, ...actionCreators }
export default whoAmIActions
