import constants, { actionTypes } from '../constants'
import {
  actionCreatorsFor,
  error,
  errorRecords,
  request,
  requestRecords,
  start,
  startRecords,
  success,
  successRecords
} from '../helpers/actionHelpers'
import { PATH_SUPPLY_OFFERS } from '../routes'
import { navigateTo } from './navigateActions'
import tableActions from './tableActions'

export const getUrl = supplyOfferId => `${PATH_SUPPLY_OFFERS}/${supplyOfferId || 'new'}`

const baseActionCreators = actionCreatorsFor(constants.actionKeys.SUPPLY_OFFERS, { createSupplyOfferPreviews: true, summaryStatsFetch: true })
const baseSupplyOfferRowActionCreators = actionCreatorsFor(constants.actionKeys.SUPPLY_OFFER_ROWS, { updateRank: true })

const additionalActionCreators = {
  navigateTo: (supplyOfferId, replaceUrl, params) => navigateTo(getUrl(supplyOfferId, params), replaceUrl),
  navigateToBase: replaceUrl => navigateTo(PATH_SUPPLY_OFFERS, replaceUrl),
  memos: actionCreatorsFor(constants.actionKeys.SUPPLY_OFFER_MEMOS),
  systemMessages: actionCreatorsFor(constants.actionKeys.SUPPLY_OFFER_SYSTEM_MESSAGES),
  supplyOfferRows: {
    ...baseSupplyOfferRowActionCreators,
    doPostAction: request(actionTypes.SUPPLY_OFFER_ROW_POST_ACTION_REQUEST),
    doPutAction: request(actionTypes.SUPPLY_OFFER_ROW_PUT_ACTION_REQUEST),
    updateRankRequest: request(baseSupplyOfferRowActionCreators.actionTypes.updateRankRequest)
  },
  doPatchAction: request(actionTypes.SUPPLY_OFFER_PATCH_ACTION_REQUEST),
  tableActions: tableActions(constants.actionKeys.SUPPLY_OFFERS),
  createSupplyOfferPreviewsRequest: request(baseActionCreators.actionTypes.createSupplyOfferPreviewsRequest),
  createSupplyOfferPreviewsStart: start(baseActionCreators.actionTypes.createSupplyOfferPreviewsStart),
  createSupplyOfferPreviewsSuccess: success(baseActionCreators.actionTypes.createSupplyOfferPreviewsSuccess),
  createSupplyOfferPreviewsError: error(baseActionCreators.actionTypes.createSupplyOfferPreviewsError),
  files: actionCreatorsFor(constants.actionKeys.SUPPLY_OFFER_FILES),
  summaryStatsFetchRequest: requestRecords(baseActionCreators.actionTypes.summaryStatsFetchRequest),
  summaryStatsFetchStart: startRecords(baseActionCreators.actionTypes.summaryStatsFetchStart),
  summaryStatsFetchSuccess: successRecords(baseActionCreators.actionTypes.summaryStatsFetchSuccess),
  summaryStatsFetchError: errorRecords(baseActionCreators.actionTypes.summaryStatsFetchError),
  doSupplyOfferAction: request(actionTypes.SUPPLY_OFFER_ACTION_REQUEST),
  supplyOfferDefaults: actionCreatorsFor(constants.actionKeys.SUPPLY_OFFER_DEFAULTS)
}

const supplyOfferActions = { ...baseActionCreators, ...additionalActionCreators }
export default supplyOfferActions
