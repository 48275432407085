import { castToArray } from '@evelia/helpers/helpers'
import constant from 'lodash/constant'
import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'
import { parseTemplate } from 'url-template'

import 'whatwg-fetch'
import { memoTypes } from '../constants'
import { getSocket } from '../socket'
import { parseTableOptionsFromQuery } from './reducerHelpers'

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
const parseJSON = response => {
  if(response.status === 204 || response.status === 205) {
    return null
  }
  return response.json()
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
const checkStatus = response => {
  if(response.status >= 200 && response.status < 300) {
    return response
  }

  const error = new Error(response.statusText)
  if(response.status === 504) {
    error.response = response
    error.status = response.status
    throw error
  }
  return response.json()
    .then(({ data, ...json }) => {
      error.message = json.message ?? error.message
      error.response = response
      error.status = response.status
      error.data = data
      error.json = json
      error.validationErrors = parseValidationErrors(json.validationErrors)
      throw error
    })
}

export const parseValidationErrors = errors => (errors || []).reduce((acc, error) => {
  // nestedErrors are included when oneOf express-validator is used on server
  if(error.nestedErrors) {
    acc = { ...acc, ...parseValidationErrors(error.nestedErrors) }
    return acc
  }
  acc[error.param] = {
    ...error,
    message: error.msg
  }
  return acc
}, {})

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
const request = (url, options) => {
  return fetch(url, options)
    .then(checkStatus)
    .then(parseJSON)
}

/**
 *
 * @param {string} url
 * @param {object} options
 * @returns {Promise}
 */
export const get = (url, options) => {
  return request(url, {
    ...options,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache',
      'X-Socket-Id': getSocket().id,
      Accept: 'application/json'
    },
    credentials: 'same-origin'
  })
}

export const post = (url, options) => {
  return request(url, {
    ...options,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Socket-Id': getSocket().id,
      Accept: 'application/json',
      ...options?.headers
    },
    credentials: 'same-origin'
  })
}

export const put = (url, options) => {
  return request(url, {
    ...options,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Socket-Id': getSocket().id,
      Accept: 'application/json'
    },
    credentials: 'same-origin'
  })
}

export const del = (url, options) => {
  return request(url, {
    ...options,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-Socket-Id': getSocket().id,
      Accept: 'application/json'
    },
    credentials: 'same-origin'
  })
}

export const patch = (url, options) => {
  return request(url, {
    ...options,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'X-Socket-Id': getSocket().id,
      Accept: 'application/json'
    },
    credentials: 'same-origin'
  })
}

export const upload = (url, options) => {
  return request(url, {
    ...options,
    method: 'POST',
    headers: {
      'X-Socket-Id': getSocket().id,
      Accept: 'application/json'
    },
    credentials: 'same-origin'
  })
}

export const download = (url, options) => {
  return fetch(url, options)
    .then(checkStatus)
    .then(response => response.blob())
}

export const getNormalizers = (embeddedName, recordResponse = false) => {
  const normalizeRecordResponse = response => {
    const { _embedded, records, record } = response
    const {
      [embeddedName]: embeddedItems
    } = _embedded
    const mainData = castToArray(records || record)

    return [
      mainData,
      embeddedItems
    ]
  }
  const normalizeListResponse = items => {
    const subItems = new Map()
    items.forEach(item => {
      if(!item._embedded) {
        return
      }
      if(item._embedded[embeddedName]) {
        subItems.set(item._embedded[embeddedName].id, item._embedded[embeddedName])
        delete item._embedded[embeddedName]
      }
      if(Object.keys(item._embedded).length === 0) {
        delete item._embedded
      }
    })
    return [items, Array.from(subItems.values())]
  }
  const normalizeResponse = item => {
    const [[normalizedItem], subObjects] = normalizeListResponse([item])
    return [normalizedItem, subObjects]
  }
  return recordResponse ? { normalizeListResponse: normalizeRecordResponse, normalizeResponse: normalizeRecordResponse } : { normalizeListResponse, normalizeResponse }
}

export const getSubEntityApi = (urlTemplate, mainIdFieldName, subIdFieldName, relationIdFieldName, embeddedName, recordResponse = false, includeSubObjects = false) => {
  const { normalizeResponse, normalizeListResponse } = getNormalizers(embeddedName, recordResponse)

  const fetchApi = (mainId, params = {}) => {
    return get(urlTemplate.expand({ ...params, [mainIdFieldName]: mainId }))
      .then(normalizeListResponse)
  }

  const createApi = ({ [mainIdFieldName]: mainId, [subIdFieldName]: subId, ...restData }) => {
    return post(urlTemplate.expand({ [mainIdFieldName]: mainId }), {
      body: JSON.stringify({ ...restData, [subIdFieldName]: subId })
    }).then(normalizeResponse)
      .then(([relationObject, subObjects]) => includeSubObjects ? [relationObject, subObjects] : relationObject)
  }

  const updateApi = ({ [mainIdFieldName]: mainId, id: relationId, ...data }) => {
    return put(urlTemplate.expand({ [mainIdFieldName]: mainId, [relationIdFieldName]: relationId }), {
      body: JSON.stringify(data)
    }).then(normalizeResponse)
      .then(([relationObject, subObjects]) => relationObject)
  }

  const deleteApi = ({ [mainIdFieldName]: mainId, id: relationId }) => {
    return del(urlTemplate.expand({ [mainIdFieldName]: mainId, [relationIdFieldName]: relationId }))
  }
  return {
    fetchApi,
    createApi,
    updateApi,
    deleteApi,
    normalizeResponse,
    normalizeListResponse
  }
}

export const getSubApi = (urlTemplate, subIdFieldName, normalizer = data => data) => {
  const getUrl = params => isFunction(urlTemplate) ? urlTemplate(params) : urlTemplate.expand(params)
  const fetchApi = (params = {}) => {
    return get(getUrl({ ...params }))
      .then(normalizer)
  }

  const createApi = (data, params = {}) => {
    return post(getUrl({ ...params }), {
      body: JSON.stringify(data)
    }).then(normalizer)
  }

  const updateApi = (data, params = {}) =>
    put(getUrl({ ...params, [subIdFieldName]: data.id }), {
      body: JSON.stringify(data)
    }).then(normalizer)

  const deleteApi = (data, params = {}) => {
    return del(getUrl({ ...params, [subIdFieldName]: data.id }))
  }
  return {
    fetchApi,
    createApi,
    updateApi,
    deleteApi
  }
}

export const getMemoApi = base => {
  const editGetParams = ({ memoType, id }) => ({ id, subItem: 'memos', subItemType: memoTypes[memoType].urlPart })
  const editMutateParams = (params, memoData) => ({ id: params.id, subItem: 'memos', subItemId: memoData.id, subItemType: memoTypes[params.memoType].urlPart })

  const memoApi = createApi({ base, editGetParams, editMutateParams, normalizer: defaultEmbeddedNormalizer })
  return memoApi
}

export const defaultNormalizer = data => data.record || data.records || data
export const defaultEmbeddedNormalizer = response => {
  const { options, ...embedded } = response._embedded || {}
  const retVal = { ...embedded, data: defaultNormalizer(response) }
  if(options) {
    retVal.tableOptions = parseTableOptionsFromQuery(options)
  }
  return retVal
}

const addBodyPayload = data => ({ body: JSON.stringify(data) })

const getCommonUrlTemplate = (extraUrlTemplate = '') => `${import.meta.env.VITE_API_DOMAIN ?? ''}/api/{+base}{/id,subItem,subItemType,subItemId,subItemAction,action}${extraUrlTemplate}`

export const getSearchParams = search => {
  if(isString(search)) {
    return {
      q: search
    }
  }
  return {
    search: JSON.stringify(search)
  }
}

// TODO: This is only for typescript hinting...
const getFirst = (params, __model) => params

export const createApi = ({
  base,
  editGetParams = getFirst,
  editMutateParams = getFirst,
  editSearchParams = constant({}),
  extraUrlTemplate = '',
  normalizer = getFirst
}) => {
  const baseUrlTemplate = new Proxy(parseTemplate(getCommonUrlTemplate(extraUrlTemplate)), {
    get: function(obj, prop) {
      return prop === 'expand' ? params => obj.expand({ base, ...params }) : obj[prop]
    }
  })

  const fetch = (params = {}, options = {}) => get(baseUrlTemplate.expand(editGetParams(params))).then(options?.normalizer || normalizer)

  // TODO: search eroaa myöhemmistä (search, options vs. search, params, options)
  const search = (search, options) => get(baseUrlTemplate.expand({ ...getSearchParams(search), ...editSearchParams(options, search) })).then(options?.normalizer || normalizer)

  const create = (data, params = {}, options = {}) =>
    post(baseUrlTemplate.expand(editMutateParams(params, data)), addBodyPayload(data)).then(options?.normalizer || normalizer)

  const update = (data, params = {}, options = {}) =>
    put(baseUrlTemplate.expand(editMutateParams({ id: data.id, ...params }, data)), addBodyPayload(data)).then(options?.normalizer || normalizer)

  const remove = (data, params = {}) =>
    del(baseUrlTemplate.expand(editMutateParams({ id: data.id, ...params }, data)))

  const patchRequest = (data, params = {}, options = {}) =>
    patch(baseUrlTemplate.expand({ base, ...editMutateParams({ id: data.id, ...params }, data) }), addBodyPayload(data)).then(options?.normalizer || normalizer)

  return {
    fetch,
    create,
    update,
    remove,
    search,
    baseUrlTemplate,
    patch: patchRequest
  }
}
