import constants, { actionTypes } from '../constants'
import {
  actionCreatorsFor,
  error,
  request,
  start,
  success
} from '../helpers/actionHelpers'
import { PATH_PRODUCTS } from '../routes'
import { navigateTo } from './navigateActions'
import tableActionsFor from './tableActions'

export const getUrl = productId => `${PATH_PRODUCTS}/${productId || 'new'}`

const baseActionCreators = actionCreatorsFor(constants.actionKeys.PRODUCTS, { fetchStats: true, fetchExtraInfo: true })

const additionalActionCreators = {
  importFromFile: files => {
    return {
      type: actionTypes.PRODUCTS_IMPORT_REQUEST,
      files
    }
  },
  tableActions: tableActionsFor(constants.actionKeys.PRODUCT_TABLE_OPTIONS),
  files: actionCreatorsFor(constants.actionKeys.PRODUCT_FILES),
  productTasks: actionCreatorsFor(constants.actionKeys.PRODUCT_TASKS),
  productWarehouseStats: actionCreatorsFor(constants.actionKeys.PRODUCT_WAREHOUSE_STATS, {}, '_id'),
  navigateTo: (productId, replaceUrl) => navigateTo(getUrl(productId), replaceUrl),
  navigateToBase: replaceUrl => navigateTo(PATH_PRODUCTS, replaceUrl),
  fetchStatsRequest: request(baseActionCreators.actionTypes.fetchStatsRequest),
  fetchStatsStart: start(baseActionCreators.actionTypes.fetchStatsStart),
  fetchStatsSuccess: success(baseActionCreators.actionTypes.fetchStatsSuccess),
  fetchStatsError: error(baseActionCreators.actionTypes.fetchStatsError),
  doPatchAction: request(actionTypes.PRODUCT_PATCH_ACTION_REQUEST),
  doPostAction: request(actionTypes.PRODUCT_POST_ACTION_REQUEST),
  fetchExtraInfoSuccess: success(baseActionCreators.actionTypes.fetchExtraInfoSuccess)
}

const productActions = { ...baseActionCreators, ...additionalActionCreators }

export default productActions
