import constants from '../constants'
import {
  getCombinedListReducerFor,
  getCombinedSingleRecordReducerFor,
  getTableOptions
} from '../helpers/reducerHelpers'
import tableReducerFor from './tableReducer'

export const initialSupplyOfferTableOptions = {
  default: getTableOptions()
}

export default getCombinedListReducerFor(constants.actionKeys.SUPPLY_OFFERS, {
  memos: getCombinedListReducerFor(constants.actionKeys.SUPPLY_OFFER_MEMOS),
  systemMessages: getCombinedListReducerFor(constants.actionKeys.SUPPLY_OFFER_SYSTEM_MESSAGES),
  supplyOfferRows: getCombinedListReducerFor(constants.actionKeys.SUPPLY_OFFER_ROWS),
  tableOptions: tableReducerFor(constants.actionKeys.SUPPLY_OFFERS, initialSupplyOfferTableOptions),
  files: getCombinedListReducerFor(constants.actionKeys.SUPPLY_OFFER_FILES),
  summaryStats: getCombinedListReducerFor(constants.actionKeys.SUPPLY_OFFERS_SUMMARY_STATS, {}, '_id'),
  supplyOfferDefaults: getCombinedSingleRecordReducerFor(constants.actionKeys.SUPPLY_OFFER_DEFAULTS)
})
