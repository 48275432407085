import PropTypes from 'prop-types'
import { FormFeedback, FormGroup, FormText, Label } from 'reactstrap'
import get from 'lodash/get'
import isFunction from 'lodash/isFunction'

import { commonFormGroupPropTypes } from './propTypes'

const PlainFormGroup = ({
  label,
  helpText,
  className,
  field,
  validationErrors,
  value,
  model,
  renderInput
}) => {
  const inputId = field

  const groupLabel = label &&
    <Label for={inputId}>{isFunction(label) ? label() : label}</Label>

  const currentValue = value === undefined && model ? get(model, field) : value

  return (
    <FormGroup className={className}>
      {groupLabel}
      {renderInput(currentValue)}
      {helpText ? <FormText color='muted'>{helpText}</FormText> : null}
      <FormFeedback>{validationErrors?.[field] ? validationErrors?.[field].msg : null}</FormFeedback>
    </FormGroup>
  )
}

PlainFormGroup.propTypes = {
  label: commonFormGroupPropTypes.label,
  helpText: commonFormGroupPropTypes.helpText,
  className: commonFormGroupPropTypes.className,
  field: commonFormGroupPropTypes.field,
  validationErrors: commonFormGroupPropTypes.validationErrors,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  model: PropTypes.object,
  renderInput: PropTypes.func.isRequired
}

export default PlainFormGroup
