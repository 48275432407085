import constants from '../constants'
import { actionCreatorsFor } from '../helpers/actionHelpers'
import tableActionsFor from './tableActions'

const baseActionCreators = actionCreatorsFor(constants.actionKeys.SUPPLIERS)

const additionalActionCreators = {
  tableActions: tableActionsFor(constants.actionKeys.SUPPLIER_TABLE_OPTIONS),
  order: actionCreatorsFor(constants.actionKeys.SUPPLIER_ORDER)
}

const supplierActions = { ...baseActionCreators, ...additionalActionCreators }
export default supplierActions
