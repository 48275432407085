import { BaseSyntheticEvent, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  FieldValues,
  Path,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  UseFormProps,
  UseFormSetError
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DeepPartial } from 'redux'
import { AnyObjectSchema, AnySchema, Lazy } from 'yup'

import { parseValidationErrors } from '../../helpers/apiHelpers'

export const handleApiSubmitErrors = <T extends FieldValues>(error: { validationErrors: Record<string, { message: string }> | { msg: string, param: string }[] }, setError: UseFormSetError<T>, rootErrors: string[] | undefined) => {
  if(error.validationErrors) {
    const validationErrors: Record<string, { message: string }> = Array.isArray(error.validationErrors)
      ? parseValidationErrors(error.validationErrors)
      : error.validationErrors
    for(const [key, validationError] of Object.entries(validationErrors)) {
      setError(key as `root.${string}` | 'root' | Path<T>, { type: 'validationError', message: validationError.message })
      if(rootErrors?.includes(key)) {
        // Set "global" errors with `rootErrors` which are cleared automatically before each submission.
        // Good for displaying custom errors for the user.
        setError(`root.${key}`, { type: 'validationError', message: validationError.message })
      }
    }
  }
}

interface UseApiFormProps<T extends FieldValues> extends UseFormProps<T> {
  schema?: AnyObjectSchema | Lazy<AnySchema, unknown>
  rootErrors?: string[]
  onSubmit?: (data: T, defaultValues?: DeepPartial<Awaited<T>> | DeepPartial<T> | ((payload?: unknown) => Promise<T>)) => T | undefined | Promise<T | undefined>
  onInvalid?: SubmitErrorHandler<T>
}

const useApiForm = <T extends FieldValues>({
  schema,
  onSubmit,
  rootErrors,
  ...hookFormOptions
}: UseApiFormProps<T>) => {
  const hookFormProps = useForm<T>({
    resolver: schema ? yupResolver(schema) : undefined,
    ...hookFormOptions
  })
  const {
    reset,
    handleSubmit,
    setError,
    clearErrors
  } = hookFormProps

  const resetForm = useCallback((model?: T) => {
    clearErrors()
    return reset(model)
  }, [clearErrors, reset])

  const safeSubmit = useCallback((event: BaseSyntheticEvent) => {
    // Call stopPropagation as soon as possible because handleSubmit is async and event might get propagated before submit
    event?.stopPropagation?.()

    const submit: SubmitHandler<T> = (model: T) => {
      return Promise.resolve(clearErrors())
        .then(() => onSubmit?.(model, hookFormOptions.defaultValues))
        .then(resetForm)
        .catch(error => {
          return handleApiSubmitErrors(error, setError, rootErrors)
        })
    }
    return handleSubmit(submit, hookFormOptions.onInvalid)(event)
  }, [clearErrors, handleSubmit, hookFormOptions.defaultValues, hookFormOptions.onInvalid, onSubmit, resetForm, rootErrors, setError])

  return {
    ...hookFormProps,
    reset,
    resetForm,
    setError,
    handleSubmit,
    clearErrors,
    submit: safeSubmit,
    hookFormProps // Export hook forms properties which must be passed to FormProviders as whole
  }
}

useApiForm.propTypes = {
  schema: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  rootErrors: PropTypes.arrayOf(PropTypes.string)
}

export default useApiForm
