// FIXME: extracted from moment package, as there is no way to import locale from package with both Vite and node, and still use with rollup and cypress
//! moment.js locale configuration
//! locale : Finnish [fi]
//! author : Tarmo Aidantausta : https://github.com/bleadof

import moment from 'moment'

const numbersPast =
        'nolla yksi kaksi kolme neljä viisi kuusi seitsemän kahdeksan yhdeksän'.split(
          ' '
        )
const numbersFuture = [
  'nolla',
  'yhden',
  'kahden',
  'kolmen',
  'neljän',
  'viiden',
  'kuuden',
  numbersPast[7],
  numbersPast[8],
  numbersPast[9]
]
function translate(number: number, _withoutSuffix: boolean, key: string, isFuture: boolean) {
  let result = ''
  switch(key) {
    case 's':
      return isFuture ? 'muutaman sekunnin' : 'muutama sekunti'
    case 'ss':
      result = isFuture ? 'sekunnin' : 'sekuntia'
      break
    case 'm':
      return isFuture ? 'minuutin' : 'minuutti'
    case 'mm':
      result = isFuture ? 'minuutin' : 'minuuttia'
      break
    case 'h':
      return isFuture ? 'tunnin' : 'tunti'
    case 'hh':
      result = isFuture ? 'tunnin' : 'tuntia'
      break
    case 'd':
      return isFuture ? 'päivän' : 'päivä'
    case 'dd':
      result = isFuture ? 'päivän' : 'päivää'
      break
    case 'M':
      return isFuture ? 'kuukauden' : 'kuukausi'
    case 'MM':
      result = isFuture ? 'kuukauden' : 'kuukautta'
      break
    case 'y':
      return isFuture ? 'vuoden' : 'vuosi'
    case 'yy':
      result = isFuture ? 'vuoden' : 'vuotta'
      break
  }
  result = verbalNumber(number, isFuture) + ' ' + result
  return result
}
function verbalNumber(number: number, isFuture: boolean) {
  return number < 10
    ? isFuture
      ? numbersFuture[number]
      : numbersPast[number]
    : number
}

export default moment.defineLocale('fi', {
  months: 'tammikuu_helmikuu_maaliskuu_huhtikuu_toukokuu_kesäkuu_heinäkuu_elokuu_syyskuu_lokakuu_marraskuu_joulukuu'.split(
    '_'
  ),
  monthsShort:
        'tammi_helmi_maalis_huhti_touko_kesä_heinä_elo_syys_loka_marras_joulu'.split(
          '_'
        ),
  weekdays:
        'sunnuntai_maanantai_tiistai_keskiviikko_torstai_perjantai_lauantai'.split(
          '_'
        ),
  weekdaysShort: 'su_ma_ti_ke_to_pe_la'.split('_'),
  weekdaysMin: 'su_ma_ti_ke_to_pe_la'.split('_'),
  longDateFormat: {
    LT: 'HH.mm',
    LTS: 'HH.mm.ss',
    L: 'DD.MM.YYYY',
    LL: 'Do MMMM[ta] YYYY',
    LLL: 'Do MMMM[ta] YYYY, [klo] HH.mm',
    LLLL: 'dddd, Do MMMM[ta] YYYY, [klo] HH.mm',
    l: 'D.M.YYYY',
    ll: 'Do MMM YYYY',
    lll: 'Do MMM YYYY, [klo] HH.mm',
    llll: 'ddd, Do MMM YYYY, [klo] HH.mm'
  },
  calendar: {
    sameDay: '[tänään] [klo] LT',
    nextDay: '[huomenna] [klo] LT',
    nextWeek: 'dddd [klo] LT',
    lastDay: '[eilen] [klo] LT',
    lastWeek: '[viime] dddd[na] [klo] LT',
    sameElse: 'L'
  },
  relativeTime: {
    future: '%s päästä',
    past: '%s sitten',
    s: translate,
    ss: translate,
    m: translate,
    mm: translate,
    h: translate,
    hh: translate,
    d: translate,
    dd: translate,
    M: translate,
    MM: translate,
    y: translate,
    yy: translate
  },
  dayOfMonthOrdinalParse: /\d{1,2}\./,
  ordinal: number => `${number}.`,
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4 // The week that contains Jan 4th is the first week of the year.
  }
})
