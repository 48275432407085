import { Nav, Navbar, NavItem } from 'reactstrap'

import './login.scss'
import AppNavLogo from '../Header/AppNavLogo'

const LoginHeader = () => {
  return (
    <Navbar color='dark' dark data-testid='loginHeader'>
      <Nav>
        <NavItem>
          <AppNavLogo isLinkToMarketingSite />
        </NavItem>
      </Nav>
    </Navbar>
  )
}

export default LoginHeader
