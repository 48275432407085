import { parseTemplate } from 'url-template'

import constants, { actionTypes } from '../constants'
import { actionCreatorsFor, request, requestRecords } from '../helpers/actionHelpers'
import { PATH_TICKETS } from '../routes'
import { navigateTo } from './navigateActions'
import tableActions from './tableActions'

const ticketUrlTemplate = parseTemplate(`${PATH_TICKETS}/{ticketId}{?workId,invoiceId,inboundInvoiceId,targetId,customerId,receiptId,receiverId,projectId,contactId}`)
export const getUrl = (ticketId, params) => ticketUrlTemplate.expand({ ticketId: ticketId || 'new', ...params })

const baseActionCreators = actionCreatorsFor(constants.actionKeys.TICKETS, { ticketsOfRelationFetch: true })

const additionalActionCreators = {
  navigateTo: (ticketId, replaceUrl, params) => navigateTo(getUrl(ticketId, params), replaceUrl),
  navigateToBase: replaceUrl => navigateTo(PATH_TICKETS, replaceUrl),
  memos: actionCreatorsFor(constants.actionKeys.TICKET_MEMOS),
  employees: actionCreatorsFor(constants.actionKeys.TICKET_EMPLOYEES),
  files: actionCreatorsFor(constants.actionKeys.TICKET_FILES),
  tableActions: tableActions(constants.actionKeys.TICKETS),
  ticketsOfRelationFetchRequest: requestRecords(baseActionCreators.actionTypes.ticketsOfRelationFetchRequest),
  doPatchAction: request(actionTypes.SET_TICKET_COMPLETED)
}

const ticketActions = { ...baseActionCreators, ...additionalActionCreators }
export default ticketActions
