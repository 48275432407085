import { addErrorNotification, addSuccessNotification } from '../../helpers/notificationHelpers'

type NotificationMessages = {
  errorMessage: string | null
  successMessage: string | null
  onError?: () => void
}

export const basicApiNotification = async(queryFulfilled: Promise<unknown>, { errorMessage, successMessage, onError }: NotificationMessages) => {
  try {
    await queryFulfilled
    if(successMessage !== null) {
      addSuccessNotification(successMessage)
    }
  } catch(error) {
    // queryFulfilled throws a QueryFulfilledRejectionReason error which is not exported easily
    const notificationError = (error as { error?: { message: string } })?.error?.message || errorMessage
    if(notificationError !== null) {
      onError?.()
      addErrorNotification(notificationError)
    }
  }
}
