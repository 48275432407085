import constants from '../constants'
import { getCombinedListReducerFor, getTableOptions } from '../helpers/reducerHelpers'
import tableReducerFor from './tableReducer'

export const initialSystemMessageTableOptions = {
  default: getTableOptions()
}

export default getCombinedListReducerFor(constants.actionKeys.SYSTEM_MESSAGES, {
  tableOptions: tableReducerFor(constants.actionKeys.SYSTEM_MESSAGES, initialSystemMessageTableOptions)
})
