import reduxCrud from 'redux-crud-mb'

import constants from '../constants'

const actionTypes = reduxCrud.actionTypesFor(constants.actionKeys.WHO_AM_I)

const initialState = { busy: true, data: {} }

const whoAmIReducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.fetchSuccess: {
      const { whoAmI, employeeSystemCustomers, vatRates } = action.records
      return {
        data: whoAmI,
        employeeSystemCustomers,
        vatRates,
        busy: false
      }
    }
    case actionTypes.fetchStart:
      return {
        ...state,
        busy: true
      }
    case actionTypes.fetchError:
      return {
        ...state,
        busy: false,
        error: action.error
      }
    default:
      return state
  }
}

export default whoAmIReducer
