import {
  all,
  call,
  put,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'

import packetActions from '../actions/packetActions'
import productActions from '../actions/productActions'
import {
  authenticateStulApi,
  createPacketInstallation,
  deletePacketInstallation,
  fetchInstallationContents,
  fetchPacketInstallations,
  installationApi,
  normalizePackets,
  packetApi,
  packetGroupApi,
  packetInstallationContentApi,
  updatePacketInstallation
} from '../api/packetApi'
import { actionTypes } from '../constants'
import {
  createFlow,
  createSocketWatcherWithApiHandlerAndNormalizer,
  deleteFlow,
  fetchFlow,
  genericSagaErrorHandler,
  getPromiseHandlersFromData,
  getSubSagas,
  searchFlow,
  updateFlow
} from '../helpers/sagaHelpers'
import { handleWhoAmI } from './whoAmISaga'

const handlePacketApiResponse = (mainAction, tableIdentifier) =>
  function* ({ data, installations, packetInstallations, tableOptions }) {
    yield put(mainAction(data))
    if(installations) {
      yield put(packetActions.installations.fetchSuccess(installations))
    }
    if(packetInstallations) {
      yield put(packetActions.packetInstallations.fetchSuccess(packetInstallations))
    }
    if(tableOptions && tableIdentifier != null && tableOptions.orderBy != null) {
      yield put(packetActions.tableActions.updateOptions(tableOptions, tableIdentifier))
    }
    return data
  }

const handlePacketInstallationsApiResponse = mainAction =>
  function* ({ data, installations }) {
    yield put(mainAction(data))
    yield put(packetActions.installations.fetchSuccess(installations))
    return data
  }

const handleInstallationsApiResponse = mainAction =>
  function* (data) {
    yield put(mainAction(data))
    return data
  }

const watchOnPacketSockets = createSocketWatcherWithApiHandlerAndNormalizer('packet', packetActions, handlePacketApiResponse, normalizePackets)

const packetFetchFlow = fetchFlow({
  fetchApi: packetApi.fetch,
  actions: packetActions,
  base: 'packets',
  errorMsg: 'Pakettien',
  getApiResponseHandler: data => handlePacketApiResponse(packetActions.fetchSuccess, data.tableIdentifier)
})

const packetUpdateFlow = updateFlow(packetApi.update, packetActions, 'Paketti', 'Paketin', handlePacketApiResponse(packetActions.updateSuccess))
const packetCreateFlow = createFlow(packetApi.create, packetActions, 'Paketti', 'Paketin', handlePacketApiResponse(packetActions.createSuccess))
const packetDeleteFlow = deleteFlow({
  deleteApi: packetApi.remove,
  actions: packetActions,
  singular: 'Paketti',
  errorMsg: 'Paketin',
  base: 'packets'
})
const packetSearchFlow = searchFlow(packetApi.search, packetActions, 'Pakettien', function* (data, searchTerm) {
  const packets = yield handlePacketApiResponse(packetActions.fetchSuccess, data.tableIdentifier)(data)
  yield put(packetActions.searchSuccess(packets, searchTerm))
  return packets
})

const installationFetchFlow = fetchFlow({
  fetchApi: installationApi.fetch,
  actions: packetActions.installations,
  base: 'packets.installations',
  errorMsg: 'Asennustapojen',
  getApiResponseHandler: () => handleInstallationsApiResponse(packetActions.installations.fetchSuccess)
})
const installationCreateFlow = createFlow(installationApi.create, packetActions.installations, 'Asennustapa', 'Asennustavan', handleInstallationsApiResponse(packetActions.installations.createSuccess))
const installationUpdateFlow = updateFlow(installationApi.update, packetActions.installations, 'Asennustapa', 'Asennustavan', handleInstallationsApiResponse(packetActions.installations.updateSuccess))
const installationDeleteFlow = deleteFlow({
  deleteApi: installationApi.remove,
  actions: packetActions.installations,
  singular: 'Asennustapa',
  errorMsg: 'Asennustavan',
  base: 'packets.installations'
})

const {
  subFetchFlow: packetInstallationFetchFlow,
  subCreateFlow: packetInstallationCreateFlow,
  subDeleteFlow: packetInstallationDeleteFlow
} = getSubSagas({
  mainActions: packetActions,
  mainReduxName: 'packets',
  subReduxName: 'packetInstallations'
}, {
  fetchApi: fetchPacketInstallations,
  createApi: createPacketInstallation,
  updateApi: updatePacketInstallation,
  deleteApi: deletePacketInstallation
}, {
  singular: 'Paketin asennustapa',
  accusative: 'Paketin asennustapojen',
  fetchError: 'Virhe paketin asennustapojen noutamisessa',
  deleteSuccess: 'Paketin asennustapa poistettu',
  deleteError: 'Virhe paketin asennustavan poistamisessa'
}, handlePacketInstallationsApiResponse)

function* packetInstallationContentFetchFlow({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(packetActions.packetInstallations.fetchContentStart())
    const response = yield call(fetchInstallationContents, record)
    const {
      products,
      packetInstallationPackets,
      packetInstallationProducts,
      packetInstallationTasks,
      packets,
      installations,
      packetInstallations
    } = response

    yield put(productActions.fetchSuccess(products))
    yield put(packetActions.packetInstallationPackets.fetchSuccess(packetInstallationPackets))
    yield put(packetActions.packetInstallationTasks.fetchSuccess(packetInstallationTasks))
    yield put(packetActions.packetInstallationProducts.fetchSuccess(packetInstallationProducts))
    yield put(packetActions.packetInstallations.fetchSuccess(packetInstallations))
    yield put(packetActions.installations.fetchSuccess(installations))
    yield put(packetActions.fetchSuccess(packets))

    yield call(resolve, response)
  } catch(err) {
    yield put(packetActions.packetInstallations.fetchContentError(err))
    yield * genericSagaErrorHandler(err, 'Virhe paketin sisällön noutamisessa', reject)
  }
}

const getContentManipulationFlows = (actions, singular, errorMsg, base) => {
  const singularText = `Paketin ${singular}`
  const errorText = `Paketin ${errorMsg}`
  return {
    createFlow: createFlow(
      packetInstallationContentApi.create,
      actions,
      singularText,
      errorText,
      function* (packetInstallationProducts) {
        yield put(actions.fetchSuccess(packetInstallationProducts))
      }
    ),
    deleteFlow: deleteFlow({
      deleteApi: packetInstallationContentApi.remove,
      actions,
      singular: singularText,
      errorMsg: errorText,
      base: `packets.${base}`
    })
  }
}

const {
  createFlow: packetInstallationProductCreateFlow,
  deleteFlow: packetInstallationProductDeleteFlow
} = getContentManipulationFlows(packetActions.packetInstallationProducts, 'tuote', 'tuotteen', 'packetInstallationProducts')

const {
  createFlow: packetInstallationTaskCreateFlow,
  deleteFlow: packetInstallationTaskDeleteFlow
} = getContentManipulationFlows(packetActions.packetInstallationTasks, 'työ', 'työn', 'packetInstallationTasks')

const {
  createFlow: packetInstallationPacketCreateFlow,
  deleteFlow: packetInstallationPacketDeleteFlow
} = getContentManipulationFlows(packetActions.packetInstallationPackets, 'alipaketti', 'alipaketin', 'packetInstallationPackets')

function* authenticateStulFlow({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    const response = yield call(authenticateStulApi, record)

    yield * handleWhoAmI(response)
    yield call(resolve, response)
  } catch(err) {
    yield * genericSagaErrorHandler(err, `Virhe STUL-kirjautumisessa: ${err.json.message}`, reject)
  }
}

const handlePacketGroupsApiResponse = mainAction =>
  function* (data) {
    yield put(mainAction(data))
    return data
  }

const packetGroupFetchFlow = fetchFlow({
  fetchApi: packetGroupApi.fetch,
  actions: packetActions.packetGroups,
  base: 'packets.packetGroups',
  errorMsg: 'Paketin ryhmien',
  getApiResponseHandler: () => handlePacketGroupsApiResponse(packetActions.packetGroups.fetchSuccess)
})

export default function* packetSaga() {
  yield takeLatest(packetActions.actionTypes.fetchRequest, packetFetchFlow)
  yield takeEvery(packetActions.actionTypes.updateRequest, packetUpdateFlow)
  yield takeEvery(packetActions.actionTypes.createRequest, packetCreateFlow)
  yield takeEvery(packetActions.actionTypes.deleteRequest, packetDeleteFlow)
  yield takeLatest(packetActions.actionTypes.searchRequest, packetSearchFlow)

  yield takeLatest(packetActions.installations.actionTypes.fetchRequest, installationFetchFlow)
  yield takeEvery(packetActions.installations.actionTypes.createRequest, installationCreateFlow)
  yield takeEvery(packetActions.installations.actionTypes.updateRequest, installationUpdateFlow)
  yield takeEvery(packetActions.installations.actionTypes.deleteRequest, installationDeleteFlow)

  yield takeLatest(packetActions.packetInstallations.actionTypes.fetchRequest, packetInstallationFetchFlow)
  yield takeEvery(packetActions.packetInstallations.actionTypes.createRequest, packetInstallationCreateFlow)
  yield takeEvery(packetActions.packetInstallations.actionTypes.deleteRequest, packetInstallationDeleteFlow)
  yield takeEvery(packetActions.packetInstallations.actionTypes.fetchContentRequest, packetInstallationContentFetchFlow)

  yield takeEvery(packetActions.packetInstallationProducts.actionTypes.createRequest, packetInstallationProductCreateFlow)
  yield takeEvery(packetActions.packetInstallationProducts.actionTypes.deleteRequest, packetInstallationProductDeleteFlow)
  yield takeEvery(packetActions.packetInstallationTasks.actionTypes.createRequest, packetInstallationTaskCreateFlow)
  yield takeEvery(packetActions.packetInstallationTasks.actionTypes.deleteRequest, packetInstallationTaskDeleteFlow)
  yield takeEvery(packetActions.packetInstallationPackets.actionTypes.createRequest, packetInstallationPacketCreateFlow)
  yield takeEvery(packetActions.packetInstallationPackets.actionTypes.deleteRequest, packetInstallationPacketDeleteFlow)

  yield takeLatest(actionTypes.AUTHENTICATE_STUL_REQUEST, authenticateStulFlow)

  yield takeLatest(packetActions.packetGroups.actionTypes.fetchRequest, packetGroupFetchFlow)

  yield all([
    watchOnPacketSockets()
  ])
}
