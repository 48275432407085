import { useCallback } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import timeRecordActions from '../../actions/timeRecordActions'
import { promisifyBoundModifyActions } from '../../helpers/dispatchHelpers'
import { callPromiseAndTapFunction } from '../../helpers/helpers'
import useActions from '../../hooks/useActions'
import { timeRecordTypePropType } from '../../propTypes'
import TimeRecordStatusButton from './TimeRecordStatusButton'

const componentActions = {
  createTimeRecord: timeRecordActions.createRequest
}

const TimeRecordTypeButton = ({ timeRecordType, disabled, onClick = noop }) => {
  const actions = useActions(componentActions)

  const createTimeRecord = useCallback(() =>
    callPromiseAndTapFunction(promisifyBoundModifyActions({ create: actions.createTimeRecord }), onClick)({ timeRecordTypeId: timeRecordType.id })
  , [actions.createTimeRecord, onClick, timeRecordType.id])

  return (
    <TimeRecordStatusButton
      timeRecordType={timeRecordType}
      onClick={createTimeRecord}
      className='w-100'
      disabled={disabled}
      testId={`createTimeRecordButton_${timeRecordType.name}`}
    />
  )
}

TimeRecordTypeButton.propTypes = {
  timeRecordType: timeRecordTypePropType,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
}
export default TimeRecordTypeButton
