import { castToArray, pluralOrSingularWithCount } from '@evelia/helpers/helpers'
import { miniSerializeError } from '@reduxjs/toolkit'
import {
  all,
  call,
  put,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'

import costCentreActions from '../actions/costCentreActions'
import denominationActions from '../actions/denominationActions'
import employeeActions from '../actions/employeeActions'
import fileActions from '../actions/fileActions'
import inboundInvoiceActions from '../actions/inboundInvoiceActions'
import receiverActions from '../actions/receiverActions'
import { showActionPrompt } from '../actions/uiActions'
import {
  approveInboundInvoice,
  assignInboundInvoiceRowsToWork,
  createInboundInvoice,
  createInboundInvoiceAccounting,
  createInboundInvoiceApprover,
  createInboundInvoiceFile,
  createInboundInvoiceRow,
  deleteInboundInvoice,
  deleteInboundInvoiceAccounting,
  deleteInboundInvoiceApproval,
  deleteInboundInvoiceApprover,
  deleteInboundInvoiceFile,
  deleteInboundInvoiceRow,
  doInboundInvoicePatchAction,
  doInboundInvoicePostAction,
  doInboundInvoiceRowPutAction,
  fetchFromMaventa,
  fetchInboundInvoiceAccountings,
  fetchInboundInvoiceApprovals,
  fetchInboundInvoiceApprovers,
  fetchInboundInvoiceFiles,
  fetchInboundInvoiceRows,
  fetchInboundInvoices,
  fetchInboundInvoiceStats,
  importC2BFromFile,
  normalizeInboundInvoiceApprovalList,
  normalizeInboundInvoiceApprovers,
  normalizeInboundInvoiceRowResponse,
  normalizeInboundInvoices,
  recurrenceRuleApi,
  updateInboundInvoice,
  updateInboundInvoiceAccounting,
  updateInboundInvoiceApprover,
  updateInboundInvoiceRow
} from '../api/inboundInvoiceApi'
import { actionTypes, uiTypes } from '../constants'
import { getMemoSagas } from '../helpers/generalSagas'
import { callFunctionOrContent } from '../helpers/helpers'
import { addInfoNotification, addSuccessNotification } from '../helpers/notificationHelpers'
import {
  createActionFlow,
  createFlow,
  createSocketWatcher,
  createSocketWatcherWithApiHandlerAndNormalizer,
  createSocketWatcherWithGenerator,
  deleteFlow,
  fetchFlow,
  genericSagaErrorHandler,
  getPromiseHandlersFromData,
  getSubEntitySagas,
  searchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

export const handleInboundInvoiceApiResponse = (mainAction, tableIdentifier) =>
  function* ({
    data,
    receivers,
    denominations,
    costCentres,
    extraInfo,
    tableOptions
  }) {
    yield put(mainAction(data))
    yield put(receiverActions.fetchSuccess(receivers))
    yield put(denominationActions.fetchSuccess(denominations))
    yield put(costCentreActions.fetchSuccess(costCentres))
    if(extraInfo) {
      yield put(inboundInvoiceActions.fetchExtraInfoSuccess(extraInfo))
    }
    if(tableOptions && tableIdentifier != null && tableOptions.orderBy != null) {
      yield put(inboundInvoiceActions.tableActions.updateOptions(tableOptions, tableIdentifier))
    }
    return data
  }

const handleInboundInvoiceRowApiResponse = mainAction =>
  function* ({ data, denominations, costCentres }) {
    yield put(mainAction(data))
    yield put(denominationActions.fetchSuccess(denominations))
    yield put(costCentreActions.fetchSuccess(costCentres))
    return data
  }

const getApprovalApiHandler = (mainAction, single, secondaryItemName) => function* ({ data, employees, [secondaryItemName]: secondaryItems }) {
  if(single) {
    yield all(castToArray(data).map(inboundInvoiceApproval => put(mainAction(inboundInvoiceApproval))))
  } else {
    yield put(mainAction(data))
  }
  yield put(inboundInvoiceActions[secondaryItemName].fetchSuccess(secondaryItems))
  yield put(employeeActions.fetchSuccess(employees))
  return data
}

const handleInboundInvoiceApprovalApiResponse = (mainAction, single) => getApprovalApiHandler(mainAction, single, 'approvers')
const handleInboundInvoiceApproverApiResponse = (mainAction, single) => getApprovalApiHandler(mainAction, single, 'approvals')

const watchOnInboundInvoiceSockets = createSocketWatcherWithApiHandlerAndNormalizer('inboundInvoice', inboundInvoiceActions, handleInboundInvoiceApiResponse, normalizeInboundInvoices)

const watchOnInboundInvoiceRowSockets = createSocketWatcherWithApiHandlerAndNormalizer('inboundInvoiceRow', inboundInvoiceActions.rows, handleInboundInvoiceRowApiResponse, normalizeInboundInvoiceRowResponse)

const watchOnInboundInvoiceApprovalSockets = createSocketWatcherWithApiHandlerAndNormalizer('inboundInvoiceApproval', inboundInvoiceActions.approvals, handleInboundInvoiceApprovalApiResponse, normalizeInboundInvoiceApprovalList)
const watchOnInboundInvoiceApproverSockets = createSocketWatcherWithApiHandlerAndNormalizer('inboundInvoiceApprover', inboundInvoiceActions.approvers, handleInboundInvoiceApproverApiResponse, normalizeInboundInvoiceApprovers)

const watchOnInboundInvoiceAccountingSockets = createSocketWatcher('inboundInvoiceAccounting', {
  created: inboundInvoiceActions.accountings.createSuccess,
  updated: inboundInvoiceActions.accountings.updateSuccess,
  deleted: inboundInvoiceActions.accountings.deleteSuccess
})

const watchOnInboundInvoiceRowExtraSockets = createSocketWatcherWithGenerator('inboundInvoiceRow', {
  unassignWork: function* (data) {
    yield put(showActionPrompt(uiTypes.PROMPT_INBOUND_INVOICE_ROW_UNASSIGN_WORK, data))
  }
})

const inboundInvoiceFetchFlow = fetchFlow({
  fetchApi: fetchInboundInvoices,
  actions: inboundInvoiceActions,
  base: 'inboundInvoices',
  errorMsg: 'Ostolaskujen',
  getApiResponseHandler: data => handleInboundInvoiceApiResponse(inboundInvoiceActions.fetchSuccess, data.tableIdentifier)
})

const inboundInvoiceUpdateFlow = updateFlow(updateInboundInvoice, inboundInvoiceActions, 'Ostolasku', 'Ostolaskun', handleInboundInvoiceApiResponse(inboundInvoiceActions.updateSuccess))
const inboundInvoiceCreateFlow = createFlow(createInboundInvoice, inboundInvoiceActions, 'Ostolasku', 'Ostolaskun', handleInboundInvoiceApiResponse(inboundInvoiceActions.createSuccess))
const inboundInvoiceSearchFlow = searchFlow(fetchInboundInvoices, inboundInvoiceActions, 'Ostolaskujen', function* (data, searchTerm) {
  const inboundInvoices = yield handleInboundInvoiceApiResponse(inboundInvoiceActions.fetchSuccess, null)(data)
  yield put(inboundInvoiceActions.searchSuccess(inboundInvoices, searchTerm))
})
const inboundInvoiceDeleteFlow = deleteFlow({
  deleteApi: deleteInboundInvoice,
  actions: inboundInvoiceActions,
  singular: 'Ostolasku',
  errorMsg: 'Ostolaskun',
  base: 'inboundInvoices'
})
const inboundInvoicePatchActionFlow = createActionFlow(doInboundInvoicePatchAction, inboundInvoiceActions, handleInboundInvoiceApiResponse)

const inboundInvoiceRowsFetchFlow = fetchFlow({
  fetchApi: fetchInboundInvoiceRows,
  actions: inboundInvoiceActions.rows,
  errorMsg: 'Ostolaskun rivien',
  getApiResponseHandler: () => handleInboundInvoiceRowApiResponse(inboundInvoiceActions.rows.fetchSuccess)
})

const inboundInvoiceRowUpdateFlow = updateFlow(updateInboundInvoiceRow, inboundInvoiceActions.rows, 'Ostolaskun rivi', 'Ostolaskun rivin', handleInboundInvoiceRowApiResponse(inboundInvoiceActions.rows.updateSuccess))
const inboundInvoiceRowCreateFlow = createFlow(createInboundInvoiceRow, inboundInvoiceActions.rows, 'Ostolaskun rivi', 'Ostolaskun rivin', handleInboundInvoiceRowApiResponse(inboundInvoiceActions.rows.createSuccess))
const inboundInvoiceRowDeleteFlow = deleteFlow({
  deleteApi: deleteInboundInvoiceRow,
  actions: inboundInvoiceActions.rows,
  singular: 'Ostolaskun rivi',
  errorMsg: 'Ostolaskun rivin',
  base: 'inboundInvoices.rows'
})

const inboundInvoiceRowPutActionFlow = createActionFlow(doInboundInvoiceRowPutAction, inboundInvoiceActions.rows, handleInboundInvoiceRowApiResponse)

const inboundInvoiceApprovalsFetchFlow = fetchFlow({
  fetchApi: fetchInboundInvoiceApprovals,
  actions: inboundInvoiceActions.approvals,
  errorMsg: 'Ostolaskun hyväksyntätietojen',
  apiResponseHandler: handleInboundInvoiceApprovalApiResponse(inboundInvoiceActions.approvals.fetchSuccess, false)
})

const inboundInvoiceApprovalDeleteFlow = deleteFlow({
  deleteApi: deleteInboundInvoiceApproval,
  actions: inboundInvoiceActions.approvals,
  singular: 'Ostolaskun hyväksyntätieto',
  errorMsg: err => `Virhe ostolaskun hyväksyntätiedon poistamisessa${err.json ? `: ${err.json.message}` : ''}`,
  base: 'inboundInvoices.approvals'
})

const inboundInvoiceApproversFetchFlow = fetchFlow({
  fetchApi: fetchInboundInvoiceApprovers,
  actions: inboundInvoiceActions.approvers,
  errorMsg: 'Ostolaskun hyväksyjien',
  apiResponseHandler: handleInboundInvoiceApproverApiResponse(inboundInvoiceActions.approvers.fetchSuccess, false)
})
const inboundInvoiceApproverUpdateFlow = updateFlow(updateInboundInvoiceApprover, inboundInvoiceActions.approvers, 'Ostolaskun hyväksyjä', 'Ostolaskun hyväksyjän', handleInboundInvoiceApproverApiResponse(inboundInvoiceActions.approvers.updateSuccess, true))
const inboundInvoiceApproverCreateFlow = createFlow(createInboundInvoiceApprover, inboundInvoiceActions.approvers, 'Ostolaskun hyväksyjä', 'Ostolaskun hyväksyjän', handleInboundInvoiceApproverApiResponse(inboundInvoiceActions.approvers.createSuccess, true))

const inboundInvoiceApproverDeleteFlow = deleteFlow({
  deleteApi: deleteInboundInvoiceApprover,
  actions: inboundInvoiceActions.approvers,
  singular: 'Ostolaskun hyväksyjä',
  errorMsg: 'ostolaskun hyväksyjän',
  base: 'inboundInvoices.approvers'
})

const inboundInvoiceAccountingsFetchFlow = fetchFlow({
  fetchApi: fetchInboundInvoiceAccountings,
  actions: inboundInvoiceActions.accountings,
  errorMsg: 'Ostolaskun tiliöinnin'
})

const inboundInvoiceAccountingUpdateFlow = updateFlow(updateInboundInvoiceAccounting, inboundInvoiceActions.accountings, 'Ostolaskun tiliöinti', 'Ostolaskun tiliöinnin')
const inboundInvoiceAccountingCreateFlow = createFlow(createInboundInvoiceAccounting, inboundInvoiceActions.accountings, 'Ostolaskun tiliöinti', 'Ostolaskun tiliöinnin')
const inboundInvoiceAccountingDeleteFlow = deleteFlow({
  deleteApi: deleteInboundInvoiceAccounting,
  actions: inboundInvoiceActions.accountings,
  singular: 'Ostolaskun maksu',
  errorMsg: 'ostolaskun maksun',
  base: 'inboundInvoices.accountings'
})

const fileFlows = getSubEntitySagas(inboundInvoiceActions, fileActions, fetchInboundInvoiceFiles, createInboundInvoiceFile, deleteInboundInvoiceFile, 'inboundInvoiceId', 'fileId', 'inboundInvoices', 'files', {
  singular: 'Ostolaskun tiedosto',
  accusative: 'Ostolaskun tiedoston',
  fetchError: 'Virhe ostolaskun tiedostojen noutamisessa',
  deleteSuccess: 'Ostolaskun tiedostolinkki poistettu',
  deleteError: 'Virhe ostolaskun tiedostolinkin poistamisessa'
})

function* inboundInvoiceFetchFromMaventaFlow({ data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(inboundInvoiceActions.fetchFromMaventaStart())
    const apiResponse = yield call(fetchFromMaventa, data)
    const inboundInvoices = yield handleInboundInvoiceApiResponse(inboundInvoiceActions.fetchSuccess, null)(apiResponse)
    yield put(inboundInvoiceActions.fetchFromMaventaSuccess(inboundInvoices))

    if(inboundInvoices.length) {
      addSuccessNotification(`${inboundInvoices.length} ostolasku${inboundInvoices.length === 1 ? '' : 'a'} noudettu Mavennasta.\nPäivitä sivu nähdäksesi uusimmat ostolaskut.`, { autoClose: 10000 })
    } else {
      addInfoNotification('Ei uusia ostolaskuja Mavennasta.')
    }
    yield call(resolve, inboundInvoices)
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe ostolaskujen noutamisessa Mavennasta', reject)
  }
}

function* approveInboundInvoiceFlow({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(inboundInvoiceActions.approveInboundInvoiceStart())
    const apiResponse = yield call(approveInboundInvoice, record, data)
    const inboundInvoiceApprovals = castToArray(apiResponse.data)
    yield put(inboundInvoiceActions.approveInboundInvoiceSuccess(apiResponse))
    yield * handleInboundInvoiceApprovalApiResponse(inboundInvoiceActions.approvals.fetchSuccess, false)(apiResponse)
    const approveText = record.isApproved ? 'hyväksytty' : 'hylätty'
    if(inboundInvoiceApprovals.length !== 1) {
      addSuccessNotification(`${pluralOrSingularWithCount(inboundInvoiceApprovals.length, 'ostolasku', 'ostolaskua')} ${approveText}`)
    } else {
      addSuccessNotification(`Ostolasku ${approveText}`)
    }
    yield call(resolve, record)
  } catch(err) {
    yield put(inboundInvoiceActions.approveInboundInvoiceError(miniSerializeError(err)))
    yield * genericSagaErrorHandler(err, 'Virhe ostolaskun hyväksynnässä', reject)
  }
}

function* assignInboundInvoiceRowsFlow({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(inboundInvoiceActions.assignInboundInvoiceRowsStart())
    const { data: inboundInvoiceRows } = yield call(assignInboundInvoiceRowsToWork, record, data)
    yield put(inboundInvoiceActions.assignInboundInvoiceRowsSuccess(inboundInvoiceRows))
    yield put(inboundInvoiceActions.rows.fetchSuccess(inboundInvoiceRows))
    addSuccessNotification('Ostolaskun rivit kohdistettu')
    yield call(resolve, inboundInvoiceRows)
  } catch(err) {
    yield put(inboundInvoiceActions.assignInboundInvoiceRowsError(miniSerializeError(err)))
    yield * genericSagaErrorHandler(err, 'Virhe ostolaskun rivien kohdistuksessa', reject)
  }
}

function* inboundInvoiceStatsFlow() {
  try {
    yield put(inboundInvoiceActions.fetchStatsStart())
    const inboundInvoiceStats = yield call(fetchInboundInvoiceStats)
    yield put(inboundInvoiceActions.fetchStatsSuccess(inboundInvoiceStats))
  } catch(err) {
    yield put(inboundInvoiceActions.fetchStatsError(miniSerializeError(err)))
    yield * genericSagaErrorHandler(err)
  }
}

function* inboundInvoicePostActionFlow({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    const apiResponse = yield call(doInboundInvoicePostAction, record, data)
    const inboundInvoices = yield * handleInboundInvoiceApiResponse(inboundInvoiceActions.fetchRequest)(apiResponse)
    addSuccessNotification(data.getSuccessMessage ? callFunctionOrContent(data.getSuccessMessage, inboundInvoices) : 'Toiminto onnistui')
    yield call(resolve, inboundInvoices)
  } catch(err) {
    yield * genericSagaErrorHandler(err, data.getErrorMessage ? callFunctionOrContent(data.getErrorMessage, err, data) : 'Toiminto epäonnistui', reject)
  }
}

const memoSagas = getMemoSagas({
  actions: inboundInvoiceActions,
  baseName: 'inboundInvoices',
  socketName: 'inbound_invoice',
  titleGenetive: 'ostolaskun',
  apiBaseUrl: 'inbound_invoices'
})

function* importC2BFlow({ data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    const apiResponse = yield call(importC2BFromFile, data)
    yield handleInboundInvoiceApiResponse(inboundInvoiceActions.fetchSuccess)(apiResponse)
    addSuccessNotification('Ostolaskut luettu tiedostosta')
    yield call(resolve, apiResponse)
  } catch(err) {
    yield * genericSagaErrorHandler(err, err.json.message, reject)
  }
}

const handleRecurrenceRuleApiResponse = mainAction =>
  function* ({ data, inboundInvoices, ...rest }) {
    yield handleInboundInvoiceApiResponse(inboundInvoiceActions.fetchSuccess)({ data: inboundInvoices, ...rest })
    yield put(mainAction(data))
    return data
  }

const inboundInvoiceRecurrenceRuleFetchFlow = fetchFlow({
  fetchApi: recurrenceRuleApi.fetch,
  actions: inboundInvoiceActions.recurrenceRules,
  errorMsg: 'Toistuvussäännön',
  apiResponseHandler: handleRecurrenceRuleApiResponse(inboundInvoiceActions.recurrenceRules.fetchSuccess)
})
const inboundInvoiceRecurrenceRuleCreateFlow = createFlow(recurrenceRuleApi.create, inboundInvoiceActions.recurrenceRules, 'Toistuva maksu', 'Toistuvan maksun', handleRecurrenceRuleApiResponse(inboundInvoiceActions.recurrenceRules.createSuccess))
const inboundInvoiceRecurrenceRuleUpdateFlow = updateFlow(recurrenceRuleApi.update, inboundInvoiceActions.recurrenceRules, 'Toistuvuussääntö', 'Toistuvuussäännön', handleRecurrenceRuleApiResponse(inboundInvoiceActions.recurrenceRules.updateSuccess))
const inboundInvoiceRecurrenceOccurrenciesFetchFlow = fetchFlow({
  fetchApi: recurrenceRuleApi.fetch,
  getData: data => {
    return {
      ...data,
      subItemAction: 'occurrencies'
    }
  },
  actions: inboundInvoiceActions.recurrenceOccurrencies,
  errorMsg: 'Maksun toistojen',
  apiResponseHandler: handleRecurrenceRuleApiResponse(inboundInvoiceActions.recurrenceOccurrencies.fetchSuccess)
})

export default function* inboundInvoiceSaga() {
  yield takeLatest(inboundInvoiceActions.actionTypes.fetchRequest, inboundInvoiceFetchFlow)
  yield takeEvery(inboundInvoiceActions.actionTypes.updateRequest, inboundInvoiceUpdateFlow)
  yield takeEvery(inboundInvoiceActions.actionTypes.createRequest, inboundInvoiceCreateFlow)
  yield takeLatest(inboundInvoiceActions.actionTypes.searchRequest, inboundInvoiceSearchFlow)
  yield takeEvery(inboundInvoiceActions.actionTypes.deleteRequest, inboundInvoiceDeleteFlow)

  yield takeEvery(inboundInvoiceActions.rows.actionTypes.fetchRequest, inboundInvoiceRowsFetchFlow)
  yield takeEvery(inboundInvoiceActions.rows.actionTypes.updateRequest, inboundInvoiceRowUpdateFlow)
  yield takeEvery(inboundInvoiceActions.rows.actionTypes.createRequest, inboundInvoiceRowCreateFlow)
  yield takeEvery(inboundInvoiceActions.rows.actionTypes.deleteRequest, inboundInvoiceRowDeleteFlow)
  yield takeEvery(actionTypes.INBOUND_INVOICE_ROWS_PUT_ACTION_REQUEST, inboundInvoiceRowPutActionFlow)

  yield takeLatest(inboundInvoiceActions.files.actionTypes.fetchRequest, fileFlows.subFetchFlow)
  yield takeEvery(inboundInvoiceActions.files.actionTypes.createRequest, fileFlows.subCreateFlow)
  yield takeLatest(inboundInvoiceActions.files.actionTypes.deleteRequest, fileFlows.subDeleteFlow)

  yield takeEvery(inboundInvoiceActions.approvals.actionTypes.fetchRequest, inboundInvoiceApprovalsFetchFlow)
  yield takeEvery(inboundInvoiceActions.approvals.actionTypes.deleteRequest, inboundInvoiceApprovalDeleteFlow)

  yield takeEvery(inboundInvoiceActions.approvers.actionTypes.fetchRequest, inboundInvoiceApproversFetchFlow)
  yield takeEvery(inboundInvoiceActions.approvers.actionTypes.createRequest, inboundInvoiceApproverCreateFlow)
  yield takeEvery(inboundInvoiceActions.approvers.actionTypes.updateRequest, inboundInvoiceApproverUpdateFlow)
  yield takeEvery(inboundInvoiceActions.approvers.actionTypes.deleteRequest, inboundInvoiceApproverDeleteFlow)

  yield takeEvery(inboundInvoiceActions.accountings.actionTypes.fetchRequest, inboundInvoiceAccountingsFetchFlow)
  yield takeEvery(inboundInvoiceActions.accountings.actionTypes.updateRequest, inboundInvoiceAccountingUpdateFlow)
  yield takeEvery(inboundInvoiceActions.accountings.actionTypes.createRequest, inboundInvoiceAccountingCreateFlow)
  yield takeEvery(inboundInvoiceActions.accountings.actionTypes.deleteRequest, inboundInvoiceAccountingDeleteFlow)

  yield takeEvery(actionTypes.INBOUND_INVOICES_FETCH_FROM_MAVENTA_REQUEST, inboundInvoiceFetchFromMaventaFlow)
  yield takeEvery(actionTypes.INBOUND_INVOICES_APPROVE_REQUEST, approveInboundInvoiceFlow)
  yield takeEvery(actionTypes.INBOUND_INVOICES_ASSIGN_ROWS_REQUEST, assignInboundInvoiceRowsFlow)

  yield takeLatest(inboundInvoiceActions.actionTypes.fetchStatsRequest, inboundInvoiceStatsFlow)
  yield takeLatest(actionTypes.INBOUND_INVOICE_POST_ACTION_REQUEST, inboundInvoicePostActionFlow)
  yield takeLatest(actionTypes.INBOUND_INVOICE_C2B_IMPORT_REQUEST, importC2BFlow)

  yield takeEvery(inboundInvoiceActions.recurrenceRules.actionTypes.fetchRequest, inboundInvoiceRecurrenceRuleFetchFlow)
  yield takeEvery(inboundInvoiceActions.recurrenceRules.actionTypes.createRequest, inboundInvoiceRecurrenceRuleCreateFlow)
  yield takeEvery(inboundInvoiceActions.recurrenceRules.actionTypes.updateRequest, inboundInvoiceRecurrenceRuleUpdateFlow)
  yield takeEvery(inboundInvoiceActions.recurrenceOccurrencies.actionTypes.fetchRequest, inboundInvoiceRecurrenceOccurrenciesFetchFlow)
  yield takeEvery(inboundInvoiceActions.actionTypes.patchRequest, inboundInvoicePatchActionFlow)

  yield all([
    memoSagas(),
    watchOnInboundInvoiceSockets(),
    watchOnInboundInvoiceRowSockets(),
    watchOnInboundInvoiceAccountingSockets(),
    watchOnInboundInvoiceApprovalSockets(),
    watchOnInboundInvoiceRowExtraSockets(),
    watchOnInboundInvoiceApproverSockets()
  ])
}
