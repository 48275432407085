import { Col, Row } from 'reactstrap'

import DenominationChooser from '../../components/Choosers/DenominationChooser'
import { formPropTypes } from '../../components/Form/Form'
import { FormInputGroup, HiddenValueFormGroup, NumberFormInput, TextFormGroup } from '../../components/FormGroups'
import InfoBox from '../../components/InfoBox'
import { denominationTypes } from '../../constants'

const ProjectExtraBudgetForm = ({
  defaultProps,
  model,
  updateField,
  unit,
  valueFormatter,
  diffValueFormatter,
  currentValue,
  extraSum,
  denomination,
  showDenominations,
  diff
}) => (
  <Row>
    {showDenominations
      ? (
        <DenominationChooser
          label='Littera'
          fieldName='denominationId'
          denominationId={model?.denominationId}
          denominationType={denominationTypes.DENOMINATION_TYPE_WORK}
          setNull
          defaultProps={defaultProps}
          updateField={updateField}
          disabled={denomination != null}
        />
        )
      : null}
    <Col>
      <FormInputGroup
        field='value'
        label='Budjetin muutos'
        inputComponent={NumberFormInput}
        appendComponent={unit}
        decimals={2}
        setNull
        labelTooltip='Syötä muutokset budjetin arvoon. Pienentääksesi budjettia syötä negatiivisena.'
        helpText={<i>Nykyinen: {valueFormatter(currentValue)}{extraSum != null ? `(${diffValueFormatter(extraSum)})` : null}</i>}
        isRequired
        {...defaultProps}
      />
      {showDenominations ? <InfoBox testId='unBudgetedSumInfo'>Litterabudjetoimatta {valueFormatter(diff)}</InfoBox> : null}
    </Col>
    <Col>
      <TextFormGroup
        field='description'
        label='Selite'
        setNull
        isRequired
        {...defaultProps}
      />
    </Col>
    <HiddenValueFormGroup field='type' setNull {...defaultProps} />
    {showDenominations ? null : <HiddenValueFormGroup field='denominationId' setNull {...defaultProps} />}
  </Row>
)

ProjectExtraBudgetForm.propTypes = formPropTypes

export default ProjectExtraBudgetForm
