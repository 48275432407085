import { parseTemplate } from 'url-template'

import constants, { actionTypes } from '../constants'
import {
  actionCreatorsFor,
  error,
  request,
  start,
  success
} from '../helpers/actionHelpers'
import { PATH_INVOICES, PATH_RECURRING_INVOICES } from '../routes'
import { navigateTo } from './navigateActions'
import tableActions from './tableActions'

const invoiceUrlBase = `${PATH_INVOICES}{/id}{?source}`
const invoiceUrlTemplate = parseTemplate(invoiceUrlBase)
export const getUrl = (id, params) => invoiceUrlTemplate.expand({ id, ...params })

const baseActionCreators = actionCreatorsFor(constants.actionKeys.INVOICES)

const additionalActionCreators = {
  chargeInvoiceRequest: request(actionTypes.CHARGE_INVOICE_REQUEST),
  chargeInvoiceStart: start(actionTypes.CHARGE_INVOICE_START),
  chargeInvoiceSuccess: success(actionTypes.CHARGE_INVOICE_SUCCESS),
  chargeInvoiceError: error(actionTypes.CHARGE_INVOICE_ERROR),
  accountings: actionCreatorsFor(constants.actionKeys.INVOICE_ACCOUNTINGS),
  files: actionCreatorsFor(constants.actionKeys.INVOICE_FILES),
  memos: actionCreatorsFor(constants.actionKeys.INVOICE_MEMOS),
  navigateTo: (invoiceId, replaceUrl, params) => navigateTo(getUrl(invoiceId, params), replaceUrl),
  navigateToBase: replaceUrl => navigateTo(getUrl(), replaceUrl),
  navigateToRecurringInvoiceBase: replaceUrl => navigateTo(PATH_RECURRING_INVOICES, replaceUrl),
  importPaymentsFromFile: file => {
    return {
      type: actionTypes.INVOICE_PAYMENTS_IMPORT_REQUEST,
      file
    }
  },
  downloadPdfRequest: request(actionTypes.DOWNLOAD_PDF_INVOICE_REQUEST),
  downloadPdfStart: start(actionTypes.DOWNLOAD_PDF_INVOICE_START),
  downloadPdfSuccess: success(actionTypes.DOWNLOAD_PDF_INVOICE_SUCCESS),
  downloadPdfError: error(actionTypes.DOWNLOAD_PDF_INVOICE_ERROR),
  createCreditInvoiceRequest: request(actionTypes.CREATE_CREDIT_INVOICE_REQUEST),
  tableActions: tableActions(constants.actionKeys.INVOICES),
  createReminderInvoices: request(actionTypes.REMINDER_INVOICES_CREATE_REQUEST),
  doPatchAction: request(actionTypes.INVOICE_PATCH_ACTION_REQUEST),
  interestInvoicing: actionCreatorsFor(constants.actionKeys.INTEREST_INVOICING),
  createConsolidateInvoice: request(actionTypes.CONSOLIDATED_INVOICE_CREATE_REQUEST),
  formSettings: actionCreatorsFor(constants.actionKeys.INVOICE_FORM_SETTINGS)
}

const invoiceActions = { ...baseActionCreators, ...additionalActionCreators }
export default invoiceActions
