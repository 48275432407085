import { parseTemplate } from 'url-template'

import {
  createApi,
  defaultEmbeddedNormalizer,
  del,
  get,
  getSubApi,
  getSubEntityApi,
  post,
  put
} from '../helpers/apiHelpers'
import { parseFilterParams, parseTableOptionsFromQuery } from '../helpers/reducerHelpers'
import { contactProjectUrlTemplate } from './contactApi'
import { optionsQueryBase, optionsQueryTerms, queryTermsToTemplate } from './urlTemplates'
import { normalizeWorkList } from './workApi'

const baseUrl = '/api/projects{/projectId}'
const projectUrlTemplate = parseTemplate(`${baseUrl}{/action}${queryTermsToTemplate(['showExtraInfo', 'includeParent', 'excludeSelf', ...optionsQueryTerms])}`)
const projectFileUrlTemplate = parseTemplate(`${baseUrl}/files{/projectFileId}`)
const projectAdditionalPersonsUrlTemplate = parseTemplate(`${baseUrl}/additional_persons{/additionalPersonId}`)
const projectContactsUrlTemplate = parseTemplate(`${baseUrl}/contacts{/contactId}`)
const projectEmployeesUrlTemplate = parseTemplate(`${baseUrl}/employees{/employeeId}`)
const projectSubItemUrlTemplate = parseTemplate(`${baseUrl}{/subItem,subItemType,subItemId}${optionsQueryBase}`)

export const normalizeProjectResponse = response => {
  const { _embedded, records, record } = response
  const {
    instalments,
    targets,
    customers,
    contacts,
    supervisors,
    costCentres,
    mainProjects,
    extraBudgets,
    authors,
    options,
    extraInfo
  } = _embedded || {}
  const data = records || record
  return {
    data,
    instalments: normalizeWorkList(instalments),
    targets: targets || [],
    customers: customers || [],
    contacts: contacts || [],
    supervisors: supervisors || [],
    costCentres: costCentres || [],
    mainProjects,
    extraBudgets: extraBudgets || [],
    authors: authors || [],
    tableOptions: parseTableOptionsFromQuery(options),
    extraInfo: extraInfo || []
  }
}

const getFetchUrl = params => {
  if(params.contactId) {
    return contactProjectUrlTemplate.expand({ ...params, subItem: 'projects' })
  } else {
    const filterParams = parseFilterParams(params)
    return projectUrlTemplate.expand(filterParams)
  }
}

export const fetchProjects = (params = {}) =>
  get(getFetchUrl(params))
    .then(normalizeProjectResponse)

export const updateProject = project =>
  put(projectUrlTemplate.expand({ projectId: project.id }), {
    body: JSON.stringify(project)
  })
    .then(normalizeProjectResponse)

export const createProject = project =>
  post(projectUrlTemplate.expand({}), {
    body: JSON.stringify(project)
  })
    .then(normalizeProjectResponse)

export const deleteProject = project =>
  del(projectUrlTemplate.expand({ projectId: project.id }))

export const createRTVReport = ({ targetMonth }) =>
  post(projectUrlTemplate.expand({ projectId: 'rtv-report' }), { body: JSON.stringify({ targetMonth }) })

export const {
  fetchApi: fetchProjectFiles,
  createApi: createProjectFile,
  deleteApi: deleteProjectFile
} = getSubEntityApi(projectFileUrlTemplate, 'projectId', 'fileId', 'projectFileId', 'file')

export const fetchProjectSubItem = ({ projectId, subItem, subItemType } = {}) =>
  get(projectSubItemUrlTemplate.expand({ projectId, subItem, subItemType }))

export const {
  fetchApi: fetchProjectAdditionalPersons,
  createApi: createProjectAdditionalPerson,
  updateApi: updateProjectAdditionalPerson,
  deleteApi: deleteProjectAdditionalPerson
} = getSubApi(projectAdditionalPersonsUrlTemplate, 'additionalPersonId')

export const {
  fetchApi: fetchProjectContacts,
  createApi: createProjectContact,
  updateApi: updateProjectContact,
  deleteApi: deleteProjectContact
} = getSubApi(projectContactsUrlTemplate, 'contactId')

export const {
  fetchApi: fetchProjectEmployees,
  updateApi: updateProjectEmployee,
  createApi: createProjectEmployee
} = getSubApi(projectEmployeesUrlTemplate, 'employeeId')

export const doPostAction = (data = {}, actionData = {}) =>
  post(projectUrlTemplate.expand(actionData), {
    body: JSON.stringify(data || {})
  }).then(normalizeProjectResponse)

const parseProjectExtraExpenseApiParams = route => params => ({
  ...params,
  id: params.projectId,
  subItem: route,
  subItemId: params.id,
  startDate: params.startDate ?? null,
  endDate: params.endDate ?? null,
  excludeSubProjects: params.excludeSubProjects ?? null
})

export const projectExtraExpenseApi = createApi({
  base: 'projects',
  editGetParams: parseProjectExtraExpenseApiParams('extra_expenses'),
  editMutateParams: parseProjectExtraExpenseApiParams('extra_expenses'),
  extraUrlTemplate: '{?startDate,endDate,excludeSubProjects}',
  normalizer: defaultEmbeddedNormalizer
})

export const projectExtraBudgetApi = createApi({
  base: 'projects',
  editGetParams: parseProjectExtraExpenseApiParams('extra_budgets'),
  editMutateParams: parseProjectExtraExpenseApiParams('extra_budgets'),
  normalizer: defaultEmbeddedNormalizer
})

export const projectDenominationBudgetApi = createApi({
  base: 'projects',
  editGetParams: parseProjectExtraExpenseApiParams('denomination_budgets'),
  editMutateParams: parseProjectExtraExpenseApiParams('denomination_budgets'),
  normalizer: defaultEmbeddedNormalizer
})

export const generateProjectReport = params =>
  post(projectSubItemUrlTemplate.expand(params), {
    body: JSON.stringify({})
  })
