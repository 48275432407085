import PropTypes from 'prop-types'
import { Label as RSLabel } from 'reactstrap'

import { callFunctionOrContent } from '../../../helpers/helpers'
import Tooltip from '../../Tooltip'
import { generateTooltipId } from '../../Tooltip/TooltipLabel'
import RequiredFieldIndicator from './RequiredFieldIndicator'

const Label = ({
  label,
  usePlaceholder,
  inputOnly,
  isCheck,
  inputId,
  isRequired,
  tooltip
}) => {
  return label && !usePlaceholder && !inputOnly
    ? (
      <RSLabel check={isCheck} for={inputId}>
        {callFunctionOrContent(label)}
        <RequiredFieldIndicator isRequired={!!isRequired} />
        {tooltip && <Tooltip id={generateTooltipId(inputId)} message={tooltip} />}
      </RSLabel>
      )
    : null
}

Label.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.node
  ]),
  usePlaceholder: PropTypes.bool,
  inputOnly: PropTypes.bool,
  isCheck: PropTypes.bool,
  inputId: PropTypes.string,
  isRequired: PropTypes.bool,
  tooltip: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.node
  ])
}

export default Label
