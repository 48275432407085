import {
  all,
  call,
  put,
  takeEvery,
  takeLeading
} from 'redux-saga/effects'

import supplierActions from '../actions/supplierActions'
import {
  createSupplier,
  fetchSuppliers,
  updateOrder,
  updateSupplier
} from '../api/supplierApi'
import {
  createFlow,
  createSocketWatcher,
  fetchFlow,
  genericSagaErrorHandler,
  updateFlow
} from '../helpers/sagaHelpers'

const watchOnSupplierSockets = createSocketWatcher('supplier', {
  created: supplierActions.createSuccess,
  updated: supplierActions.updateSuccess
})
const watchOnSupplierPrioritySockets = createSocketWatcher('supplierPriority', {
  created: data => supplierActions.tableActions.updateOptions({ ids: data.order }),
  updated: data => supplierActions.tableActions.updateOptions({ ids: data.order })
})

const handleSupplierApiResponse = (mainAction, single) =>
  function* ([suppliers, tableOptions], record) {
    const supplier = single && Array.isArray(suppliers) ? suppliers[0] : suppliers
    yield put(mainAction(supplier, record?.id))
    yield put(supplierActions.tableActions.updateOptions({ ids: tableOptions.order }))
    return supplier
  }

const supplierFetchFlow = fetchFlow({
  fetchApi: fetchSuppliers,
  actions: supplierActions,
  base: 'suppliers',
  errorMsg: 'Toimittajien',
  apiResponseHandler: handleSupplierApiResponse(supplierActions.fetchSuccess, false)
})

const supplierUpdateFlow = updateFlow(updateSupplier, supplierActions, 'Toimittaja', 'Toimittajan')
const supplierCreateFlow = createFlow(createSupplier, supplierActions, 'Toimittaja', 'Toimittajan', handleSupplierApiResponse(supplierActions.createSuccess, true))

function* supplierOrderUpdateFlow({ data = {} }) {
  try {
    const { order } = yield call(updateOrder, data)
    yield put(supplierActions.tableActions.updateOptions({ ids: order }))
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe toimittajien järjestämisessä')
  }
}

export default function* supplierSaga() {
  yield takeLeading(supplierActions.actionTypes.fetchRequest, supplierFetchFlow)
  yield takeEvery(supplierActions.actionTypes.updateRequest, supplierUpdateFlow)
  yield takeEvery(supplierActions.actionTypes.createRequest, supplierCreateFlow)
  yield takeEvery(supplierActions.tableActions.actionTypes.updateOptionsRequest, supplierOrderUpdateFlow)

  yield all([
    watchOnSupplierSockets(),
    watchOnSupplierPrioritySockets()
  ])
}
