import { all, takeEvery, takeLatest } from 'redux-saga/effects'

import supplyOfferCustomStateActions from '../actions/supplyOfferCustomStateActions'
import { supplyOfferCustomStateApi } from '../api/supplyOfferCustomStateApi'
import {
  createFlow,
  createSocketWatcher,
  deleteFlow,
  fetchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

const watchOnSupplyOfferCustomStateSockets = createSocketWatcher('supplyOfferCustomState', {
  created: supplyOfferCustomStateActions.createSuccess,
  updated: supplyOfferCustomStateActions.updateSuccess,
  deleted: supplyOfferCustomStateActions.deleteSuccess
})

const supplyOfferCustomStateFetchFlow = fetchFlow({
  fetchApi: supplyOfferCustomStateApi.fetch,
  actions: supplyOfferCustomStateActions,
  base: 'supplyOfferCustomStates',
  idField: 'id',
  errorMsg: 'tarviketarjouksen lisätilojen'
})

const supplyOfferCustomStateUpdateFlow = updateFlow(supplyOfferCustomStateApi.update, supplyOfferCustomStateActions, 'Tarviketarjouksen lisätila', 'Tarviketarjouksen lisätilan')
const supplyOfferCustomStateCreateFlow = createFlow(supplyOfferCustomStateApi.create, supplyOfferCustomStateActions, 'Tarviketarjouksen lisätila', 'Tarviketarjouksen lisätilan')
const supplyOfferCustomStateDeleteFlow = deleteFlow({
  deleteApi: supplyOfferCustomStateApi.remove,
  actions: supplyOfferCustomStateActions,
  singular: 'Tarviketarjouksen lisätila',
  errorMsg: 'Tarviketarjouksen lisätilan',
  base: 'supplyOfferCustomStates'
})

export default function* supplyOfferCustomStateSaga() {
  yield takeLatest(supplyOfferCustomStateActions.actionTypes.fetchRequest, supplyOfferCustomStateFetchFlow)
  yield takeEvery(supplyOfferCustomStateActions.actionTypes.updateRequest, supplyOfferCustomStateUpdateFlow)
  yield takeEvery(supplyOfferCustomStateActions.actionTypes.createRequest, supplyOfferCustomStateCreateFlow)
  yield takeEvery(supplyOfferCustomStateActions.actionTypes.deleteRequest, supplyOfferCustomStateDeleteFlow)
  yield all([
    watchOnSupplyOfferCustomStateSockets()
  ])
}
