import { parseTemplate } from 'url-template'

import {
  del,
  get,
  getSubEntityApi,
  post,
  put
} from '../helpers/apiHelpers'
import { parseFilterParams, parseTableOptionsFromQuery } from '../helpers/reducerHelpers'
import { customerSubItemUrlTemplate } from './customerApi'
import { optionsQueryBase } from './urlTemplates'

const baseUrl = '/api/targets{/targetId}{?newTargetId}'

const targetUrlTemplate = parseTemplate(`${baseUrl}${optionsQueryBase}`)
const targetContactUrlTemplate = parseTemplate(baseUrl + '/contacts{/targetContactId}')
const targetContactFetchUrlTemplate = parseTemplate(baseUrl + '/contacts')
const targetFileUrlTemplate = parseTemplate(baseUrl + '/files{/targetFileId}')
export const targetSubItemUrlTemplate = parseTemplate(baseUrl + '{/subItem,subItemType,subItemId}')

const getFetchUrl = params => {
  if(params.customerId) {
    return customerSubItemUrlTemplate.expand({ ...params, subItem: 'targets' })
  } if(params.subTargets) {
    return targetSubItemUrlTemplate.expand({ ...params, subItem: 'subtargets' })
  } else {
    const filterParams = parseFilterParams(params)
    return targetUrlTemplate.expand(filterParams)
  }
}

export const normalizeTargets = response => {
  const { _embedded, records, record } = response
  const {
    customers,
    contacts,
    parents,
    options
  } = _embedded || {}
  const data = records || record
  return {
    data,
    customers,
    contacts,
    parents: parents || [],
    tableOptions: parseTableOptionsFromQuery(options)
  }
}

export const fetchTargets = (params = {}) => {
  return get(getFetchUrl(params))
    .then(normalizeTargets)
}

export const updateTarget = target => {
  return put(targetUrlTemplate.expand({ targetId: target.id }), {
    body: JSON.stringify(target)
  }).then(normalizeTargets)
}

export const createTarget = target => {
  return post(targetUrlTemplate.expand({}), {
    body: JSON.stringify(target)
  }).then(normalizeTargets)
}

export const searchTargets = search => {
  return get(targetUrlTemplate.expand({ q: search }))
    .then(normalizeTargets)
}

export const {
  createApi: createTargetContact,
  deleteApi: deleteTargetContact
} = getSubEntityApi(targetContactUrlTemplate, 'targetId', 'contactId', 'targetContactId', 'contact')

export const {
  fetchApi: fetchTargetFiles,
  createApi: createTargetFile,
  deleteApi: deleteTargetFile
} = getSubEntityApi(targetFileUrlTemplate, 'targetId', 'fileId', 'targetFileId', 'file')

export const normalizeTargetContacts = response => {
  const { _embedded, records } = response
  const { contacts } = _embedded || {}
  const targetContacts = records
  return {
    targetContacts,
    contacts
  }
}

export const fetchTargetContacts = (targetId, params = {}) => {
  return get(targetContactFetchUrlTemplate.expand({ ...params, targetId }))
    .then(normalizeTargetContacts)
}

export const deleteTarget = (target, actionData = {}) =>
  del(targetUrlTemplate.expand({ targetId: target.id, ...actionData }))

export const fetchTargetStats = ({ targetId }) =>
  get(targetSubItemUrlTemplate.expand({ targetId, subItem: 'stats' }))
