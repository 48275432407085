import { parseTemplate } from 'url-template'

import {
  defaultEmbeddedNormalizer,
  del,
  get,
  getSubEntityApi,
  patch,
  post,
  put
} from '../helpers/apiHelpers'
import { parseFilterParams, parseTableOptionsFromQuery } from '../helpers/reducerHelpers'
import { customerSubItemUrlTemplate } from './customerApi'
import { normalizeEmployeeResponse } from './employeeApi'
import { targetSubItemUrlTemplate } from './targetApi'
import { optionsQueryBase } from './urlTemplates'

const baseUrl = '/api/supply_offers{/supplyOfferId}'
const actionUrl = `${baseUrl}{/action}${optionsQueryBase}`
const supplyOfferUrlTemplate = parseTemplate(actionUrl)
const supplyOfferSubItemUrlTemplate = parseTemplate(`${baseUrl}{/subItem,subItemType,subItemId}`)
const supplyOfferRowUrlTemplate = parseTemplate(`${baseUrl}/supply_offer_rows{/supplyOfferRowId}{/action}${optionsQueryBase}`)
const supplyOfferPreviewUrlTemplate = parseTemplate(`${baseUrl}/pdf_signature`)
const supplyOfferFileUrlTemplate = parseTemplate(`${baseUrl}/files{/supplyOfferFileId}`)

export const normalizeSupplyOfferList = response => {
  const { _embedded, records, record } = response
  const {
    customers,
    targets,
    supervisors,
    projects,
    contacts,
    options
  } = _embedded
  const data = records || record
  return {
    data,
    customers: customers || [],
    targets: targets || [],
    supervisors: normalizeEmployeeResponse(supervisors),
    projects: projects || [],
    contacts: contacts || [],
    tableOptions: parseTableOptionsFromQuery(options)
  }
}

const getFetchUrl = params => {
  if(params.customerId) {
    return customerSubItemUrlTemplate.expand({ ...params, subItem: 'supply_offers' })
  } else if(params.targetId) {
    return targetSubItemUrlTemplate.expand({ ...params, subItem: 'supply_offers' })
  } else {
    const filterParams = parseFilterParams(params)
    return supplyOfferUrlTemplate.expand(filterParams)
  }
}

export const fetchSupplyOffer = (params = {}) =>
  get(getFetchUrl(params))
    .then(normalizeSupplyOfferList)

export const updateSupplyOffer = (supplyOffer, params) => {
  const url = supplyOfferUrlTemplate.expand(params)
  return put(url, {
    body: JSON.stringify(supplyOffer)
  }).then(normalizeSupplyOfferList)
}
export const createSupplyOffer = (supplyOffer, params) =>
  post(supplyOfferUrlTemplate.expand(params), {
    body: JSON.stringify(supplyOffer)
  }).then(normalizeSupplyOfferList)

export const deleteSupplyOffer = (supplyOffer, params) =>
  del(supplyOfferUrlTemplate.expand({ supplyOfferId: supplyOffer.id, ...params }))

export const searchSupplyOffers = search =>
  get(supplyOfferUrlTemplate.expand({ q: search }))
    .then(normalizeSupplyOfferList)

export const fetchSupplyOfferRows = ({ supplyOfferId, ...params } = {}) =>
  get(supplyOfferRowUrlTemplate.expand({ supplyOfferId, ...params }))

export const updateSupplyOfferRow = (supplyOfferRow, params) =>
  put(supplyOfferRowUrlTemplate.expand({ supplyOfferId: supplyOfferRow.supplyOfferId, supplyOfferRowId: supplyOfferRow.id, ...params }), {
    body: JSON.stringify(supplyOfferRow)
  })

export const createSupplyOfferRow = supplyOfferRow =>
  post(supplyOfferRowUrlTemplate.expand({ supplyOfferId: supplyOfferRow.supplyOfferId }), {
    body: JSON.stringify(supplyOfferRow)
  })

export const deleteSupplyOfferRow = supplyOfferRow =>
  del(supplyOfferRowUrlTemplate.expand({ supplyOfferId: supplyOfferRow.supplyOfferId, supplyOfferRowId: supplyOfferRow.id }))

export const doSupplyOfferRowPutAction = (data = {}, actionData = {}) =>
  put(supplyOfferRowUrlTemplate.expand(actionData), {
    body: JSON.stringify(data || {})
  })

export const doSupplyOfferPatchAction = (data, { supplyOfferId, action } = {}) =>
  patch(supplyOfferUrlTemplate.expand({ supplyOfferId, action }), {
    body: JSON.stringify(data || {})
  }).then(normalizeSupplyOfferList)

export const doSupplyOfferRowPostAction = (data = {}, actionData = {}) =>
  post(supplyOfferRowUrlTemplate.expand(actionData), {
    body: JSON.stringify(data || {})
  })

export const createSupplyOfferPreview = (data, { supplyOfferId } = {}) => {
  return post(supplyOfferPreviewUrlTemplate.expand({ supplyOfferId }), {
    body: JSON.stringify(data)
  })
}

export const {
  fetchApi: fetchSupplyOfferFiles,
  createApi: createSupplyOfferFile,
  deleteApi: deleteSupplyOfferFile,
  updateApi: updateSupplyOfferFile
} = getSubEntityApi(supplyOfferFileUrlTemplate, 'supplyOfferId', 'fileId', 'supplyOfferFileId', 'file')

export const fetchSupplyOfferSummaryStats = ({ supplyOfferId }) =>
  get(supplyOfferSubItemUrlTemplate.expand({ supplyOfferId, subItem: 'supply_offer_stats' }))

export const doSupplyOfferAction = (record = {}, actionData = {}) => {
  return post(supplyOfferSubItemUrlTemplate.expand(actionData), {
    body: JSON.stringify(record || {})
  }).then(defaultEmbeddedNormalizer)
}

export const fetchSupplyOfferDefaults = () =>
  get(supplyOfferSubItemUrlTemplate.expand({ subItem: 'defaults' }))

export const updateSupplyOfferDefaults = record =>
  put(supplyOfferSubItemUrlTemplate.expand({ subItem: 'defaults' }), {
    body: JSON.stringify(record)
  })
