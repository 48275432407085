import { parseTemplate } from 'url-template'

import { get } from '../helpers/apiHelpers'

const baseUrl = '/api/eaddresses{?businessId}'

const eAddressUrlTemplate = parseTemplate(baseUrl)

export const fetchEaddresses = params =>
  get(eAddressUrlTemplate.expand(params))
