import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Getter } from '@devexpress/dx-react-core'
import {
  CustomPaging,
  CustomTreeData,
  FilteringState,
  PagingState,
  SearchState,
  SortingState,
  TreeDataState
} from '@devexpress/dx-react-grid'
import { faAngleDoubleDown, faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons'
import {
  Grid,
  PagingPanel,
  Table,
  TableBandHeader,
  TableHeaderRow,
  TableInlineCellEditing,
  TableTreeColumn,
  Toolbar,
  VirtualTable
} from 'dx-react-grid-bootstrap5'
import memoize from 'micro-memoize'

import ExpandButton from '../Buttons/ExpandButton'
import IconButton from '../Buttons/IconButton'
import { commonPropTypes, ControlledEditingState, getEditCell } from './DataGrid'
import {
  HeadCellComponent,
  HeadComponent,
  renderTableCell,
  renderTableRow,
  TableComponent,
  TableNoDataCell,
  ToolbarContent
} from './defaultGridComponents'
import { getSimpleRowId, parseOptions } from './helpers'
import {
  tableLocalizationMessages
} from './localization'

const getCustomFormatters = memoize(columnSpecs => columnSpecs.customRenderers.map(({ columnName, Formatter }) => <Formatter key={columnName} />), { maxSize: 10 })

class DataTree extends PureComponent {
  state = {
    expandedRowIds: [],
    validationErrors: {}
  }

  setValidationErrors = (rowId, validationErrors) => {
    this.setState({
      validationErrors: {
        ...this.state.validationErrors,
        [rowId]: validationErrors
      }
    })
  }

  setExpandedRowIds = expandedRowIds => {
    if(this.props.onRowExpanded) {
      const { expandedRowIds: oldExpandedRowIds } = this.state
      if(expandedRowIds.length > oldExpandedRowIds.length) {
        const expandedId = expandedRowIds.find(id => !oldExpandedRowIds.includes(id))
        this.props.onRowExpanded(expandedId)
      }
    }
    this.setState({ expandedRowIds })
  }

  getChildByItems = (row, rootRows) => (row ? row.items : rootRows)
  getChildByParentId = (row, rootRows) => {
    const { rootId = null, parentIdField = 'parentId', getRowId = getSimpleRowId } = this.props
    const childRows = rootRows.filter(r => r[parentIdField] === (row ? getRowId(row) : rootId))
    if(childRows.length) {
      return childRows
    }
    return (row && row.hasSubItems) ? [] : null
  }

  getChildByCustomFunction = (row, rootRows) => {
    const childRows = rootRows.filter(this.props.filterChildRow(row))
    if(childRows.length) {
      return childRows
    }
    return (row && row.hasSubItems) ? [] : null
  }

  handleRowClick = row => {
    const { onRowClick } = this.props
    const shouldExpand = !onRowClick || onRowClick(row)
    if(shouldExpand) {
      this.expandOnClick(row)
    }
  }

  expandOnClick = row => {
    const { getRowId = getSimpleRowId } = this.props
    const id = getRowId(row)
    const { expandedRowIds } = this.state
    const updatedRowIds = expandedRowIds.includes(id)
      ? expandedRowIds.filter(rowId => rowId !== id)
      : expandedRowIds.concat(id)
    this.setExpandedRowIds(updatedRowIds)
  }

  expandAllRowsClick = () => {
    this.setExpandedRowIds(this.props.allExpandableRowIds)
  }

  collapseAllRowsClick = () => {
    this.setExpandedRowIds([])
  }

  handleIsTreeRowLeaf = ({ isTreeRowLeaf }) => this.props.isTreeRowLeaf ? this.props.isTreeRowLeaf : isTreeRowLeaf

  render() {
    const {
      columnSpecs,
      rows,
      RowComponent,
      noRowsText,
      onAdded,
      onChanged,
      onDeleted,
      defaultModel,
      enableInlineEdit,
      useNestedItems,
      getRowId = getSimpleRowId,
      filterChildRow,
      tableClasses,
      headClasses,
      rowPropsByRow,
      tableOptions,
      defaultSorting,
      renderToolbarContent,
      testId,
      findFromRows,
      showExpandAndCollapseAllButtons,
      useVirtualTable
    } = this.props

    const searchEnabled = !!tableOptions

    const { expandedRowIds } = this.state
    const TableRowComponent = RowComponent || renderTableRow(this.handleRowClick, rowPropsByRow)

    const {
      sortingProps,
      pagingProps,
      filteringProps
    } = parseOptions(tableOptions, this.props)

    const getChildRows = filterChildRow
      ? this.getChildByCustomFunction
      : useNestedItems
        ? this.getChildByItems
        : this.getChildByParentId

    const TableCellComponent = renderTableCell(columnSpecs.columnWidths, columnSpecs.editingStates)

    const GridTable = useVirtualTable ? VirtualTable : Table

    return (
      <div className='position-relative w-100'>
        {showExpandAndCollapseAllButtons
          ? (
            <>
              <IconButton className='link-like mx-3 mb-3' icon={faAngleDoubleDown} onClick={this.expandAllRowsClick} data-testid='expandAllRows'>Avaa kaikki</IconButton>
              <IconButton className='link-like mx-3 mb-3' icon={faAngleDoubleUp} onClick={this.collapseAllRowsClick} data-testid='collapseAllRows'>Sulje kaikki</IconButton>
            </>
            )
          : null}
        <Grid
          rows={rows}
          columns={columnSpecs.columns}
          getRowId={getRowId}
        >
          <ControlledEditingState
            columnExtensions={columnSpecs.editingStates}
            onAdded={onAdded}
            onChanged={onChanged}
            onDeleted={onDeleted}
            defaultModel={defaultModel}
            rows={rows}
            getRowId={getRowId}
            setValidationErrors={this.setValidationErrors}
            findFromRows={findFromRows}
          />
          <TreeDataState
            expandedRowIds={expandedRowIds}
            onExpandedRowIdsChange={this.setExpandedRowIds}
          />
          <SearchState />
          <SortingState defaultSorting={defaultSorting} {...sortingProps} />
          {searchEnabled && <PagingState {...pagingProps} />}
          {searchEnabled && <CustomPaging {...pagingProps} />}
          {searchEnabled && <FilteringState {...filteringProps} />}
          <CustomTreeData
            getChildRows={getChildRows}
          />
          <Getter
            name='isTreeRowLeaf'
            computed={this.handleIsTreeRowLeaf}
          />
          <GridTable
            headComponent={HeadComponent(headClasses)}
            tableComponent={TableComponent(tableClasses, testId)}
            rowComponent={TableRowComponent}
            messages={noRowsText ? { noData: noRowsText } : tableLocalizationMessages}
            height={750}
            columnExtensions={columnSpecs.columnWidths}
            cellComponent={TableCellComponent}
            noDataCellComponent={TableNoDataCell}
          />

          {getCustomFormatters(columnSpecs)}
          {renderToolbarContent && <Toolbar />}
          {renderToolbarContent && <ToolbarContent renderToolbarContent={renderToolbarContent} />}
          <TableHeaderRow
            cellComponent={HeadCellComponent}
          />
          {!!columnSpecs.columnBands.length && (
            <TableBandHeader
              columnBands={columnSpecs.columnBands}
            />
          )}
          {enableInlineEdit && (
            <TableInlineCellEditing
              cellComponent={getEditCell(columnSpecs.editingStates, null, this.state.validationErrors)}
            />
          )}
          {searchEnabled && <PagingPanel pageSizes={tableOptions.defaultLimits} />}
          <TableTreeColumn
            for={columnSpecs.columns[0].name}
            expandButtonComponent={ExpandButton}
          />
        </Grid>
      </div>
    )
  }

  static propTypes = {
    ...commonPropTypes,
    rootId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    parentIdField: PropTypes.string,
    filterChildRow: PropTypes.func,
    onRowExpanded: PropTypes.func,
    useNestedItems: PropTypes.bool,
    isTreeRowLeaf: PropTypes.func,
    findFromRows: PropTypes.func,
    allExpandableRowIds: PropTypes.array,
    showExpandAndCollapseAllButtons: PropTypes.bool
  }
}

export default DataTree
