import { all, takeEvery, takeLatest } from 'redux-saga/effects'

import employeeRoleActions from '../actions/employeeRoleActions'
import {
  createEmployeeRole,
  deleteEmployeeRole,
  fetchEmployeeRoles,
  updateEmployeeRole
} from '../api/employeeRoleApi'
import {
  createFlow,
  createSocketWatcher,
  deleteFlow,
  fetchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

const watchOnEmployeeRoleSockets = createSocketWatcher('employeeRole', {
  created: employeeRoleActions.createSuccess,
  updated: employeeRoleActions.updateSuccess
})

const employeeRoleFetchFlow = fetchFlow({
  fetchApi: fetchEmployeeRoles,
  actions: employeeRoleActions,
  base: 'employeeRoles',
  errorMsg: 'roolien'
})

const employeeRoleUpdateFlow = updateFlow(updateEmployeeRole, employeeRoleActions, 'Rooli', 'Roolin')
const employeeRoleCreateFlow = createFlow(createEmployeeRole, employeeRoleActions, 'Rooli', 'Roolin')
const employeeRoleDeleteFlow = deleteFlow({
  deleteApi: deleteEmployeeRole,
  actions: employeeRoleActions,
  singular: 'Rooli',
  errorMsg: 'Roolin',
  base: 'employeeRoles'
})

export default function* employeeRoleSaga() {
  yield takeLatest(employeeRoleActions.actionTypes.fetchRequest, employeeRoleFetchFlow)
  yield takeEvery(employeeRoleActions.actionTypes.updateRequest, employeeRoleUpdateFlow)
  yield takeEvery(employeeRoleActions.actionTypes.createRequest, employeeRoleCreateFlow)
  yield takeEvery(employeeRoleActions.actionTypes.deleteRequest, employeeRoleDeleteFlow)

  yield all([
    watchOnEmployeeRoleSockets()
  ])
}
