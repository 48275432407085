import PropTypes from 'prop-types'

import workActions from '../../actions/workActions'
import { vatTypes, workPatchActions } from '../../constants'
import { usePromisifyActionCallback } from '../../hooks/useActions'
import { vatTypePropType } from '../../propTypes'
import SwitchVatPrompt from './SwitchVatPromptBody'

const SwitchWorkVatPrompt = ({ visible, data, hideActionPrompt }) => {
  const switchVatTypeAction = usePromisifyActionCallback(workActions.doPatchAction)

  const switchVatType = async vatType => {
    // Posting original vatType resets it
    const newVatType = vatType || data.vatType
    const action = newVatType === vatTypes.reversedVat
      ? workPatchActions.MARK_REVERSED_VAT
      : newVatType === vatTypes.euSales
        ? workPatchActions.MARK_EU_SALES
        : null
    if(action) {
      await switchVatTypeAction(null, { workId: data.workId, action })
    }
    hideActionPrompt()
  }

  return (
    <SwitchVatPrompt
      hideActionPrompt={hideActionPrompt}
      switchVatType={switchVatType}
      visible={visible}
      customerVatType={data.customerVatType}
      vatType={data.vatType}
      type='work'
    />
  )
}

SwitchWorkVatPrompt.propTypes = {
  visible: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    workId: PropTypes.number.isRequired,
    customerVatType: vatTypePropType,
    vatType: vatTypePropType
  }),
  hideActionPrompt: PropTypes.func.isRequired
}

export default SwitchWorkVatPrompt
