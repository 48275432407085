import { flushSync } from 'react-dom'

export const handleSubmit = function(event, saveAction, additionalSaveData) {
  if(event) {
    event.preventDefault()
    event.stopPropagation()
  }
  flushSync(() => this.setState({ validationErrors: {}, error: null, isDirty: false, isSaving: true }))
  return saveAction(this.state.model, this.props.model, additionalSaveData)
    .then(savedModel => {
      if(Array.isArray(savedModel)) {
        const [model, handled] = savedModel
        savedModel = model
        if(handled) {
          return savedModel
        }
      }
      if(this.isFormMounted.current) {
        this.setState({ model: savedModel, isDirty: false, isSaving: false })
      }
      return savedModel
    }).catch(err => {
      if(!this.isFormMounted.current) {
        return
      }
      this.setState({ isDirty: true, isSaving: false })
      if(err.validationErrors) {
        return this.setState({ validationErrors: err.validationErrors })
      } else {
        return this.setState({ error: err.json })
      }
    })
}
