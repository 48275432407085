import { now } from '@evelia/helpers/dateHelpers'
import reduxCrud from 'redux-crud-mb'

import constants from '../constants'

const actionTypes = reduxCrud.actionTypesFor(constants.actionKeys.DASHBOARD)

const initialState = {
  busy: true,
  invoiceBalance: [],
  inboundInvoiceBalance: [],
  invoiceDifference: {},
  inboundInvoiceDifference: {},
  workStats: {},
  workRecordStats: {},
  countStats: {},
  receivableInvoiceStats: [],
  payableInboundInvoiceStats: [],
  lastFetched: null,
  error: null
}

const dashboardReducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.fetchSuccess: {
      return {
        ...action.records,
        busy: false,
        lastFetched: now()
      }
    }
    case actionTypes.fetchStart:
      return {
        ...state,
        busy: true
      }
    case actionTypes.fetchError:
      return {
        ...state,
        busy: false,
        error: action.error
      }
    default:
      return state
  }
}

export default dashboardReducer
