import React from 'react'
import PropTypes from 'prop-types'

import Tooltip from './Tooltip'

export const generateTooltipId = id => `tooltip-${id.replace(' ', '-').replace(/\W/g, '_')}`

const TooltipLabel = React.memo(({
  label,
  tooltipId,
  message,
  placement,
  icon,
  className
}) => (
  <>
    {label}<Tooltip
      id={generateTooltipId(tooltipId ?? label)}
      message={message}
      placement={placement}
      icon={icon}
      className={className}
           />
  </>
))
TooltipLabel.propTypes = {
  label: PropTypes.node.isRequired,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  placement: PropTypes.string,
  icon: PropTypes.object,
  className: PropTypes.string,
  tooltipId: PropTypes.string
}

export default TooltipLabel
