import { parseTemplate } from 'url-template'

import { del, get, post, put } from '../helpers/apiHelpers'

const baseUrl = '/api/fill_out_form/{fillOutFormType}'
const baseWorkUrl = '/api/work/{workId}/fill_out_forms{/fillOutFormType}{/fillOutFormId}'
const baseWorkPdfUrl = '/api/work/{workId}/fill_out_forms{/fillOutFormType}{/fillOutFormId}/pdf'

const fillOutFormUrlTemplate = parseTemplate(baseUrl)
const fillOutFormWorkUrlTemplate = parseTemplate(baseWorkUrl)
const fillOutFormWorkPdfUrLTemplate = parseTemplate(baseWorkPdfUrl)

export const fetchFillOutForm = fillOutForm =>
  get(fillOutFormWorkUrlTemplate.expand({ workId: fillOutForm.workId }))

export const createFillOutForm = fillOutForm =>
  post(fillOutFormWorkUrlTemplate.expand({ workId: fillOutForm.workId, fillOutFormType: fillOutForm.fillOutFormType }), {
    body: JSON.stringify(fillOutForm)
  })

export const updateFillOutForm = fillOutForm =>
  put(fillOutFormWorkUrlTemplate.expand({ workId: fillOutForm.workId, fillOutFormType: fillOutForm.fillOutFormType, fillOutFormId: fillOutForm.id }), {
    body: JSON.stringify(fillOutForm)
  })
export const deleteFillOutForm = fillOutForm =>
  del(fillOutFormWorkUrlTemplate.expand({ workId: fillOutForm.workId, fillOutFormType: fillOutForm.fillOutFormType, fillOutFormId: fillOutForm.id }), {
    body: JSON.stringify(fillOutForm)
  })

export const createFillOutFormPdf = fillOutForm =>
  post(fillOutFormWorkPdfUrLTemplate.expand({ workId: fillOutForm.workId, fillOutFormType: fillOutForm.fillOutFormType, fillOutFormId: fillOutForm.fillOutFormId }), {
    body: JSON.stringify(fillOutForm)
  })

export const createFillOutFormSimplePdf = fillOutForm =>
  post(fillOutFormUrlTemplate.expand({ fillOutFormType: fillOutForm.fillOutFormType }), {
    body: JSON.stringify(fillOutForm)
  })
