import { Component } from 'react'
import { connect } from 'react-redux'

import targetActions from '../../actions/targetActions'
import { renderPostalSearchItemInline } from '../../components/Helpers/searchResultRenderers'
import { getTargetsFromSearchResult } from '../../selectors/targetSelectors'
import SearchResults, { searchResultsPropType } from './SearchResults'

class TargetResults extends Component {
  renderTarget = item => renderPostalSearchItemInline(item, 'targetNumber')
  render() {
    return (
      <SearchResults
        title='Kohteet'
        renderResult={this.renderTarget}
        {...this.props}
      />
    )
  }

  static propTypes = searchResultsPropType
}

const mapStateToProps = state => {
  return {
    results: getTargetsFromSearchResult(state),
    isBusy: state.targets.search.isBusy
  }
}

const mapDispatchToProps = dispatch => {
  return {
    navigateTo: targetId => dispatch(targetActions.navigateTo(targetId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TargetResults)
