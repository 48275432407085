import {
  faComment,
  faEuroSign
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CommentEur = props => (
  <FontAwesomeIcon {...props} icon={faEuroSign} mask={faComment} transform='shrink-7' />
)

export default CommentEur
