import { all, put, takeEvery, takeLatest } from 'redux-saga/effects'

import workActions from '../actions/workActions'
import workQuickFilterActions from '../actions/workQuickFilterActions'
import { workQuickFilterApi } from '../api/workQuickFilterApi'
import {
  createFlow,
  createSocketWatcher,
  deleteFlow,
  fetchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

const watchOnworkQuickFilterSockets = createSocketWatcher('workQuickFilter', {
  created: workQuickFilterActions.createSuccess,
  updated: workQuickFilterActions.updateSuccess,
  deleted: workQuickFilterActions.deleteSuccess
})

const handleWorkQuickFilterApiResponse = () =>
  function* (workQuickFilters) {
    yield put(workQuickFilterActions.fetchSuccess(workQuickFilters))
    if(workQuickFilters && workQuickFilters.length) {
      yield all(workQuickFilters.map(filter => put(workActions.tableActions.createOption(`workQuickFilterTab${filter.id}`))))
    }
    if(workQuickFilters.id) {
      yield put(workActions.tableActions.createOption(`workQuickFilterTab${workQuickFilters.id}`))
    }
    return workQuickFilters
  }

const workQuickFilterFetchFlow = fetchFlow({
  fetchApi: workQuickFilterApi.fetch,
  actions: workQuickFilterActions,
  base: 'workQuickFilters',
  errorMsg: 'Pikasuodattimien',
  apiResponseHandler: handleWorkQuickFilterApiResponse(workQuickFilterActions.fetchSuccess)
})

const workQuickFilterUpdateFlow = updateFlow(workQuickFilterApi.update, workQuickFilterActions, 'Pikasuodatin', 'Pikasuodattimen')
const workQuickFilterCreateFlow = createFlow(workQuickFilterApi.create, workQuickFilterActions, 'Pikasuodatin', 'Pikasuodattimen')
const workQuickFilterDeleteFlow = deleteFlow({
  deleteApi: workQuickFilterApi.remove,
  actions: workQuickFilterActions,
  singular: 'Pikasuodatin',
  errorMsg: 'Pikasuodattimen',
  base: 'workQuickFilters'
})

export default function* workQuickFilterSaga() {
  yield takeLatest(workQuickFilterActions.actionTypes.fetchRequest, workQuickFilterFetchFlow)
  yield takeEvery(workQuickFilterActions.actionTypes.updateRequest, workQuickFilterUpdateFlow)
  yield takeEvery(workQuickFilterActions.actionTypes.createRequest, workQuickFilterCreateFlow)
  yield takeEvery(workQuickFilterActions.actionTypes.deleteRequest, workQuickFilterDeleteFlow)

  yield all([
    watchOnworkQuickFilterSockets()
  ])
}
