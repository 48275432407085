import { createApi } from '../helpers/apiHelpers'
import { parseFilterParams, parseTableOptionsFromQuery } from '../helpers/reducerHelpers'
import { normalizeEmployeeResponse } from './employeeApi'
import { normalizeMachines } from './machineApi'
import { optionsQueryBase } from './urlTemplates'
import { normalizeWorkList } from './workApi'

export const normalizeMachineRecords = response => {
  const data = response.record || response.records
  const {
    work,
    machines,
    employees,
    options
  } = response._embedded || {}

  return {
    data,
    work: work ? normalizeWorkList(work) : null,
    machines: machines ? normalizeMachines(machines) : null,
    employees: employees ? normalizeEmployeeResponse(employees) : null,
    tableOptions: parseTableOptionsFromQuery(options)
  }
}

export const machineRecordApi = createApi({
  base: 'machine_records',
  normalizer: normalizeMachineRecords,
  extraUrlTemplate: optionsQueryBase,
  editGetParams: params =>
    params.workId
      ? {
          ...params,
          base: 'work',
          id: params.workId,
          subItem: 'machine_records'
        }
      : parseFilterParams(params)
})

export const startMachineRecord = data => machineRecordApi.create(data, { subItem: 'start' })
export const stopMachineRecord = data => machineRecordApi.update(data, { id: data.id, subItem: 'stop' })
