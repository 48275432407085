import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as Sentry from '@sentry/react'
import debounce from 'lodash/debounce'
import noop from 'lodash/noop'

import { keyCodes } from '../../constants'
import { checkIfEnter } from '../../helpers/helpers'
import { selectionHandler } from './inputs/TypeaheadFormInput'
import TypeaheadFormGroup from './TypeaheadFormGroup'

class SearchFormGroup extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      results: props.selected ? props.selected : [],
      isMenuOpen: false
    }
  }

  search = query => {
    this.setState({ isLoading: true })
    return new Promise((resolve, reject) => this.props.dispatch(this.props.searchAction(query, { resolve, reject })))
      .then(results => this.props.filterResults?.(results) ?? results)
      .then(results => {
        if(results.length && Array.isArray(results[0])) { // fix for search action to resolve with embeddeds
          results = results[0]
        }
        return this.setState({
          isLoading: false,
          results
        })
      }).catch(Sentry.captureException)
  }

  debouncedSearch = debounce(this.search, 200, { leading: true, trailing: false })

  render() {
    const {
      filterBy,
      handleOnChange,
      ...typeaheadProps
    } = this.props
    const { isLoading, results } = this.state
    return (
      <TypeaheadFormGroup
        {...typeaheadProps}
        async
        onSearch={this.search}
        onKeyDown={this.handleKeyDown}
        onMenuToggle={this.handleMenuToggle}
        isLoading={isLoading}
        handleOnChange={this.handleOnChange}
        options={results}
        filterBy={filterBy || Boolean}
        onFocus={this.onFocus}
      />
    )
  }

  onFocus = (...args) => {
    const { value, searchOnFocus, onFocus } = this.props
    if(value && searchOnFocus) {
      this.debouncedSearch(value)
    }
    if(onFocus) {
      return onFocus(...args)
    }
  }

  handleOnChange = ([selected]) => selectionHandler(selected, this.props, true)

  handleMenuToggle = isMenuOpen => {
    this.setState({ isMenuOpen })
  }

  handleKeyDown = event => {
    if(this.props.onKeyDown) {
      return this.props.onKeyDown(event)
    }
    if(event.keyCode === keyCodes.ESC) {
      return this.props.onEscPressed(event)
    } else if((!this.state.isMenuOpen || !this.state.results.length) && checkIfEnter(event)) {
      return this.props.onEnterPressed(event)
    }
    return event
  }

  static propTypes = {
    field: PropTypes.string.isRequired,
    label: PropTypes.node,
    value: PropTypes.string,
    helpText: PropTypes.string,
    handleOnChange: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    emptyLabel: PropTypes.string,
    promptText: PropTypes.string,
    searchText: PropTypes.string,
    labelKey: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func
    ]),
    dispatch: PropTypes.func.isRequired,
    searchAction: PropTypes.func.isRequired,
    selected: PropTypes.array,
    allowNew: PropTypes.bool,
    handleNew: PropTypes.func,
    validationErrors: PropTypes.object,
    filterBy: PropTypes.func,
    renderMenu: PropTypes.func,
    renderMenuItemChildren: PropTypes.func,
    placeholder: PropTypes.string,
    onEnterPressed: PropTypes.func,
    onEscPressed: PropTypes.func,
    autoFocus: PropTypes.bool,
    dropup: PropTypes.bool,
    flip: PropTypes.bool,
    model: PropTypes.object.isRequired,
    setNull: PropTypes.bool,
    isRequired: PropTypes.bool,
    multiple: PropTypes.bool,
    setRef: PropTypes.object,
    menuClassName: PropTypes.string,
    disabled: PropTypes.bool,
    onKeyDown: PropTypes.func,
    onBlur: PropTypes.func,
    formGroupClassName: PropTypes.string,
    getSelected: PropTypes.func,
    searchOnFocus: PropTypes.bool,
    onFocus: PropTypes.func,
    filterResults: PropTypes.func
  }

  static defaultProps = {
    validationErrors: {},
    setNull: false,
    emptyLabel: 'Ei tuloksia',
    promptText: 'Aloita haku kirjoittamalla',
    searchText: 'Etsitään...',
    labelKey: 'name',
    selected: [],
    allowNew: false,
    handleInputChange: noop,
    onEnterPressed: noop,
    onEscPressed: noop,
    getSelected: value => [],
    onChange: noop
  }
}

export default connect()(SearchFormGroup) // connected to have access to dispatch
