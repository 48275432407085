import { useCallback, useMemo, useRef, useState } from 'react'
import { deepEqual } from 'fast-equals'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'

import { throwIfNotInProduction } from '../../helpers/errors'

const hasNoFilter = filters => {
  if(isObject(filters)) {
    return Object.keys(filters).every(filterKey => filters[filterKey].length === 0)
  }
  if(isString(filters)) {
    return filters.length === 0
  }
  throwIfNotInProduction(`Received not implemented filter type: ${typeof filters}, ${JSON.stringify(filters)}`)
}

export const useExpand = (rows, filters, getSubRow, defaultExpand = {}) => {
  const [expanded, setExpanded] = useState(defaultExpand)
  const prevExpanded = useRef(expanded)
  const prevFilters = useRef(filters)
  const isPreviousSet = useRef(false)

  const allExpandedState = useMemo(() => {
    const recursiveSetTrue = (children, prefix = '') => !children
      ? {}
      : children.reduce((acc, file, idx) =>
        ({
          ...acc,
          [`${prefix}${idx}`]: true,
          ...recursiveSetTrue(getSubRow(file), `${prefix}${idx}.`)
        }),
      {})
    return recursiveSetTrue(rows)
  }, [rows, getSubRow])

  const expandAll = useCallback(() => {
    setExpanded(allExpandedState)
  }, [allExpandedState])

  const collapseAll = useCallback(() => setExpanded({}), [setExpanded])

  const expandedState = useMemo(() => {
    const hasFiltersChanged = !deepEqual(filters, prevFilters.current)
    prevFilters.current = filters
    if(!hasFiltersChanged) {
      return expanded
    }

    const noFilters = hasNoFilter(filters)
    if(noFilters) {
      setExpanded(prevExpanded.current)
      isPreviousSet.current = false
      return prevExpanded.current
    }

    if(!isPreviousSet.current) {
      prevExpanded.current = expanded
      expandAll()
      isPreviousSet.current = true
      return allExpandedState
    }
    return expanded
  }, [filters, expanded, expandAll, allExpandedState])

  return {
    expanded: expandedState,
    setExpanded,
    expandAll,
    collapseAll
  }
}

export default useExpand
