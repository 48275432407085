import { ActionCreator } from '@reduxjs/toolkit'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { useTypedDispatch } from '../reducerTypes'

export interface UseFetchProps {
  data?: Record<string, unknown>
  force?: boolean
  disabled?: boolean
}

export type FetchActionProp = ActionCreator<Record<string, unknown>>

const useFetch = <T extends FetchActionProp>(fetchAction: T, props: UseFetchProps = {}) => {
  const { data, force, disabled } = props
  const dispatch = useTypedDispatch()

  useDeepCompareEffect(() => {
    if(!disabled) {
      dispatch(fetchAction({ force, ...data }))
    }
  }, [data, disabled, dispatch, fetchAction, force])
}

export default useFetch
