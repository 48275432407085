import { useMemo } from 'react'

const useIdValueOptions = options => useMemo(() => options
  ? options.map(option => ({
    id: option.value,
    text: option.text
  }))
  : options, [options])

export default useIdValueOptions
