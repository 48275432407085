import {
  faFile,
  faFileExcel,
  faFilePdf,
  faFileVideo,
  faFileWord
} from '@fortawesome/free-solid-svg-icons'

export const mimeTypes = {
  pdf: 'application/pdf',
  mp4: 'video/mp4',
  word: 'application/msword',
  excel: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  excel2: 'application/vnd.ms-excel'
}

export const excelMimeTypes = {
  [mimeTypes.excel]: ['.xlsx'],
  [mimeTypes.excel2]: ['.xls']
}

export const imageMimeTypes = {
  'image/*': []
}

export const checkFileType = fileType => {
  if(fileType === mimeTypes.pdf) {
    return faFilePdf
  } else if(fileType === mimeTypes.mp4) {
    return faFileVideo
  } else if(fileType === mimeTypes.word) {
    return faFileWord
  } else if(fileType === mimeTypes.excel) {
    return faFileExcel
  }
  return faFile
}

export const checkIsAllowedFileType = (fileName, allowedFileExtensions) => {
  const nameParts = fileName.split('.')
  return allowedFileExtensions.includes(nameParts[nameParts.length - 1])
}
