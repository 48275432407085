import { all, takeEvery, takeLatest } from 'redux-saga/effects'

import offerPostStateActions from '../actions/offerPostStateActions'
import { offerPostStateApi } from '../api/offerPostStateApi'
import {
  createFlow,
  createSocketWatcher,
  deleteFlow,
  fetchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

const watchOnOfferPostStateSockets = createSocketWatcher('offerPostState', {
  created: offerPostStateActions.createSuccess,
  updated: offerPostStateActions.updateSuccess,
  deleted: offerPostStateActions.deleteSuccess
})

const offerPostStateFetchFlow = fetchFlow({
  fetchApi: offerPostStateApi.fetch,
  actions: offerPostStateActions,
  base: 'offerPostStates',
  idField: 'id',
  errorMsg: 'postin tilojen'
})

const offerPostStateUpdateFlow = updateFlow(offerPostStateApi.update, offerPostStateActions, 'Postin tila', 'Postin tilan')
const offerPostStateCreateFlow = createFlow(offerPostStateApi.create, offerPostStateActions, 'Postin tila', 'Postin tilan')
const offerPostStateDeleteFlow = deleteFlow({
  deleteApi: offerPostStateApi.remove,
  actions: offerPostStateActions,
  singular: 'Postin tila',
  errorMsg: 'Postin tilan',
  base: 'offerPostStates'
})

export default function* offerPostStateSaga() {
  yield takeLatest(offerPostStateActions.actionTypes.fetchRequest, offerPostStateFetchFlow)
  yield takeEvery(offerPostStateActions.actionTypes.updateRequest, offerPostStateUpdateFlow)
  yield takeEvery(offerPostStateActions.actionTypes.createRequest, offerPostStateCreateFlow)
  yield takeEvery(offerPostStateActions.actionTypes.deleteRequest, offerPostStateDeleteFlow)
  yield all([
    watchOnOfferPostStateSockets()
  ])
}
