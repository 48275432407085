import { all, takeEvery, takeLatest } from 'redux-saga/effects'

import formTypeActions from '../actions/formTypeActions'
import { formTypeApi } from '../api/formTypeApi'
import {
  createFlow,
  createSocketWatcher,
  deleteFlow,
  fetchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

const watchOnFormTypeSockets = createSocketWatcher('formType', {
  created: formTypeActions.createSuccess,
  updated: formTypeActions.updateSuccess,
  deleted: formTypeActions.deleteSuccess
})

const formTypeFetchFlow = fetchFlow({
  fetchApi: formTypeApi.fetch,
  actions: formTypeActions,
  base: 'formTypes',
  errorMsg: 'Lomaketyyppien'
})

const formTypeUpdateFlow = updateFlow(formTypeApi.update, formTypeActions, 'Lomaketyyppi', 'Lomaketyypin')
const formTypeCreateFlow = createFlow(formTypeApi.create, formTypeActions, 'Lomaketyyppi', 'Lomaketyypin')
const formTypeDeleteFlow = deleteFlow({
  deleteApi: formTypeApi.remove,
  actions: formTypeActions,
  singular: 'Lomaketyyppi',
  errorMsg: 'Lomaketyypin',
  base: 'formTypes'
})

export default function* formTypeSaga() {
  yield takeLatest(formTypeActions.actionTypes.fetchRequest, formTypeFetchFlow)
  yield takeEvery(formTypeActions.actionTypes.updateRequest, formTypeUpdateFlow)
  yield takeEvery(formTypeActions.actionTypes.createRequest, formTypeCreateFlow)
  yield takeEvery(formTypeActions.actionTypes.deleteRequest, formTypeDeleteFlow)

  yield all([
    watchOnFormTypeSockets()
  ])
}
