import { actionTypes } from '../constants'
import { error, request, start, success } from '../helpers/actionHelpers'

const configActions = {
  fetchRequest: request(actionTypes.CONFIGS_FETCH_REQUEST),
  fetchStart: start(actionTypes.CONFIGS_FETCH_START),
  fetchSuccess: success(actionTypes.CONFIGS_FETCH_SUCCESS),
  fetchError: error(actionTypes.CONFIGS_FETCH_ERROR)
}

export default configActions
