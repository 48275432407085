import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import denominationActions from '../../actions/denominationActions'
import { actionKeys, denominationTypes } from '../../constants'
import {
  chooserTemplatePropType,
  denominationTypePropType,
  formDefaultPropsPropType,
  selectOptionsPropType
} from '../../propTypes'
import { findDenominationWithId, formatDenominationText } from '../../selectors/denominationSelectors'
import Feature from '../Authentication/Feature'
import { SimpleDetails } from '../Details'
import { HiddenValueFormGroup } from '../FormGroups'
import BaseChooser from './BaseChooser'
import { emptyDefaultProps } from './chooserHelpers'
import SelectedBlock, { getUnset } from './SelectedBlock'
import useIdValueOptions from './useIdValueOptions'

const DenominationTemplate = ({
  item,
  disabled,
  label = 'Littera',
  isRequired = false,
  fieldName,
  updateField
}) => {
  return (
    <SelectedBlock
      title={label}
      isRequired={isRequired}
      disabled={disabled || !fieldName}
      unset={getUnset(updateField, fieldName)}
      testId={`${fieldName}Detail`}
    >
      {item
        ? <SimpleDetails text={formatDenominationText(item)} />
        : 'Ei litteraa'}
    </SelectedBlock>
  )
}

DenominationTemplate.propTypes = chooserTemplatePropType

const formatOptionText = option => option.text

export const useDenominationSearchAction = (denominationType = denominationTypes.DENOMINATION_TYPE_WORK) => {
  return useMemo(() => (searchTerm, data) =>
    denominationActions.searchRequest(searchTerm, {
      ...data,
      filters: {
        type: denominationType
      }
    }), [denominationType])
}

const DenominationChooser = ({
  denominationId,
  denominationType,
  options,
  defaultValue,
  fieldName = 'denominationId',
  label = 'Littera',
  defaultProps = emptyDefaultProps,
  ...props
}) => {
  const denomination = useSelector(state => findDenominationWithId(state, denominationId === undefined ? defaultValue : denominationId))
  const searchAction = useDenominationSearchAction(denominationType)
  const formattedOptions = useIdValueOptions(options)
  return (
    <Feature
      featureKey={actionKeys.DENOMINATIONS}
      fallback={<HiddenValueFormGroup field={fieldName} setNull {...defaultProps} />}
      ignoreAccessLevel
    >
      <BaseChooser
        {...props}
        fieldName={fieldName}
        label={label}
        defaultProps={defaultProps}
        item={denomination}
        itemId={denomination?.id}
        ItemTemplate={DenominationTemplate}
        labelKey={options ? 'text' : 'name'}
        renderSearchResult={options ? formatOptionText : formatDenominationText}
        searchAction={options ? null : searchAction}
        options={formattedOptions}
        disableNew
      />
    </Feature>
  )
}

DenominationChooser.propTypes = {
  denominationId: PropTypes.number,
  defaultProps: formDefaultPropsPropType,
  fieldName: PropTypes.string.isRequired,
  denominationType: denominationTypePropType,
  options: selectOptionsPropType,
  defaultValue: PropTypes.number,
  label: PropTypes.node
}

export default DenominationChooser
