import { all, takeEvery, takeLatest } from 'redux-saga/effects'

import cashFlowForecastExtraValueActions from '../actions/cashFlowForecastExtraValueActions'
import { cashFlowForecastExtraValueApi } from '../api/cashFlowForecastExtraValueApi'
import {
  createFlow,
  createSocketWatcher,
  deleteFlow,
  fetchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

const watchOnCashFlowForecastExtraValueSockets = createSocketWatcher('cashFlowForecastExtraValue', {
  created: cashFlowForecastExtraValueActions.createSuccess,
  updated: cashFlowForecastExtraValueActions.updateSuccess,
  deleted: cashFlowForecastExtraValueActions.deleteSuccess
})

const cashFlowForecastExtraValueFetchFlow = fetchFlow({
  fetchApi: cashFlowForecastExtraValueApi.fetch,
  actions: cashFlowForecastExtraValueActions,
  base: 'cashFlowForecastExtraValues',
  errorMsg: 'Kassavirtaennusteen asetusten'
})

const cashFlowForecastExtraValueUpdateFlow = updateFlow(cashFlowForecastExtraValueApi.update, cashFlowForecastExtraValueActions, 'Kassavirtaennusteen lisäarvo', 'Kassavirtaennusteen lisäarvon')
const cashFlowForecastExtraValueCreateFlow = createFlow(cashFlowForecastExtraValueApi.create, cashFlowForecastExtraValueActions, 'Kassavirtaennusteen lisäarvo', 'Kassavirtaennusteen lisäarvon')
const cashFlowForecastExtraValueDeleteFlow = deleteFlow({
  deleteApi: cashFlowForecastExtraValueApi.remove,
  actions: cashFlowForecastExtraValueActions,
  singular: 'Kassavirtaennusteen lisäarvo',
  errorMsg: 'Kassavirtaennusteen lisäarvon',
  base: 'cashFlowForecastExtraValues'
})

export default function* cashFlowForecastExtraValueSaga() {
  yield takeLatest(cashFlowForecastExtraValueActions.actionTypes.fetchRequest, cashFlowForecastExtraValueFetchFlow)
  yield takeEvery(cashFlowForecastExtraValueActions.actionTypes.updateRequest, cashFlowForecastExtraValueUpdateFlow)
  yield takeEvery(cashFlowForecastExtraValueActions.actionTypes.createRequest, cashFlowForecastExtraValueCreateFlow)
  yield takeEvery(cashFlowForecastExtraValueActions.actionTypes.deleteRequest, cashFlowForecastExtraValueDeleteFlow)

  yield all([
    watchOnCashFlowForecastExtraValueSockets()
  ])
}
