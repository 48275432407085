import { all, takeEvery, takeLatest } from 'redux-saga/effects'

import workCustomStateActions from '../actions/workCustomStateActions'
import { workCustomStateApi } from '../api/workCustomStateApi'
import {
  createFlow,
  createSocketWatcher,
  deleteFlow,
  fetchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

const watchOnWorkCustomStateSockets = createSocketWatcher('workCustomState', {
  created: workCustomStateActions.createSuccess,
  updated: workCustomStateActions.updateSuccess,
  deleted: workCustomStateActions.deleteSuccess
})

const workCustomStateFetchFlow = fetchFlow({
  fetchApi: workCustomStateApi.fetch,
  actions: workCustomStateActions,
  base: 'workCustomStates',
  idField: 'id',
  errorMsg: 'työn lisätilojen'
})

const workCustomStateUpdateFlow = updateFlow(workCustomStateApi.update, workCustomStateActions, 'Työn lisätila', 'Työn lisätilan')
const workCustomStateCreateFlow = createFlow(workCustomStateApi.create, workCustomStateActions, 'Työn lisätila', 'Työn lisätilan')
const workCustomStateDeleteFlow = deleteFlow({
  deleteApi: workCustomStateApi.remove,
  actions: workCustomStateActions,
  singular: 'Työn lisätila',
  errorMsg: 'Työn lisätilan',
  base: 'workCustomStates'
})

export default function* workCustomStateSaga() {
  yield takeLatest(workCustomStateActions.actionTypes.fetchRequest, workCustomStateFetchFlow)
  yield takeEvery(workCustomStateActions.actionTypes.updateRequest, workCustomStateUpdateFlow)
  yield takeEvery(workCustomStateActions.actionTypes.createRequest, workCustomStateCreateFlow)
  yield takeEvery(workCustomStateActions.actionTypes.deleteRequest, workCustomStateDeleteFlow)
  yield all([
    watchOnWorkCustomStateSockets()
  ])
}
