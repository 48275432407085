import { createListenerMiddleware, ThunkAction, UnknownAction } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'
import constant from 'lodash/constant'
import isEqual from 'lodash/isEqual'

import ticketActions from '../../actions/ticketActions'
import { ApiResponse, KanbanQuery, KanbanQuickFilter, KanbanQuickFilterCreateBody } from '.'
import { getBaseQuery, kanbanQuery } from './apiHelpers'
import { handleDispatchEmbeddedData, TicketModel, ticketsApi } from './ticketsApi'

export type TicketsKanbanModel = {
  records: TicketModel[]
  totalCount: number
  mainFilter: string
  column: string | number
}

export type TicketsKanbanQuickFilter = KanbanQuickFilter<'tickets'>

export const ticketsKanbanApi = createApi({
  reducerPath: 'ticketsKanbanApi',
  baseQuery: getBaseQuery('tickets/kanban'),
  tagTypes: ['quick_filters'],
  endpoints: builder => ({
    getTicketsKanban: builder.query<ApiResponse<TicketsKanbanModel>, KanbanQuery>({
      query: kanbanQuery,
      serializeQueryArgs: ({ endpointName }) => endpointName,
      forceRefetch: ({ currentArg, previousArg }) => {
        return !isEqual(currentArg?.extraFilters, previousArg?.extraFilters) ||
               !isEqual(currentArg?.sort, previousArg?.sort) ||
               currentArg?.mainFilter !== previousArg?.mainFilter ||
               !isEqual(currentArg?.columns, previousArg?.columns)
      }
    }),
    getTicketsKanbanQuickFilters: builder.query<TicketsKanbanQuickFilter[], void>({
      query: constant('/quick_filters'),
      providesTags: [{ type: 'quick_filters' }]
    }),
    getTicketsKanbanQuickFilter: builder.query<TicketsKanbanQuickFilter, number>({
      query: query => `/quick_filters/${query}`,
      providesTags: (__result, __error, id) => [{ type: 'quick_filters', id }]
    }),
    createTicketsKanbanQuickFilter: builder.mutation<TicketsKanbanQuickFilter, KanbanQuickFilterCreateBody>({
      query: body => ({
        url: '/quick_filters',
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: 'quick_filters' }]
    }),
    updateTicketsKanbanQuickFilter: builder.mutation<TicketsKanbanQuickFilter, TicketsKanbanQuickFilter>({
      query: body => ({
        url: `/quick_filters/${body.id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: (__result, __error, { id }) => [{ type: 'quick_filters' }, { type: 'quick_filters', id }]
    }),
    deleteTicketsKanbanQuickFilter: builder.mutation<void, number>({
      query: id => ({
        url: `/quick_filters/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (__result, __error, id) => [{ type: 'quick_filters' }, { type: 'quick_filters', id }]
    })
  })
})

const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
  matcher: ticketsKanbanApi.endpoints.getTicketsKanban.matchFulfilled,
  effect: async(action, listenerApi) => {
    const { records, _embedded } = action?.payload || {}

    if(records) {
      listenerApi.dispatch(ticketsApi.util.resetApiState())
      for(const record of records) {
        const { mainFilter, column } = record
        const data: ApiResponse<TicketModel> = {
          records: record.records,
          _embedded: {
            options: {
              totalCount: record.totalCount
            }
          }
        }
        const upsertQueryData = ticketsApi.util.upsertQueryData('getTickets', { mainFilter: [mainFilter, column], cacheBust: true }, { ...data })
        // Something weird happens here with typescript
        listenerApi.dispatch(upsertQueryData as ThunkAction<unknown, unknown, unknown, UnknownAction>)
        listenerApi.dispatch(ticketActions.fetchSuccess(record.records))
      }
    }
    if(_embedded) {
      handleDispatchEmbeddedData(listenerApi.dispatch, _embedded)
    }
  }
})

export const {
  useGetTicketsKanbanQuery,
  useGetTicketsKanbanQuickFiltersQuery,
  useUpdateTicketsKanbanQuickFilterMutation,
  useCreateTicketsKanbanQuickFilterMutation,
  useDeleteTicketsKanbanQuickFilterMutation
} = ticketsKanbanApi
export const { middleware: ticketsKanbanApiMiddleware } = listenerMiddleware
