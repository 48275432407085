import { timeRecordSystemTypes } from '@evelia/helpers/constants'
import {
  faBuilding,
  faBuildingCircleArrowRight,
  faHandshake,
  faHeadSideCough,
  faPersonWalkingArrowRight,
  faQuestion,
  faUmbrellaBeach,
  faUtensils
} from '@fortawesome/free-solid-svg-icons'

const timeRecordTypeIcons = {
  AWAY: faPersonWalkingArrowRight,
  WORK_TIME: faBuilding,
  LUNCH: faUtensils,
  SICK_LEAVE: faHeadSideCough,
  CUSTOMER: faHandshake,
  VACATION: faUmbrellaBeach,
  EXTRA_LEAVE: faBuildingCircleArrowRight
}

export const timeRecordTypeIconOptions = Object.entries(timeRecordTypeIcons).map(([key, icon]) => ({ key, icon }))

const getTimeRecordTypeIconFromData = timeRecordType => (timeRecordType.icon ? timeRecordTypeIcons[timeRecordType.icon] : null) ?? null

const getTimeRecordTypeIconFromSystemType = timeRecordType => {
  switch(timeRecordType.systemType) {
    case timeRecordSystemTypes.TIME_RECORD_SYSTEM_TYPE_AWAY:
      return timeRecordTypeIcons.AWAY
    case timeRecordSystemTypes.TIME_RECORD_SYSTEM_TYPE_WORK_TIME:
      return timeRecordTypeIcons.WORK_TIME
    case timeRecordSystemTypes.TIME_RECORD_SYSTEM_TYPE_LUNCH:
      return timeRecordTypeIcons.LUNCH
    case timeRecordSystemTypes.TIME_RECORD_SYSTEM_TYPE_SICK_LEAVE:
    case timeRecordSystemTypes.TIME_RECORD_TYPE_SICK_LEAVE_CHILD_SICK:
    case timeRecordSystemTypes.TIME_RECORD_TYPE_SICK_LEAVE_OCCUPATIONAL_ACCIDENT:
      return timeRecordTypeIcons.SICK_LEAVE
    case timeRecordSystemTypes.TIME_RECORD_SYSTEM_TYPE_CUSTOMER:
      return timeRecordTypeIcons.CUSTOMER
    case timeRecordSystemTypes.TIME_RECORD_TYPE_VACATION_WITHOUT_SALARY:
    case timeRecordSystemTypes.TIME_RECORD_SYSTEM_TYPE_VACATION_WITH_SALARY:
      return timeRecordTypeIcons.VACATION
    case timeRecordSystemTypes.TIME_RECORD_SYSTEM_TYPE_EXTRA_LEAVE:
      return timeRecordTypeIcons.EXTRA_LEAVE
    default:
      return faQuestion
  }
}

export const getTimeRecordTypeIcon = timeRecordType =>
  getTimeRecordTypeIconFromData(timeRecordType) || getTimeRecordTypeIconFromSystemType(timeRecordType)
