import PropTypes from 'prop-types'
import { appendEitherOrEmpty } from '@evelia/helpers/helpers'
import {
  faEuroSign,
  faHandHolding
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const HandHoldingEur = ({ title, className, ...props }) => (
  <span className={appendEitherOrEmpty(className, 'fa-layers fa-fw')} title={title}>
    <FontAwesomeIcon {...props} icon={faHandHolding} />
    <FontAwesomeIcon icon={faEuroSign} transform='right-1 up-4 shrink-5' />
  </span>
)

HandHoldingEur.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string
}

export default HandHoldingEur
