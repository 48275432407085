import { createSelector } from 'reselect'

export const getLastChangedRoute = createSelector(
  state => state.router.history,
  state => state.router.history[state.router.history.length - 1],
  (history, lastHistoryItem) => {
    if(lastHistoryItem) {
      return [...history].reverse().find(historyItem => historyItem.pathname !== lastHistoryItem.pathname)
    }
    return null
  }
)
