import { takeLatest } from 'redux-saga/effects'

import configActions from '../actions/configActions'
import { fetchConfigs } from '../api/configApi'
import { actionTypes } from '../constants'
import { fetchFlow } from '../helpers/sagaHelpers'

const configFetchFlow = fetchFlow({
  fetchApi: fetchConfigs,
  actions: configActions,
  errorMsg: 'Asetusten'
})

export default function* configSaga() {
  yield takeLatest(actionTypes.CONFIGS_FETCH_REQUEST, configFetchFlow)
}
