import { useSelector } from 'react-redux'
import { faBullhorn } from '@fortawesome/free-solid-svg-icons'

import systemMessageActions from '../../actions/systemMessageActions'
import useFetch from '../../hooks/useFetch'
import useTapLocationChange from '../../hooks/useTapLocationChange'
import useToggle from '../../hooks/useToggle'
import { getSystemMessages } from '../../selectors/systemMessageSelectors'
import IconButton from '../Buttons/IconButton'
import CountBadgeIcon from '../Icons/CountBadgeIcon'
import SystemMessageNotificationDropdown from './SystemMessageNotificationDropdown'

const NotificationsButton = () => {
  const [isOpen, { toggle, disable }] = useToggle()
  useFetch(systemMessageActions.fetchRequest)
  const systemMessages = useSelector(getSystemMessages)

  useTapLocationChange(disable, isOpen)
  return (
    <div className='position-relative'>
      <IconButton
        IconComponent={CountBadgeIcon}
        iconProps={{ count: systemMessages.length, hideZero: true }}
        icon={faBullhorn}
        color='primary'
        onClick={toggle}
        testId='notificationsButton'
        size='md'
      />
      {isOpen
        ? <SystemMessageNotificationDropdown />
        : null}
    </div>
  )
}

export default NotificationsButton
