import { accessLevels } from '../../constants'
import withPermission from './withPermission'

const SuperAdminPermission = withPermission(accessLevels.SUPERADMIN)
const AdminPermission = withPermission(accessLevels.ADMIN)
const AdministrationPermission = withPermission(accessLevels.ADMINISTRATION)
const FinancePermission = withPermission(accessLevels.FINANCE)
const SupervisorPermission = withPermission(accessLevels.SUPERVISOR)
const UserPermission = withPermission(accessLevels.USER)
const RestrictedUserPermission = withPermission(accessLevels.RESTRICTED_USER)
const UserWithoutPricePermission = withPermission(accessLevels.USER_WITHOUT_PRICES)
const SubcontractorPermission = withPermission(accessLevels.SUBCONTRACTOR)
const AllPermission = withPermission(accessLevels.ALL)

const permissions = {
  [accessLevels.SUPERADMIN]: SuperAdminPermission,
  [accessLevels.ADMIN]: AdminPermission,
  [accessLevels.ADMINISTRATION]: AdministrationPermission,
  [accessLevels.FINANCE]: FinancePermission,
  [accessLevels.SUPERVISOR]: SupervisorPermission,
  [accessLevels.USER]: UserPermission,
  [accessLevels.RESTRICTED_USER]: RestrictedUserPermission,
  [accessLevels.USER_WITHOUT_PRICES]: UserWithoutPricePermission,
  [accessLevels.SUBCONTRACTOR]: SubcontractorPermission,
  [accessLevels.ALL]: AllPermission
}

const getPermissionWrapperByAccessLevel = accessLevel => permissions[accessLevel] ?? AllPermission

export {
  SuperAdminPermission,
  AdminPermission,
  AdministrationPermission,
  FinancePermission,
  SupervisorPermission,
  UserPermission,
  RestrictedUserPermission,
  UserWithoutPricePermission,
  SubcontractorPermission,
  AllPermission,
  getPermissionWrapperByAccessLevel
}
