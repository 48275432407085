import { createApi } from '../helpers/apiHelpers'
import { parseFilterParams } from '../helpers/reducerHelpers'
import { optionsQueryBase } from './urlTemplates'

export const normalizeWorkMachines = response => {
  const data = response.record || response.records
  return {
    data
  }
}

export const workMachineApi = createApi({
  base: 'calendar_events/work_machines',
  normalizer: normalizeWorkMachines,
  extraUrlTemplate: optionsQueryBase,
  editGetParams: parseFilterParams
})
