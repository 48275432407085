import { FormGroup, FormText, Label } from 'reactstrap'
import clsx from 'clsx'

import Tooltip from '../Tooltip'
import { generateTooltipId } from '../Tooltip/TooltipLabel'

interface FormInputWrapperProps {
  name: string
  label?: React.ReactNode
  inputOnly?: boolean
  required?: boolean
  helpText?: React.ReactNode
  check?: boolean
  isSwitch?: boolean
  inline?: boolean
  labelClassName?: string
  formGroupClassName?: string
  children?: React.ReactNode
  tag?: React.ElementType
  tooltip?: React.ReactNode
}

const FormInputWrapper = ({
  name,
  label,
  inputOnly,
  check,
  isSwitch,
  required,
  children,
  formGroupClassName,
  inline,
  labelClassName,
  tag,
  tooltip,
  helpText
}: FormInputWrapperProps) => {
  return inputOnly
    ? children
    : (
      // FormGroup always adds mb-3 to className...
        (
          <FormGroup
            className={clsx('input-form-group mb-0 mb-sm-1 position-relative',
              formGroupClassName,
              inline && 'd-flex align-items-center')}
            check={check}
            switch={isSwitch}
            data-testid={`inputWrapper-${name}`}
            tag={tag}
          >
            {label
              ? (
                <Label
                  for={name}
                  size='sm'
                  className={labelClassName}
                  check={check || isSwitch}
                >
                  {label}
                  {required
                    ? <span className='text-danger'>*</span>
                    : null}
                  {tooltip && <Tooltip id={generateTooltipId(name)} message={tooltip} />}
                </Label>
                )
              : null}
            {inline
              ? <span className='flex-fill'>{children}</span>
              : children}
            {helpText ? <FormText>{helpText}</FormText> : null}
          </FormGroup>
        )
      )
}

export default FormInputWrapper
