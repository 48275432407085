import { createApi } from '../helpers/apiHelpers'

const normalizeMachineLogs = response => {
  const data = response.record || response.records
  return {
    data
  }
}

const editGetParams = params => ({ ...params, id: params.machineId, subItem: 'machine_logs' })
const editMutateParams = (params, data) => ({ id: params.machineId ?? data.machineId, subItemId: params.id, subItem: 'machine_logs' })

export const machineLogApi = createApi({
  base: 'machines',
  normalizer: normalizeMachineLogs,
  editGetParams,
  editMutateParams
})
