import { all, put, takeEvery, takeLatest } from 'redux-saga/effects'

import costCentreActions from '../actions/costCentreActions'
import { costCentreApi, costCentreGroupApi, normalizeCostCentres } from '../api/costCentreApi'
import {
  createFlow,
  createSocketWatcherWithApiHandlerAndNormalizer,
  defaultApiResponseHandler,
  fetchFlow,
  searchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

const reduxName = 'costCentres'

const handleCostCentreApiResponse = (mainAction, tableIdentifier) =>
  function* ({ data, costCentreGroups, tableOptions }) {
    yield put(mainAction(data))
    yield put(costCentreActions.costCentreGroups.fetchSuccess(costCentreGroups))

    if(tableIdentifier != null && tableOptions && tableOptions.orderBy != null) {
      yield put(costCentreActions.tableActions.updateOptions(tableOptions, tableIdentifier))
    }
    return data
  }

const watchOnCostCentreSockets = createSocketWatcherWithApiHandlerAndNormalizer('costCentre', costCentreActions, handleCostCentreApiResponse, normalizeCostCentres)

const costCentreFetchFlow = fetchFlow({
  fetchApi: costCentreApi.fetch,
  actions: costCentreActions,
  base: reduxName,
  idField: 'id',
  errorMsg: 'kustannuspaikkojen',
  getApiResponseHandler: data => handleCostCentreApiResponse(costCentreActions.fetchSuccess, data.tableIdentifier)
})

const costCentreUpdateFlow = updateFlow(costCentreApi.update, costCentreActions, 'Kustannuspaikka', 'Kustannuspaikan', handleCostCentreApiResponse(costCentreActions.updateSuccess))
const costCentreCreateFlow = createFlow(costCentreApi.create, costCentreActions, 'Kustannuspaikka', 'Kustannuspaikan', handleCostCentreApiResponse(costCentreActions.createSuccess))
const costCentreSearchFlow = searchFlow(costCentreApi.search, costCentreActions, 'Kustannuspaikkojen', function* (data, searchTerm) {
  const costCentres = yield handleCostCentreApiResponse(costCentreActions.fetchSuccess)(data)
  yield put(costCentreActions.searchSuccess(costCentres, searchTerm))
  return costCentres
})

const costCentreGroupFetchFlow = fetchFlow({
  fetchApi: costCentreGroupApi.fetch,
  actions: costCentreActions.costCentreGroups,
  base: `${reduxName}.costCentreGroups`,
  idField: 'id',
  errorMsg: 'kustannuspaikkaryhmien',
  getApiResponseHandler: () => defaultApiResponseHandler(costCentreActions.costCentreGroups.fetchSuccess)
})

const costCentreGroupUpdateFlow = updateFlow(costCentreGroupApi.update, costCentreActions.costCentreGroups, 'Kustannuspaikkaryhmä', 'Kustannuspaikkaryhmän', defaultApiResponseHandler(costCentreActions.costCentreGroups.updateSuccess))
const costCentreGroupCreateFlow = createFlow(costCentreGroupApi.create, costCentreActions.costCentreGroups, 'Kustannuspaikkaryhmä', 'Kustannuspaikkaryhmän', defaultApiResponseHandler(costCentreActions.costCentreGroups.createSuccess))

export default function* costCentreSaga() {
  yield takeEvery(costCentreActions.actionTypes.fetchRequest, costCentreFetchFlow)
  yield takeEvery(costCentreActions.actionTypes.updateRequest, costCentreUpdateFlow)
  yield takeEvery(costCentreActions.actionTypes.createRequest, costCentreCreateFlow)
  yield takeLatest(costCentreActions.actionTypes.searchRequest, costCentreSearchFlow)

  yield takeLatest(costCentreActions.costCentreGroups.actionTypes.fetchRequest, costCentreGroupFetchFlow)
  yield takeEvery(costCentreActions.costCentreGroups.actionTypes.updateRequest, costCentreGroupUpdateFlow)
  yield takeEvery(costCentreActions.costCentreGroups.actionTypes.createRequest, costCentreGroupCreateFlow)

  yield all([
    watchOnCostCentreSockets()
  ])
}
