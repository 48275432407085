import { all, takeEvery, takeLatest } from 'redux-saga/effects'

import employeeLicenceTypeActions from '../actions/employeeLicenceTypeActions'
import {
  createEmployeeLicenceType,
  deleteEmployeeLicenceType,
  fetchEmployeeLicenceTypes,
  updateEmployeeLicenceType
} from '../api/employeeLicenceTypeApi'
import {
  createFlow,
  createSocketWatcher,
  deleteFlow,
  fetchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

const watchOnEmployeeLicenceTypeSockets = createSocketWatcher('employeeLicenceType', {
  created: employeeLicenceTypeActions.createSuccess,
  updated: employeeLicenceTypeActions.updateSuccess,
  deleted: employeeLicenceTypeActions.deleteSuccess
})

const employeeLicenceTypeFetchFlow = fetchFlow({
  fetchApi: fetchEmployeeLicenceTypes,
  actions: employeeLicenceTypeActions,
  base: 'employeeLicenceTypes',
  errorMsg: 'Pätevyystyyppien'
})

const employeeLicenceTypeUpdateFlow = updateFlow(updateEmployeeLicenceType, employeeLicenceTypeActions, 'Pätevyystyyppi', 'Pätevyystyypin')
const employeeLicenceTypeCreateFlow = createFlow(createEmployeeLicenceType, employeeLicenceTypeActions, 'Pätevyystyyppi', 'Pätevyystyypin')
const employeeLicenceTypeDeleteFlow = deleteFlow({
  deleteApi: deleteEmployeeLicenceType,
  actions: employeeLicenceTypeActions,
  singular: 'Pätevyystyyppi',
  errorMsg: 'Pätevyystyypin',
  base: 'employeeLicenceTypes'
})

export default function* employeeLicenceTypeSaga() {
  yield takeLatest(employeeLicenceTypeActions.actionTypes.fetchRequest, employeeLicenceTypeFetchFlow)
  yield takeEvery(employeeLicenceTypeActions.actionTypes.updateRequest, employeeLicenceTypeUpdateFlow)
  yield takeEvery(employeeLicenceTypeActions.actionTypes.createRequest, employeeLicenceTypeCreateFlow)
  yield takeEvery(employeeLicenceTypeActions.actionTypes.deleteRequest, employeeLicenceTypeDeleteFlow)

  yield all([
    watchOnEmployeeLicenceTypeSockets()
  ])
}
