import { combineReducers } from 'redux'

import { actionTypes } from '../constants'

const initialState = {
  actionPrompt: {
    visible: false,
    promptType: null,
    data: {}
  }
}

const actionPromptReducer = (state = initialState.actionPrompt, action) => {
  switch(action.type) {
    case actionTypes.ACTION_PROMPT_SHOW: {
      const { promptType, data } = action
      return {
        ...state,
        visible: true,
        promptType,
        data
      }
    }
    case actionTypes.ACTION_PROMPT_HIDE:
      return initialState.actionPrompt
    default:
      return state
  }
}

export default combineReducers({
  actionPrompt: actionPromptReducer
})
