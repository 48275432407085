import { RowData, Table } from '@tanstack/react-table'

import Paginator from '../Paginator'

type Props<TData extends RowData> = {
  table: Table<TData>
  isVirtualTotalCount: boolean
}

const DataTablePagination = <TData extends RowData>({ table, isVirtualTotalCount }: Props<TData>) => {
  return (
    <Paginator
      page={table.getState().pagination.pageIndex}
      limit={table.getState().pagination.pageSize}
      totalCount={table.getRowCount()}
      pageCount={table.getPageCount()}
      setPage={table.setPageIndex}
      setLimit={table.setPageSize}
      isVirtualTotalCount={isVirtualTotalCount}
    />
  )
}

export default DataTablePagination
