import React from 'react'
import { Accordion } from 'reactstrap'
import { useImmer } from 'use-immer'

import { childrenPropType } from '../../propTypes'

export const AccordionContainer = ({ children }) => {
  const [openIdsArray, updateOpenIdsArray] = useImmer([])
  const toggle = id => {
    if(openIdsArray.includes(id)) {
      const deleteIndex = openIdsArray.findIndex(element => element === id)
      updateOpenIdsArray(draft => {
        draft.splice(deleteIndex, 1)
      })
    } else {
      updateOpenIdsArray(draft => {
        draft.push(id)
      })
    }
  }

  const childrenWithProps = React.Children.map(children, child => {
    const isChildOpen = openIdsArray.includes(child?.props?.accordionId)
    if(React.isValidElement(child)) {
      return React.cloneElement(child, { isOpen: isChildOpen })
    }
    return child
  })

  return (
    <Accordion open={openIdsArray} toggle={toggle}>
      {childrenWithProps}
    </Accordion>
  )
}

AccordionContainer.propTypes = {
  children: childrenPropType
}

export default AccordionContainer
