import { faCheck } from '@fortawesome/free-solid-svg-icons'

import CancelButton from '../Buttons/CancelButton'
import SaveButton from '../Buttons/SaveButton'
import DelayedActionButton from '../misc/DelayedActionButton'

interface CommonProps {
  onCancel: () => void
  isBusy: boolean
  cancelLabel?: string
}

interface SaveProps {
  saveLabel?: string
}

interface SuccessProps<T extends object> {
  onSuccess?: (model: T) => Promise<T>
}

export const ButtonFooter = ({
  onCancel,
  isBusy,
  saveLabel = 'Tallenna',
  cancelLabel = 'Peruuta'
}: CommonProps & SaveProps) => (
  <>
    <SaveButton
      condensed
      busyContent='Ladataan...'
      isBusy={isBusy}
    >
      {saveLabel}
    </SaveButton>
    <CancelButton onClick={onCancel} condensed isBusy={isBusy}>{cancelLabel}</CancelButton>
  </>
)

export const CancelFooter = ({ onCancel, isBusy, cancelLabel = 'Peruuta' }: CommonProps) => (
  <CancelButton onClick={onCancel} condensed isBusy={isBusy}>{cancelLabel}</CancelButton>
)

export const ConfirmationFooter = <T extends object, >({
  onSuccess,
  onCancel,
  isBusy,
  saveLabel = 'Tallenna',
  cancelLabel = 'Peruuta'
}: CommonProps & SaveProps & SuccessProps<T>) => (
  <>
    <DelayedActionButton
      icon={faCheck}
      onClick={onSuccess}
      confirmationMessage='Varmista toiminto'
      color='success'
      isBusy={isBusy}
      testId='confirmSetWorkFixedPrice'
    >
      {saveLabel}
    </DelayedActionButton>
    <CancelButton onClick={onCancel} condensed isBusy={isBusy}>{cancelLabel}</CancelButton>
  </>
  )
