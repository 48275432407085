import { useCallback, useMemo, useState } from 'react'

import { getLocalStorageValueOrDefault, useKeepInLocalStorage } from './useLocalStorage'

export type ToggleApi = {
  toggle: () => void
  disable: () => void
  enable: () => void
}

const useToggle = (initialState = false): [boolean, ToggleApi] => {
  const [isEnabled, setIsEnabled] = useState(initialState)
  const toggle = useCallback(() => setIsEnabled(isEnabled => !isEnabled), [])
  const disable = useCallback(() => setIsEnabled(false), [])
  const enable = useCallback(() => setIsEnabled(true), [])

  const api = useMemo(() => ({
    toggle,
    disable,
    enable
  }), [toggle, disable, enable])

  return [isEnabled, api]
}

export default useToggle

export const useLocalStorageToggle = (storageKey: string, initialState: boolean): [boolean, ToggleApi] => {
  const [isEnabled, api] = useToggle(getLocalStorageValueOrDefault(storageKey, initialState))
  useKeepInLocalStorage(storageKey, isEnabled)
  return [isEnabled, api] as const
}
