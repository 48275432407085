import PropTypes from 'prop-types'

const SimpleDetails = ({ title, text }) => (
  <div>
    {title && <><b>{title}</b><br /></>}
    {text}
  </div>
)

SimpleDetails.propTypes = {
  title: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ])
}

export default SimpleDetails
