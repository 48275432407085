import constants from '../constants'
import {
  actionCreatorsFor,
  error,
  request,
  start,
  success
} from '../helpers/actionHelpers'
import { PATH_CONTACTS } from '../routes'
import { navigateTo } from './navigateActions'

export const getUrl = contactId => `${PATH_CONTACTS}/${contactId || 'new'}`

const baseActionCreators = actionCreatorsFor(constants.actionKeys.CONTACTS, { fetchStats: true })

const additionalActionCreators = {
  files: actionCreatorsFor(constants.actionKeys.CONTACT_FILES),
  navigateTo: (contactId, replaceUrl) => navigateTo(getUrl(contactId), replaceUrl),
  navigateToBase: replaceUrl => navigateTo(PATH_CONTACTS, replaceUrl),
  customers: actionCreatorsFor(constants.actionKeys.CONTACT_CUSTOMERS),
  targets: actionCreatorsFor(constants.actionKeys.CONTACT_TARGETS),
  fetchStatsRequest: request(baseActionCreators.actionTypes.fetchStatsRequest),
  fetchStatsStart: start(baseActionCreators.actionTypes.fetchStatsStart),
  fetchStatsSuccess: success(baseActionCreators.actionTypes.fetchStatsSuccess),
  fetchStatsError: error(baseActionCreators.actionTypes.fetchStatsError),
  contactExtras: actionCreatorsFor(constants.actionKeys.CONTACT_EXTRAS)
}

const contactActions = { ...baseActionCreators, ...additionalActionCreators }
export default contactActions
