import { useLayoutEffect, useRef, useState } from 'react'
// Inspiration from https://gomakethings.com/how-to-check-if-any-part-of-an-element-is-out-of-the-viewport-with-vanilla-js/

// NOTE: doesn't listen viewport size changes. Could be combined with useWindowSize hook if necessary
const isOutOfViewport = element => {
  const bounding = element.getBoundingClientRect()
  // Check if it's out of the viewport on each side
  const out = {}
  out.top = bounding.top < 0
  out.left = bounding.left < 0
  out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight)
  out.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth)
  out.any = out.top || out.left || out.bottom || out.right
  out.all = out.top && out.left && out.bottom && out.right
  return out
}

const useIsOutOfViewport = () => {
  const [isOut, setIsOut] = useState({})
  const ref = useRef()

  useLayoutEffect(() => {
    if(ref.current) {
      setIsOut(isOutOfViewport(ref.current))
    }
  }, [])
  return {
    isOut,
    ref
  }
}

export default useIsOutOfViewport
