import {
  all,
  call,
  put,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'

import contactActions from '../actions/contactActions'
import customerActions from '../actions/customerActions'
import employeeActions from '../actions/employeeActions'
import projectActions from '../actions/projectActions'
import targetActions from '../actions/targetActions'
import ticketActions from '../actions/ticketActions'
import workActions from '../actions/workActions'
import { fetchRelationTickets, normalizeTickets, ticketApi } from '../api/ticketApi'
import { normalizeTicketEmployeeResponse, ticketEmployeeApi } from '../api/ticketEmployeeApi'
import { normalizeWorkList } from '../api/workApi'
import ticketTitles, { ticketTitlesCapitalized } from '../components/Tickets/ticketTitles'
import { actionTypes } from '../constants'
import { getFileLinkSagas, getMemoSagas } from '../helpers/generalSagas'
import { addSuccessNotification } from '../helpers/notificationHelpers'
import {
  createActionFlow,
  createFlow,
  createSocketWatcherWithApiHandlerAndNormalizer,
  createSocketWatcherWithGenerator,
  deleteFlow,
  fetchFlow,
  genericSagaErrorHandler,
  getPromiseHandlersFromData,
  getSubEntitySagas,
  updateFlow
} from '../helpers/sagaHelpers'
import { handleWorkApiResponse } from './workSaga'

const titles = ticketTitles
const titlesCapitalized = ticketTitlesCapitalized
const reduxName = 'tickets'

const handleTicketApiResponse = (mainAction, tableIdentifier) =>
  function* ({
    data,
    work,
    targets,
    customers,
    contacts,
    projects,
    tableOptions
  }) {
    yield put(mainAction(data))
    yield put(targetActions.fetchSuccess(targets))
    yield put(customerActions.fetchSuccess(customers))
    yield put(contactActions.fetchSuccess(contacts))
    yield put(projectActions.fetchSuccess(projects))
    yield handleWorkApiResponse(workActions.fetchSuccess)(normalizeWorkList(work))

    if(tableIdentifier != null && tableOptions && tableOptions.orderBy != null) {
      yield put(ticketActions.tableActions.updateOptions(tableOptions, tableIdentifier))
    }
    return data
  }

const watchOnTicketSockets = createSocketWatcherWithApiHandlerAndNormalizer('ticket', ticketActions, handleTicketApiResponse, normalizeTickets)

const watchOnTicketEmployeeSockets = createSocketWatcherWithGenerator('ticketEmployee', {
  created: function* (data) {
    const [[ticketEmployee]] = normalizeTicketEmployeeResponse(data)
    yield put(ticketActions.employees.createSuccess(ticketEmployee))
  },
  updated: function* (data) {
    const [[ticketEmployee]] = normalizeTicketEmployeeResponse(data)
    yield put(ticketActions.employees.updateSuccess(ticketEmployee))
  },
  deleted: function* (data) {
    yield put(ticketActions.employees.deleteSuccess(data))
  }
})

const ticketFetchFlow = fetchFlow({
  fetchApi: ticketApi.fetch,
  actions: ticketActions,
  base: reduxName,
  idField: 'id',
  errorMsg: titles.genetive,
  getApiResponseHandler: data => handleTicketApiResponse(ticketActions.fetchSuccess, data.tableIdentifier)
})

const ticketPatchActionFlow = createActionFlow(ticketApi.patch, ticketActions, handleTicketApiResponse)

const ticketRelationFetchFlow = fetchFlow({
  fetchApi: fetchRelationTickets,
  actions: ticketActions,
  base: reduxName,
  idField: 'id',
  errorMsg: titles.genetive,
  apiResponseHandler: handleTicketApiResponse(ticketActions.fetchSuccess)
})

const ticketUpdateFlow = updateFlow(ticketApi.update, ticketActions, titlesCapitalized.basic, titlesCapitalized.genetive, handleTicketApiResponse(ticketActions.updateSuccess))
const ticketCreateFlow = createFlow(ticketApi.create, ticketActions, titlesCapitalized.basic, titlesCapitalized.genetive, handleTicketApiResponse(ticketActions.createSuccess))
const ticketDeleteFlow = deleteFlow({
  deleteApi: ticketApi.remove,
  actions: ticketActions,
  singular: titlesCapitalized.basic,
  errorMsg: titlesCapitalized.genetive,
  base: reduxName
})

const employeeFlows = getSubEntitySagas(ticketActions, employeeActions, ticketEmployeeApi.fetch, ticketEmployeeApi.create, ticketEmployeeApi.remove, 'ticketId', 'employeeId', 'tickets', 'employees', {
  singular: `${titlesCapitalized.genetive} työntekijä`,
  accusative: `${titlesCapitalized.genetive} työntekijän`,
  fetchError: `Virhe ${titles.genetive} työntekijöiden noutamisessa`,
  deleteSuccess: `${titlesCapitalized.genetive} työntekijä poistettu`,
  deleteError: `Virhe ${titles.genetive} työntekijöiden poistamisessa`
}, function* ([ticketEmployees, employees]) {
  yield put(ticketActions.employees.fetchSuccess(ticketEmployees))
  yield put(employeeActions.fetchSuccess(employees.map(employee => {
    const { _embedded, ...employeeData } = employee
    return employeeData
  })))
})

function* ticketEmployeeUpdateFlow({ record, data = {} }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    const [ticketEmployeeData] = yield call(ticketEmployeeApi.update, record)
    yield put(ticketActions.employees.updateSuccess(ticketEmployeeData))
    addSuccessNotification(`${titlesCapitalized.genetive} työntekijä päivitetty`)
    yield call(resolve, ticketEmployeeData)
  } catch(err) {
    yield put(ticketActions.employees.updateError(err, record))
    yield * genericSagaErrorHandler(err, `Virhe ${titles.genetive} työntekijän muokkaamisessa`, reject)
  }
}

const memoSagas = getMemoSagas({
  actions: ticketActions,
  baseName: 'tickets',
  socketName: 'ticket',
  titleGenetive: titles.genetive
})

const fileLinkSagas = getFileLinkSagas({
  actions: ticketActions,
  baseName: 'tickets',
  baseIdFieldName: 'ticketId',
  socketName: 'ticket',
  titleGenetive: titles.genetive
})

export default function* ticketSaga() {
  yield takeEvery(ticketActions.actionTypes.fetchRequest, ticketFetchFlow)
  yield takeLatest(ticketActions.actionTypes.ticketsOfRelationFetchRequest, ticketRelationFetchFlow)
  yield takeEvery(ticketActions.actionTypes.updateRequest, ticketUpdateFlow)
  yield takeEvery(ticketActions.actionTypes.createRequest, ticketCreateFlow)
  yield takeEvery(ticketActions.actionTypes.deleteRequest, ticketDeleteFlow)

  yield takeEvery(ticketActions.employees.actionTypes.fetchRequest, employeeFlows.subFetchFlow)
  yield takeEvery(ticketActions.employees.actionTypes.createRequest, employeeFlows.subCreateFlow)
  yield takeLatest(ticketActions.employees.actionTypes.deleteRequest, employeeFlows.subDeleteFlow)
  yield takeLatest(ticketActions.employees.actionTypes.updateRequest, ticketEmployeeUpdateFlow)

  yield takeEvery(actionTypes.SET_TICKET_COMPLETED, ticketPatchActionFlow)

  yield all([
    memoSagas(),
    fileLinkSagas(),
    watchOnTicketSockets(),
    watchOnTicketEmployeeSockets()
  ])
}
