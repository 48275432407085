import CommonValueFormGroup from './CommonValueFormGroup'
import NumberFormInput from './inputs/NumberFormInput'
import { commonFormInputDefaultProps, commonFormInputPropTypes } from './propTypes'

const NumberFormGroup = props => (
  <CommonValueFormGroup {...commonFormInputDefaultProps} {...props} Component={NumberFormInput} />
)
NumberFormGroup.propTypes = commonFormInputPropTypes

export default NumberFormGroup
