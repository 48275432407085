import sortBy from 'lodash/sortBy'
import { createSelector } from 'reselect'

import { mapRecordNameOptions } from '../helpers/helpers'
import { getFindItemByIdSelector, getFindItemsByIdsSelector } from '../helpers/selectorHelpers'

export const getEmployeeRolesFromArgument = arg => arg.employeeRoles ? arg.employeeRoles.records : arg

export const findEmployeeRoleWithId = getFindItemByIdSelector(getEmployeeRolesFromArgument)
export const findEmployeeRoleWithIds = getFindItemsByIdsSelector(getEmployeeRolesFromArgument)

export const getEmployeeRolesSorted = createSelector(
  getEmployeeRolesFromArgument,
  roles => sortBy(roles, 'id')
)

export const getEmployeeRoleSelectOptions = createSelector(
  getEmployeeRolesFromArgument,
  employeeRoles => mapRecordNameOptions(employeeRoles)
)
