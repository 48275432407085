import {
  all,
  call,
  put,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'

import cashReceiptActions from '../actions/cashReceiptActions'
import customerActions from '../actions/customerActions'
import employeeActions from '../actions/employeeActions'
import fileActions from '../actions/fileActions'
import {
  createCashReceipt,
  createCashReceiptFile,
  deleteCashReceipt,
  deleteCashReceiptFile,
  fetchCashReceiptFiles,
  fetchCashReceiptPdf,
  fetchCashReceiptRows,
  fetchCashReceipts,
  normalizeCashReceiptResponse
} from '../api/cashReceiptApi'
import {
  createFlow,
  createSocketWatcher,
  deleteFlow,
  fetchFlow,
  genericSagaErrorHandler,
  getPromiseHandlersFromData,
  getSubEntitySagas
} from '../helpers/sagaHelpers'
import { handleEmployeeApiResponse } from './employeeSaga'

const watchOnCashReceiptSockets = createSocketWatcher('cashReceipt', {
  created: function* (data) {
    yield handleCashReceiptApiResponse(cashReceiptActions.createSuccess)(normalizeCashReceiptResponse(data))
  }
})

const handleCashReceiptApiResponse = mainAction =>
  function* ({ data, customers, employees }) {
    yield handleEmployeeApiResponse(employeeActions.fetchSuccess, false)(employees)
    yield put(customerActions.fetchSuccess(customers))
    yield put(mainAction(data))
    return data
  }

const cashReceiptFetchFlow = fetchFlow({
  fetchApi: fetchCashReceipts,
  actions: cashReceiptActions,
  base: 'cashReceipts',
  errorMsg: 'Kassakuittien',
  apiResponseHandler: handleCashReceiptApiResponse(cashReceiptActions.fetchSuccess)
})

const cashReceiptCreateFlow = createFlow(createCashReceipt, cashReceiptActions, 'Kassakuitti', 'Kassakuitin', handleCashReceiptApiResponse(cashReceiptActions.createSuccess))

const handleCashRowsReceiptApiResponse = mainAction =>
  function* ({ data }) {
    yield put(mainAction(data))
    return data
  }

function* cashReceiptRowsFetchFlow({ data = {} }) {
  try {
    yield put(cashReceiptActions.rows.fetchStart())
    const apiResponse = yield call(fetchCashReceiptRows, data.cashReceiptId)
    yield handleCashRowsReceiptApiResponse(cashReceiptActions.rows.fetchSuccess)(apiResponse)
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe kassakuitin rivien noutamisessa')
  }
}

function* cashReceiptPdfFetchFlow({ data = {} }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(cashReceiptActions.pdf.fetchStart())
    const response = yield call(fetchCashReceiptPdf, data.cashReceiptId)
    yield put(cashReceiptActions.pdf.fetchSuccess(response))
    yield call(resolve, response)
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe kassakuitin pdf noutamisessa', reject)
  }
}

const fileFlows = getSubEntitySagas(cashReceiptActions, fileActions, fetchCashReceiptFiles, createCashReceiptFile, deleteCashReceiptFile, 'cashReceiptId', 'fileId', 'cashReceipts', 'files', {
  singular: 'Kassakuitin tiedosto',
  accusative: 'Kassakuitin tiedoston',
  fetchError: 'Virhe kassakuitin tiedostojen noutamisessa',
  deleteSuccess: 'Kassakuitin tiedostolinkki poistettu',
  deleteError: 'Virhe kassakuitin tiedostolinkin poistamisessa'
})

const cashReceiptDeleteFlow = deleteFlow({
  deleteApi: deleteCashReceipt,
  actions: cashReceiptActions,
  singular: 'Kassakuitti',
  errorMsg: 'Kassakuitin',
  base: 'cashReceipts'
})

export default function* cashReceiptSaga() {
  yield takeLatest(cashReceiptActions.actionTypes.fetchRequest, cashReceiptFetchFlow)
  yield takeEvery(cashReceiptActions.actionTypes.createRequest, cashReceiptCreateFlow)

  yield takeLatest(cashReceiptActions.rows.actionTypes.fetchRequest, cashReceiptRowsFetchFlow)

  yield takeLatest(cashReceiptActions.pdf.actionTypes.fetchRequest, cashReceiptPdfFetchFlow)

  yield takeLatest(cashReceiptActions.files.actionTypes.fetchRequest, fileFlows.subFetchFlow)
  yield takeEvery(cashReceiptActions.files.actionTypes.createRequest, fileFlows.subCreateFlow)
  yield takeLatest(cashReceiptActions.files.actionTypes.deleteRequest, fileFlows.subDeleteFlow)

  yield takeEvery(cashReceiptActions.actionTypes.deleteRequest, cashReceiptDeleteFlow)

  yield all([
    watchOnCashReceiptSockets()
  ])
}
