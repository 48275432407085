import { actionTypes } from '../constants'

const historyActions = {
  replaceHistory: key => {
    return {
      type: actionTypes.REPLACE_HISTORY,
      data: {
        key
      }
    }
  }
}

export default historyActions
