import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { ActionCreator, ActionCreatorWithOptionalPayload, ActionCreatorWithPayload, UnknownAction } from '@reduxjs/toolkit'
import { bindActionCreators } from 'redux'

import { promisifyUnboundAction, promisifyUnboundToolkitAction } from '../helpers/dispatchHelpers'

type UnknownActionCreator = ActionCreator<UnknownAction> | ActionCreatorWithOptionalPayload<unknown>

const useActions = <T extends Record<string, UnknownActionCreator>>(actions: T): T => {
  const dispatch = useDispatch()
  return useMemo(() => bindActionCreators(actions, dispatch), [actions, dispatch])
}

export const useAction = (action: UnknownActionCreator, params: object) => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(action(params))
  }, [dispatch, action, params])
}

export const useActionCallback = (action: UnknownActionCreator) => {
  const dispatch = useDispatch()

  return useCallback((params: unknown) => {
    dispatch(action(params))
  }, [dispatch, action])
}

export const usePromisifyActionCallback = (action: UnknownActionCreator) => {
  const dispatch = useDispatch()

  return useCallback((...params: unknown[]) => {
    return promisifyUnboundAction(dispatch, action, ...params)
  }, [dispatch, action])
}

export const usePromisifyRTKActionCallback = <TModel, T extends { record: TModel } = { record: TModel }>(action: ActionCreatorWithPayload<T>) => {
  const dispatch = useDispatch()

  return useCallback((...params: unknown[]) => {
    return promisifyUnboundToolkitAction(dispatch, action, ...params)
  }, [dispatch, action])
}

export default useActions
