import { Suspense, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container } from 'reactstrap'
import { mfaErrorResponses, mfaResponses, mfaStatusOk } from '@evelia/helpers/constants'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './main.scss'
import whoAmIActions from '../../actions/whoAmIActions'
import Loading from '../../components/Loading/Loading'
import LoadingSpinner from '../../components/Loading/LoadingSpinner'
import SetupAuthenticatorAppModal from '../../components/MultiFactorAuthentication/SetupAuthenticatorAppModal'
import TotpVerificationModal from '../../components/MultiFactorAuthentication/TotpVerificationModal'
import { storageKeys } from '../../constants'
import { screenLargerThan } from '../../helpers/helpers'
import useFetch from '../../hooks/useFetch'
import { getSessionValueOrDefault, useKeepInSessionStorage } from '../../hooks/useSessionStorage'
import useToggle from '../../hooks/useToggle'
import Header from '../Header/Header'
import LoginHeader from '../LoginPage/LoginHeader'
import Sidebar from '../Sidebar'
import AuthorizeApp from './AuthorizeApp'

const screenSizeMd = 768

const useSideDrawerAccess = () => {
  const isLargeScreen = screenLargerThan(screenSizeMd)
  const isOpenDefault = getSessionValueOrDefault(storageKeys.SESSION_KEY_SIDE_DRAWER_ACCESS, isLargeScreen)

  const [isOpen, { toggle, disable }] = useToggle(isOpenDefault)
  useKeepInSessionStorage(storageKeys.SESSION_KEY_SIDE_DRAWER_ACCESS, isOpen)

  const onNavigated = useCallback(() => {
    if(!screenLargerThan(screenSizeMd)) {
      disable()
    }
  }, [disable])

  return [
    { className: isOpen ? null : 'closed' },
    toggle,
    onNavigated,
    isOpen ? faAngleLeft : faAngleRight
  ]
}

const Main = () => {
  const dispatch = useDispatch()
  const errors = useSelector(state => state.errors)
  const [isSetupAuthenticatorAppModalOpen, { enable: openSetupAuthenticatorAppModal, disable: closeSetupAuthenticatorAppModal }] = useToggle(false)
  const [isTotpVerificationModalOpen, { enable: openTotpVerificationModal, disable: closeTotpVerificationModal }] = useToggle(false)
  const [containerProps, toggle, onNavigated, icon] = useSideDrawerAccess()
  useFetch(whoAmIActions.fetchRequest, { force: true })

  const { data, busy } = useSelector(state => state.whoAmI)
  const isActive = !!data?.isActive

  const updateWhoAmIFetchPayload = () => {
    dispatch(whoAmIActions.fetchRequest({ force: true }))
    closeSetupAuthenticatorAppModal()
    closeTotpVerificationModal()
  }

  useEffect(() => {
    if((errors.status === 412 && errors.errorCode === mfaErrorResponses.required.errorCode) || data.mfaStatus === mfaResponses.required.mfaStatus) {
      openTotpVerificationModal()
    } else if((errors.status === 412 && errors.errorCode === mfaErrorResponses.setupRequired.errorCode) || data?.mfaStatus === mfaResponses.setupRequired.mfaStatus) {
      openSetupAuthenticatorAppModal()
    }
  }, [errors, openTotpVerificationModal, openSetupAuthenticatorAppModal, data])

  return (
    <Loading loading={busy} testId='mainLoader'>
      {data.mfaStatus !== mfaStatusOk
        ? (<LoginHeader />)
        : (
          <>
            <Header isActive={isActive} />
            <div id='app-main' {...containerProps}>
              <aside id='app-side-drawer'>
                <button className='btn-sidebar-toggle' onClick={toggle}>
                  <FontAwesomeIcon className='text-muted' icon={icon} />
                </button>
                <div id='app-side-drawer-content'>
                  <Sidebar isActive={isActive} onNavClick={onNavigated} />
                </div>
              </aside>
              <Container tag='main' fluid id='app-content'>
                <Suspense fallback={<LoadingSpinner testId='lazyLoader' />}>
                  <AuthorizeApp />
                </Suspense>
              </Container>
            </div>
          </>
          )}
      <TotpVerificationModal
        isOpen={isTotpVerificationModalOpen}
        handleClose={data.mfaStatus === mfaResponses.required.mfaStatus ? updateWhoAmIFetchPayload : closeTotpVerificationModal}
      />
      <SetupAuthenticatorAppModal
        isOpen={isSetupAuthenticatorAppModalOpen}
        handleClose={data?.mfaStatus === mfaResponses.setupRequired.mfaStatus ? updateWhoAmIFetchPayload : closeSetupAuthenticatorAppModal}
      />
    </Loading>
  )
}

export default Main
