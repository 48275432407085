import { takeLatest } from 'redux-saga/effects'

import authorActions from '../actions/authorActions'
import {
  authorApi
} from '../api/authorApi'
import { fetchFlow } from '../helpers/sagaHelpers'

const authorFetchFlow = fetchFlow({
  fetchApi: authorApi.fetch,
  actions: authorActions,
  errorMsg: 'tekijän',
  base: 'authors',
  updateLastFetched: true
})

export default function* authorSaga() {
  yield takeLatest(authorActions.actionTypes.fetchRequest, authorFetchFlow)
}
