import constants from '../constants'
import {
  actionCreatorsFor,
  error,
  request,
  start,
  success
} from '../helpers/actionHelpers'
import { PATH_FILES } from '../routes'
import { navigateTo } from './navigateActions'

const getUrl = fileId => `${PATH_FILES}/${fileId}`

const baseActionCreators = actionCreatorsFor(constants.actionKeys.FILES, { downloadFile: true })

const additionalActionCreators = {
  navigateTo: (fileId, replaceUrl) => navigateTo(getUrl(fileId), replaceUrl),
  navigateToBase: replaceUrl => navigateTo(PATH_FILES, replaceUrl),
  downloadFileRequest: request(baseActionCreators.actionTypes.downloadFileRequest),
  downloadFileStart: start(baseActionCreators.actionTypes.downloadFileStart),
  downloadFileSuccess: success(baseActionCreators.actionTypes.downloadFileSuccess),
  downloadFileError: error(baseActionCreators.actionTypes.downloadFileError),
  customerRelationFiles: actionCreatorsFor(constants.actionKeys.CUSTOMER_RELATION_FILES),
  projectRelationFiles: actionCreatorsFor(constants.actionKeys.PROJECT_RELATION_FILES)
}

const fileActions = { ...baseActionCreators, ...additionalActionCreators }
export default fileActions
