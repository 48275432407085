import { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, ListGroup, ListGroupItem } from 'reactstrap'
import isFunction from 'lodash/isFunction'

export const searchResultsPropType = {
  isBusy: PropTypes.bool.isRequired,
  results: PropTypes.array.isRequired,
  navigateTo: PropTypes.func.isRequired,
  closeResults: PropTypes.func.isRequired,
  entryCount: PropTypes.number
}
class SearchResults extends Component {
  render() {
    const { isBusy, title, showMoreResults } = this.props
    return (
      <>
        <b className='d-block pt-3 pb-2'>
          {title}
          {isFunction(showMoreResults) && (
            <Button size='sm' color='link' onClick={showMoreResults}>
              Näytä lisää tuloksia...
            </Button>
          )}
        </b>
        <ListGroup flush>{isBusy ? this.showLoading() : this.showResults()}</ListGroup>
      </>
    )
  }

  showLoading = () => {
    return <ListGroupItem className='py-1 px-0'>Ladataan...</ListGroupItem>
  }

  renderGroupItem = item => (
    <ListGroupItem
      key={item.id}
      className='py-1 px-0'
      tag='a'
      href='#'
      onClick={event => this.navigateAndClose(event, item.id)}
    >
      {this.props.renderResult(item)}
    </ListGroupItem>
  )

  showResults = () => {
    const { results: allResults, entryCount } = this.props
    const results = allResults.slice(0, entryCount)
    if(!results.length) {
      return <ListGroupItem key={-1} className='py-1' color='warning'>Ei tuloksia</ListGroupItem>
    }
    return results.map(this.renderGroupItem)
  }

  navigateAndClose = (event, itemId) => {
    event.preventDefault()
    this.props.navigateTo(itemId)
    this.props.closeResults()
  }

  static propTypes = {
    isBusy: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    results: PropTypes.array.isRequired,
    entryCount: PropTypes.number,
    navigateTo: PropTypes.func.isRequired,
    closeResults: PropTypes.func.isRequired,
    renderResult: PropTypes.func.isRequired,
    showMoreResults: PropTypes.func
  }

  static defaultProps = {
    entryCount: 20
  }
}

export default SearchResults
