/**
 * Inspiration https://github.com/betsol/numerous/blob/master/locales/fi_fi.js1
 * @param {number} value
 * @param {{ one: string, other: string, fallback?: string }} variants
 * @returns string
 */
const getSuffix = (value, variants) => {
  const isNumeric = Number.isFinite(Number.parseInt(value))
  if(!isNumeric) {
    return variants.fallback ?? variants.other
  }

  const intPart = Math.floor(Math.abs(value))
  const hasDecimal = !!value.toString().replace(/^[^.]*\.?/, '').length

  return intPart === 1 && !hasDecimal
    ? variants.one
    : variants.other
}

/**
 * @param {number} value
 * @param {{ one: string, other: string, fallback?: string }} variants
 * @returns string
 */
export const pluralize = (value, variants) => `${value ?? 0} ${getSuffix(value, variants)}`

export const pluralOrSingular = (count, singularText, pluralText) =>
  getSuffix(count, { one: singularText, other: pluralText })

export const pluralOrSingularWithCount = (count, singularText, pluralText) =>
  pluralize(count, { one: singularText, other: pluralText })
