import { parseTemplate } from 'url-template'

import { get, post, put } from '../helpers/apiHelpers'

const supplierUrlTemplate = parseTemplate('/api/suppliers{/supplierId}{/url}')

export const fetchSuppliers = (params = {}) => {
  return get(supplierUrlTemplate.expand(params))
    .then(parseSupplierResponse)
}

export const updateSupplier = supplier => {
  return put(supplierUrlTemplate.expand({ supplierId: supplier.id }), {
    body: JSON.stringify(supplier)
  })
}

export const createSupplier = supplier => {
  return post(supplierUrlTemplate.expand({}), {
    body: JSON.stringify(supplier)
  })
    .then(parseSupplierResponse)
}

export const updateOrder = order => {
  return put(supplierUrlTemplate.expand({ url: 'priorities' }), {
    body: JSON.stringify(order)
  })
}

const parseSupplierResponse = response => [
  response.data,
  response._embedded
]
