/* eslint-disable handle-callback-err, react/prop-types */
import { Button, Input, InputGroup } from 'reactstrap'
import { faCalendarAlt, faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// eslint-disable-next-line n/handle-callback-err
const MaterialUiInputAdapter = ({
  error,
  helperText,
  InputProps,
  setOpen,
  setNow,
  clear,
  inputRef,
  isDate,
  buttonSize,
  hideInput,
  ...props
}) => (
  <InputGroup>
    {hideInput
      ? null
      : <Input {...props} innerRef={inputRef} />}
    <Button
      data-testid={`${props['data-testid']}Button`}
      onClick={setOpen}
      disabled={props.disabled}
      color='primary'
      title='Valitse'
      size={buttonSize}
      tabIndex={-1}
    ><FontAwesomeIcon icon={isDate ? faCalendarAlt : faClock} />
    </Button>
  </InputGroup>
)

export default MaterialUiInputAdapter
