import PropTypes from 'prop-types'
import { dateFormats, formatOrNull } from '@evelia/helpers/dateHelpers'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import isNumber from 'lodash/isNumber'

export const sortingIndicators = {
  asc: <FontAwesomeIcon icon={faCaretUp} className='pt-1 px-1' />,
  desc: <FontAwesomeIcon icon={faCaretDown} className='pt-1 px-1' />,
  placeholder: <FontAwesomeIcon icon={faCaretDown} className='invisible' /> // hack to take even amount of space
}

export const createGlobalStringFilterFn = dateColumnIds => (row, columnId, filterValue) => globalStringFilterFunction(row, columnId, filterValue, dateColumnIds)
export const globalStringFilterFn = createGlobalStringFilterFn(['createdAt'])
const globalStringFilterFunction = (row, columnId, filterValue, dateColumnIds = ['createdAt']) => {
  const search = filterValue.toLowerCase()
  if(!search || search === '') {
    return true
  }
  let value = row.getValue(columnId)
  if(dateColumnIds.includes(columnId)) {
    value = formatOrNull(value ?? '', dateFormats.finnishDateTimeFormatShort)
  } else if(isNumber(value)) {
    value = String(value)
  } else if(Array.isArray(value)) {
    value = value.join(' ')
  }
  return value?.toLowerCase().includes(search) || (row.getParentRow() && globalStringFilterFunction(row.getParentRow(), columnId, filterValue, dateColumnIds))
}

// Increases table height to display correct sized 'fake' scrollbar
export const ScrollSpacer = ({ height }) => height > 0
  ? (
    <div className='dt-tr'>
      <div className='dt-td' style={{ height: `${height}px` }} />
    </div>
    )
  : null

ScrollSpacer.propTypes = {
  height: PropTypes.number
}

export const rowSelectionToIds = (rowSelection, items) => Object.entries(rowSelection)
  .map(([index, isSelected]) => isSelected ? items[index]?.id : null)
  .filter(Boolean)

export const rankItems = (items, startRank, endRank) => {
  let result = []
  const forward = startRank < endRank
  result = items.map((row, i) => {
    const { rank } = row
    if(rank === startRank) {
      return {
        ...row,
        rank: endRank
      }
    } else if(forward && rank > startRank && row.rank <= endRank) {
      return {
        ...row,
        rank: rank - 1
      }
    } else if(!forward && rank < startRank && row.rank >= endRank) {
      return {
        ...row,
        rank: rank + 1
      }
    }
    return false
  }).filter(Boolean).sort((a, b) => a.rank - b.rank)
  return [result, startRank, endRank]
}

export const rankItemsByIndex = (items, startIndex, targetIndex) => {
  const startRank = items[startIndex].rank
  const endRank = items[targetIndex].rank
  return rankItems(items, startRank, endRank)
}

export const getNestedRowStyleProps = getSubRows => row => ({
  className: clsx(
    row.depth === 0 && getSubRows(row.original) && 'table-success-lighter',
    row.depth === 1 && 'table-light'
  )
})

export const flattenNestedRows = nestedRows => {
  const flatRows = []

  function flatten(row) {
    const { subRows, ...rest } = row
    flatRows.push(rest)
    subRows?.forEach(subRow => flatten(subRow))
  }

  nestedRows.forEach(row => flatten(row))
  return flatRows
}
