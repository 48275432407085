import constants from '../constants'
import { actionCreatorsFor } from '../helpers/actionHelpers'

const baseActionCreators = actionCreatorsFor(constants.actionKeys.SUPPLY_OFFER_TYPES)

const additionalActionCreators = {

}

const supplyOfferTypeActions = { ...baseActionCreators, ...additionalActionCreators }
export default supplyOfferTypeActions
