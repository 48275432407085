import PropTypes from 'prop-types'
import { faUserLock } from '@fortawesome/free-solid-svg-icons/faUserLock'

import IconButton from '../Buttons/IconButton'

const TotpVerifyButton = ({ handleVerifyOtp, disabled }) => {
  return (
    <IconButton
      icon={faUserLock}
      condensed
      color='success'
      onClick={handleVerifyOtp}
      testId='sendMfaAuthentication'
      disabled={disabled}
    >
      Vahvista
    </IconButton>
  )
}

TotpVerifyButton.propTypes = {
  disabled: PropTypes.bool,
  handleVerifyOtp: PropTypes.func.isRequired
}

export default TotpVerifyButton
