import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'

import { commonFormInputDefaultProps, commonFormInputPropTypes } from '../propTypes'

export class CheckboxFormInput extends PureComponent {
  render() {
    const {
      field,
      value,
      isInvalid,
      disabled,
      disableAll,
      inputId,
      onKeyDown,
      testId,
      customStyle
    } = this.props
    return (
      <Input
        invalid={isInvalid}
        type='checkbox'
        name={field}
        id={inputId}
        checked={value}
        disabled={disabled || disableAll}
        onChange={this.handleOnChange}
        onKeyDown={onKeyDown}
        data-testid={testId || field}
        style={{ ...customStyle }}
      />
    )
  }

  handleOnChange = event => {
    const { onChange } = this.props
    return onChange(event.target.checked, event)
  }

  static propTypes = { ...commonFormInputPropTypes, customStyle: PropTypes.object }
  static defaultProps = commonFormInputDefaultProps
}

export default CheckboxFormInput
