import { createEntityAdapter } from '@reduxjs/toolkit'

import { PurchaseOrderFileLinkModel } from '../api/rtk/fileApi'
import { createGenericSlice } from '../helpers/sliceHelpers'
import { EveliaRootState } from '../reducerTypes'

const purchaseOrderFileAdapter = createEntityAdapter<PurchaseOrderFileLinkModel, number>({ selectId: purchaseOrderFile => purchaseOrderFile.id })

const purchaseOrderFileSlice = createGenericSlice({
  name: 'files',
  entityAdapter: purchaseOrderFileAdapter
})

export const purchaseOrderFileEntitySelectors = purchaseOrderFileAdapter.getSelectors<EveliaRootState>(state => state.purchaseOrders.files)
export const purchaseOrderFileActions = purchaseOrderFileSlice.actions

export default purchaseOrderFileSlice
