import { useRef } from 'react'
import PropTypes from 'prop-types'
import { SketchPicker } from 'react-color'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { getElementPosition } from '../../../helpers/helpers'
import useIsOutOfViewport from '../../../hooks/useIsOutOfViewport'
import useToggle from '../../../hooks/useToggle'
import IconButton from '../../Buttons/IconButton'
import ColorBadge from '../../misc/ColorBadge'
import { commonFormInputPropTypes } from '../propTypes'

const ColorFormInput = ({
  value,
  onChange,
  disabled,
  setNull,
  resetValue,
  invalid,
  initialValue
}) => {
  const [displayColorPicker, { enable, disable }] = useToggle()
  const parentRef = useRef()

  const handleChange = ({ hex }) => {
    if(hex === '' && setNull) {
      hex = null
    }
    return onChange(hex, null)
  }

  return (
    <div className='d-flex align-items-center'>
      <div ref={parentRef}>
        <ColorBadge color={value || initialValue} onClick={disabled ? null : enable} className={invalid ? 'border-danger' : ''} data-testid='color-picker' />
        {displayColorPicker
          ? (
            <Sketch
              parentRef={parentRef}
              value={value || initialValue}
              handleChange={handleChange}
              onCancel={disable}
            />
            )
          : null}
      </div>
      {value && resetValue && (
        <IconButton
          onClick={resetValue}
          icon={faXmark}
          size='xs'
          color='link'
          className='text-muted'
        />
      )}
      {invalid
        ? <FontAwesomeIcon icon={faCircleExclamation} className='ms-1 text-danger' />
        : null}
    </div>
  )
}

ColorFormInput.propTypes = {
  ...commonFormInputPropTypes,
  invalid: PropTypes.bool,
  initialValue: PropTypes.string
}

const Sketch = ({ parentRef, value, handleChange, onCancel }) => {
  const { isOut, ref } = useIsOutOfViewport()

  const position = parentRef.current
    ? getElementPosition(parentRef.current)
    : null
  const offsetY = parentRef.current?.clientHeight
  const offsetX = parentRef.current?.clientWidth

  const left = isOut.right
    ? position.left - ref.current.clientWidth + offsetX
    : position.left
  const top = isOut.bottom
    ? position.top - ref.current.clientHeight
    : position.top + offsetY

  const style = { left, top }
  return (
    <>
      <div
        className='color-box-full-canvas'
        onClick={onCancel}
      />
      <div ref={ref} className='color-box' style={style}>
        <SketchPicker color={value || ''} onChange={handleChange} disableAlpha />
      </div>
    </>
  )
}
Sketch.propTypes = {
  parentRef: PropTypes.shape({
    current: PropTypes.object
  }),
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default ColorFormInput
